import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import backArow from "../../assets/img/newDesign/backArrow.svg";
import { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { Form, Spinner } from "react-bootstrap";
import Rte from "components/BlogNote/Rte";
import LoadingEffect from "components/Loading/LoadingEffect";
import { t } from "i18next";
import { toastAlert } from "components/toastAlert/toastAlert";
import ReactMarkdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { detect } from "program-language-detector";
import rehypeRaw from "rehype-raw";
import styles from "./blog.module.scss";
import { useDispatch } from "react-redux";
import { addBlog } from "redux/actions/blog";
let lng = JSON.parse(localStorage.getItem("language"))?.lang;

const AddBlog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [titleEn, setTitleEN] = useState("");
  const [titleAr, setTitleAr] = useState("");
  const [shortDescriptionEn, setShortDescriptionEn] = useState("");
  const [shortDescriptionAr, setShortDescriptionAr] = useState("");
  const [cover, setCover] = useState("");
  const [coverAltEn, setCoverAltEn] = useState("");
  const [coverAltAr, setCoverAltAr] = useState("");
  const [banner, setBanner] = useState("");
  const [bannerAltEn, setBannerAltEn] = useState("");
  const [bannerAltAr, setBannerAltAr] = useState("");
  const [metaDescriptionEn, setMetaDescriptionEn] = useState("");
  const [metaDescriptionAr, setMetaDescriptionAr] = useState("");
  const [contentEn, setContentEn] = useState("");
  const [contentAr, setContentAr] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    let data = {
      title_en: titleEn || "",
      title_ar: titleAr || "",
      description_en: shortDescriptionEn || "",
      description_ar: shortDescriptionAr || "",
      cover_image: cover || "",
      cover_alt_en: coverAltEn || "",
      cover_alt_ar: coverAltAr || "",
      banner_image: banner || "",
      banner_alt_en: bannerAltEn || "",
      banner_alt_ar: bannerAltAr || "",
      meta_description_en: metaDescriptionEn || "",
      meta_description_ar: metaDescriptionAr || "",
      content_en: contentEn || "",
      content_ar: contentAr || "",
      publishedAt: new Date(),
    };
    if (
      !data.title_en ||
      !data.title_ar ||
      !data.description_en ||
      !data.description_ar ||
      !data.cover_image ||
      !data.cover_alt_en ||
      !data.cover_alt_ar ||
      !data.banner_image ||
      !data.banner_alt_en ||
      !data.banner_alt_ar ||
      !data.meta_description_en ||
      !data.meta_description_ar ||
      !data.content_en ||
      !data.content_ar
    ) {
      setLoading(false);
      return toastAlert(
        `There is some missing data, please check your form`,
        undefined,
        "warning"
      );
    } else {
      dispatch(addBlog(data, setLoading, navigate));
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      setCover(e.target.files[0]);
    }
  };

  const handleFileChangeBanner = (e) => {
    if (e.target.files) {
      setBanner(e.target.files[0]);
    }
  };

  return (
    <>
      <div className="inner_content">
        <div className="categories_header subCtegoryHeader">
          <Link to={`/blog`} className="sub_case_header">
            <img src={backArow} alt="back_button" />
            <label>{t("Add Blog")}</label>
          </Link>
        </div>
        <div
          style={{ margin: "0px", maxWidth: "unset" }}
          className="write_body inner_writeBody container"
        >

          <div className="sub_case_page_wrapper">
            <div
              style={{
                overflow: "auto",
                top: "70px",
              }}
              className="col-lg-12 row no-gutters"
            >
              {loading ? (
                <div style={{ width: "100%", display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", minHeight: "500px" }}>
                  <Spinner animation="border" role="status"/>
                  </div>
              ) : (
                <Fragment>
                  <div className="col-lg-4 col-md-6 col-sm-12 mt-5 px-3 chat_wrapper">
                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <label className="table-body-label">
                        {t("Title (en)")}:
                      </label>
                      <TextareaAutosize
                        autoFocus
                        style={{ height: "40px", overflow: "hidden" }}
                        className="form-control"
                        placeholder={t("Title")}
                        value={titleEn}
                        onChange={(e) => setTitleEN(e.target.value)}
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <label className="table-body-label">
                        {t("Title (ar)")}:
                      </label>
                      <TextareaAutosize
                        autoFocus
                        style={{ height: "40px", overflow: "hidden" }}
                        className="form-control"
                        placeholder={t("Title (AR)")}
                        value={titleAr}
                        onChange={(e) => setTitleAr(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mt-5 px-3 chat_wrapper">
                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <label className="table-body-label">
                        {t("Cover Image (400 x 284)")}
                      </label>
                      <Form.Group>
                        <Form.Control
                          onChange={handleFileChange}
                          className="form-control"
                          style={{
                            padding: "5px",
                            height: "40px",
                            overflow: "hidden",
                          }}
                          type="file"
                          placeholder="choose cover"
                        />
                      </Form.Group>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <label className="table-body-label">
                        {t("Banner Image (1209 x 437)")}
                      </label>
                      <Form.Group>
                        <Form.Control
                          onChange={handleFileChangeBanner}
                          className="form-control"
                          style={{
                            padding: "5px",
                            height: "40px",
                            overflow: "hidden",
                          }}
                          type="file"
                          placeholder="choose cover"
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mt-5 px-3 chat_wrapper">
                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <label className="table-body-label">
                        {t("Cover Alt (en)")}:
                      </label>
                      <TextareaAutosize
                        autoFocus
                        style={{ height: "40px", overflow: "hidden" }}
                        className="form-control"
                        placeholder={t("Alt")}
                        value={coverAltEn}
                        onChange={(e) => setCoverAltEn(e.target.value)}
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <label className="table-body-label">
                        {t("Cover Alt (ar)")}:
                      </label>
                      <TextareaAutosize
                        autoFocus
                        style={{ height: "40px", overflow: "hidden" }}
                        className="form-control"
                        placeholder={t("Alt (AR)")}
                        value={coverAltAr}
                        onChange={(e) => setCoverAltAr(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12 mt-5 px-3 chat_wrapper">
                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <label className="table-body-label">
                        {t("Banner Alt (en)")}:
                      </label>
                      <TextareaAutosize
                        autoFocus
                        style={{ height: "40px", overflow: "hidden" }}
                        className="form-control"
                        placeholder={t("Alt")}
                        value={bannerAltEn}
                        onChange={(e) => setBannerAltEn(e.target.value)}
                      />
                    </div>

                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <label className="table-body-label">
                        {t("Banner Alt (ar)")}:
                      </label>
                      <TextareaAutosize
                        autoFocus
                        style={{ height: "40px", overflow: "hidden" }}
                        className="form-control"
                        placeholder={t("Alt (AR)")}
                        value={bannerAltAr}
                        onChange={(e) => setBannerAltAr(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mt-5 px-3 chat_wrapper">
                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <label className="table-body-label">
                        {t("Short Description (en)")}:
                      </label>
                      <TextareaAutosize
                        autoFocus
                        style={{ height: "40px", overflow: "hidden" }}
                        className="form-control"
                        placeholder={t("Description")}
                        minRows={5}
                        value={shortDescriptionEn}
                        onChange={(e) => setShortDescriptionEn(e.target.value)}
                      />
                    </div>

                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <label className="table-body-label">
                        {t("Short Description (ar)")}:
                      </label>
                      <TextareaAutosize
                        autoFocus
                        style={{ height: "40px", overflow: "hidden" }}
                        className="form-control"
                        placeholder={t("Description (AR)")}
                        minRows={5}
                        value={shortDescriptionAr}
                        onChange={(e) => setShortDescriptionAr(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mt-5 px-3 chat_wrapper">
                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <label className="table-body-label">
                        {t("Meta Description (en)")}:
                      </label>
                      <TextareaAutosize
                        autoFocus
                        style={{ height: "40px", overflow: "hidden" }}
                        className="form-control"
                        placeholder={t("Meta Description")}
                        minRows={5}
                        value={metaDescriptionEn}
                        onChange={(e) => setMetaDescriptionEn(e.target.value)}
                      />
                    </div>

                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <label className="table-body-label">
                        {t("Meta Description (ar)")}:
                      </label>
                      <TextareaAutosize
                        autoFocus
                        style={{ height: "40px", overflow: "hidden" }}
                        className="form-control"
                        placeholder={t("Meta Description (AR)")}
                        minRows={5}
                        value={metaDescriptionAr}
                        onChange={(e) => setMetaDescriptionAr(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-sm-12 mt-5 px-3">
                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <label className="table-body-label">
                        {t("Content (en)")}:
                      </label>
                      <Rte content={contentEn} setContent={setContentEn} />
                    </div>
                  </div>
                  <div className="col-lg-6 mt-5 px-3 d-none d-lg-block">
                    <div
                      style={{ textAlign: lng === "arab" ? "right" : "unset" }}
                    >
                      <label className="table-body-label">
                        {t("Live Preview")}
                      </label>
                      <div className={styles.markDown}>
                        <ReactMarkdown
                          children={contentEn}
                          rehypePlugins={[rehypeRaw]}
                          components={{
                            code({
                              node,
                              inline,
                              className,
                              children,
                              ...props
                            }) {
                              const match = /language-(\w+)/.exec(
                                className || ""
                              );
                              return !inline && match ? (
                                <>
                                  <SyntaxHighlighter
                                    children={String(children).replace(
                                      /\n$/,
                                      ""
                                    )}
                                    language={detect(
                                      String(children?.slice(0)?.shift())
                                    )?.toLowerCase()}
                                    PreTag="div"
                                    style={docco}
                                    {...props}
                                  />
                                </>
                              ) : (
                                <code {...props} className={className}>
                                  {children}
                                </code>
                              );
                            },
                            em({ node, ...props }) {
                              return <li style={{ color: "red" }} {...props} />;
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-sm-12 mt-5 px-3">
                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <label className="table-body-label">
                        {t("Content (ar)")}:
                      </label>
                      <Rte content={contentAr} setContent={setContentAr} />
                    </div>
                  </div>

                  <div className="col-lg-6 mt-5 px-3 d-none d-lg-block">
                    <div
                      style={{ textAlign: lng === "arab" ? "right" : "unset" }}
                    >
                      <label className="table-body-label">
                        {t("Live Preview")}
                      </label>
                      <div className={styles.markDown}>
                        <ReactMarkdown
                          children={contentAr}
                          rehypePlugins={[rehypeRaw]}
                          components={{
                            code({
                              node,
                              inline,
                              className,
                              children,
                              ...props
                            }) {
                              const match = /language-(\w+)/.exec(
                                className || ""
                              );
                              return !inline && match ? (
                                <>
                                  <SyntaxHighlighter
                                    children={String(children).replace(
                                      /\n$/,
                                      ""
                                    )}
                                    language={detect(
                                      String(children?.slice(0)?.shift())
                                    )?.toLowerCase()}
                                    PreTag="div"
                                    style={docco}
                                    {...props}
                                  />
                                </>
                              ) : (
                                <code {...props} className={className}>
                                  {children}
                                </code>
                              );
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center my-5 w-100">
                    <button
                      style={{
                        padding: "10px",
                        background: loading
                          ? "gray"
                          : "linear-gradient(to right, #d851ef, #1877f2)",
                        pointerEvents: loading && "none",
                      }}
                      className="codeFormSubmit col-lg-2 col-md-4 col-sm-4"
                      onClick={() => handleSubmit()}
                    >
                      {loading ? <LoadingEffect /> : t("Save")}
                    </button>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBlog;
