import React from "react";
import { Modal } from "react-bootstrap";
import "./upgrade.scss";
import { useTranslation } from "react-i18next";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import textEditor from "./textEditor.png";
import { useNavigate } from "react-router-dom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const Upgrade = ({ show, setShow, width }) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Modal centered show={show} size="lg">
      <span className="close_button" onClick={() => navigate("/")} style={{ left: lng === "arab" ? "15px" : null, right: lng === "arab" ? null : "15px" }}>
        <CloseRoundedIcon sx={{ fontSize: "20px" }} />
      </span>
      <Modal.Body
        className="upgrade_modal_doc"
        style={{
          textAlign: lng === "arab" ? "right" : "left",
          fontFamily: lng === "arab" ? "Tajawal" : "poppins",
          background:
            width > 991
              ? lng === "arab"
                ? " linear-gradient(270deg, rgba(252, 252, 252, 0.00) 70%, rgba(97, 0, 255, 0.38) 100%)"
                : " linear-gradient(90deg, rgba(252, 252, 252, 0.00) 70%, rgba(97, 0, 255, 0.38) 100%)"
              : null,
        }}
      >
        <div className="upgrade_modal_doc_content">
          <div>
            <h1>{t("Unlock Text Editor")}</h1>
            <h6>{t("Unlock your full’s productivity. Create high- quality, original, and SO-optimized content with ease. Get access to the best features.")}</h6>
            <ul>
              {t("Here’s what you get with Text Editor:")}
              <li style={{ margin: lng === "arab" ? "10px 20px 10px 0" : "10px 0 10px 20px" }}>
                <b style={{ margin: lng === "arab" ? "0 10px 0 0" : "0 0 0 10px" }}>{t("Increased your limits")} -</b>
                {t("Text Editor Allows you to scale your writing instantly")}
              </li>
              <li style={{ margin: lng === "arab" ? "10px 20px 10px 0" : "10px 0 10px 20px" }}>
                <b style={{ margin: lng === "arab" ? "0 10px 0 0" : "0 0 0 10px" }}>{t("Save Time")}</b>- {t("Create quality content and enhance your writing right away")}
              </li>
            </ul>
          </div>
          {width > 991 && (
            <div className="upgrade_modal_doc_image">
              <img src={textEditor} width={"100%"} />
            </div>
          )}
        </div>
        <Modal.Footer className="upgrade_doc_footer">
          <span>{t("You can cancel anytime")}</span>
          <button
            onClick={() => {
              setShow(false);
              navigate("/plan");
            }}
          >
            {t("Upgrade Plan")}
            <EastRoundedIcon sx={{ color: "#fff", margin: lng === "arab" ? "0 10px 0 0" : "0 0 0 10px", transform: lng === "arab" ? "scale(-1)" : null }} />
          </button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

export default Upgrade;
