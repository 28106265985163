import { USER, LOGOUT } from "redux/contstants";

export default (user = {}, action) => {
  switch (action.type) {
    case USER:
      return action.data;
    case LOGOUT:
      localStorage.removeItem('sess');
      window.location.replace(process.env.REACT_APP_LANDING_URL + 'logingOut')
      return {};
    default:
      return user;
  }
};
