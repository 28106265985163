import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import defaultIcon from '../../assets/img/newDesign/html.png'
import { useNavigate } from 'react-router-dom'
import Marquee from 'react-double-marquee';
import html from '../../assets/img/newDesign/html.png'
import flutter from '../../assets/img/newDesign/flutter.png'
import dart from '../../assets/img/newDesign/dart.png'
import css from '../../assets/img/newDesign/css.png'
import cPlus from '../../assets/img/newDesign/C++.png'
import c from '../../assets/img/newDesign/c.png'
import cSharp from '../../assets/img/newDesign/cSharp.png'
import ruby from '../../assets/img/newDesign/ruby.png'
import python from '../../assets/img/newDesign/python.png'
import php from '../../assets/img/newDesign/php.png'
import javasrcipt from '../../assets/img/newDesign/javasrcipt.png'
import java from '../../assets/img/newDesign/java.png'
import rightArrow from "../../assets/img/rightArrow.png"
import { useTranslation } from 'react-i18next'
import { Spinner } from 'react-bootstrap';

const WriteCategory = ({item, index, goTo, asIndex, asSubIndex, borderIn, programming, mainElem, desc}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const cardRef = useRef();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const textRef = useRef();
  const marqRef = useRef();
  const location = useLocation()
  const [displayMarq, setDisplayMarq] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [imageLoading, setImageLoading] = useState(true);
  const [itemState, setItemState] = useState({
    name: 
    mainElem?
    lng === 'arab'? 
      item?.arabic_name : 
      item?.name: 
    programming?
      item?.name:
      asSubIndex? 
      lng === 'arab'? 
      item?.arabic_name : 
      item?.name: 
      item?.category_name,
    icon: 
    mainElem?
      item?.icon:
      programming? 
      item?.name === 'C++'?
        cPlus:
      item?.name === 'Java'?
        java:
      item?.name === 'Javascript'?
        javasrcipt:
      item?.name === 'Python'?
        python:
      item?.name === 'CSS'?
        css:
      item?.name === 'HTML'?
        html:
      item?.name === 'C'?
        c:
      item?.name === 'C#'?
        cSharp:
      item?.name === 'Dart'?
        dart:
      item?.name === 'PHP'?
        php:
      item?.name === 'Ruby'?
        ruby:
      defaultIcon:
      asSubIndex? 
      item?.element_icon:
      item?.category_icon
  });
  useEffect(() => {
    setItemState({
      name: 
      mainElem?
    lng === 'arab'? 
      item?.arabic_name : 
      item?.name:
      programming?
        item?.name:
        asSubIndex? 
        lng === 'arab'? 
        item?.arabic_name : 
        item?.name: 
        item?.category_name,
      icon: 
      mainElem?
      item?.icon:
      programming? 
        item?.name === 'C++'?
          cPlus:
        item?.name === 'Java'?
          java:
        item?.name === 'Javascript'?
          javasrcipt:
        item?.name === 'Python'?
          python:
        item?.name === 'CSS'?
          css:
        item?.name === 'HTML'?
          html:
        item?.name === 'C'?
          c:
        item?.name === 'C#'?
          cSharp:
        item?.name === 'Dart'?
          dart:
        item?.name === 'PHP'?
          php:
        item?.name === 'Ruby'?
          ruby:
        defaultIcon:
        asSubIndex? 
        item?.element_icon:
        item?.category_icon
    });
  }, [item])

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    if (cardRef.current && textRef.current) {
      if ((cardRef.current.offsetWidth - textRef.current.offsetWidth) <= 15) {
        setDisplayMarq(true)
      }
    }
    if (cardRef.current && marqRef.current) {
      if ((cardRef.current.offsetWidth - marqRef.current.offsetWidth) > 15) {
        setDisplayMarq(false)
      }
    }
  }, [cardRef.current?.offsetWidth, itemState, textRef.current, width, marqRef.current, location.pathname])
  const onImageError = (e) => {
    e.target.src = defaultIcon
  }
  return (
    <div key={index} style={{display: 'flex', alignItems:'center', justifyContent: 'center'}} className={asIndex? 'col-xl-3 col-lg-4 col-sm-4 col-6':asSubIndex? 'col-xl-3 col-lg-4 col-sm-4 col-6 ': mainElem? "col-xl-3 col-lg-4 col-md-6 col-sm-5" :'col-xl-3 col-lg-4 col-sm-4 col-6'}>
    <Link ref={cardRef} style={borderIn && asIndex? {border: '2px solid #1877F2'}:mainElem? {alignItems: 'center', justifyContent: 'center', width: width < 600? '80%':null, height: width < 600? '182px':null}: null } 
      to={
        mainElem? 
        `/${goTo}`:
        programming?
        `/writecode/${encodeURIComponent(item?.key)}`:
        asSubIndex? `/writenow/${item?.category}/${goTo}`: `/writenow/${goTo}`}  
      className='category_card category_card_height '
    >
      {imageLoading && (
        <div className='cat_img' style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Spinner animation="grow" />
        </div>
      )}
      {
      mainElem?
      <img 
        onError={onImageError}
        onLoad={() => setImageLoading(false)} 
        className={!imageLoading? 'cat_img':'cat_img_null'} 

        src={
          itemState?.icon || 
          defaultIcon
        } 
        alt='image_generated'
        style={{width: item?.specialIcon? "35%":'50%', height: 'auto'}}
      />:
      programming?
        <img 
          onError={onImageError}
          onLoad={() => setImageLoading(false)} 
          className={!imageLoading? 'cat_img':'cat_img_null'} 
          src={
            itemState?.icon || 
            defaultIcon
          } 
          alt='image_generated'
        />:
        <img 
          className={!imageLoading? 'cat_img':'cat_img_null'} 
          onLoad={() => setImageLoading(false)}  
          onError={onImageError} 
          src={itemState?.icon || defaultIcon} 
          alt='image_generated'/>
      }
      <div style={{width: '100%', display: 'flex', flexDirection:'column', gap: '5px', alignItems: 'center', justifyContent: 'center'}}>
        {!displayMarq?
        <span>
        <label style={{whiteSpace: 'nowrap', direction:programming?'ltr':null, margin: mainElem? '0px':null}} ref={textRef}>{itemState?.name}</label>
        </span> :
        mainElem? 
        <span>
        <label style={{whiteSpace: 'nowrap', direction:programming?'ltr':null, margin: '0px'}} ref={textRef}>{itemState?.name}</label>
        </span> :
        <div className={lng === 'arab' ? 'cssmarquee_arab' : 'cssmarquee'} style={{ direction: 'ltr' }}>
          <Marquee 
            speed={0.025}
            delay={0}
            childMargin={15} 
            direction={lng === 'arab' ? 'right' : 'left'} 
            className='label_of_title'
          >
            <label style={{direction:programming?'ltr':null}} ref={marqRef}>{
              itemState?.name
            }</label>
          </Marquee>
        </div>
        }
        {
        mainElem &&
          <p style={{textAlign: lng === 'arab'? 'center':'center', color: 'gray', margin: '0px', fontSize: '12px', width: '90%'}}>
            {t(desc)}
          </p>
        }
        </div>
    </Link>
    </div>
  )
}

export default WriteCategory