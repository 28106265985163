import styles from "../../views/plansComponent/Checkout/checkout.module.scss";
import { useTranslation } from "react-i18next";
import { ReactComponent as Bubble1 } from "assets/img/upgrade_plan/Depressed.svg";
import { ReactComponent as Ellipse } from "assets/img/upgrade_plan/Ellipse 1146.svg";
import { ReactComponent as Bubble2 } from "assets/img/upgrade_plan/Group 48095509.svg";
import { ReactComponent as Bubble3 } from "assets/img/upgrade_plan/Group 48095510.svg";
import { ReactComponent as Bubble4 } from "assets/img/upgrade_plan/Group 48095511.svg";
import { ReactComponent as Bubble5 } from "assets/img/upgrade_plan/Group 48095512.svg";
import { ReactComponent as Bubble6 } from "assets/img/upgrade_plan/Group 48095513.svg";
import { ReactComponent as Bubble7 } from "assets/img/upgrade_plan/Group 48095514.svg";
import { ReactComponent as Check } from "assets/img/upgrade_plan/Check.svg";
import { ReactComponent as Cry } from "assets/img/upgrade_plan/Cry.svg";
import { ReactComponent as Alphabet } from "assets/img/upgrade_plan/Alphabet.svg";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { baseUrl } from "api/baseUrl";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

//<------------------------CSS START------------------------->

const freeTrialPopUpContainer = {
  display: "flex",
  alignItems: "center",
  justifyContent: "spaceAround",
  flexDirection: "column",
  overflow: "hidden",
};

const bubble2 = {
  position: "absolute",
  right: "50",
  top: "30",
  width: "35px",
};

const bubble3 = {
  position: "absolute",
  left: "100",
  top: "-10",
  width: "45px",
};

const bubble4 = {
  position: "absolute",
  right: "110",
  top: "-5",
  width: "35px",
};
const bubble5 = {
  position: "absolute",
  left: "0",
  top: "15",
  width: "25px",
};
const bubble6 = {
  position: "absolute",
  right: "0",
  top: "75",
  width: "25px",
};
const bubble7 = {
  position: "absolute",
  right: "190",
  top: "25",
  width: "55px",
};
const ellipse = {
  position: "absolute",
  left: "50",
  top: "50",
  width: "35px",
};
const bubble1 = {
  position: "absolute",
  left: "55",
  top: "60",
  width: "25px",
};

const alphabet = {
  position: "absolute",
  right: "15px",
  top: "15px",
  width: "25px",
  height: "25px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  backgroundColor: "#FFF",
  borderRadius: "100%",
  padding: "5px",
};

const rectangle_data1 = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  padding: "10px",
  marginRight: "0px",
  color: "black",
  fontWeight: "600",
  width: "100%",
  background: "linear-gradient(180deg, rgba(0, 178, 255, 0.60) 0%, rgba(255, 255, 255, 0.00) 100%)",
  position: "relative",
  borderRadius: "15px",
};

const freeTrialButton = {
  borderRadius: "12px",
  background: "linear-gradient(232deg, #0085FF 0%, #00C2FF 100%)",
  border: "none",
  fontWeight: "700",
  color: "#FFF",
  padding: "10px 20px",
};

const rectangle_data2 = {};

const bodyTextList = {
  gap: "10px",
};

const bodyTextListItem = {
  display: "flex",
  marginBottom: "10px",
  alignItems: "center",
  gap: "1rem",
};

const bodyH6 = {
  fontSize: "0.9rem",
  fontWeight: "500",
  color: "#000",
  margin: "0",
  textTransform: "capitalize",
};

const hrTag = {
  color: "black",
  width: "100%",
  marginBottom: 0,
};

const freeTrialFooter = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0 20px 20px 20px",
  width: "100%",
};

const freetrialCostMoney = {
  margin: "0",
  color: "black",
};

const freeTrialCostMonth = {
  color: "#868686",
};

const upgradeButton = {
  backgroundColor: "#0057FF",
  borderRadius: "14px",
  padding: "7px 20px",
  color: "#FFFFFF",
  fontWeight: "700",
  border: "none",
};
//<------------------------CSS END -------------------------->

const UpgradeModal = (props) => {
  const navigate = useNavigate();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();

  useEffect(() => {
    lastSubscription();
  }, [props.showModal]);

  const lastSubscription = async () => {
    try {
      const response = await axios.get(`${baseUrl}subscription/get/user`);
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <Modal centered show={props.showModal} onHide={() => props.setShowModal(false)}>
      <span className="close_button" onClick={() => props.setShowModal(false)} style={{ left: lng === "arab" ? "15px" : null, right: lng === "arab" ? null : "15px" }}>
        <CloseRoundedIcon sx={{ fontSize: "20px" }} />
      </span>
      <div
        style={{
          fontFamily: lng === "arab" ? "Tajawal" : "Poppins",
        }}
      >
        <div style={freeTrialPopUpContainer}>
          <div className={styles.head} style={rectangle_data1}>
            <Bubble2 style={bubble2} />
            <Bubble3 style={bubble3} />
            <Bubble4 style={bubble4} />
            <Bubble5 style={bubble5} />
            <Bubble6 style={bubble6} />
            <Bubble7 style={bubble7} />
            {/* <span style={alphabet} onClick={() => props.setShowModal(false)}>
              <Alphabet style={{ width: "12px", height: "12px" }} />
            </span> */}

            <span>
              <Ellipse style={ellipse} />
              <Bubble1 style={bubble1} />
            </span>
            <h4
              style={{
                fontFamily: lng === "arab" ? "Tajawal" : "Poppins",
                fontWeight: "600",
                color: "black",
                marginTop: "100px",
              }}
            >
              {t("Free Trial Limit Reached")}
              <Cry style={{ margin: lng === "arab" ? "0 10px 0 0" : "0 0 0 10px" }} />
            </h4>
            <p style={{ textAlign: "center", margin: "auto", width: "80%" }}>{t("You have reached your free limit, your limit will renew on")}</p>

            <button style={freeTrialButton} onClick={() => navigate("/plan")}>
              {t("Subscribe Now")}
            </button>
          </div>
          <div
            className={styles.head}
            style={{
              textAlign: lng === "arab" ? "right" : "left",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              padding: "20px",
              marginRight: "0px",
              color: "black",
              fontWeight: "600",
              width: "100%",
            }}
          >
            <h5 style={{ color: "black", fontWeight: "600", margin: lng === "arab" ? "0 0 10px auto" : "0 auto 10px 0" }}>{t("What you get")}</h5>
            <div style={bodyTextList}>
              <div style={bodyTextListItem}>
                <Check />
                <h6 style={bodyH6}>{t("Unlock endless possibilities for captivating and engaging content.")}</h6>
              </div>
              <div style={bodyTextListItem}>
                <Check />
                <h6 style={bodyH6}>{t("Elevate your content quality with AI-enhanced writing and visuals.")}</h6>
              </div>
              <div style={bodyTextListItem}>
                <Check />
                <h6 style={bodyH6}>{t("Stay ahead of the curve with the latest AI tools")} </h6>
              </div>
            </div>
            <hr style={hrTag} />
          </div>
          <div style={freeTrialFooter}>
            <div>
              <h4 style={{ marginTop: "0", fontWeight: "500", color: "#A8A8A8", marginBottom: "5px", fontSize: "1.2rem", textAlign: lng === "arab" ? "right" : "left" }}>{t("Starting at")}</h4>
              <h3 style={freetrialCostMoney}>
                $3.99 <span style={{ fontSize: "1rem" }}>/{t("month")}</span>
              </h3>
            </div>
            <button style={upgradeButton} onClick={() => navigate("/plan")}>
              {t("Upgrade Plan")}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpgradeModal;
