import React from "react";
import ReactDOM from "react-dom/client";

let languageFromLocal = JSON.parse(localStorage.getItem("language"));
if (!languageFromLocal) {
  localStorage.setItem("language", JSON.stringify({ lang: "arab" }));
}
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "redux/store";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "remixicon/fonts/remixicon.css";
import "./assets/css/araby.css";
const B = JSON.parse(localStorage.getItem("language"))?.lang === "arab" && import("./assets/scss/light-bootstrap-dashboard-react-arab.scss");
const C = JSON.parse(localStorage.getItem("language"))?.lang !== "arab" && import("./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0");
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./assets/css/demo.css";
import "./App.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import AdminLayout from "layouts/Admin.js";
import OfferTimeProvider from "providers/OfferTimeProvider";

document.documentElement.setAttribute("data-color-mode", "light");
const root = ReactDOM.createRoot(document.getElementById("root"));
if (localStorage.getItem("sess")) {
  axios.defaults.headers.common["lang"] = JSON.parse(localStorage.getItem("language"))?.lang === "arab" ? "ar" : "en";
  axios.defaults.headers.common["Authorization"] = `${localStorage.getItem("sess")}`;
} else {
  axios.defaults.headers.common["lang"] = JSON.parse(localStorage.getItem("language"))?.lang === "arab" ? "ar" : "en";
}

root.render(
  <Provider store={store}>
    {/* <OfferTimeProvider> */}
    <BrowserRouter>
      <AdminLayout />
    </BrowserRouter>
    {/* </OfferTimeProvider> */}
  </Provider>
);
