import React, { Component, Fragment, useEffect, useRef, useState, useContext } from "react";
import { useLocation, Route, Switch, BrowserRouter, Routes, Navigate, Link } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import { dashboardRoutes, enterpriseMainRoutes, enterpriseNormalRoutes } from "routes.js";
import sidebarImage from "assets/img/sidebar-3.jpg";
import OopsSvg from "assets/img/invitation.png";
import { useDispatch } from "react-redux";
import { getAllCases, getMostUsedCases } from "redux/actions/cases";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUser } from "redux/actions/user";
import axios from "axios";
import { TRANSLATIONS_ARAB } from "../translations/arab/translations";
import { initReactI18next } from "react-i18next";
import i18n, { use } from "i18next";
import LoadingPopUp from "components/popUps/LoadingPopUp";
import ClearIcon from "@mui/icons-material/Clear";
import "./admin.scss";
import { getUserSubscription, getUserEnterprise } from "redux/actions/user_subscription";
import ClaimFreeModal from "components/ClaimFreeModal/ClaimFreeModal";
import { OfferTimerContext } from "providers/OfferTimeProvider";
import PlansPopUp from "components/PlansPopUp/PlansPopUp";
import { getBusinessCases } from "redux/actions/cases";
import NotFound from "views/404_not_found/notfoundscreen";
import Home from "views/Home";
import ArabyGPT from "views/ArabyGPT";
import WriteNow from "views/WriteNow";
import SubCategories from "views/SubCategories";
import SubCategory from "views/SubCategory";
import MediaSection from "views/MediaSection/MediaSection";
import ImagesTools from "views/MediaSection/ImageTools/ImagesTools";
import ImgGen from "views/MediaSection/ImageTools/Create/ImgGen";
import Enhance from "views/MediaSection/ImageTools/Enhance/EnhanceImage";
import RevProfile from "components/RevProfile/RevProfile";
import ContactUs from "views/ContactUs";
import WriteCode from "views/WriteCode";
import CodeGen from "views/CodeGen/CodeGen";
import Plan from "views/plansComponent/Plans/Plan";
import BusinessProfile from "components/RevProfile/businessProfile";
import BusinessTools from "views/Business/BusinessTools";
import MarketingPlan from "views/Marketing/MarketingPlan";
import Presentation from "views/Presentation/Presentation";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import { useTranslation } from "react-i18next";
import GPTMain from "views/newGPT/GPTMain";
import GPTChat from "views/newGPT/GPTChat";
import { getNewCases } from "redux/actions/cases";
import aesjs from "aes-js";
import CopyToClipboard from "react-copy-to-clipboard";
import { toastAlert } from "components/toastAlert/toastAlert";
import AppleIcon from "@mui/icons-material/Apple";
import playstore from "../assets/img/newDesign/playstore.png";
import Blog from "views/blog/Blog";
import AddBlog from "views/blog/AddBlog";
import UpdateBlog from "views/blog/UpdateBlog";
import PaymentGateWay from "components/PaymentGateWay/PaymentGateWay";
import History from "views/History/History";
import Document from "views/WriteDocument/WriteDocument";
import { Modal } from "react-bootstrap";
import LoadingEffect from "components/Loading/LoadingEffect";
import checkErr from "redux/actions/api_error_func";
import bgdesk from "assets/img/newDesign/bgdesk.png";
import bgmob from "assets/img/newDesign/bgmobile.png";
import BackToSchool from "./backToSchool";
import AudioTools from "views/MediaSection/AudioTools/AudioTools";
import VideoTools from "views/MediaSection/VideoTools/VideoTools";
import ChatDoc from "views/ChatDoc/ChatDoc";

let allTextTypes = ["label", "a", "button", "div", "li", "p", "h1", "h2", "h3", "h4", "h5", "h6", "span", "input"];
const resources = {
  arab: {
    translation: TRANSLATIONS_ARAB,
  },
};
let languageFromLocal = JSON.parse(localStorage.getItem("language"));
i18n.use(initReactI18next).init({
  resources,
  lng: languageFromLocal ? languageFromLocal.lang : "arab",
});
function Admin() {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const isOpen = useSelector((state) => state.sidebar);
  const new_cases = useSelector((state) => state.new_cases);
  const location = useLocation();
  const [routeArray, setRouteArray] = React.useState([]);
  const navigate = useNavigate();
  const [changeOfLanguage, setChangeOfLanguage] = useState(0);
  const bigLoading = useSelector((state) => state.loading);
  const [loadingSwitch, setLoadingSwitch] = useState(bigLoading);
  const { cases, loading } = useSelector((state) => state?.cases);
  const stylingPoints = useSelector((state) => state?.styling);
  const dispatch = useDispatch();
  const search = useLocation().search;
  const tokenFromUrl = new URLSearchParams(search).get("t");
  const [showSecondModal, setShowSeconModal] = useState(false);
  const [getApp, setGetApp] = useState(true);
  const [getPromo, setGetPromo] = useState(false);
  const [backToSchool, setBackToSchool] = useState(true);

  useEffect(() => {
    if (user?.hasInvite) {
      setShowSeconModal(true);
    } else {
      setShowSeconModal(false);
    }
  }, [user]);
  useEffect(() => {
    if (localStorage.getItem("promo") === "close") {
      setGetPromo(false);
    } else {
      localStorage.setItem("promo", "open");
      setGetPromo(true);
    }
  }, []);
  useEffect(() => {
    if (localStorage.getItem("app") === "close") {
      setGetApp(false);
    } else {
      localStorage.setItem("app", "open");
      setGetApp(true);
    }
  }, []);
  const decode = (hex) => {
    var key = process.env.REACT_APP_DECODE_KEY;
    var keyBytes = aesjs.utils.utf8.toBytes(key);
    var encryptedBytes = aesjs.utils.hex.toBytes(hex);
    var aesCtr = new aesjs.ModeOfOperation.ctr(keyBytes, new aesjs.Counter(5));
    var decryptedBytes = aesCtr.decrypt(encryptedBytes);
    var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
    return decryptedText;
  };
  useEffect(() => {
    if (new_cases?.new_cases?.length) {
      // if (user?.enterprise) {
      if (new_cases?.new_cases?.filter((item) => item?.name_en === "business_use_case")?.length) {
        dispatch(
          getBusinessCases(
            decode(
              new_cases?.new_cases
                ?.filter((item) => item?.name_en === "business_use_case")
                ?.slice(0)
                ?.shift()?.use_case
            )
          )
        );
      }
      // }
      dispatch(
        getAllCases(
          decode(
            new_cases?.new_cases
              ?.filter((item) => item?.name_en === "use_case")
              ?.slice(0)
              ?.shift()?.use_case
          )
        )
      );
    }
  }, [new_cases, user]);

  const handleclose = () => {
    localStorage.setItem("promo", true);
    setGetPromo(false);
    setGetApp(false);
  };

  // useEffect(() => {
  //   if (user?.plan === "Trial") {
  //     setPlanPopUp(true);
  //   } else setPlanPopUp(false);
  // }, [user]);

  let languageFromLocal = JSON.parse(localStorage.getItem("language"));
  if (!languageFromLocal) {
    setChangeOfLanguage(changeOfLanguage + 1);
    localStorage.setItem("language", JSON.stringify({ lang: "arab" }));
  }
  useEffect(() => {
    languageFromLocal = JSON.parse(localStorage.getItem("language"));
  }, [changeOfLanguage]);
  useEffect(() => {
    if (languageFromLocal) {
      i18n.changeLanguage(languageFromLocal.lang);
      if (languageFromLocal.lang === "arab") {
        $("body").addClass("arabic_direction");
      } else {
        $("body").addClass("english_direction");
      }
    }
  }, [changeOfLanguage]);

  useEffect(() => {
    for (let i = 0; i < allTextTypes.length; i++) {
      let allElements = document.getElementsByTagName(allTextTypes[i]);
      for (let j = 0; j < allElements.length; j++) {
        if (languageFromLocal.lang === "arab") {
          allElements[j].style.fontFamily = "Tajawal";
        } else {
          allElements[j].style.fontFamily = "Poppins";
        }
      }
    }
  }, []);
  useEffect(() => {
    setRouteArray(location?.pathname?.split("/"));
  }, [location]);

  useEffect(() => {
    setLoadingSwitch(bigLoading);
  }, [bigLoading]);

  useEffect(() => {
    axios.defaults.headers.common["lang"] = JSON.parse(localStorage.getItem("language"))?.lang === "arab" ? "ar" : "en";
    if (tokenFromUrl) {
      localStorage.setItem("sess", tokenFromUrl);
      window.location.replace("/");
    } else if (localStorage.getItem("sess")) {
      axios.defaults.headers.common["Authorization"] = `${localStorage.getItem("sess")}`;
      dispatch(getUser());
      if (user?.enterprise) {
        dispatch(getUserEnterprise());
      } else {
        dispatch(getUserSubscription());
      }
    } else {
      window.location.replace(process.env.REACT_APP_LANDING_URL + "logingOut");
    }
  }, [tokenFromUrl, stylingPoints?.fetchuser, user?.plan]);
  useEffect(() => {
    dispatch(getNewCases());
    dispatch(getMostUsedCases());
  }, []);
  // useEffect(() => {
  // if (user?.enterprise) {
  //   dispatch(getUserEnterprise());
  // } else {
  //   dispatch(getUserSubscription());
  // }
  // }, [user?.enterprise, user?.plan, stylingPoints?.fetchuser]);
  // useEffect(() => {
  //   if (user?.enterprise) {
  //     dispatch(getBusinessCases())
  //   }
  // }, [user]);

  const mainPanel = React.useRef(null);

  useEffect(() => {
    // setPathNameStatus(location.pathname?.split('/')?.length === 4 && location.pathname?.split('/')[1] === "writenow");
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (window.innerWidth < 993 && document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element?.parentNode.removeChild(element);
    }
  }, [location?.pathname, location?.key, history, location]);
  const [claimPopUp, setClaimPopUp] = useState(false);

  const { pathname } = useLocation();

  // useEffect(() => {
  //   const noRightMenus = ['arabygpt'].findIndex((el) => {
  //     return location.pathname.includes(el);
  //   });
  //   setDisplayWidget(noRightMenus === -1);
  // }, [location]);
  // useEffect(() => {
  //   if (user?.plan === 'Pro' || user?.plan === 'Authors' || user?.plan === 'Artist') {
  //   if (customCounter) {
  //     // $(".tawk_arabi_custom").css('visibility', 'hidden')
  //     setTimeout(() => {

  //       setcustomCounterNumbers(customCounterNumbers + 1)
  //       cusBtn.css('background-color', 'transparent')
  //       cusBtn.css('background', '#fff')
  //       cusBtn.css('padding', '3px')
  //       cusBtn.css('box-shadow', '1px 0px 8px #80808052')
  //       if (!cusIframeContents.find('#theImg')?.length) {
  //         cusBtn.find("div[isroundwidget='true']").remove()
  //         cusBtn.prepend(`<img id="theImg" src="${customerService}" />`)
  //         // $(".tawk_arabi_custom").css('visibility', 'visible')
  //       }
  //       if (cusBtn?.length) {
  //         setcustomCounter(false)
  //       }

  //     }, (50))
  //   }
  // }
  // }, [customCounterNumbers, user])
  const handleRegularShare = (text) => {
    toastAlert("Copied", "Code has been copied to clipboard", "success");
  };

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  // useEffect(() => {
  //   if (
  //     user?.plan === "Trial" &&
  //     !user?.offer_claimed &&
  //     user?.user_prefernce?.flag &&
  //     !offerTimerState.isTimeLeft()
  //   ) {
  //     setClaimPopUp(true);
  //   } else setClaimPopUp(false);
  // }, [offerTimerState.seconds]);

  const [pageTitle, setPageTitle] = useState("");

  useEffect(() => {
    setPageTitle(location.pathname.split("/").includes("document") ? "Documents" : null);
  }, [location.pathname]);

  return (
    <>
      {user?.plan === "Trial" && backToSchool && <BackToSchool width={width} t={t} lng={lng} />}
      {/* {width <= 991 && getApp && (isAndroid || isIOS) ? (
          <div className={ lng ==="arab" ? "main_dynamic lng_dir_ar" : "main_dynamic lng_dir"}>
            <div className="dynamic_app container">
              <div className="logo_dynamic">
                <img style={{ width: "35px" }} src={logos} alt="..." width="55px" />
                <label style={{ cursor: "pointer", marginBottom: 0 }}>
                  {t("Get the ")}
                  <b>{t("APP")}</b>
                  {t(" now!")}
                </label>
              </div>
              <div className="get_app"> 
                {isAndroid ? (
                  <a href="https://play.google.com/store/apps/details?id=com.araby.ai">
                    <div style={{ border: "0.5px solid white", display: "flex", alignItems: "center", borderRadius: "5px", padding: "3px 10px" }}>
                      <p>{t("Available on")}</p>
                      <AppleIcon sx={{ fontSize: "12px", color: "white", margin: "0 5px" }} />
                      <img src={playstore} alt="..." width="9px" />
                    </div>
                  </a>
                ) : isIOS ? (
                  <a href="https://apps.apple.com/in/app/araby-ai/id6447341656">
                    <div style={{ border: "0.5px solid white", display: "flex", alignItems: "center", borderRadius: "5px", padding: "3px 10px" }}>
                      <p>{t("Available on")}</p>
                      <AppleIcon sx={{ fontSize: "12px", color: "white", margin: "0 5px" }} />
                      <img src={playstore} alt="..." width="9px" />
                    </div>
                  </a>
                ) : (
                  <a href="">
                    <div style={{ border: "0.5px solid white", display: "flex", alignItems: "center", borderRadius: "5px", padding: "3px 10px" }}>
                      <p>{t("Available on")}</p>
                      <AppleIcon sx={{ fontSize: "12px", color: "white", margin: "0 5px" }} />
                      <img src={playstore} alt="..." width="9px" />
                    </div>
                  </a>
                )}
                <ClearIcon
                  sx={{ fontSize: "15px", color: "white",cursor:"pointer" }}
                  onClick={() => {
                    localStorage.setItem("app", "close");
                    setGetApp(false);
                  }}
                />
              </div>
            </div>
          </div>
        ) : null} */}
      {loadingSwitch ? <LoadingPopUp /> : null}
      {/* <NotFound/> */}
      <div className="wrapper">
        <Sidebar routes={dashboardRoutes} />
        <div
          style={{
            background: "#fff",
            backgroundColor: "#fff",
            width: pageTitle === "Documents" ? (!isOpen?.sidebarIsOpen ? "100%" : "") : null,
            maxWidth: pageTitle === "Documents" ? (!isOpen?.sidebarIsOpen ? "100%" : "") : null,
            transform:
              lng === "arab" ? (width < 991 ? (isOpen?.sidebarIsOpen ? null : "translate3d(-250px, 0, 0)") : null) : width < 991 ? (isOpen?.sidebarIsOpen ? null : "translate3d(250px, 0, 0)") : null,
          }}
          className="main-panel"
          ref={mainPanel}
        >
          <AdminNavbar />

          <div
            className=" radius-border-color"
            onClick={() => {
              if (document.documentElement.classList.toggle("nav-open")) {
                document.documentElement.classList.toggle("nav-open");
              }
            }}
          >
            <Routes location={location.pathname}>
              <Route path="/" element={<Home />} />
              <Route path={"/twwtgpt"} element={<GPTMain />} />
              <Route path={"/twwtgpt/:id"} element={<ArabyGPT />} />
              <Route path={"/writenow"} element={<WriteNow />} />
              <Route path={"/writenow/:name"} element={<SubCategories />} />
              <Route path={"/writenow/:name/:id"} element={<SubCategory />} />
              <Route path={"/photogen"} element={<MediaSection />} />
              <Route path={"/pdf-chat"} element={<ChatDoc />} />
              <Route path={"/photogen/images"} element={<ImagesTools />} />
              <Route path={"/photogen/audios"} element={<AudioTools />} />
              <Route path={"/photogen/videos"} element={<VideoTools />} />
              <Route path={"/photogen/:tools/:name"} element={<ImgGen />} />
              <Route path={"/enhance"} element={<Enhance />} />
              <Route path={"/writecode"} element={<WriteCode />} />
              <Route path={"/writecode/:name"} element={<CodeGen />} />
              <Route path={"/paymentgateway"} element={<PaymentGateWay />} />
              <Route path="/document/:id" element={<Document />} />
              <Route path="/document" element={<Document />} />
              <Route path={"/plan"} element={<Plan />} />
              {/* <Route path={"/hyper/:id"} element={<HyperPay />} /> */}
              <Route path={"/profile"} element={<RevProfile />} />
              <Route path={"/contactus"} element={<ContactUs />} />
              <Route path={"/business-tools"} element={<BusinessTools />} />
              <Route path={"/business-tools/:name"} element={<BusinessTools />} />
              <Route path={"/business-tools/:name/:id"} element={<MarketingPlan />} />
              <Route path={"/business-tools/presentation/56"} element={<Presentation />} />
              <Route path={"slides/:id"} element={<Presentation />} />

              {/* <Route path="*" element={<NotFound/>} /> */}
              {/* {getRoutes(fullNewRoutes)} */}
              {user?.email === "saleemdayazada@gmail.com" ? (
                <>
                  <Route path={"/blog"} element={<Blog />} />
                  <Route path={"/blog/:name"} element={<AddBlog />} />
                  <Route path={"/blog/:name/:id"} element={<UpdateBlog />} />
                </>
              ) : null}

              <Route path={"/history"} element={<History />} />
              <Route path={"/history/:name"} element={<History />} />
              <Route path={"/history/:name/:id"} element={<History element />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </div>
        </div>
      </div>
      <EnterpriseInvitation setShowModal={setShowSeconModal} showModal={showSecondModal} />
      {/* <PlansPopUp showModal={planPopUp} setShowModal={setPlanPopUp} /> */}

      {/* <ClaimFreeModal showModal={claimPopUp} setShowModal={setClaimPopUp} /> */}
    </>
  );
}

export default Admin;

const EnterpriseInvitation = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const user = useSelector((state) => state.user);
  const [loadingSwitchAccept, setLoadingSwitchAccept] = useState(false);
  const [loadingSwitchReject, setLoadingSwitchReject] = useState(false);
  const dispatch = useDispatch();
  const invitationResponse = (res) => {
    if (res) {
      setLoadingSwitchAccept(true);
    } else {
      setLoadingSwitchReject(true);
    }
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_ENTERPRISE_URL}enterprise/answer-invite`,
      headers: {
        Authorization: localStorage.getItem("sess"),
      },
      data: { select: res },
    };
    axios(config)
      .then(function (response) {
        toastAlert("Thanks!", undefined, "success");
        dispatch(getUser());
        if (res) {
          setLoadingSwitchAccept(true);
        } else {
          setLoadingSwitchReject(true);
        }
      })
      .catch(function (error) {
        checkErr(error, res ? setLoadingSwitchAccept : setLoadingSwitchReject, toastAlert);
      });
  };
  return (
    <Modal
      className={lng === "arab" ? "modal modal-primary arabicFont thank-you-modal" : "modal modal-primary englishFont thank-you-modal"}
      show={props.showModal}
      centered
      onHide={() => props.setShowModal(false)}
    >
      <Modal.Header>
        <button onClick={() => props.setShowModal(false)} className="btn-close">
          <i className="nc-icon nc-simple-remove"></i>
        </button>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="row justify-content-center">
          <img style={{ maxHeight: "250px" }} className="modalImgWrapper" src={OopsSvg} />
        </div>
        <h3 style={{ margin: "0px", lineHeight: "40px", display: "flex", flexDirection: "column" }}>
          {lng === "arab" ? (
            <>
              <span>لقد قام</span>
              <span>{user?.inviteBy}</span>
              <span>بدعوتك للانضمام إلى فريقه</span>
            </>
          ) : (
            <>
              <span>{user?.inviteBy}</span>
              <span>Has invited you to join his team</span>
            </>
          )}
        </h3>
        <p>{t("Joining this team will upgrade your existing subscription to a business subscription")}</p>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px", width: "100%" }}>
          <button
            onClick={() => invitationResponse(true)}
            disabled={loadingSwitchAccept || loadingSwitchReject}
            style={{ background: "linear-gradient(to right, #d851ef, #1877f2)" }}
            className="btn common-btn-modal"
          >
            {loadingSwitchAccept ? <LoadingEffect /> : t("Accept")}
          </button>
          <button
            onClick={() => invitationResponse(false)}
            disabled={loadingSwitchReject || loadingSwitchAccept}
            style={{ background: "linear-gradient(270deg, #151723, #23273a)" }}
            className="btn common-btn-modal"
          >
            {loadingSwitchReject ? <LoadingEffect /> : t("Reject")}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
