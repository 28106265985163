import html2pdf from "html2pdf.js";
import { toastAlert } from "components/toastAlert/toastAlert";

const downloadPdf = (quillRef, docName, lng) => {
  if (quillRef.current.getEditor().getText().length === 1) {
    toastAlert("Nothing to Download", "error");
    return;
  }
  if (quillRef.current) {
    const content = quillRef.current.editor.root.innerHTML;
    const opt = {
      margin: 5,
      filename: `${docName} Twwt.ai .pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      setDefaultFont: "aealarabiya",
    };

    html2pdf().from(content).set(opt).save();
  }
};

export default downloadPdf;
