import React, { useState, useRef, useEffect } from "react";
import { Box, Grid, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toastAlert } from "components/toastAlert/toastAlert";
import { useLocation } from "react-router-dom";
import { socket } from "../../socket";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import UpgradeModal from "components/UpgradeModal/UpgradeModal";
import ReactQuill from "react-quill";
import useId from "react-use-uuid";
import EditorToolbar, { modules, formats } from "./Tools/EditorToolbar";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { ReactComponent as Ai } from "../../assets/img/airobo.svg";
import Header from "./Tools/Header";
import KeyboardTabRoundedIcon from "@mui/icons-material/KeyboardTabRounded";
import AiTools from "./Tools/AiTools";
import StylesButton from "./Tools/StylesButton";
import history from "./Functions/history";
import replaceText from "./Functions/replaceText";
import save from "./Functions/save";
import ToolTip1 from "./Tooltips/ToolTip1";
import "draft-js/dist/Draft.css";
import "./WriteDocument.css";
import "react-quill/dist/quill.snow.css";
import Upgrade from "./Modals/Upgrade";
import LimitPopUp from "views/plansComponent/LimitPopUp";
const WriteDocument = () => {
  const params = useParams();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const isOpen = useSelector((state) => state.sidebar);
  const refId = useId();
  const quillRef = useRef();
  const location = useLocation();
  const dispatch = useDispatch();
  const [languageInput, setLanguageInput] = useState("English");
  const [content, setContent] = useState("");
  const [docName, setDocName] = useState("untitled");
  const [aiWindow, setAiWindow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [selectedText, setSelectedText] = useState("");
  const [editorText, setEditorText] = useState("");
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [modification, setModification] = useState("");
  const [aiToolsHistory, setAiToolsHistory] = useState([]);
  const [aiToolsText, setAiToolsText] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAiTools, setShowAiTools] = useState(false);
  const [boundingRect, setBoundingRect] = useState("");
  const [undoText, setUndoText] = useState("");
  const [streaming, setStreaming] = useState(false);
  const [shake, setShake] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [count, setCount] = useState(0);
  const [show, setShow] = useState(false);
  const [wordCount, setWordCount] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const handleKeyDown = () => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    const newTypingTimeout = setTimeout(() => {
      save(quillRef, params, docName, refId);
    }, 1000);
    setTypingTimeout(newTypingTimeout);
  };

  useEffect(() => {
    socket.connect();
    return () => {
      socket.disconnect();
      setAiToolsHistory([]);
      setAiToolsText("");
      dispatch({ type: "FETCH_USER" });
    };
  }, [location.pathname]);

  useEffect(() => {
    let token = { token: localStorage.getItem("sess") };
    if (socket.connected) {
      socket.emit("token", token);
    }
  }, [socket.connected]);

  useEffect(() => {
    if (lng === "arab") {
      quillRef.current.getEditor().root.style.fontFamily = "Tajawal";
      quillRef.current.getEditor().root.style.textAlign = "right";
      quillRef.current.getEditor().root.style.direction = "rtl";
      setLanguageInput("Arabic");
    }
    function onConnect() {
      setIsConnected(true);
    }
    function onDisconnect() {
      setIsConnected(false);
    }
    if (user.plan === "Starter" || user.plan === "Trial") {
      setShow(true);
    }
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
  }, []);

  const shakeTheScreen = () => {
    if (localStorage.getItem("shake") == "true") {
      setShake(false);
    } else if (
      editorText.split(/ (?!\s)/).filter((word) => word !== "").length == 5 &&
      localStorage.getItem("shake") == undefined
    ) {
      localStorage.setItem("shake", true);
      setShake(true);
    }
  };

  useEffect(() => {
    shakeTheScreen();
  }, [content]);

  useEffect(() => {
    history(params, setDocName, setContent, setLoading, setEditorText);
  }, [params?.id]);

  function highlightText(newRange) {
    const editor = quillRef.current.getEditor();
    const selectedTex = editor.getSelection();
    const selectedText = editor.getText(
      selectedTex?.index,
      selectedTex?.length
    );
    selectedFirst(selectedText);
    setSelectedText(selectedText);
    var highlightElement = document.getElementsByClassName("toolbar")[0];
    if (newRange && newRange.length > 0) {
      var range = document.createRange();
      var selection = window.getSelection();
      selection.addRange(range);
      var highlightedRange = selection.getRangeAt(0);
      var boundingRect = highlightedRange.getBoundingClientRect();
      setBoundingRect(boundingRect.top);
      highlightElement.style.position = "absolute";
      highlightElement.style.top =
        boundingRect.top > 530
          ? boundingRect.top - 135 + "px"
          : boundingRect.top - 40 + "px";
      let leftValue;

      if (lng === "arab") {
        const maxLeft = boundingRect.left + boundingRect.width - 250;
        if (width < 991) {
          leftValue = boundingRect.left + "px";
          leftValue = Math.min(maxLeft, boundingRect.left) + "px";
        } else if (isOpen.sidebarIsOpen) {
          leftValue = boundingRect.left + "px";
          leftValue = Math.min(maxLeft, boundingRect.left) + "px";
        } else {
          leftValue = boundingRect.left + "px";
          leftValue = Math.max(maxLeft, boundingRect.left) - 250 + "px";
        }
      } else {
        if (width < 991) {
          leftValue = boundingRect.left + "px";
        } else if (isOpen.sidebarIsOpen) {
          leftValue = boundingRect.left - 250 + "px";
          // const maxLeft = boundingRect.left + boundingRect.width - 250; // Maximum allowed left value
          // leftValue = Math.min(maxLeft, boundingRect.left) + "px";
        } else {
          leftValue = boundingRect.left + "px";
        }
      }

      highlightElement.style.left = leftValue;
      highlightElement.style.display = "flex";
      highlightElement.style.zIndex = "0";
    } else {
      highlightElement.style.display = "none";
      setShowAiTools(false);
    }
  }
  const selectedFirst = (selected) => {
    if (aiToolsHistory.length <= 0 && aiToolsText.length <= 0) {
      if (selected) {
        setUndoText(selected);
      } else return;
    }
  };

  const handleChange = (value) => {
    setContent(value);
    const editor = quillRef.current.getEditor();
    let word = editor
      .getText()
      .split(/ (?!\s)/)
      .filter((word) => word !== "").length;

    setWordCount(word);

    const selectedTex = editor.getSelection();
    if (selectedTex) {
      const selectedText = editor.getText(
        selectedTex.index,
        selectedTex.length
      );
      const editText = editor.getText();
      setEditorText(editText);
      setSelectedText(selectedText);
    } else {
      const editText = editor.getText();
      // console.log(editText);
      setEditorText(editText);
    }
  };

  useEffect(() => {
    if (user?.plan === "Trial" || user?.plan === "Starter") {
      setShow(true);
    }
  }, [user]);

  const handleSubmit = async (words) => {
    if (user.text_count_left === 0) {
      return setShowModal(true);
    }
    const editor = quillRef.current.getEditor().getText();
    setEditorText(editor);

    if (aiToolsText.length) {
      let text = aiToolsText;
      setAiToolsHistory([...aiToolsHistory, text]);
      setAiToolsText("");
    }
    if (content === "") {
      toastAlert(t("Please write something to generate"), "error");
      return;
    }
    if (selectedText) {
      if (selectedText.split(" ").length < 5) {
        toastAlert(t("Please select more than 5 words to generate"), "error");
        return;
      }
    }
    var length = words;
    setModification(words);
    let request = {
      token: localStorage.getItem("sess"),
      length: typeof length === "number" ? length : null,
      modification: typeof length === "number" ? "complete" : length,
      phrase: selectedText ? selectedText : editor,
      language: languageInput,
      ref_id: params?.id ? params?.id : refId,
      action: "generate_document_section",
    };
    if (isConnected) {
      // console.log("request", request);
      socket.emit("request", request, () => {});
    }
    setSubmit(false);
  };

  let text = "";
  let combine = "";
  useEffect(() => {
    const onMessagesEvent = async (value) => {
      const editor = quillRef.current.getEditor();
      const selectedTex = editor.getSelection();
      if (value?.data?.streaming == true) {
        setStreaming(true);
        if (value?.data?.message?.content) {
          if (
            typeof modification == "number" ||
            !modification ||
            modification == "complete"
          ) {
            if (selectedTex) {
              editor.insertText(
                selectedTex.index,
                value?.data?.message?.content
              );
              setEditorText(value?.data?.message?.content);
            } else {
              editor.insertText(
                quillRef.current.getEditor().getText().length - 1,
                value?.data?.message?.content
              );
              setEditorText(value?.data?.message?.content);
            }
          } else {
            text = value?.data?.message?.content;
            combine = combine + text;
            setAiToolsText(combine);
            setAiWindow(true);
            return;
          }
        }
      } else {
        setStreaming(false);
        setModification("");
      }
    };

    socket.on("data", onMessagesEvent);
    return () => {
      socket.off("data", onMessagesEvent);
    };
  }, [modification]);

  const handleScroll = (e) => {
    var highlightElement = document.getElementsByClassName("toolbar")[0];
    if (selectedText) {
      highlightElement.style.display = "none";
      setShowAiTools(false);
    }
  };

  const handleUndo = () => {
    if (count !== 0) {
      quillRef.current.getEditor().history.undo();
      setAiToolsHistory([]), setAiToolsText("");
      setAiWindow(false);
      setCount(0);
    } else {
      setCount(0);
    }
  };

  return (
    <Box
      id="scrolling-container"
      style={{ direction: lng === "arab" ? "rtl" : "ltr" }}
      className="main_case"
    >
      <Header
        docName={docName}
        quillRef={quillRef}
        setIsEdit={setIsEdit}
        setDocName={setDocName}
        isEdit={isEdit}
        refId={refId}
      />
      {streaming && <div class="animated-gradient"></div>}
      <Box width={"100%"} sx={{ display: "flex", position: "relative" }}>
        <Grid m={"auto"} height={"100%"} width={width < 991 ? "95%" : "80%"}>
          <Box
            p={2}
            className="text-editor"
            sx={{
              position: "sticky",
              top: "15px",
              zIndex: 2,
            }}
          >
            {(aiToolsText.length > 0 || aiToolsHistory.length > 0) && (
              <div
                onClick={() =>
                  aiWindow && !streaming ? null : setAiWindow(true)
                }
                className="ai-window"
                style={{
                  right: lng === "arab" ? null : "20px",
                  left: lng === "arab" ? "20px" : null,
                  top: width < 991 ? "144px" : null,
                  maxWidth: width < 991 ? "50%" : "21%",
                  paddingBottom: !aiWindow ? "10px" : null,
                  maxHeight: width < 991 ? "65%" : "85%",
                }}
              >
                <div
                  className="ai_tools_opener"
                  onClick={() => !streaming && setAiWindow(!aiWindow)}
                >
                  <KeyboardTabRoundedIcon
                    style={{
                      transform:
                        lng !== "arab" && aiWindow
                          ? "rotate(0deg)"
                          : "rotate(180deg)"
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                    }}
                  />
                </div>
                {aiWindow && (aiToolsText || aiToolsHistory.length) && (
                  <>
                    {aiToolsText && (
                      <Tooltip
                        title={t("Tap to insert text")}
                        placement="left"
                        arrow
                      >
                        <button
                          disabled={streaming}
                          className="ai-window-response"
                          onClick={() => {
                            replaceText(quillRef, aiToolsText, aiToolsHistory);
                            setCount(count + 1);
                          }}
                          style={{
                            textAlign: lng === "arab" ? "right" : "left",
                          }}
                        >
                          {aiToolsText}
                        </button>
                      </Tooltip>
                    )}
                    {aiToolsHistory
                      .slice(0)
                      .reverse()
                      .map((text, key) => {
                        return (
                          <Tooltip
                            title={t("Tap to insert text")}
                            placement="left"
                            arrow
                          >
                            <button
                              disabled={streaming}
                              key={key}
                              className="ai-window-response"
                              onClick={() => {
                                replaceText(
                                  quillRef,
                                  aiToolsText,
                                  aiToolsHistory,
                                  key
                                );
                                setCount(count + 1);
                              }}
                              style={{
                                textAlign: lng === "arab" ? "right" : "left",
                              }}
                            >
                              {text}
                            </button>
                          </Tooltip>
                        );
                      })}
                    <div className="ai-window-buttons">
                      <button
                        style={{ width: "100%", borderRadius: "10px" }}
                        disabled={streaming}
                        onClick={() => setAiWindow(false)}
                      >
                        {t("Close")}
                      </button>
                      <button
                        disabled={streaming || !selectedText}
                        style={{
                          width: "100%",
                          background: "white",
                          color: "black",
                          borderRadius: "10px",
                          border: "1px solid #ecece2",
                        }}
                        onClick={handleUndo}
                      >
                        {t("Undo")}
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}
            <div style={{ position: "relative" }}>
              <EditorToolbar
                content={content}
                setContent={setContent}
                submit={handleSubmit}
                close={setShowAiTools}
                boundingRect={boundingRect}
                width={width}
                selectedText={selectedText}
                quillRef={quillRef}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                marginTop: "15px",
              }}
            >
              {!submit && (
                <div
                  className="write-me"
                  style={{
                    flexDirection: "column",
                    gap: "10px",
                    position: "relative",
                  }}
                >
                  <div
                    className="write-me-div"
                    style={{ position: "relative" }}
                  >
                    <button
                      disabled={streaming || wordCount < 4}
                      className={shake ? "button_write" : null}
                      onClick={() => {
                        setSubmit(true);
                      }}
                      style={{
                        fontSize: width < 991 ? "15px" : null,
                        background: wordCount < 4 ? "grey" : null,
                      }}
                    >
                      {t("Continue writing")}
                    </button>
                    <select
                      style={{ fontSize: width < 991 ? "15px" : null }}
                      disabled={streaming}
                      value={languageInput}
                      onChange={(e) => setLanguageInput(e.target.value)}
                    >
                      <option value="English">English</option>
                      <option value="Arabic">Arabic</option>
                    </select>
                    <ToolTip1 />
                  </div>
                  {wordCount < 4 && (
                    <>
                      <p style={{ color: "grey", fontSize: "0.8rem" }}>
                        {t("Write at least")} {4 - wordCount}{" "}
                        {t("words to continue")}
                      </p>
                      <div
                        className="progress-document"
                        style={{
                          width:
                            wordCount === 0
                              ? "0%"
                              : wordCount === 1
                              ? "25%"
                              : wordCount === 2
                              ? "55%"
                              : wordCount === 3
                              ? "75%"
                              : "95%",
                        }}
                      ></div>
                    </>
                  )}
                </div>
              )}
              {submit && (
                <div className="write-me ">
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      fontSize: width < 500 ? "12px" : null,
                    }}
                  >
                    <ArrowBackIosRoundedIcon
                      onClick={() => setSubmit(false)}
                      sx={{
                        fontSize: "20px",
                        cursor: "pointer",
                        transform: lng === "arab" ? "rotate(180deg)" : null,
                      }}
                    />
                    {t("Output length")}
                  </p>
                  <div className="div">
                    <button
                      disabled={streaming}
                      style={{
                        color: "black",
                        fontSize: width < 991 ? "12px" : null,
                      }}
                      onClick={() => handleSubmit(50)}
                    >
                      {t("Short")}
                    </button>
                    <button
                      disabled={streaming}
                      style={{
                        color: "black",
                        fontSize: width < 991 ? "12px" : null,
                      }}
                      onClick={() => handleSubmit(200)}
                    >
                      {t("Medium")}
                    </button>
                    <button
                      disabled={streaming}
                      style={{
                        color: "black",
                        fontSize: width < 991 ? "12px" : null,
                      }}
                      onClick={() => handleSubmit(500)}
                    >
                      {t("Long")}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Box>
          <div
            onScroll={handleScroll}
            id="scrolling-container"
            style={{
              overflowY: "auto",
              height:
                width < 991 ? "calc(100vh - 197px)" : "calc(100vh - 232px)",
            }}
          >
            {loading && (
              <Spinner
                style={{ position: "absolute", top: "50%", left: "50%" }}
                animation="border"
                role="status"
              />
            )}
            <ReactQuill
              style={{
                margin: "auto",
                width: width < 991 ? "90%" : "100%",
                pointerEvents: streaming ? "none" : null,
              }}
              ref={quillRef}
              value={content}
              theme="snow"
              onKeyPress={handleKeyDown}
              onChange={handleChange}
              placeholder={
                lng === "arab"
                  ? "ما الذي تريد الكتابة عنه؟"
                  : "Write few words to start..."
              }
              modules={
                ({
                  clipboard: {
                    matchers: ["del, strike", "strikethrough"],
                  },
                },
                modules)
              }
              scrollingContainer={"#scrolling-container"}
              formats={formats}
              onChangeSelection={highlightText}
            />
            <div
              className="toolbar"
              style={{
                borderRadius: "5px",
                border: "0.5px solid #D3D3D3",
                background: "#FFF",
                boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.08)",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0px 10px",
                gap: "30px",
                display: "none",
              }}
            >
              <div
                onClick={() =>
                  streaming ? null : setShowAiTools(!showAiTools)
                }
                className="aiTools"
                style={{ cursor: "pointer", position: "relative" }}
                id="aiTools"
              >
                <Ai />
                <button
                  disabled={streaming}
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <b>{t("Ai Tools")}</b>
                </button>

                <KeyboardArrowDownRoundedIcon
                  style={{
                    color: "#3CC8EB",
                    borderRadius: "5px",
                    background: "#F4FCFF",
                    transform:
                      boundingRect > 530 || showAiTools
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                    transition: "all 0.3s ease-in-out",
                  }}
                />
                {showAiTools && (
                  <AiTools
                    submit={handleSubmit}
                    close={setShowAiTools}
                    boundingRect={boundingRect}
                    width={width}
                  />
                )}
              </div>
              {width > 991 && <StylesButton quillRef={quillRef} />}
            </div>
          </div>
        </Grid>
      </Box>
      {user?.plan === "Trial" ? (
        <UpgradeModal setShowModal={setShowModal} showModal={showModal} />
      ) : (
        <LimitPopUp setShowModal={setShowModal} showModal={showModal} />
      )}
      <Upgrade show={show} setShow={setShow} width={width} />
    </Box>
  );
};

export default WriteDocument;
