
export default (chatHistory = { chatHistory: [], loading: true }, action) => {
    switch (action.type) {
        case "FETCH_ALL_CHAT_HISTORY":
            return { ...chatHistory, chatHistory: action.data, loading: false };
        case "LOAD_ALL_CHAT_HISTORY":
            return { ...chatHistory, loading: true };
        case "ALL_CHAT_HISTORY_LOADED":
            return { ...chatHistory, loading: false };
        default:
            return chatHistory;
    }
}