import React from 'react'
import './LoadingEffect.css'

const LoadingEffect = () => {
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;

  return (
    <>
    {lng === 'arab'?
        <div className="wavy">
            <span style={{'--i': '1'}}>ير</span>
            <span style={{'--i': '2'}}>جى</span>
            <span style={{'--i': '3'}}> </span>
            <span style={{'--i': '4'}}>ا</span>
            <span style={{'--i': '5'}}>لا</span>
            <span style={{'--i': '6'}}>نتظا</span>
            <span style={{'--i': '7'}}>ر</span>
            <span style={{'--i': '8'}}>.</span>
            <span style={{'--i': '9'}}>.</span>
        </div>: 
        <div className="wavy">
            <span style={{'--i': '1'}}>L</span>
            <span style={{'--i': '2'}}>o</span>
            <span style={{'--i': '3'}}>a</span>
            <span style={{'--i': '4'}}>d</span>
            <span style={{'--i': '5'}}>i</span>
            <span style={{'--i': '6'}}>n</span>
            <span style={{'--i': '7'}}>g</span>
            <span style={{'--i': '8'}}>.</span>
            <span style={{'--i': '9'}}>.</span>
        </div>}
    </>
  )
}

export default LoadingEffect