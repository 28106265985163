
export default (business_output = { business_output: [] }, action) => {
    switch (action.type) {
        case "ADD_MESSAGE_BUSINESS":
            return { ...business_output, business_output: [...business_output.business_output, action?.data] };
        case "REMOVE_ALL_BUSINESS":
            return { ...business_output, business_output: [] };
        default:
            return business_output;
    }
}