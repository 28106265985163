import axios from "axios";
import { baseUrl } from "api/baseUrl";

const history = (params, setDocName, setContent, setLoading, setEditorText) => {
  if (!params?.id) {
    setDocName("untitled");
    setContent("");
    return;
  }
  setLoading(true);
  axios
    .get(`${baseUrl}user/history/document/${params.id}`)
    .then((res) => {
      let text = res.data.data.text;
      setContent(text);
      setDocName(res.data.data.title);
      setEditorText(res.data.data.text);
      setLoading(false);
    })
    .catch((err) => {
      // console.log(err);
    });
};

export default history;
