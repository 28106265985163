import React, { useState, useEffect } from "react";
import { toastAlert } from "components/toastAlert/toastAlert";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import UpgradeModal from "components/UpgradeModal/UpgradeModal";
import "views/MediaSection/ImageTools/Create/ImgGen.css";
import LoadingEffect from "components/Loading/LoadingEffect";
import UpgradePlanModal from "components/UpgradeModal/UpgradePlanModal";
import { handleTextToVideo } from "redux/actions/media_section";
import LimitPopUp from "views/plansComponent/LimitPopUp";
import image_en from "assets/img/gif_en/Txt to Video-min (1).gif";
import image_ar from "assets/img/gif_ar/Txt-to-video-AR-01 (1).gif";
import ReplayIcon from "@mui/icons-material/Replay";

const TextToVideo = () => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const textToVideo = useSelector((state) => state.text_to_video);
  const [showModal, setShowModal] = useState(false);
  const [input, setInput] = useState("");
  const [totalImages, setTotalImages] = useState(0);
  const [show, setShow] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  useEffect(() => {
    setTotalImages(user?.image_count_left);
    if (user?.plan === "Trial" || user?.plan === "Starter") {
      return setShow(true);
    }
  }, [user]);

  const handleDownload = () => {
    const fileUrl = textToVideo?.textToVideo?.data?.urls[0];
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = "hello"; // Specify the file name here
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleText = async (e) => {
    e.preventDefault();
    if (totalImages === 0) {
      dispatch({ type: "SHOW_TEXT_VIDEO_FALSE" });
      return setShowModal(true);
    } else if (input === "") {
      return toastAlert(t("Please enter a valid text"), undefined, "warning");
    } else {
      let data = {
        prompt: input,
      };
      dispatch(handleTextToVideo(data));
    }
  };

  return (
    <div className="image_gen_header" style={{ padding: "40px 15px 0" }}>
      {textToVideo?.textToVideo?.length === 0 && (
        <>
          <h4>{t("Create videos instantly, and bring your ideas to life in mesmerizing motion.")}</h4>
          <p style={{ fontSize: "1rem" }}>{t("Step into a world where words come alive through AI, effortlessly crafting text-to-video creations that captivate and inspire.")}</p>
        </>
      )}
      {textToVideo?.textToVideo?.length === 0 ? (
        <form onSubmit={handleText} className="inpainting_input text_to_video">
          <input type="textarea" value={input} disabled={textToVideo?.loading} onChange={(e) => setInput(e.target.value)} placeholder={t("Describe Your Video")} />
          <button
            disabled={textToVideo?.loading}
            style={{
              padding: "15px 40px !important",
              background: textToVideo?.loading ? "gray" : "linear-gradient(45deg, rgba(17, 113, 216, 1) 0%, rgba(60, 200, 235, 1) 100%)",
              width: "170px",
              minHeight: "46px",
              borderRadius: "15px",
              alignSelf: "center",
              pointerEvents: textToVideo?.loading && "none",
            }}
            type="submit"
            className="form_buttions"
          >
            {textToVideo?.loading ? <LoadingEffect /> : t("Create Video")}
          </button>
          <img
            src={lng === "arab" ? image_ar : image_en}
            style={{
              marginTop: "30px",
              maxWidth: "80%",
              borderRadius: "10px",
            }}
            alt="text to video"
          />
        </form>
      ) : (
        <div style={{ width: "90%", margin: "auto" }}>
          <video src={textToVideo?.textToVideo?.data?.urls[0]} controls style={{ width: "100%", borderRadius: "20px" }} />
          <div className="beforeafter-gray">
            <button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                fontSize: "medium",
              }}
              className="download-btn"
              onClick={handleDownload}
            >
              {t("Download")}
              <i
                className="fas fa-arrow-down"
                style={{
                  fontSize: "medium",
                  margin: lng === "arab" ? " 0 5px 0 0" : "0 0 0 5px",
                }}
              ></i>
            </button>
            <button
              className="newupload"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                fontSize: "medium",
              }}
              onClick={() => {
                setInput("");
                dispatch({ type: "NEW_TEXT_VIDEO" });
              }}
            >
              {t("Try Again")}
              <ReplayIcon
                sx={{
                  transform: "scale(-1)",
                  margin: "0 10px",
                  fontSize: "20px",
                }}
              />
            </button>
          </div>
        </div>
      )}
      <div style={{ width: "90%", margin: "auto" }}></div>
      {user?.plan === "Trial" ? <UpgradeModal images={true} setShowModal={setShowModal} showModal={showModal} /> : <LimitPopUp setShowModal={setShowModal} showModal={showModal} />}
      <UpgradePlanModal show={show} setShow={setShow} width={width} />
    </div>
  );
};

export default TextToVideo;
