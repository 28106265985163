import { toastAlert } from "components/toastAlert/toastAlert";

const replaceText = (quillRef, aiToolsText, aiToolsHistory, key) => {
  const editor = quillRef.current.getEditor();
  const selectedTex = editor.getSelection();
  if (selectedTex === null || !selectedTex) {
    toastAlert("Please select text to replace");
    return;
  }
  if (key === undefined) {
    let text = aiToolsText;
    const combinedText = text;
    editor.deleteText(selectedTex?.index, selectedTex?.length);
    editor.insertText(selectedTex?.index, combinedText);
    editor.setSelection(selectedTex?.index, combinedText.length);
  } else {
    key = aiToolsHistory.length - key - 1;
    let repl = aiToolsHistory[key];
    editor.deleteText(selectedTex?.index, selectedTex?.length);
    editor.insertText(selectedTex?.index, repl);
    editor.setSelection(selectedTex?.index, repl.length);
  }
};

export default replaceText;
