const { Modal } = require("react-bootstrap");
const { useTranslation } = require("react-i18next");

const SurePop = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  return (
    <Modal {...props} size="ms" centered className="are_u_sure_model">
      <Modal.Body style={{ direction: lng === "arab" ? "rtl" : "ltr", fontFamily: lng === "arab" ? "Tajawal" : "Poppins" }} className="forceArabicFont">
        <h4 style={{ marginBottom: "0px", marginTop: "14px", textAlign: lng === "arab" ? "right" : "left" }}>{t("Are you sure?")}</h4>
        <label style={{ textAlign: lng === "arab" ? "right" : "left" }}>{t("By clicking yes your current plan will be imediatly canceled, And you will lose all your limits")}</label>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", gap: "10px" }}>
          <button
            style={{ borderRadius: "12px", padding: "5px 20px" }}
            className="newupload"
            onClick={() => {
              props.onsuccess(props.selecteduser);
              props.onHide();
            }}
          >
            {t("Yes")}
          </button>
          <button style={{ borderRadius: "12px", padding: "5px 20px" }} className="download-btn" onClick={() => props.onHide()}>
            {t("No")}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SurePop;
