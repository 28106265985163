import React, { useState } from "react";
import "./Popup.scss";
import { useTranslation } from "react-i18next";
import visa from "../../assets/img/visa.png";

function Popup() {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button onClick={togglePopup}>Open Popup</button>
      {isOpen && (
        <div className="popup">
          <div
            className="popup-inner"
            style={{ textAlign: lng === "arab" ? "right" : "left" }}
          >
            <div className="popuphead row">
              <div
                className="col-12"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <h4
                  style={{
                    margin: "0 auto",
                    fontWeight: 500,
                    fontSize: "17px",
                    color:'black',
                    textTransform:'uppercase'
                  }}
                >
                  {t("Payment")}
                </h4>
                <button
                  onClick={togglePopup}
                  style={{ border: "none", background: "none" }}
                >
                  X
                </button>
              </div>
            </div>
            <hr style={{ marginTop: "7px" }} />
            <div className="row">
              <div className="col-12">
                <span style={{fontSize:'14px',fontWeight:'100',color:'#53626B'}}>{t("COMBO")}:</span>
              </div>
              <div className="col-12">
                <span
                  style={{
                    fontSize: "37px",
                    fontWeight: 500,
                    color: "#1A82DC",
                  }}
                >
                  {t("BASIC PLAN")}
                </span>
              </div>
              <div className="col-12">
                <span style={{ fontSize: "64px", fontWeight: '500',marginTop: '-14px' }}>$9.99</span>
              </div>
              <div className="col-12" style={{marginTop: '-12px'}}>
                <span style={{ fontSize: "14px",color:"#53626B",fontWeight:'regular',textTransform:'capitalize' }}>{t("Per Month")}</span>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12" style={{ marginBottom: "10px" }}>
                <span style={{fontSize:'14px',fontWeight:'100',color:'#53626B'}}>{t("PAYMENT DETAIL")}:</span>
              </div>
              <div className="col-12">
                <input
                  type="text"
                  placeholder={t("Email")}
                  style={{
                    border: "none",
                    background: "#f7f7f7",
                    borderRadius: "5px",
                    padding: "9px 15px",
                    width: "100%",
                    fontSize:'14px',
                    fontWeight:'100',
                    color:'black'
        
                  }}
                />
              </div>
              <div
                className="col-12"
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                <span style={{ fontSize: "11px",color:'#53626B',fontWeight:'100' }}>
                  {t("Card Information")}
                </span>
              </div>
              <div className="col-12" style={{position: 'relative'}}>
                <img src={visa} alt='visa-icon' style={{height:'30px',position: 'absolute',
    right: '26px',
    top: '11px'}}/>
                <input
                  type="text"
                  placeholder="1234 1234 1234 1234"
                  style={{
                    border: "none",
                    background: "#f7f7f7",
                    borderRadius: "5px",
                    padding: "15px 15px",
                    width: "100%",
                    fontSize:'14px',
                    fontWeight:'100',
                    color:'black'
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col-6"
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                <input
                  type="text"
                  placeholder="MM/YY"
                  style={{
                    border: "none",
                    background: "#f7f7f7",
                    borderRadius: "5px",
                    padding: "15px 15px",
                    width: "100%",
                    fontSize:'14px',
                    fontWeight:'100',
                    color:'black'
                  }}
                />
              </div>
              <div
                className="col-6"
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                <input
                  type="text"
                  placeholder="CSV"
                  style={{
                    border: "none",
                    background: "#f7f7f7",
                    borderRadius: "5px",
                    padding: "15px 15px",
                    width: "100%",
                    fontSize:'14px',
                    fontWeight:'100',
                    color:'black'
                  }}
                />
              </div>

              <div
                className="col-12"
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                <span style={{ fontSize: "11px",color:'#53626B',fontWeight:'100' }}>{t("Name on card")}</span>
                <input
                  type="text"
                  placeholder=""
                  style={{
                    border: "none",
                    background: "#f7f7f7",
                    borderRadius: "5px",
                    padding: "15px 15px",
                    width: "100%",
                    fontSize:'14px',
                    fontWeight:'100',
                    color:'black'
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col-12"
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                <p style={{ fontSize: "11px",color:'#53626B',fontWeight:'100' }}>{t("Country or region")}</p>
                <select
                  style={{
                    border: "none",
                    background: "#f7f7f7",
                    borderRadius: "5px",
                    padding: "9px 15px",
                    width: "100%",
                    fontSize:'14px',
                    fontWeight:'100',
                    color:'black'
                  }}
                >
                  <option>United Arab Emirates</option>
                  <option>Saudi Arabia</option>
                  <option>India</option>
                  <option>Pakistan</option>
                </select>
                <div className="row">
                  <div className="col-12">
                    <button className="subscribe-btn">{t("Subscribe")}</button>
                  </div>
                  <div className="col-12">
                    <p style={{fontSize:'10px',textAlign:'center'}}>
                      By confirming your subscription, you allow twwt.Ai to
                      charge your card for this payment and future payments in
                      accordance with their team. You can always cancel your
                      subscription.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Popup;
