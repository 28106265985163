import { combineReducers } from "redux";
import cases from "./cases";
import Images from "./Images";
import styling from "./styling";
import user from "./user";
import userSubscription from "./user_subscription";
import history from "./history";
import loading from "./loading/loading";
import arabyGPT from "./arabyGPT";
import chatHistory from "./chatHistory";
import enterprise from "./enterprise";
import blogs from "./blog";
import business_cases from "./business_cases";
import newChatHolder from "./newChat";
import new_cases from "./newCases";
import business_output from "./businessOutput";
import presentation_history from "./presentationReducer";
import sidebar from "./sidemenu";
import scribble from "./media_section/scribble";
import background_remover from "./media_section/background_remover";
import background_changer from "./media_section/background_changer";
import redesign from "./media_section/redesign";
import text_to_audio from "./media_section/text_to_audio";
import text_to_speech from "./media_section/text_to_speech";
import clip_audio from "./media_section/clip_audio";
import text_to_video from "./media_section/text_to_video";
import remove_video_background from "./media_section/remove_video_background";
import reimagine_v2 from "./media_section/reimagine_v2";
import upscale from "./media_section/upscale";
import enhance from "./media_section/enhance";
import upscaleV1 from "./media_section/upscaleV1";
import headShot from "./media_section/headshot";
import infinite_zoom from "./media_section/infinite_zoom";
import overlap_animation from "./media_section/overlap-animation";
import enhance_audio from "./media_section/enhance_audio";
import enhance_video from "./media_section/enhance_video";

const rootReducers = combineReducers({
  scribble,
  background_remover,
  background_changer,
  text_to_audio,
  text_to_speech,
  text_to_video,
  infinite_zoom,
  overlap_animation,
  remove_video_background,
  enhance_audio,
  enhance_video,
  upscale,
  clip_audio,
  redesign,
  enhance,
  headShot,
  reimagine_v2,
  upscaleV1,
  sidebar,
  cases,
  Images,
  styling,
  user,
  userSubscription,
  history,
  loading,
  arabyGPT,
  chatHistory,
  blogs,
  enterprise,
  business_cases,
  newChatHolder,
  new_cases,
  business_output,
  presentation_history,
  sidebar,
});

export default rootReducers;
