import React from "react";
import { useState, useRef, useEffect } from "react";
import { toastAlert } from "components/toastAlert/toastAlert";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import UpgradeModal from "components/UpgradeModal/UpgradeModal";
import CanvasDraw from "react-canvas-draw";
import LoadingEffect from "components/Loading/LoadingEffect";
import UpgradePlanModal from "components/UpgradeModal/UpgradePlanModal";
import { handleScribble } from "redux/actions/media_section";
import scribbleImage from "assets/img/newDesign/scribble.png";
import style from "./Scribble.module.scss";
import { ReactComponent as Upload } from "assets/img/newDesign/uploadBlack.svg";
import { ReactComponent as Erase } from "assets/img/newDesign/Eraser.svg";
import { ReactComponent as Undo } from "assets/img/newDesign/undo.svg";
import { ReactComponent as Redo } from "assets/img/newDesign/redo.svg";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import Loading_v2 from "components/Loading/Laoadingv2/Loadingv2";
import ReplayIcon from "@mui/icons-material/Replay";
import { handleScribbleLoading } from "redux/actions/media_section";

import { handleLoadingScribble } from "redux/actions/media_section";
import LimitPopUp from "views/plansComponent/LimitPopUp";
const Scribble = () => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const fileRef = useRef();
  const canvasRef = useRef();
  const user = useSelector((state) => state.user);
  const scribble = useSelector((state) => state.scribble);
  const dispatch = useDispatch();

  const [input, setInput] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [totalImages, setTotalImages] = useState(0);
  const [uploadImage, setUploadImage] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploaded, setUploaded] = useState(true);
  const [base64, setBase64] = useState("");
  const [canvas, setCanvas] = useState([]);
  const [history, setHistory] = useState([]);
  const [index, setIndex] = useState(-1);
  const [show, setShow] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [pen, setPen] = useState(true);
  const [loadingText, setLoadingText] = useState("Uploading your Image");

  // const handleScribbleChange = () => {
  //   const newCanvas = [...canvas, canvasRef.current?.getSaveData()];
  //   console.log(newCanvas);
  //   setCanvas(newCanvas);
  //   setHistory([]);
  //   setIndex(newCanvas.length - 1);
  // };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  // const undo = () => {
  //   if (index >= 0) {
  //     const newHistory = [...history, canvas[index]];
  //     setHistory(newHistory);

  //     const newIndex = index - 1;
  //     setIndex(newIndex);

  //     const newItems = canvas.slice(0, newIndex + 1);
  //     setCanvas(newItems);
  //     console.log(newItems);
  //   }
  // };

  // const redo = () => {
  //   if (index < canvas.length - 1) {
  //     const newItem = history.pop();
  //     setHistory([...history]);
  //     const newItems = [...canvas, newItem];
  //     setCanvas(newItems);
  //     const newIndex = index + 1;
  //     setIndex(newIndex);
  //     console.log(newItems);
  //   }
  // };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [width]);

  useEffect(() => {
    setTotalImages(user?.image_count_left);
    if (user?.plan === "Starter" || user?.plan === "Trial") {
      setShow(true);
    }
  }, [user]);

  const handleScribbled = (e) => {
    e.preventDefault();
    if (totalImages === 0) {
      return setShowModal(true);
    } else if (input === "") {
      return toastAlert(t("Please enter a valid text"), undefined, "warning");
    } else {
      let data = {
        image: canvasRef.current?.getDataURL() || base64,
        prompt: input,
      };
      dispatch(handleScribble(data));
    }
  };

  const handleFile = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setBase64(reader.result.toString());
    };
    if (file && /image/.test(file.type)) {
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  const handleImageUpload = async (e) => {
    e.preventDefault();
    const file = await e.target.files;
    if (file) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (allowedTypes.includes(file[0].type)) {
        setUploadImage(file[0]);
        handleFile(file[0]);
        setUploaded(false);
      } else {
        toastAlert(
          "Only JPG, JPEG, and PNG files are allowed.",
          undefined,
          "warning"
        );
        setUploaded(true);
      }
    } else setUploaded(true);
  };

  const handleDownload = () => {
    const fileUrl = scribble?.scribble?.urls;
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = "hello"; // Specify the file name here
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    if (scribble?.loading) {
      let intervalTime;
      if (scribble?.loadingNumber >= 1 && scribble?.loadingNumber < 25) {
        intervalTime = 3000 / 25; // 3 seconds for 1-25, increment by 1 every 200ms
        setLoadingText("Uploading your drawing");
      } else if (
        scribble?.loadingNumber >= 25 &&
        scribble?.loadingNumber < 50
      ) {
        intervalTime = 5000 / 25; // 5 seconds for 25-50, increment by 1 every 800ms
        setLoadingText("Analyzing Your drawing");
      } else if (
        scribble?.loadingNumber >= 50 &&
        scribble?.loadingNumber < 75
      ) {
        intervalTime = 10000 / 25; // 10 seconds for 50-75, increment by 1 every 1200ms
        setLoadingText("Generating the Image");
      } else if (
        scribble?.loadingNumber >= 75 &&
        scribble?.loadingNumber < 100
      ) {
        intervalTime = 50000 / 25; // 10 seconds for 50-75, increment by 1 every 1200ms
        setLoadingText("Downloading the Image");
      }
      const timer = setInterval(() => {
        dispatch(handleScribbleLoading(scribble?.loadingNumber, "scribble"));
      }, intervalTime);
      return () => {
        clearInterval(timer);
      };
    }
  }, [scribble?.loadingNumber, scribble?.loading]);
  // console.log(scribble?.loadingNumber, "scrible");
  return (
    <>
      {scribble?.loading ? (
        <div
          style={{
            height: "calc(100vh - 200px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading_v2
            loadingText={loadingText}
            setLoadingText={setLoadingText}
            loadingNumber={scribble?.loadingNumber}
          />
        </div>
      ) : scribble?.scribble?.length != 0 ? (
        <div style={{ position: "relative" }}>
          {scribble?.scribble?.length != 0 && (
            <>
              <div
                style={{
                  maxWidth: "600px",
                  minHeight: "450px",
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={scribble?.scribble?.urls}
                  className="bg_remover_background"
                />
              </div>
              <div className="beforeAfter-container">
                <div className="beforeafter-gray">
                  <button className="download-btn" onClick={handleDownload}>
                    <i
                      className="fas fa-arrow-down"
                      style={{
                        fontSize: "medium",
                        margin: "0 10px",
                      }}
                    ></i>
                    {t("Download")}
                  </button>
                  <button
                    className="newupload"
                    onClick={() => {
                      dispatch({ type: "NEW_SCRIBBLE" });
                    }}
                  >
                    <ReplayIcon
                      sx={{
                        transform: "scale(-1)",
                        margin: "0 10px",
                        fontSize: "20px",
                      }}
                    />
                    {t("Try Again")}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="image_gen_container scribble_wrap">
          <div
            className="image_gen_header"
            style={{
              padding: width < 500 ? "10px" : "20px",
              alignSelf: "flex-start",
            }}
          >
            <h4 style={{ textAlign: lng === "arab" ? "right" : "left" }}>
              {t(
                "Bring your artwork into life and turn your drawings into real life images"
              )}
            </h4>
            <p style={{ textAlign: lng === "arab" ? "right" : "left" }}>
              {t(
                "Transform your drawings into real images in seconds with the power of twwt.ai"
              )}
            </p>
            <div
              style={{ maxWidth: "450px", margin: "auto" }}
              className="right_image_media"
            >
              <img
                src={scribbleImage}
                alt="upscale-example"
                width={"100%"}
                height={"100%"}
              />
            </div>
            {user?.plan === "Trial" ? (
              <UpgradeModal
                images={true}
                setShowModal={setShowModal}
                showModal={showModal}
              />
            ) : (
              <LimitPopUp setShowModal={setShowModal} showModal={showModal} />
            )}

            <UpgradePlanModal show={show} setShow={setShow} width={width} />
          </div>
          <div className="">
            <span className={style.button}>
              <span>
                <div
                  onClick={() => setPen(true)}
                  style={{
                    backgroundColor: pen && "#BEBDC7",
                  }}
                >
                  <EditRoundedIcon />
                </div>
                <div
                  onClick={() => setPen(false)}
                  style={{
                    backgroundColor: !pen && "#BEBDC7",
                  }}
                >
                  <Erase />
                </div>
              </span>
              <div style={{ display: "flex", gap: "10px" }}>
                <button onClick={() => fileRef.current.click()}>
                  <Upload style={{ width: "20px" }} />
                  {t("Upload")}
                  <input
                    ref={fileRef}
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                  />
                </button>
                <button
                  onClick={() => {
                    canvasRef.current?.eraseAll();
                    setUploaded(true);
                  }}
                >
                  {t("Clear")}
                </button>
                <Undo
                  style={{
                    transform: lng === "arab" ? "scaleX(-1)" : "scaleX(1)",
                  }}
                  className={style.undo}
                  onClick={() => {
                    canvasRef.current?.undo();
                  }}
                />
              </div>
            </span>
            <div className={style.container}>
              {uploaded ? (
                <CanvasDraw
                  brushColor={pen ? "#000" : "#fff"}
                  hideGrid="true"
                  lazyRadius={0}
                  ref={canvasRef}
                  canvasWidth={
                    width > 550
                      ? 500
                      : width < 550 && width > 425
                      ? 400
                      : width < 425
                      ? 300
                      : 500
                  }
                  brushRadius={pen ? 1 : 5}
                  value={canvas}
                  // onChange={handleScribbleChange}
                  style={{
                    borderRadius: "10px",
                    border: "1px solid #E1E1E1",
                  }}
                />
              ) : (
                <img id={style.canvasImage} alt="" src={selectedImage} />
              )}
            </div>
            <form onSubmit={handleScribbled} className={style.scribble_form}>
              <input
                accept=".jpg,.jpeg,.png"
                type="textarea"
                name="image_gen"
                placeholder={t("Describe Your Image")}
                minRows={1}
                onChange={(e) => setInput(e.target.value)}
              />
              <button type="submit">
                {scribble?.loading ? <LoadingEffect /> : t("Create")}
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Scribble;
