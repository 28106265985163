const { Modal } = require("react-bootstrap");
const { useTranslation } = require("react-i18next");
import { clearData } from "redux/actions/user";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseUrl } from "api/baseUrl";
import { toastAlert } from "components/toastAlert/toastAlert";
import checkErr from "redux/actions/api_error_func";
const DeletePopUp = (props) => {
  const dispatch = useDispatch();
  const handleDelete = async () => {
    try {
      const res = await axios.delete(`${baseUrl}delete_my_account`);
      toastAlert("Account successfully deleted");
      // console.log(res, "deleteresponse");
      handleLogOut();
    } catch (err) {
      if (err.response) {
        const errorMessage = await err.response.data;
        toastAlert(errorMessage.message);
      } else {
        toastAlert("An error occurred.");
      }
    }
  };
  const handleLogOut = () => {
    dispatch(clearData());
  };
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  return (
    <Modal {...props} size="ms" centered className="are_u_sure_model">
      <Modal.Body
        style={{
          direction: lng === "arab" ? "rtl" : "ltr",
          fontFamily: lng === "arab" ? "Tajawal" : "Poppins",
        }}
        className="forceArabicFont"
      >
        <h4
          style={{
            marginBottom: "10px",
            marginTop: "10px",
            textAlign: lng === "arab" ? "right" : "left",
            fontWeight: "500",
          }}
        >
          {t("Delete Account?")}
        </h4>
        <div
          style={{
            textAlign: lng === "arab" ? "right" : "left",
            fontSize: "16px",
          }}
        >
          {t("Are you sure you want to delete your account?")}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
          }}
        >
          <button
            style={{ borderRadius: "12px", padding: "5px 20px" }}
            className="newupload"
            onClick={() => {
              props.onHide();
              handleDelete();
            }}
          >
            {t("Yes")}
          </button>
          <button
            style={{ borderRadius: "12px", padding: "5px 20px" }}
            className="download-btn"
            onClick={() => props.onHide()}
          >
            {t("No")}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default DeletePopUp;
