import { Modal } from "react-bootstrap";
import checkErr from "redux/actions/api_error_func";
import { useState } from "react";
import { toastAlert } from "components/toastAlert/toastAlert";
import axios from "axios";
import { baseUrl } from "api/baseUrl";
import { useTranslation } from "react-i18next";

//<-------------------CSS Start------------------------>

const textStyle = {
  margin: "0rem 2rem 0rem 2rem",
};

const downgradePopUpButtonWrapper = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  gap: "1rem",
  margin: "auto",
  paddingBottom: "10px",
};

const buttonStyle1 = {
  borderRadius: "12px",
  padding: "7px 20px",
  border: "none",
  fontWeight: "700",
  background: "#EF142E",
  cursor: "pointer",
  color: "#fff",
};

const buttonStyle2 = {
  borderRadius: "12px",
  padding: "7px 20px",
  border: "none",
  fontWeight: "700",
  background: "#1777F2",
  cursor: "pointer",
  color: "#fff",
};
//<-------------------CSS End------------------------>

const CancelPlanPopUp = (props) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");

  const getInputValue = (e) => {
    setInputValue(e.target.value);
    // console.log(inputValue, "inputValu");
  };
  const cancelPlanApi = async () => {
    try {
      const res = await axios.post(`${baseUrl}subscription/cancel`, {
        reason: inputValue,
      });
      // console.log(res, "cancelresponse");

      props.setShowCancelPopUp(false);
      props.onHide(false);
    } catch (error) {
      checkErr(error, toastAlert);
      // console.log(error, "error");
    }
  };
  return (
    <Modal centered show={props.show} {...props}>
      <div style={textStyle}>
        <h4>{t("Are you sure you want to cancel?")}</h4>
        <input className="form-control" placeholder="What's your reason for canceling" onChange={getInputValue} />
      </div>
      <div style={downgradePopUpButtonWrapper}>
        <button
          style={buttonStyle2}
          onClick={() => {
            cancelPlanApi(), props.changeTexttoCanceled(true);
          }}
        >
          {t("Yes")}
        </button>
        <button style={buttonStyle1} onClick={() => props.onHide(false)}>
          {t("No")}
        </button>
      </div>
      <Modal.Footer style={{ textAlign: "center", fontSize: "0.7rem" }}>{t("By canceling your subscription, you will lose access to all the features of the plan on the Next renewal.")}</Modal.Footer>
    </Modal>
  );
};

export default CancelPlanPopUp;
