import {
    FETCH_CLIP_AUDIO,
    LOAD_CLIP_AUDIO,
    LOADED_CLIP_AUDIO,
    NEW_CLIP_AUDIO,
    SHOW_CLIP_AUDIO_FALSE,
    SHOW_CLIP_AUDIO_TRUE,
    LOAD_PERCENTAGE_CLIP_AUDIO
} from "redux/contstants";

export default (clipAudio = { clipAudio: [], uploaded: [], loadingNumber: 0, loading: false, showRemover: false }, action) => {
    switch (action.type) {
        case LOAD_CLIP_AUDIO:
            return { ...clipAudio, loading: true, loadingNumber: 0, showRemover: true };
        case FETCH_CLIP_AUDIO:
            return { ...clipAudio, clipAudio: action.data };
        case LOADED_CLIP_AUDIO:
            return { ...clipAudio, loading: false };
        case NEW_CLIP_AUDIO:
            return { ...clipAudio, clipAudio: [], loadingNumber: 0, uploaded: [], showRemover: false };
        case SHOW_CLIP_AUDIO_FALSE:
            return { ...clipAudio, showRemover: false };
        case SHOW_CLIP_AUDIO_TRUE:
            return { ...clipAudio, showRemover: true };
        case LOAD_PERCENTAGE_CLIP_AUDIO:
            return { ...clipAudio, loadingNumber: action.data };
        default:
            return clipAudio;
    }
};