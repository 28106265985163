import React from "react";

const StylesButton = ({ quillRef }) => {
  const handleBold = () => {
    if (quillRef.current.getEditor().getFormat().bold) {
      quillRef.current.getEditor().format("bold", false);
    } else {
      quillRef.current.getEditor().format("bold", true);
    }
  };

  const handleItalic = () => {
    if (quillRef.current.getEditor().getFormat().italic) {
      quillRef.current.getEditor().format("italic", false);
    } else {
      quillRef.current.getEditor().format("italic", true);
    }
  };

  const handleUnderline = () => {
    if (quillRef.current.getEditor().getFormat().underline) {
      quillRef.current.getEditor().format("underline", false);
    } else {
      quillRef.current.getEditor().format("underline", true);
    }
  };

  // const handleStrike = () => {
  //   if (quillRef.current.getEditor().getFormat().strike) {
  //     quillRef.current.getEditor().format("strike", false);
  //   } else {
  //     quillRef.current.getEditor().format("strike", true);
  //   }
  // };
  return (
    <div className="toolButton">
      <button onClick={handleBold}>
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.70483 15V0H6.856C8.71707 0 10.1698 0.345158 11.2138 1.03539C12.2576 1.72554 12.7797 2.75416 12.7797 4.1209C12.7797 4.83528 12.6116 5.47027 12.2749 6.02688C11.9384 6.58331 11.4199 7.00223 10.7192 7.28372C11.5983 7.48284 12.2473 7.88291 12.6664 8.48392C13.0851 9.08493 13.2948 9.77698 13.2948 10.5598C13.2948 12.0227 12.8037 13.1286 11.8218 13.877C10.8395 14.6258 9.43162 15 7.59766 15H1.70483ZM5.19735 6.25345H6.9281C7.7179 6.23973 8.30865 6.08691 8.7002 5.79492C9.09167 5.5031 9.28749 5.07558 9.28749 4.51236C9.28749 3.87356 9.0882 3.40842 8.69003 3.11643C8.29145 2.82461 7.68027 2.67857 6.85608 2.67857H5.19735V6.25345ZM5.19735 8.56123V12.3215H7.59766C8.3395 12.3215 8.89585 12.1755 9.26673 11.8835C9.63752 11.5917 9.82317 11.1436 9.82317 10.5392C9.82317 9.88671 9.66341 9.39395 9.34396 9.06078C9.02469 8.72778 8.50786 8.56123 7.79365 8.56123H5.19735Z"
            fill="#9D9D9D"
          />
        </svg>
      </button>
      <button onClick={handleItalic}>
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.79492 15H9.82617C10.0689 15 10.2656 14.8032 10.2656 14.5605C10.2656 14.3179 10.0689 14.1211 9.82617 14.1211H8.34229C8.19864 14.1211 8.06895 14.0605 7.98647 13.9549C7.90922 13.8559 7.8842 13.7314 7.9161 13.6039L10.8257 1.87752C10.9727 1.28956 11.4986 0.878906 12.1047 0.878906H13.3418C13.5845 0.878906 13.7813 0.682148 13.7813 0.439453C13.7813 0.196758 13.5845 0 13.3418 0H6.31055C6.06785 0 5.87109 0.196758 5.87109 0.439453C5.87109 0.682148 6.06785 0.878906 6.31055 0.878906H7.79443C7.93069 0.878906 8.05693 0.940518 8.14081 1.04792C8.22466 1.15532 8.25381 1.29272 8.22062 1.42544L5.31103 13.1518C5.16609 13.7316 4.65211 14.1211 4.03204 14.1211H2.79492C2.55223 14.1211 2.35547 14.3179 2.35547 14.5605C2.35547 14.8032 2.55223 15 2.79492 15Z"
            fill="#9D9D9D"
          />
        </svg>
      </button>
      <button onClick={handleUnderline}>
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.63672 11.6667C10.3992 11.6667 12.6367 9.42918 12.6367 6.66668V0H10.5534V6.66668C10.5534 8.27918 9.24922 9.58336 7.63672 9.58336C6.02422 9.58336 4.72004 8.27918 4.72004 6.66668V0H2.63672V6.66668C2.63672 9.42918 4.87422 11.6667 7.63672 11.6667Z"
            fill="#9D9D9D"
          />
          <path d="M13.4701 13.3333H1.80347V15H13.4701V13.3333Z" fill="#9D9D9D" />
        </svg>
      </button>
      {/* <button onClick={handleStrike}>
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_23_1595)">
            <path
              d="M12.75 6.75H7.50005C5.8455 6.75 4.50009 5.57254 4.50009 4.12495C4.50009 2.67751 5.8455 1.50005 7.50005 1.50005C9.15459 1.50005 10.5 2.67751 10.5 4.12495C10.5 4.53969 10.836 4.87505 11.2501 4.87505C11.664 4.87505 12 4.53969 12 4.12495C12 1.85106 9.98186 0 7.50005 0C5.01823 0 3.00005 1.85106 3.00005 4.12495C3.00005 5.121 3.38786 6.03603 4.03125 6.75H2.25009C1.83604 6.75 1.5 7.08522 1.5 7.49995C1.5 7.91469 1.83604 8.25004 2.25009 8.25004H7.50005C9.15459 8.25004 10.5 9.4275 10.5 10.875C10.5 12.3225 9.15459 13.5 7.50005 13.5C5.8455 13.5 4.50009 12.3225 4.50009 10.875C4.50009 10.4602 4.16405 10.125 3.75 10.125C3.33609 10.125 3.00005 10.4602 3.00005 10.875C3.00005 13.149 5.01823 15 7.50005 15C9.98186 15 12 13.149 12 10.875C12 9.87904 11.6122 8.96402 10.9688 8.25004H12.75C13.164 8.25004 13.5001 7.91469 13.5001 7.49995C13.5001 7.08522 13.164 6.75 12.75 6.75Z"
              fill="#9D9D9D"
            />
          </g>
          <defs>
            <clipPath id="clip0_23_1595">
              <rect width="15" height="15" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button> */}
    </div>
  );
};

export default StylesButton;
