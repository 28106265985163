import { toastAlert } from "components/toastAlert/toastAlert";
import { USER, LOGOUT, STOP_LOADING, LOADING } from "redux/contstants";
import * as api from "../../api";
import checkErr from "./api_error_func";

export const getUser = (str) => async (dispatch) => {
  if (str !== "without") {
    dispatch({ type: LOADING });
  }
  try {
    var result = await api.autoLogin();
    dispatch({ type: USER, data: { ...result.data.User, hasInvite: result.data?.hasInvite, inviteBy: result.data?.invite?.enterprise?.owner?.name } });
    dispatch({ type: STOP_LOADING });
    if (str === "enterprise") {
      // dispatch({ type: "FETCH_ENTERPRISE" });
    }
  } catch (error) {
    dispatch({ type: STOP_LOADING });
    checkErr(error, false, toastAlert, false, dispatch, false, "loghim");
  }
};

export const setUser = (user) => (dispatch) => {
  dispatch({ type: USER, data: user });
};

export const stepOne = (data, setLoading, setForceLoad, forceLoad) => async (dispatch) => {
  try {
    var result = await api.userBasicInfoOne(data);
    toastAlert("Basic Information Step One Updated Successfully", undefined, "success");
    setForceLoad(forceLoad + 1);
  } catch (error) {
    checkErr(error, setLoading, toastAlert, false, dispatch);
  }
};

export const stepTwo = (data, setLoading, setForceLoad, forceLoad) => async (dispatch) => {
  try {
    var result = await api.userBasicInfoTwo(data);
    toastAlert("Basic Information Step Two Updated Successfully", undefined, "success");
    setForceLoad(forceLoad + 1);
  } catch (error) {
    checkErr(error, setLoading, toastAlert, false, dispatch);
  }
};

export const stepThree = (data, setLoading, setForceLoad, forceLoad) => async (dispatch) => {
  try {
    var result = await api.userBasicInfoThree(data);
    toastAlert("Basic Information Step Three Updated Successfully", undefined, "success");
    setForceLoad(forceLoad + 1);
  } catch (error) {
    checkErr(error, setLoading, toastAlert, false, dispatch);
  }
};

export const stepFour = (data, setLoading, setForceLoad, forceLoad) => async (dispatch) => {
  try {
    var result = await api.userBasicInfoFour(data);
    toastAlert("Basic Information Step Four Updated Successfully", undefined, "success");
    setForceLoad(forceLoad + 1);
  } catch (error) {
    checkErr(error, setLoading, toastAlert, false, dispatch);
  }
};

export const clearData = () => async (dispatch) => {
  try {
    localStorage.removeItem("sess");
    dispatch({ type: LOGOUT, payload: null });
  } catch (error) {}
};
