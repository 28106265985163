import React from 'react'
import { Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import Remove from '../../assets/img/remove.svg'
import './ErrorModal.css';

const ErrorModal = (props) => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    return (
        <Modal
            className={lng === 'arab' ? "modal modal-primary arabicFont thank-you-modal" : "modal modal-primary englishFont thank-you-modal"}
            show={props.showModal}
            centered
            onHide={() => props.setShowModal(false)}
        >
            <Modal.Header>
                <button onClick={() => props.setShowModal(false)} className='btn-close'>
                    <i className="nc-icon nc-simple-remove"></i>
                </button>
            </Modal.Header>
            
            <Modal.Body className="text-center" >
                <div className="row justify-content-center">
                    <img className='modalImgWrapper' style={{maxHeight: '200px'}} src={Remove} />
                </div>
                <h3 style={{margin: '0px', fontWeight: 600, lineHeight: '40px' }}>
                    {props?.errorMessage ?? t("Error - Please try again")}
                </h3>
                <button
                    onClick={() => {
                        props.setShowModal(false)
                    }}
                    className="btn common-btn-modal"
                >
                    {t("Ok")}
                </button>
            </Modal.Body>
        </Modal >
    )
}

export default ErrorModal