import {
  FETCH_ENHANCE,
  LOAD_ENHANCE,
  LOADED_ENHANCE,
  NEW_ENHANCE,
  SHOW_ENHANCE_FALSE,
  SHOW_ENHANCE_TRUE,
  SHOW_ENHANCE_UPLOADING_FALSE,
  SHOW_ENHANCE_UPLOADING_TRUE,
  LOAD_PERCENTAGE_ENHANCE,
} from "redux/contstants";

export default (enhance = { enhance: [], uploaded: [], loadingNumber: 0, showUploadingRemover: false, loading: false, showRemover: false }, action) => {
  switch (action.type) {
    case LOAD_ENHANCE:
      return { ...enhance, loading: true, loadingNumber: 0, showRemover: true };
    case FETCH_ENHANCE:
      return { ...enhance, enhance: action.data, loadingNumber: 0 };
    case LOADED_ENHANCE:
      return { ...enhance, loading: false };
    case NEW_ENHANCE:
      return { ...enhance, enhance: [], uploaded: [], loadingNumber: 0, showRemover: false, showUploadingRemover: false };
    case SHOW_ENHANCE_FALSE:
      return { ...enhance, showRemover: false };
    case SHOW_ENHANCE_TRUE:
      return { ...enhance, showRemover: true };
    case SHOW_ENHANCE_UPLOADING_FALSE:
      return { ...enhance, showUploadingRemover: false };
    case SHOW_ENHANCE_UPLOADING_TRUE:
      return { ...enhance, showUploadingRemover: true };
    case LOAD_PERCENTAGE_ENHANCE:
      return { ...enhance, loadingNumber: action.data };
    default:
      return enhance;
  }
};
