import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { toastAlert } from "components/toastAlert/toastAlert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import { baseUrl } from "api/baseUrl";
import Waveform from "views/MediaSection/AudioTools/Waveform";
import { useTranslation } from "react-i18next";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import axios from "axios";

const Details = () => {
  const navigate = useNavigate();
  const params = useParams();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [docs, setDocs] = useState([]);
  const [codes, setCodes] = useState([]);
  const [audios, setAudios] = useState([]);
  const [videos, setVideos] = useState([]);
  // const [business, setBusiness] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    let generation_type = "user";
    if (location.pathname.includes("audio") || location.pathname.includes("video")) {
      generation_type = "media-generation";
    }
    let type = "";
    if (location.pathname.includes("code")) {
      type = "code";
      setType("code");
    } else if (location.pathname.includes("text")) {
      type = "text";
      setType("text");
    } else if (location.pathname.includes("audio")) {
      type = "audio";
      setType("audio");
    } else if (location.pathname.includes("video")) {
      type = "video";
      setType("video");
    } else if (location.pathname.includes("business")) {
      type = "text";
      setType("business");
    } else {
      type = "text";
      setType("text");
    }

    axios
      .get(`${baseUrl}${generation_type}/history/${type}/${params.id}`)
      .then((res) => {
        setIsLoading(false);
        if (type === "text") {
          setDocs(res?.data?.data);
        } else if (type === "code") {
          setCodes(res?.data?.data);
        } else if (type === "audio") {
          setAudios(res?.data?.data);
        } else if (type === "video") {
          setVideos(res?.data?.data);
        } else if (type === "business") {
          setDocs(res?.data?.data);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [location.pathname]);

  const handleRegularShare = () => {
    toastAlert("Copied", "Text has been copied to clipboard", "success");
  };

  return (
    <>
      {isLoading ? (
        <Spinner style={{ position: "absolute", top: "50%", left: "50%" }} animation="border" role="status" />
      ) : (
        <div style={{ margin: "20px 10px" }}>
          <button
            style={{
              border: "none",
              background: "rgb(215 215 215)",
              padding: " 5px 15px",
              borderRadius: "10px",
              transform: lng === "arab" ? "rotate(180deg)" : null,
              display: "flex",
              margin: "20px 0",
              position: "sticky",
              top: "2px",
              left: "-5px",
              zIndex: "5",
            }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </button>
          <div className="details_page_stats">
            <h4>
              {t("Date")} :
              {docs?.createdAt || codes?.createdAt || audios.createdAt || videos?.createdAt ? (
                <strong>{moment(docs?.createdAt)?.format("MMMM Do YYYY") || moment(codes?.createdAt).format("MMMM Do YYYY") || moment(audios?.createdAt).format("MMMM Do YYYY")}</strong>
              ) : null}
            </h4>

            {docs?.language || codes?.language ? (
              <h4>
                {t("Language")} :<strong>{docs?.language || codes?.language}</strong>
              </h4>
            ) : null}

            {docs?.word_count || codes?.word_count ? (
              <h4>
                {t("Word Count")} :<strong>{docs?.word_count || codes?.word_count}</strong>{" "}
              </h4>
            ) : null}

            {audios?.prompt || videos?.prompt ? (
              <h4>
                {t("Prompt")}:<strong>{audios?.prompt || videos?.prompt}</strong>
              </h4>
            ) : null}
            {videos?.useCase ? (
              <h4>
                {t("Use Case")}:<strong>Video background removal</strong>
              </h4>
            ) : null}

            {docs?.useCase ? (
              <h4>
                {t("Use Case")}:<strong>{docs?.useCase?.replace(/([A-Z]+)/g, " $1")?.replace(/([A-Z][a-z])/g, " $1")}</strong>
              </h4>
            ) : null}
          </div>

          {type === "text" || type === "business" ? (
            <>
              {docs?.text?.choices?.map((item, index) => (
                <div
                  key={index}
                  style={{
                    fontFamily: docs?.language === "Arabic" ? "Tajawal" : null,
                    position: "relative",
                  }}
                >
                  <div
                    className="detail_card"
                    style={{
                      direction: docs?.language === "Arabic" ? "rtl" : "ltr",
                    }}
                  >
                    <div style={{ width: "96%", maxWidth: "1400px" }} className={lng === "arab" ? "export_pdf" : "export_pdf_ar"}>
                      <label
                        id="chat_content"
                        style={{
                          width: "100%",
                          overflowX: "scroll",
                          padding: "10px",
                        }}
                      >
                        <ReactMarkdown
                          children={item?.text}
                          remarkPlugins={[remarkGfm]}
                          components={{
                            code({ node, inline, className, children, ...props }) {
                              const match = /language-(\w+)/.exec(className || "");
                              return !inline && match ? (
                                <>
                                  <SyntaxHighlighter
                                    children={String(children).replace(/\n$/, "")}
                                    language={detect(String(children?.slice(0)?.shift()))?.toLowerCase()}
                                    PreTag="div"
                                    style={docco}
                                    {...props}
                                  />
                                </>
                              ) : (
                                <code {...props} className={className}>
                                  {children}
                                </code>
                              );
                            },
                          }}
                        />
                      </label>
                    </div>
                    <div className="details_card_footer">
                      <p
                        style={{
                          minWidth: "100px",
                          margin: "0",
                          textAlign: "center",
                        }}
                      >
                        {t("words")} : {item?.word_count || codes?.word_count}
                      </p>
                      <CopyToClipboard onCopy={() => handleRegularShare()} text={item?.text || codes?.text}>
                        <button className="words_action_btn">
                          <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="12.424" height="12.424" viewBox="0 0 12.424 12.424">
                            <g id="Group_2026" data-name="Group 2026">
                              <path
                                id="Path_10306"
                                data-name="Path 10306"
                                d="M13.117,2.16H5.543A1.466,1.466,0,0,0,4.081,3.627v.454H3.622A1.466,1.466,0,0,0,2.16,5.548v7.569a1.466,1.466,0,0,0,1.462,1.467H11.2a1.468,1.468,0,0,0,1.467-1.467v-.454h.454A1.469,1.469,0,0,0,14.584,11.2V3.627A1.469,1.469,0,0,0,13.117,2.16ZM11.765,13.117a.57.57,0,0,1-.569.569H3.622a.567.567,0,0,1-.565-.569V5.548a.568.568,0,0,1,.565-.569H11.2a.57.57,0,0,1,.569.569v7.569ZM13.686,11.2a.57.57,0,0,1-.569.569h-.454V5.548A1.468,1.468,0,0,0,11.2,4.081H4.978V3.627a.567.567,0,0,1,.565-.569h7.574a.57.57,0,0,1,.569.569Z"
                                transform="translate(-2.16 -2.16)"
                                fill="#b1b1b1"
                              />
                            </g>
                          </svg>
                        </button>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : null}

          {type === "code" ? (
            <div className="detail_card">
              <div style={{ width: "96%", maxWidth: "1400px" }} className={lng === "arab" ? "export_pdf code_decoration" : "export_pdf_ar code_decoration"}>
                <label
                  id="chat_content"
                  style={{
                    width: "100%",
                    padding: "10px",
                    whiteSpace: "break-spaces",
                  }}
                >
                  <ReactMarkdown
                    children={codes?.code}
                    remarkPlugins={[remarkGfm]}
                    components={{
                      code({ node, inline, className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || "");
                        return !inline && match ? (
                          <>
                            <SyntaxHighlighter
                              children={String(children).replace(/\n$/, "")}
                              language={detect(String(children?.slice(0)?.shift()))?.toLowerCase()}
                              PreTag="div"
                              style={docco}
                              {...props}
                            />
                          </>
                        ) : (
                          <code {...props} className={className}>
                            {children}
                          </code>
                        );
                      },
                    }}
                  />
                </label>
              </div>
              <div className="details_card_footer">
                <p
                  style={{
                    minWidth: "100px",
                    margin: "0",
                    textAlign: "center",
                  }}
                >
                  {t("words")} : {codes?.word_count}
                </p>
                <CopyToClipboard onCopy={() => handleRegularShare()} text={codes?.code}>
                  <button className="words_action_btn">
                    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="12.424" height="12.424" viewBox="0 0 12.424 12.424">
                      <g id="Group_2026" data-name="Group 2026">
                        <path
                          id="Path_10306"
                          data-name="Path 10306"
                          d="M13.117,2.16H5.543A1.466,1.466,0,0,0,4.081,3.627v.454H3.622A1.466,1.466,0,0,0,2.16,5.548v7.569a1.466,1.466,0,0,0,1.462,1.467H11.2a1.468,1.468,0,0,0,1.467-1.467v-.454h.454A1.469,1.469,0,0,0,14.584,11.2V3.627A1.469,1.469,0,0,0,13.117,2.16ZM11.765,13.117a.57.57,0,0,1-.569.569H3.622a.567.567,0,0,1-.565-.569V5.548a.568.568,0,0,1,.565-.569H11.2a.57.57,0,0,1,.569.569v7.569ZM13.686,11.2a.57.57,0,0,1-.569.569h-.454V5.548A1.468,1.468,0,0,0,11.2,4.081H4.978V3.627a.567.567,0,0,1,.565-.569h7.574a.57.57,0,0,1,.569.569Z"
                          transform="translate(-2.16 -2.16)"
                          fill="#b1b1b1"
                        />
                      </g>
                    </svg>
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          ) : null}
          {type === "audio" ? (
            <div className="detail_card inpainting_input">
              {audios?.urls.map((item, index) => (
                <Waveform url={item} />
              ))}
            </div>
          ) : null}
          {type === "video" ? (
            <div className=" detail_card inpainting_input">
              {" "}
              <video src={videos.urls} controls disablePictureInPicture controlsList=" nodownload noremoteplayback noplaybackrate nopictureinpicture " style={{ width: "70%", borderRadius: "20px" }} />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default Details;
