import React, { useContext, useEffect, useState, useRef } from "react";
import "../assets/css/home.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toastAlert } from "components/toastAlert/toastAlert";
import WriteCategory from "components/writeCategory/WriteCategory";
import homeWritingIcon from "../assets/img/newDesign/homeWritingIcon.svg";
import homeImageIcon from "../assets/img/newDesign/homeImageIcon.svg";
import homeCodingIcon from "../assets/img/newDesign/homeCodingIcon.svg";
import homeGPTIcon from "../assets/img/newDesign/Arabygot-Icon4.png";
import businessTools from "../assets/img/newDesign/businessTools.png";
import Marquee from "react-double-marquee";

import gift from "../assets/img/loading-images/gift-popup.png";
import LoadingPopUp from "components/popUps/LoadingPopUp";
import CopyToClipboard from "react-copy-to-clipboard";
import { Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
// import { OfferTimerContext } from 'providers/OfferTimeProvider';

const Home = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const user = useSelector((state) => state.user);
  const bigLoading = useSelector((state) => state.loading);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [totalImages, setTotalImages] = useState(0);
  const [totalWords, setTotalWords] = useState(0);
  const [showOffer, setShowOffer] = useState(false);
  const [flag, setFlag] = useState(false);
  // const offerTimerState = useContext(OfferTimerContext);

  const features = [
    {
      name: "TWWTGPT",
      arabic_name: "TWWTGPT",
      link: "twwtgpt",
      icon: homeGPTIcon,
      specialIcon: true,
      desc: lng === "arab" ? "تحدث مع TwwtGPT" : "Chat with twwtGPT",
    },
    {
      name: "Business Tools",
      arabic_name: "أدوات الشركات",
      link: "business-tools",
      icon: businessTools,
      specialIcon: true,
      desc:
        lng === "arab"
          ? "أدوات الذكاء الاصطناعي للشركات"
          : "AI solutions for business",
    },
    {
      name: "Writers Section",
      arabic_name: "كاتب المحتوى الذكي",
      link: "writenow",
      icon: homeWritingIcon,
      desc:
        lng === "arab"
          ? "يكتب لك جميع أنواع المحتوى الكتابي"
          : "Enhance your content writing",
    },
    {
      name: "Media Section",
      arabic_name: "قسم الصور",
      link: "photogen",
      icon: homeImageIcon,
      desc:
        lng === "arab"
          ? "لنجعل أفكارك تنبض بالحياة"
          : "Bring your ideas to life",
    },
    {
      name: "Coders Section",
      arabic_name: "قسم المطورين",
      link: "writecode",
      icon: homeCodingIcon,
      desc:
        lng === "arab"
          ? "لنبسط معًا عمليات البرمجة"
          : "Simplify your coding process",
    },
  ];

  // useEffect(()=>{
  //     if(offerTimerState.isTimeLeft()){
  //       setShowOffer(false);
  //     }
  // },[offerTimerState.seconds])

  return (
    <div
      style={{ padding: "0px", gap: "0px" }}
      className="border-radius-top homePageWrapper Homage_araby"
    >
      {loading ? bigLoading ? null : <LoadingPopUp gptLoading={true} /> : null}
      <div
        style={{
          margin: null,
          padding: "20px 20px 15px 15px",
          alignItems: "center",
        }}
        className="write_body border-radius-top"
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "5px",
            maxWidth: "1200px",
          }}
        >
          <div
            style={{ padding: "0px 15px 0px 15px" }}
            className="image_gen_header homePageWrapper"
          >
            <h1 style={{ fontWeight: "600" }} className="araby-gpt-text">
              {t("Welcome to Twwt AI")}
            </h1>
            <h5>{t("Select where you want to go and start using our AI")}</h5>
          </div>
        </div>
        <div
          style={{
            paddingBottom: "15px",
            minHeight: "300px",
            justifyContent: "space-evenly",
            columnGap: "20px",
            maxWidth: "1250px",
            width: "100%",
          }}
          className="all_images_wrapper row"
        >
          {features?.length === 0 ? (
            <label>{t("No Items Found")}</label>
          ) : (
            features?.map((item, i) => (
              <HomeCard
                item={item}
                key={i}
                desc={item?.desc}
                mainElem={true}
                goTo={item?.id || item?.category || item?.link}
              />
            ))
          )}
        </div>
        {/* {showOffer ? (
        <CopyToClipboard text="ARABY20" onCopy={handleCopy}>
          <div
            onClick={() => navigate("/plan")}
            style={{
              textAlign: lng === "arab" ? "right" : "left",
              padding: lng === "arab" ? "0 0 0 10px" : "0 10px 0 0",
              cursor: "pointer",
            }}
            className={styles.banner}
          >
            <div className={styles.contents}>
              <img src={gift} alt="" />
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h2>{t("Enjoy Our")}</h2>
                  <h1>
                    {t("20%")} <span>{t("OFF")}</span>
                  </h1>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h4
                    style={{
                      margin: 0,
                      fontSize: "1rem",
                      color: "white",
                      fontWeight: "800",
                    }}
                  >
                    {t("Use Code")}
                  </h4>
                  <h6
                    style={{
                      cursor: "pointer",
                      margin: "0 auto",
                      fontWeight: 700,
                      color: "white",
                      fontSize: "15px",
                      backgroundColor: "#632f95",
                      borderRadius: "5px",
                      padding: "5px",
                      width: "80px",
                    }}
                  >
                    {t("ARABY20")}
                  </h6>
                </div>
              </div>
              <div className={styles.column}>
                <div>
                  <p
                    style={{
                      color: "#FDA5FF",
                      textAlign: "center",
                      margin: "3px auto",
                    }}
                  >
                    {t("For Limited Time")}
                  </p>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <h6
                      style={{
                        color: "white",
                        backgroundColor: "#080F1A",
                        padding: "5px",
                        border: "0.2px solid white",
                        borderRadius: "5px",
                      }}
                    >
                      {offerTimerState.hours.toString().length === 1
                        ? "0" + offerTimerState.hours
                        : offerTimerState.hours}
                    </h6>
                    <h6
                      style={{
                        color: "white",
                        backgroundColor: "#080F1A",
                        padding: "5px",
                        border: "0.2px solid white",
                        borderRadius: "5px",
                      }}
                    >
                      {offerTimerState.minutes.toString().length === 1
                        ? "0" + offerTimerState.minutes
                        : offerTimerState.minutes}
                    </h6>
                    <h6
                      style={{
                        color: "white",
                        backgroundColor: "#080F1A",
                        padding: "5px",
                        border: "0.2px solid white",
                        borderRadius: "5px",
                      }}
                    >
                      {offerTimerState.seconds.toString().length === 1
                        ? "0" + offerTimerState.seconds
                        : offerTimerState.seconds}
                    </h6>
                  </div>
                </div>
                <div style={{ textAlign: "center" }}>
                  <button>{t("Claim Now")}</button>
                </div>
              </div>
            </div>
          </div>
        </CopyToClipboard>
      ) : null} */}
      </div>
    </div>
  );
};

export default Home;

const HomeCard = ({
  item,
  index,
  goTo,
  asIndex,
  asSubIndex,
  borderIn,
  programming,
  mainElem,
  desc,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const cardRef = useRef();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const textRef = useRef();
  const marqRef = useRef();
  const location = useLocation();
  const [displayMarq, setDisplayMarq] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [imageLoading, setImageLoading] = useState(true);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    if (cardRef.current && textRef.current) {
      if (cardRef.current.offsetWidth - textRef.current.offsetWidth <= 15) {
        setDisplayMarq(true);
      }
    }
    if (cardRef.current && marqRef.current) {
      if (cardRef.current.offsetWidth - marqRef.current.offsetWidth > 15) {
        setDisplayMarq(false);
      }
    }
  }, [
    cardRef.current?.offsetWidth,
    textRef.current,
    width,
    marqRef.current,
    location.pathname,
  ]);
  const onImageError = (e) => {
    e.target.src = defaultIcon;
  };
  return (
    <div
      key={index}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Link
        ref={cardRef}
        style={{}}
        to={`/${goTo}`}
        className="category_card_custom "
      >
        {imageLoading && (
          <div
            className="cat_img"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner animation="grow" />
          </div>
        )}
        <img
          onError={onImageError}
          onLoad={() => setImageLoading(false)}
          className={!imageLoading ? "cat_img" : "cat_img_null"}
          src={item?.icon || defaultIcon}
          alt="image_generated"
          style={{ width: item?.specialIcon ? "35%" : "50%", height: "auto" }}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!displayMarq ? (
            <span>
              <label
                style={{
                  whiteSpace: "nowrap",
                  direction: programming ? "ltr" : null,
                  margin: "0px",
                }}
                ref={textRef}
              >
                {t(item?.name)}
              </label>
            </span>
          ) : (
            <div
              className={lng === "arab" ? "cssmarquee_arab" : "cssmarquee"}
              style={{ direction: "ltr" }}
            >
              <Marquee
                speed={0.025}
                delay={0}
                childMargin={15}
                direction={lng === "arab" ? "right" : "left"}
                className="label_of_title"
              >
                <label
                  style={{ direction: programming ? "ltr" : null }}
                  ref={marqRef}
                >
                  {lng === "arab" ? item?.arabic_name : item?.name}
                </label>
              </Marquee>
            </div>
          )}
          <p
            style={{
              textAlign: lng === "arab" ? "center" : "center",
              color: "gray",
              margin: "0px",
              fontSize: "12px",
              width: "90%",
            }}
          >
            {t(desc)}
          </p>
        </div>
      </Link>
    </div>
  );
};
