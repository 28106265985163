import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { useTranslation } from 'react-i18next'
import PaymentForm from "components/PayModal/PaymentForm"
import SaveCardForm from './SaveCardForm'

const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY

const stripeTestPromise = loadStripe(PUBLIC_KEY)

const CARD_OPTIONS = {
    iconStyle: "solid",
    hidePostalCode: true,
    style: {
        base: {
            backgroundColor: 'white',
            height: "1000px",
            iconColor: "#c4f0ff",
            color: "black",
            fontWeight: 500,
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",

        },
        invalid: {
            iconColor: "#ffc7ee",
            color: "#ffc7ee"
        }
    }
}

const AddCardModal = (props) => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;

    return (
        <Modal
            className={lng === 'arab' ? "modal modal-primary arabicFont payment" : "modal modal-primary englishFont payment"}
            centered
            show={props.showModal}
            onHide={() => props.setShowModal(false)}
        >
            <Modal.Header>
                <h2>{t('Add Payment Method')}</h2>
            </Modal.Header>
            <Modal.Body>
                <Elements stripe={stripeTestPromise} style={{ padding: '0px 20px 0px 20px' }}>
                    <SaveCardForm showModal={props.showModal} setShowModal={props.setShowModal} />
                </Elements>

            </Modal.Body>
        </Modal>

    )
}

export default AddCardModal