import { LOAD_CHANGER, FETCH_CHANGER, CHANGER_LOADED, NEW_BACKGROUND_CHANGER, SHOW_BACKGROUND_CHANGER_FALSE, SHOW_BACKGROUND_CHANGER_TRUE, LOAD_PERCENTAGE_CHANGER } from "redux/contstants";

export default (backgroundChanger = { backgroundChanger: [], uploaded: [], loadingNumber: 0, loading: false, showRemover: false }, action) => {
  switch (action.type) {
    case LOAD_CHANGER:
      return { ...backgroundChanger, loading: true, loadingNumber: 0, showRemover: true };
    case FETCH_CHANGER:
      return { ...backgroundChanger, backgroundChanger: action.data, loadingNumber: 0 };
    case CHANGER_LOADED:
      return { ...backgroundChanger, loading: false };
    case NEW_BACKGROUND_CHANGER:
      return { ...backgroundChanger, backgroundChanger: [], loadingNumber: 0, uploaded: [], showRemover: false };
    case SHOW_BACKGROUND_CHANGER_FALSE:
      return { ...backgroundChanger, showRemover: false };
    case SHOW_BACKGROUND_CHANGER_TRUE:
      return { ...backgroundChanger, showRemover: true };
    case LOAD_PERCENTAGE_CHANGER:
      return { ...backgroundChanger, loadingNumber: action.data };


    default:
      return backgroundChanger;
  }
};
