import { LOAD_SCRIBBLE, FETCH_SCRIBBLE, SCRIBBLE_LOADED, LOAD_PERCENTAGE_SCRIBBLE, NEW_SCRIBBLE } from "redux/contstants";

export default (scribble = { scribble: [], loadingNumber: 0, loading: false }, action) => {
  switch (action.type) {
    case LOAD_SCRIBBLE:
      return { ...scribble, loadingNumber: 0, loading: true };
    case FETCH_SCRIBBLE:
      return { ...scribble, scribble: action.data, loadingNumber: 0 };
    case SCRIBBLE_LOADED:
      return { ...scribble, loading: false };
    case NEW_SCRIBBLE:
      return { ...scribble, scribble: [], loadingNumber: 0 };
    case LOAD_PERCENTAGE_SCRIBBLE:
      return { ...scribble, loadingNumber: action.data };
    default:
      return scribble;
  }
};
