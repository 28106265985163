import { baseUrl } from "api/baseUrl";
import SelectPaymentMethod from "components/SelectPaymentMethod/SelectPaymentMethod";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setUser } from "redux/actions/user";
import RevPaymentSection from "./RevPaymentSection";
import RevProfileDetails from "./RevProfileDetails";
import RevProfileForm from "./RevProfileForm";
import RevProfilePlan from "./RevProfilePlan";
import axios from "axios";
import Image from "react-bootstrap/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import checkErr from "redux/actions/api_error_func";
import { toastAlert } from "components/toastAlert/toastAlert";
import ErrorModal from "components/ErrorModal/ErrorModal";
import { isValidPhoneNumber } from "react-phone-number-input";
import BusinessProfile from "./businessProfile";
import { Link } from "react-router-dom";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import DeletePopUp from "./DeletePopUp";
const MAX_FILE_SIZE = 1024; // 1MB
import LimitPopUp from "views/plansComponent/LimitPopUp";

const RevProfile = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const user = useSelector((state) => state.user);
  const userSubscription = useSelector((state) => state.userSubscription);
  const userEnterprise = useSelector((state) => state.enterprise);
  const bigLoading = useSelector((state) => state.loading);
  const [loading, setLoading] = useState(true);
  const [defaultCard, setDefaultCard] = useState(undefined);
  const [view, setView] = useState(false);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const imageRef = useRef();
  const [file, setFile] = useState();
  const dispatch = useDispatch();
  const location = useLocation();
  const [updatedUserData, setUpdatedUserData] = useState(user);
  const [deleteAccountPopUp, setDeleteAccountPopUp] = useState(false);
  const [imageGenView, setImageGenView] = useState(
    location.pathname.split("/").includes("profile") ? 0 : 1
  );
  useEffect(() => {
    setLoading(bigLoading);
  }, [bigLoading]);
  useEffect(() => {
    if (!loading) {
      if (location.pathname.split("/").includes("profile")) {
        setImageGenView(0);
      } else {
        // if (user?.enterprise) {
        setImageGenView(1);
        // } else {
        // navigate('/profile')
        // }
      }
    }
  }, [location, loading, userEnterprise, user]);
  const [cusIframe, setcusIframe] = useState($(".tawk_arabi_custom"));
  const [cusIframeContents, setcusIframeContents] = useState(
    cusIframe.eq(0).contents()
  );
  const [cusBtn, setcusBtn] = useState(cusIframeContents.find(".tawk-button"));
  const [customCounter, setcustomCounter] = useState(true);
  const [customCounterNumbers, setcustomCounterNumbers] = useState(0);
  const [displayWidget, setDisplayWidget] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  // useEffect(() => {
  //     setcusIframe($(".tawk_arabi_custom"))

  //   }, [customCounterNumbers, customCounter])
  //   useEffect(() => {
  //     setcusIframeContents(cusIframe.eq(0).contents())
  //   }, [customCounterNumbers])
  //   useEffect(() => {
  //     setcusBtn(cusIframeContents.find('.tawk-button'))
  //   }, [customCounterNumbers])
  // const customerServ = useRef();
  // useEffect(() => {
  //     if (displayWidget) {
  //         if (location.pathname.split("/").includes("profile")) {
  //             if (user?.plan === 'Pro' || user?.plan === 'Authors' || user?.plan === 'Artist') {
  //                 if (width <= 900) {
  //                   customerServ.current?.hideWidget()
  //                 } else {
  //                   customerServ.current?.showWidget()
  //                 }
  //             } else {
  //                 customerServ.current?.hideWidget()
  //             }
  //         } else {
  //             customerServ.current?.hideWidget()
  //         }

  //     }
  //     return () => {displayWidget? customerServ.current?.hideWidget():null}
  // }, [width, displayWidget, user])

  const handleChange = (e) => {
    setFile(e.target.files[e.target.files.length - 1]);
  };
  const inputFile = useRef(null);

  const callbackForm = (data) => {
    setUpdatedUserData(data);
  };

  const getDefaultCard = async () => {
    setLoading(true);
    const response = await fetch(`${baseUrl}stripe/myPaymentMethods`, {
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("sess"),
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        if (data.success) {
          const myDefault = data.payment_methods.find((item) => {
            if (item.id === data.default) {
              setDefaultCard(item);
            }
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        checkErr(error, setLoading, toastAlert);
      });
    setLoading(false);
  };

  const updateUser = async () => {
    let formData = new FormData();

    if (updatedUserData.name != null) {
      formData.append("name", updatedUserData.name);
    }
    if (updatedUserData.email != null) {
      formData.append("email", updatedUserData.email);
    }
    if (updatedUserData.phone_number) {
      if (isValidPhoneNumber(updatedUserData.phone_number)) {
        formData.append("phone_number", updatedUserData.phone_number);
      } else {
        return toastAlert(
          "Please add a valid phone number",
          undefined,
          "warning"
        );
      }
    }
    if (updatedUserData.country != null) {
      formData.append("country", updatedUserData.country);
    }
    if (updatedUserData.date_of_birth != null) {
      formData.append("date_of_birth", updatedUserData.date_of_birth);
    }
    if (updatedUserData.occupation != null) {
      formData.append("occupation", updatedUserData.occupation);
    } else if (!updatedUserData.phone_number) {
      formData.append("phone_number", "-");
    }

    //convert to foradata
    if (file !== null && file?.size / 1024 < MAX_FILE_SIZE) {
      formData.append("file", file);
      const token = localStorage.getItem("token");
      let response = await axios.put(`${baseUrl}auth/updateUser`, formData, {
        Authorization: token,
      });

      if (response.status == 200) {
        dispatch(setUser(response.data.user));
        setShowForm(!showForm);
      }
    } else if (file !== null && file?.size / 1024 > MAX_FILE_SIZE) {
      checkErr("Large Image", false, toastAlert);
      setShowErrorModal(true);
      setFile(undefined);
      return;
    } else {
      try {
        const token = localStorage.getItem("token");
        var response = await axios.put(`${baseUrl}auth/updateUser`, formData, {
          Authorization: token,
        });
        if (response.status == 200) {
          dispatch(setUser(response.data.user));
          setShowForm(!showForm);
        }
      } catch (error) {
        if (error.message === "Network Error") {
          toastAlert(
            "Profile picture can't be more than 1 MB",
            undefined,
            "warning"
          );
          setShowErrorModal(true);
        } else {
          checkErr(error, false, toastAlert);
        }
      }
    }
  };
  const handleImgError = () => {
    if (imageRef.current) {
      imageRef.current.src =
        "https://riverlegacy.org/wp-content/uploads/2021/07/blank-profile-photo.jpeg";
    }
  };

  useEffect(() => {
    getDefaultCard();
    return () => {};
  }, [defaultCard]);

  return (
    <div className="border-radius-top">
      {/* <div className="categories_header border-radius-top">
            <div className="filter_wrapper border-radius-top">
                <Link 
                    style={{ width: "auto" }} 
                    onClick={() => setImageGenView(0)} 
                    to={`/profile`} 
                    className={imageGenView === 0 ? "filter_button active" : "filter_button"}
                >
                    <span style={{ fontSize: 18, textAlign: "center" }}>{t("Profile")}</span>
                </Link>

                {bigLoading?
                null:
                <Link 
                    style={{ width: "auto" }} 
                    onClick={() => setImageGenView(1)} 
                    to={`/business`} 
                    className={imageGenView === 1 ? "filter_button active" : "filter_button"}
                >
                    <span style={{ fontSize: 18, textAlign: "center" }}>{t("Business")}</span>
                </Link>}
            </div>
            </div> */}
      <div style={{ height: "auto" }} className="revamped-profile">
        {imageGenView === 0 ? (
          <>
            <div className="title-area">
              <h3 className="title">{t("My Profile")}</h3>
              {showForm ? (
                ""
              ) : (
                <button
                  className="editbtn"
                  style={{
                    marginRight: lng === "arab" ? "auto" : "0",
                    marginLeft: lng === "arab" ? "0" : "auto",
                  }}
                  onClick={() => setShowForm(true)}
                >
                  <h5 className="edit-profile">{t("Edit")}</h5>
                </button>
              )}
            </div>
            <hr className="hredit" />
            <div className="title-area card-body ">
              <div
                className="img-styling"
                onClick={() => (showForm ? inputFile.current.click() : {})}
                style={{
                  cursor: showForm ? "pointer" : "default",
                  borderRadius: showForm ? "50%" : "0",
                }}
              >
                {file != null ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt="user"
                    style={{ objectFit: "cover" }}
                  />
                ) : user.image != null ? (
                  <img
                    ref={imageRef}
                    onError={handleImgError}
                    src={user.image}
                    alt="user"
                    style={{ objectFit: "cover" }}
                  />
                ) : (
                  <Image
                    src="https://riverlegacy.org/wp-content/uploads/2021/07/blank-profile-photo.jpeg"
                    alt="user"
                    style={{ objectFit: "cover" }}
                  />
                )}
                {showForm ? (
                  <div className="edit-icon">
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      size="xs"
                      color="white"
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {showForm ? (
              <RevProfileForm user={user} onClickCallback={callbackForm} />
            ) : (
              <RevProfileDetails user={user} />
            )}
            {showForm && (
              <div className="title-area row justify-content-center no-gutters button_wrapper">
                <button
                  style={{ borderRadius: "15px" }}
                  onClick={() => {
                    if (showForm) {
                      updateUser();
                    } else {
                      setShowForm(!showForm);
                    }
                  }}
                  type="button"
                  className="btn btn-md d-flex justify-content-center text-center prof-btn mb-4"
                >
                  {showForm
                    ? t("Save")
                    : user?.occupation &&
                      user?.country &&
                      user?.image &&
                      user?.email &&
                      user?.date_of_birth &&
                      user?.name &&
                      !showForm
                    ? t("Edit Your Profile")
                    : t("Complete Your Profile")}
                </button>
                <button
                  style={{ borderRadius: "15px" }}
                  onClick={() => {
                    setDeleteAccountPopUp(true);
                  }}
                  type="button"
                  className="btn btn-md d-flex justify-content-center text-center prof-btn mb-4"
                >
                  {showForm && t("Delete Account")}
                </button>
              </div>
            )}
            <input
              type="file"
              hidden={true}
              accept="image/png, image/jpg, image/jpeg"
              ref={inputFile}
              onChange={handleChange}
            />
            {user?.enterprise &&
            userEnterprise?.enterprise?.owner === user?._id &&
            user?.plan !== "Freelancer" ? (
              <BusinessProfile />
            ) : (
              <div className="title-area plan-box row">
                <div className="col-12">
                  <RevProfilePlan
                    user={user}
                    userSubscription={userSubscription}
                    userEnterprise={userEnterprise}
                  />
                </div>
              </div>
            )}

            {/* <div className="title-area">
                <h3 className="title">{t("Payment")}</h3>
                <hr />
                <div
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
                >
                    <button
                        onClick={() => setShowModal(true)}
                        style={{ background: 'none', border: 'none' }}
                    >
                        <h5
                            className="edit-payment"
                        >
                            {t("Edit")}
                        </h5>
                    </button>

                </div>

                <RevPaymentSection card={defaultCard} setDefaultCard={setDefaultCard} />
            </div> */}

            <SelectPaymentMethod
              defaultCard={defaultCard}
              setDefaultCard={setDefaultCard}
              setShowModal={setShowModal}
              showModal={showModal}
            />
            <ErrorModal
              setShowModal={setShowErrorModal}
              showModal={showErrorModal}
              errorMessage={t(
                "File size is too large, kindly upload an image less than 1MB"
              )}
            />
          </>
        ) : (
          <>
            {/* <div className="title-area plan-box-second row">
                    <div className='col-12'> */}
            {/* <BusinessProfile/> */}
            {/* </div>
                </div> */}
          </>
        )}
      </div>
      {/* <TawkMessengerReact
        ref={customerServ}
        onLoad={() => setDisplayWidget(true)}
        propertyId="6412bab531ebfa0fe7f2e4ef"      
        widgetId="1grkiilsu"
      /> */}
      <DeletePopUp
        show={deleteAccountPopUp}
        onHide={() => setDeleteAccountPopUp(false)}
      />
    </div>
  );
};

export default RevProfile;
