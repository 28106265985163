import React,{useEffect, useState} from 'react'
import { SortableContainer } from 'react-sortable-hoc';
import SortableItem from './SortableItem';

const SortableSlides = ({items, setItems, editSlides, setNewData, newData,textUpdates,setTextUpdates, removeSlide}) => {
  const [data, setData] = useState([])
  
  useEffect(() => {
    let newFormat = [];
    for (const key in items?.titles) {
      newFormat.push({ slideNumber: key, value: items?.titles[key] });
      setData(newFormat?.filter((item) => item?.value));
    }
  }, [items.titles, items.counter, textUpdates]);

  return (
    <div>
      {data?.map((item, index) => (
        <SortableItem removeSlide={removeSlide} textUpdates editSlides={editSlides} items={items} setTextUpdates={setTextUpdates} setItems={setItems} setNewData={setNewData} newData={newData} key={index} myKey={index} index={index} data={data} setData={setData} value={item?.value} />
      ))}
    </div>
  );
}

export default SortableContainer(SortableSlides);