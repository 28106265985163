import React from 'react'
import { Editor } from "@tinymce/tinymce-react";
import "./Rte.css";

const Rte = ({ content, setContent }) => {
    const handleEditorChange = (data) => {
        setContent(data);
    }

  return (
    <Editor
        value={content}
        init={{
            branding: false,
            height: 500,
            menubar: false,
            plugins: 'textcolor fontweight anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss',
            toolbar: 'forecolor fontweight backcolor | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
        }}
        onEditorChange={handleEditorChange}
        content_style="border: 1px solid #E3E3E3"
    />
  )
}

export default Rte