
import {
    FETCH_UPSCALE_V1,
    LOAD_UPSCALE_V1,
    LOADED_UPSCALE_V1,
    NEW_UPSCALE_V1,
    SHOW_UPSCALE_V1_FALSE,
    SHOW_UPSCALE_V1_TRUE
} from "redux/contstants";

export default (upscaleV1 = { upscaleV1: [], uploaded: [], loading: false, showRemover: false }, action) => {
    switch (action.type) {
        case LOAD_UPSCALE_V1:
            return { ...upscaleV1, loading: true, showRemover: true };
        case FETCH_UPSCALE_V1:
            return { ...upscaleV1, upscaleV1: action.data };
        case LOADED_UPSCALE_V1:
            return { ...upscaleV1, loading: false };
        case NEW_UPSCALE_V1:
            return { ...upscaleV1, upscaleV1: [], uploaded: [], showRemover: false };
        case SHOW_UPSCALE_V1_FALSE:
            return { ...upscaleV1, showRemover: false };
        case SHOW_UPSCALE_V1_TRUE:
            return { ...upscaleV1, showRemover: true };
        default:
            return upscaleV1;
    }
};