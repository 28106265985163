import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import infoIcon from "../assets/img/newDesign/info.png";
import { Link, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import backArow from "../assets/img/newDesign/backArrow.svg";
import { useNavigate } from "react-router-dom";
import { aiAPI } from "api";
import { useDispatch } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";
import { toastAlert } from "components/toastAlert/toastAlert";
import UpgradeModal from "components/UpgradeModal/UpgradeModal";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css"; // optional for styling
import "tippy.js/themes/light.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import plansLimits from "assets/data/plansLimits";
import LoadingEffect from "components/Loading/LoadingEffect";
import { socket } from "../socket.js";
import LimitPopUp from "./plansComponent/LimitPopUp";
var arr1 = [[], [], []];
var arr2 = [[], [], []];
var arr3 = [[], [], []];
var arr4 = [[], [], []];
var table = {};
const SubCategory = () => {
  // Four arrays for 4 output cards
  //For each array
  // index[0]:text, index[1]:prediction, index[2]: word_count

  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const { name, id } = useParams();
  const indexRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const elementWithoutTone = [37, 39, 42, 43, 44, 46, 47, 48, 49, 50, 51, 52, 59, 57, 54, 55, 56, 58];
  const [loadingSwitch, setLoadingSwitch] = useState(false);
  const [innerLoadingSwitch, setInnerLoadingSwitch] = useState(false);
  const [filterMethod, setFilterMethod] = useState(name || "all");
  const { cases, loading, category, tone, languages } = useSelector((state) => state?.cases);
  const [caregories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [finalElemnt, setFinalElement] = useState();
  const [toneInput, setToneInput] = useState("");
  const [numberOfOutputs, setNumberOfOutputs] = useState(3);
  const [languageInput, setLanguageInput] = useState();
  const [language, setLanguage] = useState(lng === "arab" ? "Arabic" : "English");
  const [textGenerated, setTextGenerated] = useState([]);
  const user = useSelector((state) => state.user);
  const [wordLimit, setWordLimit] = useState(0);
  const [totalWords, setTotalWords] = useState(0);
  const [imageLimit, setImageLimit] = useState(0);
  const [totalImages, setTotalImages] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const userSubscription = useSelector((state) => state.userSubscription);
  const userEnterprise = useSelector((state) => state.enterprise);
  const [claimPopUp, setClaimPopUp] = useState(false);
  const [arr1a, setArr1] = useState([]);
  const [arr2a, setArr2] = useState([]);
  const [arr3a, setArr3] = useState([]);
  const [arr4a, setArr4] = useState([]);
  //installing the socket
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [modified, setModified] = useState("");

  const formats = ["header", "font", "size", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image", "video"];

  const [width, setWidth] = useState(window.innerWidth);
  const [currentFormats, setCurrentFormats] = useState(formats);

  const isMobile = width <= 768;
  const isTablet = width <= 850;
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    setCurrentFormats(isMobile || isTablet ? ["size", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image", "video"] : formats);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setImageLimit(userSubscription?.product?.metadata?.image_count_left || plansLimits[user?.plan]?.image_limit || userEnterprise?.enterprise?.image_limit);
    setWordLimit(userSubscription?.product?.metadata?.text_count_left || plansLimits[user?.plan]?.word_limit || userEnterprise?.enterprise?.text_limit);
    setTotalWords(user.text_count_left);
    setTotalImages(user?.image_count_left);
  }, [userSubscription, user, userEnterprise]);

  const [dataObj, setDataObj] = useState(
    finalElemnt
      ? finalElemnt?.inputs?.map((item) => {
        return { [item.key]: "" };
      })
      : []
  );
  const [indexes, setIndexes] = useState([]);
  const [errorHandling, setErrorHandling] = useState({ status: false, message: "" });
  const location = useLocation();
  useEffect(() => {
    setFilterMethod(name || "all");
    if (name) {
      let element = category
        ?.filter((item) => item?.category === name)
        ?.slice(0)
        ?.shift();
      if (element) {
        setErrorHandling({ status: false, message: "" });
        let arr = category?.filter((item) => item?.category !== name);
        arr.unshift(element);
        setIndexes(arr);
        if (indexRef) {
          indexRef.current?.scroll(0, 0);
        }
        let subC = cases?.filter((item) => item?.category === name);
        setSubCategories(subC);
        if (id) {
          let subElement = cases
            ?.filter((item) => item?.id == id)
            ?.slice(0)
            ?.shift();
          if (subElement) {
            setDataObj(
              subElement?.inputs?.map((item) => {
                return { [item.key]: "" };
              })
            );
            setErrorHandling({ status: false, message: "" });
            setFinalElement(subElement);
          } else {
            setErrorHandling({ status: true, message: "Please select another sub category" });
          }
        }
      } else {
        setErrorHandling({ status: true, message: "Please select another case" });
      }
    }
  }, [name, id, category, cases]);
  useEffect(() => {
    setLoadingSwitch(loading);
    setCategories(category);
  }, [loading]);
  const handleFormChange = (e, i) => {
    let arr = dataObj.slice(0);
    let arrwith = arr?.filter((item, index) => index !== i);
    let neededObj = arr
      ?.filter((item, index) => index === i)
      ?.slice(0)
      ?.shift();
    neededObj[e.target.name] = e.target.value;
    arr.splice(i, 1, neededObj);
    setDataObj(arr);
  };

  const handleRegularShare = () => {
    toastAlert("Copied", "Text has been copied to clipboard", "success");
  };
  useEffect(() => {
    tippy("#engagment_tool", {
      // content: ,
      content: `<label class=${lng === "arab" ? "tooltip_styling_ar" : "tooltip_styling"}>${t("It is the expected effectiveness of this text according to several factors")}</label>`,
      allowHTML: true,
      theme: "light",
      popperOptions: {
        strategy: "fixed",
        modifiers: [
          {
            name: "flip",
            options: {
              fallbackPlacements: ["top", "right"],
            },
          },
        ],
      },
    });
  }, [textGenerated]);
  useEffect(() => {
    socket.connect();

    return () => {
      (arr1 = [[], [], []]), (arr2 = [[], [], []]), (arr3 = [[], [], []]), (arr4 = [[], [], []]), (table = {});
      socket.disconnect();
    };
  }, [location.pathname]);
  useEffect(() => {
    let token = { token: localStorage.getItem("sess") };
    if (socket.connected) {
      socket.emit("token", token);
    }
  }, [socket.connected]);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
      console.log("connected");
    }
    function onDisconnect() {
      setIsConnected(false);
      console.log("disconnected");
    }
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    return () => {
      dispatch({ type: "FETCH_USER" });
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
  }, []);

  const generateOutput = (e) => {
    e.preventDefault();
    setInnerLoadingSwitch(true);

    finalElemnt?.inputs?.map((item, i) => dataObj[i][item?.key] === "" && setInnerLoadingSwitch(false));

    setLanguageInput(language);
    for (let i = 0; i < dataObj.length; i++) {
      for (const [key, value] of Object.entries(dataObj[i], "onedata")) {
        if (!value) {
          toastAlert("Fill all necessary inputs");
          return;
        }
        table[key] = value;
      }
    }

    (arr1 = [[], [], []]), (arr2 = [[], [], []]), (arr3 = [[], [], []]), (arr4 = [[], [], []]);

    e.preventDefault();
    let request = {
      token: localStorage.getItem("sess"),
      inputs: [table],

      language: language,
      number: numberOfOutputs,
      useCase: finalElemnt?.key,
      action: "generate_writenow",
      tone: toneInput,
    };
    socket.emit("request", request, () => { });
  };

  useEffect(() => {
    const onMessagesEvent = async (value) => {
      if (value?.data?.message?.profanity) {
        return toastAlert(t(`Vulgar words are not allowed`));
      }
      if (value?.data?.streaming == true) {
        if (value?.data?.message?.index == 0) {
          arr1[0].push(value?.data?.message?.text);
          // add all the value to the array state
          setArr1(arr1[0].join(""));
          if (value?.data?.message?.performance_prediction) {
            arr1[1].push(value?.data?.message?.performance_prediction);
          }
          if (value?.data?.message?.word_count) {
            arr1[2].push(value?.data?.message?.word_count);
          }
        }
        if (value?.data?.message?.index == 1) {
          arr2[0].push(value?.data?.message?.text);
          setArr2(arr2[0].join(""));
          if (value?.data?.message?.performance_prediction) {
            arr2[1].push(value?.data?.message?.performance_prediction);
          }
          if (value?.data?.message?.word_count) {
            arr2[2].push(value?.data?.message?.word_count);
          }
        }
        if (value?.data?.message?.index == 2) {
          arr3[0].push(value?.data?.message?.text);
          setArr3(arr3[0].join(""));
          if (value?.data?.message?.performance_prediction) {
            arr3[1].push(value?.data?.message?.performance_prediction);
          }
          if (value?.data?.message?.word_count) {
            arr3[2].push(value?.data?.message?.word_count);
          }
        }
        if (value?.data?.message?.index == 3) {
          arr4[0].push(value?.data?.message?.text);
          setArr4(arr4[0].join(""));
          if (value?.data?.message?.performance_prediction) {
            arr4[1].push(value?.data?.message?.performance_prediction);
          }
          if (value?.data?.message?.word_count) {
            arr4[2].push(value?.data?.message?.word_count);
          }
        }
      } else {
        setInnerLoadingSwitch(false);
      }
      setModified(value);
    };

    socket.on("data", onMessagesEvent);
    return () => {
      socket.off("data", onMessagesEvent);
    };
  }, [modified]);

  var arabic = /[\u0600-\u06FF]/;
  return (
    <div className="inner_content">
      <div className="categories_header subCtegoryHeader">
        <Link to={`/writenow/${name}`} className="sub_case_header">
          <img src={backArow} alt="back_button" />
          <label style={{ direction: arabic.test(lng === "arab" ? finalElemnt?.arabic_name : finalElemnt?.name) ? "rtl" : "ltr" }}>
            {lng === "arab" ? finalElemnt?.arabic_name : finalElemnt?.name}
          </label>
        </Link>
      </div>
      <div style={{ margin: id ? "0px" : null, maxWidth: id ? "unset" : null, padding: "unset" }} className="write_body inner_writeBody container ">
        {errorHandling.status ? (
          <div className="all_images_wrapper">
            <label>{errorHandling?.message}</label>
          </div>
        ) : (
          <div className="sub_case_page_wrapper row" style={{ marginRight: 0, marginLeft: 0 }}>
            <div style={{ backgroundColor: "#fafdff", overflow: "auto" }} className="left_hand_side left_hand_side_form col-lg-5 col-md-6 col-sm-12">
              <form onSubmit={generateOutput}>
                <div style={{ textAlign: lng === "arab" ? "right" : null, flexDirection: "row" }} className="upperForm_wrraper row">
                  {finalElemnt?.inputs?.map((item, i) => (
                    <div key={i} className={item?.key === "sender" || item?.key === "recipient" ? "form-group col-lg-6 col-md-12 col-sm-12" : "form-group col-lg-12 col-md-12 col-sm-12"}>
                      <label>{lng === "arab" ? item?.arabic_name : item?.name}</label>
                      {item?.text_type === "textarea" || item?.text_type === "text" ? (
                        <TextareaAutosize
                          className="form-control"
                          style={{ overflow: "hidden" }}
                          type={item?.text_type}
                          placeholder={lng === "arab" ? item?.arabic_placeholder : item?.placeholder}
                          name={item?.key}
                          value={dataObj[i][item?.key]}
                          onChange={(e) => handleFormChange(e, i)}
                        />
                      ) : (
                        <input
                          className="form-control"
                          type={item?.text_type}
                          placeholder={lng === "arab" ? item?.arabic_placeholder : item?.placeholder}
                          name={item?.key}
                          value={dataObj[i][item?.key]}
                          onChange={(e) => handleFormChange(e, i)}
                        />
                      )}
                    </div>
                  ))}
                  <div className="form-group col-lg-12 col-md-12 col-sm-12">
                    <label>{t("Please select the language")}</label>
                    <select className="form-control" value={language} onChange={(e) => setLanguage(e.target.value)}>
                      <option value={""} disabled>
                        {t("Select Language")}
                      </option>
                      {languages?.map((item, i) => (
                        <option key={i} value={item?.key}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {elementWithoutTone?.indexOf(finalElemnt?.id) === -1 ? (
                    <div className="form-group col-lg-12 col-md-12 col-sm-12">
                      <label>{t("How Do You Want The Caption To Sound?")}</label>
                      <select className="form-control" value={toneInput} onChange={(e) => setToneInput(e.target.value)}>
                        <option value={""} disabled>
                          {t("Select Tone")}
                        </option>
                        {tone?.map((item, i) => (
                          <option key={i} value={item?.key}>
                            {lng === "arab" ? item?.Arabic_Name : item?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : null}
                  <div className="form-group col-lg-12 col-md-12 col-sm-12">
                    <label>{t("How many output cards do you need?")}</label>
                    <div className="output_number">
                      <button
                        disabled={innerLoadingSwitch}
                        style={numberOfOutputs === 1 ? { outline: "2px solid #4472f16c", color: "#4472f1da" } : null}
                        type="button"
                        onClick={() => setNumberOfOutputs(1)}
                      >
                        1
                      </button>
                      <button
                        disabled={innerLoadingSwitch}
                        type="button"
                        style={numberOfOutputs === 2 ? { outline: "2px solid #4472f16c", color: "#4472f1da" } : null}
                        onClick={() => setNumberOfOutputs(2)}
                      >
                        2
                      </button>
                      <button
                        disabled={innerLoadingSwitch}
                        type="button"
                        style={numberOfOutputs === 3 ? { outline: "2px solid #4472f16c", color: "#4472f1da" } : null}
                        onClick={() => setNumberOfOutputs(3)}
                      >
                        3
                      </button>
                      <button
                        disabled={innerLoadingSwitch}
                        type="button"
                        style={numberOfOutputs === 4 ? { outline: "2px solid #4472f16c", color: "#4472f1da" } : null}
                        onClick={() => setNumberOfOutputs(4)}
                      >
                        4
                      </button>
                    </div>
                  </div>
                </div>
                <button
                  disabled={innerLoadingSwitch}
                  style={{
                    padding: isMobile || isTablet ? "15px 40px !important" : "15px 60px !important",
                    background: innerLoadingSwitch ? "gray" : "linear-gradient(45deg, rgba(17, 113, 216, 1) 0%, rgba(60, 200, 235, 1) 100%)",
                    pointerEvents: innerLoadingSwitch && "none",
                    width: "230px",
                  }}
                  className="btn common-btn"
                  type="submit"
                >
                  {innerLoadingSwitch ? <LoadingEffect /> : t("Generate")}
                </button>
                {user?.plan === "Trial" ? (
                  <UpgradeModal setShowModal={setShowModal} showModal={showModal} />


                ) : (
                  <LimitPopUp setShowModal={setShowModal} showModal={showModal} />
                )}
              </form>
            </div>

            <div
              style={textGenerated?.text?.choices?.length > 0 ? { alignItems: "flex-start", overflow: "auto", height: "100%" } : null}
              className="right_hand_side_category row col-lg-7 col-md-6 col-sm-12 col-12"
            >
              {/* {innerLoadingSwitch ? (
                <>
                  <SkeletonTheme>
                    {numberOfOutputs === 1 ? (
                      <SkeletonCount />
                    ) : numberOfOutputs === 2 ? (
                      <>
                        <SkeletonCount />
                        <SkeletonCount />
                      </>
                    ) : numberOfOutputs === 3 ? (
                      <>
                        <SkeletonCount />
                        <SkeletonCount />
                        <SkeletonCount />
                      </>
                    ) : (
                      <>
                        <SkeletonCount />
                        <SkeletonCount />
                        <SkeletonCount />
                        <SkeletonCount />
                      </>
                    )}
                  </SkeletonTheme>
                </>
              ) : null
                (
                  <>{textGenerated?.text?.choices?.length === 0 || !textGenerated?.text?.choices && <label>{t("Please Fill the form")}</label>}</>
                )
              } */}
              <div>
                {
                  <>
                    {arr1[0].length !== 0 ? (
                      <div
                        style={{
                          direction: languageInput === "Arabic" ? "rtl" : "ltr",
                          textAlign: languageInput === "Arabic" ? "right" : "left",
                          overflow: "auto",
                          margin: "20px auto",
                          minWidth: "100px",
                        }}
                        className="text_card col-sm-11"
                      >
                        <div className="text_card_content">
                          <p className={languageInput === "Arabic" ? "arabicFont" : "englishFont"} style={{ fontFamily: languageInput === "Arabic" ? '"Tajawal"' : null, whiteSpace: "pre-wrap" }}>
                            {arr1a}
                          </p>

                          {
                            <div className="engagment_wrapper">
                              <div style={{ height: "60px", width: "60px" }}>
                                <CircularProgressbar
                                  counterClockwise={true}
                                  value={Math.round(arr1[1].toString())}
                                  text={`${Math.round(arr1[1].toString())}%`}
                                  maxValue={99}
                                  strokeWidth={9}
                                  styles={buildStyles({
                                    rotation: 0,
                                    strokeLinecap: "round",
                                    pathTransitionDuration: 1,
                                    pathColor:
                                      Math.round(arr1[1].toString()) >= 75
                                        ? "#35c235"
                                        : Math.round(arr1[1].toString()) >= 50
                                          ? "#3894f4"
                                          : Math.round(arr1[1].toString()) >= 25
                                            ? "rgb(234 183 37)"
                                            : "gray",
                                    textColor:
                                      Math.round(arr1[1].toString()) >= 75
                                        ? "#35c235"
                                        : Math.round(arr1[1].toString()) >= 50
                                          ? "#3894f4"
                                          : Math.round(arr1[1].toString()) >= 25
                                            ? "rgb(234 183 37)"
                                            : "gray",
                                  })}
                                />
                              </div>
                              <label className="engagment_text">
                                <span>{t("Engagement")}</span>
                                <span id="engagment_tool">
                                  <img src={infoIcon} alt="info_icon" />
                                </span>
                              </label>
                            </div>
                          }
                        </div>
                        <div className="text_card_footer">
                          <p className={languageInput === "Arabic" ? "arabicFont" : "englishFont"} style={{ fontFamily: languageInput === "Arabic" ? '"Tajawal"' : null, opacity: "0.5" }}>
                            {arr1[2].toString()}
                            {arr1[2] > 0 ? (languageInput === "Arabic" ? "كلمة" : " words") : null}
                          </p>
                          <div className="text_card_actions_wrapper">
                            <CopyToClipboard onCopy={() => handleRegularShare()} text={arr1[0].join("")}>
                              <button className="words_action_btn">
                                <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="12.424" height="12.424" viewBox="0 0 12.424 12.424">
                                  <g id="Group_2026" data-name="Group 2026">
                                    <path
                                      id="Path_10306"
                                      data-name="Path 10306"
                                      d="M13.117,2.16H5.543A1.466,1.466,0,0,0,4.081,3.627v.454H3.622A1.466,1.466,0,0,0,2.16,5.548v7.569a1.466,1.466,0,0,0,1.462,1.467H11.2a1.468,1.468,0,0,0,1.467-1.467v-.454h.454A1.469,1.469,0,0,0,14.584,11.2V3.627A1.469,1.469,0,0,0,13.117,2.16ZM11.765,13.117a.57.57,0,0,1-.569.569H3.622a.567.567,0,0,1-.565-.569V5.548a.568.568,0,0,1,.565-.569H11.2a.57.57,0,0,1,.569.569v7.569ZM13.686,11.2a.57.57,0,0,1-.569.569h-.454V5.548A1.468,1.468,0,0,0,11.2,4.081H4.978V3.627a.567.567,0,0,1,.565-.569h7.574a.57.57,0,0,1,.569.569Z"
                                      transform="translate(-2.16 -2.16)"
                                      fill="#b1b1b1"
                                    />
                                  </g>
                                </svg>
                              </button>
                            </CopyToClipboard>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {arr2[0].length !== 0 ? (
                      <div
                        style={{ direction: languageInput === "Arabic" ? "rtl" : "ltr", textAlign: languageInput === "Arabic" ? "right" : "left", overflow: "auto", margin: "20px auto" }}
                        className="text_card col-sm-11"
                      >
                        {" "}
                        <div className="text_card_content">
                          <p className={languageInput === "Arabic" ? "arabicFont" : "englishFont"} style={{ fontFamily: languageInput === "Arabic" ? '"Tajawal"' : null, whiteSpace: "pre-wrap" }}>
                            {arr2a}
                          </p>

                          {
                            <div className="engagment_wrapper">
                              <div style={{ height: "60px", width: "60px" }}>
                                <CircularProgressbar
                                  counterClockwise={true}
                                  value={Math.round(arr2[1])}
                                  text={`${Math.round(arr2[1])}%`}
                                  maxValue={99}
                                  strokeWidth={9}
                                  styles={buildStyles({
                                    rotation: 0,
                                    strokeLinecap: "round",
                                    pathTransitionDuration: 1,
                                    pathColor: Math.round(arr2[1]) >= 75 ? "#35c235" : Math.round(arr2[1]) >= 50 ? "#3894f4" : Math.round(arr2[1]) >= 25 ? "rgb(234 183 37)" : "gray",
                                    textColor: Math.round(arr2[1]) >= 75 ? "#35c235" : Math.round(arr2[1]) >= 50 ? "#3894f4" : Math.round(arr2[1]) >= 25 ? "rgb(234 183 37)" : "gray",
                                  })}
                                />
                              </div>
                              <label className="engagment_text">
                                <span>{t("Engagement")}</span>
                                <span id="engagment_tool">
                                  <img src={infoIcon} alt="info_icon" />
                                </span>
                              </label>
                            </div>
                          }
                        </div>
                        <div className="text_card_footer">
                          <p className={languageInput === "Arabic" ? "arabicFont" : "englishFont"} style={{ fontFamily: languageInput === "Arabic" ? '"Tajawal"' : null, opacity: "0.5" }}>
                            {arr2[2].toString()}
                            {arr2[2] > 0 ? (languageInput === "Arabic" ? "كلمة" : " words") : null}
                          </p>
                          <div className="text_card_actions_wrapper">
                            <CopyToClipboard onCopy={() => handleRegularShare()} text={arr2[0].join("")}>
                              <button className="words_action_btn">
                                <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="12.424" height="12.424" viewBox="0 0 12.424 12.424">
                                  <g id="Group_2026" data-name="Group 2026">
                                    <path
                                      id="Path_10306"
                                      data-name="Path 10306"
                                      d="M13.117,2.16H5.543A1.466,1.466,0,0,0,4.081,3.627v.454H3.622A1.466,1.466,0,0,0,2.16,5.548v7.569a1.466,1.466,0,0,0,1.462,1.467H11.2a1.468,1.468,0,0,0,1.467-1.467v-.454h.454A1.469,1.469,0,0,0,14.584,11.2V3.627A1.469,1.469,0,0,0,13.117,2.16ZM11.765,13.117a.57.57,0,0,1-.569.569H3.622a.567.567,0,0,1-.565-.569V5.548a.568.568,0,0,1,.565-.569H11.2a.57.57,0,0,1,.569.569v7.569ZM13.686,11.2a.57.57,0,0,1-.569.569h-.454V5.548A1.468,1.468,0,0,0,11.2,4.081H4.978V3.627a.567.567,0,0,1,.565-.569h7.574a.57.57,0,0,1,.569.569Z"
                                      transform="translate(-2.16 -2.16)"
                                      fill="#b1b1b1"
                                    />
                                  </g>
                                </svg>
                              </button>
                            </CopyToClipboard>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {arr3[0].length !== 0 ? (
                      <div
                        style={{ direction: languageInput === "Arabic" ? "rtl" : "ltr", textAlign: languageInput === "Arabic" ? "right" : "left", overflow: "auto", margin: "20px auto" }}
                        className="text_card col-sm-11"
                      >
                        <div className="text_card_content">
                          <p className={languageInput === "Arabic" ? "arabicFont" : "englishFont"} style={{ fontFamily: languageInput === "Arabic" ? '"Tajawal"' : null, whiteSpace: "pre-wrap" }}>
                            {arr3a}
                          </p>

                          {
                            <div className="engagment_wrapper">
                              <div style={{ height: "60px", width: "60px" }}>
                                <CircularProgressbar
                                  counterClockwise={true}
                                  value={Math.round(arr3[1])}
                                  text={`${Math.round(arr3[1])}%`}
                                  maxValue={99}
                                  strokeWidth={9}
                                  styles={buildStyles({
                                    rotation: 0,
                                    strokeLinecap: "round",
                                    pathTransitionDuration: 1,
                                    pathColor: Math.round(arr3[1]) >= 75 ? "#35c235" : Math.round(arr3[1]) >= 50 ? "#3894f4" : Math.round(arr3[1]) >= 25 ? "rgb(234 183 37)" : "gray",
                                    textColor: Math.round(arr3[1]) >= 75 ? "#35c235" : Math.round(arr3[1]) >= 50 ? "#3894f4" : Math.round(arr3[1]) >= 25 ? "rgb(234 183 37)" : "gray",
                                  })}
                                />
                              </div>
                              <label className="engagment_text">
                                <span>{t("Engagement")}</span>
                                <span id="engagment_tool">
                                  <img src={infoIcon} alt="info_icon" />
                                </span>
                              </label>
                            </div>
                          }
                        </div>
                        <div className="text_card_footer">
                          <p className={languageInput === "Arabic" ? "arabicFont" : "englishFont"} style={{ fontFamily: languageInput === "Arabic" ? '"Tajawal"' : null, opacity: "0.5" }}>
                            {arr3[2].toString()}
                            {arr3[2] > 0 ? (languageInput === "Arabic" ? "كلمة" : " words") : null}
                          </p>
                          <div className="text_card_actions_wrapper">
                            <CopyToClipboard onCopy={() => handleRegularShare()} text={arr3[0].join("")}>
                              <button className="words_action_btn">
                                <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="12.424" height="12.424" viewBox="0 0 12.424 12.424">
                                  <g id="Group_2026" data-name="Group 2026">
                                    <path
                                      id="Path_10306"
                                      data-name="Path 10306"
                                      d="M13.117,2.16H5.543A1.466,1.466,0,0,0,4.081,3.627v.454H3.622A1.466,1.466,0,0,0,2.16,5.548v7.569a1.466,1.466,0,0,0,1.462,1.467H11.2a1.468,1.468,0,0,0,1.467-1.467v-.454h.454A1.469,1.469,0,0,0,14.584,11.2V3.627A1.469,1.469,0,0,0,13.117,2.16ZM11.765,13.117a.57.57,0,0,1-.569.569H3.622a.567.567,0,0,1-.565-.569V5.548a.568.568,0,0,1,.565-.569H11.2a.57.57,0,0,1,.569.569v7.569ZM13.686,11.2a.57.57,0,0,1-.569.569h-.454V5.548A1.468,1.468,0,0,0,11.2,4.081H4.978V3.627a.567.567,0,0,1,.565-.569h7.574a.57.57,0,0,1,.569.569Z"
                                      transform="translate(-2.16 -2.16)"
                                      fill="#b1b1b1"
                                    />
                                  </g>
                                </svg>
                              </button>
                            </CopyToClipboard>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {arr4[0].length !== 0 ? (
                      <div
                        style={{ direction: languageInput === "Arabic" ? "rtl" : "ltr", textAlign: languageInput === "Arabic" ? "right" : "left", overflow: "auto", margin: "20px auto" }}
                        className="text_card col-sm-11"
                      >
                        <div className="text_card_content">
                          <p className={languageInput === "Arabic" ? "arabicFont" : "englishFont"} style={{ fontFamily: languageInput === "Arabic" ? '"Tajawal"' : null, whiteSpace: "pre-wrap" }}>
                            {arr4a}
                          </p>
                          {
                            <div className="engagment_wrapper">
                              <div style={{ height: "60px", width: "60px" }}>
                                <CircularProgressbar
                                  counterClockwise={true}
                                  value={Math.round([arr4[1]])}
                                  text={`${Math.round(arr4[1])}%`}
                                  maxValue={99}
                                  strokeWidth={9}
                                  styles={buildStyles({
                                    rotation: 0,
                                    strokeLinecap: "round",
                                    pathTransitionDuration: 1,
                                    pathColor: Math.round(arr4[1]) >= 75 ? "#35c235" : Math.round(arr4[1]) >= 50 ? "#3894f4" : Math.round(arr4[1]) >= 25 ? "rgb(234 183 37)" : "gray",
                                    textColor: Math.round(arr4[1]) >= 75 ? "#35c235" : Math.round(arr4[1]) >= 50 ? "#3894f4" : Math.round(arr4[1]) >= 25 ? "rgb(234 183 37)" : "gray",
                                  })}
                                />
                              </div>
                              <label className="engagment_text">
                                <span>{t("Engagement")}</span>
                                <span id="engagment_tool">
                                  <img src={infoIcon} alt="info_icon" />
                                </span>
                              </label>
                            </div>
                          }
                        </div>
                        <div className="text_card_footer">
                          <p className={languageInput === "Arabic" ? "arabicFont" : "englishFont"} style={{ fontFamily: languageInput === "Arabic" ? '"Tajawal"' : null, opacity: "0.5" }}>
                            {arr4[2].toString()}
                            {arr4[2] > 0 ? (languageInput === "Arabic" ? "كلمة" : " words") : null}
                          </p>
                          <div className="text_card_actions_wrapper">
                            <CopyToClipboard onCopy={() => handleRegularShare()} text={arr4[0].join("")}>
                              <button className="words_action_btn">
                                <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="12.424" height="12.424" viewBox="0 0 12.424 12.424">
                                  <g id="Group_2026" data-name="Group 2026">
                                    <path
                                      id="Path_10306"
                                      data-name="Path 10306"
                                      d="M13.117,2.16H5.543A1.466,1.466,0,0,0,4.081,3.627v.454H3.622A1.466,1.466,0,0,0,2.16,5.548v7.569a1.466,1.466,0,0,0,1.462,1.467H11.2a1.468,1.468,0,0,0,1.467-1.467v-.454h.454A1.469,1.469,0,0,0,14.584,11.2V3.627A1.469,1.469,0,0,0,13.117,2.16ZM11.765,13.117a.57.57,0,0,1-.569.569H3.622a.567.567,0,0,1-.565-.569V5.548a.568.568,0,0,1,.565-.569H11.2a.57.57,0,0,1,.569.569v7.569ZM13.686,11.2a.57.57,0,0,1-.569.569h-.454V5.548A1.468,1.468,0,0,0,11.2,4.081H4.978V3.627a.567.567,0,0,1,.565-.569h7.574a.57.57,0,0,1,.569.569Z"
                                      transform="translate(-2.16 -2.16)"
                                      fill="#b1b1b1"
                                    />
                                  </g>
                                </svg>
                              </button>
                            </CopyToClipboard>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                }
                {/* {textGenerated?.text?.choices
                  ?.sort((p1, p2) => (p1.performance_prediction < p2.performance_prediction ? 1 : p1.performance_prediction > p2.performance_prediction ? -1 : 0))
                  ?.map((item, i) => (
                    <div
                      key={i}
                      style={{ direction: languageInput === "Arabic" ? "rtl" : "ltr", textAlign: languageInput === "Arabic" ? "right" : "left", overflow: "auto", margin: "20px auto" }}
                      className="text_card col-sm-11"
                    >
                      <div className="text_card_content">
                        <p
                          className={languageInput === "Arabic" ? "arabicFont" : "englishFont"}
                          style={{ fontFamily: languageInput === "Arabic" ? '"Tajawal"' : null, whiteSpace: "pre-wrap" }}
                        >
                          {item?.text}
                        </p>
                        {finalElemnt?.category === "social_media" ? (
                          <div className="engagment_wrapper">
                            <div style={{ height: "60px", width: "60px" }}>
                              <CircularProgressbar
                                counterClockwise={true}
                                value={Math.round(item?.performance_prediction)}
                                text={`${Math.round(item?.performance_prediction)}%`}
                                maxValue={99}
                                strokeWidth={9}
                                styles={buildStyles({
                                  rotation: 0,
                                  strokeLinecap: "round",
                                  pathTransitionDuration: 1,
                                  pathColor:
                                    Math.round(item?.performance_prediction) >= 75
                                      ? "#35c235"
                                      : Math.round(item?.performance_prediction) >= 50
                                        ? "#3894f4"
                                        : Math.round(item?.performance_prediction) >= 25
                                          ? "rgb(234 183 37)"
                                          : "gray",
                                  textColor:
                                    Math.round(item?.performance_prediction) >= 75
                                      ? "#35c235"
                                      : Math.round(item?.performance_prediction) >= 50
                                        ? "#3894f4"
                                        : Math.round(item?.performance_prediction) >= 25
                                          ? "rgb(234 183 37)"
                                          : "gray",
                                })}
                              />
                            </div>
                            <label className="engagment_text">
                              <span>{t("Engagement")}</span>
                              <span id="engagment_tool">
                                <img src={infoIcon} alt="info_icon" />
                              </span>
                            </label>
                          </div>
                        ) : null}
                      </div>
                      <div className="text_card_footer">
                        <p
                          className={languageInput === "Arabic" ? "arabicFont" : "englishFont"}
                          style={{ fontFamily: languageInput === "Arabic" ? '"Tajawal"' : null, opacity: "0.5" }}
                        >
                          {item?.word_count} {languageInput === "Arabic" ? "كلمة" : "words"}
                        </p>
                        <div className="text_card_actions_wrapper">
                          <CopyToClipboard onCopy={() => handleRegularShare()} text={item?.text}>
                            <button className="words_action_btn">
                              <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="12.424" height="12.424" viewBox="0 0 12.424 12.424">
                                <g id="Group_2026" data-name="Group 2026">
                                  <path
                                    id="Path_10306"
                                    data-name="Path 10306"
                                    d="M13.117,2.16H5.543A1.466,1.466,0,0,0,4.081,3.627v.454H3.622A1.466,1.466,0,0,0,2.16,5.548v7.569a1.466,1.466,0,0,0,1.462,1.467H11.2a1.468,1.468,0,0,0,1.467-1.467v-.454h.454A1.469,1.469,0,0,0,14.584,11.2V3.627A1.469,1.469,0,0,0,13.117,2.16ZM11.765,13.117a.57.57,0,0,1-.569.569H3.622a.567.567,0,0,1-.565-.569V5.548a.568.568,0,0,1,.565-.569H11.2a.57.57,0,0,1,.569.569v7.569ZM13.686,11.2a.57.57,0,0,1-.569.569h-.454V5.548A1.468,1.468,0,0,0,11.2,4.081H4.978V3.627a.567.567,0,0,1,.565-.569h7.574a.57.57,0,0,1,.569.569Z"
                                    transform="translate(-2.16 -2.16)"
                                    fill="#b1b1b1"
                                  />
                                </g>
                              </svg>
                            </button>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                  ))} */}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubCategory;

const SkeletonCount = () => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  return (
    <p style={{ width: "90%", display: "flex", alignItems: "center", justifyContent: "center", gap: "20px", direction: "rtl", margin: "0 10px" }}>
      <div style={{ flexGrow: "1", width: "100%", textAlign: lng === "arab" ? "right" : "left" }}>
        <Skeleton animation="wave" count={1} height={14} width="80%" />
        <Skeleton animation="wave" count={1} height={14} width="50%" />
        <Skeleton animation="wave" count={1} height={14} width="30%" />
      </div>
      <div>
        <Skeleton animation="wave" variant="circular" width={60} height={60} style={{ borderRadius: "50%" }} />
      </div>
    </p>
  );
};
