import React, { useEffect, useState, useRef } from "react";
import pptxgen from "pptxgenjs";
import "./Presentation.scss";
import { useTranslation } from "react-i18next";
import exportIcon from "../../assets/img/newDesign/export.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import jsPDF from "jspdf";
import { Spinner } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const Slides = ({ slides, image, title, streaming, header, type }) => {
  useEffect(() => {
    // console.log(streaming, "streaming");

  }, [streaming])
  const text = useRef(null);
  const { t } = useTranslation();
  const swiperRef = useRef(null);
  const [name, setName] = useState("");
  const [edit, setEdit] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [loaded, setLoaded] = useState(false);



  const handleArrowKeys = (event) => {
    if (event.key === "ArrowRight") {
      swiperRef.current.swiper.slideNext();
    } else if (event.key === "ArrowLeft") {
      swiperRef.current.swiper.slidePrev();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleArrowKeys, false);
    return () => {
      document.removeEventListener("keydown", handleArrowKeys, false);
    };
  }, []);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (name === "") {
        setName("Untitled");
        setEdit(false);
      } else setEdit(false);
    }
  };

  useEffect(() => {
    setName(title);
  }, []);

  const getSlideImage = (slide) => {
    return image[slide.key]?.length && image[slide.key][0];
  };

  const exportPptx = () => {
    const pptx = new pptxgen();
    slides.forEach((item) => {
      const slide = pptx.addSlide();
      slide.background = { color: "#e0e1dd" };
      slide.addImage({ path: item?.value?.image || item?.content?.image, x: "50%", y: "0%", w: "50%", h: "100%" });
      slide.addText(item?.value?.text.split(":")[0] || item?.content?.title, { x: "5%", y: "15%", w: "50%", fontSize: 18, bold: true, color: "#455a64" });
      slide.addText(item?.value?.text.split(":")[1] || item?.content?.content, { x: "5%", y: "50%", w: "50%", fontSize: 12, color: "#455a64" });
    });
    pptx.writeFile(`name.pptx`);
    handleClose();
  };

  const printDocument = async () => {
    const doc = new jsPDF({
			format: 'a4',
			unit: 'px',
		});
    doc.html(swiperRef.current, {
      async callback(doc) {
        await doc.save('pdf_name');
      },
    });
  };

  // const exportPdf = () => {
  //   const doc = new jsPDF();

  //   slides.forEach((item) => {
  //     doc.addPage();
  //     doc.setFillColor("#e0e1dd");
  //     doc.rect(0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight(), "F");
  //     const img = new Image();
  //     img.src = item?.value?.image;
  //     doc.addImage(img, "PNG", doc.internal.pageSize.getWidth() / 2, 0, doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight());
  //     doc.setFont("helvetica", "bold");
  //     doc.setFontSize(18);
  //     doc.setTextColor("#455a64");
  //     const title = item?.value?.text.split(":")[0];
  //     doc.text(title, doc.internal.pageSize.getWidth() * 0.05, doc.internal.pageSize.getHeight() * 0.15);
  //     doc.setFont("helvetica", "normal");
  //     doc.setFontSize(12);
  //     doc.setTextColor("#455a64");
  //     const content = item?.value?.text.split(":")[1];
  //     doc.text(content, doc.internal.pageSize.getWidth() * 0.05, doc.internal.pageSize.getHeight() * 0.15);
  //   });

  //   doc.save(`${title}.pdf`);
  // };

  const toggleSlide = (index) => {
    swiperRef.current?.swiper.slideTo(index);
  };

  return (
    <div style={{ padding: "0px", gap: "0px" }} className="border-radius-top homePageWrapper">
      <div style={{ margin: null, maxWidth: null, padding: "15px" }} className="write_body  border-radius-top">
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "40px" }}>
          <div className="presentation_name">
            {edit ? (
              <input value={name} style={{ width: "100%" }} autoFocus onBlur={() => setEdit(false)} onKeyDown={handleKeyDown} onChange={(e) => setName(e.target.value)} />
            ) : (
              <h1
                className="homePageWrapper"
                style={{ textTransform: "capitalize", fontWeight: "600", fontSize: "2rem", margin: "0", textDecoration: "underline", height: "unset" }}
                // onClick={() => setEdit(true)}
              >
                {header?.length ? header.slice(17, -2) : `A ${type} Presentation About ${name}.`}
              </h1>
            )}
          </div>
          {width >= 991 ? (
            <div>
              <button
                onClick={exportPptx}
                id="basic-button"
                style={{ background: "none", border: "none", boxShadow: "1px 0px 8px #80808038", borderRadius: "10px", padding: "5px 10px", display: "flex" }}
              >
                {t("Export")}
                <img src={exportIcon} width={20} style={{ marginLeft: "10px" }} />
              </button>
              {/* <Menu MenuListProps={{ "aria-labelledby": "basic-button" }} id="basic-menu" anchorEl={menuOpen} open={open} onClose={handleClose}>
                <MenuItem onClick={exportPptx}>pptx</MenuItem>
                <MenuItem onClick={exportPdf}>pdf</MenuItem>
              </Menu> */}
            </div>
          ) : null}
        </div>
        <div>
          <div className="slide_preview">
            {width > 700 ? (
              <div className="slide_left">
                {slides?.map((item, index) => (
                  <div style={{ display: "flex", alignItems: "center", gap: "20px" }} key={index}>
                    {index + 1}
                    <div key={index} onClick={() => toggleSlide(index)} className="slide_left_item " style={{ flex: "1" }}>
                      <h4 style={{ fontSize: "0.8rem", fontWeight: "600" }}>
                        {item?.value?.text?.split(":")[0]} {item?.content?.title}
                      </h4>
                    </div>
                  </div>
                ))}
                <span style={{ margin: "0 auto" }}>{streaming && <Spinner style={{ width: "1.5rem", height: "1.5rem", borderWidth: "0.2em" }} animation="border" role="status" />}</span>
              </div>
            ) : null}
            <div className="slide_right">
              <Swiper
                ref={swiperRef}
                style={{ height: "100%" }}
                pagination={{ clickable: true }}
                observeParents={true}
                observer={true}
                modules={[Pagination]}
                className="mySwiper"
                preventClicks={true}
                watchSlidesProgress={true}
                a11y={false}
              >
                {slides?.map((item, index) => (
                  <SwiperSlide>
                    <div id={index + 1} key={index} className="slide_right_item">
                      {getSlideImage(item) ? (
                        <>
                          {/* {loaded ? ( */}
                          <img src={getSlideImage(item)} onLoad={() => setLoaded(true)} width={width <= 700 ? "60%" : "50%"} style={{ borderRadius: "20px" }} />
                          {/* ) : ( */}
                          {/* <Spinner style={{ position: "absolute", top: "50%", left: "50%" }} animation="border" role="status" />                          )} */}
                        </>
                      ) : null}
                      {item?.content?.image && !getSlideImage(item) && (
                        <img src={item?.content?.image} onLoad={() => setLoaded(true)} width={width <= 700 ? "60%" : "50%"} style={{ borderRadius: "20px" }} />
                      )}
                      <div>
                        <h2 style={{ fontWeight: "600", textAlign: width <= 700 ? "center" : null }}>
                          {item?.value?.text.split(":")[0]} {item?.content?.title}
                        </h2>
                        <h4
                          style={{
                            fontWeight: item?.value?.text.includes("Title") ? 600 : null,
                            fontSize: item?.value?.text.includes("Title") ? "3rem" : "1em",
                            lineHeight: item?.value?.text.includes("Title") ? "unset" : null,
                            margin: "auto",
                            width: "100%",
                            whiteSpace: "pre-wrap",
                            textAlign: width <= 700 ? "center" : null,
                            lineHeight: width <= 700 ? "unset" : null,
                          }}
                        >
                          {item?.value?.text.split(":")[1]}
                          {item?.content?.content}
                        </h4>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slides;
