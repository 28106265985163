import { toastAlert } from "components/toastAlert/toastAlert";
import { FETCH_CASES } from "redux/contstants";
import { CASES_LOADED } from "redux/contstants";
import { LOAD_CASES } from "redux/contstants";
import { FETCH_NEW_CASES } from "redux/contstants";
import { CASES_NEW_LOADED } from "redux/contstants";
import { LOAD_NEW_CASES } from "redux/contstants";
import {MOST_USED_CASES} from "redux/contstants";
import { FETCH_BUSINESS_CASES, LOAD_BUSINESS_CASES, CASES_BUSINESS_LOADED } from "redux/contstants";
import * as api from "../../api";
import checkErr from "./api_error_func";

export const getAllCases = (url) => async (dispatch) => {
    try {
        dispatch({ type: LOAD_CASES });
        var result = await api.getCasesCustom(url);
        await dispatch({ type: FETCH_CASES, data: result });
    } catch(error) {
        dispatch({ type: CASES_LOADED })
        checkErr(error, false, toastAlert)
    }
}
export const getNewCases = () => async (dispatch) => {
    try {
        dispatch({ type: LOAD_NEW_CASES });
        var result = await api.getNewCases();
        await dispatch({ type: FETCH_NEW_CASES, data: result?.data?.use_cases});
    } catch(error) {
        dispatch({ type: CASES_NEW_LOADED })
        checkErr(error, false, toastAlert)
    }
}

export const getBusinessCases = (url) => async (dispatch) => {
    try {
        dispatch({ type: LOAD_BUSINESS_CASES });
        var result = await api.getCasesCustom(url);
        await dispatch({ type: FETCH_BUSINESS_CASES, data: result });
    } catch(error) {
        dispatch({ type: CASES_BUSINESS_LOADED })
        checkErr(error, false, toastAlert)
    }
}

export const getMostUsedCases = () => async (dispatch) => {
    try {
        var result = await api.getMostUsedCases();
        await dispatch({ type: MOST_USED_CASES, data: result });
    } catch(error) {
        // console.log(error, "error");
        checkErr(error, false, toastAlert)
    }
}

