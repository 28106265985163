export default (sidebar = { sidebarIsOpen: true }, action) => {
  switch (action.type) {
    case "OPEN_SIDEBAR":
      return { ...sidebar, sidebarIsOpen: false };
    case "CLOSE_SIDEBAR":
      return { ...sidebar, sidebarIsOpen: true };
    default:
      return sidebar;
  }
};
