import {
  LOAD_SCRIBBLE,
  FETCH_SCRIBBLE,
  SCRIBBLE_LOADED,
  LOAD_REMOVER,
  FETCH_REMOVER,
  REMOVER_LOADED,
  LOAD_CHANGER,
  FETCH_CHANGER,
  CHANGER_LOADED,
  NEW_BACKGROUND_CHANGER,
  NEW_BACKGROUND_REMOVER,
  LOAD_REDESIGN,
  FETCH_REDESIGN,
  REDESIGN_LOADED,
  NEW_REDESIGN,
  UPLOADED_IMAGINE,
  FETCH_TEXT_AUDIO,
  LOAD_TEXT_AUDIO,
  LOADED_TEXT_AUDIO,
  NEW_TEXT_AUDIO,
  FETCH_TEXT_SPEECH,
  LOAD_TEXT_SPEECH,
  LOADED_TEXT_SPEECH,
  NEW_TEXT_SPEECH,
  FETCH_CLIP_AUDIO,
  LOAD_CLIP_AUDIO,
  LOADED_CLIP_AUDIO,
  NEW_CLIP_AUDIO,
  FETCH_TEXT_VIDEO,
  LOAD_TEXT_VIDEO,
  LOADED_TEXT_VIDEO,
  NEW_TEXT_VIDEO,
  FETCH_REMOVE_VIDEO,
  LOAD_REMOVE_VIDEO,
  LOADED_REMOVE_VIDEO,
  NEW_REMOVE_VIDEO,
  FETCH_REIMAGINE_V2,
  LOAD_REIMAGINE_V2,
  LOADED_REIMAGINE_V2,
  NEW_REIMAGINE_V2,
  FETCH_UPSCALE,
  LOAD_UPSCALE,
  LOADED_UPSCALE,
  NEW_UPSCALE,
  FETCH_UPSCALE_V1,
  LOAD_UPSCALE_V1,
  LOADED_UPSCALE_V1,
  NEW_UPSCALE_V1,
  FETCH_ENHANCE,
  LOAD_ENHANCE,
  LOADED_ENHANCE,
  NEW_ENHANCE,
  LOAD_ENHANCE_AUDIO,
  FETCH_ENHANCE_AUDIO,
  ENHANCE_AUDIO_LOADED,
  NEW_ENHANCE_AUDIO,
  LOAD_ENHANCE_VIDEO,
  FETCH_ENHANCE_VIDEO,
  ENHANCE_VIDEO_LOADED,
  NEW_ENHANCE_VIDEO,
  LOAD_HEAD,
  FETCH_HEAD,
  HEAD_LOADED,
  NEW_HEAD,
  UPLOADED_UPSCALE,
  LOAD_INFINITE_ZOOM,
  FETCH_INFINITE_ZOOM,
  LOADED_INFINITE_ZOOM,
  NEW_INFINITE_ZOOM,
  LOAD_OVERLAP_ANIMATION,
  FETCH_OVERLAP_ANIMATION,
  LOADED_OVERLAP_ANIMATION,
  NEW_OVERLAP_ANIMATION,
  LOAD_PERCENTAGE_ENHANCE,
  LOAD_PERCENTAGE_UPSCALE,
  LOAD_PERCENTAGE_REMOVER,
  LOAD_PERCENTAGE_CHANGER,
  LOAD_PERCENTAGE_REDESIGN,
  LOAD_PERCENTAGE_HEAD,
  LOAD_PERCENTAGE_SCRIBBLE,
  LOAD_PERCENTAGE_ENHANCE_AUDIO,
  LOAD_PERCENTAGE_CLIP_AUDIO,
  LOAD_PERCENTAGE_REMOVE_VIDEO,
} from "../contstants";

import checkErr from "./api_error_func";
import { toastAlert } from "components/toastAlert/toastAlert";
import * as api from "api";

export const handleScribble = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_SCRIBBLE });
    var result = await api.scribble(data);
    dispatch({ type: FETCH_SCRIBBLE, data: result.data });
    dispatch({ type: "FETCH_USER" });
    dispatch({ type: SCRIBBLE_LOADED });
    if (result.data.status === false) {
      toastAlert(result.data.error, undefined, "warning");
    }
  } catch (error) {
    dispatch({ type: SCRIBBLE_LOADED });
    checkErr(error?.data?.error, undefined, toastAlert);
  }
};

export const handleBackgroundRemover = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REMOVER });
    var result = await api.backgroundRemover(data);
    dispatch({ type: FETCH_REMOVER, data: result.data });
    dispatch({ type: "FETCH_USER" });
    dispatch({ type: REMOVER_LOADED });
    if (result.data.status === false) {
      toastAlert(result.data.error, undefined, "warning");
    }
  } catch (error) {
    dispatch({ type: REMOVER_LOADED });
    dispatch({ type: NEW_BACKGROUND_REMOVER });
    checkErr(error?.data?.error, undefined, toastAlert);
  }
};

export const handleChangeBackground = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_CHANGER });
    var result = await api.changeBackground(data);
    dispatch({ type: FETCH_CHANGER, data: result.data });
    dispatch({ type: "FETCH_USER" });
    dispatch({ type: CHANGER_LOADED });
    if (result.data.status === false) {
      toastAlert(result.data.error, undefined, "warning");
    }
  } catch (error) {
    dispatch({ type: CHANGER_LOADED });
    dispatch({ type: NEW_BACKGROUND_CHANGER });
    checkErr(error?.data?.error, undefined, toastAlert);
  }
};

export const handleRedesign = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REDESIGN, data: data });
    var result = await api.redesign(data);
    dispatch({ type: FETCH_REDESIGN, data: result.data });
    dispatch({ type: "FETCH_USER" });
    dispatch({ type: REDESIGN_LOADED });
    if (result.data.status === false) {
      toastAlert(result.data.error, undefined, "warning");
    }
  } catch (error) {
    dispatch({ type: REDESIGN_LOADED });
    dispatch({ type: NEW_REDESIGN });
    checkErr(error?.data?.error, undefined, toastAlert);
  }
};

export const uploadReImagine = (imageData) => (dispatch) => {
  dispatch({ type: UPLOADED_IMAGINE, data: imageData });
};

export const uploadUpscale = (imageData) => (dispatch) => {
  dispatch({ type: UPLOADED_UPSCALE, data: imageData });
};

export const handleTextToAudio = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_TEXT_AUDIO });
    var result = await api.textToAudio(data);
    dispatch({ type: FETCH_TEXT_AUDIO, data: result.data });
    dispatch({ type: "FETCH_USER" });
    dispatch({ type: LOADED_TEXT_AUDIO });
    if (result.data.status === false) {
      toastAlert(result.data.error, undefined, "warning");
    }
  } catch (error) {
    dispatch({ type: LOADED_TEXT_AUDIO });
    dispatch({ type: NEW_TEXT_AUDIO });
    checkErr(error?.data?.error, undefined, toastAlert);
  }
};

export const handleTextToSpeech = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_TEXT_SPEECH });
    var result = await api.textToSpeech(data);
    dispatch({ type: FETCH_TEXT_SPEECH, data: result.data });
    dispatch({ type: "FETCH_USER" });
    dispatch({ type: LOADED_TEXT_SPEECH });
    if (result.data.status === false) {
      toastAlert(result.data.error, undefined, "warning");
    }
  } catch (error) {
    dispatch({ type: LOADED_TEXT_SPEECH });
    dispatch({ type: NEW_TEXT_SPEECH });
    checkErr(error?.data?.error, undefined, toastAlert);
  }
};

export const handle = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_TEXT_SPEECH });
    var result = await api.textToSpeech(data);
    dispatch({ type: FETCH_TEXT_SPEECH, data: result.data });
    dispatch({ type: "FETCH_USER" });
    dispatch({ type: LOADED_TEXT_SPEECH });
    if (result.data.status === false) {
      toastAlert(result.data.error, undefined, "warning");
    }
  } catch (error) {
    dispatch({ type: LOADED_TEXT_SPEECH });
    dispatch({ type: NEW_TEXT_SPEECH });
    checkErr(error?.data?.error, undefined, toastAlert);
  }
};
export const handleClipAudio = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_CLIP_AUDIO });
    var result = await api.clipAudio(data);
    dispatch({ type: FETCH_CLIP_AUDIO, data: result.data });
    dispatch({ type: "FETCH_USER" });
    dispatch({ type: LOADED_CLIP_AUDIO });
    if (result.data.status === false) {
      toastAlert(result.data.error, undefined, "warning");
    }
  } catch (error) {
    dispatch({ type: LOADED_CLIP_AUDIO });
    dispatch({ type: NEW_CLIP_AUDIO });
    checkErr(error?.data?.error, undefined, toastAlert);
  }
};

export const handleTextToVideo = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_TEXT_VIDEO });
    var result = await api.textToVideo(data);
    dispatch({ type: FETCH_TEXT_VIDEO, data: result.data });
    dispatch({ type: "FETCH_USER" });
    dispatch({ type: LOADED_TEXT_VIDEO });
    if (result.data.status === false) {
      toastAlert(result.data.error, undefined, "warning");
    }
  } catch (error) {
    dispatch({ type: LOADED_TEXT_VIDEO });
    dispatch({ type: NEW_TEXT_VIDEO });
    checkErr(error?.data?.error, undefined, toastAlert);
  }
};

export const handleInfiniteZoom = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_INFINITE_ZOOM });
    var result = await api.infiniteZoom(data);
    dispatch({ type: FETCH_INFINITE_ZOOM, data: result.data });
    dispatch({ type: "FETCH_USER" });
    dispatch({ type: LOADED_INFINITE_ZOOM });
    if (result.data.status === false) {
      toastAlert(result.data.error, undefined, "warning");
    }
  } catch (error) {
    dispatch({ type: LOADED_INFINITE_ZOOM });
    dispatch({ type: NEW_INFINITE_ZOOM });
    checkErr(error?.data?.error, undefined, toastAlert);
  }
};

export const handleOverlapAnimation = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_OVERLAP_ANIMATION });
    var result = await api.overlapAnimation(data);
    dispatch({ type: FETCH_OVERLAP_ANIMATION, data: result.data });
    dispatch({ type: "FETCH_USER" });
    dispatch({ type: LOADED_OVERLAP_ANIMATION });
    if (result.data.status === false) {
      toastAlert(result.data.error, undefined, "warning");
    }
  } catch (error) {
    dispatch({ type: LOADED_OVERLAP_ANIMATION });
    dispatch({ type: NEW_OVERLAP_ANIMATION });
    checkErr(error?.data?.error, undefined, toastAlert);
  }
};

export const handleRemoveVideoBackground = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REMOVE_VIDEO });
    var result = await api.removeVideoBackground(data);
    dispatch({ type: FETCH_REMOVE_VIDEO, data: result.data });
    dispatch({ type: "FETCH_USER" });
    dispatch({ type: LOADED_REMOVE_VIDEO });
    if (result.data.status === false) {
      toastAlert(result.data.error, undefined, "warning");
    }
  } catch (error) {
    dispatch({ type: LOADED_REMOVE_VIDEO });
    dispatch({ type: NEW_REMOVE_VIDEO });
    checkErr(error?.data?.error, undefined, toastAlert);
  }
};

export const handleReimagineV2 = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REIMAGINE_V2, data: data.image });
    console.log(data.image, "data.image");
    // var result = await api.reimagineV2(data);
    dispatch({ type: FETCH_REIMAGINE_V2, data: result.data });
    dispatch({ type: "FETCH_USER" });
    dispatch({ type: LOADED_REIMAGINE_V2 });
    if (result.data.status === false) {
      toastAlert(result.data.error, undefined, "warning");
    }
  } catch (error) {
    dispatch({ type: LOADED_REIMAGINE_V2 });
    dispatch({ type: NEW_REIMAGINE_V2 });
    checkErr(error?.data?.error, undefined, toastAlert);
  }
};

export const handleUpscale = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_UPSCALE });
    var result = await api.upscale(data);
    dispatch({ type: FETCH_UPSCALE, data: result.data });
    dispatch({ type: "FETCH_USER" });
    dispatch({ type: LOADED_UPSCALE });
    if (result.data.status === false) {
      toastAlert(result.data.error, undefined, "warning");
    }
  } catch (error) {
    dispatch({ type: LOADED_UPSCALE });
    dispatch({ type: NEW_UPSCALE });
    checkErr(error?.data?.error, undefined, toastAlert);
  }
};

export const handleUpscaleV1 = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_UPSCALE_V1 });
    var result = await api.upscaleV1(data);
    dispatch({ type: FETCH_UPSCALE_V1, data: result.data });
    dispatch({ type: "FETCH_USER" });
    dispatch({ type: LOADED_UPSCALE_V1 });
    if (result.data.status === false) {
      toastAlert(result.data.error, undefined, "warning");
    }
  } catch (error) {
    dispatch({ type: LOADED_UPSCALE_V1 });
    dispatch({ type: NEW_UPSCALE_V1 });
    checkErr(error?.data?.error, undefined, toastAlert);
  }
};

export const handleEnhance = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_ENHANCE });
    var result = await api.enhance(data);
    dispatch({ type: FETCH_ENHANCE, data: result.data });
    dispatch({ type: "FETCH_USER" });
    dispatch({ type: LOADED_ENHANCE });
    if (result.data.status === false) {
      toastAlert(result.data.error, undefined, "warning");
    }
  } catch (error) {
    dispatch({ type: LOADED_ENHANCE });
    dispatch({ type: NEW_ENHANCE });
    checkErr(error?.data?.error, undefined, toastAlert);
  }
};

export const handleHeadShot = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_HEAD });
    var result = await api.headShot(data);
    dispatch({ type: FETCH_HEAD, data: result.data });
    // console.log(result?.data?.data?.urls);
    if (result?.data?.data?.urls === null) {
      return dispatch({ type: NEW_HEAD });
    }
    dispatch({ type: "FETCH_USER" });
    dispatch({ type: HEAD_LOADED });
    if (result.data.status === false) {
      toastAlert(result.data.error, undefined, "warning");
    }
  } catch (error) {
    dispatch({ type: HEAD_LOADED });
    dispatch({ type: NEW_HEAD });
    checkErr(error?.data?.error, undefined, toastAlert);
  }
};

export const handleEnhanceAudio = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_ENHANCE_AUDIO });
    const result = await api.enhanceAudio(data);
    if (result.data.data.status === "generating") {
      let interval = setInterval(async () => {
        const progressRes = await api.enhanceAudioLoading(result.data.data.provider_id);
        console.log(progressRes.data.data.progress, "progressRes.data.data.progress");
        if (progressRes.data.data.progress === 100) {
          clearInterval(interval);
          setTimeout(async () => {
            const downloadRes = await api.enhanceAudioDownload(result.data.data._id);
            dispatch({ type: FETCH_ENHANCE_AUDIO, data: downloadRes.data });
            dispatch({ type: ENHANCE_AUDIO_LOADED });
          }, 5000);
        }
      }, 3000);
    }
    if (result.data.status === false) {
      toastAlert(result.data.error, undefined, "warning");
    }
  } catch (error) {
    dispatch({ type: ENHANCE_AUDIO_LOADED });
    dispatch({ type: NEW_ENHANCE_AUDIO });
    checkErr(error?.data?.error, undefined, toastAlert);
  }
};

export const handleVideoEnhance = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_ENHANCE_VIDEO });
    const result = await api.enhanceAudio(data);
    if (result.data.data.status === "generating") {
      let interval = setInterval(async () => {
        const progressRes = await api.enhanceAudioLoading(result.data.data.provider_id);
        console.log(progressRes.data.data.progress, "progressRes.data.data.progress");
        if (progressRes.data.data.progress === 100) {
          clearInterval(interval);
          setTimeout(async () => {
            const downloadRes = await api.enhanceAudioDownload(result.data.data._id);
            dispatch({ type: FETCH_ENHANCE_VIDEO, data: downloadRes.data });
            dispatch({ type: ENHANCE_VIDEO_LOADED });
          }, 7000);
        }
      }, 3000);
    }
    if (result.data.status === false) {
      toastAlert(result.data.error, undefined, "warning");
    }
  } catch (error) {
    dispatch({ type: ENHANCE_VIDEO_LOADED });
    dispatch({ type: NEW_ENHANCE_VIDEO });
    checkErr(error?.data?.error, undefined, toastAlert);
  }
};

// export const handleLoading = (loadingNumber, name) => (dispatch) => {
//   switch (name) {
//     case "enhance":
//       // console.log(loadingNumber, "enhance");
//       if (loadingNumber >= 100) {
//         loadingNumber = 100;
//       } else {
//         loadingNumber = loadingNumber + 1;
//       }
//       dispatch({ type: LOAD_PERCENTAGE_ENHANCE, data: loadingNumber });
//     case "head":
//       if (loadingNumber >= 100) {
//         loadingNumber = 100;
//       } else {
//         loadingNumber = loadingNumber + 1;
//       }
//       dispatch({ type: LOAD_PERCENTAGE_HEAD, data: loadingNumber });
//     case "upscale":
//       if (loadingNumber >= 100) {
//         loadingNumber = 100;
//       } else {
//         loadingNumber = loadingNumber + 1;
//       }
//       dispatch({ type: LOAD_PERCENTAGE_UPSCALE, data: loadingNumber });
//     case "remover":
//       if (loadingNumber >= 100) {
//         loadingNumber = 100;
//       } else {
//         loadingNumber = loadingNumber + 1;
//       }
//       dispatch({ type: LOAD_PERCENTAGE_REMOVER, data: loadingNumber });
//     case "changer":
//       if (loadingNumber >= 100) {
//         loadingNumber = 100;
//       } else {
//         loadingNumber = loadingNumber + 1;
//       }
//       dispatch({ type: LOAD_PERCENTAGE_CHANGER, data: loadingNumber });
//     case "redesign":
//       if (loadingNumber >= 100) {
//         loadingNumber = 100;
//       } else {
//         loadingNumber = loadingNumber + 1;
//       }
//       dispatch({ type: LOAD_PERCENTAGE_REDESIGN, data: loadingNumber });
//     case "scribble":
//       // console.log(loadingNumber, "scribble");

//       if (loadingNumber >= 100) {
//         loadingNumber = 100;
//       } else {
//         loadingNumber = loadingNumber + 1;
//       }
//       dispatch({ type: LOAD_PERCENTAGE_SCRIBBLE, data: loadingNumber });
//     case "enhance_audio":
//       if (loadingNumber >= 100) {
//         loadingNumber = 100;
//       } else {
//         loadingNumber = loadingNumber + 1;
//       }
//       dispatch({ type: LOAD_PERCENTAGE_ENHANCE_AUDIO, data: loadingNumber });
//     case "clip_audio":
//       if (loadingNumber >= 100) {
//         loadingNumber = 100;
//       } else {
//         loadingNumber = loadingNumber + 1;
//       }
//       dispatch({ type: LOAD_PERCENTAGE_CLIP_AUDIO, data: loadingNumber });
//     case "removeVideo":
//       if (loadingNumber >= 100) {
//         loadingNumber = 100;
//       } else {
//         loadingNumber = loadingNumber + 1;
//       }
//       dispatch({ type: LOAD_PERCENTAGE_REMOVE_VIDEO, data: loadingNumber });
//   }
// };

export const handleEnhanceImageLoading = (loadingNumber) => (dispatch) => {
  if (loadingNumber >= 100) {
    loadingNumber = 100;
  } else {
    loadingNumber = loadingNumber + 1;
  }
  dispatch({ type: LOAD_PERCENTAGE_ENHANCE, data: loadingNumber });
};

export const handleHeadLoading = (loadingNumber) => (dispatch) => {
  if (loadingNumber >= 100) {
    loadingNumber = 100;
  } else {
    loadingNumber = loadingNumber + 1;
  }
  dispatch({ type: LOAD_PERCENTAGE_HEAD, data: loadingNumber });
};

export const handleRedesignLoading = (loadingNumber) => (dispatch) => {
  if (loadingNumber >= 100) {
    loadingNumber = 100;
  } else {
    loadingNumber = loadingNumber + 1;
  }
  dispatch({ type: LOAD_PERCENTAGE_REDESIGN, data: loadingNumber });
};
export const handleScribbleLoading = (loadingNumber) => (dispatch) => {
  if (loadingNumber >= 100) {
    loadingNumber = 100;
  } else {
    loadingNumber = loadingNumber + 1;
  }
  dispatch({ type: LOAD_PERCENTAGE_SCRIBBLE, data: loadingNumber });
};
export const handleBackgroundRemoverLoading = (loadingNumber) => (dispatch) => {
  if (loadingNumber >= 100) {
    loadingNumber = 100;
  } else {
    loadingNumber = loadingNumber + 1;
  }
  dispatch({ type: LOAD_PERCENTAGE_REMOVER, data: loadingNumber });
};
export const handleChangeBackgroundLoading = (loadingNumber) => (dispatch) => {
  if (loadingNumber >= 100) {
    loadingNumber = 100;
  } else {
    loadingNumber = loadingNumber + 1;
  }
  dispatch({ type: LOAD_PERCENTAGE_CHANGER, data: loadingNumber });
};

export const handleUpscaleLoading = (loadingNumber) => (dispatch) => {
  if (loadingNumber >= 100) {
    loadingNumber = 100;
  } else {
    loadingNumber = loadingNumber + 1;
  }
  dispatch({ type: LOAD_PERCENTAGE_UPSCALE, data: loadingNumber });
};

export const handleEnhanceAudioLoading = (loadingNumber) => (dispatch) => {
  if (loadingNumber >= 100) {
    loadingNumber = 100;
  } else {
    loadingNumber = loadingNumber + 2;
  }
  dispatch({ type: LOAD_PERCENTAGE_ENHANCE_AUDIO, data: loadingNumber });
};

export const handleClipAudioLoading = (loadingNumber) => (dispatch) => {
  if (loadingNumber >= 100) {
    loadingNumber = 100;
  } else {
    loadingNumber = loadingNumber + 2;
  }
  dispatch({ type: LOAD_PERCENTAGE_CLIP_AUDIO, data: loadingNumber });
};

export const handleRemoveVideoBackgroundLoading = (loadingNumber) => (dispatch) => {
  if (loadingNumber >= 100) {
    loadingNumber = 100;
  } else {
    loadingNumber = loadingNumber + 2;
  }
  dispatch({ type: LOAD_PERCENTAGE_REMOVE_VIDEO, data: loadingNumber });
};
