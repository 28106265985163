import { LOAD_HEAD, FETCH_HEAD, HEAD_LOADED, NEW_HEAD, UPLOADED_IMAGINE, LOAD_PERCENTAGE, UPLOADED_HEAD, LOAD_PERCENTAGE_HEAD } from "redux/contstants";

export default (headShot = { headShot: [], firstImage: [], loadingNumber: 0, uploaded: null, uploaded: false, loading: false }, action) => {
  switch (action.type) {
    case LOAD_HEAD:
      return { ...headShot, firstImage: action.data, loadingNumber: 0, loading: true, showRemover: true };
    case FETCH_HEAD:
      return { ...headShot, headShot: action.data, loadingNumber: 0 };
    case HEAD_LOADED:
      return { ...headShot, loading: false, loadingNumber: 0 };
    case NEW_HEAD:
      return { ...headShot, headShot: [], loadingNumber: 0, showRemover: false, firstImage: [], uploaded: false };
    case LOAD_PERCENTAGE_HEAD:
      return { ...headShot, loadingNumber: action.data };
    case UPLOADED_HEAD:
      return { ...headShot, uploaded: true };
    // case LOAD_PERCENTAGE:
    //   return { ...headShot, loadingNumber: action.data };
    default:
      return headShot;
  }
};
