import React from "react";
import { ReactComponent as Upload } from "assets/img/newDesign/UploadBigArrow.svg";
import { useTranslation } from "react-i18next";

const FileContainer = (props) => {
  const { t } = useTranslation();

  return (
    <div className="image-drop-container">
      <label className="file-input-label">
        <input type="file" accept={props.types} onChange={props.handleUpload} />
        <div
          className={`image-drop-zone ${
            props.dragging ? "dragging" : "undraging"
          }`}
          onDrop={props.handleDrop}
          onDragOver={props.handleDragOver}
          onDragLeave={props.handleDragLeave}
        >
          <div className="image-drop-text">
            <span className="image-drop-support-button">
              {t("Choose files")} <Upload />
            </span>
            <div className="image-drop-support-drop">
              {t("Or Drop It Here")}
            </div>
            <div className="image-drop-support-text">
              {t(`Supports ${props.support}`)}
            </div>
            <div className="image-drop-support-text">{t("Max Size 5MB")}</div>
          </div>
        </div>
      </label>
    </div>
  );
};

export default FileContainer;
