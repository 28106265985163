import { LOGOUT } from "redux/contstants";

export default function checkErr(
  error,
  setLoadingModalShow,
  toastAlert,
  option,
  dispatch,
  failedOption,
  loghim
) {
  if (error?.response?.status === 413) {
    if (toastAlert) {
      // toastAlert("Bad Request", undefined, "warning");
      toastAlert("Profile picture can't be more than 1 MB", undefined, "warning");
    }
  }
  else if (error?.response?.status === 400) {
    if (setLoadingModalShow) {
      setLoadingModalShow(false);
    }
    if (toastAlert) {
      // toastAlert("Bad Request", undefined, "warning");
      toastAlert(error?.response?.data?.message, undefined, "warning");
    }
    if (option) {
      option();
    }
  } else if (error?.response?.status === 401) {
    if (setLoadingModalShow) {
      setLoadingModalShow(false);
    }
    if (toastAlert) {
      toastAlert(
        "Please login again to continue",
        undefined,
        "warning",
        "login"
      );
    }
    // if (dispatch) {
    //   dispatch({ type: LOGOUT })
    // }

  } else {
    // if (error === 'Large Image') {
    //   toastAlert(
    //     "File size is too large, kindly upload an image less than 1MB"
    //   );
    //   return;
    // }
    if (toastAlert) {
      toastAlert(
        "Something went wrong, please try again later",
        undefined,
        "warning",
        "error"
      );
    }
    if (dispatch && loghim) {
      dispatch({ type: LOGOUT })
    }
    if (failedOption) {
      failedOption();
    }
    if (setLoadingModalShow) {
      setLoadingModalShow(false);
    }

  }
}
