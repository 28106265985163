import React from "react";
import { FC } from "react";
import styles from "./textbutton.module.scss";
import { VoidCallBack } from "utils/typedef.ts";

interface Props {
  text: string;
  textColor?: string;
  fontWeight?: string;
  textSize?: string;
  hoverTextColor?: string;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  border?: string;
  hoverBorder?: String;
  borderRadius?: string;
  padding?: string;
  shadow: string;
  hoverShadow: string;
  className?: string;
  onPressed: VoidCallBack;
  disableButton: boolean;
}

const TextButton: FC<Props> = (props: Props) => {
  return (
    <button
      className={`${styles.wrapper} ${props.className ? props.className : ""}`}
      onClick={() => {
        props.onPressed();
      }}
      style={
        {
          "--font-weight": props.fontWeight,
          "--text-color": props.textColor ?? "black",
          "--background-color": props.backgroundColor ?? "white",
          "--border": props.border ?? "2px solid white",
          "--border-radius": props.borderRadius ?? "0",
          "--padding": props.padding ?? "1.5em 5.2em",
          "--shadow": props.shadow ?? "0",
          "--hover-text-color": props.hoverTextColor ?? "white",
          "--hover-background-color": props.hoverBackgroundColor ?? "black",
          "--hover-border": props.hoverBorder ?? "2px solid white",
          "--hover-shadow": props.hoverShadow ?? "0",
        } as React.CSSProperties
      }
    >
      <p>{props.text}</p>
    </button>
  );
};

export default TextButton;
