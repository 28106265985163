
import {
    FETCH_TEXT_VIDEO,
    LOAD_TEXT_VIDEO,
    LOADED_TEXT_VIDEO,
    NEW_TEXT_VIDEO,
    SHOW_TEXT_VIDEO_FALSE,
    SHOW_TEXT_VIDEO_TRUE
} from "redux/contstants";

export default (textToVideo = { textToVideo: [], uploaded: [], loading: false, showRemover: false }, action) => {
    switch (action.type) {
        case LOAD_TEXT_VIDEO:
            return { ...textToVideo, loading: true, showRemover: true };
        case FETCH_TEXT_VIDEO:
            return { ...textToVideo, textToVideo: action.data };
        case LOADED_TEXT_VIDEO:
            return { ...textToVideo, loading: false };
        case NEW_TEXT_VIDEO:
            return { ...textToVideo, textToVideo: [], uploaded: [], showRemover: false };
        case SHOW_TEXT_VIDEO_FALSE:
            return { ...textToVideo, showRemover: false };
        case SHOW_TEXT_VIDEO_TRUE:
            return { ...textToVideo, showRemover: true };
        default:
            return textToVideo;
    }
};