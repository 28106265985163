import React, { useState, useEffect } from "react";
import { toastAlert } from "components/toastAlert/toastAlert";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import UpgradeModal from "components/UpgradeModal/UpgradeModal";
import "views/MediaSection/ImageTools/Create/ImgGen.css";
import LoadingEffect from "components/Loading/LoadingEffect";
import UpgradePlanModal from "components/UpgradeModal/UpgradePlanModal";
import { handleOverlapAnimation } from "redux/actions/media_section";
import ReplayIcon from "@mui/icons-material/Replay";
import image_en from "assets/img/gif_en/Car Shuffle.gif";

const BeforeAfterVideo = () => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const overlap_animation = useSelector((state) => state.overlap_animation);
  const [showModal, setShowModal] = useState(false);
  const [input_start, setInput_start] = useState("");
  const [input_end, setInput_end] = useState("");
  const [totalImages, setTotalImages] = useState(0);
  const [show, setShow] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  useEffect(() => {
    setTotalImages(user?.image_count_left);
    if (user?.plan === "Trial" || user?.plan === "Starter" || user?.plan === "Basic") {
      return setShow(true);
    }
  }, [user]);

  const handleDownload = () => {
    const fileUrl = overlap_animation?.overlap_animation?.data?.urls[0];
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = "hello"; // Specify the file name here
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleText = async (e) => {
    e.preventDefault();
    if (totalImages === 0) {
      dispatch({ type: "SHOW_TEXT_VIDEO_FALSE" });
      return setShowModal(true);
    } else if (input_start === "" || input_end === "") {
      return toastAlert(t("Please enter a valid text"), undefined, "warning");
    } else {
      let data = {
        prompt_start: input_start,
        prompt_end: input_end,
      };
      dispatch(handleOverlapAnimation(data));
    }
  };
  // console.log(overlap_animation, "overlap_animation");
  return (
    <div className="image_gen_header" style={{ padding: "40px 15px 0" }}>
      {overlap_animation?.overlap_animation?.length === 0 && (
        <>
          <h4>{t("Create a time lapse from a simple prompt")}</h4>
          <p style={{ fontSize: "1rem" }}>{t("seamlessly showcase the beautiful transformation from the first idea to the stunning final result.")}</p>
        </>
      )}
      {overlap_animation?.overlap_animation?.length === 0 ? (
        <form onSubmit={handleText} className="inpainting_input" style={{ flexDirection: "column" }}>
          <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
            <input
              type="textarea"
              value={input_start}
              disabled={overlap_animation?.loading}
              style={{ minWidth: "280px" }}
              onChange={(e) => setInput_start(e.target.value)}
              placeholder={t("Small Tree")}
            />
            <input type="textarea" value={input_end} disabled={overlap_animation?.loading} style={{ minWidth: "280px" }} onChange={(e) => setInput_end(e.target.value)} placeholder={t("Big Tree")} />
          </div>
          <button
            disabled={overlap_animation?.loading}
            style={{
              padding: "15px 40px !important",
              background: overlap_animation?.loading ? "gray" : "linear-gradient(45deg, rgba(17, 113, 216, 1) 0%, rgba(60, 200, 235, 1) 100%)",
              width: "170px",
              minHeight: "46px",
              borderRadius: "15px",
              alignSelf: "center",
              pointerEvents: overlap_animation?.loading && "none",
            }}
            type="submit"
            className="form_buttions"
          >
            {overlap_animation?.loading ? <LoadingEffect /> : t("Create Video")}
          </button>
          <img
            src={image_en}
            style={{
              marginTop: "30px",
              maxWidth: "80%",
              borderRadius: "10px",
            }}
            alt="text to video"
          />
        </form>
      ) : (
        <div style={{ width: "90%", margin: "auto" }}>
          <video src={overlap_animation?.overlap_animation?.data?.urls[0]} controls style={{ width: "100%", maxWidth: "600px", borderRadius: "20px" }} />
          <div className="beforeafter-gray">
            <button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                fontSize: "medium",
              }}
              className="download-btn"
              onClick={handleDownload}
            >
              {t("Download")}
              <i
                className="fas fa-arrow-down"
                style={{
                  fontSize: "medium",
                  margin: lng === "arab" ? " 0 5px 0 0" : "0 0 0 5px",
                }}
              ></i>
            </button>
            <button
              className="newupload"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                fontSize: "medium",
              }}
              onClick={() => {
                setInput_end("");
                setInput_start("");
                dispatch({ type: "NEW_OVERLAP_ANIMATION" });
              }}
            >
              {t("Try Again")}
              <ReplayIcon />
            </button>
          </div>
        </div>
      )}
      <div style={{ width: "90%", margin: "auto" }}></div>
      <UpgradeModal images={true} setShowModal={setShowModal} showModal={showModal} />
      <UpgradePlanModal show={show} setShow={setShow} width={width} />
    </div>
  );
};

export default BeforeAfterVideo;
