import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { baseUrl } from "api/baseUrl";
import { useSelector } from "react-redux";
import { toastAlert } from "components/toastAlert/toastAlert";
import axios from "axios";
import LoadingEffect from "components/Loading/LoadingEffect";
import Slides from "./Slides";
import SortableSlides from "./SortableSlides";
import rightArrow from "../../assets/img/newDesign/right-arrow.png";
import Typewriter from "typewriter-effect";
import "./Presentation.scss";
import useId from "react-use-uuid";
import { socket } from "../../socket.js";
import { useDispatch } from "react-redux";
import { useParams,useNavigate } from "react-router-dom";
import backArrow from "../../assets/img/newDesign/backArrow.svg";

const Presentation = () => {
  const refId = useId();
  const params  = useParams()
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [items, setItems] = useState({ counter: 0 });
  const [title, setTitle] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [image, setImage] = useState({});
  const [addNewSlide, setAddNewSlide] = useState(false);
  const [newSlideTitle, setNewSlideTitle] = useState("");
  const [newData, setNewData] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [streaming, setStreaming] = useState(false)
  const [textUpdates, setTextUpdates] = useState(0);
  const [showWriter, setShowWriter] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [slides, setSlides] = useState([]);
  const [slideHeader, setSlideHeader] = useState("")
  const [activeStep, setActiveStep] = useState(1);
  const [isConnected, setIsConnected] = useState(socket.connected);
  
  const history = () => {
    if(!params?.id) return
    axios
      .get(`${baseUrl}user/history/presentation/${params.id}`)
      .then((res) => {
        setActiveStep(3);
        setIsLoading(false);
        let arr = [];
        let content = [];
        for (const [key, value] of Object.entries(res?.data?.data?.slides)) {
          arr = [...arr, { key, title: value }];
          content = [...content, { key, content: value }];
          setSlides(content);
        }
        setSlideHeader(res?.data?.data?.topic);
      })
      .catch((err) => {
        // console.log(err);
      });
  };    
  
  useEffect(() => {
    history();
  }, [params?.id]);

  const handleButtonClick = (buttonNumber) => {
    setActiveStep(buttonNumber);
  };

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedType) return toastAlert(t("Please enter the type of presentation"), undefined, "error");
    if (!title) return toastAlert(t("Please enter a title"), undefined, "error");
    setIsLoading(true);
    let res = await axios
      .post(`${baseUrl}generate-presentation-titles`, {
        headers: {
          Authorization: localStorage.getItem("sess"),
          "Content-Type": "application/json",
        },
        topic: `I Want to Create a ${selectedType} Presentation About ${title} ?`,
        ref_id: refId,
      })
      .then((response) => {
        setIsLoading(false);
        setSlides([]);
        setItems({ ...response?.data, counter: items.counter + 1 });
        setActiveStep(2);
        dispatch({ type: "FETCH_USER" });
      })
      .catch(function (error) {
        toastAlert(t("Something went wrong"), undefined, "error");
        setIsLoading(false);
      });
  };

  let arr = [];
    useEffect(() => {
    function onConnect() {
      socket.emit("token", { token: localStorage.getItem("sess") }, () => {});
      setIsConnected(true);
      console.log("connected");
    }
    function onDisconnect() {
      setIsConnected(false);
      console.log("disconnected");
    }
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    const responseData = (data) => {
      if(data.type !=="response") return;
      // console.log("data", data);
      setIsLoading(false);
      setStreaming(data?.data?.streaming? true:false)
      setActiveStep(3);
      for (const [key, value] of Object.entries(data?.data?.message)) {
        arr = [...arr, { key, value }];
        setSlides(arr);
      };
      // dispatch({ type: user, data: result.data.User });
    }
    
    // socket.onAny((a,b)=>{
    //   console.log(a,b,"any events")
    // });
    
    socket.on("data", responseData);
    socket.connect();

    return () => {
      socket.disconnect();
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
  }, []);

  const handleSlideSubmit = async (e) => {
    setSlides([]);
    generateImage(e);
    setIsLoading(true);
    e.preventDefault();
    let request = {
      token: localStorage.getItem("sess"),
      ref_id: refId,
      action: "generate_presentation",
      topic: title,
      titles: items?.titles,
      language: "english",
    };
    if (isConnected) {
      socket.emit("request", request, () => {});
    }

    // let res = await axios
    //   .post(`${baseUrl}generate-presentation`, {
    //     headers: {
    //       Authorization: localStorage.getItem("sess"),
    //       "Content-Type": "application/json",
    //     },
    //     topic: title,
    //     titles: items.titles,
    //     ref_id: refId,
    //   })
    //   .then((response) => {
    //     setIsLoading(false);
    //     setSlides(response.data);
    //     setActiveStep(3);
    //   })
    //   .catch(function (error) {
    //     toastAlert(t("Something went wrong"), undefined, "error");
    //     setItems([]);
    //     setIsLoading(false);
    //   });
  };

  var img = {};
  const generateImage = async (e) => {
    e.preventDefault();
    img = {};
    for (const key in items?.titles) {
      if (key == "0" || key == Object.keys(items?.titles).pop()) {
          continue;
      }
      axios
        .post(`${baseUrl}generate-presentation-images`, {
          headers: {
            Authorization: localStorage.getItem("sess"),
            "Content-Type": "application/json",
          },
          title: `${title}: ${items?.titles[key]}`,
          language: "english",
          key: key,
          ref_id: refId,
        })
        .then((response) => {
          img[response.data.key] = response.data.images;
          setImage(img);
          })
        .catch(function (error) {
          toastAlert(t("Something went wrong"), undefined, "error");
          setIsLoading(false);
        });
    }
  };

  const swapObjectIndices = (obj, index1, index2) => {
    const keys = Object.keys(obj);
    const temp = obj[keys[index1]];
    obj[keys[index1]] = obj[keys[index2]];
    obj[keys[index2]] = temp;
    return obj;
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let updatedItems = swapObjectIndices(items.titles, oldIndex, newIndex);
    setItems({ ...items, titles: updatedItems, counter: items.counter + 1 });
  };
  const pushANewSlide = () => {
    let newData = { ...items.titles };
    let newKeyName = `${Object.keys(items.titles).length + 1}`;
    newData[newKeyName] = `\n${newSlideTitle}\n`;
    setItems({ ...items, titles: newData, counter: items.counter + 1 });
  };

  const editSlides = (index) => {
    setTextUpdates((prev) => prev + 1);
    let newTitles = { ...items.titles, [index]: newData };
    setItems({ ...items, titles: newTitles, counter: items.counter + 2 });
  };

  const removeSlide = (myKey) => {
    let copyObject = { ...items };
    for (const key in copyObject?.titles) {
      if (key == myKey) {
        delete copyObject?.titles[key];
      }
    }
    let newObj = {};
    let indexStart = 0;
    for (const key in copyObject?.titles) {
      newObj[indexStart] = copyObject?.titles[key];
      indexStart++;
    }
    setItems({ ...copyObject, titles: {...newObj}, counter: copyObject?.counter + 2 });
    setTextUpdates((prev) => prev + 1);
  };
  var arabic = /[\u0600-\u06FF]/;

  return (
    <div style={{ padding: "0px", gap: "0px", position: "relative",backgroundColor:"#fafdff" }} className="homePageWrapper height_for_presentation">
    <div className="categories_header subCtegoryHeader" onClick={()=> navigate (-1)} style={{cursor:"pointer"}}>
        <div className="sub_case_header">
          <img src={backArrow} alt='back_button' />
          <label>{t("Presentation")}</label>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          right: lng === "arab" ? null : "15px",
          left: lng === "arab" ? "15px" : null,
          top: width <= 991 && activeStep === 1 ? "160px" : width <= 991 && activeStep === 2 ? "10px" : activeStep === 3 ? "80px" : "80px",
          width: "210px",
          background: "linear-gradient(270deg, rgba(211, 24, 242, 0.45) 0%, rgba(66, 95, 236, 0.45) 50%, rgba(24, 119, 242, 0.45) 100%)",
          borderRadius: "20px",
          display: "flex",
          justifyContent: "flex-end",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
      >
        <button
          disabled={isLoading}
          style={{ color: "white", border: "none", background: activeStep === 1 ? "#425FEC" : "transparent", borderRadius: "20px", width: "70px", textAlign: "center" }}
          onClick={() => handleButtonClick(1)}
        >
          1
        </button>
        <button
          disabled={items.length === 0 || items === null || undefined || isLoading}
          style={{ color: "white", border: "none", background: activeStep === 2 ? "#425FEC" : "transparent", borderRadius: "20px", width: "70px", textAlign: "center" }}
          onClick={() => handleButtonClick(2)}
        >
          2
        </button>
        <button
          disabled={slides.length === 0 || isLoading}
          style={{ color: "white", border: "none", background: activeStep === 3 ? "#D318F2" : "transparent", borderRadius: "20px", width: "70px", textAlign: "center" }}
          onClick={() => handleButtonClick(3)}
        >
          3
        </button>
      </div>
      {activeStep === 3 ? (
        <Slides type={selectedType} setSlides={setSlides} streaming={streaming} image={image} title={title}  slides={slides} uuid={refId} header={slideHeader} />
      ) : activeStep === 2 ? (
        <div
          style={{
            margin: "auto",
            maxWidth: "1000px",
            padding: width <= 991 ? "20px" : "0 20px",
          }}
        >
          <h1 style={{ fontWeight: "600", fontSize: "52px", textAlign: lng == "arab" ? "right" : "left", height: lng === "arab" ? "80px" : "unset" }} className="araby-gpt-text " id="inline_height">
            {t("Slides Titles")}
          </h1>
          <h4 style={{ margin: "0 0 20px 0", padding: "0", fontSize: "1rem", color: "black", fontWeight: 400, textAlign: lng == "arab" ? "right" : "left" }}>{t("Edit your Slide Titles ")}</h4>
          <SortableSlides
            removeSlide={removeSlide}
            key={textUpdates}
            textUpdates={textUpdates}
            editSlides={editSlides}
            setNewData={setNewData}
            newData={newData}
            setItems={setItems}
            items={items}
            onSortEnd={onSortEnd}
            setTextUpdates={setTextUpdates}
            uuid={refId}
          />
          {addNewSlide ? (
            <input
              autoFocus
              placeholder=" About the Topic"
              value={items?.value}
              onBlur={(e) => {
                if (e.target.value === "") {
                  setAddNewSlide(false);
                  return;
                } else {
                  setEdit(false);
                  addNewSlide(false);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && e.target.value !== "") {
                  pushANewSlide();
                  setAddNewSlide(false);
                }
              }}
              onChange={(e) => {
                setNewSlideTitle(e.target.value);
              }}
              style={{ borderRadius: "10px", padding: "20px", border: "0.5px solid #D7D7D7", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.06)", width: "100%" }}
            />
          ) : (
            <>
              {!isLoading && (
                <button
                  onClick={() => setAddNewSlide(true)}
                  style={{
                    borderRadius: "10px",
                    padding: "10px 15px",
                    border: "0.5px solid #D7D7D7",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.06)",
                    background: "white",
                    display: "flex",
                    margin: "8px auto",
                  }}
                >
                  +
                </button>
              )}
            </>
          )}
          <button
            onClick={handleSlideSubmit}
            disabled={isLoading}
            style={{
              margin: "10px auto",
              display: "flex",
              justifyContent: "center",
              padding: "15px 60px !important",
              background: isLoading ? "gray" : "linear-gradient(45deg, rgba(17, 113, 216, 1) 0%, rgba(60, 200, 235, 1) 100%)",
              width: "170px",
              borderRadius: "15px",
              pointerEvents: isLoading && "none",
            }}
            className="form_buttions"
            type="submit"
          >
            {isLoading ? <LoadingEffect /> : t("Submit")}
          </button>
        </div>
      ) : activeStep === 1 ? (
        <div
          style={{
            margin: null,
            maxWidth: null,
            padding: "0px 20px 15px 15px",
          }}
          className="write_body  border-radius-top"
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ padding: "0px 15px 0px 15px", width: "unset" }} className="image_gen_header homePageWrapper">
              <h1 style={{ fontWeight: "600", height: "unset", padding: "6px", fontSize: "52px" }} className="araby-gpt-text">
                {t("Lets Make a Presentation")}
              </h1>
              <h5>{t("Create your presentation with Twwt AI")}</h5>
            </div>
          </div>

          <form onSubmit={handleSubmit} className="presentation_input" style={{ maxWidth: "1024px" }}>
            <div className="presentation_container" style={{ display: "flex", alignItems: "center", margin: "30px auto", flexWrap: "wrap ", rowGap: "15px", padding: "30px" }}>
              <h4 style={{ margin: "0 5px" }}>{t("I Want to Create a ")}</h4>
              <span onClick={() => setShowWriter(true)} className="suggestion_container" style={{ borderBottom: !showWriter ? "2px solid transparent" : null }}>
                {showWriter ? (
                  <input
                    disabled={isLoading}
                    autoFocus
                    style={{ width: width <= 600 ? "140px" : "220px" }}
                    onBlur={() => {
                      if (selectedType === "") {
                        setShowWriter(false);
                      }
                    }}
                    value={selectedType}
                    onChange={(e) => setSelectedType(e.target.value)}
                    placeholder={t("Type of presentation")}
                  />
                ) : (
                  <Typewriter
                    options={{
                      strings: [
                        t("Informative"),
                        t("Persuasive"),
                        t("Entertaining"),
                        t("Inspiring"),
                        t("Educational"),
                        t("Demonstrative"),
                        t("Explanatory"),
                        t("Instructional"),
                        t("Descriptive"),
                        t("Narrative"),
                      ],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                )}
              </span>
              <h4 style={{ margin: "0 5px" }}>{t("Presentation About ,")} </h4>
              <input
                disabled={isLoading}
                value={title}
                style={{ borderBottom: "2px solid transparent" }}
                onChange={(e) => setTitle(e.target.value)}
                placeholder={t("What is your presentation about")}
              />
              <h4 style={{ margin: "0 5px" }}>{t("?")}</h4>
            </div>
            <button
              style={{
                background: isLoading ? "gray" : "linear-gradient(45deg, rgba(17, 113, 216, 1) 0%, rgba(60, 200, 235, 1) 100%)",
                width: "220px",
                borderRadius: "15px",
                float: "right",
                pointerEvents: isLoading && "none ",
              }}
              className="form_buttions"
              type="submit"
            >
              {isLoading ? <LoadingEffect /> : t("Create Presentation")}
              {!isLoading && <img src={rightArrow} width={30} style={{ margin: lng === "arab" ? "0 20px 0 0" : "0 0 0 20px", transform: lng === "arab" ? "rotate(180deg)" : null }} />}
            </button>
          </form>
        </div>
      ) : null}
    </div>
  );
};
export default Presentation;
