import React, { useState, useEffect } from "react";
import { toastAlert } from "components/toastAlert/toastAlert";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import UpgradeModal from "components/UpgradeModal/UpgradeModal";
import LoadingEffect from "components/Loading/LoadingEffect";
import UpgradePlanModal from "components/UpgradeModal/UpgradePlanModal";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { handleChangeBackground } from "redux/actions/media_section";
import changeBackgroundImage from "assets/img/images/change_background/changeBackground.png";
import Loading_v2 from "components/Loading/Laoadingv2/Loadingv2";
import { ReactComponent as Upload } from "assets/img/newDesign/UploadBigArrow.svg";
import ReplayIcon from "@mui/icons-material/Replay";
import { handleChangeBackgroundLoading } from "redux/actions/media_section";
import "views/MediaSection/ImageTools/Create/ImgGen.css";
import FileContainer from "components/MediaSection/FileContainer";

import LimitPopUp from "views/plansComponent/LimitPopUp";
const ChangeBackground = () => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const backgroundChanger = useSelector((state) => state.background_changer);
  const [showModal, setShowModal] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [base64, setBase64] = useState("");
  const [totalImages, setTotalImages] = useState(0);
  const [input, setInput] = useState("");
  const [rotateValue, setRotateValue] = useState(0);
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [loadingText, setLoadingText] = useState("Uploading your Image");

  const [errors, setError] = useState();
  let error_msg =
    lng === "arab"
      ? "حجم الصورة لا يجب أن يزيد عن 5 ميجابايت"
      : "Image size should be less than 5 MB";
  let error_valid =
    lng === "arab"
      ? "يُسمح فقط بملفات JPG و JPEG و PNG"
      : "Only JPG, JPEG, and PNG files are allowed.";

  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;
  const isTablet = width <= 850;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [width]);

  useEffect(() => {
    if (user?.plan === "Trial" || user?.plan === "Starter") {
      setShow(true);
    }
  }, [user]);

  useEffect(() => {
    setTotalImages(user?.image_count_left);
  }, [user]);

  const handlePainting = async (e) => {
    e.preventDefault();
    if (totalImages === 0) {
      dispatch({ type: "SHOW_BACKGROUND_CHANGER_FALSE" });
      setShowModal(true);
      return;
    }
    if (input === "") {
      return toastAlert(t("Please enter a valid text"), undefined, "warning");
    }
    if (selectedImage === null) {
      return toastAlert(t("Please upload an image"), undefined, "warning");
    }
    let data = {
      image: base64,
      prompt: input,
    };
    dispatch(handleChangeBackground(data));
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    const file = await e.dataTransfer.files[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (allowedTypes.includes(file.type)) {
        if (file.size > 5242880) {
          setError(error_msg);
          return toastAlert(error_msg, undefined, "warning");
        } else {
          handleFile(file);
          dispatch({ type: "SHOW_BACKGROUND_CHANGER_TRUE" });
          setDragging(false);
        }
      } else {
        setError(error_valid);
        toastAlert(error_valid, undefined, "warning");
      }
    }
  };

  const handleImageUpload = async (e) => {
    const file = await e.target.files;
    if (file) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (allowedTypes.includes(file[0].type)) {
        if (file[0].size > 5000000) {
          setError(error_msg);
          return toastAlert(error_msg, undefined, "warning");
        } else {
          handleFile(file[0]);
          dispatch({ type: "SHOW_BACKGROUND_CHANGER_TRUE" });
          setDragging(false);
        }
      } else {
        setError(error_valid);
        toastAlert(error_valid, undefined, "warning");
      }
    }
  };

  const handleFile = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setBase64(reader.result.toString());
    };
    if (file && /image/.test(file.type)) {
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  const handleDownload = () => {
    const fileUrl = backgroundChanger?.backgroundChanger?.urls;
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = "hello"; // Specify the file name here
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  useEffect(() => {
    if (backgroundChanger?.loading) {
      let intervalTime;
      if (
        backgroundChanger?.loadingNumber >= 1 &&
        backgroundChanger?.loadingNumber < 25
      ) {
        intervalTime = 3000 / 25; // 5 seconds for 1-25, increment by 1 every 200ms
        setLoadingText("Uploading Your Image");
      } else if (
        backgroundChanger?.loadingNumber >= 25 &&
        backgroundChanger?.loadingNumber < 50
      ) {
        intervalTime = 5000 / 25; // 20 seconds for 25-50, increment by 1 every 800ms
        setLoadingText("Analyzing Your Image");
      } else if (
        backgroundChanger?.loadingNumber >= 50 &&
        backgroundChanger?.loadingNumber < 75
      ) {
        intervalTime = 10000 / 25; // 30 seconds for 50-75, increment by 1 every 1200ms
        setLoadingText("Changing Background");
      } else if (
        backgroundChanger?.loadingNumber >= 75 &&
        backgroundChanger?.loadingNumber < 100
      ) {
        intervalTime = 50000 / 25; // 10 seconds for 50-75, increment by 1 every 1200ms
        setLoadingText("Downloading the Image");
      }
      const timer = setInterval(() => {
        dispatch(handleChangeBackgroundLoading(backgroundChanger?.loadingNumber, "changer"));
      }, intervalTime);
      return () => {
        clearInterval(timer);
      };
    }
  }, [backgroundChanger?.loadingNumber, backgroundChanger?.loading]);
  return (
    <>
      {backgroundChanger?.loading ? (
        <div
          style={{
            height: "calc(100vh - 200px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading_v2
            loadingText={loadingText}
            setLoadingText={setLoadingText}
            loadingNumber={backgroundChanger?.loadingNumber}
          />
        </div>
      ) : backgroundChanger?.backgroundChanger?.length != 0 ? (
        <div style={{ position: "relative" }}>
          {backgroundChanger?.backgroundChanger?.length != 0 && (
            <>
              <div
                style={{
                  maxWidth: "600px",
                  minHeight: "450px",
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={backgroundChanger?.backgroundChanger?.urls}
                  className="bg_remover_background"
                />
              </div>
              <div className="beforeAfter-container">
                <div className="beforeafter-gray">
                  <button className="download-btn" onClick={handleDownload}>
                    <i
                      className="fas fa-arrow-down"
                      style={{
                        fontSize: "medium",
                        margin: "0 10px",
                      }}
                    ></i>
                    {t("Download")}
                  </button>
                  <button
                    className="newupload"
                    onClick={() => {
                      dispatch({ type: "NEW_BACKGROUND_CHANGER" });
                      setInput("");
                    }}
                  >
                    <ReplayIcon
                      sx={{
                        transform: "scale(-1)",
                        margin: "0 10px",
                        fontSize: "20px",
                      }}
                    />
                    {t("Try Again")}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          <div className="image_gen_container">
            <div
              className="image_gen_header"
              style={{
                padding: width < 500 ? "10px" : "20px",
                alignSelf: "flex-start",
              }}
            >
              <h4 style={{ textAlign: lng === "arab" ? "right" : "left" }}>
                {t(
                  "Dont like your background? change it instantly with Twwt.Ai background changer"
                )}
              </h4>
              <p style={{ textAlign: lng === "arab" ? "right" : "left" }}>
                {t(
                  "need a professional background? change your background to anything you can think of. simply describe it."
                )}
              </p>
              {backgroundChanger?.backgroundChanger?.length === 0 && (
                <form
                  onSubmit={handlePainting}
                  className="change_background_form"
                >
                  <input
                    type="textarea"
                    value={input}
                    disabled={backgroundChanger?.loading}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder={t("Describe Your Background")}
                  />
                  <button type="submit">
                    {backgroundChanger?.loading ? (
                      <LoadingEffect />
                    ) : (
                      t("Create")
                    )}
                  </button>
                </form>
              )}
              {backgroundChanger?.showRemover &&
                backgroundChanger?.backgroundChanger?.length === 0 &&
                backgroundChanger?.loading === false && (
                  <div
                    style={{
                      maxWidth: "500px",
                      minHeight: "450px",
                      margin: "auto",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ position: "relative" }}>
                      <span
                        onClick={() =>
                          dispatch({ type: "NEW_BACKGROUND_CHANGER" })
                        }
                        className="close_button"
                        style={{
                          left: lng === "arab" ? "0px" : null,
                          right: lng === "arab" ? null : "0px",
                          top: "0px",
                          background: "black",
                        }}
                      >
                        <CloseRoundedIcon
                          sx={{ fontSize: "20px", color: "white" }}
                        />
                      </span>
                      <img
                        className="bg_remover_background"
                        style={{ backgroundImage: "none" }}
                        src={selectedImage}
                      />
                    </span>
                  </div>
                )}

              {!backgroundChanger.showRemover && (
                <FileContainer
                  handleDrop={handleDrop}
                  handleDragOver={handleDragOver}
                  handleDragLeave={handleDragLeave}
                  handleUpload={handleImageUpload}
                  dragging={dragging}
                  types=".jpg,.jpeg,.png"
                  support="JPG , PNG"
                />
              )}
              {user?.plan === "Trial" ? (
                <UpgradeModal
                  images={true}
                  setShowModal={setShowModal}
                  showModal={showModal}
                />
              ) : (
                <LimitPopUp setShowModal={setShowModal} showModal={showModal} />
              )}

              <UpgradePlanModal show={show} setShow={setShow} width={width} />
            </div>
            <div className="right_image_media">
              <img
                src={changeBackgroundImage}
                alt="backgroundChanger-example"
                width={"100%"}
                height={"100%"}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ChangeBackground;
