import React, { createContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { baseUrl } from "api/baseUrl";
import { useDispatch } from "react-redux";

class OfferTimerState {
  hours = 0;
  minutes = 0;
  seconds = 0;

  constructor(hours, minutes, seconds) {
    this.hours = hours;
    this.minutes = minutes;
    this.seconds = seconds;
  }

  isTimeLeft() {
    return this.hours <= 0 && this.minutes <= 0 && this.seconds <= 0;
  }
}

// const initialValue = {
//     hours: 0,
//     minutes: 0,
//     seconds: 0,
//   }

const initialValue = new OfferTimerState(0, 0, 0);

export const OfferTimerContext = createContext(initialValue);

const OfferTimeProvider = (props) => {
  const bigLoading = useSelector((state) => state.loading);
  const user = useSelector((state) => state.user);
  const [deadLineState, setDeadLineState] = useState(
    user?.user_prefernce?.time
  );
  const [offerTime, setOfferTime] = useState(initialValue);
  const dispatch = useDispatch();

  let countDownDate = moment().add(2, "hours");

  useEffect(() => {
    if (!bigLoading) {
      if (!user?.user_prefernce?.time) {
        timer();
        dispatch({ type: "FETCH_USER" });
      }
    }
  }, [user]);

  const timer = async () => {
    let response = await fetch(`${baseUrl}updateUserPreference`, {
      method: "POST",
      headers: {
        Authorization: localStorage.getItem("sess"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        flag: true,
        time: countDownDate,
      }),
    }).then((res) => res.json());
    dispatch({ type: "FETCH_USER" })
      .then(async (data) => {
      })
      .catch((error) => {
        checkErr(error, setLoading, toastAlert);
      });
  };

  useEffect(() => {
    setDeadLineState(new Date(user?.user_prefernce?.time));
  }, [user]);

  const getTime = () => {
    var now = new Date().getTime();
    var timeLeft = deadLineState - now;
    if (timeLeft > 0) {
      const value = new OfferTimerState(
        Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)),
        Math.floor((timeLeft % (1000 * 60)) / 1000)
      );
      setOfferTime(value);
    }else{
      const value = new OfferTimerState(0,0,0);
      setOfferTime(value);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(deadLineState), 1000);
    return () => clearInterval(interval);
  }, [deadLineState]);

  return (
    <OfferTimerContext.Provider value={offerTime}>
      {props.children}
    </OfferTimerContext.Provider>
  );
};

export default OfferTimeProvider;
