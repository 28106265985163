export const FETCH_CASES = "FETCH_CASES";
export const LOAD_CASES = "LOAD_CASES";
export const CASES_LOADED = "CASES_LOADED";

export const FETCH_TEXT_AUDIO = "FETCH_TEXT_AUDIO";
export const LOAD_TEXT_AUDIO = "LOAD_TEXT_AUDIO";
export const LOADED_TEXT_AUDIO = "LOADED_TEXT_AUDIO";
export const NEW_TEXT_AUDIO = "NEW_TEXT_AUDIO";
export const SHOW_TEXT_AUDIO_FALSE = "SHOW_TEXT_AUDIO_FALSE";
export const SHOW_TEXT_AUDIO_TRUE = "SHOW_TEXT_AUDIO_TRUE";

export const FETCH_TEXT_SPEECH = "FETCH_TEXT_SPEECH";
export const LOAD_TEXT_SPEECH = "LOAD_TEXT_SPEECH";
export const LOADED_TEXT_SPEECH = "LOADED_TEXT_SPEECH";
export const NEW_TEXT_SPEECH = "NEW_TEXT_SPEECH";
export const SHOW_TEXT_SPEECH_FALSE = "SHOW_TEXT_SPEECH_FALSE";
export const SHOW_TEXT_SPEECH_TRUE = "SHOW_TEXT_SPEECH_TRUE";

export const FETCH_CLIP_AUDIO = "FETCH_CLIP_AUDIO";
export const LOAD_CLIP_AUDIO = "LOAD_CLIP_AUDIO";
export const LOADED_CLIP_AUDIO = "LOADED_CLIP_AUDIO";
export const NEW_CLIP_AUDIO = "NEW_CLIP_AUDIO";
export const SHOW_CLIP_AUDIO_FALSE = "SHOW_CLIP_AUDIO_FALSE";
export const SHOW_CLIP_AUDIO_TRUE = "SHOW_CLIP_AUDIO_TRUE";

export const FETCH_TEXT_VIDEO = "FETCH_TEXT_VIDEO";
export const LOAD_TEXT_VIDEO = "LOAD_TEXT_VIDEO";
export const LOADED_TEXT_VIDEO = "LOADED_TEXT_VIDEO";
export const NEW_TEXT_VIDEO = "NEW_TEXT_VIDEO";
export const SHOW_TEXT_VIDEO_FALSE = "SHOW_TEXT_VIDEO_FALSE";
export const SHOW_TEXT_VIDEO_TRUE = "SHOW_CLIP_AUDIO_TRUE";

export const FETCH_UPSCALE = "FETCH_UPSCALE";
export const LOAD_UPSCALE = "LOAD_UPSCALE";
export const LOADED_UPSCALE = "LOADED_UPSCALE";
export const NEW_UPSCALE = "NEW_UPSCALE";
export const SHOW_UPSCALE_FALSE = "SHOW_UPSCALE_FALSE";
export const SHOW_UPSCALE_TRUE = "SHOW_UPSCALE_TRUE";
export const UPLOADED_UPSCALE = "UPLOADED_UPSCALE";

export const FETCH_UPSCALE_V1 = "FETCH_UPSCALE_V1";
export const LOAD_UPSCALE_V1 = "LOAD_UPSCALE_V1";
export const LOADED_UPSCALE_V1 = "LOADED_UPSCALE_V1";
export const NEW_UPSCALE_V1 = "NEW_UPSCALE_V1";
export const SHOW_UPSCALE_V1_FALSE = "SHOW_UPSCALE_V1_FALSE";
export const SHOW_UPSCALE_V1_TRUE = "SHOW_UPSCALE_V1_TRUE";

export const FETCH_ENHANCE = "FETCH_ENHANCE";
export const LOAD_ENHANCE = "LOAD_ENHANCE";
export const LOADED_ENHANCE = "LOADED_ENHANCE";
export const NEW_ENHANCE = "NEW_ENHANCE";
export const SHOW_ENHANCE_FALSE = "SHOW_ENHANCE_FALSE";
export const SHOW_ENHANCE_TRUE = "SHOW_ENHANCE_TRUE";
export const SHOW_ENHANCE_UPLOADING_FALSE = "SHOW_ENHANCE_UPLOADING_FALSE";
export const SHOW_ENHANCE_UPLOADING_TRUE = "SHOW_ENHANCE_UPLOADING_TRUE";

export const FETCH_REMOVE_VIDEO = "FETCH_REMOVE_VIDEO";
export const LOAD_REMOVE_VIDEO = "LOAD_REMOVE_VIDEO";
export const LOADED_REMOVE_VIDEO = "LOADED_REMOVE_VIDEO";
export const NEW_REMOVE_VIDEO = "NEW_REMOVE_VIDEO";
export const SHOW_REMOVE_VIDEO_FALSE = "SHOW_REMOVE_VIDEO_FALSE";
export const SHOW_REMOVE_VIDEO_TRUE = "SHOW_REMOVE_VIDEO_TRUE";

export const FETCH_REIMAGINE_V2 = "FETCH_REIMAGINE_V2";
export const FETCH_INITIAL_IMAGE = "FETCH_INITIAL_IMAGE";
export const LOAD_REIMAGINE_V2 = "LOAD_REIMAGINE_V2";
export const LOADED_REIMAGINE_V2 = "LOADED_REIMAGINE_V2";
export const NEW_REIMAGINE_V2 = "NEW_REIMAGINE_V2";
export const SHOW_REIMAGINE_V2_FALSE = "SHOW_REIMAGINE_V2_FALSE";
export const SHOW_REIMAGINE_V2_TRUE = "SHOW_REIMAGINE_V2_TRUE";

export const FETCH_IMAGES = "FETCH_IMAGES";
export const LOAD_IMAGES = "LOAD_IMAGES";
export const IMAGES_LOADED = "IMAGES_LOADED";
export const EMPTY_IMAGE = "EMPTY_IMAGE";

export const FETCH_IMAGE_DESIGN = "FETCH_IMAGE_DESIGN";
export const LOAD_IMAGE_DESIGN = "LOAD_IMAGE_DESIGN ";
export const IMAGE_DESIGN_LOADED = "IMAGE_DESIGN_LOADED";
export const EMPTY_IMAGE_DESIGN = "EMPTY_IMAGE_DESIGN";

export const FETCH_MORE_IMAGES = "FETCH_MORE_IMAGES";
export const EMPTY_MORE_LIKE_THIS = "EMPTY_MORE_LIKE_THIS";

export const FETCH_BUSINESS_CASES = "FETCH_BUSINESS_CASES";
export const LOAD_BUSINESS_CASES = "LOAD_BUSINESS_CASES";
export const CASES_BUSINESS_LOADED = "CASES_BUSINESS_LOADED";

export const CASES_NEW_LOADED = "CASES_NEW_LOADED";
export const LOAD_NEW_CASES = "LOAD_NEW_CASES";
export const FETCH_NEW_CASES = "FETCH_NEW_CASES";

export const MOST_USED_CASES = "MOST_USED_CASES";

export const LOADING = "LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const USER_ENTERPRISE = "USER_ENTERPRISE";
export const USER = "USER";
export const LOGOUT = "LOGOUT";
export const FETCH_OLD_IMAGES = "FETCH_OLD_IMAGES";
export const LOAD_OLD_IMAGES = "LOAD_OLD_IMAGES";
export const OLD_IMAGES_LOADED = "OLD_IMAGES_LOADED";
export const OLD_IMAGES_PAGINATION = "OLD_IMAGES_PAGINATION";

export const USER_SUBSCRIPTION = "USER_SUBSCRIPTION";

export const FETCH_BLOG = "FETCH_BLOG";
export const LOAD_BLOG = "LOAD_BLOG";
export const BLOG_LOADED = "BLOG_LOADED";

//media section

export const LOAD_SCRIBBLE = "LOAD_SCRIBBLE";
export const FETCH_SCRIBBLE = "FETCH_SCRIBBLE";
export const SCRIBBLE_LOADED = "SCRIBBLE_LOADED";
export const NEW_SCRIBBLE = "NEW_SCRIBBLE";

export const LOAD_REMOVER = "LOAD_BGREMOVER";
export const FETCH_REMOVER = "FETCH_BGREMOVER";
export const REMOVER_LOADED = "BGREMOVER_LOADED";
export const NEW_BACKGROUND_REMOVER = "NEW_BACKGROUND_REMOVER";
export const SHOW_BACKGROUND_REMOVER_FALSE = "SHOW_BACKGROUND_REMOVER_FALSE";
export const SHOW_BACKGROUND_REMOVER_TRUE = "SHOW_BACKGROUND_REMOVER_TRUE";
export const LOAD_PERCENTAGE_BACKGROUND_REMOVER = "LOAD_PERCENTAGE_BACKGROUND_REMOVER";

export const LOAD_CHANGER = "LOAD_BGCHANGER";
export const FETCH_CHANGER = "FETCH_BGCHANGER";
export const CHANGER_LOADED = "BGCHANGER_LOADED";
export const NEW_BACKGROUND_CHANGER = "NEW_BACKGROUND_CHANGER";
export const SHOW_BACKGROUND_CHANGER_FALSE = "SHOW_BACKGROUND_CHANGER_FALSE";
export const SHOW_BACKGROUND_CHANGER_TRUE = "SHOW_BACKGROUND_CHANGER_TRUE";
export const LOAD_PERCENTAGE = "LOAD_PERCENTAGE";
export const LOAD_PERCENTAGE_BACKGROUND_CHANGER = "LOAD_PERCENTAGE_BACKGROUND_CHANGER";

export const LOAD_REDESIGN = "LOAD_REDESIGN";
export const FETCH_REDESIGN = "FETCH_REDESIGN";
export const REDESIGN_LOADED = "REDESIGN_LOADED";
export const NEW_REDESIGN = "NEW_REDESIGN";
export const UPLOADED_IMAGINE = "UPLOADED_IMAGINE";

export const LOAD_HEAD = "LOAD_HEAD";
export const FETCH_HEAD = "FETCH_HEAD";
export const HEAD_LOADED = "HEAD_LOADED";
export const NEW_HEAD = "NEW_HEAD";

export const LOAD_PERCENTAGE_ENHANCE = "LOAD_PERCENTAGE_ENHANCE";
export const LOAD_PERCENTAGE_UPSCALE = "LOAD_PERCENTA_UPSCALE";
export const LOAD_PERCENTAGE_REMOVER = "LOAD_PERCENTAGE_REMOVER";
export const LOAD_PERCENTAGE_CHANGER = "LOAD_PERCENTAGE_CHANGER";
export const LOAD_PERCENTAGE_REDESIGN = "LOAD_PERCENTAGE_REDESIGN";
export const LOAD_PERCENTAGE_HEAD = "LOAD_PERCENTAGE_HEAD";
export const LOAD_PERCENTAGE_SCRIBBLE = "LOAD_PERCENTAGE_SCRIBBLE";
export const LOAD_PERCENTAGE_ENHANCE_AUDIO = "LOAD_PERCENTAGE_ENHANCE_AUDIO";
export const LOAD_PERCENTAGE_CLIP_AUDIO = "LOAD_PERCENTAGE_CLIP_ENHANCE_AUDIO";
export const LOAD_PERCENTAGE_REMOVE_VIDEO = "LOAD_PERCENTAGE_REMOVE_VIDEO";

export const LOAD_INFINITE_ZOOM = "LOAD_INFINITE_ZOOM";
export const FETCH_INFINITE_ZOOM = "FETCH_INFINITE_ZOOM";
export const LOADED_INFINITE_ZOOM = "LOADED_INFINITE_ZOOM";
export const NEW_INFINITE_ZOOM = "NEW_INFINITE_ZOOM";

export const LOAD_OVERLAP_ANIMATION = "LOAD_OVERLAP_ANIMATION";
export const FETCH_OVERLAP_ANIMATION = "FETCH_OVERLAP_ANIMATION";
export const LOADED_OVERLAP_ANIMATION = "LOADED_OVERLAP_ANIMATION";
export const NEW_OVERLAP_ANIMATION = "NEW_OVERLAP_ANIMATION";
export const UPLOADED_HEAD = "UPLOADED_HEAD";

export const LOAD_ENHANCE_AUDIO = "LOAD_ENHANCE_AUDIO";
export const FETCH_ENHANCE_AUDIO = "FETCH_ENHANCE_AUDIO";
export const ENHANCE_AUDIO_LOADED = "ENHANCE_AUDIO_LOADED";
export const NEW_ENHANCE_AUDIO = "NEW_ENHANCE_AUDIO";
export const FETCH_ENHANCE_LOADING = "FETCH_ENHANCE_LOADING";
export const SHOW_ENHANCE_AUDIO_FALSE = "SHOW_ENHANCE_AUDIO_FALSE";
export const SHOW_ENHANCE_AUDIO_TRUE = "SHOW_ENHANCE_AUDIO_TRUE";

export const LOAD_ENHANCE_VIDEO = "LOAD_ENHANCE_VIDEO";
export const FETCH_ENHANCE_VIDEO = "FETCH_ENHANCE_VIDEO";
export const ENHANCE_VIDEO_LOADED = "ENHANCE_VIDEO_LOADED";
export const NEW_ENHANCE_VIDEO = "NEW_ENHANCE_VIDEO";
export const FETCH_ENHANCE_VIDEO_LOADING = "FETCH_ENHANCE_VIDEO_LOADING";
export const SHOW_ENHANCE_VIDEO_FALSE = "SHOW_ENHANCE_VIDEO_FALSE";
export const SHOW_ENHANCE_VIDEO_TRUE = "SHOW_ENHANCE_VIDEO_TRUE";



