import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import OopsSvg from "assets/img/newDesign/Question.svg";
import { changeStripeSubscription } from "../Functions/stripe";
import { HyperPayChangeSubscription } from "../Functions/hyperPay";
import { changeCryptoSubscription } from "../Functions/crypto";
import { useDispatch, useSelector } from "react-redux";
import "./SubscribeModal.css";
import { CircularProgress } from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { toastAlert } from "components/toastAlert/toastAlert";
import Paypal from "../Paypal/Paypal";

const SubscribeModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [isLoading, setIsLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  return (
    <Modal
      className={lng === "arab" ? "modal modal-primary arabicFont thank-you-modal" : "modal modal-primary englishFont thank-you-modal"}
      show={props.showModal}
      centered
      onHide={() => props.setShowModal(false)}
    >
      <Modal.Header>
        <button onClick={() => props.setShowModal(false)} className="btn-close">
          <i className="nc-icon nc-simple-remove"></i>
        </button>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="row justify-content-center">
          <img style={{ maxHeight: "300px" }} className="modalImgWrapper" src={OopsSvg} />
        </div>
        <h3 style={{ margin: "0px", lineHeight: "40px" }}>{props?.errorMessage ?? t("Are you sure you want to change your subscription?")}</h3>
        {props?.lastPayment?.last_transaction_id?.paymentGate === "paypal" ? (
          <Paypal
            selectedPlan={props.selectedPlanPayPal}
            selectedNumber={props.selectedNumber}
            isYearly={props.isYearly}
            user={user}
            setThankyou={props.setShowModalPayPal}
            setShowSubscribeModal={props.setShowModal}
            setShowCheckoutModal={props.setIsOpen}
          />
        ) : (
          <button
            onClick={() => {
              if (props.lastPayment?.last_transaction_id?.paymentGate === "hyperpay") {
                HyperPayChangeSubscription(setIsLoading, props.setShowModal, props.planDetails, props.editType, props.selectedNumber, props.price, props.deletedUsers, props.isYearly);
              } else if (props.lastPayment?.last_transaction_id?.paymentGate === "coinbase") {
                changeCryptoSubscription(props.setShowModal, props.planDetails, props.editType, props.selectedNumber, props.price, props.deletedUsers);
              } else if (props.lastPayment?.last_transaction_id?.paymentGate === "stripe") {
                changeStripeSubscription(props.setShowModal, props.subscribe_id, props.editType, props.selectedNumber, props.deletedUsers, props.setShowTyModal, dispatch, setLoaded, setIsLoading);
              } else {
                toastAlert("error", t("You cant change your subscription from website"));
              }
            }}
            style={{ background: "linear-gradient(to left, #c03eff, #af62fd)" }}
            className="btn common-btn-modal"
          >
            {loaded ? <CheckRoundedIcon sx={{ padding: "20px" }} /> : isLoading ? <CircularProgress sx={{ color: "white", fontSize: "20px" }} /> : <>{t("Yes")}</>}
          </button>
        )}
      </Modal.Body>
      <Modal.Footer>
        {props.editType == "downgrade" && (
          <p
            style={{
              fontSize: "12px",
              margin: "10px auto",
              textAlign: "center",
              fontFamily: lng === "arab" ? "Tajawal" : "poppins",
              fontWeight: "600",
            }}
          >
            {t("Downgrading plan will take effect on the next renewal")}
          </p>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default SubscribeModal;
