import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
import LoadingEffect from "components/Loading/LoadingEffect";
import draghover from "assets/img/drag-img-hover.png";
import dragphoto from "assets/img/newDesign/cloud.svg";
import none from "assets/img/loading-images/none.png";
import anime from "assets/img/loading-images/anime.png";
import cinematic from "assets/img/loading-images/cinematic.png";
import threeD from "assets/img/loading-images/3d.png";
import poly from "assets/img/loading-images/lowpoly.png";
import digital from "assets/img/loading-images/digital-art.png";
import photog from "assets/img/loading-images/photographic.png";
import fantasy from "assets/img/loading-images/fantasy-art.png";
import comic from "assets/img/loading-images/comic-book.png";
import neon from "assets/img/loading-images/neon.png";
import isometric from "assets/img/loading-images/isometric.png";
import origami from "assets/img/loading-images/orgami.png";
import clay from "assets/img/loading-images/clay.png";
import Arty from "assets/img/loading-images/arty-picture.png";
import circleSVG from "assets/img/newDesign/circleBC.svg";
import Enhance from "views/MediaSection/ImageTools/Create/ImgGen.css";
import UpgradeModal from "components/UpgradeModal/UpgradeModal";
import "font-awesome/css/font-awesome.min.css";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "axios";
import { baseUrl } from "api/baseUrl";
import { toastAlert } from "components/toastAlert/toastAlert";
import { useTranslation } from "react-i18next";
import checkErr from "redux/actions/api_error_func";
import ImageGenerated from "components/imageGenerated/ImageGenerated";
import bottomArrow from "assets/img/newArrow.png";
import UpgradePlanModal from "components/UpgradeModal/UpgradePlanModal";
import { getimageDesign } from "redux/actions/image";
import LimitPopUp from "views/plansComponent/LimitPopUp";
const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

const ImageDesign = () => {
  // drag and drop start here
  const { images, title, morelikeThis, imageDesign, imageLoading } =
    useSelector((state) => state.Images);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const flag = "true";
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [width, setWidth] = useState(window.innerWidth);
  const [currentFormats, setCurrentFormats] = useState(formats);
  const [totalImages, setTotalImages] = useState(0);
  const [showU, setShowU] = useState(false);

  const isMobile = width <= 768;
  const isTablet = width <= 850;
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    setCurrentFormats(
      isMobile || isTablet
        ? [
            "size",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
            "video",
          ]
        : formats
    );
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const user = useSelector((state) => state.user);
  const [dragging, setDragging] = useState(false);
  const [percentage, setPercentage] = useState(0);
  // const [timeLeft, setTimeLeft] = useState(0);
  const [complete, setComplete] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showEnhance, setShowEnhance] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [fileSize, setFileSize] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loadingSwitch, setLoadingSwitch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [serverImage, setServerImage] = useState(null);
  const [uploadingLoading, setUploadingLoading] = useState(false);
  const [errorUploading, setErrorUploading] = useState(false);
  const [showAdvance, setShowAdvance] = useState(false);
  const [userData, setUserData] = useState({
    prompt: "more image like this",
    selectStyle: "",
    outputNumber: user?.plan === "Trial" ? 1 : 4,
    image: "",
    is_uploaded: "True",
  });

  useEffect(() => {
    if (selectedFile !== null) {
      handleUpload();
    }
  }, [selectedFile]);

  useEffect(() => {
    if (user?.plan === "Trial" || user?.plan === "Starter") {
      setShowU(false);
    }
  }, [user]);

  const handleDrop = async (e) => {
    e.preventDefault();
    const file = await e.dataTransfer.files[0];
    setSelectedFile(file);
    handleFile(file);
    if (file) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (allowedTypes.includes(file.type)) {
        setComplete(false);
        setShowEnhance(true);
        setDragging(false);
        // handleUpload();
      } else {
        toastAlert(
          "Only JPG, JPEG, and PNG files are allowed.",
          undefined,
          "warning"
        );
      }
    }
  };

  const handleFileInput = async (e) => {
    e.preventDefault();
    const file = await e.target.files;
    setSelectedFile(file[0]);
    handleFile(file[0]);

    if (file) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!allowedTypes.includes(file[0].type)) {
        toastAlert(
          "Only JPG, JPEG, and PNG files are allowed.",
          undefined,
          "warning"
        );
      } else {
        if (file[0].size > 5242880) {
          return toastAlert(
            "Image size should be less than 5 MB",
            undefined,
            "warning"
          );
        } else {
          // handleUpload();
          setComplete(false);
          setShowEnhance(true);
        }
      }
    }
  };
  const handleFile = (file) => {
    if (file && /image/.test(file.type)) {
      setSelectedImage(URL.createObjectURL(file));
      // const fileName = file.name;
      setFileName(file.name);
      const fileSizeInBytes = file.size;
      const fileSizeInKB = fileSizeInBytes / 1024;
      setFileSize(fileSizeInKB.toFixed(2), "KB");
      setSelectedFile(file);
      // file is an image, handle it here
    }
  };
  const tryAgain = () => {
    dispatch({ type: "EMPTY_IMAGE_DESIGN" });
    setShowEnhance(false);
    setShow(false);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    setUploadingLoading(true);
    setErrorUploading(false);
    await formData.append("file", selectedFile);
    const config = {
      headers: {
        Authorization: localStorage.getItem("sess"),
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      setErrorUploading(false);
      await axios
        .post(`${baseUrl}upload-user-images`, formData, config)
        .then((res) => {
          setUploadingLoading(false);
          setServerImage(res.data.url);
        })
        .catch((error) => {
          checkErr(error, setUploadingLoading, toastAlert);
          setErrorUploading(true);
        });
    } catch (error) {
      // setShowEnhance(false)
      checkErr(error, setUploadingLoading, toastAlert);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };
  const submitImages = (e) => {
    if (user?.image_count_left === 0) {
      return setShowModal(true);
    } else if (!userData?.prompt) {
      return toastAlert("Please enter a valid text", undefined, "warning");
    }
    setShowAdvance(false);
    setShow(true);
    dispatch(getimageDesign({ ...userData, image: serverImage }));
  };

  const styles = [
    {
      name: "None",
      nom: 1,
      image: none,
    },
    {
      name: "Anime",
      image: anime,
    },
    {
      name: "Photographic",
      image: photog,
    },
    {
      name: "Digital art",
      image: digital,
    },
    {
      name: "Fantasy",
      image: fantasy,
    },
    {
      name: "Cinematic",
      image: cinematic,
    },
    {
      name: "Low poly",
      image: poly,
    },
    {
      name: "3D",
      image: threeD,
    },
    {
      name: "Comic Book",
      image: comic,
    },
    {
      name: "Neon",
      image: neon,
    },
    {
      name: "Isometric",
      image: isometric,
    },
    {
      name: "Origami",
      image: origami,
    },
    {
      name: "Craft clay",
      image: clay,
    },
    {
      name: "Line Art",
      image: Arty,
    },
  ];
  let sortStyle = styles.sort((a, b) => a.nom - b.nom);
  return (
    <>
      <div
        style={{ padding: "40px 15px ", rowGap: "unset" }}
        className=" all_images_wrapper row"
      >
        <h4 style={{ fontWeight: "600" }}>{t("Change Image Styles")}</h4>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => setShowAdvance(!showAdvance)}
        >
          {!show && !showEnhance && (
            <>
              <h6 style={{ paddingTop: "10px" }}>{t("Select Style")}</h6>

              <img
                onClick={() => setShowAdvance(!showAdvance)}
                src={bottomArrow}
                alt="bottom arrow"
                style={{
                  height: "15px",
                  width: "15px",
                  margin: lng === "arab" ? "0 10px 0 0" : "0 0 0 10px",
                  cursor: "pointer",
                  transform: !showAdvance
                    ? lng === "arab"
                      ? "rotate(90deg)"
                      : "rotate(90deg)"
                    : "rotate(270deg)",
                }}
              />
            </>
          )}
        </div>

        {showAdvance ? (
          <>
            <div
              id="image_style_container_overflow"
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "0px",
                textAlign: lng === "arab" ? "right" : "left",
              }}
              className="form-group col-lg-12 col-md-12 col-sm-12"
            >
              <div className="row image_style_container2">
                {sortStyle.map((items, index) => {
                  return (
                    <button
                      key={index}
                      style={{
                        outline:
                          userData.selectStyle === items.name
                            ? "4px solid #3cc8eb"
                            : null,
                        backgroundImage: `url(${items.image})`,
                        backgroundSize: "cover",
                        position: "relative",
                        border: "unset",
                        backgroundColor: "transparent",
                        backgroundRepeat: "no-repeat",
                        padding: "5px",
                        borderRadius: "15px",
                        backgroundPosition: "center center",
                        overflow: "hidden",
                        minWidth: "100px",
                      }}
                      type="button"
                      className="image_style_buttons"
                      onClick={() =>
                        setUserData({
                          ...userData,
                          selectStyle: items.name,
                        })
                      }
                    >
                      <div
                        style={{
                          position: "absolute",
                          bottom: "0px",
                          left: "0px",
                          right: "0px",
                          display: "flex",
                          backgroundColor: "rgb(0 0 0 / 72%)",
                          padding: "5px",
                        }}
                      >
                        <span
                          style={{
                            color: "#fff",
                            margin: "auto",
                            fontWeight: "600",
                          }}
                        >
                          {t(
                            items.name === "None"
                              ? items.name.replace(1, "")
                              : t(items.name)
                          )}
                        </span>
                      </div>
                    </button>
                  );
                })}
              </div>
            </div>
          </>
        ) : null}

        {!show ? (
          <>
            {showEnhance ? (
              <>
                <Enhance
                  flag={flag}
                  uploadingLoading={uploadingLoading}
                  selectedImage={selectedImage}
                  showEnhance={showEnhance}
                  setShowEnhance={setShowEnhance}
                  errorUploading={errorUploading}
                  fileName={fileName}
                  fileSize={fileSize}
                  serverImage={serverImage}
                />
              </>
            ) : (
              <>
                <div
                  className="image-drop-container"
                  style={{
                    padding: "0px",
                    width: "100%",
                  }}
                >
                  <label className="file-input-label">
                    <input
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      onChange={handleFileInput}
                    />
                    <div
                      className={`image-drop-zone ${
                        dragging ? "dragging" : "undraging"
                      }`}
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                    >
                      <>
                        <div className="image-drop-text">
                          {dragging ? (
                            <img
                              src={draghover}
                              alt="other-img"
                              style={{
                                height: "80px",
                                width: "122px",
                                objectFit: "cover",
                                borderRadius: "5px",
                              }}
                            />
                          ) : (
                            <img
                              src={dragphoto}
                              alt="drop-img"
                              style={{
                                height: "80px",
                                width: "122px",
                                objectFit: "cover",
                                borderRadius: "5px",
                              }}
                            />
                          )}
                          <h5 style={{ marginBottom: "0px" }}>
                            {t("Drop your image here, or")}{" "}
                            <span className="file-input-label">
                              {t("browse")}
                            </span>
                          </h5>

                          <div className="image-drop-support-text">
                            {t("Supports JPG , PNG")}
                          </div>
                          <div className="image-drop-support-text">
                            {t("Maximum Size 5MB")}
                          </div>
                        </div>
                        {percentage > 0 && (
                          <div className="upload-progress-bar">
                            <div
                              className="progress-bar"
                              style={{ width: `${percentage}%` }}
                            ></div>
                            <div className="progress-info">
                              {percentage}% complete ({timeLeft} seconds left)
                              <button>{t("Stop")}</button>
                              <button>{t("Cancel")}</button>
                            </div>
                          </div>
                        )}
                      </>
                    </div>
                  </label>
                </div>
              </>
            )}
          </>
        ) : imageLoading ? (
          <div
            style={{
              minHeight: "unset",
              width: "100%",
              display: "flex",
              alignItems: "center",
              padding: "30px 0px",
              justifyContent: "space-around",
              overflow: "auto",
            }}
          >
            <Skeleton
              height={width <= 500 ? 350 : 400}
              width={width <= 500 ? 350 : 450}
              style={{ borderRadius: "15px", margin: "auto" }}
            />
          </div>
        ) : (
          imageDesign?.map((item, i) => (
            <ImageGenerated
              show={show}
              userData={userData}
              // moreLikeThis={moreLikeThis}
              key={i}
              item={item}
              i={i}
              title={title}
            />
          ))
        )}
      </div>
      {imageDesign?.length === 0 ? (
        <button
          disabled={loadingSwitch}
          onClick={submitImages}
          style={{
            padding:
              isMobile || isTablet
                ? "15px 40px !important"
                : "15px 60px !important",
            background: loadingSwitch
              ? "gray"
              : "linear-gradient(45deg, rgba(17, 113, 216, 1) 0%, rgba(60, 200, 235, 1) 100%)",
            width: "170px",
            minHeight: "46px",
            borderRadius: "15px",
            alignSelf: "center",
            pointerEvents: loadingSwitch && "none",
          }}
          className="form_buttions"
        >
          {imageLoading ? <LoadingEffect /> : t("Create Image")}
        </button>
      ) : (
        !imageLoading && (
          <button
            style={{ maxWidth: "200px", margin: "10px auto" }}
            className="newupload"
            onClick={tryAgain}
          >
            {t("New Upload")} <i className="fas fa-arrow-up"></i>
          </button>
        )
      )}
      <UpgradePlanModal show={showU} setShow={setShowU} />
      {user?.plan === "Trial" ? (
        <UpgradeModal
          images={true}
          setShowModal={setShowModal}
          showModal={showModal}
        />
      ) : (
        <LimitPopUp setShowModal={setShowModal} showModal={showModal} />
      )}
    </>
  );
};

export default ImageDesign;
