import { toastAlert } from "components/toastAlert/toastAlert";
import { FETCH_IMAGES } from "redux/contstants";
import { LOAD_IMAGES } from "redux/contstants";
import { IMAGES_LOADED } from "redux/contstants";
import { FETCH_MORE_IMAGES } from "redux/contstants";
import { LOAD_MORE_IMAGES } from "redux/contstants";
import { IMAGES_MORE_LOADED } from "redux/contstants";
import { EMPTY_MORE_LIKE_THIS } from "redux/contstants";
import { EMPTY_IMAGE } from "redux/contstants";
import { FETCH_IMAGE_DESIGN } from "redux/contstants";
import { IMAGE_DESIGN_LOADED } from "redux/contstants";
import { LOAD_IMAGE_DESIGN } from "redux/contstants";
import * as api from "../../api";
import checkErr from "./api_error_func";

export const getAllImages = (userText) => async (dispatch) => {
  dispatch({ type: EMPTY_MORE_LIKE_THIS })
  try {
    const datauser = {
      prompt: userText?.prompt,
      image_size: userText?.aspectRatio,
      num_images: userText?.outputNumber || '4',
      style: userText.selectStyle,
    };
    dispatch({ type: LOAD_IMAGES });
    var result = await api.imgaiAPI(datauser, "image-gen-dreamshaper", dispatch);
    dispatch({ type: 'FETCH_USER' })
    await dispatch({ type: FETCH_IMAGES, data: { ...result?.data, prompt: userText?.prompt } });
  } catch (error) {
    dispatch({ type: IMAGES_LOADED });
    checkErr(error, false, toastAlert)
  }
};

export const getimageDesign = (userText) => async (dispatch) => {
  dispatch({ type: EMPTY_IMAGE })
  try {
    const datauser = {
      prompt: userText?.prompt,
      image: userText?.image,
      num_images: userText?.outputNumber || '4',
      style: userText.selectStyle,
    };
    dispatch({ type: LOAD_IMAGE_DESIGN });
    var result = await api.morelikethisAPI(datauser, "more-like-this", dispatch);
    dispatch({ type: 'FETCH_USER' })
    await dispatch({ type: FETCH_IMAGE_DESIGN, data: { ...result.data, prompt: userText?.prompt } });
    dispatch({ type: IMAGE_DESIGN_LOADED });

  } catch (error) {
    // console.log(error, "error");
    dispatch({ type: IMAGE_DESIGN_LOADED });
    checkErr(error, false, toastAlert)
  }
}

export const getMoreLikeThisImages = (userText) => async (dispatch) => {
  dispatch({ type: EMPTY_IMAGE })
  try {
    const datauser = {
      prompt: userText?.prompt,
      image: userText?.image,
      num_images: userText?.outputNumber || '4',
      style: userText.selectStyle,
    };
    dispatch({ type: LOAD_IMAGES });
    var result = await api.morelikethisAPI(datauser, "more-like-this", dispatch);
    dispatch({ type: 'FETCH_USER' })
    await dispatch({ type: FETCH_MORE_IMAGES, data: { ...result.data, prompt: userText?.prompt } });
    await dispatch({ type: FETCH_IMAGE_DESIGN, data: { ...result.data, prompt: userText?.prompt } });


  } catch (error) {
    // console.log(error, "error");
    dispatch({ type: IMAGES_LOADED });
    checkErr(error, false, toastAlert)
  }
}


