import { FETCH_OLD_IMAGES } from "redux/contstants";
import { LOAD_OLD_IMAGES } from "redux/contstants";
import { OLD_IMAGES_LOADED } from "redux/contstants";
import { OLD_IMAGES_PAGINATION } from "redux/contstants";
export default (oldImages = { oldImages: [], imagePagination: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_OLD_IMAGES:
            return { ...oldImages, oldImages: action.data, loading: false };
        case OLD_IMAGES_PAGINATION:
            return { ...oldImages, imagePagination: action.data, loading: false }
        case LOAD_OLD_IMAGES:
            return { ...oldImages, loading: true };
        case OLD_IMAGES_LOADED:
            return { ...oldImages, loading: false };
        default:
            return oldImages;
    }
}

