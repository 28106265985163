import { baseUrl } from 'api/baseUrl';
import CustomRadioBtn from 'components/CustomRadioSelection/CustomRadioBtn';
import CustomRadioGroup from 'components/CustomRadioSelection/CustomRadioGroup';
import { toastAlert } from 'components/toastAlert/toastAlert';
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader, ModalTitle, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import checkErr from 'redux/actions/api_error_func';
import './SelectPaymentMethod.css';

const SelectPaymentMethod = (props) => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [myCards, setMyCards] = useState([]);
    const [selectedCard, setSelectedCard] = useState(props?.defaultCard ?? undefined);

    const getMyCards = async () => {
        setLoading(true)
        // const response = await fetch("http://192.168.100.157:5000/stripe/myPaymentMethods", {
        const response = await fetch(`${baseUrl}stripe/myPaymentMethods`, {

            method: "GET",
            headers: {
                Authorization: localStorage.getItem("sess"),
                'Content-Type': 'application/json'

            },
        }).then((res) => res.json()).then(async (data) => {
            if (data.success) {
                setMyCards(data.payment_methods);
                setSelectedCard(data.default);
                setLoading(false);

            }
        }).catch(error => {
            checkErr(error, setLoading, toastAlert)
        })
    }

    const onChange = (e) => {
        setSelectedCard(e.target.value);
    }

    const saveCard = async () => {
        setUpdating(true);

        // const response = await fetch("http://192.168.100.157:5000/stripe/updateDefaultPaymentMethod", {
        const response = await fetch(`${baseUrl}stripe/updateDefaultPaymentMethod`, {

            method: "POST",
            headers: {
                Authorization: localStorage.getItem("sess"),
                'Content-Type': 'application/json'

            },
            body: JSON.stringify({ payment_method_id: selectedCard })
        }).then((res) => res.json()).then(async (data) => {
            if (data.success) {
                setUpdating(false);
                props.setDefaultCard(selectedCard)
                props.setShowModal(false);

            }
        }).catch(error => {
            checkErr(error, setLoading, toastAlert)
        })
    }

    useEffect(() => {
        getMyCards();
    }, [])
    return (
        <Modal
            className={lng === 'arab' ? "modal modal-primary arabicFont payment" : "modal modal-primary englishFont payment"}
            centered
            show={props.showModal}
            onHide={() => props.setShowModal(false)}
        >
            <button
                onClick={() => props.setShowModal(false)}
                style={{
                    position: 'absolute',
                    height: 30,
                    width: 30,
                    right: 7,
                    top: 5,
                    border: 'none',
                    background: 'none',
                    color: 'grey'
                }}>
                <p style={{ fontSize: 25 }}>x</p>

            </button>
            <Modal.Header>
                <h2>{t("Your selected payment method")}</h2>
            </Modal.Header>
            <Modal.Body>
                {loading ?

                    (<div
                        className="d-flex align-items-center justify-content-center"
                        style={{ height: "10vh" }}
                    >
                        {" "}
                        <Spinner animation="grow" />
                    </div>)

                    :
                    <div style={{ margin: '0px 20px 20px 20px' }}>
                        {myCards.length === 0 ?
                            <h5 style={{ margin: '20px 0px 40px 0px', textAlign: 'center' }}>{t("No payment methods yet")}</h5>
                            : <CustomRadioGroup showModal={props.showModal} setShowModal={props.setShowModal} options={myCards} onSelect={onChange} defaultSelected={selectedCard} />}

                    </div>
                }
                {myCards.length !== 0 && <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                    <button
                        onClick={saveCard}
                        style={{ backgroundColor: updating && 'grey', pointerEvents: updating && 'none' }}
                        className="btn common-btn">
                        {updating ? t('Saving...') : t('Save')}
                    </button>
                </div>}
            </Modal.Body>
        </Modal>
    )
}

export default SelectPaymentMethod