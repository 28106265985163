import React, { useEffect, useState } from "react";
import "./Business.scss";
import BusinessFilter from "./BusinessFilter";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import { useTranslation } from "react-i18next";
import busi from "../../assets/img/business/busi1.png";
import markt from "../../assets/img/business/meg.png";
import post from "../../assets/img/business/post1.png";
import user from "../../assets/img/business/user1.png";
import script from "../../assets/img/business/script1.png";
import seo from "../../assets/img/business/seo.png";
import strat from "../../assets/img/business/strat.png";
import product from "../../assets/img/business/product.png";
import link from "../../assets/img/business/linkedin.png";
import google from "../../assets/img/business/google.png";
import meta from "../../assets/img/business/meta.png";
import snap from "../../assets/img/business/snapchat1.png";
import tiktok from "../../assets/img/business/Tiktok1.png";
import presen from "../../assets/img/business/presentationEdit.svg"
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import LoadingPopUp from "components/popUps/LoadingPopUp";
import { Modal, Spinner } from "react-bootstrap";
// import OopsSvg from '../../assets/img/newDesign/Question.svg';
import OopsSvg from '../../assets/img/Oops1.svg';

const BusinessTools = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [businessCases, setBusinessCases] = useState([])
  const user = useSelector((state) => state.user);
  const bigLoading = useSelector((state) => state.loading);
  const {business_cases, loading, category} = useSelector((state) => state?.business_cases);
  const { name } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const [showModal, setShowModal] = useState(false)
  useEffect(() => {
    if (!bigLoading) {
      // if (user?.enterprise) {
        if (!name) {
          if (category?.length) {
            navigate(`/business-tools/${category?.slice(0)?.shift()?.category}`)
          }
        } else {
          setBusinessCases(business_cases?.filter(item => item?.category === name))
        }
      // } else {
        // navigate(`/`)
      // }
    }
    
    
  }, [business_cases, category, location, user])
  const [fillColor, setFillColor] = useState("#E9E9E9");

  const handleClick = (i) => {
    if (fillColor === "#F8C133") {
      setFillColor("#E9E9E9");
    } else {
      setFillColor("#F8C133");
    }
  };

  const business = [
    {
      title: "Marketing Plan",
      titleAr: "خطة التسويق",
      content:
        lng === "arab"
          ? "قم بإنشاء خطة تسويقية مفصلة لعملك"
          : "Generate a detailed marketing plan for your business",
      icon: busi,
      background: "#E7E6FF",
    },
    {
      title: "Email Marketing",
      titleAr: "التسويق عبر البريد الإلكتروني",
      content:
        lng === "arab"
          ? " إنشاء كلمات رئيسية لتحسين محركات البحث بناءً على عملك وسوقك"
          : "Generate SEO keywords based on your business and market",
      icon: markt,
      background: "#E6FFE8",
    },
    {
      title: "Post Suggestions",
      titleAr: "اقتراحات المشاركة",
      content:
        lng === "arab"
          ? "اقتراحات النشر على وسائل التواصل الاجتماعي "
          : "Social media post suggestions",
      icon: post,
      background: "#E6FAFF",
    },
    {
      title: "Users Persona",
      titleAr: "المستخدمين بيرسونا",
      content:
        lng === "arab"
          ? " إنشاء كلمات رئيسية لتحسين محركات البحث بناءً على عملك وسوقك"
          : "Generate SEO keywords based on your business and market",
      icon: user,
      background: "#E6FFE8",
    },
    {
      title: "Script Writing and story telling",
      titleAr: "كتابة السيناريو ورواية القصة",
      content:
        lng === "arab"
          ? "اقتراحات النشر على وسائل التواصل الاجتماعي"
          : "Social media post suggestions",
      icon: script,
      background: "#FFE6EC",
    },
    {
      title: "SEO Keywords",
      titleAr: "الكلمات الرئيسية لتحسين محركات البحث",
      content:
        lng === "arab"
          ? " إنشاء كلمات رئيسية لتحسين محركات البحث بناءً على عملك وسوقك"
          : "Generate SEO keywords based on your business and market",
      icon: seo,
      background: "#F2E6FF",
    },
    {
      title: "Marketing Strategies",
      titleAr: "استراتيجيات التسويق",
      content:
        lng === "arab"
          ? " إنشاء كلمات رئيسية لتحسين محركات البحث بناءً على عملك وسوقك"
          : "Generate SEO keywords based on your business and market",
      icon: strat,
      background: "#FFF8E6",
    },
    {
      title: "Product Development",
      titleAr: "تطوير المنتج",
      content:
        lng === "arab"
          ? " إنشاء كلمات رئيسية لتحسين محركات البحث بناءً على عملك وسوقك"
          : "Generate SEO keywords based on your business and market",
      icon: product,
      background: "#EFFFE6",
    },
    {
      title: "Linkedin Ads",
      titleAr: "إعلانات لينكد إن",
      content:
        lng === "arab"
          ? " إنشاء كلمات رئيسية لتحسين محركات البحث بناءً على عملك وسوقك"
          : "Generate SEO keywords based on your business and market",
      icon: link,
      background: "#D4F2FF",
    },
    {
      title: "Google Ads",
      titleAr: "إعلانات جوجل",
      content:
        lng === "arab"
          ? " إنشاء كلمات رئيسية لتحسين محركات البحث بناءً على عملك وسوقك"
          : "Generate SEO keywords based on your business and market",
      icon: google,
      background: "#E5F3FF",
    },
    {
      title: "Meta Ads",
      titleAr: "إعلانات ميتا",
      content:
        lng === "arab"
          ? " إنشاء كلمات رئيسية لتحسين محركات البحث بناءً على عملك وسوقك"
          : "Generate SEO keywords based on your business and market",
      icon: meta,
      background: "#E5FAFF",
    },
    {
      title: "Snapchat Ads",
      titleAr: "إعلانات سناب شات",
      content:
        lng === "arab"
          ? "اقتراحات النشر على وسائل التواصل الاجتماعي"
          : "Social media post suggestions",
      icon: snap,
      background: "#FFFBE6",
    },
    {
      title: "Tiktok Ads",
      titleAr: "إعلانات تيكتوك",
      content:
        lng === "arab"
          ? "اقتراحات النشر على وسائل التواصل الاجتماعي"
          : "Social media post suggestions",
      icon: tiktok,
      background: "#FCDAE0",
    },
  ];

  return (
    <div style={{ position: "relative", overflow: "hidden", backgroundColor: '#fff' }} className="business_tools">
      {/* {loading? 
        <LoadingPopUp gptLoading={true} opacity={true}/> :
        null
      } */}
      <div className="container">
        <div className="business_title">
          <h4
            style={{
              fontFamily: lng === "arab" ? "Tajawal" : "Kanit, sans-serif",
            }}
          >
            {t("Ai-Powered Business Tools")}
            <span>⚡️</span>
          </h4>
          <p
            style={{
              fontFamily: lng === "arab" ? "Tajawal" : "Kanit, sans-serif",
            }}
          >
            {t("Begin selecting the tool from the options below")}
          </p>
        </div>
        <>
          <div className="Nav_Business_tools">
            <BusinessFilter loading={loading} category={category} />
          </div>
          <div className="templete_business">
            <div style={{minHeight: '60px'}} className="template_child">
              {/* <p className="badge_mains" style={{fontFamily: lng === "arab" ? "Tajawal" : "Kanit, sans-serif"}}>
              {t("Book Markes")}{" "}
              <TurnedInIcon style={{ fill: "#ffff", stroke: "none", fontSize: "17px" }}/>
            </p> */}
            </div>
            <div className="busines_mains row">
              {loading ? (
                <div style={{ minHeight: "300px", width: "100%" }} className="all_images_wrapper row">
                  <Spinner animation="border" role="status" />
                </div>
              ) : businessCases?.length === 0 ? (
                <div  className="col-lg-4 col-md-6 col-sm-12">
                  <Link style={{ flexDirection: "column", minHeight: "141px" }} to={`/business-tools/presentation/1`} className="tem_busi minHeightDiff">
                    <div style={{ width: "100%", display: "flex", alignItems: "center", gap: "15px", flexGrow: "1" }}>
                      <div className="busines_icon">
                        <img src={presen} alt="image" width="100%" />
                      </div>
                      <div style={{ flexGrow: "1" }} className="template_card">
                        <div className="business_deatils">
                          <div style={{ flexGrow: "1", display: "flex", flexDirection: "column", gap: "5px" }}>
                            <h6 style={{ textAlign: lng === "arab" ? "right" : "left" }}>{t("presentation")}</h6>
                            <p style={{ textAlign: lng === "arab" ? "right" : "left" }}>{t("Create presentation using Twwt ai")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ) : businessCases?.length !== 0 ? (
                businessCases?.map((item, i) => {
                  return (
                    <div key={i} className="col-lg-4 col-md-6 col-sm-12">
                      <Link style={{ flexDirection: "column", minHeight: "141px" }} to={`/business-tools/${item?.category}/${item?.id}`} className="tem_busi minHeightDiff">
                        <div style={{ width: "100%", display: "flex", alignItems: "center", gap: "15px", flexGrow: "1" }}>
                          <div className="busines_icon" style={{ backgroundColor: item.background }}>
                            <img src={item.element_icon} alt="image" width="100%" />
                          </div>
                          <div style={{ flexGrow: "1" }} className="template_card">
                            <div className="business_deatils">
                              <div style={{ flexGrow: "1", display: "flex", flexDirection: "column", gap: "5px" }}>
                                <h6 style={{ textAlign: lng === "arab" ? "right" : "left" }}>{lng === "arab" ? item.arabic_name : item.name}</h6>
                                <p style={{ textAlign: lng === "arab" ? "right" : "left" }}>{lng === "arab" ? item?.arabic_description : item.description}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })
              ) : (
                <div style={{ minHeight: "300px", width: "100%" }} className="all_images_wrapper row">
                  <label>{t("No Tools Found")}</label>
                </div>
              )}
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default BusinessTools;

