import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../WriteDocument.css";

const ToolTip1 = () => {
  const [show, setShow] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (localStorage.getItem("tooltip1") === "true") {
      setShow(false);
    } else {
      setShow(true);
    }
  }, []);
  return (
    <>
      {show && (
        <div className="tooltip1_container">
          <span style={{ alignSelf: "flex-start", position: "relative" }}>
            <div className="trian"></div>
            <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="Text">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M19.5938 33.1055C20.42 33.1055 21.0898 32.4356 21.0898 31.6094C21.0898 30.7831 20.42 30.1133 19.5938 30.1133H18.7656V3.88672H27.0625V6.375C27.0625 7.20125 27.7323 7.87109 28.5586 7.87109C29.3848 7.87109 30.0547 7.20125 30.0547 6.375V2.39062C30.0547 1.56437 29.3848 0.894531 28.5586 0.894531H1.99609C1.16984 0.894531 0.5 1.56437 0.5 2.39062V6.375C0.5 7.20125 1.16984 7.87109 1.99609 7.87109C2.82235 7.87109 3.49219 7.20125 3.49219 6.375V3.88672H11.7891V30.1133H10.9609C10.1347 30.1133 9.46484 30.7831 9.46484 31.6094C9.46484 32.4356 10.1347 33.1055 10.9609 33.1055H19.5938Z"
                    fill="url(#paint0_linear_203_341)"
                    stroke="url(#paint1_linear_203_341)"
                  />
                  <path
                    id="Vector_2"
                    d="M34.0039 17.332H22.0508C21.5007 17.332 21.0547 17.778 21.0547 18.3281V20.3203C21.0547 20.8704 21.5007 21.3164 22.0508 21.3164C22.6009 21.3164 23.0469 20.8704 23.0469 20.3203V19.3242H27.0312V30.6133H26.0352C25.485 30.6133 25.0391 31.0593 25.0391 31.6094C25.0391 32.1595 25.485 32.6055 26.0352 32.6055H30.0195C30.5696 32.6055 31.0156 32.1595 31.0156 31.6094C31.0156 31.0593 30.5696 30.6133 30.0195 30.6133H29.0234V19.3242H33.0078V20.3203C33.0078 20.8704 33.4538 21.3164 34.0039 21.3164C34.554 21.3164 35 20.8704 35 20.3203V18.3281C35 17.778 34.554 17.332 34.0039 17.332Z"
                    fill="url(#paint2_linear_203_341)"
                  />
                </g>
              </g>
              <defs>
                <linearGradient id="paint0_linear_203_341" x1="15.2773" y1="32.6055" x2="15.2773" y2="1.39453" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#3A42FF" />
                  <stop offset="1" stop-color="#81C3FF" />
                </linearGradient>
                <linearGradient id="paint1_linear_203_341" x1="15.2773" y1="1.39453" x2="15.2773" y2="32.6055" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#7FBFFF" />
                  <stop offset="1" stop-color="#3C46FF" />
                </linearGradient>
                <linearGradient id="paint2_linear_203_341" x1="28.0273" y1="32.6055" x2="28.0273" y2="17.332" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#BFBEFF" />
                  <stop offset="1" stop-color="#F1F2FF" />
                </linearGradient>
              </defs>
            </svg>
          </span>
          <h5 style={{ color: "#1D25C6", padding: "0 10px", marginBottom: "0px", fontSize: "0.9rem" }}>{t("Click “Continue Writing” to let Ai write for you")}</h5>
          <button
            onClick={() => {
              localStorage.setItem("tooltip1", "true");
              setShow(false);
            }}
            className="tooltip_button"
          >
            {t("Done")}
          </button>
        </div>
      )}
    </>
  );
};

export default ToolTip1;
