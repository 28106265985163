import React, { useState, useEffect, useRef } from 'react'
import "react-phone-number-input/style.css";
import gif1 from '../../gifs/RobotA1-540.gif'
import gif2 from '../../gifs/RobotA2-540.gif'
import gif3 from '../../gifs/RobotB-540.gif'

const LoadingPopUp = (props) => {
    const [randomCounter, setRandomCounter] = useState(Math.floor(Math.random() * 3) + 1);
  return (          
    <div
      className='big_loading_modal'
      style={{backgroundColor: props.gptLoading? props.opacity? '#fff': 'rgb(255 255 255 / 51%)':null, position: props.gptLoading? 'absolute':null}}
    >
      <div className='forceArabicFont row' style={{ direction: 'rtl', display: 'flex', padding: '5px' }}>
        <div className='formWrapper widthMedia form-group bigLoadingWrapper  col-md-12 col-sm-12 col-lg-12'>
        {randomCounter === 1? 
            <img src={gif1} alt='loading' className='loading_gif'/>:
            randomCounter === 2?
            <img src={gif2} alt='loading' className='loading_gif'/>:
            <img src={gif3} alt='loading' className='loading_gif'/>
        }
        </div>
      </div>
    </div>
  )
}

export default LoadingPopUp