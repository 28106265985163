import { toastAlert } from "components/toastAlert/toastAlert";
import { FETCH_OLD_IMAGES } from "redux/contstants";
import { LOAD_OLD_IMAGES } from "redux/contstants";
import { OLD_IMAGES_PAGINATION } from "redux/contstants";
import { OLD_IMAGES_LOADED } from "redux/contstants";

import * as api from "../../api";
import checkErr from "./api_error_func";

export const getAllOldImages = (userText) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_OLD_IMAGES });
    var { data } = await api.getOldImages();
    await dispatch({ type: FETCH_OLD_IMAGES, data: data?.history });
    await dispatch({ type: OLD_IMAGES_PAGINATION, data: data });
  } catch (error) {
    dispatch({ type: OLD_IMAGES_LOADED });
    checkErr(error, false, toastAlert)
  }
};

