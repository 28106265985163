import { LOAD_REDESIGN, FETCH_REDESIGN, REDESIGN_LOADED, NEW_REDESIGN, UPLOADED_IMAGINE, LOAD_PERCENTAGE_REDESIGN } from "redux/contstants";

export default (redesign = { redesign: [], firstImage: [], loadingNumber: 0, uploaded: null, loading: false }, action) => {
  switch (action.type) {
    case LOAD_REDESIGN:
      return { ...redesign, firstImage: action.data, loadingNumber: 0, loading: true, showRemover: true };
    case FETCH_REDESIGN:
      return { ...redesign, redesign: action.data, loadingNumber: 0 };
    case REDESIGN_LOADED:
      return { ...redesign, loading: false };
    case NEW_REDESIGN:
      return { ...redesign, redesign: [], loadingNumber: 0, showRemover: false };
    case UPLOADED_IMAGINE:
      return { ...redesign, uploaded: action.data };
    case LOAD_PERCENTAGE_REDESIGN:
      return { ...redesign, loadingNumber: action.data };
    default:
      return redesign;
  }
};
