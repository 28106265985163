import React, { useState, useEffect } from "react";
import { toastAlert } from "components/toastAlert/toastAlert";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { handleEnhance } from "redux/actions/media_section";
import UpgradeModal from "components/UpgradeModal/UpgradeModal";
import UpgradePlanModal from "components/UpgradeModal/UpgradePlanModal";
import enhanceImage from "assets/img/images/enhance/enhance_image.png";
import Loading_v2 from "components/Loading/Laoadingv2/Loadingv2";
import ReplayIcon from "@mui/icons-material/Replay";
import "font-awesome/css/font-awesome.min.css";
import "views/MediaSection/ImageTools/Create/ImgGen.css";
import { handleEnhanceImageLoading } from "redux/actions/media_section";
import FileContainer from "components/MediaSection/FileContainer";

import { handleLoadingEnhance } from "redux/actions/media_section";
import LimitPopUp from "views/plansComponent/LimitPopUp";
const EnhanceImage = () => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const enhance = useSelector((state) => state.enhance);
  const [dragging, setDragging] = useState(false);
  const [base64, setBase64] = useState("");
  const [totalImages, setTotalImages] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loadingText, setLoadingText] = useState();
  const [loadingNumber, setLoadingNumber] = useState(0);

  useEffect(() => {
    setTotalImages(user?.image_count_left);
  }, [user]);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  let error_msg =
    lng === "arab"
      ? "حجم الصورة لا يجب أن يزيد عن 5 ميجابايت"
      : "Image size should be less than 5 MB";
  let error_valid =
    lng === "arab"
      ? "يُسمح فقط بملفات JPG و JPEG و PNG"
      : "Only JPG, JPEG, and PNG files are allowed.";

  const handleBackground = async () => {
    if (totalImages === 0) {
      dispatch({ type: "SHOW_ENHANCE_FALSE" });
      return setShowModal(true);
    } else {
      dispatch(handleEnhance({ image: base64 }));
    }
  };

  useEffect(() => {
    if (base64) {
      handleBackground();
    }
  }, [base64]);

  const handleDrop = async (e) => {
    e.preventDefault();
    if (totalImages === 0) {
      dispatch({ type: "SHOW_ENHANCE_FALSE" });
      return setShowModal(true);
    } else {
      const file = await e.dataTransfer.files[0];
      if (file) {
        const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
        if (allowedTypes.includes(file.type)) {
          if (file.size > 5242880) {
            return toastAlert(error_msg, undefined, "warning");
          } else {
            handleFile(file);
            dispatch({ type: "SHOW_ENHANCE_TRUE" });
            setDragging(false);
          }
        } else {
          toastAlert(error_valid, undefined, "warning");
        }
      }
    }
  };

  const handleImageUpload = async (e) => {
    e.preventDefault();
    if (totalImages === 0) {
      dispatch({ type: "SHOW_ENHANCE_FALSE" });
      return setShowModal(true);
    } else {
      const file = await e.target.files;
      if (file) {
        const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
        if (allowedTypes.includes(file[0].type)) {
          if (file[0].size > 5242880) {
            return toastAlert(error_msg, undefined, "warning");
          } else {
            handleFile(file[0]);
            dispatch({ type: "SHOW_ENHANCE_TRUE" });
            setDragging(false);
          }
        } else {
          toastAlert(error_valid, undefined, "warning");
        }
      }
    }
  };

  const handleFile = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setBase64(reader.result.toString());
    };
  };

  const handleDownload = () => {
    const fileUrl = enhance?.enhance?.urls;
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = "bg_remover twwt.ai";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  useEffect(() => {
    if (enhance?.loading) {
      let intervalTime;
      if (enhance?.loadingNumber >= 1 && enhance?.loadingNumber < 25) {
        intervalTime = 3000 / 25; // 5 seconds for 1-25, increment by 1 every 200ms
        setLoadingText("Uploading Your Image");
      } else if (enhance?.loadingNumber >= 25 && enhance?.loadingNumber < 50) {
        intervalTime = 5000 / 25; // 20 seconds for 25-50, increment by 1 every 800ms
        setLoadingText("Analyzing Your Image");
      } else if (enhance?.loadingNumber >= 50 && enhance?.loadingNumber < 75) {
        intervalTime = 50000 / 25; // 30 seconds for 50-75, increment by 1 every 1200ms
        setLoadingText("Enhancing the Image");
      } else if (enhance?.loadingNumber >= 75 && enhance?.loadingNumber < 100) {
        intervalTime = 80000 / 25; // 10 seconds for 50-75, increment by 1 every 1200ms
        setLoadingText("Downloading the Image");
      }
      const timer = setInterval(() => {
        dispatch(handleEnhanceImageLoading(enhance?.loadingNumber, "enhance"));
      }, intervalTime);
      return () => {
        clearInterval(timer);
      };
    }
  }, [enhance?.loadingNumber, enhance?.loading]);

  return (
    <>
      {enhance?.loading ? (
        <div
          style={{
            height: "calc(100vh - 200px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading_v2
            loadingText={loadingText}
            setLoadingText={setLoadingText}
            loadingNumber={enhance?.loadingNumber}
          />
        </div>
      ) : enhance?.enhance?.length != 0 ? (
        <div style={{ position: "relative" }}>
          {enhance?.enhance?.length != 0 && (
            <>
              <div
                style={{
                  maxWidth: "600px",
                  minHeight: "450px",
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={enhance?.enhance?.urls}
                  className="bg_remover_background"
                />
              </div>
              <div className="beforeAfter-container">
                <div className="beforeafter-gray">
                  <button className="download-btn" onClick={handleDownload}>
                    <i
                      className="fas fa-arrow-down"
                      style={{
                        fontSize: "medium",
                        margin: "0 10px",
                      }}
                    ></i>
                    {t("Download")}
                  </button>
                  <button
                    className="newupload"
                    onClick={() => {
                      dispatch({ type: "NEW_ENHANCE" });
                    }}
                  >
                    <ReplayIcon
                      sx={{
                        transform: "scale(-1)",
                        margin: "0 10px",
                        fontSize: "20px",
                      }}
                    />
                    {t("Try Again")}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          <div className="image_gen_container">
            <div
              className="image_gen_header"
              style={{
                padding: width < 500 ? "10px" : "20px",
                alignSelf: "flex-start",
              }}
            >
              <h4 style={{ textAlign: lng === "arab" ? "right" : "left" }}>
                {t(
                  "AI Image enhancer, Upload your image and make it HD in one click Instantly"
                )}
              </h4>
              <p style={{ textAlign: lng === "arab" ? "right" : "left" }}>
                {t(
                  "twwt.Ai’s Image enhancer helps you enhance, sharpen and unblur your media files instantly."
                )}
              </p>
              {!enhance.showRemover && (
                <FileContainer
                  handleDrop={handleDrop}
                  handleDragOver={handleDragOver}
                  handleDragLeave={handleDragLeave}
                  handleUpload={handleImageUpload}
                  dragging={dragging}
                  types=".jpg,.jpeg,.png"
                  support="JPG and PNG"
                />
              )}
              {user?.plan === "Trial" ? (
                <UpgradeModal
                  images={true}
                  setShowModal={setShowModal}
                  showModal={showModal}
                />
              ) : (
                <LimitPopUp setShowModal={setShowModal} showModal={showModal} />
              )}

              <UpgradePlanModal show={show} setShow={setShow} width={width} />
            </div>
            <div className="right_image_media">
              <img
                src={enhanceImage}
                alt="upscale-example"
                width={"90%"}
                height={"90%"}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EnhanceImage;
