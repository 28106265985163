
import {
    FETCH_REIMAGINE_V2,
    FETCH_INITIAL_IMAGE,
    LOAD_REIMAGINE_V2,
    LOADED_REIMAGINE_V2,
    NEW_REIMAGINE_V2,
    SHOW_REIMAGINE_V2_TRUE,
    SHOW_REIMAGINE_V2_FALSE
} from "redux/contstants";

export default (reimagineV2 = { reimagineV2: [], initialImage: [], uploaded: [], loading: false, showRemover: false }, action) => {
    switch (action.type) {
        case LOAD_REIMAGINE_V2:
            return { ...reimagineV2, loading: true, showRemover: true, initialImage: action.data };
        case FETCH_REIMAGINE_V2:
            // console.log(reimagineV2, "redux")
            return { ...reimagineV2, reimagineV2: action.data, loading: false };
        case LOADED_REIMAGINE_V2:
            // console.log(reimagineV2, "reduxend")
            return { ...reimagineV2, loading: false };
        case NEW_REIMAGINE_V2:
            return { ...reimagineV2, reimagineV2: [], initialImage: [], uploaded: [], showRemover: false };
        case SHOW_REIMAGINE_V2_FALSE:
            return { ...reimagineV2, showRemover: false };
        case SHOW_REIMAGINE_V2_TRUE:
            return { ...reimagineV2, showRemover: true };
        default:
            return reimagineV2;
    }
};
