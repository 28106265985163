
export default (newChatHolder = {chatsObj: {}, status: '', loading: false}, action) => {
    switch (action.type) {
        case "CREATE_CHAT":
            return { 
            ...newChatHolder, 
            chatsObj: action.data,
            status: 'pending',
            loading: true
        };
        case "DONE_FETCHING":
            return {chatsObj: {}, status: '', loading: false}
        default:
            return newChatHolder;
    }
}   