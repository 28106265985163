import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './Business.scss'
import arrowForCases from '../../assets/img/newArrow.png'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, autoplay } from "swiper";
import "swiper/css/navigation";
import 'swiper/css';
const BusinessFilter = ({ loading, category }) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [routeName, setRoutName] = useState()
  const { name } = useParams();
  const [width, setWidth] = useState(window.innerWidth);
  const [view, setView] = useState(5);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    if (window.innerWidth > 3400) {
      //   setView(category?.length > 15? 15:category?.length);
      // } else if (window.innerWidth > 2530 && window.innerWidth <= 3400) {
      //   setView(category?.length > 15? 15:category?.length);
      // } else if (window.innerWidth > 1860 && window.innerWidth <= 2530) {
      //   setView(category?.length > 9? 9:category?.length);
      // } else if (window.innerWidth > 1400 && window.innerWidth <= 1860) {
      //   setView(category?.length > 5? 5:category?.length);
    } else if (window.innerWidth > 1111) {
      setView(category?.length > 4 ? 4 : category?.length);
    } else if (window.innerWidth > 900 && window.innerWidth <= 1111) {
      setView(category?.length > 3 ? 3 : category?.length);
    } else if (window.innerWidth > 700 && window.innerWidth <= 900) {
      setView(category?.length > 3 ? 3 : category?.length);
    } else if (window.innerWidth > 550 && window.innerWidth <= 700) {
      setView(category?.length > 2 ? 2 : category?.length);
    } else if (window.innerWidth > 400 && window.innerWidth <= 550) {
      setView(category?.length > 2 ? 2 : category?.length);
    } else if (window.innerWidth > 280 && window.innerWidth <= 400) {
      setView(category?.length > 2 ? 2 : category?.length);
    } else if (window.innerWidth > 210 && window.innerWidth <= 280) {
      setView(category?.length > 1 ? 1 : category?.length);
    } else {
      setView(category?.length > 1 ? 1 : category?.length);
    }
  }, [width, category]);
  return (
    <div style={{ margin: '0px', padding: '0px', backgroundColor: 'transparent', boxShadow: 'unset', position: 'relative' }} className="categories_header row ">
      {loading ? (
        <div style={{ minHeight: "50px" }} />
      ) : (
        <div className="business_filter_list">
          <Swiper
            spaceBetween={10}
            slidesPerView={view}
            scrollbar
            modules={[Navigation]}
            navigation={{
              prevEl: ".prev",
              nextEl: ".next",
            }}
            loop={true}
            style={{ width: '90%', direction: 'ltr' }}
          >
            {category?.map((item, i) => (
              <SwiperSlide key={i}>
                <Link style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }} key={i} to={`/business-tools/${item?.category}`} className={name === item?.category ? "filter_button active" : "filter_button"}>
                  <span style={{ fontSize: 18, textAlign: "center", fontFamily: lng === 'arab' ? 'Tajawal' : 'Poppins' }}>
                    {lng === 'arab' ? item?.arabic_category_name : item?.category_name}
                  </span>
                  <img src={item?.cat_icon} alt='cat_icon' style={{ height: '30px', width: '30px' }} />
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
          {/* <Link style={{width: "auto"}} to={`/business-tools/presentation`} className={name === "presentation"  ? "filter_button active" : "filter_button"}>
              <span style={{ fontSize: 18, textAlign: "center", fontFamily: lng === 'arab'? 'Tajawal':'Poppins' }}>
               {t("Presentation")}
              </span>
            </Link> */}
          <img className='next' src={arrowForCases} alt={'arrow for categories'} style={{ height: "20px", position: 'absolute', right: '0px', zIndex: '10' }} />
          <img className='prev' src={arrowForCases} alt={'arrow for categories'} style={{ height: "20px", transform: "rotate(180deg)", position: 'absolute', left: '0px', zIndex: '10' }} />
        </div>
      )}
    </div>
    // <div className={lng === 'arab' ? ` row business_filter_list_ar ` : ` row business_filter_list`}>
    //         <Link style={{width: "auto"}} style={{ width: "auto" }} to={`#`} className={name === "marketing"? "filter_button active" : "filter_button"}>
    //           <span style={{ fontSize: 18, textAlign: "center" }}>
    //             {t("Marketing")} 📣
    //           </span>
    //         </Link>

    //         <Link style={{width: "auto"}} style={{ width: "auto" }} to={`#`} className={name === "productivity" ? "filter_button active" : "filter_button"}>
    //           <span style={{ fontSize: 18, textAlign: "center" }}>
    //             {t("Productivity")}📝
    //           </span>
    //         </Link>

    //         <Link style={{width: "auto"}} style={{ width: "auto" }} to={`#`} className={name === "business" ? "filter_button active" : "filter_button"}>
    //           <span style={{ fontSize: 18, textAlign: "center" }}>
    //             {t("Business")} 💼
    //           </span>
    //         </Link>
    //         <Link style={{width: "auto"}} style={{ width: "auto" }} to={`#`} className={name === "finance" ? "filter_button active" : "filter_button"}>
    //           <span style={{ fontSize: 18, textAlign: "center" }}>
    //             {t("Finance")}  📈
    //           </span>
    //         </Link>
    //         <Link style={{width: "auto"}} style={{ width: "auto" }} to={`#`} className={name === "customerservice" ? "filter_button active" : "filter_button"}>
    //           <span style={{ fontSize: 18, textAlign: "center" }}>
    //             {t("Customer Service")}  🎧
    //           </span>
    //         </Link>
    //         <Link style={{width: "auto"}} style={{ width: "auto" }} to={`#`} className={name === "communication" ? "filter_button active" : "filter_button"}>
    //           <span style={{ fontSize: 18, textAlign: "center" }}>
    //             {t("Communication")} 🧑🏻‍💻
    //           </span>
    //         </Link>
    //         <Link style={{width: "auto"}} style={{ width: "auto" }} to={`#`} className={name === "education" ? "filter_button active" : "filter_button"}>
    //           <span style={{ fontSize: 18, textAlign: "center" }}>
    //             {t("Education")} 📚
    //           </span>
    //         </Link>
    //     </div>
  )
}

export default BusinessFilter