import React from "react";
import { Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const Skeletons = () => {
  return (
    <Row style={{ rowGap: "10px" }} className="justify-content-center">
      <Skeleton
        width={270}
        height={320}
        borderRadius={"5%"}
        style={{
          margin: "0 10px 0 10px",
          aspectRatio: "278 / 401",
        }}
      />
      <div style={{ width: "1em" }} />
      <Skeleton
        width={270}
        height={320}
        borderRadius={"5%"}
        style={{
          margin: "0 10px 0 10px",
          aspectRatio: "278 / 401",
        }}
      />
      <div style={{ width: "1em" }} />
      <Skeleton
        width={270}
        height={320}
        borderRadius={"5%"}
        style={{
          margin: "0 10px 0 10px",
          aspectRatio: "278 / 401",
        }}
      />
    </Row>
  );
};

export default Skeletons;
