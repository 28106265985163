import "assets/css/home.css";
import { useTranslation } from "react-i18next";
import "./MediaSection.scss";
import { useNavigate } from "react-router-dom";
import AudioTools from "assets/img/newDesign/imageSectionVideos/audioTools.png";
import ImageTools from "assets/img/newDesign/imageSectionVideos/imageTools.png";
import VideoTools from "assets/img/newDesign/videoTools.png";
import ImagesFilterComponent from "./ImagesFilterComponent";
import LimitPopUp from "views/plansComponent/LimitPopUp";
const MediaSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const features = [
    {
      name_en: "Ai Image Tools",
      name_ar: "أدوات الصور",
      desc_en: "Step into the world of visual enchantment with AI Image Tools",
      desc_ar: "اكتشف عالم آخر من تحسين الصور باستخدام الذكاء الاصطناعي",
      link: "images",
      image: ImageTools,
    },
    {
      name_en: "Ai Audio Tools",
      name_ar: "أدوات الصوت",
      desc_en: "Experience audio perfection at your fingertips with AI Audio Tools",
      desc_ar: "استغل سحر الذكاء الاصطناعي في الحصول على مقاطع صوتية ممتازة",
      link: "audios",
      image: AudioTools,
    },
    {
      name_en: "Ai Videos Tools",
      name_ar: "أدوات الفيديو",
      desc_en: "Dive into the world of visual storytelling with AI Video Tools",
      desc_ar:
        "دع الذكاء الاصطناعي يقوم بتحويل ما في خاطرك من كلمات الى فيديو مشوق",
      link: "videos",
      image: VideoTools,
    },
  ];
  return (
    <div
      style={{
        gap: "20px",
        padding: "0px",
        height: "calc(100vh - 110px)",
        position: "relative",
      }}
      className="page_wrapper"
    >
      <div
        style={{ padding: "0px" }}
        className="image_gen_outer_wraaper border-radius-top"
        id="image_gen_outer_wraaper"
      >
        <ImagesFilterComponent />
        <div
          style={{
            margin: null,
            maxWidth: null,
            padding: "40px 20px 15px 15px",
          }}
          className="write_body height_media_section"
        >
          <div className="media_section">
            {features?.length === 0 ? (
              <label>{t("No Items Found")}</label>
            ) : (
              features?.map((item, i) => (
                <div
                  key={i}
                  className="media_section_card"
                  onClick={() => navigate(item.link)}
                >
                  <img src={item.image} width={200} />
                  <div>
                    <h2>{lng === "arab" ? item.name_ar : item.name_en}</h2>
                    <h5>{lng === "arab" ? item.desc_ar : item.desc_en}</h5>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaSection;
