import React, { useEffect,useContext } from "react";
import style from "./claim.module.scss";
import { Modal } from "react-bootstrap";
import gift from "../../assets/img/loading-images/gift-popup.png";
import rightArrow from "../../assets/img/newDesign/right-arrow.png";
import checkErr from "redux/actions/api_error_func";
import { toastAlert } from "components/toastAlert/toastAlert";
import { useState } from "react";
import { baseUrl } from "api/baseUrl";
import { useSelector } from "react-redux";
import LoadingPopUp from "components/popUps/LoadingPopUp";
import CopyToClipboard from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { useDispatch } from "react-redux";
import { OfferTimerContext } from 'providers/OfferTimeProvider';


const ClaimFreeModal = (props) => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const bigLoading = useSelector((state) => state.loading);
  const offerTimerState = useContext(OfferTimerContext);
  const dispatch = useDispatch();

  const handleCopy = () =>{
    toastAlert(t("Copied"),undefined,"success")
  }

  useEffect(()=>{
    if(user?.user_prefernce?.time){
      if((!offerTimerState.isTimeLeft())){
        props.setShowModal(false);
      }
    }
  },[props.seconds])

  const handleClose = async () => {
    props.setShowModal(false)
    let response = await fetch(`${baseUrl}updateUserPreference`, {
      method: "POST",
      headers: {
        Authorization: localStorage.getItem("sess"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        flag:false
        }),
      })
      dispatch({ type: 'FETCH_USER' })
      .catch((error) => {
        checkErr(error, setLoading, toastAlert);
      });
  }

  return (
    <CopyToClipboard text="ARABY20" onCopy={handleCopy}>
    <Modal
      // style={{backgroundColor: "#00000080"}}
      show={props.showModal}
      backdrop={true}
      centered
      className="claimPOPUP"
      onHide={() => props.setShowModal(false)}
      size="medium"
      fullscreen={true}

      onExit={()=>{
        handleClose() 

      }}

    >
    <div
          onClick={() => {
            (props.setShowModal(false), navigate("/plan"))
            handleClose() }
          } 
        className={style.container}
        style={{
          cursor:"pointer",
          position: "relative",
          overflow: "hidden",
          direction: lng === "arab" ? "rtl" : "ltr",
          fontFamily: lng === "arab" ? "Tajawal" : null,
        }}
      >
        {loading ? (
          bigLoading ? null : (
            <LoadingPopUp gptLoading={true} />
          )
        ) : null}
        <div className={style.right}>
          <img src={gift} style={{ margin: "20px auto" }} />
          <h1>
            {t("Enjoy")}
            <span>20%</span>
            {t("OFF")}
          </h1>
          <h4>{t("Use Code")}</h4>
         
            <h2 style={{ cursor: "pointer" }}>{t("ARABY20")}</h2>
         
          <p>{t("For Limited Time")}</p>
          <div className={style.time}>
            <div>
              <h1>{offerTimerState.hours ? offerTimerState.hours.toString().length === 1 ? "0" + offerTimerState.hours : offerTimerState.hours : "00"}</h1>
              <h6>{t("hours")} </h6>
            </div>
            <div>
              <h1>
                {offerTimerState.minutes ? offerTimerState.minutes.toString().length === 1 ? "0" + offerTimerState.minutes : offerTimerState.minutes : "00"}
              </h1>
              <h6>{t("minutes")}</h6>
            </div>
            <div>
              <h1>
                {offerTimerState.seconds ? offerTimerState.seconds.toString().length === 1 ? "0" + offerTimerState.seconds : offerTimerState.seconds : "00"}
              </h1>
              <h6>{t("seconds")}</h6>
            </div>
          </div>
          <button
            className={style.claimBrn}
            disabled={loading}
           
          >
            {loading ? t("Loading..") : t("Claim Now")}
            <img
              src={rightArrow}
              style={{
                width: "20px",
                margin: lng === "arab" ? "0 10px 0 0" : "0 0 0 10px",
                transform: lng === "arab" ? "rotate(180deg)" : null,
              }}
            />
          </button>
        </div>
      </div>
      <button onClick={handleClose} className={style.close}>
        <i
          className="nc-icon nc-simple-remove"
          style={{ fontWeight: 800, color: "white" }}
        ></i>
      </button>

    </Modal>
    </CopyToClipboard>
  );
};

export default ClaimFreeModal;
