import React, { useState } from "react";
import { Quill } from "react-quill";
import { useTranslation } from "react-i18next";
import { ReactComponent as Ai } from "assets/img/airobo.svg";
import { ReactComponent as Light } from "assets/img/newDesign/Group.svg";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import AiTools from "./AiTools";
import { Tooltip } from "@mui/material";

var icons = Quill.import("ui/icons");
icons["undo"] = `  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_23_1639)">
      <path
        d="M2.76709 0.549056L1.98339 4.48917C1.91011 4.85852 2.14983 5.21754 2.51918 5.29105L6.45938 6.07484C6.82877 6.14831 7.18784 5.90844 7.26131 5.53905C7.27022 5.49414 7.27454 5.44935 7.27454 5.40526C7.27454 5.08656 7.05 4.80169 6.72553 4.73713L4.27729 4.25017C6.17397 3.21998 8.60652 3.50617 10.2048 5.10443C11.1795 6.0792 11.6668 7.35922 11.6667 8.63965C11.6666 9.91975 11.1793 11.2003 10.2048 12.1748C8.2555 14.1241 5.08371 14.1241 3.13443 12.1748C2.86815 11.9085 2.43634 11.9085 2.17002 12.1748C1.90374 12.4411 1.90374 12.8729 2.17002 13.1392C4.65109 15.6203 8.68808 15.6203 11.1691 13.1392C12.4098 11.8985 13.0301 10.2694 13.03 8.63965C13.0298 7.01033 12.4095 5.38043 11.1691 4.14001C9.97269 2.94356 8.38193 2.28166 6.68997 2.27625C5.6175 2.27279 4.58421 2.53366 3.66467 3.02753L4.10475 0.815106C4.17809 0.445627 3.93836 0.0866946 3.56892 0.0132264C3.19958 -0.0602418 2.8406 0.179667 2.76709 0.549056Z"
        fill="#9D9D9D"
      />
    </g>
    <defs>
      <clipPath id="clip0_23_1639">
        <rect width="15" height="15" fill="white" transform="translate(0 15) rotate(-90)" />
      </clipPath>
    </defs>
  </svg>`;
icons["redo"] = ` <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_23_1641)">
      <path
        d="M12.2329 0.549056L13.0166 4.48917C13.0899 4.85852 12.8502 5.21754 12.4808 5.29105L8.54062 6.07484C8.17123 6.14831 7.81216 5.90844 7.73869 5.53905C7.72978 5.49414 7.72546 5.44936 7.72546 5.40526C7.72546 5.08656 7.95 4.80169 8.27447 4.73713L10.7227 4.25017C8.82603 3.21998 6.39348 3.50617 4.79522 5.10443C3.82049 6.0792 3.33317 7.35922 3.33326 8.63965C3.33339 9.91975 3.82071 11.2003 4.79522 12.1748C6.7445 14.1241 9.91629 14.1241 11.8656 12.1748C12.1318 11.9085 12.5637 11.9085 12.83 12.1748C13.0963 12.4411 13.0963 12.8729 12.83 13.1392C10.3489 15.6203 6.31192 15.6203 3.83085 13.1392C2.59016 11.8985 1.96986 10.2694 1.97004 8.63965C1.97018 7.01033 2.59048 5.38043 3.83085 4.14001C5.02731 2.94356 6.61807 2.28166 8.31003 2.27625C9.3825 2.27279 10.4158 2.53366 11.3353 3.02753L10.8952 0.815106C10.8219 0.445627 11.0616 0.0866947 11.4311 0.0132265C11.8004 -0.0602417 12.1594 0.179667 12.2329 0.549056Z"
        fill="#9D9D9D"
      />
    </g>
    <defs>
      <clipPath id="clip0_23_1641">
        <rect width="15" height="15" fill="white" transform="matrix(0 -1 -1 0 15 15)" />
      </clipPath>
    </defs>
  </svg>`;
icons["bold"] = `
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.70483 15V0H6.856C8.71707 0 10.1698 0.345158 11.2138 1.03539C12.2576 1.72554 12.7797 2.75416 12.7797 4.1209C12.7797 4.83528 12.6116 5.47027 12.2749 6.02688C11.9384 6.58331 11.4199 7.00223 10.7192 7.28372C11.5983 7.48284 12.2473 7.88291 12.6664 8.48392C13.0851 9.08493 13.2948 9.77698 13.2948 10.5598C13.2948 12.0227 12.8037 13.1286 11.8218 13.877C10.8395 14.6258 9.43162 15 7.59766 15H1.70483ZM5.19735 6.25345H6.9281C7.7179 6.23973 8.30865 6.08691 8.7002 5.79492C9.09167 5.5031 9.28749 5.07558 9.28749 4.51236C9.28749 3.87356 9.0882 3.40842 8.69003 3.11643C8.29145 2.82461 7.68027 2.67857 6.85608 2.67857H5.19735V6.25345ZM5.19735 8.56123V12.3215H7.59766C8.3395 12.3215 8.89585 12.1755 9.26673 11.8835C9.63752 11.5917 9.82317 11.1436 9.82317 10.5392C9.82317 9.88671 9.66341 9.39395 9.34396 9.06078C9.02469 8.72778 8.50786 8.56123 7.79365 8.56123H5.19735Z" fill="#9D9D9D"/>
  </svg>`;
icons["italic"] = `<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M2.79492 15H9.82617C10.0689 15 10.2656 14.8032 10.2656 14.5605C10.2656 14.3179 10.0689 14.1211 9.82617 14.1211H8.34229C8.19864 14.1211 8.06895 14.0605 7.98647 13.9549C7.90922 13.8559 7.8842 13.7314 7.9161 13.6039L10.8257 1.87752C10.9727 1.28956 11.4986 0.878906 12.1047 0.878906H13.3418C13.5845 0.878906 13.7813 0.682148 13.7813 0.439453C13.7813 0.196758 13.5845 0 13.3418 0H6.31055C6.06785 0 5.87109 0.196758 5.87109 0.439453C5.87109 0.682148 6.06785 0.878906 6.31055 0.878906H7.79443C7.93069 0.878906 8.05693 0.940518 8.14081 1.04792C8.22466 1.15532 8.25381 1.29272 8.22062 1.42544L5.31103 13.1518C5.16609 13.7316 4.65211 14.1211 4.03204 14.1211H2.79492C2.55223 14.1211 2.35547 14.3179 2.35547 14.5605C2.35547 14.8032 2.55223 15 2.79492 15Z"
    fill="#9D9D9D"
  />
</svg>`;
icons["underline"] = ` <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.63672 11.6667C10.3992 11.6667 12.6367 9.42918 12.6367 6.66668V0H10.5534V6.66668C10.5534 8.27918 9.24922 9.58336 7.63672 9.58336C6.02422 9.58336 4.72004 8.27918 4.72004 6.66668V0H2.63672V6.66668C2.63672 9.42918 4.87422 11.6667 7.63672 11.6667Z" fill="#9D9D9D"/>
<path d="M13.4701 13.3333H1.80347V15H13.4701V13.3333Z" fill="#9D9D9D"/>
</svg>`;
icons["strike"] = `<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_23_1595)">
      <path
        d="M12.75 6.75H7.50005C5.8455 6.75 4.50009 5.57254 4.50009 4.12495C4.50009 2.67751 5.8455 1.50005 7.50005 1.50005C9.15459 1.50005 10.5 2.67751 10.5 4.12495C10.5 4.53969 10.836 4.87505 11.2501 4.87505C11.664 4.87505 12 4.53969 12 4.12495C12 1.85106 9.98186 0 7.50005 0C5.01823 0 3.00005 1.85106 3.00005 4.12495C3.00005 5.121 3.38786 6.03603 4.03125 6.75H2.25009C1.83604 6.75 1.5 7.08522 1.5 7.49995C1.5 7.91469 1.83604 8.25004 2.25009 8.25004H7.50005C9.15459 8.25004 10.5 9.4275 10.5 10.875C10.5 12.3225 9.15459 13.5 7.50005 13.5C5.8455 13.5 4.50009 12.3225 4.50009 10.875C4.50009 10.4602 4.16405 10.125 3.75 10.125C3.33609 10.125 3.00005 10.4602 3.00005 10.875C3.00005 13.149 5.01823 15 7.50005 15C9.98186 15 12 13.149 12 10.875C12 9.87904 11.6122 8.96402 10.9688 8.25004H12.75C13.164 8.25004 13.5001 7.91469 13.5001 7.49995C13.5001 7.08522 13.164 6.75 12.75 6.75Z"
        fill="#9D9D9D"
      />
    </g>
    <defs>
      <clipPath id="clip0_23_1595">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
</svg>`;
icons.header[1] = `<svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.74631 14.9391V14.0604H10.0229C10.2612 14.0604 10.4836 14.0431 10.6917 14.0101C10.9007 13.9756 11.0809 13.9051 11.2338 13.8001C11.3868 13.6951 11.51 13.5427 11.6025 13.3456C11.6945 13.1481 11.7401 12.8859 11.7401 12.558V7.65436H4.93423V12.558C4.93423 12.8854 4.97977 13.1472 5.07181 13.3461C5.16386 13.5427 5.28658 13.6956 5.43998 13.8001C5.59339 13.906 5.77507 13.9751 5.98648 14.0101C6.19837 14.0436 6.41889 14.0609 6.65091 14.0609H6.92752V14.9396H0.836914V14.0609H1.10297C1.34123 14.0609 1.56366 14.0436 1.7722 14.0101C1.98025 13.976 2.16194 13.906 2.31918 13.8001C2.47546 13.6942 2.59818 13.5427 2.68782 13.3461C2.77603 13.1486 2.81966 12.8854 2.81966 12.558V2.29914C2.81966 1.99281 2.77363 1.7464 2.68207 1.55849C2.58907 1.37057 2.46443 1.22627 2.30815 1.12416C2.15139 1.02205 1.9697 0.955417 1.76069 0.924736C1.55408 0.893576 1.33404 0.878715 1.10249 0.878715H0.836914V0H6.92752V0.878715H6.65091C6.41937 0.878715 6.19885 0.895494 5.98648 0.93001C5.77555 0.963567 5.59387 1.03404 5.43998 1.1395C5.28706 1.24497 5.16433 1.39645 5.07181 1.59444C4.97977 1.79147 4.93423 2.05417 4.93423 2.38111V6.61074H11.7391V2.38111C11.7391 2.05369 11.6931 1.79147 11.6015 1.59396C11.51 1.39645 11.3868 1.24449 11.2334 1.1395C11.08 1.03404 10.8997 0.964046 10.6912 0.93001C10.4826 0.895494 10.2612 0.878715 10.022 0.878715H9.74535V0H15.836V0.878715H15.5709C15.3321 0.878715 15.1102 0.895494 14.9016 0.93001C14.6936 0.963567 14.5124 1.03404 14.3547 1.1395C14.1984 1.24497 14.0756 1.39645 13.9865 1.59396C13.8973 1.79099 13.8537 2.05369 13.8537 2.38111V12.6601C13.8537 12.9669 13.9002 13.2138 13.9913 13.4008C14.0833 13.5887 14.2084 13.7301 14.3647 13.8245C14.521 13.9204 14.7027 13.9832 14.9117 14.0144C15.1198 14.0451 15.3393 14.0604 15.5699 14.0604H15.8364V14.9391H9.74631Z" fill="#9D9D9D"/>
  <path d="M20.7197 14.9698H19.0732V8.76477C18.4717 9.32727 17.7627 9.74329 16.9463 10.0128V8.51868C17.376 8.37805 17.8428 8.11243 18.3467 7.7218C18.8506 7.32727 19.1963 6.86829 19.3838 6.34485H20.7197V14.9698Z" fill="#9D9D9D"/>
</svg>`;
icons.header[2] = ` <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.19246 12.4677V11.7422H8.42083C8.61753 11.7422 8.80117 11.728 8.97294 11.7007C9.1455 11.6722 9.29431 11.614 9.42057 11.5273C9.54682 11.4406 9.64854 11.3148 9.72492 11.1521C9.80091 10.9891 9.83851 10.7726 9.83851 10.5019V6.45344H4.21964V10.5019C4.21964 10.7722 4.25724 10.9883 4.33322 11.1525C4.40921 11.3148 4.51053 11.441 4.63718 11.5273C4.76383 11.6148 4.91383 11.6718 5.08837 11.7007C5.26331 11.7284 5.44536 11.7426 5.63692 11.7426H5.86528V12.4681H0.836914V11.7426H1.05657C1.25327 11.7426 1.43691 11.7284 1.60908 11.7007C1.78085 11.6726 1.93085 11.6148 2.06066 11.5273C2.18969 11.4399 2.29101 11.3148 2.36502 11.1525C2.43784 10.9895 2.47386 10.7722 2.47386 10.5019V2.03219C2.47386 1.77929 2.43586 1.57586 2.36027 1.42071C2.28349 1.26557 2.18058 1.14644 2.05156 1.06213C1.92214 0.977834 1.77214 0.922821 1.59958 0.897491C1.429 0.871765 1.24734 0.859496 1.05618 0.859496H0.836914V0.134033H5.86528V0.859496H5.63692C5.44576 0.859496 5.2637 0.873348 5.08837 0.901844C4.91423 0.929549 4.76423 0.987728 4.63718 1.0748C4.51093 1.16187 4.40961 1.28694 4.33322 1.45039C4.25724 1.61306 4.21964 1.82995 4.21964 2.09987V5.59182H9.83772V2.09987C9.83772 1.82955 9.79972 1.61306 9.72413 1.45C9.64854 1.28694 9.54682 1.16148 9.42017 1.0748C9.29352 0.987728 9.14471 0.929945 8.97254 0.901844C8.80038 0.873348 8.61753 0.859496 8.42004 0.859496H8.19167V0.134033H13.22V0.859496H13.0012C12.8041 0.859496 12.6208 0.873348 12.4487 0.901844C12.2769 0.929549 12.1273 0.987728 11.9971 1.0748C11.8681 1.16187 11.7667 1.28694 11.6931 1.45C11.6195 1.61266 11.5835 1.82955 11.5835 2.09987V10.5862C11.5835 10.8395 11.6219 11.0433 11.6971 11.1976C11.7731 11.3528 11.8764 11.4695 12.0054 11.5475C12.1344 11.6267 12.2844 11.6785 12.457 11.7042C12.6288 11.7296 12.81 11.7422 13.0004 11.7422H13.2204V12.4677H8.19246Z" fill="#9D9D9D"/>
<path d="M19.841 10.8822V12.4115H14.0695C14.132 11.8334 14.3195 11.2865 14.632 10.7709C14.9445 10.2514 15.5617 9.56387 16.4836 8.7084C17.2258 8.017 17.6808 7.54825 17.8488 7.30215C18.0754 6.96231 18.1887 6.62637 18.1887 6.29434C18.1887 5.92715 18.089 5.6459 17.8898 5.45059C17.6945 5.25137 17.423 5.15176 17.0754 5.15176C16.7316 5.15176 16.4582 5.25528 16.2551 5.46231C16.0519 5.66934 15.9347 6.01309 15.9035 6.49356L14.2629 6.3295C14.3605 5.42325 14.6672 4.77286 15.1828 4.37833C15.6984 3.9838 16.343 3.78653 17.1164 3.78653C17.964 3.78653 18.6301 4.01505 19.1144 4.47208C19.5988 4.92911 19.841 5.49747 19.841 6.17715C19.841 6.56387 19.7707 6.93301 19.6301 7.28458C19.4933 7.63223 19.2746 7.99747 18.9738 8.38028C18.7746 8.63419 18.4152 8.99942 17.8957 9.47598C17.3762 9.95255 17.0461 10.269 16.9055 10.4252C16.7687 10.5815 16.6574 10.7338 16.5715 10.8822H19.841Z" fill="#9D9D9D"/>
</svg>
`;
icons.header[3] = `<svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.38252 11.7432V11.0976H7.58574C7.76078 11.0976 7.9242 11.085 8.07705 11.0607C8.23061 11.0353 8.36304 10.9835 8.47539 10.9064C8.58774 10.8293 8.67826 10.7173 8.74623 10.5725C8.81385 10.4274 8.84731 10.2348 8.84731 9.99386V6.39122H3.84715V9.99386C3.84715 10.2344 3.88061 10.4267 3.94823 10.5729C4.01586 10.7173 4.10602 10.8296 4.21872 10.9064C4.33143 10.9842 4.46491 11.035 4.62023 11.0607C4.7759 11.0853 4.93791 11.098 5.10838 11.098H5.31159V11.7436H0.836914V11.098H1.03238C1.20743 11.098 1.37085 11.0853 1.52405 11.0607C1.67691 11.0357 1.81039 10.9842 1.92591 10.9064C2.04073 10.8286 2.13089 10.7173 2.19675 10.5729C2.26156 10.4278 2.29361 10.2344 2.29361 9.99386V2.45681C2.29361 2.23176 2.2598 2.05073 2.19253 1.91267C2.1242 1.7746 2.03263 1.66859 1.91781 1.59357C1.80264 1.51856 1.66916 1.4696 1.5156 1.44706C1.3638 1.42417 1.20214 1.41325 1.03203 1.41325H0.836914V0.76767H5.31159V1.41325H5.10838C4.93826 1.41325 4.77625 1.42558 4.62023 1.45093C4.46526 1.47559 4.33178 1.52736 4.21872 1.60485C4.10637 1.68233 4.01621 1.79362 3.94823 1.93908C3.88061 2.08383 3.84715 2.27684 3.84715 2.51704V5.62448H8.84661V2.51704C8.84661 2.27649 8.8128 2.08383 8.74553 1.93873C8.67826 1.79362 8.58774 1.68198 8.47504 1.60485C8.36234 1.52736 8.22991 1.47594 8.0767 1.45093C7.9235 1.42558 7.76078 1.41325 7.58503 1.41325H7.38181V0.76767H11.8565V1.41325H11.6617C11.4863 1.41325 11.3233 1.42558 11.1701 1.45093C11.0172 1.47559 10.8841 1.52736 10.7682 1.60485C10.6534 1.68233 10.5632 1.79362 10.4977 1.93873C10.4322 2.08348 10.4002 2.27649 10.4002 2.51704V10.0689C10.4002 10.2943 10.4343 10.4757 10.5012 10.613C10.5689 10.7511 10.6608 10.855 10.7756 10.9244C10.8904 10.9948 11.0239 11.0409 11.1775 11.0638C11.3303 11.0864 11.4916 11.0976 11.661 11.0976H11.8568V11.7432H7.38252Z" fill="#9D9D9D"/>
<path d="M12.5399 9.40497L14.1337 9.21161C14.1844 9.61786 14.3212 9.92841 14.5438 10.1432C14.7665 10.3581 15.036 10.4655 15.3524 10.4655C15.6922 10.4655 15.9774 10.3366 16.2079 10.0788C16.4422 9.82098 16.5594 9.47333 16.5594 9.03583C16.5594 8.62177 16.4481 8.29364 16.2255 8.05145C16.0028 7.80927 15.7313 7.68817 15.411 7.68817C15.2001 7.68817 14.9481 7.72919 14.6551 7.81122L14.8368 6.46942C15.2821 6.48114 15.6219 6.38544 15.8563 6.18231C16.0907 5.97528 16.2079 5.70184 16.2079 5.362C16.2079 5.07294 16.1219 4.84247 15.9501 4.67059C15.7782 4.49872 15.5497 4.41278 15.2645 4.41278C14.9833 4.41278 14.743 4.51044 14.5438 4.70575C14.3446 4.90106 14.2235 5.18622 14.1805 5.56122L12.663 5.30341C12.7684 4.78387 12.9266 4.36981 13.1376 4.06122C13.3524 3.74872 13.6493 3.50458 14.0282 3.3288C14.411 3.14911 14.8387 3.05927 15.3114 3.05927C16.12 3.05927 16.7684 3.31708 17.2567 3.8327C17.659 4.25458 17.8602 4.73114 17.8602 5.26239C17.8602 6.0163 17.4481 6.61786 16.6239 7.06708C17.1161 7.17255 17.5087 7.40887 17.8016 7.77606C18.0985 8.14325 18.2469 8.58661 18.2469 9.10614C18.2469 9.86005 17.9715 10.5026 17.4208 11.0339C16.87 11.5651 16.1844 11.8307 15.3641 11.8307C14.5868 11.8307 13.9422 11.6081 13.4305 11.1628C12.9188 10.7136 12.6219 10.1276 12.5399 9.40497Z" fill="#9D9D9D"/>
</svg>`;
icons.list["ordered"] = `  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_23_1630)">
  <path
    d="M4.54101 2.63671H14.5605C14.8032 2.63671 14.9999 2.43996 14.9999 2.19726C14.9999 1.95457 14.8032 1.75781 14.5605 1.75781H4.54101C4.29832 1.75781 4.10156 1.95457 4.10156 2.19726C4.10156 2.43996 4.29832 2.63671 4.54101 2.63671Z"
    fill="#9D9D9D"
  />
  <path
    d="M14.5605 7.06052H4.54101C4.29832 7.06052 4.10156 7.25727 4.10156 7.49997C4.10156 7.74266 4.29832 7.93942 4.54101 7.93942H14.5605C14.8032 7.93942 14.9999 7.74266 14.9999 7.49997C14.9999 7.25727 14.8032 7.06052 14.5605 7.06052Z"
    fill="#9D9D9D"
  />
  <path
    d="M14.5605 12.3632H4.54101C4.29832 12.3632 4.10156 12.56 4.10156 12.8027C4.10156 13.0454 4.29832 13.2421 4.54101 13.2421H14.5605C14.8032 13.2421 14.9999 13.0454 14.9999 12.8027C14.9999 12.56 14.8032 12.3632 14.5605 12.3632Z"
    fill="#9D9D9D"
  />
  <path
    d="M0.761963 0.878937H1.01134V3.66214C1.01134 3.90484 1.2081 4.10159 1.45079 4.10159C1.69349 4.10159 1.89024 3.90484 1.89024 3.66214V0.439484C1.89024 0.196788 1.69349 3.05176e-05 1.45079 3.05176e-05H0.761963C0.519268 3.05176e-05 0.32251 0.196788 0.32251 0.439484C0.32251 0.682179 0.519268 0.878937 0.761963 0.878937Z"
    fill="#9D9D9D"
  />
  <path
    d="M2.17041 8.66461C1.91122 8.66786 1.63832 8.67024 1.39468 8.67129C1.56047 8.4472 1.77572 8.15022 2.05029 7.75793C2.2583 7.46077 2.39423 7.17853 2.45441 6.91901C2.45775 6.90463 2.46036 6.8901 2.46223 6.87545L2.47785 6.75319C2.48022 6.73474 2.48139 6.71613 2.48139 6.6975C2.48139 6.0092 1.92144 5.44925 1.23317 5.44925C0.638032 5.44925 0.122905 5.87268 0.00832495 6.45604C-0.0384622 6.69419 0.116694 6.92517 0.354848 6.97193C0.593032 7.01865 0.823979 6.86356 0.870737 6.6254C0.904546 6.45317 1.05698 6.32816 1.23314 6.32816C1.42919 6.32816 1.59006 6.48173 1.60178 6.67488L1.59355 6.73942C1.56624 6.84422 1.49804 7.01414 1.33023 7.25397C0.830571 7.9679 0.532475 8.35819 0.372339 8.5679C0.170014 8.8328 0.0585691 8.97873 0.131928 9.2173C0.175639 9.35936 0.282895 9.46822 0.426303 9.51601C0.485776 9.53584 0.533442 9.55172 1.14701 9.55172C1.39058 9.55172 1.72336 9.54923 2.18148 9.54346C2.42415 9.54041 2.61841 9.34119 2.6154 9.0985C2.61229 8.85583 2.41272 8.66192 2.17041 8.66461Z"
    fill="#9D9D9D"
  />
  <path
    d="M1.30786 10.8984C0.714189 10.8984 0.200381 11.3208 0.086123 11.9027C0.0393359 12.1409 0.194492 12.3718 0.432646 12.4186C0.67083 12.4653 0.901777 12.3102 0.948535 12.072C0.98208 11.9013 1.13319 11.7773 1.30786 11.7773C1.5098 11.7773 1.67407 11.9416 1.67407 12.1435C1.67407 12.3455 1.5098 12.5097 1.30786 12.5097C1.06517 12.5097 0.868408 12.7065 0.868408 12.9492C0.868408 13.1919 1.06517 13.3886 1.30786 13.3886C1.5098 13.3886 1.67407 13.5529 1.67407 13.7549C1.67407 13.9568 1.5098 14.1211 1.30786 14.1211C1.12616 14.1211 0.970244 13.9858 0.945225 13.8065C0.942851 13.7895 0.94165 13.7722 0.94165 13.7549C0.94165 13.5122 0.744893 13.3154 0.502197 13.3154C0.259502 13.3154 0.0627441 13.5122 0.0627441 13.7549C0.0627441 13.8126 0.0667871 13.8708 0.0747266 13.928C0.16001 14.5391 0.690166 15 1.30786 15C1.99443 15 2.55298 14.4414 2.55298 13.7549C2.55298 13.4478 2.44101 13.1665 2.25608 12.9492C2.44104 12.7319 2.55298 12.4506 2.55298 12.1435C2.55298 11.457 1.9944 10.8984 1.30786 10.8984Z"
    fill="#9D9D9D"
  />
</g>
<defs>
  <clipPath id="clip0_23_1630">
    <rect width="15" height="15" fill="white" />
  </clipPath>
</defs>
</svg>`;
icons.list["bullet"] = `<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
  d="M16.336 7.83594H4.79004C4.42328 7.83594 4.12598 8.13324 4.12598 8.5C4.12598 8.86676 4.42328 9.16406 4.79004 9.16406H16.336C16.7028 9.16406 17.0001 8.86676 17.0001 8.5C17.0001 8.13324 16.7028 7.83594 16.336 7.83594Z"
  fill="#9D9D9D"
/>
<path
  d="M16.336 2.85547H4.79004C4.42328 2.85547 4.12598 3.15277 4.12598 3.51953C4.12598 3.88629 4.42328 4.18359 4.79004 4.18359H16.336C16.7028 4.18359 17.0001 3.88629 17.0001 3.51953C17.0001 3.15277 16.7028 2.85547 16.336 2.85547Z"
  fill="#9D9D9D"
/>
<path
  d="M16.336 12.8164H4.79004C4.42328 12.8164 4.12598 13.1137 4.12598 13.4805C4.12598 13.8472 4.42328 14.1445 4.79004 14.1445H16.336C16.7028 14.1445 17.0001 13.8472 17.0001 13.4805C17.0001 13.1137 16.7028 12.8164 16.336 12.8164Z"
  fill="#9D9D9D"
/>
<path
  d="M0.896484 4.41602C1.3916 4.41602 1.79297 4.01465 1.79297 3.51953C1.79297 3.02442 1.3916 2.62305 0.896484 2.62305C0.40137 2.62305 0 3.02442 0 3.51953C0 4.01465 0.40137 4.41602 0.896484 4.41602Z"
  fill="#9D9D9D"
/>
<path
  d="M0.896484 9.39648C1.3916 9.39648 1.79297 8.99511 1.79297 8.5C1.79297 8.00489 1.3916 7.60352 0.896484 7.60352C0.40137 7.60352 0 8.00489 0 8.5C0 8.99511 0.40137 9.39648 0.896484 9.39648Z"
  fill="#9D9D9D"
/>
<path
  d="M0.896484 14.377C1.3916 14.377 1.79297 13.9756 1.79297 13.4805C1.79297 12.9854 1.3916 12.584 0.896484 12.584C0.40137 12.584 0 12.9854 0 13.4805C0 13.9756 0.40137 14.377 0.896484 14.377Z"
  fill="#9D9D9D"
/>
</svg>`;
icons.align[""] = `  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Right" clip-path="url(#clip0_23_1557)">
<g id="Group">
<g id="Group_2">
<path id="Vector" d="M0.585938 15L14.4141 15C14.7377 15 15 14.7377 15 14.4141C15 14.0904 14.7377 13.8281 14.4141 13.8281L0.585938 13.8281C0.262324 13.8281 7.95155e-08 14.0904 5.12243e-08 14.4141C2.29331e-08 14.7377 0.262324 15 0.585938 15Z" fill="#9D9D9D"/>
</g>
</g>
<g id="Group_3">
<g id="Group_4">
<path id="Vector_2" d="M0.390626 10.3906L9.60938 10.3906C9.82512 10.3906 10 10.1283 10 9.80469C10 9.48107 9.82512 9.21875 9.60938 9.21875L0.390626 9.21875C0.174884 9.21875 1.03319e-06 9.48107 1.0049e-06 9.80469C9.76607e-07 10.1283 0.174884 10.3906 0.390626 10.3906Z" fill="#9D9D9D"/>
</g>
</g>
<g id="Group_5">
<g id="Group_6">
<path id="Vector_3" d="M0.585938 5.78125L14.4141 5.78125C14.7377 5.78125 15 5.51893 15 5.19531C15 4.8717 14.7377 4.60937 14.4141 4.60937L0.585938 4.60937C0.262324 4.60937 7.95155e-08 4.8717 5.12243e-08 5.19531C2.29331e-08 5.51892 0.262324 5.78125 0.585938 5.78125Z" fill="#9D9D9D"/>
</g>
</g>
<g id="Group_7">
<g id="Group_8">
<path id="Vector_4" d="M0.390626 1.17187L9.60938 1.17187C9.82512 1.17187 10 0.909551 10 0.585938C10 0.262324 9.82512 -1.52887e-08 9.60938 -3.41495e-08L0.390626 -8.40078e-07C0.174884 -8.58939e-07 1.03319e-06 0.262323 1.0049e-06 0.585937C9.76607e-07 0.90955 0.174884 1.17187 0.390626 1.17187Z" fill="#9D9D9D"/>
</g>
</g>
</g>
<defs>
<clipPath id="clip0_23_1557">
<rect width="15" height="15" fill="white" transform="translate(15 15) rotate(-180)"/>
</clipPath>
</defs>
</svg>`;
icons.align["center"] = `   <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_23_1531)">
  <path
    d="M15.251 0H1.42285C1.09924 0 0.836914 0.262324 0.836914 0.585938C0.836914 0.909551 1.09924 1.17188 1.42285 1.17188H15.251C15.5746 1.17188 15.8369 0.909551 15.8369 0.585938C15.8369 0.262324 15.5746 0 15.251 0Z"
    fill="#9D9D9D"
  />
  <path
    d="M12.9463 4.60938H3.72754C3.5118 4.60938 3.33691 4.8717 3.33691 5.19531C3.33691 5.51893 3.5118 5.78125 3.72754 5.78125H12.9463C13.162 5.78125 13.3369 5.51893 13.3369 5.19531C13.3369 4.8717 13.162 4.60938 12.9463 4.60938Z"
    fill="#9D9D9D"
  />
  <path
    d="M15.251 9.21875H1.42285C1.09924 9.21875 0.836914 9.48107 0.836914 9.80469C0.836914 10.1283 1.09924 10.3906 1.42285 10.3906H15.251C15.5746 10.3906 15.8369 10.1283 15.8369 9.80469C15.8369 9.48107 15.5746 9.21875 15.251 9.21875Z"
    fill="#9D9D9D"
  />
  <path
    d="M12.9463 13.8281H3.72754C3.5118 13.8281 3.33691 14.0904 3.33691 14.4141C3.33691 14.7377 3.5118 15 3.72754 15H12.9463C13.162 15 13.3369 14.7377 13.3369 14.4141C13.3369 14.0904 13.162 13.8281 12.9463 13.8281Z"
    fill="#9D9D9D"
  />
</g>
<defs>
  <clipPath id="clip0_23_1531">
    <rect width="15" height="15" fill="white" transform="translate(0.836914)" />
  </clipPath>
</defs>
</svg>`;
icons.align["right"] = ` <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_23_1544)">
  <path
    d="M15.251 0H1.42285C1.09924 0 0.836914 0.262324 0.836914 0.585938C0.836914 0.909551 1.09924 1.17188 1.42285 1.17188H15.251C15.5746 1.17188 15.8369 0.909551 15.8369 0.585938C15.8369 0.262324 15.5746 0 15.251 0Z"
    fill="#9D9D9D"
  />
  <path
    d="M15.4463 4.60938H6.22754C6.0118 4.60938 5.83691 4.8717 5.83691 5.19531C5.83691 5.51893 6.0118 5.78125 6.22754 5.78125H15.4463C15.662 5.78125 15.8369 5.51893 15.8369 5.19531C15.8369 4.8717 15.662 4.60938 15.4463 4.60938Z"
    fill="#9D9D9D"
  />
  <path
    d="M15.251 9.21875H1.42285C1.09924 9.21875 0.836914 9.48107 0.836914 9.80469C0.836914 10.1283 1.09924 10.3906 1.42285 10.3906H15.251C15.5746 10.3906 15.8369 10.1283 15.8369 9.80469C15.8369 9.48107 15.5746 9.21875 15.251 9.21875Z"
    fill="#9D9D9D"
  />
  <path
    d="M15.4463 13.8281H6.22754C6.0118 13.8281 5.83691 14.0904 5.83691 14.4141C5.83691 14.7377 6.0118 15 6.22754 15H15.4463C15.662 15 15.8369 14.7377 15.8369 14.4141C15.8369 14.0904 15.662 13.8281 15.4463 13.8281Z"
    fill="#9D9D9D"
  />
</g>
<defs>
  <clipPath id="clip0_23_1544">
    <rect width="15" height="15" fill="white" transform="translate(0.836914)" />
  </clipPath>
</defs>
</svg>`;
icons.align["justify"] = ` <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Justify" clip-path="url(#clip0_23_1570)">
<g id="Group">
<g id="Group_2">
<path id="Vector" d="M15.251 0H1.42285C1.09924 0 0.836914 0.262324 0.836914 0.585938C0.836914 0.909551 1.09924 1.17188 1.42285 1.17188H15.251C15.5746 1.17188 15.8369 0.909551 15.8369 0.585938C15.8369 0.262324 15.5746 0 15.251 0Z" fill="#9D9D9D"/>
</g>
</g>
<g id="Group_3">
<g id="Group_4">
<path id="Vector_2" d="M15.251 4.60938H1.42285C1.09924 4.60938 0.836914 4.8717 0.836914 5.19531C0.836914 5.51893 1.09924 5.78125 1.42285 5.78125H15.251C15.5746 5.78125 15.8369 5.51893 15.8369 5.19531C15.8369 4.8717 15.5746 4.60938 15.251 4.60938Z" fill="#9D9D9D"/>
</g>
</g>
<g id="Group_5">
<g id="Group_6">
<path id="Vector_3" d="M15.251 9.21875H1.42285C1.09924 9.21875 0.836914 9.48107 0.836914 9.80469C0.836914 10.1283 1.09924 10.3906 1.42285 10.3906H15.251C15.5746 10.3906 15.8369 10.1283 15.8369 9.80469C15.8369 9.48107 15.5746 9.21875 15.251 9.21875Z" fill="#9D9D9D"/>
</g>
</g>
<g id="Group_7">
<g id="Group_8">
<path id="Vector_4" d="M15.251 13.8281H1.42285C1.09924 13.8281 0.836914 14.0904 0.836914 14.4141C0.836914 14.7377 1.09924 15 1.42285 15H15.251C15.5746 15 15.8369 14.7377 15.8369 14.4141C15.8369 14.0904 15.5746 13.8281 15.251 13.8281Z" fill="#9D9D9D"/>
</g>
</g>
</g>
<defs>
<clipPath id="clip0_23_1570">
<rect width="15" height="15" fill="white" transform="translate(0.836914)"/>
</clipPath>
</defs>
</svg>
`;

// Undo and redo functions for Custom Toolbar
function undoChange() {
  if (this.quill.history.stack.undo.length === 0) return;
  this.quill.history.undo();
  // console.log(this.quill.history.stack);
}
function redoChange() {
  if (this.quill.history.stack.redo.length === 0) return;
  this.quill.history.redo();
  // console.log(this.quill.history.stack);
}

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      undo: undoChange,
      redo: redoChange,
      // clear: clearChange,
    },
  },
  history: {
    delay: 1000,
    maxStack: 100,
  },
};

export const formats = ["header", "bold", "italic", "underline", "align", "list", "bullet", "strike"];

const QuillToolbar = ({ submit, close, boundingRect, width, selectedText, quillRef }) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();

  const textAlignLeft = () => {
    quillRef.current.getEditor().root.style.textAlign = "left";
    quillRef.current.getEditor().root.style.direction = "ltr";
  };

  const textAlignRight = () => {
    quillRef.current.getEditor().root.style.textAlign = "right";
    quillRef.current.getEditor().root.style.direction = "rtl";
  };

  return (
    <div id="toolbar" style={{ position: "relative", display: "flex", justifyContent: "space-around", padding: width < 991 ? "10px 0px" : "18px" }}>
      <span>
        <button className="ql-undo"></button>
        <button className="ql-redo"></button>
      </span>

      <span>
        <button className="ql-bold"></button>
        <button className="ql-italic"></button>
        <button className="ql-underline"></button>
      </span>

      {width < 500 ? (
        <span>
          <select className="ql-align">
            <option selected>Align</option>
            <option value="center">Center</option>
            <option value="right">Right</option>
            <option value="justify">Justify</option>
          </select>
        </span>
      ) : (
        <span>
          <button className="ql-align" onClick={lng === "arab" ? textAlignLeft : null} value=""></button>
          <button className="ql-align" value="center"></button>
          <button className="ql-align" onClick={lng === "arab" ? textAlignRight : null} value="right"></button>
          <button className="ql-align" value="justify"></button>
        </span>
      )}
      <span>
        <button className="ql-header" value="1"></button>
        <button className="ql-header" value="2"></button>
        <button className="ql-header" value="3"></button>
      </span>
      {width > 500 && (
        <span>
          <button className="ql-list" value="ordered"></button>
          <button className="ql-list" value="bullet"></button>
        </span>
      )}
      {width > 500 && (
        <Tooltip title={t("Highlight Text to use Ai Tools")} placement="bottom" arrow>
          <span style={{ cursor: "pointer" }}>
            <Light className="bounce_tool" />
          </span>
        </Tooltip>
      )}
      {/* {width > 500 && (
        <span style={{ cursor: "pointer", fontFamily: "tajawal", position: "relative" }} onClick={() => (selectedText ? setShow(!show) : alert("select text to open ai window"))}>
          <Ai />
          {t("Ai Tools")}
          <KeyboardArrowDownRoundedIcon
            style={{
              color: "#3CC8EB",
              borderRadius: "5px",
              background: "#F4FCFF",
              transition: "all 0.3s ease-in-out",
            }}
          />
          <div style={{ position: "absolute", top: "0px" }}>{show && <AiTools submit={submit} close={close} boundingRect={boundingRect} />}</div>
        </span>
      )} */}
    </div>
  );
};

export default QuillToolbar;
