import React,{useState} from 'react'
import { SortableElement } from 'react-sortable-hoc';
import deletep from "../../assets/img/delete.png";
import EditIcon from "../../assets/img/miscellaneous/edit_icon.png";
import { useTranslation } from 'react-i18next'
import dragIcon from "../../assets/img/drag.png"

const SortableItem = ({ value, myKey , setItems, items, setTextUpdates, editSlides, setNewData, newData ,data, removeSlide  }) => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);

  const handleInputKeyDown = (e, myKey) => {
    if (e.key === "Enter" && newData !== "") {
      e.preventDefault();
      setEdit(false);
      editSlides(myKey) 
      // setEditIndex(null);
    } else if (e.key === "Enter" && e.target.value === "") {
      e.preventDefault();
      toastAlert(t("Please enter a title"), undefined, "error");
      return;
    }
  };

  return (
    <>
    
      {edit ? (
        <input
        className='slides_tile_input'
          autoFocus
          placeholder="Ex: History : About the company"
          key={myKey}
          onBlur={(e) => {
            if (e.target.value === "") {
              toastAlert(t("Please enter a title"), undefined, "error");
              setEdit(true);
              return;
            }else if(e.target.value === value){
              editSlides(myKey)
              setEdit(false);
              return;
            }
            setEdit(false);
          }}
          value={newData}
          onKeyDown={(e) => handleInputKeyDown(e, myKey)}
          onChange={(e) => setNewData(e.target.value)}
          style={{
            borderRadius: "10px",
            padding: "17px 20px",
            border: "0.5px solid #D7D7D7",
            width: "100%",
            backgroundColor: "white",
            border: "1px solid #D318F2",
            margin: "10px 0"
          }}
        />
      ) : (
        <div className="slides_tile" style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <li
            className="slides_tile_item_grabbing"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: "10px",
              padding: "20px",
              width: "100%",
              height: "60px",
              margin: "8px auto",
              background: "rgba(217, 217, 217, 0.37)",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <img src={dragIcon} width={20} style={{ opacity: 0.4 }} />
              <h6 style={{ marginBottom: "0",fontWeight:"500",color:"#646161" }}>{`Slide ${myKey + 1} : ${value.replace("start", "")}`}</h6>
            </div>
            <div className="slides_buttons" >
              <button
                style={{ border: "none", background: "transparent",padding:"5px 3px"  }}
                onClick={() => {
                  setEdit(true);
                  setNewData(value);
                }}
              >
                <img src={EditIcon} width={15} style={{ pointerEvents: "none" }} />
              </button>
              <button style={{ border: "none", background: "transparent",padding:"5px 3px" }} onClick={() => removeSlide(myKey)}>
                <img src={deletep} width={18} style={{ pointerEvents: "none" }} />
              </button>
            </div>
          </li>
        </div>
      )}
    </>
  );
};

export default SortableElement(SortableItem);
