import { TextareaAutosize } from '@mui/material';
import LoadingPopUp from 'components/popUps/LoadingPopUp';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import creativeContent from "../../assets/img/newDesign/creativeContent.png";
import coding from "../../assets/img/newDesign/coding.png";
import thinking from "../../assets/img/newDesign/thinking.png";
import arrow from "../../assets/img/newDesign/Vector.svg";
import UpgradeModal from 'components/UpgradeModal/UpgradeModal';
import useId from 'react-use-uuid';
import { useNavigate, useLocation } from 'react-router-dom';
import { socket } from '../../socket'
import LimitPopUp from 'views/plansComponent/LimitPopUp';
const GPTMain = () => {
  const [screenLoader, setScreenLoader] = useState(false)
  const bigLoading = useSelector((state) => state.loading);
  const newChatInfo = useSelector((state) => state.arabyGPT_newChat);
  const gptHistory = useSelector((state) => state.arabyGPT);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [innerLoadingSwitch, setInnerLoadingSwitch] = useState(false);
  const [totalWords, setTotalWords] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const user = useSelector((state) => state.user);
  const userSubscription = useSelector((state) => state.userSubscription);
  const userEnterprise = useSelector((state) => state.enterprise);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputCount, setInputCount] = useState(0);
  const [inputCountContainer, setInputCountContainer] = useState(true)
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const navigate = useNavigate();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const generatedID = useId();
  const location = useLocation()

  useEffect(() => {
    setTotalWords(user.text_count_left)
  }, [userSubscription, user, userEnterprise])
  useEffect(() => {
    socket.connect();
    return () => {
      socket.disconnect();
    };
  }, [])
  useEffect(() => {
    let token = { token: localStorage.getItem("sess") };
    if (socket.connected) {
      dispatch({ type: "LOAD_ALL_CHAT_HISTORY" })
      socket.emit("token", token);
    }
  }, [socket.connected]);
  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }
    function onDisconnect() {
      setIsConnected(false);
    }
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
  }, [gptHistory]);
  useEffect(() => {
    const onMessagesEvent = async (value) => {
      switch (value?.type) {
        case "token":
          // console.log(value)
          dispatch({ type: "FETCH_ALL_CHAT_HISTORY", data: value?.chats })
          break;
        default:
          break;
      }
    };
    socket.on("data", onMessagesEvent);
    return () => {
      socket.off("data", onMessagesEvent);
    };
  }, [gptHistory]);
  const generateText = async (e) => {

    e.preventDefault();
    setInputCount(0)
    // console.log(inputValue)
    if (totalWords === 0) {
      return setShowModal(true);
    }
    setScreenLoader(true)
    dispatch({
      type: "CREATE_CHAT", data: {
        role: "user",
        action: "generate-text",
        ref_id: generatedID,
        message_id: Date.now(),
        message: inputValue,
        title: inputValue?.slice(0, 15),
        history: []
      }
    })
    socket.disconnect();
    navigate(`/twwtgpt/${generatedID}`)
  }

  const generateFromEx = (str) => {
    setInputValue(str);
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      if (!(innerLoadingSwitch || isLoading)) {
        setInputCount(0);
        generateText(e);
      }
    } else if (e.key === 'Enter' && e.shiftKey) {
      // alert('only enter')
    }
  }
  // console.log(inputCount, "inputCountmain")
  return (
    <div className="inner_content border-radius-top" style={{ overflow: "hidden" }}>
      {screenLoader ? bigLoading ? null : <LoadingPopUp gptLoading={true} /> : null}
      <div className="Araby_GPT">
        <div className="Araby_GPT_chat_space">
          <div className="landing">
            <h1 style={{ fontWeight: "600" }} className="araby-gpt-text">
              {t("Welcome to TwwtGPT")}
            </h1>
            <h5>{t("Your personalised Ai - powered by Twwt ai")}</h5>
            <div className="examples_wrapper row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="example_element_header">
                  <img src={creativeContent} alt="header icon" />
                  <label>{t("Create creative content")}</label>
                </div>
                <div onClick={() => generateFromEx(t("Write me a poem in the old Arabic style"))} className="example_element">
                  <p>{t("Write me a poem in the old Arabic style")}</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="example_element_header">
                  <img src={coding} alt="header icon" />
                  <label>{t("Write source code")}</label>
                </div>
                <div onClick={() => generateFromEx(t("Write a php code for dynamic banner"))} className="example_element">
                  <p>{t("Write a php code for dynamic banner")}</p>
                </div>
              </div>
              <div className=" col-lg-4 col-md-6 col-sm-12">
                <div className="example_element_header">
                  <img src={thinking} alt="header icon" />
                  <label>{t("Ask complex questions")}</label>
                </div>
                <div onClick={() => generateFromEx(t("Write me an idea for an advertising company"))} className="example_element">
                  <p>{t("Write me an idea for an advertising company")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="arabyGPT_form_wrapper">
          <form onKeyDown={handleKeyPress} onSubmit={generateText} className="arabyGPTForm">
            <div style={{ display: "flex" }}>
              <TextareaAutosize
                autoFocus
                onFocus={() => {
                  setInputCountContainer(true);
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setInputCountContainer(false);
                  }, 200);
                }}
                value={inputValue}
                //   style={{transform:"unset" }}
                className="form-control"
                maxRows={8}
                // maxLength={4000}
                placeholder={t("Write here..")}
                onChange={(e) => {
                  setInputValue(e.target.value);
                  setInputCount(e.target.value.length);
                }}
              />
              <button disabled={innerLoadingSwitch || isLoading} type="submit" style={{ marginTop: "0" }}>
                <img src={arrow} alt="submit arrow" />
              </button>
            </div>
            {/* {inputCountContainer && <span style={{ padding: " 0 5px", color: "#6A7280", textAlign: "end", fontSize: "0.8rem", display: "block" }}>{inputCount} / 4000</span>} */}
          </form>
        </div>
        {user?.plan === "Trial" ? (
          <UpgradeModal setShowModal={setShowModal} showModal={showModal} />


        ) : (
          <LimitPopUp setShowModal={setShowModal} showModal={showModal} />
        )}
      </div>
    </div>
  );
}

export default GPTMain