import React, { useState, useEffect } from "react";
import UpgradeModal from "components/UpgradeModal/UpgradeModal";
import UpgradePlanModal from "components/UpgradeModal/UpgradePlanModal";
import reSizer from "react-image-file-resizer";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { toastAlert } from "components/toastAlert/toastAlert";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { handleRedesign, uploadReImagine } from "redux/actions/media_section";
import ReplayIcon from "@mui/icons-material/Replay";
import upscaleImage from "assets/img/newDesign/reimagine.png";
import "font-awesome/css/font-awesome.min.css";
import "views/MediaSection/ImageTools/Create/ImgGen.css";
import Loading_v2 from "components/Loading/Laoadingv2/Loadingv2";
import { handleRedesignLoading } from "redux/actions/media_section";
import FileContainer from "components/MediaSection/FileContainer";

import { handleLoadingRedesign } from "redux/actions/media_section";
import LimitPopUp from "views/plansComponent/LimitPopUp";
const ReImagine = () => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const redesign = useSelector((state) => state.redesign);
  const [showModal, setShowModal] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [base64, setBase64] = useState("");
  const [totalImages, setTotalImages] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [show, setShow] = useState(false);
  const [loadingText, setLoadingText] = useState("Uploading your Image");

  useEffect(() => {
    setTotalImages(user?.image_count_left);
    if (
      user?.plan === "Trial" ||
      user?.plan === "Starter" ||
      user?.plan === "Basic"
    ) {
      setShow(true);
    }
  }, [user]);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  let error_valid =
    lng === "arab"
      ? "يُسمح فقط بملفات JPG و JPEG و PNG"
      : "Only JPG, JPEG, and PNG files are allowed.";

  const handleReImagine = async () => {
    if (totalImages === 0) {
      dispatch({ type: "NEW_REDESIGN" });
      return setShowModal(true);
    }
    dispatch(handleRedesign({ image: base64 }));
  };

  useEffect(() => {
    if (base64) {
      handleReImagine();
    }
  }, [base64]);

  const handleDrop = async (e) => {
    e.preventDefault();
    if (totalImages === 0) {
      dispatch({ type: "NEW_REDESIGN" });
      return setShowModal(true);
    } else {
      const file = await e.dataTransfer.files;
      if (file) {
        const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
        if (allowedTypes.includes(file[0].type)) {
          const image = await resizeFile(file[0]);
          dispatch(uploadReImagine(image));
          setBase64(image);
          setDragging(false);
        } else {
          toastAlert(error_valid, undefined, "warning");
        }
      }
    }
  };

  const handleImageUpload = async (e) => {
    e.preventDefault();
    if (totalImages === 0) {
      dispatch({ type: "NEW_REDESIGN" });
      return setShowModal(true);
    } else {
      const file = await e.target.files;
      if (file) {
        const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
        if (allowedTypes.includes(file[0].type)) {
          const image = await resizeFile(file[0]);
          setBase64(image);
          // console.log(image, "SSSSS");
          setDragging(false);
        } else {
          toastAlert(error_valid, undefined, "warning");
        }
      }
    }
  };

  const handleDownload = async () => {
    const fileUrl = redesign?.redesign?.urls;
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = "redesign twwt.ai";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      reSizer.imageFileResizer(
        file,
        1024,
        1024,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  useEffect(() => {
    if (redesign?.loading) {
      let intervalTime;
      if (redesign?.loadingNumber >= 1 && redesign?.loadingNumber < 25) {
        intervalTime = 3000 / 25; // 5 seconds for 1-25, increment by 1 every 200ms
        setLoadingText("Uploading Your Image");
      } else if (
        redesign?.loadingNumber >= 25 &&
        redesign?.loadingNumber < 50
      ) {
        intervalTime = 5000 / 25; // 20 seconds for 25-50, increment by 1 every 800ms
        setLoadingText("Analyzing Your Image");
      } else if (
        redesign?.loadingNumber >= 50 &&
        redesign?.loadingNumber < 75
      ) {
        intervalTime = 10000 / 25; // 30 seconds for 50-75, increment by 1 every 1200ms
        setLoadingText("Redesigning the Image");
      } else if (
        redesign?.loadingNumber >= 75 &&
        redesign?.loadingNumber < 100
      ) {
        intervalTime = 50000 / 25; // 10 seconds for 50-75, increment by 1 every 1200ms
        setLoadingText("Downloading the Image");
      }
      const timer = setInterval(() => {
        dispatch(handleRedesignLoading(redesign?.loadingNumber, "redesign"));
      }, intervalTime);
      return () => {
        clearInterval(timer);
      };
    }
  }, [redesign?.loadingNumber, redesign?.loading]);
  // console.log(redesign, "redesign");
  return (
    <>
      {redesign?.loading ? (
        <div
          style={{
            height: "calc(100vh - 200px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading_v2
            loadingText={loadingText}
            setLoadingText={setLoadingText}
            loadingNumber={redesign?.loadingNumber}
          />
        </div>
      ) : redesign?.redesign?.length != 0 ? (
        <div style={{ position: "relative" }}>
          <div
            className="redesign_image"
            style={{
              maxWidth: "500px",
              minHeight: "300px",
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              className="redesign_image_item"
            >
              <h5
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  margin: "10px 0 ",
                }}
              >
                {t("Before")}
              </h5>
              <img
                src={redesign?.firstImage?.image}
                style={{
                  width: width > 1200 ? "450px" : "300px",
                  borderRadius: "10px",
                  maxHeight: "500px",
                  objectFit: "cover",
                  marginBottom: "68px",
                }}
              />
            </div>
            {width > 670 && (
              <ArrowForwardRoundedIcon
                style={{
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  transform: lng === "arab" ? "scale(-1)" : null,
                }}
              />
            )}

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                className="redesign_image_item_item"
              >
                <h5
                  style={{
                    textAlign: "center",
                    fontWeight: "600",
                    margin: "10px 0",
                  }}
                >
                  {t("After")}
                </h5>
                <img
                  src={redesign?.redesign?.urls}
                  style={{
                    width: width > 1200 ? "450px" : "300px",
                    borderRadius: "10px",
                    maxHeight: "500px",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div
                className="beforeafter-gray"
                style={{ padding: "0px", marginTop: "20px" }}
              >
                <button className="download-btn" onClick={handleDownload}>
                  <i
                    className="fas fa-arrow-down"
                    style={{
                      fontSize: "medium",
                      margin: "0 10px",
                    }}
                  ></i>
                  {t("Download")}
                </button>
                <button
                  className="newupload"
                  onClick={() => {
                    setBase64("");
                    dispatch({ type: "NEW_REDESIGN" });
                  }}
                >
                  <ReplayIcon
                    sx={{
                      transform: "scale(-1)",
                      margin: "0 10px",
                      fontSize: "20px",
                    }}
                  />
                  {t("Try Again")}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="image_gen_container">
          <div
            className="image_gen_header"
            style={{
              padding: width < 500 ? "10px" : "20px",
              alignSelf: "flex-start",
            }}
          >
            <h4 style={{ textAlign: lng === "arab" ? "right" : "left" }}>
              {t(
                "Re-imagine and image from a different perspective with the help of Ai"
              )}
            </h4>
            <p style={{ textAlign: lng === "arab" ? "right" : "left" }}>
              {t(
                "have a new look on life from another point of view with twwt.ai redesign tool."
              )}
            </p>
            <FileContainer
              handleDrop={handleDrop}
              handleDragOver={handleDragOver}
              handleDragLeave={handleDragLeave}
              handleUpload={handleImageUpload}
              dragging={dragging}
              types=".jpg,.jpeg,.png"
              support="JPG , PNG"
            />
          </div>
          <div className="right_image_media">
            <img
              src={upscaleImage}
              alt="upscale-example"
              width={"100%"}
              height={"100%"}
            />
          </div>
        </div>
      )}
      {user?.plan === "Trial" ? (
        <UpgradeModal
          images={true}
          setShowModal={setShowModal}
          showModal={showModal}
        />
      ) : (
        <LimitPopUp setShowModal={setShowModal} showModal={showModal} />
      )}
      <UpgradePlanModal show={show} setShow={setShow} width={width} />
    </>
  );
};
export default ReImagine;
