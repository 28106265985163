import { toastAlert } from "components/toastAlert/toastAlert";
import * as api from "../../api";
import checkErr from "./api_error_func";
import { FETCH_BLOG } from "redux/contstants";
import { LOAD_BLOG } from "redux/contstants";
import { BLOG_LOADED } from "redux/contstants";

export const getAllBlogs = (page, limit, setLoading) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_BLOG });
    var { data } = await api.getBlogs(page, limit);
    await dispatch({ type: FETCH_BLOG, data: data });
    setLoading(false);
  } catch (error) {
    dispatch({ type: BLOG_LOADED });
    checkErr(error, setLoading, toastAlert);
  }
};

export const addBlog = (data, setLoading, navigate) => async (dispatch) => {
  try {
    var result = await api.blog(data);
    setLoading(false);
    toastAlert("Blog Added Successfully", undefined, "success");
    navigate('/blog')
  } catch (error) {
    checkErr(error, setLoading, toastAlert);
  }
};

export const getSingleBlogs = (id, setLoading, setBlog) => async (dispatch) => {
  try {
    var { data } = await api.getBlogById(id);
    setBlog(data?.blog);
    setLoading(false);
  } catch (error) {
    checkErr(error, setLoading, toastAlert);
  }
};

export const updateBlog = (data, id, setLoading, navigate) => async (dispatch) => {
    try {
      var result = await api.UpdateBlog(data, id);
      setLoading(false);
      toastAlert("Blog Updated Successfully", undefined, "success");
      navigate('/blog')
    } catch (error) {
      checkErr(error, setLoading, toastAlert);
    }
  };

  export const deleteBlog = (id, setLoading, setForceLoad, forceLoad) => async (dispatch) => {
    try {
      var result = await api.deleteBlogById(id);
      setLoading(false);
      setForceLoad(forceLoad + 1);
      toastAlert("Blog Deleted Successfully", undefined, "success");
    } catch (error) {
      checkErr(error, setLoading, toastAlert);
    }
  };