import { FETCH_ENHANCE_AUDIO, LOAD_ENHANCE_AUDIO, ENHANCE_AUDIO_LOADED, NEW_ENHANCE_AUDIO, SHOW_ENHANCE_AUDIO_FALSE, SHOW_ENHANCE_AUDIO_TRUE, LOAD_PERCENTAGE_ENHANCE_AUDIO } from "redux/contstants";

export default (enhanceAudio = { enhanceAudio: [], uploaded: [], loadingNumber: 0, loading: false, showRemover: false }, action) => {
  switch (action.type) {
    case LOAD_ENHANCE_AUDIO:
      return { ...enhanceAudio, loading: true, showRemover: true, loadingNumber: 0, loadingNumber: 0 };
    case FETCH_ENHANCE_AUDIO:
      return { ...enhanceAudio, enhanceAudio: action.data };
    case ENHANCE_AUDIO_LOADED:
      return { ...enhanceAudio, loading: false };
    case NEW_ENHANCE_AUDIO:
      return { ...enhanceAudio, enhanceAudio: [], loadingNumber: 0, uploaded: [], showRemover: false };
    case SHOW_ENHANCE_AUDIO_FALSE:
      return { ...enhanceAudio, showRemover: false };
    case SHOW_ENHANCE_AUDIO_TRUE:
      return { ...enhanceAudio, showRemover: true };
    case LOAD_PERCENTAGE_ENHANCE_AUDIO:
      return { ...enhanceAudio, loadingNumber: action.data };
    default:
      return enhanceAudio;
  }
};
