import React, { useEffect } from "react";
import { paypal_client_id } from "api/baseUrl";

const Paypal = ({ selectedPlan, isYearly, selectedNumber, user, setThankyou, setShowSubscribeModal, setShowCheckoutModal }) => {
  useEffect(() => {
    let plan_id = "";
    let quantity = "";
    if (isYearly) {
      plan_id = selectedPlan?.paypal_yearly_plan_id;
    } else {
      plan_id = selectedPlan?.paypal_monthly_plan_id;
    }
    let custom_id = user?._id;
    if (selectedPlan?.name === "Freelancer") {
      quantity = 1;
    } else {
      quantity = selectedNumber;
    }

    const script = document.createElement("script");
    script.src = `https://www.paypal.com/sdk/js?client-id=${paypal_client_id}&vault=true&intent=subscription`;
    script.async = true;

    script.onload = () => {
      window.paypal
        .Buttons({
          fundingSource: window.paypal.FUNDING.PAYPAL,
          style: {
            shape: "rect",
            color: "gold",
            layout: "vertical",
            Tagline: false,
          },
          createSubscription: function (data, actions) {
            return actions.subscription.create({
              plan_id: plan_id,
              custom_id: custom_id,
              quantity: quantity,
            });
          },
          onApprove: function (data, actions) {
            setShowSubscribeModal(false);
            setShowCheckoutModal(false);
            // console.log(data);
            // console.log(actions);
            setThankyou(true);
          },
        })
        .render("#paypal-button-container");
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div id="paypal-button-container"></div>;
};

export default Paypal;
