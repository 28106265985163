import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./upgradePlan.scss";
import { useTranslation } from "react-i18next";
import king from "./icons/king.svg";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import { useNavigate } from "react-router-dom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const UpgradePlanModal = ({ show, setShow, width }) => {
  const navigate = useNavigate();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal centered show={show} size="md">
      <span className="close_button" onClick={() => navigate(-1)} style={{ left: lng === "arab" ? "15px" : null, right: lng === "arab" ? null : "15px" }}>
        <CloseRoundedIcon sx={{ fontSize: "20px" }} />
      </span>
      <Modal.Body className="upgrade_modal_plan" style={{ textAlign: lng === "arab" ? "right" : "left", fontFamily: lng === "arab" ? "Tajawal" : "poppins" }}>
        <div className="upgrade_modal_header">
          <img src={king} width={"50px"} />
          <h1>{t("Upgrade to get Access")}</h1>
          <h5>{t("Upgrade your account Get access to all the latest features and newest Ai tools with any upgraded plan.")}</h5>
        </div>
        <Modal.Footer className="upgrade_doc_footer" style={{ alignItems: "center", padding: "unset" }}>
          <span style={{ fontWeight: 400, opacity: 0.7 }}>{t("You can cancel anytime")}</span>
          <button
            style={{ fontWeight: "700" }}
            onClick={() => {
              setShow(false);
              navigate("/plan");
            }}
          >
            {t("Upgrade Plan")}
            <EastRoundedIcon sx={{ color: "#fff", margin: lng === "arab" ? "0 10px 0 0" : "0 0 0 10px", transform: lng === "arab" ? "scale(-1)" : null }} />
          </button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

export default UpgradePlanModal;
