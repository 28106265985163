import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import "assets/css/home.css";
import { useTranslation } from "react-i18next";
import text_video_image_en from "assets/img/images/text_video_image.png";
import text_video_image_ar from "assets/img/images_ar/Txt to Video AR-min.png";
import text_video_gif_en from "assets/img/gif_en/Txt to Video-min (1).gif";
import text_video_gif_ar from "assets/img/gif_ar/Txt-to-video-AR-01 (1).gif";
import remove_background from "assets/img/newDesign/imageSectionVideos/showreeledit.gif";
import remove_background_img from "assets/img/newDesign/imageSectionVideos/showreeledit.png";
import overlap_image from "assets/img/images/Car Shuffle Thumb (1).png";
import overlap_gif from "assets/img/gif_en/Car Shuffle.gif";
import zoom_gif from "assets/img/gif_en/Infinite Video.gif";
import zoom_image from "assets/img/images/Infinite Thumb.png";
import Marquee from "react-double-marquee";
import { Link } from "react-router-dom";
import ImagesFilterComponent from "views/MediaSection/ImagesFilterComponent";
import { ReactComponent as Crown } from "assets/img/newDesign/Crown.svg";

const VideoTools = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const features = [
    {
      name: "Video Background Remover",
      arabic_name: "إزالة خلفية الفيديو",
      link: "video_background",
      video: remove_background,
      videoAr: remove_background,
      videoH: remove_background_img,
      videoHA: remove_background_img,
      videom: remove_background_img,
      videomAr: remove_background_img,
      access: ["Free"],
    },
    {
      name: "Text to Video",
      arabic_name: "نص إلى فيديو",
      link: "text-video",
      video: text_video_gif_en,
      videoAr: text_video_gif_ar,
      videoH: text_video_image_en,
      videoHA: text_video_image_ar,
      videom: text_video_image_en,
      videomAr: text_video_image_ar,
      access: ["Basic"],
    },

    // {
    //   name: "overlap-animation",
    //   arabic_name: "مقطع فيديو متسارع",
    //   link: "before-after",
    //   video: overlap_gif,
    //   videoAr: overlap_gif,
    //   videoH: overlap_image,
    //   videoHA: overlap_image,
    //   videom: overlap_image,
    //   videomAr: overlap_image,
    //   access: ["Pro"],
    //   icon: Crown,
    // },
    {
      name: "infinite-zoom",
      arabic_name: "تقريب لا نهائي",
      link: "infinite",
      video: zoom_gif,
      videoAr: zoom_gif,
      videoH: zoom_image,
      videoHA: zoom_image,
      videom: zoom_image,
      videomAr: zoom_image,
      access: ["Basic"],
    },
  ];
  return (
    <div
      style={{
        gap: "20px",
        padding: "0px",
        height: "calc(100vh - 110px)",
        position: "relative",
      }}
      className="page_wrapper border-radius-top"
    >
      <div style={{ padding: "0px" }} className="image_gen_outer_wraaper border-radius-top" id="image_gen_outer_wraaper">
        <ImagesFilterComponent />
        <h2
          style={{
            fontWeight: "600",
            color: "black",
            fontSize: "1.5rem",
            textAlign: lng === "arab" ? "right" : "left",
            marginBottom: "0",
          }}
        >
          {t("Video Tools")}
        </h2>
        <div
          style={{
            margin: null,
            maxWidth: null,
            padding: "40px 20px 15px 15px",
          }}
          className="write_body border-radius-top"
        >
          <div
            style={{
              paddingBottom: "15px",
              minHeight: "300px",
              justifyContent: "flex-start",
            }}
            className="all_images_wrapper row"
          >
            {features?.length === 0 ? (
              <label>{t("No Items Found")}</label>
            ) : (
              features?.map((item, i) => (
                <VideoCard
                  key={i}
                  title={lng === "arab" ? item?.arabic_name : item?.name}
                  index={i}
                  videoAr={item?.videoAr}
                  video={item?.video}
                  thumb={lng === "arab" ? item?.videoHA || item?.videoH : item?.videoH}
                  videom={item?.videom}
                  videomAr={item?.videomAr}
                  access={item?.access}
                  Icon={item?.icon}
                  goTo={item?.link}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoTools;

const VideoCard = ({ title, video, index, Icon, goTo, thumb, videoAr, videom, access, videomAr }) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const cardRef = useRef();
  const textRef = useRef();
  const marqRef = useRef();
  const [displayMarq, setDisplayMarq] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [imageLoading, setImageLoading] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [media, setMedia] = useState("");

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    if (cardRef.current && textRef.current) {
      if (cardRef.current.offsetWidth - textRef.current.offsetWidth <= 15) {
        setDisplayMarq(true);
      }
    }
    if (cardRef.current && marqRef.current) {
      if (cardRef.current.offsetWidth - marqRef.current.offsetWidth > 15) {
        setDisplayMarq(false);
      }
    }
  }, [cardRef.current?.offsetWidth, title, textRef.current, width, marqRef.current, location.pathname]);

  useEffect(() => {
    if (location.pathname.includes("images")) {
      setMedia("images");
    } else if (location.pathname.includes("audios")) {
      setMedia("audios");
    } else if (location.pathname.includes("videos")) {
      setMedia("videos");
    }
  }, [location.pathname]);
  return (
    <div
      onMouseOver={() => setHovering(true)}
      onMouseOut={() => setHovering(false)}
      key={index}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className={"col-xl-4 col-lg-4 col-md-6 col-sm-6"}
    >
      <Link style={{ width: "100%", borderRadius: "1.2rem" }} ref={cardRef} to={`/photogen/${media}/${goTo}`} className="">
        {imageLoading && (
          <div className="">
            <Spinner animation="grow" />
          </div>
        )}
        {access.map((item, i) => (
          <span
            key={i}
            style={{
              position: "absolute",
              zIndex: 10,
              background: item === "Pro" ? "#FFC2C2" : item === "Basic" ? "#B6FFAA" : item === "Starter" ? "#FFEDAF" : "#E4EFFF",
              borderRadius: "15px",
              right: lng === "arab" ? null : "35px",
              left: lng === "arab" ? "35px" : null,
              top: "10px",
              color: item === "Pro" ? "#7D0D0D" : item === "Basic" ? "#139A07" : item === "Starter" ? "#9A4E07" : "#07549A",
              fontSize: "0.6rem",
              padding: "3px 10px",
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {item === "Pro" && <Icon />}
            {t(item)}
          </span>
        ))}
        {/* <GifPlayer gif={video}/> */}
        <img
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "1.2rem",
            transition: "transform 0.4s ease-out, height 0.4s ease-out",
            transform: hovering ? "scale(1)" : "scale(0.95)",
          }}
          src={width <= 900 ? (lng === "arab" ? videomAr : videom) : !hovering ? thumb : lng === "arab" ? videoAr || video : video}
          alt={title}
        />

        <div style={{ width: "100%" }} className="cards_mobile">
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: hovering ? "1.2rem" : null,
            }}
            className={`videoBtnEffect`}
          >
            {!displayMarq ? (
              <span>
                <label style={{ whiteSpace: "nowrap" }} ref={textRef}>
                  {title}
                </label>
              </span>
            ) : (
              <div className={lng === "arab" ? "cssmarquee_arab" : "cssmarquee"} style={{ direction: "ltr" }}>
                <Marquee speed={0.025} delay={0} childMargin={15} direction={lng === "arab" ? "right" : "left"} className="label_of_title">
                  <label ref={marqRef}>{title}</label>
                </Marquee>
              </div>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};
