import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { baseUrl } from "api/baseUrl";
import searchIcon from "../assets/img/newDesign/searchIcon.svg";
import { Spinner } from "react-bootstrap";
import WriteCategory from "components/writeCategory/WriteCategory";
import { Link, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import backArow from "../assets/img/newDesign/backArrow.svg";
import arrowForCases from "../assets/img/newDesign/ArrowRight.svg";
import Filter from "components/filter/Filter";
import { useTranslation } from "react-i18next";

const WriteNow = ({ programming }) => {
  const { t } = useTranslation();
  const { name, id } = useParams();
  const [filterMethod, setFilterMethod] = useState(name || "all");
  const [loadingSwitch, setLoadingSwitch] = useState(false);
  const [searchData, setSearchData] = useState("");
  // const [data, setData] = useState();
  const { cases, loading, category, tone, languages, code, catInfo, most } = useSelector((state) => state?.cases);
  const [caregories, setCategories] = useState([]);
  const [mostUsed, setMostUsed] = useState([]);
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;

  useEffect(() => {
    setLoadingSwitch(loading);
    if (programming) {
      setCategories(code);
    } else {
      setCategories(category);
    }
  }, [loading]);
  useEffect(() => {
    if (searchData) {
      let arr = [];
      if (programming) {
        arr = code.filter((item) => item?.name?.toUpperCase()?.includes(searchData.toUpperCase()));
      } else {
        arr = cases.filter(
          (item) =>
            item?.name?.toUpperCase()?.includes(searchData.toUpperCase()) ||
            item?.category_name?.toUpperCase()?.includes(searchData.toUpperCase()) ||
            item?.arabic_name?.includes(searchData) ||
            item?.category_name?.includes(searchData.toUpperCase())
        );
      }
      setCategories(arr);
    } else {
      if (programming) {
        setCategories(code);
      } else {
        let arr = [];
        cases.filter((item) => {
          most?.map((item2) => {
            if (item.key === item2.useCase) {
              arr.push(item);
            }
            setMostUsed(arr);
          });
        });
      }
    }
  }, [cases, searchData, category, location.pathname]);
  // console.log(mostUsed, "mostused")
  return (
    <div style={{ padding: "0px", gap: "0px" }} className="border-radius-top">
      <Filter programming={programming} setSearchData={setSearchData} searchData={searchData} filterMethod={filterMethod} />
      <div style={{ margin: id ? "0px" : null, maxWidth: id ? "unset" : null, padding: "20px", margin: "auto" }} className="write_body container save-10-head">
        <div style={{ paddingBottom: "15px", width: searchData ? "100%" : null }} className="all_images_wrapper row">
          {loadingSwitch ? (
            <Spinner animation="border" role="status" />
          ) : caregories?.length === 0 ? (
            <label>{t("No Items Found")}</label>
          ) : (
            <div style={{ width: "100%" }} className="templete_business">
              {location.pathname.includes("writenow") && mostUsed ? (
                <>
                  <h4 style={{ paddingTop: "0px", marginTop: "0px", direction: lng === "arab" ? "rtl" : "ltr", textAlign: lng === "arab" ? "right" : "left" }}>{t(`Most Used`)}</h4>
                  <div style={{ margin: "0px", justifyContent: "flex-start", direction: lng === "arab" ? "rtl" : "ltr" }} className="busines_mains row">
                    {mostUsed?.map((item, i) => {
                      if (!programming) {
                        return (
                          <div key={i} className="col-lg-4 col-md-6 col-sm-12">
                            <Link style={{ flexDirection: "column", minHeight: "141px" }} to={`/writenow/${item?.category}/${item?.id}`} className="tem_busi minHeightDiff">
                              <div style={{ width: "100%", display: "flex", alignItems: "center", gap: "15px", flexGrow: "1" }}>
                                <div className="busines_icon" style={{ backgroundColor: item.background }}>
                                  <img src={searchData ? item.element_icon : item.element_icon} alt="image" width="100%" />
                                </div>
                                <div style={{ flexGrow: "1" }} className="template_card">
                                  <div className="business_deatils">
                                    <div style={{ flexGrow: "1", display: "flex", flexDirection: "column", gap: "5px" }}>
                                      <h6 style={{ textAlign: lng === "arab" ? "right" : "left" }}>{lng === "arab" ? item.arabic_name : item.name}</h6>
                                      <p style={{ textAlign: lng === "arab" ? "right" : "left" }}>{lng === "arab" ? item?.arabic_description : item.description}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      }
                    })}
                  </div>
                  <h4 style={{ direction: lng === "arab" ? "rtl" : "ltr", textAlign: lng === "arab" ? "right" : "left" }}>{t(`All Categories`)}</h4>
                </>
              ) : null}

              <div style={{ margin: "0px", justifyContent: "flex-start", direction: lng === "arab" ? "rtl" : "ltr" }} className="busines_mains row">
                {caregories.map((item, i) => {
                  if (!programming) {
                    return (
                      <div key={i} className="col-lg-4 col-md-6 col-sm-12">
                        <Link style={{ flexDirection: "column", minHeight: "141px" }} to={`/writenow/${item?.category}/`} className="tem_busi minHeightDiff">
                          <div style={{ width: "100%", display: "flex", alignItems: "center", gap: "15px", flexGrow: "1" }}>
                            <div className="busines_icon" style={{ backgroundColor: item.background }}>
                              <img src={searchData ? item.element_icon : item.category_icon} alt="image" width="100%" />
                            </div>
                            <div style={{ flexGrow: "1" }} className="template_card">
                              <div className="business_deatils">
                                <div style={{ flexGrow: "1", display: "flex", flexDirection: "column", gap: "5px" }}>
                                  <h6 style={{ textAlign: lng === "arab" ? "right" : "left" }}>{searchData ? (lng === "arab" ? item.arabic_name : item.name) : item.category_name}</h6>
                                  <p style={{ textAlign: lng === "arab" ? "right" : "left" }}>{searchData ? (lng === "arab" ? item?.arabic_description : item.description) : item.desc}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%", gap: "10px" }}>
                            <span>
                              {cases?.filter((inner) => inner?.category === item?.category)?.length}{" "}
                              {lng === "arab"
                                ? cases?.filter((inner) => inner?.category === item?.category)?.length === 1
                                  ? "أداه"
                                  : cases?.filter((inner) => inner?.category === item?.category)?.length === 2
                                  ? "أداه"
                                  : cases?.filter((inner) => inner?.category === item?.category)?.length > 2 && cases?.filter((inner) => inner?.category === item?.category)?.length < 10
                                  ? "أدوات"
                                  : "أداه"
                                : "Items"}
                            </span>
                            <img style={{ transform: lng === "arab" ? "rotate(180deg)" : "rotat(0deg)" }} src={arrowForCases} alt="arrow for cases" />
                          </div>
                        </Link>
                      </div>
                    );
                  } else {
                    return (
                      <WriteCategory
                        item={item}
                        programming={programming}
                        asSubIndex={searchData ? true : false}
                        key={i}
                        // title={title}
                        subTitle={"test"}
                        goTo={item?.id || item?.category}
                      />
                    );
                  }
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WriteNow;
