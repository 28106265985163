import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

const ImageGenerated = ({ show, item, i, title, moreLikeThis, userData, isLoading }) => {
  const { t } = useTranslation();
  var FileSaver = require("file-saver");
  async function downloadFile(item, index) {
    FileSaver.saveAs(item, `${userData}_${index}`);
  }
  const [open, setOpen] = useState(false);
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  return (
    <>
      <div key={i} className="image_gen_img_wrapper  col-lg-3 col-md-6 col-sm-11" style={{ cursor: "pointer" }} onClick={() => setOpen(true)}>
        <img src={item} alt="image_generated " />
        <div className="image_generation_options">
          <p>{`${title?.slice(0, 50)} ${i + 1}`}</p>
          <div className="options_btn_wrapper">
            {title && !show && <button onClick={() => moreLikeThis(title, item)}>{t("More Like This")}</button>}
            <button
              onClick={(e) => {
                e.stopPropagation();
                downloadFile(item, i);
              }}
            >
              {t("Download")}
            </button>
          </div>
        </div>
      </div>
      {width > 991 && (
        <Modal show={open} onHide={() => setOpen(false)} size="lg" centered>
          <div style={{ position: "relative", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "60%" }}>
            <span className="close_button" onClick={() => setOpen(false)} style={{ left: lng === "arab" ? "15px" : null, right: lng === "arab" ? null : "15px", top: "15px" }}>
              <CloseRoundedIcon sx={{ fontSize: "20px" }} />
            </span>
            <span className="close_button" onClick={() => downloadFile(item, i)} style={{ left: lng === "arab" ? "45px" : null, right: lng === "arab" ? null : "45px", top: "15px" }}>
              <DownloadRoundedIcon sx={{ fontSize: "20px" }} />
            </span>
            <img
              style={{
                borderRadius: "20px",
                width: "100%",
                maxHeight: "500px",
                objectFit: "cover",
              }}
              src={item}
              alt="image_generated"
              className="modal_image"
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default ImageGenerated;
