import { FETCH_IMAGES } from "redux/contstants";
import { LOAD_IMAGES } from "redux/contstants";
import { IMAGES_LOADED } from "redux/contstants";
import { FETCH_MORE_IMAGES } from "redux/contstants";
import { EMPTY_MORE_LIKE_THIS } from "redux/contstants";
import { EMPTY_IMAGE } from "redux/contstants";
import { FETCH_IMAGE_DESIGN } from "redux/contstants";
import { LOAD_IMAGE_DESIGN } from "redux/contstants";
import { IMAGE_DESIGN_LOADED } from "redux/contstants";
import { EMPTY_IMAGE_DESIGN } from "redux/contstants";



export default (image = { images: [], title: '', loading: false, imageLoading: false, morelikeThis: [], imageDesign: [] }, action) => {
    switch (action.type) {
        case FETCH_IMAGES:
            return { ...image, images: action.data?.urls, title: action?.data?.prompt, loading: false };
        case LOAD_IMAGES:
            return { ...image, loading: true };
        case IMAGES_LOADED:
            return { ...image, loading: false };
        case FETCH_MORE_IMAGES:
            return { ...image, morelikeThis: action.data?.urls, title: action?.data?.prompt, loading: false };
        case LOAD_IMAGE_DESIGN:
            return { ...image, imageLoading: true };
        case IMAGE_DESIGN_LOADED:
            return { ...image, imageLoading: false };
        case FETCH_IMAGE_DESIGN:
            return { ...image, imageDesign: action.data?.urls, title: action?.data?.prompt, loading: false };
        case EMPTY_IMAGE_DESIGN:
            return { imageDesign: [] };
        case EMPTY_MORE_LIKE_THIS:
            return { morelikeThis: [] }
        case EMPTY_IMAGE:
            return { images: [] }
        default:
            return image;
    }
}
