import moment from "moment";
import React from "react";
import "./rev-profile.css";
import { useTranslation } from "react-i18next";

const RevProfileDetails = ({ user }) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  function contact(email) {
    if (email != null) {
      if (email.includes("+") == true) {
        return email.split("@")[0];
      } else {
        return email;
      }
    } else {
      return "-";
    }
  }

  return (
    <div
      className="row justify-content-start profile-width"
      style={{ marginBottom: 60, }}
    >
      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
        <div className="profile-label">{t("Name")}:</div>
        <div className="profile-desc">{user?.name || "-"}</div>

        <hr />
        <div className="profile-label">{t("Email")}:</div>
        <div style={{ direction: "ltr" }} className="profile-desc">
          {contact(user?.email) ?? "-"}
        </div>
        <hr />
      </div>
      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
        <div className="profile-label">{t("Date of Birth")}:</div>
        <div className="profile-desc">
          {user?.date_of_birth
            ? moment(user?.date_of_birth)?.format("YYYY-MM-DD")
            : "-"}
        </div>
        <hr />
        <div className="profile-label">{t("Address")}:</div>
        <div className="profile-desc">{user?.country || "-"}</div>
        <hr />
      </div>
      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
        <div className="profile-label">{t("Phone Number")}:</div>
        <div style={{ direction: "ltr" }} className="profile-desc">
          {contact(user?.phone_number) ?? "-"}
        </div>
        <hr />
      </div>
    </div>
  );
};

export default RevProfileDetails;
