import React, { Component, useEffect, useRef, useState } from "react";
import { useLocation, NavLink, Link, useNavigate } from "react-router-dom";
import { Nav, Spinner } from "react-bootstrap";
import logos from "../../assets/img/newDesign/newLogo.png";
import logo from "assets/img/newDesign/dashboardLogo.svg";
import profileDefault from "assets/img/newDesign/profileDefault.png";
import { connect, useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import arabygpt from "assets/img/newDesign/gptSideBarHover.svg";
import chatIcon from "assets/img/newDesign/chat.png";
import deleteIcon from "assets/img/newDesign/delete.png";
import editIcon from "assets/img/newDesign/editPen.png";
import arabygptHover from "assets/img/newDesign/arabyGPHovering.svg";
import axios from "axios";
import { baseUrl } from "api/baseUrl";
import { toastAlert } from "components/toastAlert/toastAlert";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { socket } from "../../socket.js";
import { dashboardRoutes, blogMainRoutes, enterpriseNormalRoutes } from "routes.js";

function Sidebar({ routes }) {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { chatHistory, loading } = useSelector((state) => state.chatHistory);
  const gptChatID = useSelector((state) => state.arabyGPT.chatID);
  const isStreaming = useSelector((state) => state.arabyGPT.isStreaming);
  const isOpen = useSelector((state) => state.sidebar);
  const location = useLocation();
  const dispatch = useDispatch();
  const imageRef = useRef();
  const navigate = useNavigate();
  const [editingTheName, setEditingTheName] = useState(false);
  const [chatName, setChatName] = useState("");
  const [selectedElementToEdit, setSelectedElementToEdit] = useState("");
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : routeName.split("/").includes("business-tools") && location.pathname.split("/").includes("business-tools") ? "active" : "";
  };
  const user = useSelector((state) => state.user);
  const userSubscription = useSelector((state) => state.userSubscription);
  const userEnterprise = useSelector((state) => state.enterprise);
  const handleImgError = () => {
    if (imageRef.current) {
      imageRef.current.src = "https://riverlegacy.org/wp-content/uploads/2021/07/blank-profile-photo.jpeg";
    }
  };
  const deleteChat = (item) => {
    if (isStreaming) {
      return toastAlert("You can not delete a chat while displaying a message", undefined, "warning");
    }
    dispatch({ type: "DELETING" });
    axios
      .delete(`${baseUrl}chat/delete/${item}`)
      .then((response) => {
        dispatch({ type: "DELETED_ELEMENT", data: item });
        if (gptChatID === item) {
          navigate("/twwtgpt");
        }
        socket.disconnect();
        socket.connect();
      })
      .catch((error) => {
        dispatch({ type: "DELETED" });
        toastAlert("Failed to delete the chat", undefined, "warning");
      });
  };
  const updateName = (e) => {
    e.preventDefault();
    if (isStreaming) {
      return toastAlert("You can not update a chat name while displaying a message", undefined, "warning");
    }
    setEditingTheName(true);
    var config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${baseUrl}update_chat_title/${selectedElementToEdit}`,
      headers: {
        Authorization: localStorage.getItem("sess"),
      },
      data: { title: chatName },
    };
    axios(config)
      .then(function (response) {
        setEditingTheName(false);
        setSelectedElementToEdit("");
        setChatName("");
        socket.disconnect();
        socket.connect();
      })
      .catch(function (error) {
        setEditingTheName(false);
        setSelectedElementToEdit("");
        toastAlert("Failed to change the chat name", undefined, "warning");
      });
  };
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSelectedElementToEdit("");
          setChatName("");
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const [fullNewRoutes, setFullNewRoutes] = useState([]);
  useEffect(() => {
    let fullArray = [];
    fullArray.push(...dashboardRoutes);
    if (user?.email === "saleemdayazada@gmail.com") {
      fullArray.push(...blogMainRoutes);
    }
    setFullNewRoutes(fullArray.sort((p1, p2) => (p1.id < p2.id ? 1 : p1.id > p2.id ? -1 : 0)).reverse());
  }, [user, userEnterprise]);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  useEffect(() => {
    dispatch({ type: "CLOSE_SIDEBAR" });
  }, [location.pathname]);
  return (
    <div
      className="sidebar"
      style={{
        transform:
          lng === "arab"
            ? location.pathname.split("/").includes("document")
              ? width < 991
                ? isOpen?.sidebarIsOpen
                  ? "translate3d(250px, 0, 0)"
                  : "translate3d(0px, 0, 0)"
                : isOpen?.sidebarIsOpen
                ? "translate3d(0px, 0, 0)"
                : "translate3d(250px, 0, 0)"
              : null
            : location.pathname.split("/").includes("document")
            ? width < 991
              ? isOpen?.sidebarIsOpen
                ? "translate3d(-250px, 0, 0)"
                : "translate3d(0px, 0, 0)"
              : isOpen?.sidebarIsOpen
              ? "translate3d(0px, 0, 0)"
              : "translate3d(-250px, 0, 0)"
            : null,
      }}
    >
      <div className="sidebar-background" />
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-start">
          <Link
            onClick={() => {
              document.documentElement.classList.toggle("nav-open");
            }}
            style={{ alignSelf: "flex-center", width: "100%" }}
            to="/"
            className="simple-text logo-mini mx-1"
          >
            <div className="logo-img">
              <img src={logos} alt="..." />
              <label style={{ cursor: "pointer" }}>Twwt AI</label>
            </div>
          </Link>
          <Link
            onClick={() => {
              document.documentElement.classList.toggle("nav-open");
            }}
            to={"/profile"}
            className="user_card"
          >
            <img
              ref={imageRef}
              onError={handleImgError}
              src={user.image != null ? user.image : "https://riverlegacy.org/wp-content/uploads/2021/07/blank-profile-photo.jpeg"}
              alt="user_profile_image"
              className="user_profile_image"
            />
            <div style={{ display: "flex", flexDirection: "column", position: "relative", flexGrow: "1" }}>
              <label style={{ textAlign: lng === "arab" ? "right" : "left", marginBottom: "4px" }} className="userName">
                {user.name?.slice(0, 7) ?? t("New Member")}
              </label>
              <p
                style={{
                  textAlign: lng === "arab" ? "right" : "left",
                  margin: "0px",
                  color: "#FFD200",
                  fontSize: "11px",
                  position: "absolute",
                  left: "0px",
                  right: "0px",
                  bottom: "-12px",
                  width: "100%",
                }}
              >
                {user?.enterprise ? t("Business Account") + "  " + "💼" : ""}{" "}
              </p>
            </div>

            {/* <Link to={'/profile'} style={{ color: "black", }} onClick={() => {
              document.documentElement.classList.toggle("nav-open");
            }}>
              <span style={{ fontSize: '15px' }}>{t("View Profile")}</span>
            </Link> */}
          </Link>
        </div>
        <div style={{ display: "flex", flexDirection: "column", flexGrow: "1", justifyContent: "space-between" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Nav>
              {fullNewRoutes
                ?.filter((item) => !item?.status)
                ?.map((prop, key) => {
                  if (!prop.redirect)
                    return (
                      <>
                        {
                          <li style={{ position: "relative" }} className={activeRoute(prop.path)} key={key}>
                            <NavLink
                              style={{ position: "relative" }}
                              to={prop.path}
                              onClick={() => {
                                if (location.pathname.includes("document")) {
                                  isOpen?.sidebarIsOpen ? dispatch({ type: "OPEN_SIDEBAR" }) : dispatch({ type: "CLOSE_SIDEBAR" });
                                }
                                document.documentElement.classList.toggle("nav-open");
                              }}
                              className="nav-link"
                            >
                              {prop.icon && prop.hicon ? <img style={{ marginTop: "4px" }} src={activeRoute(prop.path) ? prop.hicon : prop.icon} alt="nav_icon" /> : null}
                              <p style={{ display: "flex", alignItems: "center", gap: "10px" }} className={activeRoute(prop.path)}>
                                {t(prop.name) + " " + (prop?.id === 2 ? "⚡️" : "")}
                                {prop?.id === 2 ? <label className="labelBeta">{t("Beta")}</label> : null}
                                {prop?.id === 20 ? <label className="labelBeta">{t("New")}</label> : null}
                              </p>
                            </NavLink>
                            {location.pathname === prop.path || (prop.path.split("/").includes("business-tools") && location.pathname.split("/").includes("business-tools")) ? (
                              <div
                                style={
                                  lng === "arab"
                                    ? { left: "auto", right: "0px", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px" }
                                    : { left: "0px", right: "auto", borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }
                                }
                                className="sideMenuAction"
                              />
                            ) : null}
                          </li>
                        }
                      </>
                    );
                  return null;
                })}
            </Nav>
            <Link
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
              }}
              className="gpt_Menu_BTN"
              to="/twwtgpt"
            >
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "5px" }}>
                <img src={arabygpt} alt="nav_icon" />
                <p>TWWTGPT</p>
              </div>

              <label>{t("New")}</label>
            </Link>
            {location.pathname.split("/").includes("twwtgpt") ? (
              <>
                {loading && !chatHistory?.length ? (
                  <div className="gpt_history_wrapper">
                    <SkeletonTheme>
                      {[1, 2, 3]?.map((outer) => (
                        <p
                          key={outer}
                          style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", gap: "10px", direction: "rtl", marginBottom: "0px", padding: "5px 15px" }}
                        >
                          <div style={{ flexGrow: "1", width: "100%", textAlign: lng === "arab" ? "right" : "left" }}>
                            <Skeleton variant="text" baseColor="#B6D6FF" highlightColor="#5b8bc9" count={1} height={10} width="80%" />
                          </div>
                          <div>
                            <Skeleton animation="wave" baseColor="#B6D6FF" highlightColor="#5b8bc9" variant="rectangular" width={15} height={15} />
                          </div>
                        </p>
                      ))}
                    </SkeletonTheme>
                  </div>
                ) : chatHistory?.length ? (
                  <div className="gpt_history_wrapper">
                    {chatHistory
                      ?.slice(0)
                      ?.reverse()
                      ?.map((item, i) => (
                        <div key={i} style={{ position: "relative" }} className={gptChatID === item?.ref_id ? "gpt_element_active" : "gpt_element"}>
                          <Link onDoubleClick={() => setSelectedElementToEdit(item?.ref_id)} className="chat_link" to={`/twwtgpt/${item?.ref_id}`} key={i} style={{ width: "200px" }}>
                            <img src={chatIcon} alt="chat_icon" />
                            {selectedElementToEdit === item?.ref_id ? (
                              editingTheName ? (
                                <p
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    gap: "10px",
                                    direction: "rtl",
                                    marginBottom: "0px",
                                    padding: "5px 15px",
                                  }}
                                >
                                  <SkeletonTheme>
                                    <div>
                                      <Skeleton animation="wave" baseColor="#B6D6FF" highlightColor="#5b8bc9" variant="rectangular" width={15} height={15} />
                                    </div>
                                  </SkeletonTheme>
                                </p>
                              ) : (
                                <form onSubmit={updateName} style={{ marginBottom: "0px" }}>
                                  <input
                                    ref={wrapperRef}
                                    autoFocus
                                    value={chatName}
                                    onChange={(e) => setChatName(e.target.value)}
                                    style={{ backgroundColor: "transparent", border: "none", background: "transparent", caretColor: "#fff", color: "#fff", marginBottom: "0px" }}
                                    type="text"
                                  />
                                </form>
                              )
                            ) : (
                              <p style={{ fontSize: lng === "arab" ? "14.8px" : "14px", padding: lng === "arab" ? "0 5px 0 0" : " 0 0 0 3px" }}>
                                {`${t(item?.title ?? "").length >= 15 ? item?.title + "..." : item?.title}`}
                              </p>
                            )}
                          </Link>
                          <img
                            onClick={() => deleteChat(item?.ref_id)}
                            style={{ position: "absolute", left: lng === "arab" ? "3px" : "auto", right: lng === "arab" ? "auto" : "3px" }}
                            src={deleteIcon}
                            className="delete_icon"
                            alt="chat_icon"
                          />
                          <img
                            onClick={() => {
                              setChatName("");
                              setSelectedElementToEdit(item?.ref_id);
                            }}
                            style={{ position: "absolute", left: lng === "arab" ? "26px" : "auto", right: lng === "arab" ? "auto" : "25px" }}
                            src={editIcon}
                            className="delete_icon"
                            alt="chat_icon"
                          />
                        </div>
                      ))}
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {location.pathname.split("/").includes("twwtgpt") ? null : (
              <div className="upgrade_sideMenu">
                <label style={{ display: "flex", flexDirection: lng === "arab" ? "row-reverse" : "row", gap: "4px", marginBottom: user.plan !== "Pro" ? "2px" : "none" }}>
                  <span>{`${t(userSubscription?.product?.name ?? user?.plan) ?? t("Free Trial")} `}</span>
                  <span>{t("Plan")}</span>
                </label>
                {userSubscription?.product?.name !== "Pro" && !user?.enterprise && (
                  <Link to="/plan" style={{ marginBottom: "4px" }} className="navbar_button2" tabIndex={-1}>
                    {t("Upgrade Plan")}
                  </Link>
                )}
              </div>
            )}
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px", gap: "10px" }}>
              <div style={{ height: "1px", flexGrow: "1", backgroundColor: "#80808073", borderRadius: "5px" }} />
              <Link to="/contactus" style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", color: "gray", fontSize: "15px", marginBottom: "1px" }}>
                {t("Need Help?")}
              </Link>
              <div style={{ height: "1px", flexGrow: "1", backgroundColor: "#80808073", borderRadius: "5px" }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
