import { UPLOADED_UPSCALE } from "redux/contstants";
import { FETCH_UPSCALE, LOAD_UPSCALE, LOADED_UPSCALE, NEW_UPSCALE, SHOW_UPSCALE_FALSE, SHOW_UPSCALE_TRUE, LOAD_PERCENTAGE_UPSCALE } from "redux/contstants";

export default (upscale = { upscale: [], uploaded: [], loading: false, loadingNumber: 0, showRemover: false }, action) => {
  switch (action.type) {
    case LOAD_UPSCALE:
      return { ...upscale, uploaded: action.data, loadingNumber: 0, loading: true, showRemover: true };
    case FETCH_UPSCALE:
      return { ...upscale, upscale: action.data, loadingNumber: 0 };
    case LOADED_UPSCALE:
      return { ...upscale, loading: false, uploaded: [] };
    case NEW_UPSCALE:
      return { ...upscale, upscale: [], uploaded: [], loadingNumber: 0, showRemover: false };
    case SHOW_UPSCALE_FALSE:
      return { ...upscale, showRemover: false };
    case SHOW_UPSCALE_TRUE:
      return { ...upscale, showRemover: true };
    case UPLOADED_UPSCALE:
      return { ...upscale, uploaded: action.data };
    case LOAD_PERCENTAGE_UPSCALE:
      return { ...upscale, loadingNumber: action.data };
    default:
      return upscale;
  }
};
