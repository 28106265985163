import {
    FETCH_TEXT_AUDIO,
    LOAD_TEXT_AUDIO,
    LOADED_TEXT_AUDIO,
    NEW_TEXT_AUDIO,
    SHOW_TEXT_AUDIO_FALSE,
    SHOW_TEXT_AUDIO_TRUE

} from "redux/contstants";

export default (textToAudio = { textToAudio: [], uploaded: [], loading: false, showRemover: false }, action) => {
    switch (action.type) {
        case LOAD_TEXT_AUDIO:
            return { ...textToAudio, loading: true, showRemover: true };
        case FETCH_TEXT_AUDIO:
            return { ...textToAudio, textToAudio: action.data };
        case LOADED_TEXT_AUDIO:
            return { ...textToAudio, loading: false };
        case NEW_TEXT_AUDIO:
            return { ...textToAudio, textToAudio: [], uploaded: [], showRemover: false };
        case SHOW_TEXT_AUDIO_FALSE:
            return { ...textToAudio, showRemover: false };
        case SHOW_TEXT_AUDIO_TRUE:
            return { ...textToAudio, showRemover: true };
        default:
            return textToAudio;
    }
};