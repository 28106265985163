import React from "react";
import Form from "react-bootstrap/Form";
import { useState, useRef } from "react";
import axios from "axios";

import { useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { toastAlert } from "components/toastAlert/toastAlert";
import { useForm, Controller } from "react-hook-form";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";

const RevProfileForm = ({ onClickCallback }) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const user = useSelector((state) => state.user);
  const [userForm, setUserForm] = useState(user);
  const currentYear = new Date().getFullYear(); // 2020
  const previousYear = currentYear - 1;

  function contact(email) {
    if (email != null) {
      if (email.includes("+") == true) {
        return email.split("@")[0];
      } else {
        return email;
      }
    } else {
      return "-";
    }
  }

  function dob(dob) {
    if (dob != null) {
      return dob;
    } else {
      return dob;
      // return dob.split('T')[0]
    }
  }

  function onChange(e) {
    // if target name and value doesnt exist add it to user form
    if (moment(userForm?.date_of_birth)?.format("yyyy") > previousYear) {
      toastAlert("Invalid date");
    }
    onClickCallback({ ...userForm, [e.target.name]: e.target.value });
    setUserForm({ ...userForm, [e.target.name]: e.target.value });
  }

  const onChangePhone = (value) => {
    onClickCallback({ ...userForm, phone_number: value });
    setUserForm({ ...userForm, phone_number: value });
  };

  const handleValidate = (value) => {
    const isValid = isPossiblePhoneNumber(value);
    return { isValid };
  };

  return (
    <div style={{ marginBottom: 25 }}>
      <Form className="row justify-content-start">
        <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6 col-12">
          <Form.Group className="mb-3" controlId="formGroupName">
            <Form.Label
              contr
              style={{
                textAlign: lng === "arab" ? "right" : "left",
                width: "100%",
              }}
            >
              {t("Name")}
            </Form.Label>
            <Form.Control
              onChange={onChange}
              type="text"
              name="name"
              placeholder={t("Enter your name")}
              value={userForm.name}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label
              style={{
                textAlign: lng === "arab" ? "right" : "left",
                width: "100%",
              }}
            >
              {t("Email")}
            </Form.Label>
            <Form.Control
              onChange={onChange}
              disabled
              style={{ direction: "ltr" }}
              type="email"
              placeholder={t("Enter your email")}
              name="email"
              value={contact(userForm.email)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formGroupContact">
            <Form.Label
              style={{
                textAlign: lng === "arab" ? "right" : "left",
                width: "100%",
              }}
            >
              {t("Phone Number")}
            </Form.Label>
            <div
              style={{
                backgroundColor: "#FFFFFF",
                border: "1px solid #E3E3E3",
                borderRadius: "4px",
                color: "#565656",
                padding: "8px 12px",
                height: "40px",
                boxShadow: "none",
                direction: "ltr",
              }}
            >
              <PhoneInput
                id="phone_number"
                onChange={onChangePhone}
                inputProps={{
                  name: "phone_number",
                  // required: true,
                }}
                defaultCountry={"AE"}
                limitMaxLength
                placeholder="Enter your phone number"
                value={userForm.phone_number}
              />
            </div>
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="formGroupContact">
                        <Form.Label style={{ textAlign: lng === 'arab' ? 'right' : 'left', width: '100%' }}>{t("Phone Number")}</Form.Label> */}
          {/* <Form.Control
                            onChange={(e) => onChange(e)}
                            style={{ direction: 'ltr' }}
                            type="tel"
                            placeholder={t("Enter your phone number")}
                            name="phone_number"
                            value={contact(userForm.phone_number)}
                        /> */}
          {/* </Form.Group> */}
          {/* <Form.Group className="mb-3" controlId="formGroupWork">
                        <Form.Label style={{ textAlign: lng === 'arab' ? 'right' : 'left', width: '100%' }}>{t("Occupation")}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={onChange}
                            placeholder={t("Occupation")}
                            name="occupation"
                            value={userForm.occupation}
                        />
                    </Form.Group> */}
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
          <Form.Group className="mb-3" controlId="formGroupDOB">
            <Form.Label
              style={{
                textAlign: lng === "arab" ? "right" : "left",
                width: "100%",
              }}
            >
              {t("Date of Birth")}
            </Form.Label>
            <Form.Control
              onChange={onChange}
              type="date"
              placeholder={t("Date of Birth")}
              name="date_of_birth"
              value={
                moment(userForm?.date_of_birth)?.format("YYYY") <=
                  previousYear &&
                moment(userForm?.date_of_birth)?.format("YYYY-MM-DD")
              }
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formGroupAddress">
            <Form.Label
              style={{
                textAlign: lng === "arab" ? "right" : "left",
                width: "100%",
              }}
            >
              {t("Country")}
            </Form.Label>
            <Form.Control
              onChange={onChange}
              type="text"
              placeholder={t("Country")}
              name="country"
              value={userForm.country}
            />
          </Form.Group>
        </div>
      </Form>
      <input type="file" id="file" style={{ display: "none" }} />
    </div>
  );
};

export default RevProfileForm;
