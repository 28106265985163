import axios from "axios";
import SuccessModal from "components/SuccessModal/SuccessModal";
import { baseUrl } from "api/baseUrl";
import { toastAlert } from "components/toastAlert/toastAlert";
import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import checkErr from "redux/actions/api_error_func";
import { useDispatch, useSelector } from "react-redux";
import "./rev-profile.css";
import { getUser } from "redux/actions/user";
import { getUserSubscription } from "redux/actions/user_subscription";

const RevProfilePlan = ({ user, userSubscription, userEnterprise }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [getUserData, setGetUserData] = useState([]);
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const dispatch = useDispatch();
  const addCommas = (item) => {
    return item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const navigateToPayment = () => {
    navigate("/paymentgateway");
  };
  const getUser = async () => {
    let response = await axios.get(`${baseUrl}subscription/get/user`).then((res) => {
      setGetUserData(res?.data?.subscription);
    });
  };
  useEffect(() => {
    getUser();
  }, []);

  const handleManagePlan = () => {
    // if(user?.plan === "Trial"){
    //   return navigate("/plan");
    // }
    setShowModal(true);
    var data = {
      return_url: window.location.href,
    };
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}stripe/portal/create`,
      headers: {
        Authorization: localStorage.getItem("sess"),
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        setShowModal(false);
        window.open(response.data?.portal?.url, "_blank");
      })
      .catch(function (error) {
        checkErr(error, setShowModal, toastAlert);
      });
  };
  const leaveOrg = () => {
    setShowModal(true);
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_ENTERPRISE_URL}enterprise/leave_org`,
      headers: {
        Authorization: localStorage.getItem("sess"),
      },
    };

    axios(config)
      .then(async function (response) {
        setShowModal(false);
        await dispatch(getUser("enterprise"));
        await dispatch(getUserSubscription());
        toastAlert("Done!", undefined, "success");
      })
      .catch(function (error) {
        setShowModal(false);
        checkErr(error, setShowModal, toastAlert);
      });
  };

  return (
    <>
      <div className="col-12 plan-head">
        <h3 className="title myplan-title">{t("My Plan")}</h3>
      </div>
      <hr className="hrplan" />
      <div className="plan-content">
        <div
          className="col-6"
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
            alignItems: "flex-start",
          }}
        >
          <h4
            style={{
              display: "flex",
              flexDirection: "row",
              direction: lng === "arab" ? "rtl" : "ltr",
              gap: "4px",
              margin: "0 0 10px 0",
              flexDirection: lng === "arab" ? "row-reverse" : null,
            }}
          >
            {/* <span>{`${t(user.plan) ?? t("Free Trial")} `}</span> */}
            <span>{user?.plan ? `${t(user?.plan)}` : t("Free Trial")}</span>
            {user?.plan ? <span>{t("Plan")}</span> : null}
          </h4>
          {userSubscription?.interval && (
            <h5
              style={{
                direction: lng === "arab" ? "rtl" : "ltr",
                margin: "0 0 10px 0",
              }}
            >
              {userSubscription?.interval === "month" ? `$${userSubscription?.amount / 100 + " " + t("Monthly")} ` : `$${userSubscription?.amount / 100 + " " + t("Yearly")}`}
            </h5>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <h5
            style={{
              marginTop: 8,
              textAlign: "right",
              fontSize: "15px",
              fontWeight: "700",
            }}
          >
            {`${user?.text_count_left !== undefined ? addCommas(user?.text_count_left).toString() : "0"}  
           ${user?.plan === "Trial" ? (user?.text_count_left > 1 ? t("Words") : t("Attempts")) : t("Words")}${t(",")} `}
          </h5>
          <h5
            style={{
              marginTop: 8,
              textAlign: "right",
              fontSize: "15px",
              fontWeight: "700",
            }}
          >
            {`${user?.image_count_left !== undefined ? addCommas(user?.image_count_left) : "0"}  ${user?.plan === "Trial" ? (user?.image_count_left > 1 ? t("Images") : t("Attempts")) : t("Images")}`}
          </h5>

          {user?.enterprise && user.plan != "Freelancer" ? (
            <Link
              onClick={leaveOrg}
              to="#"
              className="manage-plan-link"
              style={{
                marginRight: lng === "arab" ? "auto" : "0",
                marginLeft: lng === "arab" ? "0" : "auto",
              }}
            >
              <h5 className="upgrade-plan manage-plan">{t("Leave Organisation")}</h5>
            </Link>
          ) : (
            <div
              // onClick={handleManagePlan}
              onClick={() => {
                getUserData?.last_transaction_id?.paymentGate == "hyperpay" || getUserData?.last_transaction_id?.paymentGate == "coinbase" || getUserData?.last_transaction_id?.paymentGate == "paypal"
                  ? navigateToPayment()
                  : handleManagePlan();
              }}
              className="manage-plan-link"
              style={{
                marginRight: lng === "arab" ? "auto" : "0",
                marginLeft: lng === "arab" ? "0" : "auto",
                cursor: "pointer",
              }}
            >
              <h5 className="upgrade-plan manage-plan">{t("Manage Plan")}</h5>
            </div>
          )}
        </div>
        <CancelModal setShowModal={setShowModal} showModal={showModal} backdrop="static" />
      </div>
    </>
  );
};

export default RevProfilePlan;

const CancelModal = (props) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;

  return (
    <Modal className={lng === "arab" ? "arabicFont loadingModal" : "englishFont loadingModal"} centered size="lg" backdrop="static" show={props.showModal} onHide={() => props.setShowModal(false)}>
      <Modal.Body
        className="text-center"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "unset",
          backgroundColor: "transparent",
        }}
      >
        <Spinner variant="light" />
      </Modal.Body>
    </Modal>
  );
};
