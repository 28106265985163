import React from 'react'
import styled from "styled-components";

const Wrapper = styled.div`
   display: flex;
   gap: 0.5rem;
   align-items: center;
`;

const CustomRadioBtn = (props) => {
    return (
        <Wrapper>
            <form>
                <fieldset>
                    <input
                        type="radio"
                        id={props.id}
                        value={props.value}
                        checked={props.default}
                        onChange={props.onChange}
                    />
                    <label
                        htmlFor={props.id}
                        style={{
                            fontSize: '1rem',
                            fontWeight: 600,
                            margin: 10,
                        }}
                    >{props.label}</label>

                </fieldset>
            </form>
        </Wrapper>
    )
}

export default CustomRadioBtn