import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import { useDispatch, useSelector } from "react-redux";
import UpgradeModal from "components/UpgradeModal/UpgradeModal";
import { toastAlert } from "components/toastAlert/toastAlert";
import { Spinner } from "react-bootstrap";
import arabygptHover from "../assets/img/newDesign/newLogo.png";
import creativeContent from "../assets/img/newDesign/creativeContent.png";
import draw from "../assets/img/newDesign/draw.png";
import coding from "../assets/img/newDesign/coding.png";
import thinking from "../assets/img/newDesign/thinking.png";
import arrow from "../assets/img/newDesign/Vector.svg";
import { socket } from "../socket.js";
import remove from "../assets/img/newDesign/removeHistory.svg";
import gptDarker from "../assets/img/newDesign/GPTDark.png";
import regenerate from "../assets/img/newDesign/restart.png";
import stopbutton from "../assets/img/newDesign/stopbutton.png";
import "./arabychat.scss";
import LimitPopUp from "./plansComponent/LimitPopUp";
import CodeEditor from "@uiw/react-textarea-code-editor";
import ReactMarkdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import CopyToClipboard from "react-copy-to-clipboard";
import { detect } from "program-language-detector";
import useId from "react-use-uuid";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "api/baseUrl";
import LoadingPopUp from "components/popUps/LoadingPopUp";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import plansLimits from "assets/data/plansLimits";
import ClaimFreeModal from "components/ClaimFreeModal/ClaimFreeModal";
import remarkGfm from "remark-gfm";
import { getUser } from "redux/actions/user";
import { USER } from "redux/contstants";
import { useParams } from "react-router-dom";

const ArabyGPT = () => {
  //Main Tools
  const gptHistory = useSelector((state) => state.arabyGPT);
  const gptChatID = useSelector((state) => state.arabyGPT.chatID);
  const gptChatTitle = useSelector((state) => state.arabyGPT.title);
  const newChatHolder = useSelector((state) => state.newChatHolder);
  const { chatHistory, loading } = useSelector((state) => state.chatHistory);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const search = window?.location?.search;
  const { id } = useParams();
  const generatedID = useId();
  const [gettingChat, setGettingChat] = useState(false);
  const [screenLoader, setScreenLoader] = useState(true);
  const [inputCount, setInputCount] = useState(0);
  const [inputCountContainer, setInputCountContainer] = useState(true);
  useEffect(() => {
    setScreenLoader(id);
  }, [id]);
  const bigLoading = useSelector((state) => state.loading);
  //istalling the socket
  const [isConnected, setIsConnected] = useState(socket.connected);
  useEffect(() => {
    socket.connect();
    return () => {
      socket.disconnect();
    };
  }, [location.pathname, id]);
  useEffect(() => {
    let token = { token: localStorage.getItem("sess") };
    if (socket.connected) {
      dispatch({ type: "LOAD_ALL_CHAT_HISTORY" });
      socket.emit("token", token);
    }
  }, [socket.connected]);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }
    function onDisconnect() {
      setInnerLoadingSwitch(false);
      setIsLoading(false);
      setIsConnected(false);
    }
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
  }, []);
  //---------------------------------------------------------------
  //getting old messages
  const gettingMessages = async (id) => {
    return await axios
      .get(`${baseUrl}get_chat_history/${id}`)
      .then((response) => {
        let arr = response?.data?.data?.map((item) => {
          if (item?.role === "user") {
            return { role: item?.role, message: item?.message, ref_id: item?.ref_id, message_id: "" };
          } else if (item?.message && item?.urls?.length === 0) {
            return { role: "assistant", is_image: false, message: item?.message, ref_id: item?.ref_id, message_id: "" };
          } else {
            return { role: "assistant", is_image: true, message: "", urls: item?.urls, ref_id: item?.ref_id, message_id: "" };
          }
        });
        dispatch({ type: "ADD_CHAT_ID", data: { id: id, chat: arr, title: chatHistory?.filter((item) => item?.ref_id === id)?.title } });
        setScreenLoader(false);
      })
      .catch((error) => {
        toastAlert("Failed to load messages", undefined, "warning");
        dispatch({ type: "ADD_CHAT_ID", data: { id: id, chat: [], title: chatHistory?.filter((item) => item?.ref_id === id)?.title } });
        setScreenLoader(false);
      });
  };

  //---------------------------------------------------------------
  //Managing display, which chat and initializing the redux
  useEffect(() => {
    if (!loading) {
      if (gptChatID !== id) {
        setScreenLoader(true);
        if (id) {
          if (chatHistory?.filter((item) => item?.ref_id === id)?.length) {
            gettingMessages(id);
            dispatch({ type: "DONE_STREAMING" });
            setStopGeneratingEvent(false);
            setScreenLoader(false);
          } else {
            dispatch({ type: "CLEAR_WHOLE_CHAT" });
            dispatch({ type: "DONE_STREAMING" });
            setStopGeneratingEvent(false);
            if (newChatHolder?.chatsObj?.ref_id === id && newChatHolder?.status === "pending") {
              if (isConnected) {
                setStopGeneratingEvent(false);
                if (user.text_count_left === 0) {
                  return setShowModal(true);
                } else {
                  setInnerLoadingSwitch(true);
                  dispatch({ type: "STREAMING" });
                  dispatch({ type: "ADD_CHAT_ID", data: { id: id, chat: [], title: "" } });
                  dispatch({
                    type: "ADD_MESSAGE",
                    data: { role: newChatHolder?.chatsObj?.role, message: newChatHolder?.chatsObj?.message, ref_id: newChatHolder?.chatsObj?.ref_id, message_id: newChatHolder?.chatsObj?.message_id },
                  });
                  socket.emit("request", newChatHolder?.chatsObj, () => { });
                  dispatch({ type: "DONE_FETCHING" });
                  setInputValue("");
                }
              }
            } else {
              setStopGeneratingEvent(false);
              dispatch({ type: "DONE_STREAMING" });
              navigate("/twwtgpt");
            }
            setScreenLoader(false);
          }
        } else {
          navigate("/twwtgpt");
        }
      }
    }
  }, [id, location, loading, newChatHolder, isConnected]);

  //---------------------------------------------------------------
  //language Tools
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;

  //---------------------------------------------------------------
  //user, and user plan
  const [wordLimit, setWordLimit] = useState(0);
  const [totalWords, setTotalWords] = useState(0);
  const [imageLimit, setImageLimit] = useState(0);
  const [totalImages, setTotalImages] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const user = useSelector((state) => state.user);
  const userSubscription = useSelector((state) => state.userSubscription);
  const userEnterprise = useSelector((state) => state.enterprise);
  useEffect(() => {
    setImageLimit(userSubscription?.product?.metadata?.image_count_left || plansLimits[user?.plan]?.image_limit || userEnterprise?.enterprise?.image_limit);
    setWordLimit(userSubscription?.product?.metadata?.text_count_left || plansLimits[user?.plan]?.word_limit || userEnterprise?.enterprise?.text_limit);
    setTotalWords(user.text_count_left);
    setTotalImages(user?.image_count_left);
  }, [userSubscription, user, userEnterprise]);

  //---------------------------------------------------------------
  //Sending a Message
  const [inputValue, setInputValue] = useState("");
  const [innerLoadingSwitch, setInnerLoadingSwitch] = useState(false);
  const [newHistory, setNewHistory] = useState([]);
  const [claimPopUp, setClaimPopUp] = useState(false);
  const [stopGeneratingEvent, setStopGeneratingEvent] = useState(false);

  const generateText = async (e) => {
    e.preventDefault();
    setInputCount(0);
    setStopGeneratingEvent(false);
    if (totalWords === 0) {
      return setShowModal(true);
    } else {
      if (inputValue.trim() == "") return;
      setInnerLoadingSwitch(true);
      dispatch({ type: "STREAMING" });
      let request = {
        role: "user",
        action: "generate-text",
        ref_id: gptChatID,
        message_id: Date.now(),
        message: inputValue,
        title:
          gptChatTitle || gptHistory?.messages?.length === 0
            ? inputValue?.slice(0, 15)
            : gptHistory?.messages
              ?.filter((item) => item?.role === "user")
              ?.slice(0)
              ?.shift()
              ?.message?.slice(0, 15),
        history: gptHistory?.messages?.map((item) => {
          if (item?.is_image) {
            return { role: item?.role, content: "" };
          } else {
            return { role: item?.role, content: item?.message };
          }
        }),
      };
      dispatch({ type: "ADD_MESSAGE", data: { role: request?.role, message: inputValue, ref_id: request?.ref_id, message_id: request?.message_id } });
      socket.emit("request", request, () => { });
      setInputValue("");
    }
  };

  //---------------------------------------------------------------
  //Sizes & Styling
  const chat_contentRef = useRef();
  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  var arabic = /[\u0600-\u06FF]/;
  const handleSizeMobile = () => {
    if (width < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    if (chat_contentRef.current) {
      let elm = document.getElementById("chat_content");
      if (newHistory?.slice(newHistory?.length - 1)?.shift()?.role === "user") {
        elm?.scrollTo(0, elm.scrollHeight);
      } else {
        if (elm.scrollHeight <= elm.scrollTop + elm.offsetHeight + 100) {
          elm?.scrollTo(0, elm.scrollHeight);
        }
      }
    }
  }, [chat_contentRef.current, newHistory]);
  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener("resize", handleSizeMobile);
    return () => {
      window.removeEventListener("resize", handleSizeMobile);
    };
  }, [width]);
  useEffect(() => {
    handleSizeMobile();
  }, [isMobile]);

  //---------------------------------------------------------------
  //Handling Data From Redux
  useEffect(() => {
    setNewHistory(gptHistory?.messages);
  }, [gptHistory]);

  //---------------------------------------------------------------
  //Receiving the message event
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const onMessagesEvent = async (value) => {
      switch (value?.type) {
        case "response":
          if (gptChatID) {
            if (gptChatID === value?.data?.extra?.ref_id) {
              setIsLoading(value?.data?.streaming);
              dispatch({ type: value?.data?.streaming ? "STREAMING" : "DONE_STREAMING" });
              if (!value?.data?.streaming) {
                // dispatch(getUser('without'));
              }
              if (value?.data?.is_image && value?.data?.urls) {
                let request = {
                  role: "assistant",
                  is_image: true,
                  message: "",
                  urls: value?.data?.urls,
                  message_id: value?.data?.extra?.message_id,
                  ref_id: value?.data?.extra?.ref_id,
                };
                await dispatch({ type: "ADD_MESSAGE", data: request });
                setInnerLoadingSwitch(false);
                setIsLoading(false);
              } else if (value?.data?.message?.content) {
                setInnerLoadingSwitch(false);
                let request = {
                  role: "assistant",
                  is_image: false,
                  message: value?.data?.message?.content,
                  message_id: value?.data?.extra?.message_id,
                  ref_id: value?.data?.extra?.ref_id,
                };
                dispatch({ type: "ADD_MESSAGE", data: request });
              }
            }
          }
          break;
        case "token":
          dispatch({ type: "FETCH_ALL_CHAT_HISTORY", data: value?.chats });
          break;
          break;
        case "limit":
          dispatch({ type: USER, data: value?.user });
          dispatch({ type: "FETCH_ALL_CHAT_HISTORY", data: value?.chats });
          break;
        default:
          setInnerLoadingSwitch(false);
          setIsLoading(false);
          break;
      }
    };
    socket.on("data", onMessagesEvent);
    return () => {
      socket.off("data", onMessagesEvent);
    };
  }, [gptHistory, id]);

  //choose the screen size
  const removeHistory = (item, id) => {
    setStopGeneratingEvent(false);
    setScreenLoader(true);
    if (id) {
      socket.emit("stopGenerating", { message_id: id }, () => { });
    }
    axios
      .delete(`${baseUrl}chat/clear/${item}`)
      .then(async (response) => {
        await gettingMessages(item);
        //setScreenLoader(false)
      })
      .catch((error) => {
        setScreenLoader(false);
        toastAlert("Failed to delete the history", undefined, "warning");
      });
  };
  const handleRegularShare = (text) => {
    toastAlert("Copied", "Code has been copied to clipboard", "success");
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      if (!(innerLoadingSwitch || isLoading)) {
        setInputCount(0);
        generateText(e);
      }
    } else if (e.key === "Enter" && e.shiftKey) {
      // alert('only enter')
    }
  };
  const handleStopGenerating = (id) => {
    if (id) {
      socket.emit("stopGenerating", { message_id: id }, () => { });
      setStopGeneratingEvent(true);
      let myTimeout = setTimeout(() => {
        setStopGeneratingEvent(true);
      }, 1000);
      // setTimeout(
      //   function() {
      //     setStopGeneratingEvent(true)
      //   }, 1000);
      clearTimeout(myTimeout);
    }
  };
  const handleReGenerating = async (message) => {
    let userLastMessage = gptHistory?.messages
      ?.slice(0)
      ?.filter((item) => item?.role === "user")
      ?.reverse()
      ?.shift();
    setStopGeneratingEvent(false);
    if (totalWords === 0) {
      return setShowModal(true);
    } else {
      setInnerLoadingSwitch(true);
      dispatch({ type: "STREAMING" });
      let request = {
        role: "user",
        action: "generate-text",
        ref_id: userLastMessage?.ref_id,
        message_id: Date.now(),
        message: userLastMessage?.message,
        title:
          gptChatTitle || gptHistory?.messages?.length === 0
            ? inputValue?.slice(0, 15)
            : gptHistory?.messages
              ?.filter((item) => item?.role === "user")
              ?.slice(0)
              ?.shift()
              ?.message?.slice(0, 15),
        history: gptHistory?.messages?.map((item) => {
          if (item?.is_image) {
            return { role: item?.role, content: "" };
          } else {
            return { role: item?.role, content: item?.message };
          }
        }),
      };
      await dispatch({ type: "ADD_MESSAGE", data: { role: request?.role, message: request?.message, ref_id: request?.ref_id, message_id: request?.message_id } });
      socket.emit("request", request, () => { });
      setInputValue("");
    }
  };
  return (
    <div className="inner_content border-radius-top" style={{ overflow: "hidden" }}>
      {/* <div className="categories_header subCtegoryHeader GPTHeader">
        <Link to={`/`} className="sub_case_header">
          <img src={backArow} alt='back_button' />
          <label>ArabyGPT</label>
        </Link>
      </div> */}
      {screenLoader ? bigLoading ? null : <LoadingPopUp gptLoading={true} opacity={true} /> : null}
      <div className="Araby_GPT">
        <div className="Araby_GPT_chat_space">
          <div ref={chat_contentRef} id="chat_content" style={{ padding: "0px" }} className="chat_content panel-body">
            {newHistory?.map((item, i) => (
              <div
                style={{
                  textAlign: lng === "arab" ? "right" : "left",
                  padding: "20px",
                  backgroundColor: item?.role === "user" ? "#EBF7FF" : null,
                }}
                className="chat_item_GPT msg"
                key={i}
              >
                <div className="textImgCard" style={{ width: "80%", display: "flex", gap: "10px" }}>
                  <img
                    style={{
                      border: item?.role === "user" ? "3px solid #EBF7FF" : null,
                      objectFit: "cover",
                    }}
                    src={item?.role === "user" ? user?.image || "https://riverlegacy.org/wp-content/uploads/2021/07/blank-profile-photo.jpeg" : gptDarker}
                    alt="araby_gpt"
                  />
                  <div
                    style={{
                      width: "80%",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      flexWrap: "wrap",
                      textAlign: !arabic.test(item?.message) ? "left" : "right",
                      fontFamily: arabic.test(item?.message) ? "Tajawal" : "Poppins",
                      direction: !arabic.test(item?.message) ? "ltr" : "rtl",
                    }}
                  >
                    <label
                      style={{
                        color: item?.err ? "red" : "#000",
                        cursor: item?.role === "user" ? "text" : null,
                        fontSize: "16px",
                        whiteSpace: "pre-wrap",
                        fontWeight: "400",
                        position: "relative",
                        cursor: "text",
                      }}
                    >
                      {item?.role === "user" ? null : width <= 900 ? null : (
                        <CopyToClipboard onCopy={() => handleRegularShare()} text={item?.message}>
                          <p
                            type="button"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              position: "absolute",
                              left: lng === "arab" ? "-45px" : "auto",
                              right: lng === "arab" ? "auto" : "-45px",
                              top: "-4px",
                            }}
                            className="words_action_btn"
                          >
                            <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="12.424" height="12.424" viewBox="0 0 12.424 12.424">
                              <g id="Group_2026" data-name="Group 2026">
                                <path
                                  id="Path_10306"
                                  data-name="Path 10306"
                                  d="M13.117,2.16H5.543A1.466,1.466,0,0,0,4.081,3.627v.454H3.622A1.466,1.466,0,0,0,2.16,5.548v7.569a1.466,1.466,0,0,0,1.462,1.467H11.2a1.468,1.468,0,0,0,1.467-1.467v-.454h.454A1.469,1.469,0,0,0,14.584,11.2V3.627A1.469,1.469,0,0,0,13.117,2.16ZM11.765,13.117a.57.57,0,0,1-.569.569H3.622a.567.567,0,0,1-.565-.569V5.548a.568.568,0,0,1,.565-.569H11.2a.57.57,0,0,1,.569.569v7.569ZM13.686,11.2a.57.57,0,0,1-.569.569h-.454V5.548A1.468,1.468,0,0,0,11.2,4.081H4.978V3.627a.567.567,0,0,1,.565-.569h7.574a.57.57,0,0,1,.569.569Z"
                                  transform="translate(-2.16 -2.16)"
                                  fill="#b1b1b1"
                                />
                              </g>
                            </svg>
                          </p>
                        </CopyToClipboard>
                      )}
                      <ReactMarkdown
                        children={item?.message}
                        remarkPlugins={[remarkGfm]}
                        components={{
                          code({ node, inline, className, children, ...props }) {
                            const match = /language-(\w+)/.exec(className || "");
                            return !inline && match ? (
                              <>
                                <CopyToClipboard onCopy={() => handleRegularShare()} text={String(children?.slice(0)?.shift())}>
                                  <p type="button" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} className="words_action_btn">
                                    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="12.424" height="12.424" viewBox="0 0 12.424 12.424">
                                      <g id="Group_2026" data-name="Group 2026">
                                        <path
                                          id="Path_10306"
                                          data-name="Path 10306"
                                          d="M13.117,2.16H5.543A1.466,1.466,0,0,0,4.081,3.627v.454H3.622A1.466,1.466,0,0,0,2.16,5.548v7.569a1.466,1.466,0,0,0,1.462,1.467H11.2a1.468,1.468,0,0,0,1.467-1.467v-.454h.454A1.469,1.469,0,0,0,14.584,11.2V3.627A1.469,1.469,0,0,0,13.117,2.16ZM11.765,13.117a.57.57,0,0,1-.569.569H3.622a.567.567,0,0,1-.565-.569V5.548a.568.568,0,0,1,.565-.569H11.2a.57.57,0,0,1,.569.569v7.569ZM13.686,11.2a.57.57,0,0,1-.569.569h-.454V5.548A1.468,1.468,0,0,0,11.2,4.081H4.978V3.627a.567.567,0,0,1,.565-.569h7.574a.57.57,0,0,1,.569.569Z"
                                          transform="translate(-2.16 -2.16)"
                                          fill="#b1b1b1"
                                        />
                                      </g>
                                    </svg>
                                  </p>
                                </CopyToClipboard>
                                <SyntaxHighlighter
                                  children={String(children).replace(/\n$/, "")}
                                  language={detect(String(children?.slice(0)?.shift()))?.toLowerCase()}
                                  PreTag="div"
                                  style={docco}
                                  {...props}
                                />
                              </>
                            ) : (
                              <code {...props} className={className}>
                                {children}
                              </code>
                            );
                          },
                        }}
                      />
                    </label>
                    {item?.is_image ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          flexWrap: "wrap",
                          width: "100%",
                        }}
                      >
                        {item?.urls?.map((item, i) => (
                          <img className="GPT_Generated_Image" key={i} src={item} alt="Generated Image" />
                        ))}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ))}

            {innerLoadingSwitch ? (
              <div
                style={{
                  textAlign: lng === "arab" ? "right" : "left",
                  padding: "20px",
                  // backgroundColor: "#EBF7FF",
                }}
                className="chat_item_GPT"
              >
                <div className="textImgCard" style={{ width: "80%", display: "flex", gap: "10px" }}>
                  <img src={gptDarker} alt="twwt_gpt" />
                  <div
                    style={{
                      width: "80%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        padding: "10px",
                        minHeight: "50px",
                      }}
                    >
                      <Spinner animation="grow" size="sm" variant="dark" />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {/* <div style={{boxShadow: '1px 0px 8px gray', height: '1px', width: '100%', backgroundColor: '#8080803b'}}/> */}
        <div className="arabyGPT_form_wrapper">
          <div style={{ position: "relative", width: "100%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "98%",
                paddingBottom: "5px",
                position: "static",
                bottom: "0px",
                right: lng === "arab" ? "0px" : "auto",
                left: lng === "arab" ? "auto" : "40px",
              }}
            >
              <label
                className="stop_and_re"
                onClick={() => navigate("/twwtgpt")}
                style={{
                  cursor: innerLoadingSwitch ? "not-allowed" : "pointer",
                  fontSize: "12px",
                  alignSelf: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "fle-start",
                  gap: "5px",
                  padding: "5px 10px 5px 10px",
                }}
              >
                <span>{t("New Chat")}</span>
                <img src={remove} style={{ height: "15px", width: "15px", marginBottom: "2px" }} alt="" />
              </label>
            </div>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  paddingBottom: "5px",
                  position: "absolute",
                  bottom: "0px",
                  right: lng === "arab" ? "auto" : "0px",
                  left: lng === "arab" ? "0px" : "auto",
                }}
              >
                <label
                  className="stop_and_re"
                  onClick={() => handleStopGenerating(gptHistory?.messages?.slice(0)?.reverse()?.shift()?.role === "user" ? undefined : gptHistory?.messages?.slice(0)?.reverse()?.shift()?.message_id)}
                  style={{
                    cursor: innerLoadingSwitch ? "not-allowed" : "pointer",
                    fontSize: "12px",
                    alignSelf: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "fle-start",
                    gap: "5px",
                    padding: "5px 10px 5px 10px",
                  }}
                >
                  <span>{t("Stop Generating")}</span>
                  <img src={stopbutton} style={{ height: "15px", width: "15px", marginBottom: "2px" }} alt="" />
                </label>
              </div>
            ) : null}
            {!isLoading && stopGeneratingEvent ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  paddingBottom: "5px",
                  position: "absolute",
                  bottom: "0px",
                  right: lng === "arab" ? "auto" : "0px",
                  left: lng === "arab" ? "0px" : "auto",
                }}
              >
                <label
                  className="stop_and_re"
                  onClick={() => handleReGenerating()}
                  style={{
                    cursor: innerLoadingSwitch ? "not-allowed" : "pointer",
                    fontSize: "12px",
                    alignSelf: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "fle-start",
                    gap: "5px",
                    padding: "5px 10px 5px 10px",
                  }}
                >
                  <span>{t("Re-Generate")}</span>
                  <img src={regenerate} style={{ height: "12px", width: "12px", marginBottom: "2px" }} alt="" />
                </label>
              </div>
            ) : null}
          </div>
          <form onKeyDown={handleKeyPress} onSubmit={generateText} className="arabyGPTForm">
            <div style={{ display: "flex" }}>
              <TextareaAutosize
                autoFocus
                onKeyDown={(e) => {
                  if (e.key == "Enter" && !e.shiftKey) {
                    e.preventDefault();
                  }
                }}
                onFocus={() => {
                  setInputCountContainer(true);
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setInputCountContainer(false);
                  }, 200);
                }}
                value={inputValue}
                style={{ transform: "unset" }}
                className="form-control"
                maxRows={8}
                // maxLength={4000}
                placeholder={t("Write here..")}
                onChange={(e) => {
                  setInputValue(e.target.value);
                  setInputCount(e.target.value.length);
                }}
              />
              <button disabled={innerLoadingSwitch || isLoading} type="submit" style={{ marginTop: "0" }}>
                <img src={arrow} alt="submit arrow" />
              </button>
            </div>
            {/* {inputCountContainer && <span style={{ padding: " 0 5px", color: "#6A7280", textAlign: "end", fontSize: "0.8rem", display: "block" }}>{inputCount} / 4000</span>} */}
          </form>
        </div>
        <ClaimFreeModal showModal={claimPopUp} setShowModal={setClaimPopUp} />
        {user?.plan === "Trial" ? (
          <UpgradeModal setShowModal={setShowModal} showModal={showModal} />

        ) : (
          <LimitPopUp setShowModal={setShowModal} showModal={showModal} />
        )}
        <ClaimFreeModal showModal={claimPopUp} setShowModal={setClaimPopUp} />
      </div>
    </div>
  );
};

export default ArabyGPT;

const CodeEditorWindow = ({ isDarkTheme, code, setCode, name }) => {
  return <CodeEditor value={code} language="md" readOnly={true} data-color-mode="dark" lineNumber style={{ background: "none" }} />;
};
