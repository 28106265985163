import React, { useState, useEffect } from "react";
import dragphoto from "assets/img/newDesign/cloud.svg";
import draghover from "assets/img/drag-img-hover.png";
import { toastAlert } from "components/toastAlert/toastAlert";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getAllOldImages } from "redux/actions/history";
import UpgradeModal from "components/UpgradeModal/UpgradeModal";
import "views/MediaSection/ImageTools/Create/ImgGen.css";
import rotateL from "assets/img/newDesign/rotate-left.png";
import rotateR from "assets/img/newDesign/rotate-right.png";
import checkErr from "redux/actions/api_error_func";
import { Cloudinary } from "@cloudinary/url-gen";
import { byAngle } from "@cloudinary/url-gen/actions/rotate";
import LoadingEffect from "components/Loading/LoadingEffect";
import Skeleton from "react-loading-skeleton";
import UpgradePlanModal from "components/UpgradeModal/UpgradePlanModal";
import { handleUpscaleV1 } from "redux/actions/media_section";
import LimitPopUp from "views/plansComponent/LimitPopUp";
const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

const UpscaleV1 = () => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const [numberOfOutputs, setNumberOfOutputs] = useState(3);
  const [showModal, setShowModal] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [base64, setBase64] = useState("");
  const [totalImages, setTotalImages] = useState(0);
  const [input, setInput] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const user = useSelector((state) => state.user);
  const upscaleV1 = useSelector((state) => state.upscaleV1);
  const dispatch = useDispatch();
  const [rotateValue, setRotateValue] = useState(0);
  const [imageURL, setImageURL] = useState();
  const [show, setShow] = useState(false);

  const [errors, setError] = useState();
  let error_msg =
    lng === "arab"
      ? "حجم الصورة لا يجب أن يزيد عن 5 ميجابايت"
      : "Image size should be less than 5 MB";

  let error_valid =
    lng === "arab"
      ? "يُسمح فقط بملفات JPG و JPEG و PNG"
      : "Only JPG, JPEG, and PNG files are allowed.";

  const rotateImages = () => {
    const cld = new Cloudinary({
      cloud: {
        cloudName: "cloud-name",
      },
    });
    const rotateImg = cld
      .image(upscaleV1?.upscaleV1?.urls)
      .rotate(byAngle(rotateValue));
    setImageURL(rotateImg.toURL());
  };

  useEffect(() => {
    rotateImages();
  }, [rotateValue]);

  const [width, setWidth] = useState(window.innerWidth);
  const [currentFormats, setCurrentFormats] = useState(formats);
  const isMobile = width <= 768;
  const isTablet = width <= 850;
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    setCurrentFormats(
      isMobile || isTablet
        ? [
            "size",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
            "video",
          ]
        : formats
    );
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setTotalImages(user?.image_count_left);
    if (user?.plan === "Trial" || user?.plan === "Starter") {
      return setShow(true);
    }
  }, [user]);

  const handlePainting = async (e) => {
    e.preventDefault();
    if (totalImages === 0) {
      dispatch({ type: "SHOW_UPSCALE_V1_FALSE" });
      setShowModal(true);
      return;
    }
    try {
      let data = {
        width: parseInt(numberOfOutputs),
        height: parseInt(numberOfOutputs),
        image: base64,
      };
      dispatch(handleUpscaleV1(data));
      dispatch({ type: "FETCH_USER" });
      dispatch(getAllOldImages());
      setBase64("");
    } catch (error) {
      toastAlert(error);
      checkErr(error, toastAlert);
    } finally {
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    const file = await e.dataTransfer.files[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (allowedTypes.includes(file.type)) {
        if (file.size > 5242880) {
          setError(error_msg);
          return toastAlert(error_msg, undefined, "warning");
        } else {
          setSelectedFile(file[0]);
          dispatch({ type: "SHOW_UPSCALE_V1_TRUE" });
          handleFile(file);
          setDragging(false);
        }
      } else {
        setError(error_valid);
        toastAlert(error_valid, undefined, "warning");
      }
    } else setUploaded(true);
  };

  const handleImageUpload = async (e) => {
    const file = await e.target.files;
    if (file) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (allowedTypes.includes(file[0].type)) {
        if (file[0].size > 5000000) {
          setError(error_msg);
          return toastAlert(error_msg, undefined, "warning");
        } else {
          setSelectedFile(file[0]);
          dispatch({ type: "SHOW_UPSCALE_V1_TRUE" });
          handleFile(file[0]);
          setDragging(false);
        }
      } else {
        setError(error_valid);
        toastAlert(error_valid, undefined, "warning");
      }
    } else setUploaded(true);
  };

  const handleFile = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setBase64(reader.result.toString());
    };
    if (file && /image/.test(file.type)) {
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  const handleDownload = () => {
    const fileUrl = upscaleV1?.upscaleV1?.urls;
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = "hello"; // Specify the file name here
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };
  // console.log(upscaleV1, "upscaleV1");
  return (
    <div className="image_gen_header" style={{ padding: "40px 15px 0" }}>
      <h4>{t("Upscale Your Images")}</h4>
      {upscaleV1?.upscaleV1?.length === 0 && (
        <form onSubmit={handlePainting}>
          <div className="inpainting_input">
            <h5
              style={{
                textAlign: lng === "arab" ? "right" : "left",
                paddingTop: "20px",
                margin: "0",
              }}
            >
              {t("Upscale Value")}
            </h5>
            <div className="output_number" style={{ gap: "15px" }}>
              <button
                style={
                  numberOfOutputs === 1024
                    ? { outline: "2px solid #4472f16c", color: "#4472f1da" }
                    : null
                }
                type="button"
                onClick={() => setNumberOfOutputs(1024)}
              >
                X2
              </button>
              <button
                type="button"
                style={
                  numberOfOutputs === 2048
                    ? { outline: "2px solid #4472f16c", color: "#4472f1da" }
                    : null
                }
                onClick={() => setNumberOfOutputs(2048)}
              >
                X4
              </button>
              <button
                type="button"
                style={
                  numberOfOutputs === 3072
                    ? { outline: "2px solid #4472f16c", color: "#4472f1da" }
                    : null
                }
                onClick={() => setNumberOfOutputs(3072)}
              >
                X6
              </button>
              <button
                type="button"
                style={
                  numberOfOutputs === 4096
                    ? { outline: "2px solid #4472f16c", color: "#4472f1da" }
                    : null
                }
                onClick={() => setNumberOfOutputs(4096)}
              >
                X8
              </button>
            </div>
          </div>
          <div className="inpainting_input">
            <button
              style={{
                padding:
                  isMobile || isTablet
                    ? "15px 40px !important"
                    : "15px 60px !important",
                background: upscaleV1?.loading
                  ? "gray"
                  : "linear-gradient(45deg, rgba(17, 113, 216, 1) 0%, rgba(60, 200, 235, 1) 100%)",
                width: "170px",
                minHeight: "46px",
                borderRadius: "15px",
                alignSelf: "center",
                pointerEvents: upscaleV1?.loading && "none",
              }}
              className="form_buttions"
              type="submit"
            >
              {upscaleV1?.loading ? <LoadingEffect /> : t("Upscale")}
            </button>
          </div>
        </form>
      )}

      {upscaleV1.showRemover ? (
        <>
          <div style={{ margin: "auto", width: "100%", borderRadius: "20px" }}>
            {upscaleV1?.loading && (
              <div style={{ height: "55vh" }}>
                <Skeleton
                  height={width <= 500 ? 350 : 400}
                  width={width <= 500 ? 350 : 450}
                  style={{ borderRadius: "15px" }}
                />
                <p>{t("This image will take upto 1 - 2 minute")}</p>
              </div>
            )}
            {upscaleV1?.upscaleV1?.length === 0 &&
              upscaleV1?.loading === false && (
                <div
                  style={{
                    maxWidth: "500px",
                    minHeight: "450px",
                    margin: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    className="bg_remover_background"
                    style={{ backgroundImage: "none" }}
                    src={selectedImage}
                  />
                </div>
              )}
            {upscaleV1?.loading === false &&
            upscaleV1?.upscaleV1?.length === 0 ? (
              <button
                className="newupload"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "medium",
                  margin: "10px auto",
                }}
                onClick={() => {
                  setInput("");
                  dispatch({ type: "NEW_UPSCALE_V1" });
                }}
              >
                {" "}
                {t("New Upload")}{" "}
                <i
                  className="fas fa-arrow-up"
                  style={{
                    fontSize: "medium",
                    margin: lng === "arab" ? " 0 5px 0 0" : "0 0 0 5px",
                  }}
                ></i>
              </button>
            ) : null}
          </div>
          <div style={{ position: "relative" }}>
            {upscaleV1?.loading === false &&
            upscaleV1?.upscaleV1?.length != 0 ? (
              <div className="rotate_buttons">
                <img
                  src={rotateL}
                  onClick={() => setRotateValue(rotateValue - 90)}
                  width={25}
                  style={{
                    cursor: "pointer",
                    margin: lng === "arab" ? "0 5px 0 0" : "0 0 0 5px ",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                    borderRadius: "5px",
                    padding: "3px",
                    border: "0.2px solid #00000029",
                  }}
                />
                <img
                  src={rotateR}
                  onClick={() => setRotateValue(rotateValue + 90)}
                  width={25}
                  style={{
                    cursor: "pointer",
                    margin: lng === "arab" ? "0 5px 0 0" : "0 0 0 5px ",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                    borderRadius: "5px",
                    padding: "3px",
                    border: "0.2px solid #00000029",
                  }}
                />
              </div>
            ) : null}
            {upscaleV1?.upscaleV1?.length != 0 && (
              <div
                style={{
                  maxWidth: "500px",
                  minHeight: "450px",
                  margin: "auto",
                  transform: `rotate(${rotateValue}deg)`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={upscaleV1?.upscaleV1?.urls[0]}
                  className="bg_remover_background"
                />
              </div>
            )}
            {upscaleV1?.loading === true ||
              (upscaleV1?.upscaleV1?.length != 0 && (
                <div className="beforeAfter-container">
                  {upscaleV1?.upscaleV1?.length != 0 && (
                    <div className="beforeafter-gray">
                      <button
                        className="newupload"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                          fontSize: "medium",
                        }}
                        onClick={() => {
                          setInput("");
                          dispatch({ type: "NEW_UPSCALE_V1" });
                        }}
                      >
                        {t("New Upload")}
                        <i
                          className="fas fa-arrow-up"
                          style={{
                            fontSize: "medium",
                            margin: lng === "arab" ? " 0 5px 0 0" : "0 0 0 5px",
                          }}
                        ></i>
                      </button>
                      <button
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                          fontSize: "medium",
                        }}
                        className="download-btn"
                        onClick={handleDownload}
                      >
                        {t("Download")}
                        <i
                          className="fas fa-arrow-down"
                          style={{
                            fontSize: "medium",
                            margin: lng === "arab" ? " 0 5px 0 0" : "0 0 0 5px",
                          }}
                        ></i>
                      </button>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </>
      ) : (
        <>
          <div className="image-drop-container">
            <label className="file-input-label">
              <input
                type="file"
                accept=".jpg,.jpeg,.png"
                onChange={handleImageUpload}
              />
              <div
                className={`image-drop-zone ${
                  dragging ? "dragging" : "undraging"
                }`}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
              >
                <>
                  <div className="image-drop-text">
                    {dragging ? (
                      <img
                        src={draghover}
                        alt="other-img"
                        style={{
                          height: "80px",
                          width: "122px",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                      />
                    ) : (
                      <img
                        src={dragphoto}
                        alt="drop-img"
                        style={{
                          height: "80px",
                          width: "122px",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                      />
                    )}
                    <label>
                      {t("Drop your image here, or")}{" "}
                      <span className="file-input-label">{t("browse")}</span>
                    </label>

                    <div className="image-drop-support-text">
                      {t("Supports JPG , PNG")}
                    </div>
                    <div className="image-drop-support-text">
                      {t("Maximum Size 5MB")}
                    </div>
                  </div>
                </>
              </div>
            </label>
          </div>
        </>
      )}
      {user?.plan === "Trial" ? (
        <UpgradeModal
          images={true}
          setShowModal={setShowModal}
          showModal={showModal}
        />
      ) : (
        <LimitPopUp setShowModal={setShowModal} showModal={showModal} />
      )}

      <UpgradePlanModal show={show} setShow={setShow} width={width} />
      <label style={{ color: "red", paddingTop: "50px" }}>{errors}</label>
    </div>
  );
};

export default UpscaleV1;
