import { FETCH_REMOVE_VIDEO, LOAD_REMOVE_VIDEO, LOADED_REMOVE_VIDEO, NEW_REMOVE_VIDEO, SHOW_REMOVE_VIDEO_FALSE, SHOW_REMOVE_VIDEO_TRUE, LOAD_PERCENTAGE_REMOVE_VIDEO } from "redux/contstants";

export default (removeVideo = { removeVideo: [], uploaded: [], loadingNumber: 0, loading: false, showRemover: false }, action) => {
  switch (action.type) {
    case LOAD_REMOVE_VIDEO:
      return { ...removeVideo, loading: true, showRemover: true };
    case FETCH_REMOVE_VIDEO:
      return { ...removeVideo, removeVideo: action.data, loadingNumber: 0 };
    case LOADED_REMOVE_VIDEO:
      return { ...removeVideo, loading: false };
    case NEW_REMOVE_VIDEO:
      return { ...removeVideo, removeVideo: [], loadingNumber: 0, uploaded: [], showRemover: false };
    case SHOW_REMOVE_VIDEO_FALSE:
      return { ...removeVideo, showRemover: false };
    case SHOW_REMOVE_VIDEO_TRUE:
      return { ...removeVideo, showRemover: true };
    case LOAD_PERCENTAGE_REMOVE_VIDEO:
      return { ...removeVideo, loadingNumber: action.data };
    default:
      return removeVideo;
  }
};
