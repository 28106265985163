import homeIcon from "assets/img/newDesign/homeIcon.svg";
import imgGenIcon from "assets/img/newDesign/imgGenIcon.svg";
import savedIcon from "assets/img/newDesign/savedIcon.svg";
import writeIcon from "assets/img/newDesign/writeIcon.svg";
import homeIconHover from "assets/img/newDesign/homeIconHover.svg";
import historyIcon from "assets/img/newDesign/historyIcon.svg";
import historyIconHover from "assets/img/newDesign/historyHover.svg";

import businessToolIcon from "assets/img/newDesign/businessIcon2.svg";
import businessToolIconHover from "assets/img/newDesign/businessHover2.svg";

import writeCodeIcon from "assets/img/newDesign/codeicon.svg";
import writeCodeIconHover from "assets/img/newDesign/codeiconHovering.svg";

import arabygpt from "assets/img/newDesign/gptLogoWhite.svg";
import arabygptHover from "assets/img/newDesign/gptSideBarHover.svg";

import bookmarksIcon from "assets/img/newDesign/bookmarks.svg";
import bookmarksIconHover from "assets/img/newDesign/bookmarksHovering.svg";

import imgGenIconHover from "assets/img/newDesign/imgGenIconHover.svg";
import savedIconHover from "assets/img/newDesign/savedIconHover.svg";
import writeIconHover from "assets/img/newDesign/writeIconHover.svg";
import templatesIconHover from "assets/img/newDesign/templatesHover.svg";
import templatesIcon from "assets/img/newDesign/templates.svg";
import writeDoc from "assets/img/newDesign/writeGray.svg";
import writeDocHovering from "assets/img/newDesign/writeWhite.svg";
import Home from "views/Home";
import WriteNow from "views/WriteNow";
import ImgGen from "views/MediaSection/ImageTools/Create/ImgGen";
import SubCategories from "views/SubCategories";
import SubCategory from "views/SubCategory";
import Templates from "views/Templates";
import ContactUs from "views/ContactUs";
import ProfileSteps from "components/profileSteps/ProfileSteps";
import RevProfile from "components/RevProfile/RevProfile";
import RevUsage from "components/RevProfile/RevUsage";
import WriteDocument from "views/WriteDocument/WriteDocument";
import WriteCode from "views/WriteCode";
import CodeGen from "views/CodeGen/CodeGen";
import ArabyGPT from "views/ArabyGPT";
import PaymentGateWay from "components/PaymentGateWay/PaymentGateWay";
import Enhance from "views/MediaSection/ImageTools/Enhance/EnhanceImage";
import InviteUsers from "views/InviteUsers";
import MediaSection from "views/MediaSection/MediaSection";
import Presentation from "views/Presentation/Presentation";
import Slides from "views/Presentation/Slides";
// import HyperPay from "views/HyperPay/HyperPay";
import BusinessTools from "views/Business/BusinessTools";
import MarketingPlan from "views/Marketing/MarketingPlan";
import Blog from "views/blog/Blog";
import AddBlog from "views/blog/AddBlog";
import UpdateBlog from "views/blog/UpdateBlog";
import History from "views/History/History";
import Plan from "views/plansComponent/Plans/Plan.jsx";

import ImagesTools from "views/MediaSection/ImageTools/ImagesTools";
import AudioTools from "views/MediaSection/AudioTools/AudioTools";
import VideoTools from "views/MediaSection/VideoTools/VideoTools";
import ChatDoc from "views/ChatDoc/ChatDoc";

export const dashboardRoutes = [
  {
    path: "/",
    name: "Home",
    icon: homeIcon,
    hicon: homeIconHover,
    component: Home,
    id: 1,
  },
  {
    path: "/business-tools",
    name: "Business Tools",
    icon: businessToolIcon,
    hicon: businessToolIconHover,
    component: BusinessTools,
    // withConditon: 'enterprise',
    id: 2,
  },
  {
    path: "/twwtgpt",
    name: "TwwtGPT",
    icon: arabygpt,
    hicon: arabygptHover,
    component: ArabyGPT,
    id: 3,
  },
  {
    path: "/writenow",
    name: "Writers Section",
    icon: writeIcon,
    hicon: writeIconHover,
    component: WriteNow,
    id: 4,
  },
  {
    path: "/writenow/:name",
    name: "Writers Section",
    icon: writeIcon,
    hicon: writeIconHover,
    component: SubCategories,
    status: "sub",
    id: 5,
  },
  {
    path: "/writenow/:name/:id",
    name: "Writers Section",
    icon: writeIcon,
    hicon: writeIconHover,
    component: SubCategory,
    status: "sub",
    id: 6,
  },
  {
    path: "/photogen",
    name: "Media Section",
    icon: imgGenIcon,
    hicon: imgGenIconHover,
    component: MediaSection,
    id: 7,
  },
  {
    path: "/photogen/images",
    name: "Media Section",
    component: ImagesTools,
    id: 8,
    status: "sub",
  },
  {
    path: "/photogen/audios",
    name: "Media Section",
    component: AudioTools,
    id: 9,
    status: "sub",
  },
  {
    path: "/photogen/videos",
    name: "Media Section",
    component: VideoTools,
    id: 10,
    status: "sub",
  },
  {
    path: "/photogen/:tools/:name",
    name: "Media Section",
    icon: imgGenIcon,
    hicon: imgGenIconHover,
    component: ImgGen,
    status: "sub",
    id: 11,
  },
  {
    path: "enhance",
    component: Enhance,
    status: "sub",
    id: 12,
  },

  // {
  //   path: "/savedprojects",
  //   name: "Saved Projects",
  //   icon: savedIcon,
  //   hicon: savedIconHover,
  //   component: Typography,
  //   id: 13
  // },

  {
    path: "/profile",
    name: "Profile",
    icon: savedIcon,
    hicon: savedIconHover,
    // component: Profile,
    component: RevProfile,
    status: "sub",
    id: 14,
  },
  // {
  //   path: "/profile/:steps",
  //   name: "Profile",
  //   icon: savedIcon,
  //   hicon: savedIconHover,
  //   component: ProfileSteps,
  //   status: "sub",
  //   id: 16
  // },

  // {
  //   path: "/payment",
  //   name: "Payment Method",
  //   icon: templatesIcon,
  //   hicon: templatesIconHover,
  //   component: Templates,
  //   status: "sub",
  //   id: 17
  // },

  {
    path: "/contactus",
    name: "Contact Us",
    icon: savedIcon,
    hicon: savedIconHover,
    component: ContactUs,
    status: "sub",
    id: 18,
  },

  // {
  //   path: "/Usage",
  //   name: "Usage",
  //   icon: savedIcon,
  //   hicon: savedIconHover,
  //   component: RevUsage,
  //   status: "sub",
  //   id: 19
  // },
  {
    path: "/document",
    name: "Text editor",
    icon: writeDoc,
    hicon: writeDocHovering,
    component: WriteDocument,
    id: 20,
  },
  {
    path: "/document/:id",
    name: "Text editor",
    icon: writeDoc,
    hicon: writeDocHovering,
    component: WriteDocument,
    status: "sub",
    id: 23,
  },
  {
    path: "/writecode",
    name: "Coders Section",
    icon: writeCodeIcon,
    hicon: writeCodeIconHover,
    component: WriteCode,
    id: 21,
  },

  {
    path: "/writecode/:name",
    name: "Coders Section",
    icon: writeCodeIcon,
    hicon: writeCodeIconHover,
    component: CodeGen,
    status: "sub",
    id: 22,
  },
  // {
  //   path: "/presentation",
  //   name: "Presentation",
  //   icon: presentationIcon,
  //   hicon: presentationIconHover,
  //   component: Presentation,
  //   id: 23
  // },
  // {
  //   path: "/hyper/:id",
  //   component: HyperPay,
  //   status: 'sub',
  //   id: 24

  // },
  // {
  //   path: "/slides",
  //   component: Slides,
  //   name: "Presentation",
  //   status: 'sub',
  //   id: 25
  // },
  {
    path: "/plan",
    name: "Plans",
    icon: savedIcon,
    hicon: savedIconHover,
    component: Plan,
    id: 26,
  },

  // {
  //   path: "/invite-users/:id",
  //   name: "Invitation",
  //   icon: writeCodeIcon,
  //   hicon: writeCodeIconHover,
  //   component: InviteUsers,
  //   status: 'sub',
  //   id: 27
  // },
  {
    path: "/paymentgateway",
    // name: "Plans",
    // icon: savedIcon,
    // hicon: savedIconHover,
    component: PaymentGateWay,
    status: "sub",
    id: 28,
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   id: 29
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   component: Maps,
  //   id: 30
  // },
  // {
  //   path: "/table",
  //   name: "Table List",
  //   icon: "nc-icon nc-notes",
  //   component: TableList,
  //   noExact: true,
  //   id: 31
  // },
  // {
  //   path: "/history/text",
  //   name: "History",
  //   icon: bookmarksIcon,
  //   hicon: bookmarksIconHover,
  //   component: History,
  //   id: 32
  // },
  // {
  //   path: "/paymentgateway",
  //   // name: "Plans",
  //   // icon: savedIcon,
  //   // hicon: savedIconHover,
  //   component: PaymentGateWay,
  //   id: 33
  // },

  {
    path: "/business-tools/:name",
    name: "Business Tools",
    icon: businessToolIcon,
    hicon: businessToolIconHover,
    component: BusinessTools,
    status: "sub",
    // withConditon: 'enterprise',
    id: 9,
  },
  {
    path: "/history/text",
    name: "History",
    icon: historyIcon,
    hicon: historyIconHover,
    component: History,
    id: 32,
  },
  {
    path: "/pdf-chat",
    name: "Chat Doc",
    icon: writeDoc,
    hicon: writeDocHovering,
    component: ChatDoc,
    id: 5,
  },
];

export const blogMainRoutes = [
  {
    path: "/blog",
    name: "Blog",
    icon: writeCodeIcon,
    hicon: writeCodeIconHover,
    component: Blog,
    id: 27,
  },
  {
    path: "/blog/:name",
    name: "Blog",
    icon: writeCodeIcon,
    hicon: writeCodeIconHover,
    component: AddBlog,
    status: "sub",
  },
  {
    path: "/blog/:name/:id",
    name: "Blog",
    icon: writeCodeIcon,
    hicon: writeCodeIconHover,
    component: UpdateBlog,
    status: "sub",
  },
];

export const enterpriseNormalRoutes = [
  {
    path: "/business-tools/:name/:id",
    name: "businessCase",
    icon: businessToolIcon,
    hicon: businessToolIconHover,
    component: MarketingPlan,
    status: "sub",
    withConditon: "enterprise",
    dynamicName: true,
    id: 10,
  },
];

// export default dashboardRoutes;
