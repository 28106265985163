import React from "react";
import "./PaymentGateWay.scss";
import InvoiceHistory from "./InvoiceHistory";
import moment from "moment";
import Popup from "./Popup";
import iconPayment from "../../assets/img/addpayment-icon.png";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "api/baseUrl";
import checkErr from "redux/actions/api_error_func";
import { toastAlert } from "components/toastAlert/toastAlert";
import CancelPlanPopUp from "./CancelPlanPopUp";
//<---------------------------CSS STARTS ------------------------------------>

const cancel_button = {
  backgroundColor: "none",
  borderRadius: "20px",
  border: "1px solid gray",
  flex: "1",
  minWidth: "100px",
  padding: "10px 20px",
  fontSize: "1.2rem",
};

//<---------------------------CSS ENDS ------------------------------------>

const PaymentGateWay = () => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const [getUserData, setGetUserData] = useState([]);
  const [clickCancel, setClickCancel] = useState(false);
  const [showCancelPopUp, setShowCancelPopUp] = useState(false);
  
  const getUser = async () => {
    let response = await axios.get(`${baseUrl}subscription/get/user`).then((res) => {
      setGetUserData(res?.data?.subscription);
      if (res?.data?.subscription?.canceld_at != null) {
        setClickCancel(true);
      }
    });
  };

  const cancelPlan = () => {
    setShowCancelPopUp(true);
  };
  const changeTexttoCanceled = (val) => {
    setClickCancel(val);
    // console.log(clickCancel, "clickCancel");
  };

  useEffect(() => {
    getUser();
  }, []);
  return (
    <>
      <div className="bookmarks_page inner_content border-radius-top">
        <div id="Checkout" class="inline" style={{ width: "100%", height: "100%" }}>
          <h1 style={{ textTransform: "uppercase" }}>{t("Manage Plans")}</h1>
          <div
            class="card-row"
            style={{
              textAlign: "left",
              textAlign: lng === "arab" ? "right" : "left",
            }}
          >
            <label
              for="PaymentAmount"
              style={{
                textTransform: "uppercase",
                color: "#9C9C9C",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {t("Current Plan")}
            </label>
          </div>
          <form>
            <div class="form-group">
              <div class="amount-placeholder row" style={{ alignItems: "center" }}>
                <div
                  // className="col-6 left-plan"
                  style={{
                    textAlign: lng === "arab" ? "right" : "left",
                    flex: "3",
                  }}
                >
                  <h5
                    for="PaymentAmount"
                    style={{
                      fontSize: "19px",
                      marginBottom: "0px",
                      color: "black",
                      fontSize: "17px",
                      fontWeight: "300",
                    }}
                  >
                    {lng === "arab" ? getUserData?.product_id?.name_ar : getUserData?.product_id?.name}
                  </h5>
                  <span style={{ fontSize: "25px", fontWeight: "500" }}>
                    ${getUserData?.last_transaction_id?.total_amount}
                    <span style={{ fontSize: "17px", fontWeight: "300" }}>{t("/ Month")}</span>
                  </span>
                </div>
                <button
                  disabled={clickCancel}
                  style={cancel_button}
                  onClick={(e) => {
                    e.preventDefault();
                    cancelPlan();
                  }}
                >
                  {clickCancel ? t("Cancelled") : t("Cancel Plan")}
                </button>
              </div>
              <div className="row" style={{ marginTop: "23px" }}>
                <div className="col-12" style={{ padding: "0px" }}>
                  <h5
                    style={{
                      fontSize: "14px",
                      fontWeight: "300",
                      textAlign: lng === "arab" ? "right" : "left",
                    }}
                  >
                    <span>{t("your plan ends on ")}</span>

                    {moment(getUserData?.end_date)?.format("YYYY-MM-DD")}
                  </h5>
                </div>
              </div>
            </div>
            <hr className="Pyament2ndhr" />

            <p style={{ textAlign: lng === "arab" ? "right" : "left" }}>
              <label
                for="PaymentAmount"
                style={{
                  textTransform: "uppercase",
                  color: "#9C9C9C",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                {t("INVOICE HISTORY")}
              </label>
            </p>

            <InvoiceHistory />
          </form>
        </div>
        <CancelPlanPopUp show={showCancelPopUp} onHide={setShowCancelPopUp} changeTexttoCanceled={changeTexttoCanceled} setShowCancelPopUp={setShowCancelPopUp} />
      </div>
    </>
  );
};

export default PaymentGateWay;
