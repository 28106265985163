import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import "assets/css/home.css";
import { useTranslation } from "react-i18next";
// English images
import gen_image_en from "assets/img/images/generate_image_en.png";
import enhance_image_en from "assets/img/images/enhance_image_en.png";
import scribble_image_en from "assets/img/images/scribble_image_en.png";
import no_bckgrnd_en from "assets/img/images/no_bckgrnd_en.png";
import change_bckgrnd_en from "assets/img/images/change_bckgrnd_en.png";
import upscale_image_en from "assets/img/images/upscale_img.png";
import gen_image_ar from "assets/img/images_ar/PhotoGenAR-min.png";
import change_bckgrnd_ar from "assets/img/images_ar/AddBGAR-min.png";
import gen_gif_en from "assets/img/gif_en/Photo_Gen.gif";
import no_bckgrnd_gif_en from "assets/img/gif_en/No_BG.gif";
import change_bckgrnd_gif_en from "assets/img/gif_en/ADD_BG (1).gif";
import enhance_gif_en from "assets/img/gif_en/Enhance.gif";
import scribble_gif_en from "assets/img/gif_en/Scribble.gif";
import upscale_gif_en from "assets/img/gif_en/Upscale Edit-min (1).gif";
import enhance_en from "assets/img/newDesign/ChangeStyle.gif";
import enhance_e from "assets/img/newDesign/ChangeStyle.png";
import enhance_a from "assets/img/newDesign/ChangeStyle.png";
import enhance_ar from "assets/img/newDesign/ChangeStyleA.gif";
import Redesign from "assets/img/newDesign/imageSectionVideos/ImageVariatio.gif";
import headShot from "assets/img/newDesign/imageSectionVideos/HeadShot.png";
import headShot_gif from "assets/img/newDesign/imageSectionVideos/HeadShot-min.gif";
import redesign_image from "assets/img/newDesign/reimaging.png";
import gen_gif_ar from "assets/img/gif_ar/Photo_Gen_AR.gif";
import change_bckgrnd_gif_ar from "assets/img/gif_ar/ADD_BG_AR.gif";
import Marquee from "react-double-marquee";
import { Link } from "react-router-dom";
import ImagesFilterComponent from "views/MediaSection/ImagesFilterComponent";
import { ReactComponent as Crown } from "assets/img/newDesign/Crown.svg";

const ImagesTools = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const features = [
    {
      name: "Generate Image",
      arabic_name: "حول النص إلى صورة",
      link: "create",
      video: gen_gif_en,
      videoAr: gen_gif_ar,
      videoH: gen_image_en,
      videoHA: gen_image_ar,
      videom: gen_image_en,
      videomAr: gen_image_ar,
      access: ["Free"],
    },
    {
      name: "Image Enhance",
      arabic_name: "تحسين الصورة",
      link: "enhance",
      video: enhance_gif_en,
      videoH: enhance_image_en,
      videom: enhance_image_en,
      videomAr: enhance_image_en,
      access: ["Free"],
    },
    {
      name: "Head Shot",
      arabic_name: " صورة رسمية",
      link: "head",
      video: headShot_gif,
      videoH: headShot,
      videom: headShot,
      videomAr: headShot,
      access: ["Free"],
    },
    {
      name: "Re-Design",
      arabic_name: "إعادة تخيل الصورة",
      link: "resize",
      video: Redesign,
      videoH: redesign_image,
      videom: redesign_image,
      videomAr: redesign_image,
      access: ["Pro"],
      icon: Crown,
    },

    // {
    //   name: "Reimagine V2",
    //   arabic_name: "إعادة تخيل الصورة",
    //   link: "resizev2",
    //   video: Redesign,
    //   videoH: redesign_image,
    //   videom: redesign_image,
    //   videomAr: redesign_image,
    // },
    // {
    //   name: "Change Style",
    //   arabic_name: "تغيير نمط الصورة",
    //   link: "image_design",
    //   video: enhance_en,
    //   videoAr: enhance_ar,
    //   videoH: enhance_e,
    //   videoHA: enhance_a,
    //   videom: enhance_e,
    //   videomAr: enhance_a,
    // },
    {
      name: "Scribble",
      arabic_name: "شخابيط إلى رسم",
      link: "scribble",
      video: scribble_gif_en,
      videoH: scribble_image_en,
      videom: scribble_image_en,
      videomAr: scribble_image_en,
      access: ["Basic"],
    },
    {
      name: "Background Remover",
      arabic_name: "مسح خلفية الصور",
      link: "remover",
      video: no_bckgrnd_gif_en,
      videoH: no_bckgrnd_en,
      videom: no_bckgrnd_en,
      videomAr: no_bckgrnd_en,
      access: ["Basic"],
    },
    {
      name: "Replace Background",
      arabic_name: "تغيير خلفية الصور",
      link: "painting",
      video: change_bckgrnd_gif_en,
      videoAr: change_bckgrnd_gif_ar,
      videoH: change_bckgrnd_en,
      videoHA: change_bckgrnd_ar,
      videom: change_bckgrnd_en,
      videomAr: change_bckgrnd_ar,
      access: ["Basic"],
    },
    {
      name: "Image Upscale",
      arabic_name: "تكبير الصورة",
      link: "upscale",
      video: upscale_gif_en,
      videoH: upscale_image_en,
      videom: upscale_image_en,
      videomAr: upscale_image_en,
      access: ["Basic"],
    },
  ];
  return (
    <div
      style={{
        gap: "20px",
        padding: "0px",
        height: "calc(100vh - 110px)",
        position: "relative",
      }}
      className="page_wrapper border-radius-top"
    >
      <div style={{ padding: "0px" }} className="image_gen_outer_wraaper border-radius-top" id="image_gen_outer_wraaper">
        <ImagesFilterComponent />
        <h2
          style={{
            fontWeight: "600",
            color: "black",
            fontSize: "1.5rem",
            textAlign: lng === "arab" ? "right" : "left",
            marginBottom: "0",
          }}
        >
          {t("Image Tools")}
        </h2>
        <div
          style={{
            margin: null,
            maxWidth: null,
            padding: "40px 20px 15px 15px",
          }}
          className="write_body border-radius-top"
        >
          <div
            style={{
              paddingBottom: "15px",
              minHeight: "300px",
              justifyContent: "flex-start",
            }}
            className="all_images_wrapper row"
          >
            {features?.length === 0 ? (
              <label>{t("No Items Found")}</label>
            ) : (
              features?.map((item, i) => (
                <VideoCard
                  key={i}
                  title={lng === "arab" ? item?.arabic_name : item?.name}
                  index={i}
                  videoAr={item?.videoAr}
                  video={item?.video}
                  thumb={lng === "arab" ? item?.videoHA || item?.videoH : item?.videoH}
                  videom={item?.videom}
                  videomAr={item?.videomAr}
                  goTo={item?.link}
                  access={item?.access}
                  Icon={item?.icon}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImagesTools;

const VideoCard = ({ title, video, index, goTo, thumb, videoAr, videom, videomAr, access, Icon }) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const cardRef = useRef();
  const textRef = useRef();
  const marqRef = useRef();
  const [displayMarq, setDisplayMarq] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [imageLoading, setImageLoading] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [media, setMedia] = useState("");

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    if (cardRef.current && textRef.current) {
      if (cardRef.current.offsetWidth - textRef.current.offsetWidth <= 15) {
        setDisplayMarq(true);
      }
    }
    if (cardRef.current && marqRef.current) {
      if (cardRef.current.offsetWidth - marqRef.current.offsetWidth > 15) {
        setDisplayMarq(false);
      }
    }
  }, [cardRef.current?.offsetWidth, title, textRef.current, width, marqRef.current, location.pathname]);

  useEffect(() => {
    if (location.pathname.includes("images")) {
      setMedia("images");
    } else if (location.pathname.includes("audios")) {
      setMedia("audios");
    } else if (location.pathname.includes("videos")) {
      setMedia("videos");
    }
  }, [location.pathname]);

  return (
    <div
      onMouseOver={() => setHovering(true)}
      onMouseOut={() => setHovering(false)}
      key={index}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className={"col-xl-4 col-lg-4 col-md-6 col-sm-6"}
    >
      <Link style={{ width: "100%", borderRadius: "1.2rem", position: "relative" }} ref={cardRef} to={`/photogen/${media}/${goTo}`} className="">
        {imageLoading && (
          <div className="">
            <Spinner animation="grow" />
          </div>
        )}
        {access.map((item, i) => (
          <span
            key={i}
            style={{
              position: "absolute",
              zIndex: 10,
              background: item === "Pro" ? "#FFC2C2" : item === "Basic" ? "#B6FFAA" : item === "Starter" ? "#FFEDAF" : "#E4EFFF",
              borderRadius: "15px",
              right: lng === "arab" ? null : "15px",
              left: lng === "arab" ? "15px" : null,
              top: "15px",
              color: item === "Pro" ? "#7D0D0D" : item === "Basic" ? "#139A07" : item === "Starter" ? "#9A4E07" : "#07549A",
              fontSize: "0.6rem",
              padding: "3px 10px",
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {item === "Pro" && <Icon />}
            {t(item)}
          </span>
        ))}
        <img
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "1.2rem",
            transition: "transform 0.4s ease-out, height 0.4s ease-out",
            transform: hovering ? "scale(1)" : "scale(0.95)",
          }}
          src={width <= 900 ? (lng === "arab" ? videomAr : videom) : !hovering ? thumb : lng === "arab" ? videoAr || video : video}
          alt={title}
        />
        <div style={{ width: "100%" }} className="cards_mobile">
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: hovering ? "1.2rem" : null,
            }}
            className={`videoBtnEffect`}
          >
            {!displayMarq ? (
              <span>
                <label style={{ whiteSpace: "nowrap" }} ref={textRef}>
                  {title}
                </label>
              </span>
            ) : (
              <div className={lng === "arab" ? "cssmarquee_arab" : "cssmarquee"} style={{ direction: "ltr" }}>
                <Marquee speed={0.025} delay={0} childMargin={15} direction={lng === "arab" ? "right" : "left"} className="label_of_title">
                  <label ref={marqRef}>{title}</label>
                </Marquee>
              </div>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};
