import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import backArow from "../../assets/img/newDesign/backArrow.svg";
import { useMouseMove } from "./useMouseMove.ts";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { t } from "i18next";
import { aiAPI } from "api";
import UpgradeModal from "components/UpgradeModal/UpgradeModal";
import { toastAlert } from "components/toastAlert/toastAlert";
import axios from "axios";
import TextareaAutosize from "react-textarea-autosize";
import TextAnimation from "components/TextAnimation";
import plansLimits from "assets/data/plansLimits";
import ClaimFreeModal from "components/ClaimFreeModal/ClaimFreeModal";
import LoadingEffect from "components/Loading/LoadingEffect";
import LimitPopUp from "views/plansComponent/LimitPopUp";
const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
]

const CodeGen = () => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const { name } = useParams();
  const dispatch = useDispatch();
  const chatRef = useRef();
  const { loading, code } = useSelector((state) => state?.cases);
  const [innerLoadingSwitch, setInnerLoadingSwitch] = useState(false);
  const [languageLoading, setLanguageLoading] = useState(true);
  const [loadingSwitch, setLoadingSwitch] = useState(
    loading || languageLoading
  );

  const [width, setWidth] = useState(window.innerWidth);
  const [currentFormats, setCurrentFormats] = useState(formats);

  const isMobile = width <= 768;
  const isTablet = width <= 850;
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }


  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    setCurrentFormats(isMobile || isTablet ? [
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
      'video',
    ] : formats)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const [compilerLang, setComilerLang] = useState("");
  const [finalElemnt, setFinalElement] = useState();
  const [textGenerated, setTextGenerated] = useState([]);
  const user = useSelector((state) => state.user);
  const [wordLimit, setWordLimit] = useState(0);
  const [totalWords, setTotalWords] = useState(0);
  const [imageLimit, setImageLimit] = useState(0);
  const [totalImages, setTotalImages] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [compiledData, setCompiledData] = useState("");
  const [codeText, setCodeText] = useState(
    `function add(a, b) {\n  return a + b;\n}`
  );
  const [chatSimulator, setChatSimulator] = useState([
    {
      sender: lng === "arab" ? "عربي AI" : "Twwt AI",
      text: lng === "arab" ? "كيف يمكنني مساعدتك؟" : "How can I help you?",
    },
  ]);
  const [processing, setProcessing] = useState(null);
  const [claimPopUp, setClaimPopUp] = useState(false)

  useEffect(() => {
    if (chatRef.current) {
      let elm = document.getElementById("chat_content");
      elm.scrollTo({
        top: elm.offsetHeight,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [chatRef.current, chatSimulator]);
  useEffect(() => {
    setCodeText(
      name === "HTML" ?
        textGenerated?.text?.slice(0)?.shift()?.text :
        textGenerated?.text?.slice(0)?.shift()?.text?.replace(/(<([^>]+)>)/gi, "")
    );
  }, [textGenerated]);
  // useEffect(() => {
  //   if (compilerLang && codeText) {
  //     handleCompile()
  //   }
  // }, [codeText, compilerLang])
  useEffect(() => {
    setLoadingSwitch(loading || languageLoading);
  }, [loading, languageLoading]);
  useEffect(() => {
    setLanguageLoading(true);
    setComilerLang(
      name === "C++"
        ? 52
        : name === "Java"
          ? 62
          : name === "Javascript"
            ? 63
            : name === "Python"
              ? 71
              : name === "CSS"
                ? ""
                : name === "HTML"
                  ? ""
                  : name === "C"
                    ? 50
                    : name === "C#"
                      ? 51
                      : name === "Dart"
                        ? 90
                        : name === "PHP"
                          ? 68
                          : name === "Ruby"
                            ? 72
                            : ""
    );
    setLanguageLoading(false);
  }, []);
  const [inputValue, setInputValue] = useState("");

  const userSubscription = useSelector((state) => state.userSubscription);
  const userEnterprise = useSelector((state) => state.enterprise);
  useEffect(() => {
    setImageLimit(userSubscription?.product?.metadata?.image_count_left || plansLimits[user?.plan]?.image_limit || userEnterprise?.enterprise?.image_limit)
    setWordLimit(userSubscription?.product?.metadata?.text_count_left || plansLimits[user?.plan]?.word_limit || userEnterprise?.enterprise?.text_limit)
    setTotalWords(user.text_count_left)
    setTotalImages(user?.image_count_left)
  }, [userSubscription, user, userEnterprise])
  const [errorHandling, setErrorHandling] = useState({
    status: false,
    message: "",
  });
  useEffect(() => {
    if (name) {
      let element = code
        ?.filter((item) => item?.name === decodeURIComponent(name))
        ?.slice(0)
        ?.shift();
      if (element) {
        setErrorHandling({ status: false, message: "" });
        setFinalElement(element);
      } else {
        setErrorHandling({
          status: true,
          message: "Please select another programming language",
        });
      }
    }
  }, [name, code]);

  const handleRegularShare = () => {
    toastAlert("Copied", "Text has been copied to clipboard", "success");
  };
  const generateText = (e) => {
    e.preventDefault();
    if (totalWords === 0) {
      // if (user?.plan === 'Trial' && !user?.offer_claimed) {
      //   return setClaimPopUp(true)
      // } else {
      return setShowModal(true);
      // }
    } else {
      // setTextGenerated([]);
      setInnerLoadingSwitch(true);
      if (!inputValue) {
        setInnerLoadingSwitch(false);
        return toastAlert(
          `There is some missing data, please check your form`,
          undefined,
          "warning"
        );
      }
      let chatArr = [...chatSimulator, { sender: "You", text: inputValue }];
      setChatSimulator(chatArr);
      setProcessing(null);
      setCompiledData("");
      setCodeText("");
      setInputValue("");
      aiAPI(
        {
          prompt: chatArr
            ?.filter((item) => item?.sender === "You")
            ?.map((item) => item?.text)
            ?.join(", "),
          codeLanguage: finalElemnt?.key,
        },
        "generate-code",
        setTextGenerated,
        setInnerLoadingSwitch,
        setErrorHandling,
        dispatch,
        'programming',
        textGenerated
      );
    }
  };
  const checkStatus = async (token) => {
    setProcessing("Checking the ability of displaying..");
    const options = {
      method: "GET",
      url: "https://judge0-ce.p.rapidapi.com/submissions" + "/" + token,
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "X-RapidAPI-Key": "574237ec90msh7564abd7bec6de9p1b696djsn4c081cd889ed",
        "X-RapidAPI-Host": "judge0-ce.p.rapidapi.com",
      },
    };
    try {
      let response = await axios.request(options);
      let statusId = response.data.status?.id;

      // Processed - we have a result
      if (statusId === 1 || statusId === 2) {
        // still processing
        setTimeout(() => {
          checkStatus(token);
        }, 2000);
        return;
      } else {
        setProcessing("false");
        setCompiledData(response.data);
        return;
      }
    } catch (err) {
      setProcessing("Failed To Load, Try another command");
    }
  };
  const getOutput = (outputDetails) => {
    let statusId = outputDetails?.status?.id;

    if (statusId === 6) {
      return (
        <pre className="px-2 py-1 font-normal text-xs text-red-500">
          {atob(outputDetails?.compile_output)}
        </pre>
      );
    } else if (statusId === 3) {
      if (outputDetails?.stdout) {
        return (
          <pre className="px-2 py-1 font-normal text-xs text-green-500">
            {atob(outputDetails.stdout) !== null
              ? `${atob(outputDetails.stdout)}`
              : null}
          </pre>
        );
      } else {
        setProcessing("This code is not able to be compiled");
      }
    } else if (statusId === 5) {
      return (
        <pre className="px-2 py-1 font-normal text-xs text-red-500">
          {`Time Limit Exceeded`}
        </pre>
      );
    } else {
      return (
        <pre className="px-2 py-1 font-normal text-xs text-red-500">
          {atob(outputDetails?.stderr)}
        </pre>
      );
    }
  };
  const handleCompile = () => {
    setProcessing("Collecting Data..");
    const formData = {
      language_id: compilerLang,
      // encode source code in base64
      source_code: btoa(codeText),
      stdin: btoa(""),
    };
    const options = {
      method: "POST",
      url: " https://judge0-ce.p.rapidapi.com/submissions",
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "content-type": "application/json",
        "Content-Type": "application/json",
        "X-RapidAPI-Key": "574237ec90msh7564abd7bec6de9p1b696djsn4c081cd889ed",
        "X-RapidAPI-Host": "judge0-ce.p.rapidapi.com",
      },
      data: formData,
    };

    axios
      .request(options)
      .then(function (response) {
        const token = response.data.token;
        checkStatus(token);
      })
      .catch((err) => {
        let error = err.response ? err.response.data : err;
        setProcessing("Failed To Load, Try another command");
      });
  };
  const [objectsList, setObjectsList] = useState([])
  const handleClick = (item, i) => {
    // <.log('here')
    // objectsList?.filter(inner => inner === {...item, isOpen: inner?.isOpen})?.slice(0)?.shift()?.isOpen
    setObjectsList(
      objectsList.map((e, index) => index === i ? ({ ...e, isOpen: !e.isOpen }) : (e))
    )
  }
  useEffect(() => {
    setObjectsList(
      textGenerated.map(e => ({ ...e, isOpen: true }))
    );
  }, [textGenerated])
  var arabic = /[\u0600-\u06FF]/;
  return (
    <div className="inner_content">
      <div className="categories_header subCtegoryHeader">
        <Link to={`/writecode`} className="sub_case_header">
          <img src={backArow} alt="back_button" />
          <label style={{ direction: arabic.test(finalElemnt?.name) ? 'rtl' : 'ltr' }}>{finalElemnt?.name}</label>
        </Link>
      </div>

      <div style={{ margin: name ? "0px" : null, maxWidth: name ? "unset" : null }} className="write_body inner_writeBody container">
        {errorHandling.status ? (
          <div className="all_images_wrapper">
            <label>{errorHandling?.message}</label>
          </div>
        ) : loadingSwitch ? (
          <div
            style={{
              minHeight: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner animation="border" role="status" />
          </div>
        ) : (
          <div className="sub_case_page_wrapper row">
            <div style={{ backgroundColor: "#fafdff", overflow: width <= 768 ? "none" : "auto" }} className="left_hand_side left_hand_side_form col-lg-5 col-md-6 col-sm-12">
              <div className="form_inner">
                <div ref={chatRef} className="chat_wrapper">
                  <form
                    onSubmit={generateText}
                    style={{
                      flexDirection: "row",
                      rowGap: "10px",
                      justifyContent: "flex-end",
                      padding: "0px",
                    }}
                    className="row"
                  >
                    <TextareaAutosize
                      autoFocus
                      value={inputValue}
                      style={{ height: "40px", overflow: "hidden" }}
                      className="form-control"
                      placeholder={t("ex. do it like…")}
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                    {/* <TextAreaCustom
                      value={inputValue}
                      handleFormChange={(text) => setInputValue(text)}
                    /> */}
                    {/* <button
                      type="submit"
                      className="codeFormSubmit col-lg-2 col-md-2 col-sm-2"
                    >
                      {t("Enter")}
                    </button> */}
                    <div className="col-lg-4 col-md-4 col-sm-6">
                      <button
                        style={{
                          padding: isMobile || isTablet ? "15px 40px !important" : "15px 60px !important",
                          background: innerLoadingSwitch ? "gray" : "linear-gradient(to right, #d851ef, #1877f2)",
                          pointerEvents: innerLoadingSwitch && "none",
                          width: "100%",
                        }}
                        className="codeFormSubmit "
                        type="submit"
                      >
                        {innerLoadingSwitch ? <LoadingEffect /> : t("Enter")}
                      </button>
                    </div>
                  </form>
                  <div id="chat_content" className="chat_content">
                    {chatSimulator?.map((item, i) => (
                      <div style={{ textAlign: lng === "arab" ? "right" : "left" }} className="chat_item" key={i}>
                        <label style={{ fontSize: "17px" }}>{item?.sender}</label>
                        <TextAnimation
                          content={item?.text}
                          style={{
                            color: item?.sender === "You" ? "#72B4F7" : "#000",
                            cursor: item?.sender === "You" ? "text" : null,
                            fontSize: "17px",
                          }}
                        />
                        {/* <p onClick= style={>{item?.text}</p> */}
                      </div>
                    ))}
                  </div>
                </div>
                {/* {compilerLang?
                <div className='compiler_wrapper'>
                  {processing?
                  <label style={{fontSize: '12px', color: 'gray', opacity: '0.6'}}>{processing}</label>:
                  compiledData?
                    getOutput(compiledData):
                    <label style={{fontSize: '12px', color: 'gray', opacity: '0.6'}}>{t("This is a compiler area, If your code is able to be compiled, The output will be shown here")}</label>}
                </div>:null} */}
              </div>
            </div>
            <div style={textGenerated?.length ? { alignItems: "flex-start", overflow: width <= 900 ? "unset" : "auto", maxHeight: "calc(100vh - 100px)", padding: "20px" } : { padding: "20px" }}
              className="right_hand_side_category row col-lg-7 col-md-6 col-sm-12">
              {innerLoadingSwitch ? (
                <Spinner animation="border" role="status" />
              ) : !textGenerated?.length ? (
                <label>{t("Please Fill the form")}</label>
              ) : null}
              {textGenerated?.map((item, i) => (
                <div
                  key={i}
                  style={{
                    direction: lng === "arab" ? "rtl" : "ltr",
                    textAlign: lng === "arab" ? "right" : "left",
                    minHeight: !objectsList
                      ?.filter((inner, inerI) => inerI === i)
                      ?.slice(0)
                      ?.shift()?.isOpen
                      ? "auto"
                      : null,
                  }}
                  className="text_card col-lg-12 col-md-12 col-sm-12"
                >
                  <button onClick={() => handleClick(item, i)} style={{ backgroundColor: "transparent", border: "unset" }} className="collapse_wrapper">
                    <p style={{ margin: "0px" }}>
                      {
                        chatSimulator
                          ?.filter((item) => item?.sender === "You")
                          .filter((item, index) => index === i)
                          ?.slice(0)
                          ?.shift()?.text
                      }
                    </p>
                    <img style={{ height: "15px", width: "20px", transform: "rotate(270deg)" }} src={backArow} alt="back_button" />
                  </button>
                  {objectsList
                    ?.filter((inner, inerI) => inerI === i)
                    ?.slice(0)
                    ?.shift()?.isOpen ? (
                    <>
                      <div className="text_card_code">
                        <div className="editor">
                          <div style={{ width: "100%" }}>
                            <div id="left-handler_code"></div>
                            <div id="right-handler_code"></div>
                            <div className="container_code">
                              <CodeEditorWindow code={item?.text?.slice(0)?.shift()?.text} setCode={setCodeText} name={finalElemnt?.name} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text_card_footer">
                        <p
                          className={lng === "arab" ? "arabicFont" : "englishFont"}
                          style={{ fontFamily: lng === "arab" ? '"Tajawal"' : null, opacity: "0.5", direction: lng === "arab" ? "rtl" : "ltr" }}
                        >
                          {item?.text?.slice(0)?.shift()?.word_count} {lng === "arab" ? "كلمة" : "words"}
                        </p>
                        <div className="text_card_actions_wrapper">
                          {/* save */}
                          {/* <svg xmlns="http://www.w3.org/2000/svg" width="9.345" height="12.744" viewBox="0 0 9.345 12.744">
                                <path id="saved" d="M13.92,13.743a.425.425,0,0,1-.243-.077l-4-2.8-4,2.8A.425.425,0,0,1,5,13.318V3.039A2.041,2.041,0,0,1,7.039,1h5.267a2.041,2.041,0,0,1,2.039,2.039V13.318A.425.425,0,0,1,13.92,13.743ZM9.672,9.92A.425.425,0,0,1,9.916,10L13.5,12.5V3.039A1.189,1.189,0,0,0,12.306,1.85H7.039A1.189,1.189,0,0,0,5.85,3.039V12.5L9.429,10A.425.425,0,0,1,9.672,9.92Z" transform="translate(-5 -1)" fill="#b1b1b1"/>
                            </svg> */}

                          {/* share */}
                          {/* <svg id="document" xmlns="http://www.w3.org/2000/svg" width="9.707" height="12.744" viewBox="0 0 9.707 12.744">
                                <path id="Path_10307" data-name="Path 10307" d="M70.279,2.645,67.879.374A1.364,1.364,0,0,0,66.938,0H62.369A1.371,1.371,0,0,0,61,1.369V11.375a1.37,1.37,0,0,0,1.369,1.369h6.969a1.37,1.37,0,0,0,1.369-1.369V3.639a1.375,1.375,0,0,0-.428-.994Zm-.725.342H67.7a.125.125,0,0,1-.124-.124V1.111ZM69.338,12H62.369a.623.623,0,0,1-.622-.622V1.369a.623.623,0,0,1,.622-.622h4.455V2.862a.872.872,0,0,0,.871.871H69.96v7.641A.623.623,0,0,1,69.338,12Z" transform="translate(-61 0)" fill="#b1b1b1"/>
                                <path id="Path_10308" data-name="Path 10308" d="M133.849,200h-5.476a.373.373,0,1,0,0,.747h5.476a.373.373,0,1,0,0-.747Z" transform="translate(-126.332 -195.022)" fill="#b1b1b1"/>
                                <path id="Path_10309" data-name="Path 10309" d="M133.849,280h-5.476a.373.373,0,1,0,0,.747h5.476a.373.373,0,1,0,0-.747Z" transform="translate(-126.332 -273.031)" fill="#b1b1b1"/>
                                <path id="Path_10310" data-name="Path 10310" d="M130.183,360h-1.81a.373.373,0,1,0,0,.747h1.81a.373.373,0,1,0,0-.747Z" transform="translate(-126.332 -351.04)" fill="#b1b1b1"/>
                            </svg> */}

                          {/* copy */}
                          <CopyToClipboard
                            onCopy={() => handleRegularShare()}
                            text={
                              name === "HTML"
                                ? item?.text?.slice(0)?.shift()?.text
                                : item?.text
                                  ?.slice(0)
                                  ?.shift()
                                  ?.text?.replace(/(<([^>]+)>)/gi, "")
                            }
                          >
                            <button className="words_action_btn">
                              <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="12.424" height="12.424" viewBox="0 0 12.424 12.424">
                                <g id="Group_2026" data-name="Group 2026">
                                  <path
                                    id="Path_10306"
                                    data-name="Path 10306"
                                    d="M13.117,2.16H5.543A1.466,1.466,0,0,0,4.081,3.627v.454H3.622A1.466,1.466,0,0,0,2.16,5.548v7.569a1.466,1.466,0,0,0,1.462,1.467H11.2a1.468,1.468,0,0,0,1.467-1.467v-.454h.454A1.469,1.469,0,0,0,14.584,11.2V3.627A1.469,1.469,0,0,0,13.117,2.16ZM11.765,13.117a.57.57,0,0,1-.569.569H3.622a.567.567,0,0,1-.565-.569V5.548a.568.568,0,0,1,.565-.569H11.2a.57.57,0,0,1,.569.569v7.569ZM13.686,11.2a.57.57,0,0,1-.569.569h-.454V5.548A1.468,1.468,0,0,0,11.2,4.081H4.978V3.627a.567.567,0,0,1,.565-.569h7.574a.57.57,0,0,1,.569.569Z"
                                    transform="translate(-2.16 -2.16)"
                                    fill="#b1b1b1"
                                  />
                                </g>
                              </svg>
                            </button>
                          </CopyToClipboard>
                          {/* like */}
                          {/* <svg xmlns="http://www.w3.org/2000/svg" width="12.371" height="12.424" viewBox="0 0 12.371 12.424">
                                <path id="like" d="M12.412,6.563H10.028l.539-2.426a1.6,1.6,0,0,0-.6-1.622L9.6,2.237a1.182,1.182,0,0,0-1.736.355,29.5,29.5,0,0,1-2.5,3.816.444.444,0,0,0-.4-.254H2.5a.444.444,0,0,0-.444.444v6.972a.444.444,0,0,0,.444.444H4.963a.438.438,0,0,0,.432-.383,2.46,2.46,0,0,0,1.8.793H9.632a2.443,2.443,0,0,0,2.037-1.091L14.091,9.7a2.018,2.018,0,0,0-1.679-3.136ZM4.52,13.125H2.946V7.04H4.52Zm8.833-3.917L10.931,12.84a1.559,1.559,0,0,1-1.3.7H7.2a1.569,1.569,0,0,1-1.249-.625l-.54-.72V7.634a21.449,21.449,0,0,0,3.222-4.6.289.289,0,0,1,.2-.141.3.3,0,0,1,.239.053l.37.278a.71.71,0,0,1,.266.721L9.043,6.911a.444.444,0,0,0,.433.54h2.938a1.131,1.131,0,0,1,.941,1.757Z" transform="translate(-2.059 -1.999)" fill="#b1b1b1"/>
                            </svg> */}

                          {/* dislike */}
                          {/* <svg xmlns="http://www.w3.org/2000/svg" width="12.371" height="12.424" viewBox="0 0 12.371 12.424">
                                <path id="like" d="M4.076,9.858H6.46l-.539,2.426a1.6,1.6,0,0,0,.6,1.622l.371.278a1.182,1.182,0,0,0,1.736-.355,29.5,29.5,0,0,1,2.5-3.816.444.444,0,0,0,.4.254h2.459a.444.444,0,0,0,.444-.444V2.853a.444.444,0,0,0-.444-.444H11.525a.438.438,0,0,0-.432.383A2.46,2.46,0,0,0,9.292,2H6.856A2.443,2.443,0,0,0,4.819,3.09L2.4,6.722A2.018,2.018,0,0,0,4.076,9.858ZM11.969,3.3h1.573V9.381H11.969ZM3.136,7.214,5.557,3.582a1.559,1.559,0,0,1,1.3-.7H9.292a1.569,1.569,0,0,1,1.249.625l.54.72V8.787a21.449,21.449,0,0,0-3.222,4.6.289.289,0,0,1-.2.141.3.3,0,0,1-.239-.053l-.37-.278a.71.71,0,0,1-.266-.721l.658-2.966a.444.444,0,0,0-.433-.54H4.075a1.131,1.131,0,0,1-.941-1.757Z" transform="translate(-2.059 -1.999)" fill="#b1b1b1"/>
                            </svg> */}
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              ))
              }
            </div>
          </div>
        )}
      </div>
      <ClaimFreeModal showModal={claimPopUp} setShowModal={setClaimPopUp} />
      {user?.plan === "Trial" ? (
        <UpgradeModal setShowModal={setShowModal} showModal={showModal} />


      ) : (
        <LimitPopUp setShowModal={setShowModal} showModal={showModal} />
      )}
    </div>
  );
};

export default CodeGen;

const CodeEditorWindow = ({ isDarkTheme, code, setCode, name }) => {
  return (
    <CodeEditor
      value={code}
      language={
        name === "C++"
          ? "cpp"
          : name === "Java"
            ? "java"
            : name === "Javascript"
              ? "js"
              : name === "Python"
                ? "py"
                : name === "CSS"
                  ? "css"
                  : name === "HTML"
                    ? "html"
                    : name === "C"
                      ? "c"
                      : name === "C#"
                        ? "cs"
                        : name === "Dart"
                          ? "dart"
                          : name === "PHP"
                            ? "php"
                            : name === "Ruby"
                              ? "rb"
                              : "js"
      }
      // onChange={(evn) => setCode(evn.target.value)}
      padding={15}
      style={{
        fontSize: 12,
        backgroundColor: "#fff",
        fontFamily:
          "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
      }}
    />
  );
};
