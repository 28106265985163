import { FETCH_BLOG } from "redux/contstants";
import { LOAD_BLOG } from "redux/contstants";
import { BLOG_LOADED } from "redux/contstants";

export default (blogs = { blogs: [], totalDocs: 0, loading: false }, action) => {
    switch (action.type) {
        case FETCH_BLOG:
            return { ...blogs, blogs: action.data?.docs, totalDocs: action.data?.totalDocs , loading: false };
        case LOAD_BLOG:
            return { ...blogs, loading: true };
        case BLOG_LOADED:
            return { ...blogs, loading: false };
        default:
            return blogs;
    }
}