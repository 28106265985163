import React, { useState, useEffect } from "react";
import "./history.scss";
import { useSelector } from "react-redux";
import imageDefault from "../../assets/img/newDesign/imageDefault.png";
import ImageGenerated from "components/imageGenerated/ImageGenerated";
import { useTranslation } from "react-i18next";
import { getAllOldImages } from "redux/actions/history";
import circleSVG from "../../assets/img/newDesign/circleBC.svg";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import { getAllImages } from "../../redux/actions/image";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { baseUrl } from "api/baseUrl";
import axios from "axios";

const PhotoHistory = () => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const navigate = useNavigate();
  const { oldImages } = useSelector((state) => state.history);
  const { imagePagination } = useSelector((state) => state.history);
  const oldImagesLoading = useSelector((state) => state.history.loading);
  const { images, loading, title } = useSelector((state) => state.Images);
  const user = useSelector((state) => state.user);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [historyData, setHistoryData] = useState();
  const [userData, setUserData] = useState({
    prompt: "",
    aspectRatio: "1:1",
    selectStyle: "",
    outputNumber: user?.plan === "Trial" ? 1 : 4,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    getHistoryData(page);
  }, [page]);

  const getHistoryData = async (page) => {
    setLoader(true);
    const res = await axios.get(
      `${baseUrl}textGeneration/getImageHistory?page=${page}`
    );
    setHistoryData(res?.data);
    setLoader(false);
  };

  const moreLikeThis = (text) => {
    navigate("/photogen/create");
    setUserData({ ...userData, prompt: text });
    dispatch(getAllImages({ ...userData, prompt: text }));
  };
  return (
    <>
      <div
        className="all_images_wrapper row height "
        style={{ marginLeft: "0", marginRight: "0", overflow: "auto" }}
      >
        {loader ? (
          <Spinner animation="border" role="status" />
        ) : historyData?.history?.length === 0 ? (
          <ImageGenNotFoundReplacement />
        ) : (
          historyData?.history.map((item, i) => {
            return item?.urls?.map((inner, i) => (
              <ImageGenerated
                userData={userData}
                moreLikeThis={moreLikeThis}
                key={i}
                item={inner}
                i={i}
                title={item?.prompt}
              />
            ));
          })
        )}
      </div>
      <div
        className="table_footer"
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          className="table_footer_buttons"
          style={{ width: "100%", justifyContent: "space-between" }}
        >
          <button
            style={{
              marginLeft: "20px",
              marginBottom: "20px",
              borderRadius: "15px",
              float: "right",
            }}
            className="form_buttions"
            disabled={page === 1}
            onClick={() => {
              page != 1 && setPage((page) => (page -= 1));
            }}
          >
            <ArrowForwardIosRoundedIcon
              fontSize="small"
              sx={{
                // color: !data?.hasNextPage ? "grey" : null,
                transform: lng === "arab" ? null : "rotate(180deg)",
              }}
            />
            Prev
          </button>
          <button
            style={{
              marginBottom: "20px",
              marginRight: "20px",
              borderRadius: "15px",
              float: "right",
            }}
            className="form_buttions"
            disabled={historyData?.hasNextPage === false}
            onClick={() => {
              historyData?.hasNextPage && setPage((page) => (page += 1));
            }}
          >
            Next
            <ArrowForwardIosRoundedIcon
              fontSize="small"
              sx={{
                transform: lng === "arab" ? "rotate(180deg)" : null,
              }}
            />
          </button>
        </div>
      </div>
    </>
  );
};
export default PhotoHistory;

const ImageGenNotFoundReplacement = ({ image, tip, prompt }) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  return (
    <div
      style={{
        justifyContent: "center",
        padding: "0 20px",
        minHeight: prompt ? "353px" : null,
      }}
      className="image_not_found_replaceMent row container"
    >
      <div className="image_inner_wrapper col-lg-5 col-md-6 col-sm-12">
        <img
          className="background_circle"
          src={circleSVG}
          alt="image_default"
        />
        <img
          className="image_not_found"
          style={{
            left: lng === "arab" ? "40px" : "auto",
            right: lng === "arab" ? "auto" : "40px",
          }}
          src={image || imageDefault}
          alt="image_default"
        />
      </div>
      <div className="image_inner_content_wrapper col-lg-5 col-md-6 col-sm-12">
        {prompt ? null : (
          <div>
            <h5>{t("Tip")}</h5>
            <p>
              {t(
                "The more descriptive you are, the better your image will turn our"
              )}
            </p>
          </div>
        )}
        <div style={{ minWidth: "350px", maxWidth: "350px" }}>
          <h5>{t("Examples")}</h5>
          <p>
            {prompt ||
              t(
                '"navy blue horse with bright white hair running through the dessert with the galaxy in the sky, night light, majestic"'
              )}
          </p>
        </div>
      </div>
    </div>
  );
};
