

import { USER_ENTERPRISE } from "redux/contstants";

export default (userEnterprise = {}, action) => {
    switch (action.type) {
        case USER_ENTERPRISE:
            return action.subscription;
        default:
            return userEnterprise;
    }
};
