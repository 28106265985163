import axios from "axios";
import { toastAlert } from "components/toastAlert/toastAlert";
import checkErr from "redux/actions/api_error_func";
import { baseUrl, blogBaseUrl } from "./baseUrl";

export const getCases = () => axios.get(`${baseUrl}use-cases`);
export const getBusinessCases = () => axios.get(`${baseUrl}business_use_cases`);
export const getNewCases = () => axios.get(`${baseUrl}use_case`);
export const getMostUsedCases = () => axios.get(`${baseUrl}use-case/most-used`);

export const getCasesCustom = async (url) => {
  try {
    let result = await fetch(url, { headers: { "Content-Type": "application/json", Accept: "application/json" } });
    let newResult = await result.json();
    return newResult;
  } catch (error) { }
};

export const getOldImages = () => axios.get(`${baseUrl}textGeneration/getImageHistory`);
export const getOldText = () => axios.get(`${baseUrl}textGeneration/getTextHistory`);

export const imgaiAPI = async (userData, endpoint, dispatch) => {
  return await axios({
    method: "post",
    url: `${baseUrl}sinkin-dream-shaper`,
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    data: JSON.stringify(userData),
  });
};

export const morelikethisAPI = async (userData, endpoint, dispatch) => {
  return await axios({
    method: "post",
    url: `${baseUrl}more_like_this`,
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    data: JSON.stringify(userData),
  });
};

export const aiAPI = async (userData, endpoint, setGeneratedText, setLoading, setErrorHandling, dispatch, str, textGenerated) => {
  fetch(`${baseUrl}${endpoint}`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: localStorage.getItem("sess")
      ? {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("sess"),
      }
      : { "Content-Type": "application/json" },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(userData),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) {
        dispatch({ type: "FETCH_USER" });
        if (str) {
          setGeneratedText([...textGenerated, data]);
        } else {
          setGeneratedText(data);
        }
        setLoading(false);
        // return data
      } else {
        if (data.message || data.error) {
          setLoading(false);
          toastAlert(data.message || data.error, undefined, "warning");
        }
      }
      setLoading(false);
    })
    .catch((e) => {
      checkErr(e, setLoading, toastAlert);
    });
};

export const autoLogin = async () => {
  return await axios.get(`${process.env.REACT_APP_AUTH_BASE_URL}autoLogin`, {
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });
};

export const userSubscription = async () => {
  return await axios.get(`${process.env.REACT_APP_BASE_URL}stripe/subscribe/get-latest`, {
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("sess"),
    },
  });
};

export const userEnterprise = async () => {
  return await axios.get(`${process.env.REACT_APP_ENTERPRISE_URL}enterprise`, {
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("sess"),
    },
  });
};

export const userBasicInfoOne = async (data) => {
  return await axios.post(`${baseUrl}user/fillBasicInfo/one`, data);
};

export const userBasicInfoTwo = async (data) => {
  let finalFormData = new FormData();
  finalFormData.append("file", data?.file);
  return await axios({
    method: "post",
    url: `${baseUrl}user/fillBasicInfo/two`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};

export const userBasicInfoThree = async (data) => {
  return await axios.post(`${baseUrl}user/fillBasicInfo/three`, data);
};

export const userBasicInfoFour = async (data) => {
  return await axios.post(`${baseUrl}user/fillBasicInfo/four`, data);
};

export const getBlogs = async (page, limit) => {
  return await axios.get(`${blogBaseUrl}blog/my_blogs?page=${page}&limit=${limit}`);
};

export const getBlogById = async (id) => {
  return await axios.get(`${blogBaseUrl}blog/${id}`);
};

export const blog = async (data) => {
  let finalFormData = new FormData();
  finalFormData.append("title_en", data?.title_en);
  finalFormData.append("title_ar", data?.title_ar);
  finalFormData.append("description_en", data?.description_en);
  finalFormData.append("description_ar", data?.description_ar);
  finalFormData.append("cover_image", data?.cover_image);
  finalFormData.append("cover_alt_en", data?.cover_alt_en);
  finalFormData.append("cover_alt_ar", data?.cover_alt_ar);
  finalFormData.append("banner_image", data?.banner_image);
  finalFormData.append("banner_alt_en", data?.banner_alt_en);
  finalFormData.append("banner_alt_ar", data?.banner_alt_ar);
  finalFormData.append("meta_description_en", data?.meta_description_en);
  finalFormData.append("meta_description_ar", data?.meta_description_ar);
  finalFormData.append("content_en", data?.content_en);
  finalFormData.append("content_ar", data?.content_ar);
  finalFormData.append("publishedAt", data?.publishedAt);
  return await axios({
    method: "post",
    url: `${blogBaseUrl}blog`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};

export const UpdateBlog = async (data, id) => {
  let finalFormData = new FormData();
  finalFormData.append("title_en", data?.title_en);
  finalFormData.append("title_ar", data?.title_ar);
  finalFormData.append("description_en", data?.description_en);
  finalFormData.append("description_ar", data?.description_ar);
  finalFormData.append("cover_alt_en", data?.cover_alt_en);
  finalFormData.append("cover_alt_ar", data?.cover_alt_ar);
  finalFormData.append("banner_alt_en", data?.banner_alt_en);
  finalFormData.append("banner_alt_ar", data?.banner_alt_ar);
  finalFormData.append("meta_description_en", data?.meta_description_en);
  finalFormData.append("meta_description_ar", data?.meta_description_ar);
  finalFormData.append("content_en", data?.content_en);
  finalFormData.append("content_ar", data?.content_ar);
  finalFormData.append("publishedAt", data?.publishedAt);
  if (data?.cover_image) {
    finalFormData.append("cover_image", data?.cover_image);
  }
  if (data?.banner_image) {
    finalFormData.append("banner_image", data?.banner_image);
  }
  return await axios({
    method: "put",
    url: `${blogBaseUrl}blog/${id}`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};

export const deleteBlogById = async (id) => {
  return await axios.delete(`${blogBaseUrl}blog/${id}`);
};

export const API_URL = (endpoint) => {
  return process.env.REACT_APP_BASE_URL + endpoint;
};

export const mostUsed = async () => {
  return await axios.get(`${baseUrl}use-case/most-used`, {
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });
};

//media section

export const scribble = async (data) => {
  return await axios({
    method: "post",
    url: `${baseUrl}image-scribble`,
    data: data,
  });
};

export const backgroundRemover = async (data) => {
  return await axios({
    method: "post",
    url: `${baseUrl}background-remover`,
    data: data,
  });
};

export const changeBackground = async (data) => {
  return await axios({
    method: "post",
    url: `${baseUrl}image-mockup`,
    data: data,
  });
};

export const redesign = async (data) => {
  return await axios({
    method: "post",
    url: `${baseUrl}image-reimagine`,
    data: data,
  });
};

export const textToAudio = async (data) => {
  return await axios({
    method: "post",
    url: `${baseUrl}media-generation/text-to-audio`,
    data: data,
  });
};

export const textToSpeech = async (data) => {
  return await axios({
    method: "post",
    url: `${baseUrl}media-generation/text-to-speech`,
    data: data,
  });
};

export const clipAudio = async (data) => {
  return await axios({
    method: "post",
    url: `${baseUrl}media-generation/split-audio`,
    data: data,
  });
};

export const textToVideo = async (data) => {
  return await axios({
    method: "post",
    url: `${baseUrl}media-generation/animate-diff`,
    data: data,
  });
};

export const removeVideoBackground = async (data) => {
  return await axios({
    method: "post",
    url: `${baseUrl}media-generation/remove-video-background`,
    data: data,
  });
};

export const reimagineV2 = async (data) => {
  return await axios({
    method: "post",
    url: `${baseUrl}clip_interrogator`,
    data: data,
  });
};

export const upscale = async (data) => {
  return await axios({
    method: "post",
    url: `${baseUrl}image-upscale`,
    data: data,
  });
};

export const upscaleV1 = async (data) => {
  return await axios({
    method: "post",
    url: `http://arabyai-app-test.me-south-1.elasticbeanstalk.com/image-upscale_v1`,
    data: data,
  });
};

export const enhance = async (data) => {
  return await axios({
    method: "post",
    url: `${baseUrl}enhance-image`,
    data: data,
  });
};




export const enhanceAudioLoading = async (id) => {
  return await axios({
    method: "get",
    url: `${baseUrl}media-generation/enhance-media/status?id=${id}`,
  });
};


export const enhanceAudioDownload = async (id) => {
  return await axios({
    method: "get",
    url: `${baseUrl}media-generation/pending/${id}`,
  });
};

export const headShot = async (data) => {
  return await axios({
    method: "post",
    url: `${baseUrl}media-generation/head-shot`,
    data: data,
  });
};


export const infiniteZoom = async (data) => {
  return await axios({
    method: "post",
    url: `${baseUrl}media-generation/infinite-zoom`,
    data: data,
  });
};

export const overlapAnimation = async (data) => {
  return await axios({
    method: "post",
    url: `${baseUrl}media-generation/overlap-animation`,
    data: data,
  });
};

export const enhanceAudio = async (data) => {
  return await axios({
    method: "post",
    url: `${baseUrl}media-generation/enhance-media`,
    data: data,
  });
};



