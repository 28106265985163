import React from "react";
import { Box } from "@mui/material";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { ReactComponent as Expand } from "../../../assets/img/Expand.svg";
import { ReactComponent as Idea } from "../../../assets/img/Idea.svg";
import { ReactComponent as Enhance } from "../../../assets/img/Bowling.svg";
import { ReactComponent as Knife } from "../../../assets/img/Knife.svg";
import { ReactComponent as List } from "../../../assets/img/List.svg";
import { ReactComponent as UpArr } from "../../../assets/img/Uparrow.svg";
import { useTranslation } from "react-i18next";

const AiTools = ({ submit, close, boundingRect, width }) => {
  const { t } = useTranslation();
  const lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const handleAiToolsChange = (data) => {
    submit(data);
    close(false);
  };

  return (
    <Box
      sx={{
        position: "absolute",
        background: "#fff",
        top: width < 991 ? "40px" : boundingRect > 530 ? "-255px" : "50px",
        border: "0.5px solid #D3D3D3",
        boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.08)",
        borderRadius: "5px",
        width: width < 991 ? "120px" : "240px",
        left: lng === "arab" ? "" : "-8%",
        right: lng === "arab" ? "-8%" : "",
      }}
    >
      <button
        onClick={() => handleAiToolsChange("expand")}
        style={{ display: "flex", justifyContent: "space-between", padding: width < 991 ? "8px 6px 6px 6px" : "15px 10px 10px 10px", minHeight: width < 991 ? "0px" : null, width: "95%" }}
      >
        <span style={{ display: "flex", gap: "10px", alignItems: "center", fontFamily: lng === "arab" ? "Tajawal" : "poppins" }}>
          <Expand />
          {t("Expand")}
        </span>
        <ChevronRightRoundedIcon style={{ color: "#D8D8D8", transform: lng === "arab" ? "rotate(180deg)" : "" }} />
      </button>
      <button
        onClick={() => handleAiToolsChange("shorten")}
        style={{ display: "flex", justifyContent: "space-between", padding: width < 991 ? "5px" : "10px", minHeight: width < 991 ? "0px" : null, width: "95%" }}
      >
        <span style={{ display: "flex", gap: "10px", alignItems: "center", fontFamily: lng === "arab" ? "Tajawal" : "poppins" }}>
          <Knife />
          {t("Shorten")}
        </span>
        <ChevronRightRoundedIcon style={{ color: "#D8D8D8", transform: lng === "arab" ? "rotate(180deg)" : "" }} />
      </button>
      <button
        onClick={() => handleAiToolsChange("enhance")}
        style={{ display: "flex", justifyContent: "space-between", padding: width < 991 ? "5px" : "10px", minHeight: width < 991 ? "0px" : null, width: "95%" }}
      >
        <span style={{ display: "flex", gap: "10px", alignItems: "center", fontFamily: lng === "arab" ? "Tajawal" : "poppins" }}>
          <Enhance />
          {t("Enhance")}
        </span>
        <ChevronRightRoundedIcon style={{ color: "#D8D8D8", transform: lng === "arab" ? "rotate(180deg)" : "" }} />
      </button>
      <button
        onClick={() => handleAiToolsChange("pharaphrase")}
        style={{ display: "flex", justifyContent: "space-between", padding: width < 991 ? "5px" : "10px", minHeight: width < 991 ? "0px" : null, width: "95%" }}
      >
        <span style={{ display: "flex", gap: "10px", alignItems: "center", fontFamily: lng === "arab" ? "Tajawal" : "poppins" }}>
          <UpArr />
          {t("Paraphrase")}
        </span>
        <ChevronRightRoundedIcon style={{ color: "#D8D8D8", transform: lng === "arab" ? "rotate(180deg)" : "" }} />
      </button>
      <button
        onClick={() => handleAiToolsChange("text to bullet")}
        style={{ display: "flex", justifyContent: "space-between", padding: width < 991 ? "5px" : "10px", minHeight: width < 991 ? "0px" : null, width: "95%" }}
      >
        <span style={{ display: "flex", gap: "10px", alignItems: "center", fontFamily: lng === "arab" ? "Tajawal" : "poppins" }}>
          <List />
          {t("Text to bullet")}
        </span>
        <ChevronRightRoundedIcon style={{ color: "#D8D8D8", transform: lng === "arab" ? "rotate(180deg)" : "" }} />
      </button>
      <button
        onClick={() => handleAiToolsChange("summarize")}
        style={{ display: "flex", justifyContent: "space-between", padding: width < 991 ? "6px 6px 9px 8px" : "10px 10px 15px 10px", minHeight: width < 991 ? "0px" : null, width: "95%" }}
      >
        <span style={{ display: "flex", gap: "10px", alignItems: "center", fontFamily: lng === "arab" ? "Tajawal" : "poppins" }}>
          <Idea />
          {t("Summarize")}
        </span>
        <ChevronRightRoundedIcon style={{ color: "#D8D8D8", transform: lng === "arab" ? "rotate(180deg)" : "" }} />
      </button>
    </Box>
  );
};

export default AiTools;
