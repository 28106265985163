import React from "react";
import "./Loading_v2.scss";
import { useTranslation } from "react-i18next";

const Loading_v2 = (props) => {
  const { t } = useTranslation();
  const progress_style = {
    display: "block",
    height: "100%",
    width: `${props.loadingNumber}%`,
    background: "linear-gradient(90deg, #6100FF 2%, #00E9FF 100%)",
    borderRadius: "3px",
    position: "relative",
    transition: "width 0.1s ease-out",
  };

  return (
    <>
      <div className="Loading_v2_container">
        <h5>{t(props.loadingText)}</h5>
        <div className="progress_bar_v2 stripes animated reverse slower">
          <span style={progress_style}></span>
        </div>
        <h5>{props.loadingNumber}%</h5>
      </div>
    </>
  );
};

export default Loading_v2;
