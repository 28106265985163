import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ThankYou from "../../assets/img/newDesign/Thank.svg";
import "./ThankYouModal.css";

const ThankYouModal = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const navigate = useNavigate();
  return (
    <Modal
      className={lng === "arab" ? "modal modal-primary arabicFont thank-you-modal" : "modal modal-primary englishFont thank-you-modal"}
      show={props.showModal}
      centered
      size="md"
      onHide={() => props.setShowModal(false)}
    >
      <Modal.Header>
        <button
          onClick={() => {
            props.setShowModal(false);
            window.location.reload(true);
            window.location.href = `${process.env.REACT_APP_WEBSITE_URL}plan?payment=true`;
          }}
          className="btn-close"
        >
          <i className="nc-icon nc-simple-remove"></i>
        </button>
      </Modal.Header>

      <Modal.Body className="text-center">
        <div className="row justify-content-center">
          <img className="modalImgWrapper" src={ThankYou} />
        </div>
        <div>
          <h3 style={{ margin: "0px", fontWeight: 600, lineHeight: "40px" }}>{t("Thank you!")}</h3>
          <label>{t("Glad to have you on board")}</label>
        </div>
        <button
          onClick={() => {
            props.setShowModal(false);
            window.location.reload(true);
            window.location.href = `${process.env.REACT_APP_WEBSITE_URL}plan?payment=true`;
          }}
          className="btn common-btn-modal"
        >
          {t("Ok")}
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default ThankYouModal;
