import axios from "axios";
import { baseUrl } from "api/baseUrl";
import { toastAlert } from "components/toastAlert/toastAlert";

const save = async (quillRef, params, docName, refId) => {
  let request = {
    token: localStorage.getItem("sess"),
    text: quillRef.current.getEditor().root.innerHTML,
    ref_id: params.id ? params.id : refId,
    title: docName,
  };
  let response = await axios
    .post(`${baseUrl}textGeneration/document/create`, request)
    .then((res) => {})
    .catch((err) => {
      toastAlert("Document didn't save");
      // console.log(err);
    });
};

export default save;
