import React, { useEffect, useState } from "react";
import { baseUrl } from "api/baseUrl";
import axios from "axios";
import Text from "./TextHistory";
import Code from "./CodeHistory";
import Photo from "./PhotoHistory";
import Business from "./BusinessHistory";
import Details from "./HistoryDetails";
import Document from "./DocumentHistory";
import Presentation from "./PresentationHistory";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import "./history.scss";
import AudioHistory from "./AudioHistory";
import VideoHistory from "./VideoHistory";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, autoplay } from "swiper";
import "swiper/css/navigation";
import "swiper/css";
import arrowForCases from "../../assets/img/newArrow.png";

const History = ({ element }) => {
  const { t } = useTranslation();
  const [activeComponent, setActiveComponent] = useState("document");
  const { name } = useParams();
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [codePage, setCodePage] = useState(1);
  const [codeLimit, setCodeLimit] = useState(20);
  const [businessPage, setBusinessPage] = useState(1);
  const [businessLimit, setBusinessLimit] = useState(20);
  const [audioPage, setAudioPage] = useState(1);
  const [audioLimit, setAudioLimit] = useState(20);
  const [audio_text, setAudioText] = useState([]);
  const [videoPage, setVideoPage] = useState(1);
  const [videoLimit, setVideoLimit] = useState(20);
  const [video_text, setVideoText] = useState([]);
  const [presentationPage, setPresentationPage] = useState(1);
  const [presentationLimit, setPresentationLimit] = useState(20);
  const [documentPage, setDocumentPage] = useState(1);
  const [documentLimit, setDocumentLimit] = useState(20);
  const [document_text, setDocumentText] = useState([]);
  const [history_code, setHistory_code] = useState([]);
  const [business_text, setBusiness_text] = useState([]);
  const [presentation_text, setPresentation_text] = useState([]);
  // const [document, setDocument_text] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const CodeHistory = async () => {
    setIsLoading(true);
    let response = await axios.get(`${baseUrl}user/history/code?page=${codePage}&limit=${codeLimit}`).then((res) => {
      setHistory_code(res?.data?.history);
      setIsLoading(false);
    });
  };

  const BusinessHistory = async () => {
    setIsLoading(true);
    let response = await axios.get(`${baseUrl}user/history/texts?page=${businessPage}&limit=${businessLimit}&type=business`).then((res) => {
      setBusiness_text(res?.data?.history);
      setIsLoading(false);
    });
  };

  const TextHistory = async () => {
    setIsLoading(true);
    let response = await axios.get(`${baseUrl}user/history/texts?page=${page}&limit=${limit}&type=normal`).then((res) => {
      setDocumentText(res?.data?.history);
      setIsLoading(false);
    });
  };

  const PresentationHistory = async () => {
    setIsLoading(true);
    let response = await axios.get(`${baseUrl}user/history/presentation?page=${presentationPage}&limit=${presentationLimit}`).then((res) => {
      setPresentation_text(res?.data?.history);
      setIsLoading(false);
    });
  };
  const DocumentHistory = async () => {
    setIsLoading(true);
    let response = await axios.get(`${baseUrl}user/history/document?page=${documentPage}&limit=${documentLimit}`).then((res) => {
      setDocumentText(res?.data?.history);
      setIsLoading(false);
    });
  };

  const HistoryAudio = async () => {
    setIsLoading(true);
    let response = await axios.get(`${baseUrl}media-generation/history/audio?page=${audioPage}&limit=${audioLimit}`).then((res) => {
      setAudioText(res?.data?.history);
      setIsLoading(false);
    });
  };

  const HistoryVideo = async () => {
    setIsLoading(true);
    let response = await axios.get(`${baseUrl}media-generation/history/video?page=${videoPage}&limit=${videoLimit}`).then((res) => {
      setVideoText(res?.data?.history);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (name == "text") {
      TextHistory();
    } else if (name == "code") {
      CodeHistory();
    } else if (name == "business") {
      BusinessHistory();
    } else if (name == "presentation") {
      PresentationHistory();
    } else if (name == "dc") {
      DocumentHistory();
    } else if (name == "audio") {
      HistoryAudio();
    } else if (name == "video") {
      HistoryVideo();
    }
  }, [name, page, limit, businessPage, businessLimit, codePage, codeLimit, presentationPage, presentationLimit, documentPage, documentLimit, audioPage, audioLimit, videoPage, videoLimit]);

  useEffect(() => {
    setPage(1);
    setCodePage(1);
    setBusinessPage(1);
    setPresentationPage(1);
    setDocumentPage(1);
    setAudioPage(1);
    setVideoPage(1);
  }, [limit, codeLimit, businessLimit, presentationLimit, documentLimit, audioLimit, videoLimit]);

  useEffect(() => {
    setActiveComponent(name);
  }, [name]);

  return (
    <div className="bookmarks_page inner_content border-radius-top">
      <div
        style={{
          justifyContent: "flex-start",
          gap: "20px",
          overflowX: "auto",
        }}
        className="bookmarks_pageHeader categories_header subCtegoryHeader"
      >
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={20}
          modules={[Navigation]}
          navigation={{
            prevEl: ".prev",
            nextEl: ".next",
          }}
          loop={false}
          style={{ marginLeft: "15px", marginRight: "15px" }}
        >
          <SwiperSlide style={{ width: "auto" }}>
            <Link to="/history/text" className={`${activeComponent === "text" ? "myActive" : ""}`}>
              {t("Text")}
            </Link>
          </SwiperSlide>
          <SwiperSlide style={{ width: "auto" }}>
            <Link to="/history/code" className={`${activeComponent === "code" ? "myActive" : ""}`}>
              {t("Code")}
            </Link>
          </SwiperSlide>
          <SwiperSlide style={{ width: "auto" }}>
            <Link to="/history/business" className={`${activeComponent === "business" ? "myActive" : ""}`}>
              {t("Business Tools")}
            </Link>
          </SwiperSlide>
          {/* <SwiperSlide style={{ width: "auto" }}>
            <Link to="/history/presentation" className={`${activeComponent === "presentation" ? "myActive" : ""}`}>
              {t("Presentation")}
            </Link>
          </SwiperSlide> */}
          <SwiperSlide style={{ width: "auto" }}>
            <Link to="/history/photo" className={`${activeComponent === "photo" ? "myActive" : ""}`}>
              {t("Photo")}
            </Link>
          </SwiperSlide>
          <SwiperSlide style={{ width: "auto" }}>
            <Link to="/history/dc" className={`${activeComponent === "dc" ? "myActive" : ""}`}>
              {t("Document")}
            </Link>
          </SwiperSlide>
          <SwiperSlide style={{ width: "auto" }}>
            <Link to="/history/audio" className={`${activeComponent === "audio" ? "myActive" : ""}`}>
              {t("Audio ")}
            </Link>
          </SwiperSlide>
          <SwiperSlide style={{ width: "auto" }}>
            <Link to="/history/video" className={`${activeComponent === "video" ? "myActive" : ""}`}>
              {t("Video")}
            </Link>
          </SwiperSlide>
        </Swiper>
        <img
          className="next"
          src={arrowForCases}
          alt={"arrow for categories"}
          style={{
            height: "20px",
            position: "absolute",
            right: "0px",
            zIndex: "10",
            marginLeft: "10px",
          }}
        />
        <img
          className="prev"
          src={arrowForCases}
          alt={"arrow for categories"}
          style={{
            height: "20px",
            transform: "rotate(180deg)",
            position: "absolute",
            left: "0px",
            zIndex: "10",
          }}
        />
      </div>
      <div className="bookmark_height">
        {element ? (
          <Details width={width} />
        ) : activeComponent === "text" ? (
          <Text data={document_text} limit={limit} setLimit={setLimit} page={page} setPage={setPage} isLoading={isLoading} width={width} />
        ) : activeComponent === "code" ? (
          <Code data={history_code} limit={codeLimit} setLimit={setCodeLimit} page={codePage} setPage={setCodePage} isLoading={isLoading} width={width} />
        ) : activeComponent === "photo" ? (
          <Photo />
        ) : activeComponent === "dc" ? (
          <Document data={document_text} limit={documentLimit} setLimit={setDocumentLimit} page={documentPage} setPage={setDocumentPage} isLoading={isLoading} width={width} />
        ) : activeComponent === "business" ? (
          <Business data={business_text} limit={businessLimit} setLimit={setBusinessLimit} page={businessPage} setPage={setBusinessPage} isLoading={isLoading} width={width} />
        ) : activeComponent === "presentation" ? (
          <Presentation data={presentation_text} limit={presentationLimit} setLimit={setPresentationLimit} page={presentationPage} setPage={setPresentationPage} isLoading={isLoading} width={width} />
        ) : activeComponent === "audio" ? (
          <AudioHistory data={audio_text} limit={audioLimit} setLimit={setAudioLimit} page={audioPage} setPage={setAudioPage} isLoading={isLoading} width={width} />
        ) : activeComponent === "video" ? (
          <VideoHistory data={video_text} limit={videoLimit} setLimit={setVideoLimit} page={videoPage} setPage={setVideoPage} isLoading={isLoading} width={width} />
        ) : (
          <center style={{ margin: "20px 0" }}>{t("Please choose another section")}</center>
        )}
      </div>
    </div>
  );
};

export default History;
