import { CASES_NEW_LOADED } from "redux/contstants";
import { LOAD_NEW_CASES } from "redux/contstants";
import { FETCH_NEW_CASES } from "redux/contstants";

export default (new_cases = { new_cases: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_NEW_CASES:
            return { ...new_cases, loading: false, new_cases: action.data };
        case LOAD_NEW_CASES:
            return { ...new_cases, loading: true };
        case CASES_NEW_LOADED:
            return { ...new_cases, loading: false };
        default:
            return new_cases;
    }
}