import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import "./rev-profile.css";
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { baseUrl } from 'api/baseUrl';
import { set } from 'date-fns';

Chart.register(...registerables);

// DUMMY DATA
const labels = ["Dec 10", "Dec 15", "Dec 20", "Dec 25", "Dec 30"];
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];



const RevUsage = () => {
    const user = useSelector((state) => state.user);
    const [totalWordCount, setTotalWordCount] = useState(0);
    const [totalImgCount, setTotalImgCount] = useState(0);
    const [wordPlan, setWordPlan] = useState(0)
    const [imgPlan, setImgPlan] = useState(0)
    const [userAnalytics, setUserAnalytics] = useState();
    const [userLabels, setUserLabels] = useState();
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const fetchUserAnalytics = async (e) => {
        try {
            const response = await fetch(`${baseUrl}textGeneration/analytics`, {
                method: "GET",
                headers: {
                    Authorization: localStorage.getItem("sess"),
                    'Content-Type': 'application/json'

                }
            }).then((res) => res.json()).then(async (data) => {
                if (data.status === 'success') {
                    setTotalWordCount(data.textAnalytics.totalWordCount)
                    setTotalImgCount(data.Imagenalytics.totalImageCount)
                    setUserAnalytics(data);
                    if (data.textAnalytics.dailyAnalytics.length > 1) {
                        setUserLabels(data.textAnalytics.dailyAnalytics.map((e) => {
                            var newDate = new Date(e._id)?.toLocaleString('default', { month: 'short', day: "numeric", });
                            return newDate;
                        }));

                        let uniqueChars = [];
                        userLabels.map((element) => {
                            if (!uniqueChars.includes(element)) {
                                uniqueChars.push(element);
                            }
                        });
                        setUserLabels(uniqueChars);
                    }

                    setWordPlan(user.plan === "Best" || user.plan === "Pro" ? 150000 : user.plan === "Basic" ? 45000 : 2500)
                    setImgPlan(user.plan === "Best" || user.plan === "Pro" ? 200 : user.plan === "Basic" ? 100 : 20)
                }
            })
        } catch (err) {
        }
    }

    useEffect(() => {
        fetchUserAnalytics();

    }, [user])
    return (
        <div className="revamped-usage" style={{ padding: '0 5% 0 5%', width: '100%' }}>
            <Row style={{ justifyContent: 'space-between', padding: '10px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }} className="info_box">
                    <h5>{t("Total Words Used")}</h5>
                    <h2 style={{ fontWeight: 600, margin: '0px' }}>{totalWordCount}</h2>
                    {/* // ADVISED TO HIDE UNTIL NEW DESIGN IS OUT */}
                    {/* <h5 style={{ marginTop: '10px', marginBottom: '5px' }}>{t("Word Count")}</h5>
                    <h3 style={{ fontWeight: 600, margin: '0px' }}>{`${user.text_count_left}/${wordPlan} Left`}</h3> */}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 2, alignItems: 'flex-start' }} className="info_box">
                    <h5>{t("Images Generated")}</h5>
                    <h2 style={{ fontWeight: 600, margin: '0px' }}>{totalImgCount}</h2>
                    {/* // ADVISED TO HIDE UNTIL NEW DESIGN IS OUT */}
                    {/* <h5 style={{ marginTop: '10px', marginBottom: '5px' }}>{t("Image Count")}</h5>
                    <h3 style={{ fontWeight: 600, margin: '0px' }}>{`${user.image_count_left}/${imgPlan} Left`}</h3> */}
                </div>
            </Row>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <h5 style={{ marginTop: '10px', marginBottom: '5px' }}>Total Amount of Words</h5>
                <h5 style={{ fontWeight: 600, margin: '0px', fontSize: 20 }}>{'Dec 30 - Jan 30'}</h5>
                <h2 style={{ fontWeight: 600, margin: '0px' }}>{totalWordCount}</h2>
            </div>
            <div>

                {user && userAnalytics && <Bar
                    data={{
                        // Name of the variables on x-axies for each bar
                        labels: [],//userLabels,
                        datasets: [
                            {
                                label: t('Word Count'),
                                data:
                                    userAnalytics.textAnalytics.dailyAnalytics.map((item) => ({ x: item._id, y: item.total_word_count })),
                                // [
                                //     { x: '2023-01-18', y: 79 },
                                //     { x: '2023-01-20', y: 480 },
                                //     { x: '2023-01-21', y: 230 },
                                //     { x: '2023-01-22', y: 180 },
                                //     { x: '2023-01-23', y: 737 },
                                //     { x: '2023-01-25', y: 428 },
                                // ],
                                backgroundColor: ["rgba(177, 117, 255, 1)"],
                                // Border color of each bar
                                borderColor: ["rgba(177, 117, 255, 1)"],
                                borderWidth: 0.5

                            }
                        ]

                    }}
                    // Height of graph
                    // height={400}
                    width={1200}
                    style={{ minHeight: 400, maxHeight: 500 }}
                    options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        plugins: {
                            legend: {
                                labels: {
                                    // This more specific font property overrides the global property
                                    font: {
                                        size: 18
                                    }
                                }
                            },

                        },
                        pan: {
                            enabled: true,
                        },
                        zoom: {
                            enabled: true,
                        },
                        scales: {
                            x: {
                                beginAtZero: true,
                                type: 'time',
                                time: {
                                    unit: 'day'
                                },
                                grid: {
                                    display: false,
                                },
                                border: {
                                    display: false,
                                },
                                ticks: {
                                    stepSize: 5,
                                }

                            },
                            y:
                            {

                                grid: {
                                    display: false,
                                },
                                border: {
                                    display: false,
                                },
                                ticks: {
                                    // The y-axis value will start from zero
                                    // display: false,

                                    beginAtZero: true,
                                },
                            },


                        },
                    }}
                />}
            </div>
            <div style={{ height: 150 }}></div>
        </div>
    )
}

export default RevUsage