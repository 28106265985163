import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { baseUrl } from "api/baseUrl";
import plansLimits from "assets/data/plansLimits";

const WordCount = (props) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const userSubscription = useSelector((state) => state.userSubscription);
  const userEnterprise = useSelector((state) => state.enterprise);
  const [wordLimit, setWordLimit] = useState(0);
  const [imageLimit, setImageLimit] = useState(0);
  const [totalWords, setTotalWords] = useState(0);
  const [totalImages, setTotalImages] = useState(0);

  useEffect(() => {
    setImageLimit(userSubscription?.product?.metadata?.image_count_left || plansLimits[user?.plan]?.image_limit || userEnterprise?.enterprise?.image_limit);
    setWordLimit(userSubscription?.product?.metadata?.text_count_left || plansLimits[user?.plan]?.word_limit || userEnterprise?.enterprise?.text_limit);
    setTotalWords(user.text_count_left);
    setTotalImages(user?.image_count_left);
  }, [userSubscription, user, userEnterprise]);
  // const setPlan = (plan) => {
  //   switch (plan) {
  //     case 'Basic':
  //       setWordLimit(user.text_count_left > 45000 ? user.text_count_left : 45000)
  //       const amount1 = (user.text_count_left);
  //       setTotalWords(amount1)
  //       setImageLimit(user?.image_count_left > 100 ? user?.image_count_left : 100)
  //       const images1 = (user?.image_count_left);
  //       setTotalImages(images1)
  //       break;
  //     case 'Pro':
  //       setWordLimit(user.text_count_left > 150000 ? user.text_count_left : 150000)
  //       const amount2 = (user.text_count_left);
  //       setTotalWords(amount2)
  //       setImageLimit(user?.image_count_left > 200 ? user?.image_count_left : 200)
  //       const images2 = (user?.image_count_left);
  //       setTotalImages(images2)
  //       break;

  //     case 'Trial':
  //       setWordLimit(user.text_count_left > 2500 ? user.text_count_left : 2500)
  //       const amount3 = (user.text_count_left);
  //       setTotalWords(amount3)
  //       setImageLimit(user?.image_count_left > 20 ? user?.image_count_left : 20)
  //       const images3 = (user?.image_count_left);
  //       setTotalImages(images3)
  //       break;

  //     case 'Authors':
  //       setWordLimit(user.text_count_left > 275000 ? user.text_count_left : 275000)
  //       const amount4 = (user.text_count_left);
  //       setTotalWords(amount4)
  //       setImageLimit(0)
  //       const images4 = 0;
  //       setTotalImages(images4)
  //       break;

  //     case 'Writers':
  //         setWordLimit(user.text_count_left > 75000 ? user.text_count_left : 75000)
  //         const amount5 = (user.text_count_left);
  //         setTotalWords(amount5)
  //         setImageLimit(0)
  //         const images5 = 0;
  //         setTotalImages(images5)
  //         break;

  //       case 'Designer':
  //           setWordLimit(0)
  //           const amount6 = 0;
  //           setTotalWords(amount6)
  //           setImageLimit(user?.image_count_left > 450 ? user?.image_count_left : 450)
  //           const images6 = (user?.image_count_left);
  //           setTotalImages(images6)
  //           break;

  //       case 'Artist':
  //           setWordLimit(0)
  //           const amount7 = 0;
  //           setTotalWords(amount7)
  //           setImageLimit(user?.image_count_left > 800 ? user?.image_count_left : 800)
  //           const images7 = (user?.image_count_left);
  //           setTotalImages(images7)
  //           break;

  //   }
  // }

  // useEffect(() => {
  //   if (user && user?.plan) {
  //     setPlan(user?.plan);
  //   }
  // }, [user])

  return (
    <div className="word-count-container col-lg-8 col-md-8 col-sm-12 upgrade">
      <div className="word_count_wrapper">
        <label style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: lng === "arab" ? "row-reverse" : "row", gap: "5px" }} className="svg_title">
          {userSubscription?.product?.name !== undefined || user.plan ? (
            <>
              <span>{t(userSubscription?.product?.name) || t(user.plan)}</span>
              <span>{t("Plan")}</span>
            </>
          ) : (
            t("Free Trial")
          )}
        </label>
        <div className="bars_wrraper">
          <div className="text_wrapper">
            <label>{props.imgGen ? t("Media Credits") : t("Word Count")}</label>
            <div />
            {/* CHANGED TO WORD COUNT LEFT  */}
            {/* <label>{props.imgGen ? `${totalImages}/${imageLimit}` : `${totalWords}/${wordLimit}`}</label> */}
            <label>{props.imgGen ? `${totalImages || 0}` : `${totalWords || 0}`}</label>
          </div>
          <div style={{ direction: "ltr" }} className={props.imgGen ? "inner_bars_wrapper" : "inner_bars_wrapper_write"}>
            <div
              style={
                props.imgGen
                  ? // { width: `${(totalImages > 0 ? totalImages / imageLimit : 0) * 100}%` } :
                    { width: imageLimit <= 0 ? "0px" : `${100 - (parseInt(totalImages) / imageLimit) * 100}%` }
                  : // { width: `${(totalWords > 0 ? totalWords / wordLimit : 0) * 100}%` }
                    { width: wordLimit <= 0 ? "0px" : `${100 - (parseInt(totalWords) / wordLimit) * 100}%` }
              }
              className={props.imgGen ? "progress_bar" : "progress_bar_write"}
            />
          </div>
        </div>
      </div>
      {userSubscription?.product?.name !== "Pro" && !user?.enterprise && (
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <Link to="/plan" style={{ marginBottom: "4px" }} className="navbar_button">
            {t("Upgrade Plan")}
          </Link>
        </div>
      )}
    </div>
  );
};

export default WordCount;
