import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import searchIcon from "assets/img/newDesign/searchIcon.svg";
import imageDefault from "assets/img/newDesign/imageDefault.png";
import anime from "assets/img/loading-images/anime.png";
import none from "assets/img/loading-images/none.png";
import cinematic from "assets/img/loading-images/cinematic.png";
import threeD from "assets/img/loading-images/3d.png";
import poly from "assets/img/loading-images/lowpoly.png";
import digital from "assets/img/loading-images/digital-art.png";
import photog from "assets/img/loading-images/photographic.png";
import fantasy from "assets/img/loading-images/fantasy-art.png";
import comic from "assets/img/loading-images/comic-book.png";
import neon from "assets/img/loading-images/neon.png";
import isometric from "assets/img/loading-images/isometric.png";
import origami from "assets/img/loading-images/orgami.png";
import clay from "assets/img/loading-images/clay.png";
import Arty from "assets/img/loading-images/arty-picture.png";
import circleSVG from "assets/img/newDesign/circleBC.svg";
import { useSelector } from "react-redux";
import { getAllImages, getMoreLikeThisImages } from "redux/actions/image";
import { Spinner } from "react-bootstrap";
import ImageGenerated from "components/imageGenerated/ImageGenerated";
import { getAllOldImages } from "redux/actions/history";
import { useTranslation } from "react-i18next";
import { toastAlert } from "components/toastAlert/toastAlert";
import UpgradeModal from "components/UpgradeModal/UpgradeModal";
import plansLimits from "assets/data/plansLimits";
import bottomArrow from "assets/img/newArrow.png";
import { withStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Enhance from "views/MediaSection/ImageTools/Enhance/EnhanceImage";
import { Link } from "react-router-dom";
import prompts from "views/MediaSection/loadingImages";
import Scribble from "views/MediaSection/ImageTools/Scribble/Scribble";
import BackgroundRemover from "views/MediaSection/ImageTools/BackgroundRemover/BackgroundRemover";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Upscale from "views/MediaSection/ImageTools/Upscale/Upscale";
import UpscaleV1 from "views/MediaSection/ImageTools/UpscaleV1/UpscaleV1";
import VideoBackgroundRemover from "views/MediaSection/VideoTools/VideoBackgroundRemover/VideoBackgroundRemover";
import TextToSpeech from "views/MediaSection/AudioTools/TextToSpeech/TextToSpeech";
import ResizeV2 from "../ResizeV2/ResizeV2";
import ChangeBackground from "views/MediaSection/ImageTools/ChangeBackground/ChangeBackground";
import TextToVideo from "views/MediaSection/VideoTools/TextToVideo/TextToVideo";
import ImagesFilterComponent from "views/MediaSection/ImagesFilterComponent";
import ImageDesign from "views/MediaSection/ImageTools/ChangeStyles/ChangeStyles";
import LoadingEffect from "components/Loading/LoadingEffect";
import ReImagine from "views/MediaSection/ImageTools/ReImagine/ReImagine";
import TextToAudio from "views/MediaSection/AudioTools/TextToAudio/TextToAudio";
import ClipAudio from "views/MediaSection/AudioTools/ClipAudio/ClipAudio";
import EnhanceAudio from "views/MediaSection/AudioTools/EnhanceAudio/EnhanceAudio";
// import EnhanceVideo from "views/MediaSection/VideoTools/EnhaceVideo/EnhanceVideo";
import HeadShot from "../Headshot/Headshot";
import InfiniteZoom from "views/MediaSection/VideoTools/InfiniteZoom/InfiniteZoom";
import BeforeAfterVideo from "views/MediaSection/VideoTools/BeforeAfterVideo/BeforeAfterVideo";

import LimitPopUp from "views/plansComponent/LimitPopUp";
const formats = ["header", "font", "size", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image", "video"];

const ImgGen = () => {
  const { name } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userSubscription = useSelector((state) => state.userSubscription);
  const userEnterprise = useSelector((state) => state.enterprise);
  const [loadingSwitch, setLoadingSwitch] = useState(false);
  const { images, loading, title, morelikeThis } = useSelector((state) => state.Images);
  const { oldImages } = useSelector((state) => state.history);
  const oldImagesLoading = useSelector((state) => state.history.loading);
  const [showAdvance, setShowAdvance] = useState(false);
  const user = useSelector((state) => state.user);
  const [userData, setUserData] = useState({
    prompt: "",
    aspectRatio: "1:1",
    selectStyle: "",
    outputNumber: user?.plan === "Trial" ? 1 : 4,
    image: "",
    is_uploaded: "False",
  });
  // const [imageGenView, setImageGenView] = useState(0);
  const [wordLimit, setWordLimit] = useState(0);
  const [totalWords, setTotalWords] = useState(0);
  const [imageLimit, setImageLimit] = useState(0);
  const [totalImages, setTotalImages] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [currentPrompt, setCurrentPrompt] = useState("");
  const [currentImageUrl, setCurrentImageUrl] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [imageGenView, setImageGenView] = useState(location.pathname.split("/").includes("photogen"));

  useEffect(() => {
    if (
      name !== "create" &&
      name !== "image_design" &&
      name !== "enhance" &&
      name !== "scribble" &&
      name !== "remover" &&
      name !== "painting" &&
      name !== "text-video" &&
      name !== "audio" &&
      name !== "video" &&
      name !== "upscale" &&
      name !== "upscaleV1" &&
      name !== "video_background" &&
      name !== "text-speech" &&
      name !== "resize" &&
      name !== "resizev2" &&
      name !== "split-audio" &&
      name !== "enhance-audio" &&
      name !== "video_enhance" &&
      name !== "head" &&
      name !== "infinite" &&
      name !== "before-after"
    ) {
      navigate("/photogen/");
    }
  }, [name]);
  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * prompts?.length);
    setCurrentPrompt(prompts[randomIndex].prompt);
    setCurrentImageUrl(prompts[randomIndex].image);
    const intervalId = setInterval(() => {
      if (loading) {
        const randomIndex = Math.floor(Math.random() * prompts?.length);
        setCurrentPrompt(prompts[randomIndex].prompt);
        setCurrentImageUrl(prompts[randomIndex].image);
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [loading]);

  useEffect(() => {
    setImageLimit(userSubscription?.product?.metadata?.image_count_left || plansLimits[user?.plan]?.image_limit || userEnterprise?.enterprise?.image_limit);
    setWordLimit(userSubscription?.product?.metadata?.text_count_left || plansLimits[user?.plan]?.word_limit || userEnterprise?.enterprise?.text_limit);
    setTotalWords(user.text_count_left);
    setTotalImages(user?.image_count_left);
  }, [userSubscription, user, userEnterprise]);
  useEffect(() => {
    dispatch(getAllOldImages());
  }, [images]);
  useEffect(() => {
    setImageGenView(0);
    setLoadingSwitch(loading || oldImagesLoading);
  }, [loading, oldImagesLoading]);
  const submitImages = (e) => {
    setShowAdvance(false);
    e.preventDefault();
    if (totalImages === 0) {
      return setShowModal(true);
    } else if (!userData?.prompt) {
      return toastAlert("Please enter a valid text", undefined, "warning");
    }
    dispatch(getAllImages(userData));
  };
  const moreLikeThis = (text, images) => {
    if (totalImages === 0) {
      return setShowModal(true);
    }
    setUserData({ ...userData, prompt: text, image: images });
    dispatch(getMoreLikeThisImages({ ...userData, prompt: text, image: images }));
  };

  return (
    <div
      style={{
        gap: "20px",
        padding: "0px",
        height: "calc(100vh - 110px)",
        position: "relative",
      }}
      className="page_wrapper border-radius-top"
    >
      <div style={{ padding: "0px" }} className="image_gen_outer_wraaper" id="image_gen_outer_wraaper">
        <ImagesFilterComponent />
        {name === "create" || name == undefined ? (
          <div
            style={{
              minHeight: "unset",
              width: "100%",
              // rowGap: '0px',
              padding: "30px 0px",
              overflow: "auto",
            }}
            className="all_images_wrapper row"
          >
            <SearchImgForm submitImages={submitImages} loadingSwitch={loadingSwitch} setUserData={setUserData} userData={userData} showAdvance={showAdvance} setShowAdvance={setShowAdvance} />
            {loading ? (
              <div
                style={{
                  wudth: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                  padding: "0 10px 30px 10px",
                }}
              >
                <Box sx={{ width: "70%" }}>
                  <LinearProgress />
                </Box>
                <ImageGenNotFoundReplacement image={currentImageUrl} prompt={t(currentPrompt)} />
              </div>
            ) : // }
              // </div>
              images?.length === 0 && morelikeThis?.length === 0 ? (
                <ImageGenNotFoundReplacement />
              ) : (
                images?.map((item, i) => <ImageGenerated userData={userData} moreLikeThis={moreLikeThis} key={i} item={item} i={i} title={title} />)
              )}
            {!loading && morelikeThis?.map((item, i) => <ImageGenerated userData={userData} moreLikeThis={moreLikeThis} key={i} item={item} i={i} title={title} />)}
          </div>
        ) : name === "enhance" ? (
          <>
            <Enhance />
          </>
        ) : name === "scribble" ? (
          <>
            <Scribble />
          </>
        ) : name === "remover" ? (
          <>
            <BackgroundRemover />
          </>
        ) : name === "image_design" ? (
          <>
            <ImageDesign />
          </>
        ) : name === "painting" ? (
          <>
            <ChangeBackground />
          </>
        ) : name === "upscale" ? (
          <>
            <Upscale />
          </>
        ) : name === "upscaleV1" ? (
          <>
            <UpscaleV1 />
          </>
        ) : name === "audio" ? (
          <>
            <TextToAudio />
          </>
        ) : name === "text-video" ? (
          <>
            <TextToVideo />
          </>
        ) : name === "text-speech" ? (
          <>
            <TextToSpeech />
          </>
        ) : name === "video_background" ? (
          <>
            <VideoBackgroundRemover />
          </>
        ) : name === "resize" ? (
          <>
            <ReImagine />
          </>
        ) : name === "resizev2" ? (
          <>
            <ResizeV2 />
          </>
        ) : name === "split-audio" ? (
          <>
            <ClipAudio />
          </>
        ) : name === "head" ? (
          <>
            <HeadShot />
          </>
        ) : name === "infinite" ? (
          <>
            <InfiniteZoom />
          </>
        ) : name === "before-after" ? (
          <>
            <BeforeAfterVideo />
          </>
        ) : name === "enhance-audio" ? (
          <>
            <EnhanceAudio />
          </>
        ) : (
          // : name === "video_enhance" ? (
          //   <>
          //     <EnhanceVideo />
          //   </>
          // )
          // : name === "history" ? (
          //   <div
          //     className="all_images_wrapper row height"
          //     style={{ marginLeft: "0", marginRight: "0", overflow: "auto" }}
          //   >
          //     {oldImagesLoading ? (
          //       <Spinner animation="border" role="status" />
          //     ) : oldImages?.length === 0 ? (
          //       <ImageGenNotFoundReplacement />
          //     ) : (
          //       oldImages?.map((item, i) => {
          //         return item?.urls?.map((inner, i) => (
          //           <ImageGenerated
          //             userData={userData}
          //             moreLikeThis={moreLikeThis}
          //             key={i}
          //             item={inner}
          //             i={i}
          //             title={item?.prompt}
          //           />
          //         ));
          //       })
          //     )}
          //   </div>
          // )
          ""
        )}
      </div>
      {user?.plan === "Trial" ? (
        <UpgradeModal images={true} setShowModal={setShowModal} showModal={showModal} />


      ) : (
        <LimitPopUp setShowModal={setShowModal} showModal={showModal} />
      )}
      {/* <ClaimFreeModal showModal={claimPopUp} setShowModal={setClaimPopUp} /> */}
    </div >
  );
};

export default ImgGen;

const ImageGenNotFoundReplacement = ({ image, tip, prompt }) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;

  return (
    <div style={{ justifyContent: "center", padding: "0 20px", minHeight: prompt ? "353px" : null }} className="image_not_found_replaceMent row container">
      <div className="image_inner_wrapper col-lg-5 col-md-6 col-sm-12">
        <img className="background_circle" src={circleSVG} alt="image_default" />
        <img className="image_not_found" style={{ left: lng === "arab" ? "40px" : "auto", right: lng === "arab" ? "auto" : "40px" }} src={image || imageDefault} alt="image_default" />
      </div>
      <div className="image_inner_content_wrapper col-lg-5 col-md-6 col-sm-12">
        {prompt ? null : (
          <div>
            <h5>{t("Tip")}</h5>
            <p>{t("The more descriptive you are, the better your image will turn our")}</p>
          </div>
        )}
        <div style={{ minWidth: "350px", maxWidth: "350px" }}>
          <h5>{t("Examples")}</h5>
          <p>{prompt || t('"navy blue horse with bright white hair running through the dessert with the galaxy in the sky, night light, majestic"')}</p>
        </div>
      </div>
    </div>
  );
};

const SearchImgForm = ({ submitImages, loadingSwitch, setUserData, userData, setShowAdvance, showAdvance }) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;

  const [width, setWidth] = useState(window.innerWidth);
  const [currentFormats, setCurrentFormats] = useState(formats);

  const isMobile = width <= 768;
  const isTablet = width <= 850;
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    setCurrentFormats(isMobile || isTablet ? ["size", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image", "video"] : formats);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const styles = [
    {
      name: "None",
      nom: 1,
      image: none,
    },
    {
      name: "Anime",
      image: anime,
    },
    {
      name: "Photographic",
      image: photog,
    },
    {
      name: "Digital art",
      image: digital,
    },
    {
      name: "Fantasy",
      image: fantasy,
    },
    {
      name: "Cinematic",
      image: cinematic,
    },
    {
      name: "Low poly",
      image: poly,
    },
    {
      name: "3D",
      image: threeD,
    },
    {
      name: "Comic Book",
      image: comic,
    },
    {
      name: "Neon",
      image: neon,
    },
    {
      name: "Isometric",
      image: isometric,
    },
    {
      name: "Origami",
      image: origami,
    },
    {
      name: "Craft clay",
      image: clay,
    },
    {
      name: "Line Art",
      image: Arty,
    },
  ];

  let sortStyle = styles.sort((a, b) => a.nom - b.nom);

  return (
    <div style={{ width: "100%", display: "flex", alignItems: "center", padding: "5px" }}>
      <div style={{ width: "100%" }} className="image_gen_header">
        <h4 style={{ marginBottom: "0px" }}>{t("Create Anything")}</h4>
        <form style={{ width: "100%", padding: "10px 10px 0px 10px", margin: "auto" }} onSubmit={submitImages} className="newImgForm col-lg-10 col-md-10 col-sm-12">
          <input
            className="form-control"
            style={{ overflow: "hidden" }}
            type="textarea"
            placeholder={t("Get creative!")}
            name="image_gen"
            minRows={1}
            value={userData?.prompt}
            onChange={(e) => setUserData({ ...userData, prompt: e.target.value })}
          />
          <div style={{ margin: "0px" }} className="form-group row">
            <div style={{ minHeight: "30px", width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-start", cursor: "pointer" }} onClick={() => setShowAdvance(!showAdvance)}>
              <h5 style={{ marginBottom: "0px" }}>
                {t("Advance settings ")}
                <span style={{ color: "grey", fontSize: "0.85em" }}>{t("(Optional)")}</span>
              </h5>
              <img
                onClick={() => setShowAdvance(!showAdvance)}
                src={bottomArrow}
                alt="bottom arrow"
                style={{
                  height: "15px",
                  width: "15px",
                  margin: lng === "arab" ? "0 10px 0 0" : "0 0 0 10px",
                  cursor: "pointer",
                  transform: !showAdvance ? (lng === "arab" ? "rotate(90deg)" : "rotate(90deg)") : "rotate(270deg)",
                }}
              />
            </div>
            {showAdvance ? (
              <>
                <div
                  style={{ display: "flex", flexDirection: "column", padding: "0px", paddingTop: "15px", textAlign: lng === "arab" ? "right" : "left" }}
                  className="form-group col-lg-12 col-md-12 col-sm-12"
                >
                  <h6 style={{ marginBottom: "0px" }}>{t("Aspect Ratio")}</h6>
                  <div style={{ justifyContent: "flex-start", gap: "15px", paddingTop: "20px", paddingRight: "0px", paddingLeft: "0px" }} className="output_number ratioDirstBtn">
                    <button
                      style={userData.aspectRatio === "3:2" ? { color: "#fff", backgroundColor: "#000", outline: "unset" } : { outline: "unset" }}
                      type="button"
                      onClick={() => setUserData({ ...userData, aspectRatio: "3:2" })}
                    >
                      <span style={{ height: "25px", width: "35px", borderRadius: "5px", border: userData.aspectRatio === "3:2" ? "1px solid #fff" : "1px solid gray" }}></span>
                      3:2
                    </button>
                    <button
                      type="button"
                      style={userData.aspectRatio === "1:1" ? { color: "#fff", backgroundColor: "#000", outline: "unset" } : { outline: "unset" }}
                      onClick={() => setUserData({ ...userData, aspectRatio: "1:1" })}
                    >
                      <span style={{ height: "25px", width: "25px", borderRadius: "5px", border: userData.aspectRatio === "1:1" ? "1px solid #fff" : "1px solid gray" }}></span>
                      1:1
                    </button>
                    <button
                      type="button"
                      style={userData.aspectRatio === "2:3" ? { color: "#fff", backgroundColor: "#000", outline: "unset" } : { outline: "unset" }}
                      onClick={() => setUserData({ ...userData, aspectRatio: "2:3" })}
                    >
                      <span style={{ height: "25px", width: "20px", borderRadius: "5px", border: userData.aspectRatio === "2:3" ? "1px solid #fff" : "1px solid gray" }}></span>
                      2:3
                    </button>
                  </div>
                </div>
                <h6 style={{ paddingTop: "10px" }}>{t("Select Style")}</h6>
                <div
                  id="image_style_container_overflow"
                  style={{ display: "flex", flexDirection: "column", padding: "0px", textAlign: lng === "arab" ? "right" : "left" }}
                  className="form-group col-lg-12 col-md-12 col-sm-12"
                >
                  <div className="row image_style_container" style={{ overflowX: "auto" }}>
                    {sortStyle.map((items, index) => {
                      return (
                        <button
                          key={index}
                          style={{
                            outline: userData.selectStyle === items.name ? "4px solid #3cc8eb" : null,
                            backgroundImage: `url(${items.image})`,
                            backgroundSize: "cover",
                            position: "relative",
                            border: "unset",
                            backgroundColor: "transparent",
                            backgroundRepeat: "no-repeat",
                            padding: "5px",
                            borderRadius: "15px",
                            backgroundPosition: "center center",
                            overflow: "hidden",
                            minWidth: "100px",
                          }}
                          type="button"
                          className="image_style_buttons"
                          onClick={() => setUserData({ ...userData, selectStyle: items.name })}
                        >
                          <div style={{ position: "absolute", bottom: "0px", left: "0px", right: "0px", display: "flex", backgroundColor: "rgb(0 0 0 / 72%)", padding: "5px" }}>
                            <span style={{ color: "#fff", margin: "auto", fontWeight: "600" }}>{t(items.name === "None" ? items.name.replace(1, "") : t(items.name))}</span>
                          </div>
                        </button>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : null}
          </div>
          {/* <button
            disabled={loadingSwitch}
            style={{ width: "170px", minHeight: "45px", borderRadius: "15px", alignSelf: "center", }}
            type="submit"
            className="form_buttions"
          >
            {loadingSwitch ? t("Loading..") : t("Create Image")}
          </button> */}

          <button
            style={{
              padding: isMobile || isTablet ? "15px 40px !important" : "15px 60px !important",
              background: loadingSwitch ? "gray" : "linear-gradient(45deg, rgba(17, 113, 216, 1) 0%, rgba(60, 200, 235, 1) 100%)",
              width: "170px",
              minHeight: "46px",
              borderRadius: "15px",
              alignSelf: "center",
              pointerEvents: loadingSwitch && "none",
            }}
            className="form_buttions"
            type="submit"
          >
            {loadingSwitch ? <LoadingEffect /> : t("Create Image")}
          </button>
        </form>
      </div>
    </div>
  );
};
