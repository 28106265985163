import { baseUrl } from 'api/baseUrl';
import TextButton from "../components/textbutton/textbutton.tsx"
import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import '../assets/css/contact-us.css'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import checkErr from 'redux/actions/api_error_func';
import { toastAlert } from 'components/toastAlert/toastAlert';
import RecaptchaPopUp from 'components/RecaptchaPopUp';


export default function ContactUs() {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;

    const [submitting, setSubmitting] = useState(false);
    const [formValues, setFormValues] = useState({
        //all required except last two
        name: "",
        email: "",
        message: "",
        // department: string | enum [`Technical`, `Others`] | optional
        // priority: string | enum [`Low`, `Medium`, `High`, `Urgent`] | optional 
    });
    const [captchaResponse, setCaptchaResponse] = useState(0)
    const [recaptchaPopUp, setRecaptchaPopUp] = useState(false)
    const sendTicket = async (e) => {
        e.preventDefault();
        setRecaptchaPopUp(true)
    }
    const pureFunc = async (e) => {
        try {
            setSubmitting(true);
            const response = await fetch(`${baseUrl}ticket/create`, {
                method: "POST",
                headers: {
                    Authorization: localStorage.getItem("sess"),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formValues)
            }).then((res) => res.json()).then(async (data) => {
                if (data.success) {
                    Swal.fire({
                        title: t('Sent!'),
                        text: t('Your ticket has been successfully sent to us'),
                        icon: 'success',
                        confirmButtonText: t('Close')
                    })
                }
                setFormValues({
                    name: "",
                    email: "",
                    message: "",
                  })
                setSubmitting(false);
            })
        } catch (err) {
            checkErr(err, false, toastAlert)
            setSubmitting(false);
        }
      }
      useEffect(() => {
        if (captchaResponse) {
          pureFunc()
        }
      }, [captchaResponse])
    return (
        <div id="contact-us " className={i18next.language === 'arab' ? "contact-us-section arabicFont border-radius-top" : "contact-us-section englishFont border-radius-top"}>
            <div className="row center cu-center" style={{ //margin: '0px 5px 0px 5px' 
            }}>
                <h1 className="cuh1-contactus">{t("Contact Us")}</h1>
                {/* <div style={{ width: '1em' }} /> */}
                <h1 className="cuh1-contactus-caption" >{t("How can we help you")}</h1>
                <h6>{t("Have a question, or just want to say Hi. Fill in the form below")}</h6>
            </div>
            <form style={{direction: lng === 'arab'? 'rtl':'ltr'}} onSubmit={sendTicket} className="cu-divborder"  >
                <div className="cu-divcontents" >
                    <div className='row justify-content-between cu-row' >
                        <div className='col-md-6 col-sm-6 col-12' style={{ margin: '0px 0px 20px 0px' }} >
                            <h6 style={{textAlign: lng === 'arab'? 'right':'left'}}>{t("Your Name")}</h6>
                            <input className="cu-input"
                                value={formValues.name}
                                required
                                onChange={(e) => {
                                    setFormValues({ ...formValues, name: e.target.value })
                                }}>
                            </input>
                        </div>
                        <div className='col-md-6 col-sm-6 col-12' style={{ margin: '0px 0px 20px 0px' }}>
                            <h6 style={{textAlign: lng === 'arab'? 'right':'left'}}>{t("Your Email")}</h6>
                            <input className="cu-input"
                            type='email'
                                value={formValues.email}
                                required
                                onChange={(e) => {
                                    setFormValues({ ...formValues, email: e.target.value })
                                }}>
                            </input>
                        </div>
                    </div>
                    <div className='row cu-row' >
                        <h6>{t("Your Message")}</h6>
                        <div className="cu-textarea">
                            <textarea
                                value={formValues.message}
                                required
                                onChange={(e) => {
                                    setFormValues({ ...formValues, message: e.target.value })
                                }}>

                            </textarea>
                        </div>

                    </div>
                    <div className='row cu-row cu-row-btn'>

                        <TextButton
                            fontWeight={600}
                            text={t("Submit")}
                            backgroundColor={`transparent linear-gradient(to right,rgba(216, 81, 239, 1),rgba(146, 64, 202, 1),rgba(24, 119, 242, 1),rgba(24, 119, 242, 1),rgba(146, 64, 202, 1),rgba(216, 81, 239, 1)) no-repeat 0% 0% `}
                            hoverBackgroundColor={`transparent linear-gradient(to right,rgba(216, 81, 239, 1),rgba(146, 64, 202, 1),rgba(24, 119, 242, 1),rgba(24, 119, 242, 1),rgba(146, 64, 202, 1),rgba(216, 81, 239, 1)) no-repeat  100% 0%`}
                            textColor={"white"}
                            padding={"0.5em 2em"}
                            borderRadius={"8px"}
                        />
                    </div>
                </div>
            </form>
            {/* fix the css */}
            <RecaptchaPopUp setLoading={setSubmitting} backdrop='static' show={recaptchaPopUp} onHide={() => setRecaptchaPopUp(false)} captcharesponse={captchaResponse} submitfunction={setCaptchaResponse}/>

        </div>
    )
}   