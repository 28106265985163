import { LOAD_BUSINESS_CASES } from "redux/contstants";
import { FETCH_BUSINESS_CASES } from "redux/contstants";
import { CASES_BUSINESS_LOADED } from "redux/contstants";

export default (business_cases = { business_cases: [], category: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_BUSINESS_CASES:
            let lng = JSON.parse(localStorage.getItem("language"))?.lang;
            let newObj = {...business_cases};
            for (const key in action?.data) {
                if (key === 'use_cases') {
                    let casesInner =  action.data['use_cases']?.filter(item => item.arabic_name);
                    let category = casesInner?.map((item, i) => {return {category: item?.category, category_icon: item?.category_icon, category_name: lng === 'arab'? item?.arabic_category_name:item?.category_name }});
                    let categoryUniqu = [...new Map(category?.map((item) => [item["category"], item]))?.values(),]?.filter(item => item?.category && item?.category_name)
                    newObj = { ...newObj, business_cases: casesInner, category: action?.data?.category, loading: false }
                } else {
                    newObj = {...newObj, [key]: action?.data[key]}
                }
            }
            return { ...newObj};
        case CASES_BUSINESS_LOADED:
            return { ...business_cases, loading: false };
        default:
            return business_cases;
    }
}