import React, { useState, useEffect } from "react";
import { toastAlert } from "components/toastAlert/toastAlert";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import UpgradeModal from "components/UpgradeModal/UpgradeModal";
import "views/MediaSection/ImageTools/Create/ImgGen.css";
import LoadingEffect from "components/Loading/LoadingEffect";
import UpgradePlanModal from "components/UpgradeModal/UpgradePlanModal";
import { handleTextToAudio } from "redux/actions/media_section";
import LimitPopUp from "views/plansComponent/LimitPopUp";
import image_en from "assets/img/newDesign/text-to-music-en.png";
import image_ar from "assets/img/newDesign/text-to-music-ar.png";
import ReplayIcon from "@mui/icons-material/Replay";

const TextToAudio = () => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [totalImages, setTotalImages] = useState(0);
  const [input, setInput] = useState("");
  const [options, setOptions] = useState({
    duration: 5,
    continuation_start: undefined,
    continuation_end: undefined,
    continuation: undefined,
  });
  const user = useSelector((state) => state.user);
  const textToAudio = useSelector((state) => state.text_to_audio);
  const dispatch = useDispatch();

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  useEffect(() => {
    setTotalImages(user?.image_count_left);
    if (user?.plan === "Trial" || user?.plan === "Starter") {
      return setShow(true);
    }
  }, [user]);

  const handlePainting = async (e) => {
    e.preventDefault();
    if (totalImages === 0) {
      dispatch({ type: "SHOW_TEXT_AUDIO_FALSE" });
      setShowModal(true);
      return;
    }
    if (input === "") {
      return toastAlert(t("Input cannot be empty"), undefined, "warning");
    } else {
      let data = {
        prompt: input,
        duration: parseInt(options?.duration),
      };
      dispatch(handleTextToAudio(data));
    }
  };

  const handleDownload = () => {
    const fileUrl = textToAudio?.textToAudio?.data?.urls;
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = "hello"; // Specify the file name here
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="image_gen_header" style={{ padding: "40px 15px 0" }}>
      {textToAudio?.textToAudio?.length === 0 && (
        <>
          <h4>{t("Create music and soundtracks instantly with just your words")}</h4>
          <p style={{ fontSize: "1rem" }}>{t("Find the perfect sound in minutes")}</p>
        </>
      )}
      {textToAudio?.textToAudio?.length === 0 ? (
        <form onSubmit={handlePainting}>
          <div className="inpainting_input">
            <input
              type="textarea"
              value={input}
              disabled={textToAudio.loading}
              onChange={(e) => setInput(e.target.value)}
              placeholder={t("Describe Your Audio")}
            />
            <button
              style={{
                padding: "15px 40px !important",
                background: textToAudio.loading
                  ? "gray"
                  : "linear-gradient(45deg, rgba(17, 113, 216, 1) 0%, rgba(60, 200, 235, 1) 100%)",
                width: "170px",
                minHeight: "46px",
                borderRadius: "15px",
                alignSelf: "center",
                pointerEvents: textToAudio.loading && "none",
              }}
              className="form_buttions"
              type="submit"
            >
              {textToAudio.loading ? <LoadingEffect /> : t("Create Audio")}
            </button>
          </div>
          <div className="continuation">
            <div className="item">
              <h5
                style={{
                  textAlign: lng === "arab" ? "right" : "left",
                  paddingTop: "20px",
                  margin: "0",
                }}
              >
                {t("Duration: ")}
              </h5>
              <select
                defaultValue={5}
                style={{
                  borderRadius: "10px",
                  borderColor: "#00B2FF",
                  padding: "3px",
                  textAlignLast: "center",
                }}
                onChange={(e) => handleChange(e, "duration")}
              >
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={25}>25</option>
                <option value={30}>30</option>
              </select>
              <h5
                style={{
                  textAlign: lng === "arab" ? "right" : "left",
                  paddingTop: "20px",
                  margin: "0",
                }}
              >
                {t("seconds")}
              </h5>
            </div>
          </div>
          <img
            src={lng === "arab" ? image_ar : image_en}
            style={{
              marginTop: "30px",
              maxWidth: "80%",
            }}
            alt="text to music"
          />
        </form>
      ) : (
        <div className="text_audio_result">
          <div className="inpainting_input">
            {/* <Waveform url={textToAudio?.textToAudio?.data?.urls} /> */}
            <audio src={textToAudio?.textToAudio?.data?.urls} controls />
          </div>
          <div className="beforeafter-gray">
            <button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                fontSize: "medium",
              }}
              className="download-btn"
              onClick={handleDownload}
            >
              {t("Download")}
              <i
                className="fas fa-arrow-down"
                style={{
                  fontSize: "medium",
                  margin: lng === "arab" ? " 0 5px 0 0" : "0 0 0 5px",
                }}
              ></i>
            </button>
            <button
              className="newupload"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                fontSize: "medium",
              }}
              onClick={() => {
                setInput("");
                dispatch({ type: "NEW_TEXT_AUDIO" });
              }}
            >
              <ReplayIcon sx={{ transform: "scale(-1)", margin: "0 10px" }} />
              {t("Try Again")}
            </button>
          </div>
        </div>
      )}
      {user?.plan === "Trial" ? (
        <UpgradeModal
          images={true}
          setShowModal={setShowModal}
          showModal={showModal}
        />
      ) : (
        <LimitPopUp setShowModal={setShowModal} showModal={showModal} />
      )}
      <UpgradePlanModal show={show} setShow={setShow} width={width} />
    </div>
  );
};

export default TextToAudio;
