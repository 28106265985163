import { baseUrl } from "api/baseUrl";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingPopUp from "components/popUps/LoadingPopUp";
import { toastAlert } from "components/toastAlert/toastAlert";
import React, { useEffect, useRef, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import checkErr from "redux/actions/api_error_func";
import { getUserEnterprise } from "redux/actions/user_subscription";
import enterprise from "redux/reducers/enterprise";
import deleteIcon from "../../assets/img/newDesign/delete.png";
import deleteIconBlack from "../../assets/img/newDesign/deleteIcon.png";
import newLink from "../../assets/img/newDesign/newLink.png";
import editPen from "../../assets/img/newDesign/editPen.png";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import { Card, Container, Grid, Box } from "@mui/material";
import people from "../../assets/BusinessAssets/Teamwork.png";
import picture from "../../assets/BusinessAssets/Picture.png";
import documentt from "../../assets/BusinessAssets/Document.png";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import moment from "moment";
import Tippy from "@tippyjs/react";
import infoIcon from "../../assets/img/newDesign/info.png";
import { ReactComponent as Replace } from "../../assets/img/newDesign/replaceUser.svg";

const BusinessProfile = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { cases } = useSelector((state) => state?.cases);
  const { business_cases } = useSelector((state) => state?.business_cases);
  const stylingPoints = useSelector((state) => state?.styling);
  const [editTeam, setEditTeam] = useState(false);
  const [editUsers, setEditUsers] = useState(true);
  const [show, setShow] = useState(false);
  const [showReplace, setShowReplace] = useState(false);
  const [replaceID, setReplaceID] = useState("");
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };
  const handleAddClose = () => {
    setShowReplace(false);
  };

  const openReplaceModal = (id) => {
    setReplaceID(id);
    // console.log(id);
    setShowReplace(true);
  };

  const [removeUserModal, setRemoveUserModal] = useState(false);
  const [teamUseCases, setTeamUseCases] = useState([]);
  const [fullTeamCases, setFullTeamCases] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [allCount, setAllCount] = useState({
    imageCount: 0,
    userCount: 0,
    wordCount: 0,
  });
  const [selectedUSer, setSelectedUser] = useState("");
  const user = useSelector((state) => state.user);
  const userEnterprise = useSelector((state) => state.enterprise);
  const [getUserData, setGetUserData] = useState([]);
  const [teamName, setTeamName] = useState(userEnterprise?.enterprise?.name || "");
  const [inviteEmail, setInviteEmail] = useState(userEnterprise?.enterprise?.invite_uuid ? `${process.env.REACT_APP_WEBSITE_URL}invite-users/${userEnterprise?.enterprise?.invite_uuid}` : "");
  const dispatch = useDispatch();
  useEffect(() => {
    setTeamName(userEnterprise?.enterprise?.name || "");
    setInviteEmail(userEnterprise?.enterprise?.invite_uuid ? `${process.env.REACT_APP_ENTERPRISE_URL}invite-users/${userEnterprise?.enterprise?.invite_uuid}` : "");
  }, [userEnterprise, user]);
  const [emailForm, setEmailForm] = useState("");
  const [emailReplaceForm, setEmailReplaceForm] = useState("");
  const [loadingSwitch, setLoadingSwitch] = useState(false);

  const imageRef = useRef();
  const navigate = useNavigate();
  const navigateToPayment = () => {
    navigate("/paymentgateway");
  };
  const getLimits = () => {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_ENTERPRISE_URL}enterprise/enterpriseWordAndImageCount`,
      headers: {
        Authorization: localStorage.getItem("sess"),
      },
    };
    axios(config)
      .then(function (response) {
        setAllCount({
          imageCount: response?.data?.imageCount,
          userCount: response?.data?.userCount,
          wordCount: response?.data?.wordCount,
        });
      })
      .catch(function (error) {});
  };
  const getUser = async () => {
    let response = await axios.get(`${baseUrl}subscription/get/user`).then((res) => {
      setGetUserData(res?.data?.subscription);
    });
  };
  useEffect(() => {
    getUser();
  }, []);
  const getUseCases = () => {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_ENTERPRISE_URL}enterprise/userUsedUseCasesCount`,
      headers: {
        Authorization: localStorage.getItem("sess"),
      },
    };
    axios(config)
      .then(function (response) {
        setTeamUseCases(response?.data?.data);
      })
      .catch(function (error) {});
  };
  useEffect(() => {
    getLimits();
    getUseCases();
  }, [stylingPoints?.fetchuser, userEnterprise]);
  const changeTeamName = (e) => {
    setLoadingSwitch(true);
    e.preventDefault();
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_ENTERPRISE_URL}enterprise/update`,
      headers: {
        Authorization: localStorage.getItem("sess"),
      },
      data: { name: teamName },
    };
    axios(config)
      .then(function (response) {
        toastAlert("Updated", undefined, "success");
        dispatch(getUserEnterprise());
        setLoadingSwitch(false);
      })
      .catch(function (error) {
        checkErr(error, setLoadingSwitch, toastAlert);
      });
  };
  const changeEmail = () => {
    if (!emailForm) {
      toastAlert("Please add email", undefined, "error");
      return;
    }
    setLoadingSwitch(true);
    setShow(false);
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_ENTERPRISE_URL}enterprise/invite-user`,
      headers: {
        Authorization: localStorage.getItem("sess"),
      },
      data: { email: emailForm },
    };
    axios(config)
      .then(function (response) {
        toastAlert("Added pending user", undefined, "success");
        dispatch(getUserEnterprise());
        setLoadingSwitch(false);
      })
      .catch(function (error) {
        checkErr(error, setLoadingSwitch, toastAlert);
      });
  };

  const replaceEmail = (id) => {
    if (!emailReplaceForm) {
      toastAlert("Please add email", undefined, "error");
      return;
    }
    setLoadingSwitch(true);
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_ENTERPRISE_URL}enterprise/replace-user`,
      headers: {
        Authorization: localStorage.getItem("sess"),
      },
      data: {
        enterpriseUserId: id,
        email: emailReplaceForm,
      },
    };
    axios(config)
      .then(function (response) {
        toastAlert("Added pending user", undefined, "success");
        dispatch(getUserEnterprise());
        setLoadingSwitch(false);
        setShowReplace(false);
      })
      .catch(function (error) {
        checkErr(error, setLoadingSwitch, toastAlert);
      });
  };

  const handleImgError = () => {
    if (imageRef.current) {
      imageRef.current.src = "https://riverlegacy.org/wp-content/uploads/2021/07/blank-profile-photo.jpeg";
    }
  };
  const refreshInviteLink = () => {
    setLoadingSwitch(true);
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_ENTERPRISE_URL}enterprise/enable_public_link`,
      headers: {
        Authorization: localStorage.getItem("sess"),
      },
    };
    axios(config)
      .then(function (response) {
        dispatch(getUserEnterprise());
        toastAlert("Refreshed", undefined, "success");
        setLoadingSwitch(false);
      })
      .catch(function (error) {
        checkErr(error, setLoadingSwitch, toastAlert);
      });
  };
  const deleteInviteLink = () => {
    setLoadingSwitch(true);
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_ENTERPRISE_URL}enterprise/disable_public_link`,
      headers: {
        Authorization: localStorage.getItem("sess"),
      },
    };
    axios(config)
      .then(function (response) {
        dispatch(getUserEnterprise());
        toastAlert("Deleted", undefined, "success");
        setLoadingSwitch(false);
      })
      .catch(function (error) {
        checkErr(error, setLoadingSwitch, toastAlert);
      });
  };
  const handleCopyLink = () => {
    toastAlert("Copied", "Text has been copied to clipboard", "success");
  };
  const handleRemoveUser = (userID) => {
    setLoadingSwitch(true);
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_ENTERPRISE_URL}enterprise/remove_user`,
      headers: {
        Authorization: localStorage.getItem("sess"),
      },
      data: { user: userID },
    };
    axios(config)
      .then(function (response) {
        dispatch(getUserEnterprise());
        toastAlert("Deleted", undefined, "success");
        setLoadingSwitch(false);
      })
      .catch(function (error) {
        checkErr(error, setLoadingSwitch, toastAlert);
      });
  };
  const handlePOPUP = (userID) => {
    setSelectedUser(userID);
    setRemoveUserModal(true);
  };
  useEffect(() => {
    let fullCasesDisplay = [];
    for (let i = 0; i < teamUseCases?.length; i++) {
      if ([...cases, ...business_cases]?.filter((item) => item?.key === teamUseCases[i]?._id)?.length) {
        fullCasesDisplay.push({
          ar_name: [...cases, ...business_cases]
            ?.filter((item) => item?.key === teamUseCases[i]?._id)
            ?.slice(0)
            ?.shift()?.arabic_name,
          en_name: [...cases, ...business_cases]
            ?.filter((item) => item?.key === teamUseCases[i]?._id)
            ?.slice(0)
            ?.shift()?.name,
          icon: [...cases, ...business_cases]
            ?.filter((item) => item?.key === teamUseCases[i]?._id)
            ?.slice(0)
            ?.shift()?.element_icon,
          count: teamUseCases[i]?.count,
        });
      }
    }
    setFullTeamCases(fullCasesDisplay);
  }, [teamUseCases, cases]);

  const handleManagePlan = () => {
    var data = {
      return_url: window.location.href,
    };
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}stripe/portal/create`,
      headers: {
        Authorization: localStorage.getItem("sess"),
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        window.open(response.data?.portal?.url, "_blank");
      })
      .catch(function (error) {
        checkErr(error, toastAlert);
      });
  };

  return (
    <>
      <Container className="marketing small_text" style={{ direction: lng === "arab" ? "rtl" : "ltr", padding: "0px", backgroundColor: "transparent" }} maxWidth="xl">
        <div style={{ minHeight: "68.5px" }} className="title-area">
          <h3 className="title">{t("Your Business Overview")}</h3>
        </div>
        <hr className="hredit" />
        {user?.enterprise ? (
          <Grid className="title-area" style={{ marginLeft: "0px", width: "100%" }} container spacing={5}>
            <Grid item lg={12} xs={12} sx={{ display: "flex", justifyContent: "space-between", gap: "10px", width: "100%" }} style={{ paddingLeft: "0px" }}>
              <Card className="business_card">
                <img src={people} width={40} />
                <h2>
                  {allCount?.userCount + 1}
                  <span style={{ fontSize: "0.6em", fontWeight: 400 }}>/{userEnterprise?.enterprise?.max_users}</span>
                </h2>
                <h4>{t("Team Members")}</h4>
                <button onClick={handleShow}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {t("Add")}
                    <AddRoundedIcon
                      fontSize="small"
                      sx={{
                        color: "white",
                        border: "2px solid white",
                        borderRadius: "8px",
                        ml: lng === "arab" ? 0 : 1,
                        mr: lng === "arab" ? 1 : 0,
                      }}
                    />
                  </div>
                </button>
              </Card>

              <Card className="business_card">
                <img src={picture} width={40} />
                <h2>{allCount?.imageCount}</h2>
                <h4>{t("Image Generated")}</h4>
              </Card>

              <Card className="business_card">
                <img src={documentt} width={40} />
                <h2>{allCount?.wordCount}</h2>
                <h4>{t("Words Generated")}</h4>
              </Card>
            </Grid>
            <Grid item lg={12} xs={12} style={{ paddingLeft: "0px" }}>
              <Card className="plan_billing_card">
                <Box className="plan_billing small_text">
                  <h4 className="small_text">{t("Plan & Billing")}</h4>
                  <button
                    onClick={() => {
                      getUserData?.last_transaction_id?.paymentGate == "hyperpay" ||
                      getUserData?.last_transaction_id?.paymentGate == "coinbase" ||
                      getUserData?.last_transaction_id?.paymentGate == "paypal"
                        ? navigateToPayment()
                        : handleManagePlan();
                    }}
                  >
                    {t("Manage Plan")}
                  </button>
                </Box>
                <Grid container sx={{ direction: "rtl" }} className="small_text">
                  <Grid item xs={12} mt={3} className="plan_billing_grid" sx={{ textAlign: lng === "arab" ? "right" : "left" }}>
                    <Grid item xs={6}>
                      <h5 style={{ fontWeight: "600", color: "black" }}>{t(user?.plan)}</h5>
                      <p style={{ fontSize: "0.9em" }}>
                        {userEnterprise?.enterprise?.max_users} {t("users")}
                      </p>
                    </Grid>
                    <Grid item xs={6}>
                      <h5 style={{ color: "black", textAlign: lng === "arab" ? "right" : "left", direction: lng === "arab" ? "rtl" : "ltr" }}>
                        <span style={{ fontWeight: 600 }}>
                          ${userEnterprise?.enterprise?.unit_price || 0} / {t("month")} / {t("user")}
                        </span>
                      </h5>
                      <p style={{ color: "#ADADAD", fontSize: "0.9em" }}>{t("Billed monthly")}</p>
                    </Grid>
                  </Grid>
                  <Grid className="plan_billing_grid" item xs={12}>
                    <Grid item xs={6} className="plan_billing_items" sx={{ direction: lng === "arab" ? "rtl" : "ltr" }}>
                      <img src={documentt} width={20} />
                      <h5 style={{ textAlign: lng === "arab" ? "right" : "left" }}>
                        <span style={{ fontWeight: 600 }}>{userEnterprise?.enterprise?.text_limit || 0}</span>
                        {" " + t("words")} / {t("month")} / {t("user")}
                      </h5>
                    </Grid>
                    <Grid item xs={6} className="plan_billing_items" sx={{ direction: lng === "arab" ? "rtl" : "ltr" }}>
                      <img src={picture} width={20} />
                      <h5>
                        <span style={{ fontWeight: 600 }}>{userEnterprise?.enterprise?.image_limit || 0}</span>
                        {" " + t("images")} / {t("month")} / {t("user")}
                      </h5>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item lg={12} xs={12} style={{ paddingLeft: "0px" }}>
              <Card className="plan_billing_card">
                <Box className="plan_billing">
                  <h4 className="small_text">{t("Your Team")}</h4>
                  <button onClick={handleShow}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      {t("Invite new members")}
                      <AddRoundedIcon
                        fontSize="small"
                        sx={{
                          color: "white",
                          border: "2px solid white",
                          borderRadius: "8px",
                          ml: lng === "arab" ? 0 : 1,
                          mr: lng === "arab" ? 1 : 0,
                        }}
                      />
                    </div>
                  </button>
                </Box>
                <Box>
                  <div style={{ overflowX: "auto" }} className="tableScroll">
                    <table style={{ overflowX: "scroll", width: "100%", textAlign: lng === "arab" ? "right" : "left", overflow: "auto" }} className="table business_table">
                      <thead>
                        <tr style={{ textAlign: lng === "arab" ? "right" : "left" }}>
                          <th style={{ textAlign: lng === "arab" ? "right" : "left" }}>{t("Name")}</th>
                          <th style={{ textAlign: lng === "arab" ? "right" : "left" }}>{t("Status")}</th>
                          <th style={{ textAlign: lng === "arab" ? "right" : "left" }}>{t("Word Count")}</th>
                          <th style={{ textAlign: lng === "arab" ? "right" : "left" }}>{t("Media Credits")}</th>
                        </tr>
                      </thead>
                      <tbody style={{ backgroundColor: "transparent" }}>
                        {userEnterprise?.enterprise?.owner === user?._id && (
                          <tr className="small_text">
                            <td style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                              <img
                                style={{ width: "35px", height: "35px", borderRadius: "50%" }}
                                ref={imageRef}
                                onError={handleImgError}
                                src={user.image != null ? user.image : "https://riverlegacy.org/wp-content/uploads/2021/07/blank-profile-photo.jpeg"}
                                alt="user_profile_image"
                                className="user_profile_image"
                              />
                              <div>
                                <label style={{ fontWeight: 600, marginBottom: "0px" }}>{user?.name}</label>
                                <br />
                                <label style={{ fontSize: "12px", fontStyle: "italic" }}>{user?.email}</label>
                              </div>
                            </td>
                            <td>
                              <label style={{ fontWeight: 600 }}>{t("-")}</label>
                            </td>
                            <td>
                              <label style={{ fontWeight: 600 }}>{user?.text_count_left}</label>
                              <br />
                              <label>{t("remaining of") + " " + userEnterprise?.enterprise?.text_limit}</label>
                            </td>
                            <td>
                              <label style={{ fontWeight: 600 }}>{user?.image_count_left}</label>
                              <br />
                              <label>{t("remaining of") + " " + userEnterprise?.enterprise?.image_limit}</label>
                            </td>
                          </tr>
                        )}
                        {userEnterprise?.users?.map((item, i) => (
                          <tr key={i} style={{ backgroundColor: "transparent" }}>
                            <td style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                              <img
                                style={{ width: "35px", height: "35px", borderRadius: "50%" }}
                                ref={imageRef}
                                onError={handleImgError}
                                src={item?.user_info.image != null ? item?.user_info.image : "https://riverlegacy.org/wp-content/uploads/2021/07/blank-profile-photo.jpeg"}
                                alt="user_profile_image"
                                className="user_profile_image"
                              />
                              <div>
                                <label style={{ fontWeight: 600, marginBottom: "0px" }}>{item?.user_info?.name}</label>
                                <br />
                                <label style={{ fontSize: "12px", fontStyle: "italic" }}>{item?.user_info?.email}</label>
                              </div>
                            </td>
                            <td>
                              <label style={{ fontWeight: 600, display: "flex", alignItems: "center", gap: "5px" }}>
                                <span
                                  style={{
                                    display: "block",
                                    height: "10px",
                                    width: "10px",
                                    backgroundColor: item?.replacedBy ? "#230000" : item?.deletedAt ? "#c30000" : item?.joinedAt ? "#3fb03f" : "#e8e81b",
                                    borderRadius: "50%",
                                  }}
                                ></span>
                                {item?.replacedBy ? t("Replaced By") : item?.deletedAt ? t("Deleted") : item?.joinedAt ? t("Active") : t("Pending")}
                                {item?.replacedBy && (
                                  <svg width="20" height="20" viewBox="0 0 200 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M96.6023 139.236C95.6023 138.836 94.6023 138.136 93.8023 137.336L67.6023 111.136C64.3023 107.836 64.3023 102.436 67.6023 99.0356C70.9023 95.7356 76.3023 95.7356 79.7023 99.0356L91.3023 110.636V99.9356C91.3023 54.6356 54.4023 17.7356 9.10229 17.7356C4.40229 17.7356 0.602287 13.9356 0.602287 9.2356C0.602287 4.5356 4.40229 0.735604 9.10229 0.735604C63.8023 0.735604 108.402 45.2356 108.402 100.036V110.736L120.102 99.0356C123.402 95.7356 128.802 95.7356 132.202 99.0356C133.902 100.736 134.702 102.836 134.702 105.036C134.702 107.236 133.902 109.436 132.202 111.036L106.002 137.236C105.202 138.036 104.302 138.636 103.202 139.136C101.002 140.036 98.7023 140.036 96.6023 139.236Z"
                                      fill="#6F7594"
                                    />
                                  </svg>
                                )}
                                {item?.deletedAt || item?.replacedBy ? (
                                  <Tippy
                                    theme="light"
                                    content={
                                      <label style={{ textAlign: "center" }} className={lng === "arab" ? "tooltip_styling_ar" : "tooltip_styling"}>
                                        {item?.replacedBy ? t("Member will be replaced after new member accepts the invitation") : t("Member will be deleted next month")}
                                      </label>
                                    }
                                  >
                                    <span id="engagment_tool">
                                      <img style={{ marginBottom: "3px" }} src={infoIcon} alt="info_icon" />
                                    </span>
                                  </Tippy>
                                ) : null}
                              </label>
                              {item?.deletedAt && item?.replacedBy && (
                                <label style={{ fontWeight: 600, display: "flex", alignItems: "center", gap: "5px" }}>
                                  <span
                                    style={{
                                      display: "block",
                                      height: "10px",
                                      width: "10px",
                                      backgroundColor: item?.replacedBy && "#e8e81b",
                                      borderRadius: "50%",
                                    }}
                                  ></span>
                                  {item?.replacedBy && t("Pending")} <br />
                                  <label style={{ fontSize: "0.8rem", fontStyle: "italic" }}>{item?.replacedBy?.email}</label>
                                </label>
                              )}
                            </td>
                            <td>
                              <label style={{ fontWeight: 600 }}>{item?.user_info?.text_count_left || item?.text_limit_left}</label>
                              <br />
                              <label>{t("remaining of") + " " + item?.text_limit}</label>
                            </td>
                            <td>
                              <label style={{ fontWeight: 600 }}>{item?.user_info?.image_count_left || item?.image_limit_left}</label>
                              <br />
                              <label>{t("remaining of") + " " + item?.image_limit}</label>
                            </td>
                            <td>
                              {item?.deletedAt ? (
                                <Replace
                                  onClick={() => {
                                    openReplaceModal(item?._id);
                                  }}
                                  style={{ width: "30px", cursor: "pointer" }}
                                />
                              ) : (
                                <>
                                  {userEnterprise?.enterprise?.owner === user?._id && (
                                    <img
                                      onClick={() => handlePOPUP(item?.user_info?._id)}
                                      src={deleteIcon}
                                      alt="delete_icon"
                                      style={{ height: "18px", width: "18px", border: "unset", cursor: "pointer" }}
                                    />
                                  )}
                                </>
                              )}
                            </td>
                            <Modal style={{ fontFamily: lng === "arab" ? "Tajawal" : "Poppins" }} size="md" centered show={showReplace} onHide={handleAddClose}>
                              <AddRoundedIcon
                                onClick={handleAddClose}
                                sx={{
                                  color: "white",
                                  cursor: "pointer",
                                  transform: "rotate(45deg)",
                                  position: "absolute",
                                  right: "10px",
                                  top: "10px",
                                  backgroundColor: "#F9536F",
                                  borderRadius: "50%",
                                }}
                              />
                              <Modal.Header>
                                <Modal.Title className="business_modal_title">
                                  <h4 style={{ textAlign: "center" }}>{t("Replace the deleted team member")}</h4>
                                  <h5 style={{ textAlign: "center" }}>{t("Send an invitation to your team member by email")}</h5>
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body className="business_modal_body">
                                <input onChange={(e) => setEmailReplaceForm(e.target.value)} style={{ direction: "ltr" }} type="email" placeholder={t("Name@example.com")} />
                              </Modal.Body>
                              <Modal.Footer className="business_modal_footer">
                                <button onClick={() => replaceEmail(replaceID)} variant="secondary">
                                  {t("Invite")}
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Box>
              </Card>
            </Grid>
            {/* {fullTeamCases?.length? */}
            <Grid item lg={12} xs={12} style={{ paddingLeft: "0px" }}>
              <Card className="plan_billing_card">
                <Box className="plan_billing">
                  <h4 className="small_text">{t("Top used cases")}</h4>
                </Box>
                <Box>
                  <div style={{ overflowX: "auto" }} className="tableScroll">
                    <table style={{ overflowX: "scroll", width: "100%", textAlign: lng === "arab" ? "right" : "left", overflow: "auto" }} className="table">
                      <thead>
                        <tr style={{ textAlign: lng === "arab" ? "right" : "left" }}>
                          <th style={{ textAlign: lng === "arab" ? "right" : "left" }}>{t("Used Case")}</th>
                          <th style={{ textAlign: lng === "arab" ? "left" : "right" }}>{t("Words Used")}</th>
                        </tr>
                      </thead>
                      {fullTeamCases?.length ? (
                        <tbody style={{ backgroundColor: "transparent" }}>
                          {fullTeamCases?.map((item, i) => (
                            <tr key={i} style={{ backgroundColor: "transparent" }}>
                              <td style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                <img
                                  style={{ width: "35px", height: "35px", borderRadius: "50%" }}
                                  ref={imageRef}
                                  onError={handleImgError}
                                  src={item?.icon ? item.icon : "https://riverlegacy.org/wp-content/uploads/2021/07/blank-profile-photo.jpeg"}
                                  alt="user_profile_image"
                                  className="user_profile_image"
                                />
                                <label style={{ fontWeight: 600 }}>{lng === "arab" ? item?.ar_name : item?.en_name}</label>
                              </td>
                              <td style={{ textAlign: lng === "arab" ? "left" : "right" }}>
                                <label style={{ fontWeight: 600 }}>{item?.count}</label>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody style={{ position: "relative", height: "60px" }}>
                          <div style={{ position: "absolute", top: "0px", left: "0px", right: "0px", bottom: "0px", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                            <label style={{ color: "lightgray", fontStyle: "italic" }}>{t("No tools found")}</label>
                          </div>
                        </tbody>
                      )}
                    </table>
                  </div>
                </Box>
              </Card>
            </Grid>
          </Grid>
        ) : null}
        <AreUSurePopUp show={removeUserModal} onHide={() => setRemoveUserModal(false)} selecteduser={selectedUSer} onsuccess={handleRemoveUser} />
      </Container>
      <Modal className="small_text" style={{ fontFamily: lng === "arab" ? "Tajawal" : "Poppins" }} size="md" centered show={show} onHide={handleClose}>
        <AddRoundedIcon
          onClick={handleClose}
          sx={{ color: "white", cursor: "pointer", transform: "rotate(45deg)", position: "absolute", right: "10px", top: "10px", backgroundColor: "#F9536F", borderRadius: "50%" }}
        />
        <Modal.Header>
          <Modal.Title className="business_modal_title">
            <h4 style={{ textAlign: "center" }}>{t("Invite team member")}</h4>
            <h5 style={{ textAlign: "center" }}>{t("Send an invitation to your team member by email")}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="business_modal_body">
          <input onChange={(e) => setEmailForm(e.target.value)} style={{ direction: "ltr" }} type="email" placeholder={t("Name@example.com")} />
          {/* <div>
            <CheckRoundedIcon fontSize="15px" sx={{ backgroundColor: "#268E7B", color: "white", borderRadius: "50%", padding: "2px" }} />
            <h5>{t("The invitation has been sent to the email")}</h5>
          </div>
          <div>
            <AddRoundedIcon fontSize="15px" sx={{ backgroundColor: "#F9536F", color: "white", borderRadius: "50%", padding: "2px", transform: "rotate(45deg)" }} />
            <h5>{t("Please upgrade your plan to invite more teammates.")}</h5>
          </div> */}
        </Modal.Body>
        <Modal.Footer className="business_modal_footer">
          <button onClick={changeEmail} variant="secondary">
            {t("Invite")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BusinessProfile;

const AreUSurePopUp = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  return (
    <Modal {...props} size="md" centered className="are_u_sure_model">
      <Modal.Body style={{ direction: lng === "arab" ? "rtl" : "ltr", fontFamily: lng === "arab" ? "Tajawal" : "Poppins" }} className="forceArabicFont">
        <h4 style={{ marginBottom: "0px", marginTop: "14px", textAlign: lng === "arab" ? "right" : "left" }}>{t("Are you sure?")}</h4>
        <label style={{ textAlign: lng === "arab" ? "right" : "left" }}>{t("This member will be removed from your business and deleted permanently")}</label>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", gap: "10px" }}>
          <button
            className="newupload"
            onClick={() => {
              props.onsuccess(props.selecteduser);
              props.onHide();
            }}
          >
            {t("Yes")}
          </button>
          <button className="download-btn" onClick={() => props.onHide()}>
            {t("No")}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
