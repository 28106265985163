import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { HyperPayBaseUrl } from "api/baseUrl";
import { url } from "api/baseUrl";

const HyperPay = (props) => {
  const loadHyperPay = () => {
    const script = document.createElement("script");

    script.src = `${HyperPayBaseUrl}?checkoutId=${props?.id}`;
    script.async = true;

    document.body.appendChild(script);

    const form = document.createElement("form");
    form.action = `${url}plan`;
    form.setAttribute("class", "paymentWidgets");
    form.setAttribute("data-brands", "VISA MASTER AMEX MADA");
    document.getElementById("hyper-pay").append(form);
  };

  useEffect(() => {
    if (props?.id) {
      loadHyperPay();
    }
  }, [props?.id]);

  return <div id="hyper-pay">{!props?.id && <Spinner animation="border" style={{ margin: "auto", position: "absolute", left: "0", right: "0", bottom: "125px" }} />}</div>;
};

export default HyperPay;
