import React, { Fragment, useEffect, useRef, useState } from "react";
import "./marketing.scss";
const ref = React.createRef();
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import ReactToPdf from "react-to-pdf";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingEffect from "components/Loading/LoadingEffect";
import UpgradeModal from "components/UpgradeModal/UpgradeModal";
import { TextareaAutosize } from "@mui/material";
import remarkGfm from 'remark-gfm'
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import ReactMarkdown from 'react-markdown'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { aiAPI } from "api";
import { toastAlert } from "components/toastAlert/toastAlert";
import plansLimits from "assets/data/plansLimits";
import backArow from '../../assets/img/newDesign/backArrow.svg'
import { socket } from "../../socket.js";
import { getUser } from "redux/actions/user";
import html2canvas from "html2canvas";
import { USER } from "redux/contstants";
import checkErr from "redux/actions/api_error_func";
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import { PDFDownloadLink } from "@react-pdf/renderer";
import Html from 'react-pdf-html';
import LoadingPopUp from "components/popUps/LoadingPopUp";
import OopsSvg from '../../assets/img/Oops1.svg';
import { Modal } from "react-bootstrap";
import jsPDF from 'jspdf';
import axios from "axios";
import { baseUrl } from "api/baseUrl";
import FileSaver, { saveAs } from 'file-saver';
// require("downloadjs")(data, strFileName, strMimeType);
import Tippy from '@tippyjs/react';
import infoIcon from '../../assets/img/newDesign/info.png'
import LimitPopUp from "views/plansComponent/LimitPopUp";
// export const socket = io(process.env.REACT_APP_SOCKET_URL, {
//   autoConnect: true,
//   transports: ['websocket']
// }); 
const options = {
  orientation: "landscape",
  unit: "in",
  format: [4, 2],
};
var arabic = /[\u0600-\u06FF]/;

const MarketingPlan = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const navigate = useNavigate()
  const { business_cases, loading, category } = useSelector((state) => state?.business_cases);
  const dropdownContainer = useSelector((state) => state?.business_cases);
  const { tone, languages } = useSelector((state) => state?.cases);
  const user = useSelector((state) => state.user);
  const bigLoading = useSelector((state) => state.loading);
  const business_output = useSelector((state) => state.business_output);
  const dispatch = useDispatch();
  const userSubscription = useSelector((state) => state.userSubscription);
  const location = useLocation();
  const [errorHandling, setErrorHandling] = useState({ status: false, message: '' });
  const { name, id } = useParams();
  const [finalElemnt, setFinalElement] = useState();
  const [showExport, setShowExport] = useState(false);
  const [toneInput, setToneInput] = useState('');
  const [languageInput, setLanguageInput] = useState(lng !== 'arab' ? 'English' : 'Arabic');
  const [innerLoadingSwitch, setInnerLoadingSwitch] = useState(false);
  const [generatePDFLoading, setGeneratePDFLoading] = useState(false);
  const [generatedText, setGeneratedText] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showSecondModal, setShowSeconModal] = useState(false);
  const [innerLoadin, setInnerLoading] = useState(true);
  const chat_contentRef = useRef();
  const chatItem = useRef();

  const userEnterprise = useSelector((state) => state.enterprise);
  const [wordLimit, setWordLimit] = useState(0);
  const [totalWords, setTotalWords] = useState(0);
  const [imageLimit, setImageLimit] = useState(0);

  const printDocument = () => {
    setGeneratePDFLoading(true)
    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      responseType: 'blob', // important
      url: `${baseUrl}textGeneration/html-pdf`,
      headers: {
        'Authorization': localStorage.getItem("sess")
      },
      data: {
        htmlContent: `<!DOCTYPE html><html lang="en"><head><style>
        .export_pdf ol, .export_pdf_ar ol {
          padding: 10px;
        }
        .export_pdf ul, .export_pdf_ar ul {
          padding: 10px;
        }
        .export_pdf li, .export_pdf_ar li {
          margin-bottom: 10px;
        }
        .export_pdf table, .export_pdf_ar table {
          margin: 0px 0px 40px 0px;
          border-radius: 12px;
          width: 100%;
          min-width: 750px;
          overflow: hidden !important;
          min-height: unset !important;
          box-shadow: 0px 0px 6px rgb(0 0 0 / 15%);
          -webkit-box-shadow: 0px 0px 6px rgb(0 0 0 / 15%);;
        }
        .export_pdf thead, .export_pdf_ar thead {
          border-bottom: 1px solid #D7D7D7;
          background-color: #fff;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.067);
        }
        .export_pdf thead tr th, .export_pdf_ar thead tr th {
          padding: 10px 5px 10px 5px;
          border-bottom: 1px solid #D7D7D7;
          border-top: 1px solid #D7D7D7;
          font-weight: 600;
          font-size: 15px;
          font-weight: bold;
        }
        .export_pdf thead tr th {
          font-family: 'SohneBold';
        }
        .export_pdf_ar thead tr th {
          font-family: 'Tajawal_black';
        }
        .export_pdf thead tr th {
          border-right: 1px solid #D7D7D7;
        }
        .export_pdf thead tr th:last-child {
          border-right: unset;
        }
        .export_pdf thead tr th:first-child {
          border-left: 1px solid #D7D7D7;
        }
        .export_pdf_ar thead tr th {
          border-left: 1px solid #D7D7D7;
        }
        .export_pdf_ar thead tr th:last-child {
          border-left: unset;
        }
        .export_pdf_ar thead tr th:last-child {
          border-right: 1px solid #D7D7D7;
        }
        .export_pdf tbody tr, .export_pdf_ar tbody tr{
          border-bottom: 1px solid #D7D7D7;
        }
        .export_pdf tbody tr:last-child, .export_pdf_ar tbody tr:last-child {
          border-bottom: unset;
        }
        .export_pdf tbody tr td, .export_pdf_ar tbody tr td{
          padding: 5px;
          border-bottom: 1px solid #D7D7D7;
        }
        .export_pdf tbody tr td:first-child, .export_pdf_ar tbody tr td:first-child{
          padding: 5px;
          border-left: 1px solid #D7D7D7;
        }
        .export_pdf tbody tr td {
          border-right: 1px solid #D7D7D7;
        }
        .export_pdf tbody tr td:last-child {
          border-right: unset;
        }
        
        .export_pdf_ar tbody tr td {
          border-left: 1px solid #D7D7D7;
        }
        .export_pdf_ar tbody tr td:last-child {
          border-right: 1px solid #D7D7D7;
        }
        .export_pdf_ar tbody tr td:last-child {
          border-left: unset;
        }
        .export_pdf p, .export_pdf_ar p {
          font-weight: 800;
        }
        .export_pdf_ar p {
          font-family: 'Tajawal';
        }
        .export_pdf table {
          margin: 0px 0px 40px 0px;
        }
        .export_pdf p {
          font-weight: 800;
        }
        </style><meta name="viewport" content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"/><meta charset="utf-8" /><meta name="theme-color" content="#000000" /></head><body class=${lng === 'arab' ? "export_pdf_ar" : "export_pdf"}>${chatItem.current.innerHTML}</body></html>`
      }

    };
    axios(config)
    .then(function (response) {
      setGeneratePDFLoading(false)
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'file.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    })
    .catch(function (error) {
      // console.log(error)
      checkErr(error, setGeneratePDFLoading, toastAlert)
    });
  };
  const [totalImages, setTotalImages] = useState(0); const [dataObj, setDataObj] = useState(
    finalElemnt ?
      finalElemnt?.inputs?.map(item => {
        return { [item.key]: '' }
      }) :
      []
  );
  useEffect(() => {
    if (!bigLoading) {
      // if (user?.enterprise) {
      if (name) {
        let element = category?.filter(item => item?.category === name)?.slice(0)?.shift();
        if (element) {
          setErrorHandling({ status: false, message: '' })
          if (id) {
            let subElement = business_cases?.filter(item => item?.id == id)?.slice(0)?.shift();
            if (subElement) {
              setDataObj(
                subElement?.inputs?.map(item => { return { [item.key]: '' } })
              );
              setErrorHandling({ status: false, message: '' });
              setFinalElement(subElement)
            } else {
              setErrorHandling({ status: true, message: 'Please select another tool' });
            }
          }
          setInnerLoading(false)
        } else {
          setErrorHandling({ status: true, message: 'Please select another category' });
          setInnerLoading(false)
        }
        // }
      } else {
        navigate('/')
      }
    }

  }, [name, id, category, business_cases, bigLoading])

  useEffect(() => {
    setImageLimit(userSubscription?.product?.metadata?.image_count_left || plansLimits[user?.plan]?.image_limit || userEnterprise?.enterprise?.image_limit)
    setWordLimit(userSubscription?.product?.metadata?.text_count_left || plansLimits[user?.plan]?.word_limit || userEnterprise?.enterprise?.text_limit)
    setTotalWords(user.text_count_left)
    setTotalImages(user?.image_count_left)
  }, [userSubscription, user, userEnterprise])

  const handleFormChange = (e, i) => {
    let arr = dataObj.slice(0);
    let arrwith = arr?.filter((item, index) => index !== i);
    let neededObj = arr?.filter((item, index) => index === i)?.slice(0)?.shift();
    neededObj[e.target.name] = e.target.value;
    arr.splice(i, 1, neededObj)
    setDataObj(arr);

  }
  const [isConnected, setIsConnected] = useState(socket.connected);
  useEffect(() => {
    setShowExport(false)
    // socket.disconnect();
    dispatch({ type: "REMOVE_ALL_BUSINESS" })
    socket.connect();
    return () => {
      socket.disconnect();
    };
  }, [location.pathname])
  useEffect(() => {
    let token = { token: localStorage.getItem("sess") };
    if (isConnected) {
      socket.emit("token", token);
    }
  }, [isConnected]);
  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }
    function onDisconnect() {
      setInnerLoadingSwitch(false)
      setIsConnected(false)
    }
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
  }, []);
  const generateText = async (e) => {
    e.preventDefault();
    if (!isConnected) {
      socket.connect();
    }
    if (!user?.enterprise) {
      return setShowSeconModal(true)
    }
    if (totalWords === 0) {
      return setShowModal(true);
    } else {
      dispatch({ type: "REMOVE_ALL_BUSINESS" })
      setShowExport(false)
      setGeneratedText([])
      setInnerLoadingSwitch(true)
      let arr = [];
      let obj = {};
      for (let i = 0; i < dataObj.length; i++) {
        for (const item in dataObj[i]) {
          if (!dataObj[i][item] && item !== 'tone' && finalElemnt?.inputs?.filter(inner => inner?.key === item)?.slice(0)?.shift()?.optional !== "True") {
            setInnerLoadingSwitch(false)
            return toastAlert(`There is some missing data, please check your form`, undefined, 'warning');
          }
          if (item === 'tone') {
            obj[item] = toneInput;
          } else {
            obj[item] = dataObj[i][item];
          }
        }
      }
      arr.push(obj)
      let request = {
        token: localStorage.getItem("sess"),
        action: "business_tool",
        useCase: finalElemnt?.key,
        tone: toneInput,
        language: languageInput,
        inputs: arr,
        number: 1,
      }
      socket.emit('request', request, () => { });
    }
  }
  useEffect(() => {
    const onMessagesEvent = async (value) => {
      switch (value?.type) {
        case "response":
          if (!value?.data?.streaming) {
            setInnerLoadingSwitch(false);
            setShowExport(true)
          }
          dispatch({ type: "ADD_MESSAGE_BUSINESS", data: value?.data?.message?.content })
          // setGeneratedText([...generatedText, value?.data?.message?.content])
          // setGeneratedText(value?.data?.message?.content)
          break;
        case "token":
          break;
        case "limit":
          dispatch({ type: USER, data: value?.user });
          break;
        case "error":
          setInnerLoadingSwitch(false)
          toastAlert(value?.error, undefined, 'warning')
          // checkErr(value?.error, setInnerLoadingSwitch, toastAlert)
          break;
        default:
          setInnerLoadingSwitch(false);
          break;
      }
    };
    socket.on("data", onMessagesEvent);
    return () => {
      socket.off("data", onMessagesEvent);
    };
  }, [generatedText, location]);
  useEffect(() => {
    setGeneratedText(business_output?.business_output?.join(''))
  }, [business_output])

  const scrollRef = useRef();



  var arabic = /[\u0600-\u06FF]/;
  const [isAtBottom, setIsAtBottom] = useState(false)
  const isUserAtBottomRef = useRef(true);
  useEffect(() => {
    const container = scrollRef.current;
    if (isUserAtBottomRef.current) {
      container.scrollTop = container.scrollHeight;

    }
  }, [generatedText, isUserAtBottomRef.current]);

  const handleScroll = () => {
    const container = scrollRef.current;
    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;

    if (scrollHeight <= scrollTop + clientHeight + 50) {
      isUserAtBottomRef.current = true;
      container.style.overflowScrolling = 'auto';
    } else {
      isUserAtBottomRef.current = false;
      container.style.overflowScrolling = 'touch';
    }
  };
  useEffect(() => {
    const container = scrollRef.current;
    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const spaceHandeler = (e) => {
    if (e.which === 32) {
      e.preventDefault();
      return false;
    }
    if (e.which === 13) {
      if (innerLoadingSwitch) {
        e.preventDefault();
        return false;
      }
    }
  }
  return (
    <Fragment>
      <div onScroll={handleScroll} ref={scrollRef} style={{ width: '100%', height: '100vh', backgroundColor: '#fff', overflowY: 'auto', scrollBehavior: 'smooth' }} className="inner_content">
        {innerLoadin ?
          <LoadingPopUp gptLoading={true} opacity={true} /> :
          null
        }
        <div className="categories_header subCtegoryHeader">
          <Link to={`/business-tools/${name}`} className="sub_case_header">
            <img src={backArow} alt='back_button' />
            <label style={{ textAlign: lng === 'arab' ? 'right' : 'left', direction: arabic.test(lng === 'arab' ? finalElemnt?.arabic_name : finalElemnt?.name) ? 'rtl' : 'ltr' }}>{lng === 'arab' ? finalElemnt?.arabic_name : finalElemnt?.name}</label>
            {finalElemnt?.info ?
              <Tippy theme="light" content={<label className={lng === 'arab' ? 'tooltip_styling_ar' : 'tooltip_styling'}>{lng === 'arab' ? finalElemnt?.info_ar : finalElemnt?.info}</label>}>
                <span id="engagment_tool">
                  <img style={{ marginBottom: '5px', transform: 'unset' }} src={infoIcon} alt="info_icon" />
                </span>
              </Tippy> : null}
          </Link>
        </div>
        {errorHandling.status ?
          <div className="all_images_wrapper">
            <label>{errorHandling?.message}</label>
          </div> :
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', flexDirection: 'column' }}>
            <div style={{ backgroundColor: '#fafdff', paddingBottom: '20px', width: '100%', paddingTop: '10px' }} className='row'>
              <form onSubmit={generateText} className='col-lg-10 col-md-12 col-sm-12'>
                <div style={{ textAlign: lng === 'arab' ? 'right' : null, flexDirection: 'row', alignItems: 'flex-start' }} className="upperForm_wrraper row">
                  {finalElemnt?.inputs?.filter(item => item?.key !== 'tone')?.map((item, i) =>
                    <div key={i} className={"form-group col-lg-6 col-md-6 col-sm-12"}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <label>{lng === 'arab' ? item?.arabic_name : item?.name}</label>
                        {item?.info ?
                          <Tippy className="tippy_custom" placement="bottom" theme="light" content={<label className={lng === 'arab' ? 'tooltip_styling_ar' : 'tooltip_styling'}>{lng === 'arab' ? item?.info_ar : item?.info}</label>}>
                            <span id="engagment_tool">
                              <img style={{ marginBottom: '5px' }} src={infoIcon} alt="info_icon" />
                            </span>
                          </Tippy> : null}
                      </div>
                      {item?.text_type === 'textarea' || item?.text_type === 'text' ?
                        <TextareaAutosize
                          className="form-control"
                          style={{ overflow: 'hidden' }}
                          type={item?.text_type}
                          placeholder={lng === 'arab' ? item?.arabic_placeholder : item?.placeholder}
                          name={item?.key}
                          value={dataObj[i][item?.key]}
                          onChange={(e) => handleFormChange(e, i)}
                        /> :
                        item?.text_type === 'select' ?
                          <select className="form-control" name={item?.key} value={dataObj[i][item?.key]} onChange={(e) => handleFormChange(e, i)} >
                            <option value={''} disabled>{t("Select")}</option>
                            {dropdownContainer[item?.key]?.map((item, i) =>
                              <option key={i} value={item?.key}>{lng === 'arab' ? item?.ar_name : item?.name}</option>
                            )}
                          </select> :
                          <input
                            className="form-control"
                            type={item?.text_type}
                            placeholder={lng === 'arab' ? item?.arabic_placeholder : item?.placeholder}
                            name={item?.key}
                            value={dataObj[i][item?.key]}
                            onChange={(e) => handleFormChange(e, i)}
                          />
                      }
                    </div>
                  )}
                  <div className="form-group col-lg-6 col-md-12 col-sm-12">
                    <label>{t("Please select the language")}</label>
                    <select className="form-control" value={languageInput} onChange={(e) => setLanguageInput(e.target.value)}>
                      <option value={''} disabled>{t("Select Language")}</option>
                      {languages?.map((item, i) =>
                        <option key={i} value={item?.key}>{item?.name}</option>
                      )}
                    </select>
                  </div>
                  {finalElemnt?.inputs?.filter(item => item?.key === 'tone')?.length ?
                    <div className="form-group col-lg-6 col-md-12 col-sm-12">
                      <label>{t("How Do You Want The Caption To Sound?")}</label>
                      <select className="form-control" value={toneInput} onChange={(e) => setToneInput(e.target.value)}>
                        <option value={''} disabled>{t("Select Tone")}</option>
                        {tone?.map((item, i) =>
                          <option key={i} value={item?.key}>{lng === 'arab' ? item?.Arabic_Name : item?.name}</option>
                        )}
                      </select>
                    </div> : null}
                  <div style={{ alignItems: 'center', justifyContent: 'flex-end', display: 'flex' }} className="col-lg-12 col-md-12 col-sm-12">
                    <button onKeyDown={spaceHandeler} onKeyUp={spaceHandeler} style={{ background: innerLoadingSwitch ? 'gray' : 'linear-gradient(45deg, rgba(17, 113, 216, 1) 0%, rgba(60, 200, 235, 1) 100%)', pointerEvents: innerLoadingSwitch && 'none', borderRadius: '15px', width: '136px', color: '#fff' }} className="form-control btn_maketing" type="submit" >
                      {innerLoadingSwitch ? <LoadingEffect /> : t("Generate")}
                    </button>
                  </div>
                </div>


                {/* <ClaimFreeModal showModal={claimPopUp} setShowModal={setClaimPopUp}/> */}
                {user?.plan === "Trial" ? (
                  <UpgradeModal setShowModal={setShowModal} showModal={showModal} />


                ) : (
                  <LimitPopUp setShowModal={setShowModal} showModal={showModal} />
                )}
              </form>
            </div>
            {generatedText?.length ?
              <div style={{ minHeight: '200px', width: '96%', maxWidth: '1400px' }} className={lng === 'arab' ? !arabic.test(generatedText) ? "export_pdf" : "export_pdf_ar" : !arabic.test(generatedText) ? "export_pdf" : "export_pdf_ar"}>
                {/* <ReactToPdf
              targetRef={chat_contentRef}
              filename={lng === 'arab' ? `${finalElemnt?.arabic_name}.pdf` : `${finalElemnt?.name}.pdf`}
            > */}
                {/* {({ toPdf }) => */}
                {/* {showExport && <PDFDownloadLink
                document={<MyDocument element={generatedText}/>}
                fileName="test.pdf" */}
                {/* > */}
                {/* {({ blob, url, loading, error }) => */}
                {/* <button onClick={() => printDocument()} disabled={loading || innerLoadingSwitch || generatePDFLoading}> <OpenInNewOutlinedIcon  sx={{fontSize : '20px'}}/> {t("Export")}</button> */}
                {/* } */}
                {/* </PDFDownloadLink>} */}
                {/* } */}
                {/* </ReactToPdf> */}
                <label style={{ textAlign: !arabic.test(generatedText) ? "left" : "right", fontFamily: arabic.test(generatedText) ? "Tajawal" : "Sohne", direction: !arabic.test(generatedText) ? "ltr" : "rtl", maxWidth: '1400px', width: '100%', padding: '10px' }}>
                  <ReactMarkdown
                    children={generatedText}
                    remarkPlugins={[remarkGfm]}
                    components={{
                      code({ node, inline, className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || '')
                        return !inline && match ? (
                          <>
                            <SyntaxHighlighter
                              children={String(children).replace(/\n$/, '')}
                              language={detect(String(children?.slice(0)?.shift()))?.toLowerCase()}
                              PreTag="div"
                              style={docco}
                              {...props}
                            />
                          </>
                        ) :
                          <code  {...props} className={className}>
                            {children}
                          </code>
                      }
                    }}
                  />
                </label>
              </div> : null}
          </div>}
      </div>
      <YouAreNotEnterprise
        setShowModal={setShowSeconModal}
        showModal={showSecondModal}
      />
    </Fragment>
  );
};

export default MarketingPlan;

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});
const MyDocument = ({ element }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text><ReactMarkdown remarkPlugins={[remarkGfm]}>{element}</ReactMarkdown></Text>
          {/* <Text> */}
          {/* {element} */}
          {/* <ReactMarkdown remarkPlugins={[remarkGfm]}>{element}</ReactMarkdown> */}
          {/* </Text> */}
        </View>
      </Page>
    </Document>
  )
};

const YouAreNotEnterprise = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  return (
    <Modal
      className={lng === 'arab' ? "modal modal-primary arabicFont thank-you-modal" : "modal modal-primary englishFont thank-you-modal"}
      show={props.showModal}
      centered
      onHide={() => props.setShowModal(false)}
    >
      <Modal.Header>
        <button onClick={() => props.setShowModal(false)} className='btn-close'>
          <i className="nc-icon nc-simple-remove"></i>
        </button>
      </Modal.Header>
      <Modal.Body className="text-center" >
        <div className="row justify-content-center">
          <img style={{ maxHeight: '100px' }} className='modalImgWrapper' src={OopsSvg} />
        </div>
        <h3 style={{ margin: '0px', lineHeight: '40px' }}>
          {t("We are sorry, You can not continue")}
        </h3>
        <p>{t("Because you don't have a Business account, You can upgrade your subscribtion by clicking the button below")}</p>
        <Link
          to={'/plan'}
          style={{ background: 'linear-gradient(47deg, #1171d8, #3cc8eb)' }}
          className="btn common-btn-modal"
        >
          {t("Subscribe")}
        </Link>
      </Modal.Body>
    </Modal >
  )
}
