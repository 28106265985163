import L1 from "assets/img/loading-images/img1.png";
import L2 from "assets/img/loading-images/img2.png";
import L3 from "assets/img/loading-images/img3.png";
import L4 from "assets/img/loading-images/img4.png";
import L5 from "assets/img/loading-images/img5.png";
import L6 from "assets/img/loading-images/img6.png";
import L7 from "assets/img/loading-images/img7.png";
import L8 from "assets/img/loading-images/img8.png";
import L9 from "assets/img/loading-images/img9.png";
import L10 from "assets/img/loading-images/10.png";
import L11 from "assets/img/loading-images/11.png";
import L12 from "assets/img/loading-images/12.png";
import L13 from "assets/img/loading-images/13.png";
import L14 from "assets/img/loading-images/14.png";
import L15 from "assets/img/loading-images/15.png";
import L16 from "assets/img/loading-images/16.png";
import L17 from "assets/img/loading-images/17.png";
import L18 from "assets/img/loading-images/18.png";
import L19 from "assets/img/loading-images/img19.png";
import L20 from "assets/img/loading-images/img20.png";
import L21 from "assets/img/loading-images/img21.png";
import L22 from "assets/img/loading-images/img22.png";
import L23 from "assets/img/loading-images/img23.png";
import L24 from "assets/img/loading-images/img24.png";
import L25 from "assets/img/loading-images/img25.png";
import L26 from "assets/img/loading-images/img26.png";
import L27 from "assets/img/loading-images/img27.png";
import L28 from "assets/img/loading-images/img28.png";
import L31 from "assets/img/loading-images/img31.png";
import L32 from "assets/img/loading-images/img32.png";
import L33 from "assets/img/loading-images/img33.png";
import L34 from "assets/img/loading-images/img34.png";
import L35 from "assets/img/loading-images/img35.png";
import L36 from "assets/img/loading-images/img36.png";
import L37 from "assets/img/loading-images/img37.png";
import L38 from "assets/img/loading-images/img38.png";
import L39 from "assets/img/loading-images/img39.png";

const prompts = [
  {
    image: L1,
    prompt: "Car in the deserted land with mountain",
  },
  {
    image: L2,
    prompt: "The futuristic landscape with big moon",
  },
  // {
  //   image: L3,
  //   prompt:
  //     "A man standing on top of building with red moon in cyberpunk styles",
  // },
  // {
  //   image: L4,
  //   prompt: "A tree on top of mountain fantasy style",
  // },
  // {
  //   image: L5,
  //   prompt: "Modern kitchen with blue and green theme",
  // },
  // {
  //   image: L6,
  //   prompt: "White Lion in the skyline style",
  // },
  // {
  //   image: L7,
  //   prompt: "A spooky haunted house with bats flying around",
  // },
  // {
  //   image: L8,
  //   prompt: "A spooky haunted house with bats flying around",
  // },
  // {
  //   image: L9,
  //   prompt: "A grand canyon with a river flowing at the bottom",
  // },
  {
    image: L10,
    prompt: "Neon Samurai in black and red holding two swords",
  },
  {
    image: L11,
    prompt: "Thick oil painting of a womens face with vivid intense colors in yellow and purple",
  },
  {
    image: L12,
    prompt: "Thick oil painting of a fox gazing in the distance with short brush strokes",
  },
  {
    image: L13,
    prompt: "A fluffy white Maltese dog sitting inside a snow globe with snow falling",
  },
  {
    image: L14,
    prompt: "The Starry Night by Vincent van Gogh hanging on a yellow wall",
  },
  {
    image: L15,
    prompt: "Colorful bird soaring through space with the cosmos behind him",
  },
  {
    image: L16,
    prompt: "Hand drawn mythical creatures on a painting canves with paint splashed on it",
  },
  {
    image: L17,
    prompt: "Paint brush art of a dog drawn on canvas with thick paint strokes and colorful paint",
  },
  {
    image: L18,
    prompt: "A man face emerging out of smoke with intense colorful colors splattered on his face, cinematic",
  },
  {
    image: L19,
    prompt: "A city skyline at night with a full moon",
  },
  // {
  //   image: L20,
  //   prompt: "A misty forest with a mysterious fog hovering over it",
  // },
  // {
  //   image: L21,
  //   prompt: "A snow-covered landscape with a cabin in the woods",
  // },
  // {
  //   image: L22,
  //   prompt: "A group of hot air balloons floating over a mountain range",
  // },
  // {
  //   image: L23,
  //   prompt: "A Giant octopus drowning a ship in the ocean",
  // },
  // {
  //   image: L24,
  //   prompt: "A stormy sea with a lighthouse in the distance",
  // },
  // {
  //   image: L25,
  //   prompt: "A waterfall cascading into a crystal-clear pool",
  // },
  // {
  //   image: L26,
  //   prompt:
  //     "A medieval village with thatched-roof houses and a winding cobblestone road",
  // },
  // {
  //   image: L27,
  //   prompt: "A group of animals in a forest clearing at dawn",
  // },
  // {
  //   image: L28,
  //   prompt: "A volcano erupting with lava flowing down its slopes",
  // },
  // {
  //   image: L31,
  //   prompt:
  //     "An underwater world with crystal-clear water that reveals a colorful coral reef teeming with exotic fish. Sunlight filters down from the surface, casting a warm glow on the reef and illuminating the vibrant colors of the sea creatures.",
  // },
  // {
  //   image: L32,
  //   prompt:
  //     "A desert landscape with towering sand dunes that stretch as far as the eye can see. The sun is setting, casting a warm orange glow over the landscape. The sky is a riot of color, with shades of pink, purple, and red blending together in a stunning display.",
  // },
  // {
  //   image: L33,
  //   prompt:
  //     "A snowy mountain range with jagged peaks that pierce the clear blue sky. The snow-covered landscape is pristine and untouched, and the air is crisp and cold. The sun reflects off the snow, creating a dazzling, almost blinding, effect.",
  // },
  // {
  //   image: L34,
  //   prompt:
  //     "a home built in a huge Soap bubble, windows, doors, porches, awnings, middle of SPACE, cyberpunk lights, Hyper Detail, 8K, HD, Octane Rendering, Unreal Engine, V-Ray, full hd -- s5000 --uplight --q 3 --stop 80--w 0.5",
  // },
  // {
  //   image: L35,
  //   prompt:
  //     "Rubber Duck Aliens visiting the Earth for the first time, hyper-realistic, cinematic, detailed",
  // },
  // {
  //   image: L36,
  //   prompt:
  //     "Starvation black monster wolves with red eyes, fight in the ice age Scenery, : concept art::1 cyberpunk::1 splatter paint::1 wide angle lens::2 Zdzislaw Beksinski style, and a little prince boy  walks to the Shining Ethereum shape sun, in winter storm, ultra detailed",
  // },
  {
    image: L37,
    prompt:
      "Skeleton King of Hell :: Flaming Skull, Intricately Designed Nordic Armor",
  },
  {
    image: L38,
    prompt:
      "Full body cyborg| full-length portrait| detailed face intricate detailed| to scale",
  },
  {
    image: L39,
    prompt: "cyberpunk dog, futuristic, highly detailed, made with blender",
  },
];

export default prompts;
