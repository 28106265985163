import "../Checkout/checkout.module.scss";
import { useTranslation } from "react-i18next";
import { ReactComponent as Alert } from "assets/img/downgrade/Danger.svg";
import Downgrade from "./Downgrade";
import { Modal } from "react-bootstrap";
import { useState } from "react";
//<-------------CSS Styles Start--------------->

const downgradePopUpContainer = {
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  margin: "10px",
};

const downgradeHead = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  flexDirection: "column",
  marginRight: "0px",
};

const downgradePopUpButtonWrapper = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  gap: "1rem",
  margin: "auto",
};

const buttonStyle1 = {
  borderRadius: "12px",
  padding: "7px 20px",
  border: "none",
  fontWeight: "700",
  background: "#EF142E",
  cursor: "pointer",
  color: "#fff",
};

const buttonStyle2 = {
  borderRadius: "12px",
  padding: "7px 20px",
  border: "none",
  fontWeight: "700",
  background: "#1777F2",
  cursor: "pointer",
  color: "#fff",
};

//<----------------CSS Styles End------------------>

const DowngradePopUp = (props) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  return (
    <Modal centered show={props.showModal} onHide={() => props.setShowModal(false)} {...props}>
      <div style={{ fontFamily: lng === "arab" ? "Tajawal" : "Poppins" }}>
        <div style={downgradePopUpContainer}>
          <div className="head" style={downgradeHead}>
            <Alert />
            <h4
              style={{
                fontSize: "18px",
                textAlign: "center",
                fontWeight: "600",
                color: "black",
              }}
            >
              {t("Hold on! We can't proceed, you currently have more users in your account than in this plan.")}
            </h4>
            <div style={downgradePopUpButtonWrapper}>
              <button
                style={buttonStyle1}
                onClick={() => {
                  props.showDown(true);
                  props.setShowModal(false);
                }}
              >
                {t("Manage Users")}
              </button>
              <button style={buttonStyle2} onClick={() => props.setShowModal(false)}>
                {t("Keep My Plan")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DowngradePopUp;
