import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import backArow from "../../assets/img/newDesign/backArrow.svg";
import { useState } from "react";
import { toastAlert } from "components/toastAlert/toastAlert";
import TextareaAutosize from "react-textarea-autosize";
import { Form, Modal, Spinner } from "react-bootstrap";
import Rte from "components/BlogNote/Rte";
import LoadingEffect from "components/Loading/LoadingEffect";
import { t } from "i18next";
import ReactMarkdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { detect } from "program-language-detector";
import rehypeRaw from "rehype-raw";
import styles from "./blog.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getSingleBlogs } from "redux/actions/blog";
import { updateBlog } from "redux/actions/blog";
import { Fragment } from "react";
import close from "../../assets/img/close.png";

let lng = JSON.parse(localStorage.getItem("language"))?.lang;

const UpdateBlog = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [blog, setBlog] = useState(null);
  const [titleEn, setTitleEN] = useState(blog?.title_en || "");
  const [titleAr, setTitleAr] = useState(blog?.title_ar || "");
  const [shortDescriptionEn, setShortDescriptionEn] = useState(
    blog?.description_en || ""
  );
  const [shortDescriptionAr, setShortDescriptionAr] = useState(
    blog?.description_ar || ""
  );
  const [cover, setCover] = useState("");
  const [coverAltEn, setCoverAltEn] = useState(blog?.cover_alt_en || "");
  const [coverAltAr, setCoverAltAr] = useState(blog?.cover_alt_ar || "");
  const [banner, setBanner] = useState("");
  const [bannerAltEn, setBannerAltEn] = useState(blog?.banner_alt_en || "");
  const [bannerAltAr, setBannerAltAr] = useState(blog?.banner_alt_ar || "");
  const [metaDescriptionEn, setMetaDescriptionEn] = useState(
    blog?.meta_description_en || ""
  );
  const [metaDescriptionAr, setMetaDescriptionAr] = useState(
    blog?.meta_description_ar || ""
  );
  const [contentEn, setContentEn] = useState(blog?.content_en || "");
  const [contentAr, setContentAr] = useState(blog?.content_ar || "");
  const [loading, setLoading] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [type, setType] = useState("");

  useEffect(() => {
    if (params?.id) {
      fetchBlog(params?.id);
    }
  }, []);

  useEffect(() => {
    if (blog) {
      setTitleEN(blog?.title_en || "");
      setTitleAr(blog?.title_ar || "");
      setShortDescriptionEn(blog?.description_en || "");
      setShortDescriptionAr(blog?.description_ar || "");
      setCoverAltEn(blog?.cover_alt_en || "");
      setCoverAltAr(blog?.cover_alt_ar || "");
      setBannerAltEn(blog?.banner_alt_en || "");
      setBannerAltAr(blog?.banner_alt_ar || "");
      setMetaDescriptionEn(blog?.meta_description_en || "");
      setMetaDescriptionAr(blog?.meta_description_ar || "");
      setContentEn(blog?.content_en || "");
      setContentAr(blog?.content_ar || "");
    }
  }, [blog]);

  const fetchBlog = async (id) => {
    setLoading(true);
    dispatch(getSingleBlogs(id, setLoading, setBlog));
  };

  const handleSubmit = () => {
    setLoading(true);
    let data = {
      title_en: titleEn,
      title_ar: titleAr,
      description_en: shortDescriptionEn,
      description_ar: shortDescriptionAr,
      cover_alt_en: coverAltEn,
      cover_alt_ar: coverAltAr,
      banner_alt_en: bannerAltEn,
      banner_alt_ar: bannerAltAr,
      meta_description_en: metaDescriptionEn,
      meta_description_ar: metaDescriptionAr,
      content_en: contentEn,
      content_ar: contentAr,
      publishedAt: new Date(),
    };
    if (cover) {
      data.cover_image = cover;
    }
    if (banner) {
      data.banner_image = banner;
    }
    if (
      !data.title_en ||
      !data.title_ar ||
      !data.description_en ||
      !data.description_ar ||
      !data.cover_alt_en ||
      !data.cover_alt_ar ||
      !data.banner_alt_en ||
      !data.banner_alt_ar ||
      !data.meta_description_en ||
      !data.meta_description_ar ||
      !data.content_en ||
      !data.content_ar
    ) {
      setLoading(false);
      return toastAlert(
        `There is some missing data, please check your form`,
        undefined,
        "warning"
      );
    } else {
      dispatch(updateBlog(data, blog?._id, setLoading, navigate));
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      setCover(e.target.files[0]);
    }
  };

  const handleFileChangeBanner = (e) => {
    if (e.target.files) {
      setBanner(e.target.files[0]);
    }
  };

  return (
    <Fragment>
      <div className="inner_content">
        <div className="categories_header subCtegoryHeader">
          <Link to={`/blog`} className="sub_case_header">
            <img src={backArow} alt="back_button" />
            <label>{t("Edit Blog")}</label>
          </Link>
        </div>
        <div
          style={{ margin: "0px", maxWidth: "unset" }}
          className="write_body inner_writeBody container"
        >
          <div className="sub_case_page_wrapper">
            <div
              style={{
                overflow: "auto",
                top: "70px",
              }}
              className="col-lg-12 row"
            >
              {loading ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    minHeight: "500px",
                  }}
                >
                  <Spinner animation="border" role="status" />
                </div>
              ) : blog ? (
                <Fragment>
                  <div className="col-lg-4 col-md-6 col-sm-12 mt-5 chat_wrapper">
                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <label className="table-body-label">
                        {t("Title (en)")}:
                      </label>
                      <TextareaAutosize
                        autoFocus
                        style={{ height: "40px", overflow: "hidden" }}
                        className="form-control"
                        placeholder={t("Title")}
                        value={titleEn}
                        onChange={(e) => setTitleEN(e.target.value)}
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <label className="table-body-label">
                        {t("Title (ar)")}:
                      </label>
                      <TextareaAutosize
                        autoFocus
                        style={{ height: "40px", overflow: "hidden" }}
                        className="form-control"
                        placeholder={t("Title (AR)")}
                        value={titleAr}
                        onChange={(e) => setTitleAr(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mt-5 chat_wrapper">
                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <label className="table-body-label">
                          {t("Cover Image (400 x 284)")}
                        </label>
                        {!cover && (
                          <Link
                            onClick={() => {
                              setShowImage(true);
                              setType("COVER");
                            }}
                            style={{ color: "#1877f2" }}
                          >
                            {t("View Image")}
                          </Link>
                        )}
                      </div>
                      <Form.Group>
                        <Form.Control
                          onChange={handleFileChange}
                          className="form-control"
                          style={{
                            padding: "5px",
                            height: "40px",
                            overflow: "hidden",
                          }}
                          type="file"
                          placeholder="choose cover"
                        />
                      </Form.Group>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <label className="table-body-label">
                          {t("Banner Image (1209 x 437)")}
                        </label>
                        {!banner && (
                          <Link
                            onClick={() => {
                              setShowImage(true);
                              setType("BANNER");
                            }}
                            style={{ color: "#1877f2" }}
                          >
                            {t("View Image")}
                          </Link>
                        )}
                      </div>
                      <Form.Group>
                        <Form.Control
                          onChange={handleFileChangeBanner}
                          className="form-control"
                          style={{
                            padding: "5px",
                            height: "40px",
                            overflow: "hidden",
                          }}
                          type="file"
                          placeholder="choose cover"
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mt-5 chat_wrapper">
                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <label className="table-body-label">
                        {t("Cover Alt (en)")}:
                      </label>
                      <TextareaAutosize
                        autoFocus
                        style={{ height: "40px", overflow: "hidden" }}
                        className="form-control"
                        placeholder={t("Alt")}
                        value={coverAltEn}
                        onChange={(e) => setCoverAltEn(e.target.value)}
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <label className="table-body-label">
                        {t("Cover Alt (ar)")}:
                      </label>
                      <TextareaAutosize
                        autoFocus
                        style={{ height: "40px", overflow: "hidden" }}
                        className="form-control"
                        placeholder={t("Alt (AR)")}
                        value={coverAltAr}
                        onChange={(e) => setCoverAltAr(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mt-5 chat_wrapper">
                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <label className="table-body-label">
                        {t("Banner Alt (en)")}:
                      </label>
                      <TextareaAutosize
                        autoFocus
                        style={{ height: "40px", overflow: "hidden" }}
                        className="form-control"
                        placeholder={t("Alt")}
                        value={bannerAltEn}
                        onChange={(e) => setBannerAltEn(e.target.value)}
                      />
                    </div>

                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <label className="table-body-label">
                        {t("Banner Alt (ar)")}:
                      </label>
                      <TextareaAutosize
                        autoFocus
                        style={{ height: "40px", overflow: "hidden" }}
                        className="form-control"
                        placeholder={t("Alt (AR)")}
                        value={bannerAltAr}
                        onChange={(e) => setBannerAltAr(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mt-5 chat_wrapper">
                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <label className="table-body-label">
                        {t("Short Description (en)")}:
                      </label>
                      <TextareaAutosize
                        autoFocus
                        style={{ height: "40px", overflow: "hidden" }}
                        className="form-control"
                        placeholder={t("Description")}
                        minRows={5}
                        value={shortDescriptionEn}
                        onChange={(e) => setShortDescriptionEn(e.target.value)}
                      />
                    </div>

                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <label className="table-body-label">
                        {t("Short Description (ar)")}:
                      </label>
                      <TextareaAutosize
                        autoFocus
                        style={{ height: "40px", overflow: "hidden" }}
                        className="form-control"
                        placeholder={t("Description (AR)")}
                        minRows={5}
                        value={shortDescriptionAr}
                        onChange={(e) => setShortDescriptionAr(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mt-5 chat_wrapper">
                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <label className="table-body-label">
                        {t("Meta Description (en)")}:
                      </label>
                      <TextareaAutosize
                        autoFocus
                        style={{ height: "40px", overflow: "hidden" }}
                        className="form-control"
                        placeholder={t("Meta Description")}
                        minRows={5}
                        value={metaDescriptionEn}
                        onChange={(e) => setMetaDescriptionEn(e.target.value)}
                      />
                    </div>

                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <label className="table-body-label">
                        {t("Meta Description (ar)")}:
                      </label>
                      <TextareaAutosize
                        autoFocus
                        style={{ height: "40px", overflow: "hidden" }}
                        className="form-control"
                        placeholder={t("Meta Description (AR)")}
                        minRows={5}
                        value={metaDescriptionAr}
                        onChange={(e) => setMetaDescriptionAr(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 mt-5">
                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <label className="table-body-label">
                        {t("Content (en)")}:
                      </label>
                      <Rte content={contentEn} setContent={setContentEn} />
                    </div>
                  </div>
                  <div className="col-lg-6 mt-5 d-none d-lg-block">
                    <div
                      style={{ textAlign: lng === "arab" ? "right" : "unset" }}
                    >
                      <label className="table-body-label">
                        {t("Live Preview")}
                      </label>
                      <div className={styles.markDown}>
                        <ReactMarkdown
                          children={contentEn}
                          rehypePlugins={[rehypeRaw]}
                          components={{
                            code({
                              node,
                              inline,
                              className,
                              children,
                              ...props
                            }) {
                              const match = /language-(\w+)/.exec(
                                className || ""
                              );
                              return !inline && match ? (
                                <>
                                  <SyntaxHighlighter
                                    children={String(children).replace(
                                      /\n$/,
                                      ""
                                    )}
                                    language={detect(
                                      String(children?.slice(0)?.shift())
                                    )?.toLowerCase()}
                                    PreTag="div"
                                    style={docco}
                                    {...props}
                                  />
                                </>
                              ) : (
                                <code {...props} className={className}>
                                  {children}
                                </code>
                              );
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 mt-5">
                    <div
                      style={{
                        width: "100%",
                        textAlign: lng === "arab" ? "right" : "unset",
                      }}
                    >
                      <label className="table-body-label">
                        {t("Content (ar)")}:
                      </label>
                      <Rte content={contentAr} setContent={setContentAr} />
                    </div>
                  </div>
                  <div className="col-lg-6 mt-5 px-3 d-none d-lg-block">
                    <div
                      style={{ textAlign: lng === "arab" ? "right" : "unset" }}
                    >
                      <label className="table-body-label">
                        {t("Live Preview")}
                      </label>
                      <div className={styles.markDown}>
                        <ReactMarkdown
                          children={contentAr}
                          rehypePlugins={[rehypeRaw]}
                          components={{
                            code({
                              node,
                              inline,
                              className,
                              children,
                              ...props
                            }) {
                              const match = /language-(\w+)/.exec(
                                className || ""
                              );
                              return !inline && match ? (
                                <>
                                  <SyntaxHighlighter
                                    children={String(children).replace(
                                      /\n$/,
                                      ""
                                    )}
                                    language={detect(
                                      String(children?.slice(0)?.shift())
                                    )?.toLowerCase()}
                                    PreTag="div"
                                    style={docco}
                                    {...props}
                                  />
                                </>
                              ) : (
                                <code {...props} className={className}>
                                  {children}
                                </code>
                              );
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center my-5 w-100">
                    <button
                      style={{
                        padding: "10px",
                        background: loading
                          ? "gray"
                          : "linear-gradient(to right, #d851ef, #1877f2)",
                        pointerEvents: loading && "none",
                      }}
                      className="codeFormSubmit col-lg-2 col-md-2 col-sm-2"
                      onClick={() => handleSubmit()}
                    >
                      {loading ? <LoadingEffect /> : t("Save")}
                    </button>
                  </div>
                </Fragment>
              ) : null}
            </div>
          </div>
        </div>
        <Modal
          style={{ paddingTop: "5%", backgroundColor: "#000000a8" }}
          show={showImage}
          onHide={() => setShowImage(false)}
          backdrop={false}
        >
          <Modal.Header
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>{type === "COVER" ? t("Cover Image") : t("Banner Image")}</div>
            <img
              src={close}
              width={20}
              onClick={() => setShowImage(false)}
              style={{ cursor: "pointer" }}
            />
          </Modal.Header>
          <Modal.Body>
            <img
              style={{ width: "100%" }}
              src={type === "COVER" ? blog?.cover_image : blog?.banner_image}
              alt={type === "COVER" ? blog?.cover_alt_en : blog?.banner_alt_en}
            />
          </Modal.Body>
        </Modal>
      </div>
    </Fragment>
  );
};

export default UpdateBlog;
