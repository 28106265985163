import React, { useState, useEffect } from "react";
import UpgradeModal from "components/UpgradeModal/UpgradeModal";
import UpgradePlanModal from "components/UpgradeModal/UpgradePlanModal";
import { toastAlert } from "components/toastAlert/toastAlert";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { handleHeadShot } from "redux/actions/media_section";
import { ReactComponent as Male } from "assets/img/newDesign/imageSectionVideos/male.svg";
import { ReactComponent as Female } from "assets/img/newDesign/imageSectionVideos/female.svg";
import headshot from "assets/img/newDesign/headshot.png";
import CloseIcon from "assets/img/newDesign/Group 9308.png";
import LimitPopUp from "views/plansComponent/LimitPopUp";
import "font-awesome/css/font-awesome.min.css";
import ReplayIcon from "@mui/icons-material/Replay";
import Loading_v2 from "components/Loading/Laoadingv2/Loadingv2";
import "views/MediaSection/ImageTools/Create/ImgGen.css";
import "./HeadShot.scss";
import { handleHeadLoading } from "redux/actions/media_section";
import FileContainer from "components/MediaSection/FileContainer";

const HeadShot = () => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const headShot = useSelector((state) => state.headShot);
  const [showModal, setShowModal] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [base64, setBase64] = useState("");
  const [totalImages, setTotalImages] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [show, setShow] = useState(false);
  const [gender, setGender] = useState("man");
  const [loadingText, setLoadingText] = useState("Uploading your Image");
  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState(null);
  useEffect(() => {
    setTotalImages(user?.image_count_left);
  }, [user]);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const [errors, setError] = useState("");
  let error_valid = lng === "arab" ? "يُسمح فقط بملفات JPG و JPEG و PNG" : "Only JPG, JPEG, and PNG files are allowed.";

  const handleHead = async () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("gender", gender);
    if (totalImages === 0) {
      dispatch({ type: "NEW_HEAD" });
      return setShowModal(true);
    }
    dispatch(handleHeadShot(formData));
  };
  const handleFile = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setBase64(reader.result.toString());
    };
    if (file && /image/.test(file.type)) {
      setSelectedImage(URL.createObjectURL(file));
      dispatch({ type: "UPLOADED_HEAD" });
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    if (totalImages === 0) {
      dispatch({ type: "NEW_HEAD" });
      return setShowModal(true);
    } else {
      const file = await e.dataTransfer.files;
      if (file) {
        const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
        if (allowedTypes.includes(file[0].type)) {
          handleFile(file[0]);
          setFile(file[0]);
          setDragging(false);
        } else {
          setError(error_valid);
          toastAlert(error_valid, undefined, "warning");
          setError("Only JPG, JPEG, and PNG files are allowed.");
        }
      }
    }
  };

  const handleImageUpload = async (e) => {
    e.preventDefault();
    if (totalImages === 0) {
      dispatch({ type: "NEW_HEAD" });
      return setShowModal(true);
    } else {
      const file = await e.target.files;
      if (file) {
        const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
        if (allowedTypes.includes(file[0].type)) {
          handleFile(file[0]);
          setFile(file[0]);
          setDragging(false);
          setError();
        } else {
          toastAlert("Only JPG, JPEG, and PNG files are allowed.", undefined, "warning");
        }
      }
    }
  };

  const handleDownload = async () => {
    const fileUrl = headShot?.headShot?.data?.urls;
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = "headShot twwt";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };
  console.log(headShot, "head");
  useEffect(() => {
    if (headShot?.loading) {
      let intervalTime;
      if (headShot?.loadingNumber >= 1 && headShot?.loadingNumber < 25) {
        intervalTime = 3000 / 25; // 3 seconds for 1-25, increment by 1 every 200ms
        setLoadingText("Uploading Your Image");
      } else if (headShot?.loadingNumber >= 25 && headShot?.loadingNumber < 50) {
        intervalTime = 5000 / 25; // 5 seconds for 25-50, increment by 1 every 800ms
        setLoadingText("Analyzing Your Image");
      } else if (headShot?.loadingNumber >= 50 && headShot?.loadingNumber < 75) {
        intervalTime = 10000 / 25; // 10 seconds for 50-75, increment by 1 every 1200ms
        setLoadingText("Enhancing the Image");
      } else if (headShot?.loadingNumber >= 75 && headShot?.loadingNumber < 100) {
        intervalTime = 50000 / 25; // 50 seconds for 50-75, increment by 1 every 1200ms
        setLoadingText("Downloading the Image");
      }
      const timer = setInterval(() => {
        // console.log(headShot?.loadingNumber, "headShot?.loadingNumber");
        dispatch(handleHeadLoading(headShot?.loadingNumber, "head"));
      }, intervalTime);
      return () => {
        clearInterval(timer);
      };
    }
  }, [headShot?.loadingNumber, headShot?.loading]);
  // console.log(headShot?.loadingNumber, "headShot");
  return (
    <>
      {headShot?.loading ? (
        <div
          style={{
            height: "calc(100vh - 200px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading_v2 loadingText={loadingText} setLoadingText={setLoadingText} loadingNumber={headShot?.loadingNumber} />
        </div>
      ) : headShot?.headShot?.length != 0 ? (
        <div style={{ position: "relative" }}>
          <div
            className="redesign_image"
            style={{
              maxWidth: "500px",
              minHeight: "300px",
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "20px",
              }}
              className="headShot_image_item_item"
            >
              <img
                src={headShot?.headShot?.data?.urls}
                style={{
                  width: width > 1200 ? "450px" : "300px",
                  borderRadius: "10px",
                  maxHeight: "620px",
                  objectFit: "cover",
                  margin: "auto",
                }}
              />

              <div className="beforeafter-gray" style={{ padding: "0px", marginTop: "20px" }}>
                <button className="download-btn" onClick={handleDownload}>
                  <i
                    className="fas fa-arrow-down"
                    style={{
                      fontSize: "medium",
                      margin: "0 10px",
                    }}
                  ></i>
                  {t("Download")}
                </button>
                <button
                  className="newupload"
                  onClick={() => {
                    setBase64("");
                    dispatch({ type: "NEW_HEAD" });
                  }}
                >
                  <ReplayIcon
                    sx={{
                      transform: "scale(-1)",
                      margin: "0 10px",
                      fontSize: "20px",
                    }}
                  />
                  {t("Try Again")}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="image_gen_container">
          <div
            className="image_gen_header"
            style={{
              padding: width < 500 ? "10px" : "20px",
              alignSelf: "flex-start",
            }}
          >
            <h4 style={{ textAlign: lng === "arab" ? "right" : "left" }}>{t("Turn your photo into a professinal headshot")}</h4>
            <p style={{ textAlign: lng === "arab" ? "right" : "left" }}>
              {t("Get professional headshots in seconds with the help of Ai, you never have to worry again about findind the perfect picture")}{" "}
            </p>
            <div className="headShot_container">
              <button
                onClick={() => setGender("man")}
                className="headShot_buttons"
                style={{
                  outline: gender == "man" ? "2px solid rgba(0, 68, 255, 0.7)" : null,
                  margin: "20px 0px",
                }}
              >
                <Male style={{ width: "15px" }} /> {t("Male")}
              </button>
              <button
                onClick={() => setGender("woman")}
                className="headShot_buttons"
                style={{
                  outline: gender == "woman" ? "2px solid rgba(0, 68, 255, 0.7)" : null,
                }}
              >
                <Female style={{ width: "15px" }} />
                {t("Female")}
              </button>
            </div>
            {headShot?.uploaded ? (
              <div
                style={{
                  maxWidth: "500px",
                  maxHeight: "500px",
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <span
                    onClick={() => {
                      dispatch({ type: "NEW_HEAD" });
                    }}
                    className="close_button"
                    style={{
                      left: lng === "arab" ? "0px" : null,
                      right: lng === "arab" ? null : "0px",
                      top: "0px",
                      position: "absolute",
                      top: "30px",
                      right: "25px",
                    }}
                  >
                    <img src={CloseIcon} />
                  </span>
                  <img className="bg_remover_background" style={{ backgroundImage: "none", position: "relative" }} src={selectedImage} />
                  <button
                    onClick={handleHead}
                    style={{
                      marginTop: "0",
                      background: "linear-gradient(252deg, #0047FF 10.82%, #00B2FF 92.85%)",
                      color: "white",
                      fontWeight: "700",
                      borderRadius: "8px",
                      padding: "5px 15px",
                      border: "none",
                      fontSize: "0.9",
                      minWidth: "150px",
                    }}
                    type="submit"
                  >
                    {t("Get Headshots")}
                  </button>
                </span>
              </div>
            ) : (
              <FileContainer
                handleDrop={handleDrop}
                handleDragOver={handleDragOver}
                handleDragLeave={handleDragLeave}
                handleUpload={handleImageUpload}
                dragging={dragging}
                types=".jpg,.jpeg,.png"
                support="JPG , PNG"
              />
            )}
          </div>
          <div className="right_image_media">
            <img src={headshot} alt="upscale-example" width={"100%"} height={"100%"} />
          </div>
        </div>
      )}
      <UpgradeModal images={true} setShowModal={setShowModal} showModal={showModal} />
      <UpgradePlanModal show={show} setShow={setShow} width={width} />
    </>
  );
};

export default HeadShot;
