import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import searchIcon from "../../assets/img/newDesign/searchIcon.svg";
import { useSelector } from "react-redux";
import defaultIcon from "../../assets/img/newDesign/default.png";

import "./filter.css";
import { useTranslation } from "react-i18next";
import newSearch from "../../assets/img/newSearc.png";
import newArrow from "../../assets/img/newArrow.png";
// Import Swiper styles
import "swiper/css";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, autoplay } from "swiper";
import "swiper/css/navigation";
import "swiper/css";
import arrowForCases from "../../assets/img/newArrow.png";
const Filter = ({ filterMethod, setSearchData, searchData, programming }) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;

  const { t } = useTranslation();
  const { category } = useSelector((state) => state?.cases);
  const { loading } = useSelector((state) => state?.cases);
  const [isSearch, setIsSearch] = useState(false);
  const [caregories, setCategories] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const ref = useRef();
  useEffect(() => {
    setCategories(category);
  }, [category, width]);
  const onImageError = (e) => {
    e.target.src = defaultIcon;
  };
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  return (
    <div className="filter_outer_wrraoer border-radius-top " style={{ boxShadow: programming ? "none" : "1px 0px 8px #80808036" }}>
      {programming ? (
        <div style={{ minHeight: "15px" }} />
      ) : (
        <div className="categories_header row ">
          {loading ? (
            <div style={{ minHeight: "50px" }} />
          ) : (
            <div className="filter_wrapper">
              <div className="new-search-bar" onClick={() => (!isSearch ? setIsSearch(!isSearch) : "")}>
                <img src={newSearch} width={25} onClick={() => setIsSearch(!isSearch)} />
                {isSearch ? (
                  <input autoFocus value={searchData} onChange={(e) => setSearchData(e.target.value)} placeholder={t("Search here")} style={{ margin: "0" }} type="text"></input>
                ) : (
                  <img src={newArrow} width={15} style={{ transform: lng === "arab" ? "ScaleX(-1)" : null }} />
                )}
              </div>

              <Swiper slidesPerView={"auto"} spaceBetween={20} modules={[Navigation]} loop={false} style={{ marginLeft: "15px", marginRight: "15px" }}>
                <SwiperSlide style={{ width: "auto" }}>
                  <Link
                    key={"all"}
                    to="/writenow"
                    style={{
                      width: "85px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className={filterMethod === "all" ? "filter_button active" : "filter_button"}
                  >
                    <svg style={{ direction: "ltr", marginTop: "3px" }} xmlns="http://www.w3.org/2000/svg" width="24" height="29" viewBox="0 0 24 29">
                      <text id="_" data-name="🤩" transform="translate(12 22)" fill="#efb253" font-size="24" fontFamily="AppleColorEmoji, Apple Color Emoji">
                        <tspan x="-11.5" y="0">
                          🤩
                        </tspan>
                      </text>
                    </svg>
                    <span>{t("All")}</span>
                  </Link>
                </SwiperSlide>

                {caregories?.map((item, i) => (
                  <SwiperSlide style={{ width: "auto" }} key={i}>
                    <Link key={i} to={`/writenow/${item?.category}`} className={filterMethod === item?.category ? "filter_button active" : "filter_button"}>
                      <span style={{ fontSize: 18, textAlign: "center" }}>{item?.category_name}</span>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </div>
      )}
      {programming && (
        <>
          {lng === "arab" ? (
            <>
              <div className="row container filter-header-align-right ">
                {programming ? (
                  <label className="page_title text-center filter-label-ar" style={{ margin: "0" }}>
                    {t("Choose The Programming Language")}
                  </label>
                ) : (
                  <label className="page_title text-center filter-label-ar">{t("Choose The Type Of Content You Would Like To Create Now")}</label>
                )}
                <div className="search_input_wrapper col-lg-6 col-md-6 col-sm-12 input_wrapper-ar" style={{ border: "1px solid #80808040" }}>
                  <button
                    type="submit"
                    style={{
                      padding: "0px 5px 0px 5px",
                      border: "unset",
                      backgroundColor: "transparent",
                    }}
                  >
                    <img src={searchIcon} alt="search_icon" />
                  </button>
                  <input
                    value={searchData}
                    onChange={(e) => setSearchData(e.target.value)}
                    placeholder={programming ? t("Which prgramming language?") : t("What do you want to create?")}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="filter-header-align row">
                {programming ? (
                  <label className="page_title text-center filter-label">{t("Choose The Programming Language ")}</label>
                ) : (
                  <label className="page_title text-center filter-label">{t("Choose The Type Of Content ")}</label>
                )}
                <div className="search_input_wrapper col-lg-5 col-md-6 col-sm-11" style={{ border: "1px solid #80808040" }}>
                  <button
                    type="submit"
                    style={{
                      padding: "0px 5px 0px 5px",
                      border: "unset",
                      backgroundColor: "transparent",
                    }}
                  >
                    <img src={searchIcon} alt="search_icon" />
                  </button>
                  <input
                    value={searchData}
                    onChange={(e) => setSearchData(e.target.value)}
                    placeholder={programming ? t("Which prgramming language?") : t("What do you want to create?")}
                    className="form-control"
                    type="text"
                  />
                </div>

                {/* {programming ? (
              <label className="page_title text-center filter-label">
                {t("Choose The Programming Language ")}
              </label>
            ) : (
              <label className="page_title text-center filter-label">
                {t("Choose The Type Of Content You Would Like To Create Now")}
              </label>
            )} */}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Filter;
