

import { USER_SUBSCRIPTION } from "redux/contstants";

export default (userSubscription = {}, action) => {
    switch (action.type) {
        case USER_SUBSCRIPTION:
            return action.subscription;
        default:
            return userSubscription;
    }
};
