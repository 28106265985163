import { baseUrl } from "api/baseUrl";
import checkErr from "redux/actions/api_error_func";
import { toastAlert } from "components/toastAlert/toastAlert";
import { getUser } from "redux/actions/user";
import axios from "axios";

export const createStripeCheckout = async (setIsLoading, id, coupon, selectedNumber, selectedPlan) => {
  setIsLoading(true);
  let response = await fetch(`${baseUrl}stripe/checkout/create`, {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("sess"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      plan_id: id,
      success_url: `${process.env.REACT_APP_WEBSITE_URL}plan` + "?payment=true",
      cancel_url: `${process.env.REACT_APP_WEBSITE_URL}plan` + "?canceled=true",
      quantity: selectedPlan?.name === "Freelancer" ? 1 : selectedNumber,
      coupon_id: coupon,
    }),
  })
    .then((res) => res.json())
    .then(async (data) => {
      if (data.success) {
        setIsLoading(false);
        window.open(data.url, "_self");
      }
    })
    .catch((error) => {
      checkErr(error, undefined, toastAlert);
    });
};

export const changeStripeSubscription = async (setShowModal, subscribe_id, editType, selectedNumber, deletedUsers, setShowTyModal, dispatch, setLoaded, setIsLoading) => {
  setIsLoading(true);
  try {
    const response = await axios.post(
      `${baseUrl}stripe/user/subscription/change`,
      {
        quantity: selectedNumber || 1,
        editType: editType,
        deletedUsers: deletedUsers,
        subscribe_id: subscribe_id,
      },
      {
        headers: {
          Authorization: localStorage.getItem("sess"),
          "Content-Type": "application/json",
        },
      }
    );
    // await new Promise((resolve) => setTimeout(resolve, 2000));
    setLoaded(true);
    setIsLoading(false);
    setShowModal(false);
    localStorage.setItem("planId", subscribe_id);
    updatePlan(setShowTyModal, dispatch);
    dispatch({ type: "FETCH_USER" });
  } catch (error) {
    // console.log(error);
    checkErr(error, undefined, toastAlert); // Make sure "checkErr" and "toastAlert" are defined
  } finally {
    setIsLoading(false);
  }
};

const updatePlan = async (setShowTyModal, dispatch) => {
  try {
    const response = await fetch(`${baseUrl}stripe/user/updateplan`, {
      method: "POST",
      headers: {
        Authorization: localStorage.getItem("sess"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        subscribe_id: localStorage.getItem("planId"),
      }),
    })
      .then((resp) => resp.json())
      .then((res) => {
        setShowTyModal(true);
        dispatch(getUser());
        localStorage.removeItem("planId");
      });
  } catch (error2) {
    // console.log(error2);
    checkErr(error2, undefined, toastAlert);
    localStorage.removeItem("planId");
  }
};
