import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import searchIcon from '../assets/img/newDesign/searchIcon.svg'
import { Spinner } from 'react-bootstrap';
import { Link, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import WriteCategory from "components/writeCategory/WriteCategory";
import Filter from "components/filter/Filter";
import backArow from '../assets/img/newDesign/backArrow.svg'
import { useTranslation } from 'react-i18next'
import "./Business/Business.scss";

const SubCategories = () => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const { name } = useParams();
  const indexRef = useRef();
  const [filterMethod, setFilterMethod] = useState(name || 'all')
  const [loadingSwitch, setLoadingSwitch] = useState(false);
  const { cases, loading, category, tone, languages } = useSelector((state) => state?.cases);
  const [subCategories, setSubCategories] = useState([]);
  const [errorHandling, setErrorHandling] = useState({ status: false, message: '' });
  const [searchData, setSearchData] = useState('');
  const location = useLocation();
  const [indexes, setIndexes] = useState([]);
  useEffect(() => {
    setLoadingSwitch(loading);
  }, [loading])
  useEffect(() => {
    setFilterMethod(name || 'all')
    if (name) {
      let element = category?.filter(item => item?.category === name)?.slice(0)?.shift();
      if (element) {
        setErrorHandling({ status: false, message: '' })
        setIndexes(category);
        let subC = cases?.filter(item => item?.category === name);
        setSubCategories(subC)
      } else {
        setErrorHandling({ status: true, message: 'Please select another case' });
      }
    }
  }, [name, category, cases])
  useEffect(() => {
    if (name) {
      let element = category?.filter(item => item?.category === name)?.slice(0)?.shift();
      if (element) {
        if (searchData) {
          let subC = cases?.filter(item => item?.category === name);
          let arr = subC.filter(item =>
            item?.name?.toUpperCase()?.includes(searchData.toUpperCase()) ||
            item?.arabic_name?.includes(searchData.toUpperCase())
          )
          setSubCategories(arr);
        } else {
          setSubCategories(cases?.filter(item => item?.category === name));
        }
      } else {
        setErrorHandling({ status: true, message: 'Please select another case' });
      }
    }
  }, [cases, searchData, category])
  // console.log(subCategories, "Subcategory")
  return (
    <div className="subCategories_content border-radius-top">
      <Filter
        setSearchData={setSearchData}
        searchData={searchData}
        filterMethod={filterMethod}
      />
      <div className="write_body_height">
        <div style={{ padding: "20px" }} className="write_body">
          {errorHandling.status ? (
            <div className="all_images_wrapper">
              <label>{errorHandling?.message}</label>
            </div>
          ) : (
            <div className="container write_body">
              <div className="busines_mains all_images_wrapper row">
                {loadingSwitch ? (
                  <Spinner animation="border" role="status" />
                ) : subCategories?.length === 0 ? (
                  <label>No sub categories Found</label>
                ) : (
                  // subCategories?.map((item, i) => (
                  // <WriteCategory asSubIndex item={item} index={i}// title={title}
                  //   subTitle={"test"}
                  //   goTo={item?.id}
                  // />
                  <div style={{ width: '100%' }} className="templete_business">
                    <div style={{ margin: '0px', justifyContent: 'flex-start' }} className="busines_mains row">
                      {subCategories.map((item, i) => {
                        return (
                          <div key={i} className="col-lg-4 col-md-6 col-sm-12">
                            <Link to={`/writenow/${item?.category}/${item?.id}`} className="tem_busi">
                              <div className="busines_icon" style={{ backgroundColor: item.background }}>
                                <img src={item.element_icon} alt="image" width="100%" />
                              </div>
                              <div style={{ flexGrow: '1' }} className="template_card">
                                <div className="business_deatils">
                                  {/* <div className={lng === "arab" ? `icons_badge_ar` : `icons_badge`}>
                                    <TurnedInIcon onClick={(e) => handleClick(i)} key={i} style={{ fill: fillColor, stroke: "none" }}/>
                                  </div> */}
                                  <div style={{ flexGrow: '1', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <h6 style={{ textAlign: lng === "arab" ? "right" : "left" }}>
                                      {lng === "arab" ? item.arabic_name : item.name}
                                    </h6>
                                    <p style={{ textAlign: lng === "arab" ? "right" : "left" }}>
                                      {lng === 'arab' ? item?.arabic_description : item.description}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  // ))
                )}
              </div>
            </div>
          )}
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
            <Link to={`/writenow`} style={{ width: "auto", marginTop: "20px", justifyContent: "center", }} className="sub_case_header">
              <label style={{ padding: "8px", borderBottom: "1px solid #80808063", opacity: "0.6", fontSize: "14px", display: "flex", alignItems: "center", justifyContent: "center", }}>
                <img src={backArow} style={{ width: "14px", height: "14px" }} alt="back_button" />
                {t("Back To Categories")}
              </label>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubCategories