import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";
import { MDBBox, MDBPagination, MDBTypography } from "mdbreact";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useState } from "react";
import "./blog.css";
import { t } from "i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogs } from "redux/actions/blog";
import moment from "moment";
import { deleteBlog } from "redux/actions/blog";
import { Modal } from "react-bootstrap";
import close from "../../assets/img/close.png";
import { Spinner } from "react-bootstrap";
import { Fragment } from "react";

let lng = JSON.parse(localStorage.getItem("language"))?.lang;

const rows = [
  {
    title_en: "AI advantages for businesses",
    title_ar: "مدى فوائد الذكاء الاصطناعي للشركات",
    created_at: "10/05/2023,4:36:57 pm",
  },
  {
    title_en: "10 Myths About AI Image Generation Debunked",
    title_ar: "10 أساطير حول إنتاج الصور بالذكاء الاصطناعي تم تفنيدها",
    created_at: "10/05/2023,4:36:57 pm",
  },
  {
    title_en: "How AI is revolutionizing the Arabic language industry",
    title_ar: "طريقة ثورية للذكاء الاصطناعي في صناعة اللغة العربية",
    created_at: "10/05/2023,4:36:57 pm",
  },
  {
    title_en: "AI advantages for businesses",
    title_ar: "مدى فوائد الذكاء الاصطناعي للشركات",
    created_at: "10/05/2023,4:36:57 pm",
  },
  {
    title_en: "10 Myths About AI Image Generation Debunked",
    title_ar: "10 أساطير حول إنتاج الصور بالذكاء الاصطناعي تم تفنيدها",
    created_at: "10/05/2023,4:36:57 pm",
  },
  {
    title_en: "How AI is revolutionizing the Arabic language industry",
    title_ar: "طريقة ثورية للذكاء الاصطناعي في صناعة اللغة العربية",
    created_at: "10/05/2023,4:36:57 pm",
  },
];

const headCells = [
  {
    id: "1",
    label: "Title En",
  },
  {
    id: "2",
    label: "Title Ar",
  },
  {
    id: "3",
    label: "Date",
  },
  {
    id: "4",
    label: "Actions",
  },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={lng === "arab" ? "right" : "left"}
          >
            {t(headCell.label)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar() {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <h3 className="title">{t("Blog")}</h3>
      <Link to={"/blog/add-blog"} className="navbar_button3">
        {t("Add Blog")}
      </Link>
    </Toolbar>
  );
}

export default function Blog() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const blogs = useSelector((state) => state.blogs.blogs);
  const blogLoading = useSelector((state) => state.blogs.loading);
  const total = useSelector((state) => state.blogs.totalDocs);

  const [count, setCount] = useState(1);
  const [forceLoad, setForceLoad] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState(null);

  const handleNext = () => {
    setCount(count + 1);
  };
  const handlePrev = () => {
    if (count >= 2) {
      setCount(count - 1);
    } else setCount(1);
  };
  useEffect(() => {
    setLoading(true);
    dispatch(getAllBlogs(count, 10, setLoading));
  }, [count, forceLoad]);

  const handleDeleteBlog = () => {
    setLoading(true);
    dispatch(deleteBlog(id, setLoading, setForceLoad, forceLoad));
    setShowModal(false);
  };

  return (
    <div className="p-5 border-radius-top" style={{ backgroundColor: "#fafdff" }}>
      <Box sx={{ width: "100%" }}>
        <Paper
          sx={{
            width: "100%",
            mb: 2,
            boxShadow: "1px 0px 8px rgba(128, 128, 128, 0.203)",
            borderRadius: "15px",
          }}
        >
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", minHeight: "500px" }}>
              <Spinner animation="border" role="status" />
            </div>
          ) : (
            <Fragment>
              <EnhancedTableToolbar />
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                >
                  <EnhancedTableHead />
                  <TableBody>
                    {blogs.map((row, index) => {
                      return (
                        <TableRow
                          key={index}
                          hover
                          sx={{ cursor: "pointer" }}
                          className="test-table"
                        >
                          <TableCell
                            component="th"
                            align={lng === "arab" ? "right" : "left"}
                          >
                            <label
                              className="table-body-label"
                              style={{
                                display: "inline-block",
                                maxWidth: "250px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {row.title_en}
                            </label>
                          </TableCell>
                          <TableCell align={lng === "arab" ? "right" : "left"}>
                            <label
                              className="table-body-label"
                              style={{
                                display: "inline-block",
                                maxWidth: "250px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {row.title_ar}
                            </label>
                          </TableCell>
                          <TableCell align={lng === "arab" ? "right" : "left"}>
                            <label className="table-body-label">
                              {moment(row?.createdAt).format(
                                "DD/MM/YYYY,h:mm:ss a"
                              )}
                            </label>
                          </TableCell>
                          <TableCell align={lng === "arab" ? "right" : "left"}>
                            <div className="d-flex justify-content-between align-items-center">
                              <DeleteIcon
                                style={{ color: "red" }}
                                titleAccess={t("Delete Blog")}
                                onClick={() => {
                                  setId(row?._id);
                                  setShowModal(true);
                                }}
                              />
                              <EditIcon
                                titleAccess={t("Edit Blog")}
                                onClick={() =>
                                  navigate(`/blog/update-blog/${row?._id}`)
                                }
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>

                <MDBBox
                  mt={2}
                  p={3}
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                >
                  <MDBPagination variant={"gradient"} color={"info"}>
                    <MDBPagination item onClick={handlePrev}>
                      {lng === "arab" ? (
                        <ChevronRightIcon
                          sx={{
                            fontWeight: "bold",
                            border: "1px solid black",
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        <ChevronLeftIcon
                          sx={{
                            fontWeight: "bold",
                            border: "1px solid black",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                    </MDBPagination>

                    <MDBBox mb={{ xs: 3, sm: 0 }} mx={3}>
                      <MDBTypography
                        variant="button"
                        color="secondary"
                        fontWeight="regular"
                      >
                        {lng === "arab"
                          ? `عرض ${count} إلى 10 من ${total}`
                          : `Showing ${count} to 10 of ${total}`}
                      </MDBTypography>
                    </MDBBox>

                    <MDBPagination item onClick={handleNext}>
                      {lng === "arab" ? (
                        <ChevronLeftIcon
                          sx={{
                            fontWeight: "bold",
                            border: "1px solid black",
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        <ChevronRightIcon
                          sx={{
                            fontWeight: "bold",
                            border: "1px solid black",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                    </MDBPagination>
                  </MDBPagination>
                </MDBBox>
              </TableContainer>
            </Fragment>
          )}
        </Paper>
        <Modal
          style={{ backgroundColor: "#000000a8" }}
          show={showModal}
          onHide={() => setShowModal(false)}
          size="sm"
          backdrop={false}
          centered
        >
          <Modal.Header
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>{t("Are You Sure?")}</div>
            <img
              src={close}
              width={20}
              onClick={() => setShowModal(false)}
              style={{ cursor: "pointer" }}
            />
          </Modal.Header>
          <Modal.Body
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <button
              onClick={() => setShowModal(false)}
              className="navbar_button3"
            >
              {t("No")}
            </button>
            <button
              onClick={() => handleDeleteBlog()}
              className="navbar_button3"
            >
              {t("Yes")}
            </button>
          </Modal.Body>
        </Modal>
      </Box>
    </div>
  );
}
