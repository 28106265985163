import { FETCH_ENHANCE_VIDEO, LOAD_ENHANCE_VIDEO, ENHANCE_VIDEO_LOADED, NEW_ENHANCE_VIDEO, SHOW_ENHANCE_VIDEO_FALSE, SHOW_ENHANCE_VIDEO_TRUE } from "redux/contstants";

export default (enhanceVideo = { enhanceVideo: [], uploaded: [], loading: false, showRemover: false }, action) => {
  switch (action.type) {
    case LOAD_ENHANCE_VIDEO:
      return { ...enhanceVideo, loading: true, loadingNumber: 0, showRemover: true };
    case FETCH_ENHANCE_VIDEO:
      return { ...enhanceVideo, enhanceVideo: action.data };
    case ENHANCE_VIDEO_LOADED:
      return { ...enhanceVideo, loading: false };
    case NEW_ENHANCE_VIDEO:
      return { ...enhanceVideo, enhanceVideo: [], uploaded: [], showRemover: false };
    case SHOW_ENHANCE_VIDEO_FALSE:
      return { ...enhanceVideo, showRemover: false };
    case SHOW_ENHANCE_VIDEO_TRUE:
      return { ...enhanceVideo, showRemover: true };
    default:
      return enhanceVideo;
  }
};
