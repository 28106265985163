import React, { useState } from "react";
import { ReactComponent as Save } from "assets/img/newDesign/saveDoc.svg";
import { ReactComponent as Download } from "assets/img/newDesign/exportPdf.svg";
import { ReactComponent as BackArrow } from "assets/img/Menu.svg";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import downloadPdf from "../Functions/downloadPdf";
import save from "../Functions/save";
import { toastAlert } from "components/toastAlert/toastAlert";

const Header = ({ quillRef, docName, setIsEdit, setDocName, isEdit, refId }) => {
  const [typingTimeout, setTypingTimeout] = useState(null);

  const handleKeyDown = () => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    const newTypingTimeout = setTimeout(() => {
      save(quillRef, params, docName, refId);
    }, 1000);
    setTypingTimeout(newTypingTimeout);
  };
  const { t } = useTranslation();
  const lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.sidebar);
  const params = useParams();

  return (
    <div className="categories_header " style={{ boxShadow: " 0px 4px 20px 0px rgba(0, 0, 0, 0.06)" }}>
      <div className="sub_case_header">
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            dispatch({
              type: isOpen?.sidebarIsOpen ? "OPEN_SIDEBAR" : "CLOSE_SIDEBAR",
            })
          }
        >
          <BackArrow />
        </div>
        <Box
          sx={{
            position: "absolute",
            right: lng === "arab" ? "" : "10px",
            left: lng === "arab" ? "10px" : "",
            gap: "10px",
            display: "flex",
          }}
        >
          <Tooltip title={t("Download as PDF")} placement="bottom" arrow>
            <IconButton onClick={() => downloadPdf(quillRef, docName, lng)} sx={{ borderRadius: "50%", border: "1px solid #EAEAEA" }}>
              <Download style={{ width: "20px", height: "20px" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("Save")} placement="bottom" arrow>
            <IconButton
              onClick={() => {
                if (quillRef.current.getEditor().root.innerHTML == "<p><br></p>" || quillRef.current.getEditor().getText().length === 0) {
                  toastAlert("Nothing to save");
                  return;
                }
                save(quillRef, params, docName, refId);
                toastAlert(t("Document saved successfully"), "success");
              }}
              sx={{ borderRadius: "50%", border: "1px solid #EAEAEA" }}
            >
              <Save style={{ width: "20px", height: "20px" }} />
            </IconButton>
          </Tooltip>
        </Box>
        <div>
          {isEdit ? (
            <input
              className="document_name"
              style={{
                border: "none",
                width: "100%",
                borderRadius: "2px",
                marginBottom: "0px",
              }}
              type="text"
              autoFocus
              value={docName}
              onChange={(e) => setDocName(e.target.value)}
              onBlur={(e) => {
                if (docName != "" && docName != null) {
                  setIsEdit(false);
                }
              }}
              onKeyDown={(e) => {
                handleKeyDown();
                if (e.key === "Enter" && docName != "" && docName != null) {
                  setIsEdit(false);
                }
              }}
            />
          ) : (
            <Tooltip title={t("Rename")} placement="bottom" arrow>
              <div onClick={() => setIsEdit(true)} className="document_name_static" style={{ textAlign: lng === "arab" ? "right" : null }}>
                {docName}
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
