import { baseUrl } from "api/baseUrl";
import axios from "axios";

export const createHyperPayCheckout = async (selectedPlan, isYearly, price, setCheckoutId, selectedNumber, isBusiness) => {
  try {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}payment/hyperpay/checkout/create`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        planId: selectedPlan?.product?._id,
        interval: isYearly ? "year" : "month",
        amount: price,
        quantity: isBusiness ? selectedNumber : 1,
      },
    };

    const response = await axios(config);
    setCheckoutId(response.data?.id);
    // setCountyLoading(false);
  } catch (error) {
    // console.log(error);
    // setCountyLoading(false);
  }
};

export const HyperPayChangeSubscription = async (selectedPlan, editType, selectedNumber, price, deletedUser, isYearly) => {
  {
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}payment/hyperpay/subscription/change`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        planId: selectedPlan?.product?._id,
        interval: isYearly ? "year" : "month",
        amount: price,
        quantity: selectedNumber ? selectedNumber : 1,
        editType: editType,
        deletedUsers: deletedUser,
      },
    };

    axios(config)
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
};
