import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { useNavigate } from "react-router-dom";
import "./MediaSection.scss";
import "swiper/css/navigation";
import "swiper/css";

const ImagesFilterComponent = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const navigate = useNavigate();
  const { name } = useParams();
  const [media, setMedia] = useState(null);

  useEffect(() => {
    if (location?.pathname.split("/")?.length === 3 && location.pathname.includes("images")) {
      setMedia("images");
    } else if (location?.pathname.split("/")?.length === 3 && location.pathname.includes("audios")) {
      setMedia("audios");
    } else if (location?.pathname.split("/")?.length === 3 && location.pathname.includes("videos")) {
      setMedia("videos");
    }
  }, [location.pathname]);

  let dynamicValue = [
    { param: "create", heading: "Create Your Images" },
    { param: "enhance", heading: "Enhance Images" },
    { param: "upscale", heading: "Upscale Images" },
    { param: "head", heading: "Headshots" },
    { param: "resize", heading: "Re-Design" },
    { param: "scribble", heading: "Scribble" },
    { param: "remover", heading: "Remove Background" },
    { param: "painting", heading: "Replace Background" },
    { param: "audio", heading: "Text to Music" },
    { param: "text-speech", heading: "Text to Speech" },
    { param: "split-audio", heading: "Split Audio" },
    { param: "video_background", heading: "Remove Video Background" },
    { param: "enhance-audio", heading: "Enhance Audio" },
    { param: "text-video", heading: "Text to Video" },
    { param: "infinite", heading: "Infinite Zoom" },
    { param: "before-after", heading: "TimeLapse" },
    { param: "video_enhance", heading: "Enhance Videos" },
    { param: "image_design", heading: "Change Style" },
  ];

  return (
    <div className={`categories_header row ${location?.pathname.split("/")?.length <= 3 && "border-radius-top"} `} style={{ backgroundColor: "white" }}>
      <div className="filter_wrapper">
        {media === "images" ? (
          <Swiper slidesPerView={"auto"} spaceBetween={20} loop={false} style={{ borderRadius: "13px", direction: lng === "arab" ? "rtl" : "ltr" }}>
            <SwiperSlide style={{ width: "auto" }}>
              <Link
                key={"all"}
                to="/photogen"
                style={{ width: "85px", display: "flex", alignItems: "center", justifyContent: "center" }}
                className={name === undefined ? "filter_button active" : "filter_button"}
              >
                <svg style={{ direction: "ltr", marginTop: "3px" }} xmlns="http://www.w3.org/2000/svg" width="24" height="29" viewBox="0 0 24 29">
                  <text id="_" data-name="🤩" transform="translate(12 22)" fill="#efb253" font-size="24" fontFamily="AppleColorEmoji, Apple Color Emoji">
                    <tspan x="-11.5" y="0">
                      🤩
                    </tspan>
                  </text>
                </svg>
                <span>{t("All")}</span>
              </Link>
            </SwiperSlide>
            <SwiperSlide style={{ width: "auto" }}>
              <Link style={{ width: "auto" }} to={`/photogen/images/create`} className={name === "create" ? "filter_button active" : "filter_button"}>
                <span style={{ fontSize: 18, textAlign: "center" }}>{t("Create")}</span>
              </Link>
            </SwiperSlide>
            <SwiperSlide style={{ width: "auto" }}>
              <Link style={{ width: "auto" }} to={`/photogen/images/enhance`} className={name === "enhance" ? "filter_button active" : "filter_button"}>
                <span style={{ fontSize: 18, textAlign: "center" }}>{t("Enhance")}</span>
              </Link>
            </SwiperSlide>
            <SwiperSlide style={{ width: "auto" }}>
              <Link style={{ width: "auto" }} to={`/photogen/images/head`} className={name === "head" ? "filter_button active" : "filter_button"}>
                <span style={{ fontSize: 18, textAlign: "center" }}>{t("Headshot")}</span>
              </Link>
            </SwiperSlide>
            <SwiperSlide style={{ width: "auto" }}>
              <Link style={{ width: "auto" }} to={`/photogen/images/resize`} className={name === "resize" ? "filter_button active" : "filter_button"}>
                <span style={{ fontSize: 18, textAlign: "center" }}>{t("Re-Design")}</span>
              </Link>
            </SwiperSlide>
            {/* <SwiperSlide style={{ width: "auto" }}>
              <Link style={{ width: "auto" }} to={`/photogen/images/image_design`} className={name === "image_design" ? "filter_button active" : "filter_button"}>
                <span style={{ fontSize: 18, textAlign: "center" }}>{t("Change Style")}</span>
              </Link>
            </SwiperSlide> */}
            <SwiperSlide style={{ width: "auto" }}>
              <Link style={{ width: "auto" }} to={`/photogen/images/scribble`} className={name === "scribble" ? "filter_button active" : "filter_button"}>
                <span style={{ fontSize: 18, textAlign: "center" }}>{t("Scribble")}</span>
              </Link>
            </SwiperSlide>
            <SwiperSlide style={{ width: "auto" }}>
              <Link style={{ width: "auto" }} to={`/photogen/images/remover`} className={name === "remover" ? "filter_button active" : "filter_button"}>
                <span style={{ fontSize: 18, textAlign: "center" }}>{t("Background Remover")}</span>
              </Link>
            </SwiperSlide>
            <SwiperSlide style={{ width: "auto" }}>
              <Link style={{ width: "auto" }} to={`/photogen/images/painting`} className={name === "painting" ? "filter_button active" : "filter_button"}>
                <span style={{ fontSize: 18, textAlign: "center" }}>{t("Replace Background")}</span>
              </Link>
            </SwiperSlide>
            {/* <SwiperSlide style={{ width: "auto" }}>
              <Link style={{ width: "auto" }} to={`/photogen/images/resizev2`} className={name === "resizev2" ? "filter_button active" : "filter_button"}>
                <span style={{ fontSize: 18, textAlign: "center" }}>{t("ReimagineV2")}</span>
              </Link>
            </SwiperSlide> */}
            {/* <SwiperSlide style={{ width: "auto" }}>
              <Link style={{ width: "auto" }} to={`/photogen/images/upscaleV1`} className={name === "upscaleV1" ? "filter_button active" : "filter_button"}>
                <span style={{ fontSize: 18, textAlign: "center" }}>{t("UpscaleV1")}</span>
              </Link>
            </SwiperSlide> */}
            <SwiperSlide style={{ width: "auto" }}>
              <Link style={{ width: "auto" }} to={`/photogen/images/upscale`} className={name === "upscale" ? "filter_button active" : "filter_button"}>
                <span style={{ fontSize: 18, textAlign: "center" }}>{t("Upscale")}</span>
              </Link>
            </SwiperSlide>
          </Swiper>
        ) : media === "audios" ? (
          <>
            <Link
              key={"all"}
              to="/photogen"
              style={{ width: "85px", display: "flex", alignItems: "center", justifyContent: "center" }}
              className={name === undefined ? "filter_button active" : "filter_button"}
            >
              <svg style={{ direction: "ltr", marginTop: "3px" }} xmlns="http://www.w3.org/2000/svg" width="24" height="29" viewBox="0 0 24 29">
                <text id="_" data-name="🤩" transform="translate(12 22)" fill="#efb253" font-size="24" fontFamily="AppleColorEmoji, Apple Color Emoji">
                  <tspan x="-11.5" y="0">
                    🤩
                  </tspan>
                </text>
              </svg>
              <span>{t("All")}</span>
            </Link>
            <Link style={{ width: "auto" }} to={`/photogen/audios/text-speech`} className={name === "text-speech" ? "filter_button active" : "filter_button"}>
              <span style={{ fontSize: 18, textAlign: "center" }}>{t("Text to Speech")}</span>
            </Link>
            <Link style={{ width: "auto" }} to={`/photogen/audios/enhance-audio`} className={name === "enhance-audio" ? "filter_button active" : "filter_button"}>
              <span style={{ fontSize: 18, textAlign: "center" }}>{t("Enhance Audio")}</span>
            </Link>
            <Link style={{ width: "auto" }} to={`/photogen/audios/audio`} className={name === "audio" ? "filter_button active" : "filter_button"}>
              <span style={{ fontSize: 18, textAlign: "center" }}>{t("Text to Music")}</span>
            </Link>
            <Link style={{ width: "auto" }} to={`/photogen/audios/split-audio`} className={name === "split-audio" ? "filter_button active" : "filter_button"}>
              <span style={{ fontSize: 18, textAlign: "center" }}>{t("Split Audio")}</span>
            </Link>
          </>
        ) : media === "videos" ? (
          <>
            <Link
              key={"all"}
              to="/photogen"
              style={{ width: "85px", display: "flex", alignItems: "center", justifyContent: "center" }}
              className={name === undefined ? "filter_button active" : "filter_button"}
            >
              <svg style={{ direction: "ltr", marginTop: "3px" }} xmlns="http://www.w3.org/2000/svg" width="24" height="29" viewBox="0 0 24 29">
                <text id="_" data-name="🤩" transform="translate(12 22)" fill="#efb253" font-size="24" fontFamily="AppleColorEmoji, Apple Color Emoji">
                  <tspan x="-11.5" y="0">
                    🤩
                  </tspan>
                </text>
              </svg>
              <span>{t("All")}</span>
            </Link>
            <Link style={{ width: "auto" }} to={`/photogen/videos/video_background`} className={name === "video_background" ? "filter_button active" : "filter_button"}>
              <span style={{ fontSize: 18, textAlign: "center" }}>{t("Remove Video Background")}</span>
            </Link>
            <Link style={{ width: "auto" }} to={`/photogen/videos/text-video`} className={name === "text-video" ? "filter_button active" : "filter_button"}>
              <span style={{ fontSize: 18, textAlign: "center" }}>{t("Text to Video")}</span>
            </Link>
            <Link style={{ width: "auto" }} to={`/photogen/videos/infinite`} className={name === "infinite" ? "filter_button active" : "filter_button"}>
              <span style={{ fontSize: 18, textAlign: "center" }}>{t("Infinite Zoom")}</span>
            </Link>
            {/* <Link style={{ width: "auto" }} to={`/photogen/videos/before-after`} className={name === "before-after" ? "filter_button active" : "filter_button"}>
              <span style={{ fontSize: 18, textAlign: "center" }}>{t("Before And After")}</span>
            </Link> */}

            {/* <Link style={{ width: "auto" }} to={`/photogen/videos/video_enhance`} className={name === "video_enhance" ? "filter_button active" : "filter_button"}>
              <span style={{ fontSize: 18, textAlign: "center" }}>{t("Enhance Videos")}</span>
            </Link> */}
          </>
        ) : location?.pathname.split("/")?.length <= 3 ? (
          <>
            <Link
              key={"all"}
              to="/photogen"
              style={{ width: "85px", display: "flex", alignItems: "center", justifyContent: "center" }}
              className={name === undefined ? "filter_button active" : "filter_button"}
            >
              <svg style={{ direction: "ltr", marginTop: "3px" }} xmlns="http://www.w3.org/2000/svg" width="24" height="29" viewBox="0 0 24 29">
                <text id="_" data-name="🤩" transform="translate(12 22)" fill="#efb253" font-size="24" fontFamily="AppleColorEmoji, Apple Color Emoji">
                  <tspan x="-11.5" y="0">
                    🤩
                  </tspan>
                </text>
              </svg>
              <span>{t("All")}</span>
            </Link>
            <Link style={{ width: "auto" }} to={`/photogen/images`} className={name === "images" ? "filter_button active" : "filter_button"}>
              <span style={{ fontSize: 18, textAlign: "center" }}>{t("Image Tools")}</span>
            </Link>
            <Link style={{ width: "auto" }} to={`/photogen/audios`} className={name === "audios" ? "filter_button active" : "filter_button"}>
              <span style={{ fontSize: 18, textAlign: "center" }}>{t("Audio Tools")}</span>
            </Link>
            <Link style={{ width: "auto" }} to={`/photogen/videos`} className={name === "videos" ? "filter_button active" : "filter_button"}>
              <span style={{ fontSize: 18, textAlign: "center" }}>{t("Video Tools")}</span>
            </Link>
          </>
        ) : (
          <div className="media_container">
            <button className="media_back" style={{ left: lng === "arab" ? null : "0px", right: lng === "arab" ? "0px" : null }} onClick={() => navigate(-1)}>
              <ArrowBackIosRoundedIcon sx={{ color: "#A3A3A3", transform: lng === "arab" ? "rotate(180deg)" : null, fontSize: "18px" }} /> {t("Back")}
            </button>
            <h4 className="media_heading">
              {dynamicValue.map((item, i) => {
                if (item.param === name) {
                  return t(item.heading);
                }
              })}
            </h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImagesFilterComponent;

//{name === dynamicValue.name ? dynamicValue.heading : null}
