import React, { useState, useEffect } from "react";
import { toastAlert } from "components/toastAlert/toastAlert";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import UpgradeModal from "components/UpgradeModal/UpgradeModal";
import "views/MediaSection/ImageTools/Create/ImgGen.css";
import LoadingEffect from "components/Loading/LoadingEffect";
import UpgradePlanModal from "components/UpgradeModal/UpgradePlanModal";
import { handleTextToSpeech } from "redux/actions/media_section";
import LimitPopUp from "views/plansComponent/LimitPopUp";
import ReplayIcon from "@mui/icons-material/Replay";

const TextToSpeech = () => {
  let voices = [
    {
      name: "Rachel",
      id: "21m00Tcm4TlvDq8ikWAM",
    },
    {
      name: "Clyde",
      id: "2EiwWnXFnvU5JabPnv8n",
    },
    {
      name: "Domi",
      id: "AZnzlk1XvdvUeBnXmlld",
    },
    {
      name: "Dave",
      id: "CYw3kZ02Hs0563khs1Fj",
    },
    {
      name: "Fin",
      id: "D38z5RcWu1voky8WS1ja",
    },
    {
      name: "Bella",
      id: "EXAVITQu4vr4xnSDxMaL",
    },
    {
      name: "Antoni",
      id: "ErXwobaYiN019PkySvjV",
    },
    {
      name: "Thomas",
      id: "GBv7mTt0atIp3Br8iCZE",
    },
    {
      name: "Charlie",
      id: "IKne3meq5aSn9XLyUdCD",
    },
    {
      name: "Emily",
      id: "LcfcDJNUP1GQjkzn1xUU",
    },
    {
      name: "Elli",
      id: "MF3mGyEYCl7XYWbV9V6O",
    },
    {
      name: "Callum",
      id: "N2lVS1w4EtoT3dr4eOWO",
    },
    {
      name: "Patrick",
      id: "ODq5zmih8GrVes37Dizd",
    },
    {
      name: "Harry",
      id: "SOYHLrjzK2X1ezoPC6cr",
    },
    {
      name: "Liam",
      id: "TX3LPaxmHKxFdv7VOQHJ",
    },
    {
      name: "Dorothy",
      id: "ThT5KcBeYPX3keUQqHPh",
    },
    {
      name: "Josh",
      id: "TxGEqnHWrfWFTfGW9XjX",
    },
    {
      name: "Arnold",
      id: "VR6AewLTigWG4xSOukaG",
    },
    {
      name: "Charlotte",
      id: "XB0fDUnXU5powFXDhCwa",
    },
    {
      name: "Matilda",
      id: "XrExE9yKIg1WjnnlVkGX",
    },
  ];

  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [totalImages, setTotalImages] = useState(0);
  const [input, setInput] = useState("");
  const textToSpeech = useSelector((state) => state.text_to_speech);
  const [width, setWidth] = useState(window.innerWidth);
  const [voiceId, setVoiceId] = useState("21m00Tcm4TlvDq8ikWAM");

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  useEffect(() => {
    setTotalImages(user?.image_count_left);
  }, [user]);

  const handleSpeech = async (e) => {
    e.preventDefault();
    if (totalImages === 0) {
      dispatch({ type: "SHOW_TEXT_SPEECH_FALSE" });
      setShowModal(true);
      return;
    }
    if (input === "") {
      return toastAlert(t("Input cannot be empty"), undefined, "warning");
    } else {
      let data = {
        prompt: input,
        voice_id: voiceId,
      };
      dispatch(handleTextToSpeech(data));
    }
  };

  const handleDownload = () => {
    const fileUrl = textToSpeech?.textToSpeech?.data?.urls;
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = "hello"; // Specify the file name here
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="image_gen_header" style={{ padding: "40px 15px 0" }}>
      <h4>{t("Text to Speech")}</h4>

      {textToSpeech?.textToSpeech?.length === 0 && (
        <form onSubmit={handleSpeech}>
          <div className="inpainting_input textarea_text_to_speech">
            <textarea rows={5} type="textarea" value={input} disabled={textToSpeech?.loading} onChange={(e) => setInput(e.target.value)} placeholder={t("Describe Your Speech")} />
          </div>
          <button
            onClick={() => {}}
            style={{
              padding: "15px 40px !important",
              background: textToSpeech?.loading ? "gray" : "linear-gradient(45deg, rgba(17, 113, 216, 1) 0%, rgba(60, 200, 235, 1) 100%)",
              width: "170px",
              margin: "0 auto",
              minHeight: "46px",
              borderRadius: "15px",
              alignSelf: "center",
              pointerEvents: textToSpeech?.loading && "none",
            }}
            className="form_buttions"
            type="submit"
          >
            {textToSpeech?.loading ? <LoadingEffect /> : t("Create")}
          </button>
          <div className="output_number" style={{ gap: "15px" }}>
            {voices?.map((item, i) => {
              return (
                <button
                  key={i}
                  style={{
                    background: "#808080",
                    color: "black",
                    padding: "10px 10px",
                    background: "none",
                    outline: voiceId === item.id ? "2px solid #000000" : null,
                  }}
                  type="button"
                  onClick={() => setVoiceId(item.id)}
                >
                  {item.name}
                </button>
              );
            })}
          </div>
        </form>
      )}

      {textToSpeech?.showRemover ? (
        <>
          <div style={{ position: "relative" }}>
            {textToSpeech?.textToSpeech?.data?.urls?.length === 1 && (
              <>
                <div
                  style={{
                    margin: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <Waveform url={textToSpeech?.textToSpeech?.data?.urls} /> */}

                  <audio src={textToSpeech?.textToSpeech?.data?.urls} controls controlsList="nodownload noplaybackrate" />
                </div>
              </>
            )}
            {textToSpeech?.loading ||
              (textToSpeech?.textToSpeech?.data?.urls?.length != 0 && (
                <div className="beforeAfter-container">
                  {textToSpeech?.textToSpeech?.data?.urls?.length != 0 && (
                    <div className="beforeafter-gray">
                      <button
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                          fontSize: "medium",
                        }}
                        className="download-btn"
                        onClick={handleDownload}
                      >
                        {t("Download")}
                        <i
                          className="fas fa-arrow-down"
                          style={{
                            fontSize: "medium",
                            margin: lng === "arab" ? " 0 5px 0 0" : "0 0 0 5px",
                          }}
                        ></i>
                      </button>
                      <button
                        className="newupload"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                          fontSize: "medium",
                        }}
                        onClick={() => {
                          setInput("");
                          dispatch({ type: "NEW_TEXT_SPEECH" });
                        }}
                      >
                        {t("Try Again")}
                        <ReplayIcon
                          sx={{
                            transform: "scale(-1)",
                            margin: "0 10px",
                            fontSize: "20px",
                          }}
                        />
                      </button>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </>
      ) : null}
      {user?.plan === "Trial" ? <UpgradeModal images={true} setShowModal={setShowModal} showModal={showModal} /> : <LimitPopUp setShowModal={setShowModal} showModal={showModal} />}
      <UpgradePlanModal show={show} setShow={setShow} width={width} />
    </div>
  );
};

export default TextToSpeech;
