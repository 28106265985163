import { FETCH_INFINITE_ZOOM, LOAD_INFINITE_ZOOM, LOADED_INFINITE_ZOOM, NEW_INFINITE_ZOOM } from "redux/contstants";

export default (infinite_zoom = { infinite_zoom: [], loading: false, showRemover: false }, action) => {
  switch (action.type) {
    case LOAD_INFINITE_ZOOM:
      return { ...infinite_zoom, loading: true, showRemover: true };
    case FETCH_INFINITE_ZOOM:
      return { ...infinite_zoom, infinite_zoom: action.data };
    case LOADED_INFINITE_ZOOM:
      return { ...infinite_zoom, loading: false };
    case NEW_INFINITE_ZOOM:
      return { ...infinite_zoom, infinite_zoom: [], showRemover: false };
    default:
      return infinite_zoom;
  }
};
