import styles from "../../views/plansComponent/Checkout/checkout.module.scss";
import { useTranslation } from "react-i18next";
import { ReactComponent as Bubble1 } from "assets/img/upgrade_plan/Depressed.svg";
import image from "assets/img/upgrade_plan/Group 48095563 (1).png";

import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { baseUrl } from "api/baseUrl";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

//<------------------------CSS START------------------------->

const freeTrialPopUpContainer = {
  display: "flex",
  alignItems: "center",
  justifyContent: "spaceAround",
  flexDirection: "column",
  overflow: "hidden",
};

const rectangle_data1 = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  marginRight: "0px",
  color: "black",
  fontWeight: "600",
  width: "100%",
  position: "relative",
  borderRadius: "15px",
  marginBottom: "50px",
  overflow: "hidden",
};

const freeTrialButton = {
  borderRadius: "12px",
  background: "linear-gradient(232deg, #0085FF 0%, #00C2FF 100%)",
  border: "none",
  fontWeight: "700",
  color: "#FFF",
  padding: "10px 30px",
  marginTop: "20px",
  fontSize: "20px",
};

//<------------------------CSS END -------------------------->

const LimitPopUp = (props) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  const navigate = useNavigate();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();

  useEffect(() => {
    lastSubscription();
  }, [props.showModal]);

  const lastSubscription = async () => {
    try {
      const response = await axios.get(`${baseUrl}subscription/get/user`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      centered
      show={props.showModal}
      onHide={() => props.setShowModal(false)}
    >
      <span
        className="close_button"
        onClick={() => props.setShowModal(false)}
        style={{
          left: lng === "arab" ? "15px" : null,
          right: lng === "arab" ? null : "15px",
        }}
      >
        <CloseRoundedIcon sx={{ fontSize: "20px" }} />
      </span>
      <div
        style={{
          fontFamily: lng === "arab" ? "Tajawal" : "Poppins",
        }}
      >
        <div style={freeTrialPopUpContainer}>
          <div className={styles.head} style={rectangle_data1}>
            <img
              src={image}
              height={width < 500 ? 200 : 300}
              style={{ width: "100%", objectFit: "cover" }}
            />
            <h4
              style={{
                fontFamily: lng === "arab" ? "Tajawal" : "Poppins",
                fontWeight: "700",
                color: "black",
                textAlign: "center",
                fontSize: width < 500 ? "20px" : "30px",
                padding: "0px 18px",
                marginTop: "0px",
              }}
            >
              {t("Oh No!")}
              <br />
              {t("You have reached your limit")}
            </h4>
            <p
              style={{
                textAlign: "center",
                margin: "auto",
                width: "100%",
                fontSize: width < 500 ? "14px" : "18px",
                padding: width < 500 ? "0px" : "20px",
              }}
            >
              {t("Please upgrade your plan to continue access Twwt.Ai")}
            </p>

            <button style={freeTrialButton} onClick={() => navigate("/plan")}>
              {t("Upgrade Now")}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LimitPopUp;
