import React, { useState, useEffect } from "react";
import { toastAlert } from "components/toastAlert/toastAlert";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import UpgradeModal from "components/UpgradeModal/UpgradeModal";
import UpgradePlanModal from "components/UpgradeModal/UpgradePlanModal";
import { handleUpscale } from "redux/actions/media_section";
import upscaleImage from "assets/img/newDesign/upscale.png";
import Loading_v2 from "components/Loading/Laoadingv2/Loadingv2";
import ReplayIcon from "@mui/icons-material/Replay";
import { handleUpscaleLoading } from "redux/actions/media_section";
import FileContainer from "components/MediaSection/FileContainer";

import { handleLoadingUpscale } from "redux/actions/media_section";
import LimitPopUp from "views/plansComponent/LimitPopUp";
const Upscale = () => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [base64, setBase64] = useState("");
  const [totalImages, setTotalImages] = useState(0);
  const user = useSelector((state) => state.user);
  const upscale = useSelector((state) => state.upscale);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [loadingText, setLoadingText] = useState("Uploading your Image");

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  let error_msg =
    lng === "arab"
      ? "حجم الصورة لا يجب أن يزيد عن 5 ميجابايت"
      : "Image size should be less than 5 MB";
  let error_valid =
    lng === "arab"
      ? "يُسمح فقط بملفات JPG و JPEG و PNG"
      : "Only JPG, JPEG, and PNG files are allowed.";

  useEffect(() => {
    setTotalImages(user?.image_count_left);
    if (user?.plan === "Trial" || user?.plan === "Starter") {
      return setShow(true);
    }
  }, [user]);

  const handleUpscaleChange = async () => {
    if (totalImages === 0) {
      dispatch({ type: "SHOW_UPSCALE_FALSE" });
      return setShowModal(true);
    } else {
      let data = {
        image: base64,
        scale: 2,
      };
      dispatch(handleUpscale(data));
    }
  };

  useEffect(() => {
    if (base64) {
      handleUpscaleChange();
    }
  }, [base64]);

  const handleDrop = async (e) => {
    e.preventDefault();
    if (totalImages === 0) {
      dispatch({ type: "SHOW_UPSCALE_FALSE" });
      return setShowModal(true);
    } else {
      const file = await e.dataTransfer.files[0];
      if (file) {
        const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
        if (allowedTypes.includes(file.type)) {
          if (file.size > 5242880) {
            return toastAlert(error_msg, undefined, "warning");
          } else {
            handleFile(file);
            dispatch({ type: "SHOW_UPSCALE_TRUE" });
            setDragging(false);
          }
        } else {
          toastAlert(error_valid, undefined, "warning");
        }
      }
    }
  };

  const handleImageUpload = async (e) => {
    e.preventDefault();
    if (totalImages === 0) {
      dispatch({ type: "SHOW_UPSCALE_FALSE" });
      return setShowModal(true);
    } else {
      const file = await e.target.files;
      if (file) {
        const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
        if (allowedTypes.includes(file[0].type)) {
          if (file[0].size > 5242880) {
            return toastAlert(error_msg, undefined, "warning");
          } else {
            handleFile(file[0]);
            dispatch({ type: "SHOW_UPSCALE_TRUE" });
            setDragging(false);
          }
        } else {
          toastAlert(error_valid, undefined, "warning");
        }
      }
    }
  };

  const handleFile = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setBase64(reader.result.toString());
    };
  };

  const handleDownload = () => {
    const fileUrl = upscale?.upscale?.urls;
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = "hello"; // Specify the file name here
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  useEffect(() => {
    if (upscale?.loading) {
      let intervalTime;
      if (upscale?.loadingNumber >= 1 && upscale?.loadingNumber < 25) {
        intervalTime = 3000 / 25; // 5 seconds for 1-25, increment by 1 every 200ms
        setLoadingText("Uploading Your Image");
      } else if (upscale?.loadingNumber >= 25 && upscale?.loadingNumber < 50) {
        intervalTime = 5000 / 25; // 20 seconds for 25-50, increment by 1 every 800ms
        setLoadingText("Analyzing Your Image");
      } else if (upscale?.loadingNumber >= 50 && upscale?.loadingNumber < 75) {
        intervalTime = 10000 / 25; // 30 seconds for 50-75, increment by 1 every 1200ms
        setLoadingText("Upscaling Your Image");
      } else if (upscale?.loadingNumber >= 75 && upscale?.loadingNumber < 100) {
        intervalTime = 50000 / 25; // 10 seconds for 50-75, increment by 1 every 1200ms
        setLoadingText("Downloading the Image");
      }
      const timer = setInterval(() => {
        dispatch(handleUpscaleLoading(upscale?.loadingNumber, "upscale"));
      }, intervalTime);
      return () => {
        clearInterval(timer);
      };
    }
  }, [upscale?.loadingNumber, upscale?.loading]);

  return (
    <>
      {upscale?.loading ? (
        <div
          style={{
            height: "calc(100vh - 200px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading_v2
            loadingText={loadingText}
            setLoadingText={setLoadingText}
            loadingNumber={upscale?.loadingNumber}
          />
        </div>
      ) : upscale?.upscale?.length != 0 ? (
        <div style={{ position: "relative" }}>
          <div
            style={{
              maxWidth: "600px",
              minHeight: "450px",
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={upscale?.upscale?.urls}
              className="bg_remover_background"
            />
          </div>
          <div className="beforeAfter-container">
            <div className="beforeafter-gray">
              <button className="download-btn" onClick={handleDownload}>
                <i
                  className="fas fa-arrow-down"
                  style={{
                    fontSize: "medium",
                    margin: "0 10px",
                  }}
                ></i>
                {t("Download")}
              </button>
              <button
                className="newupload"
                onClick={() => {
                  dispatch({ type: "NEW_UPSCALE" });
                }}
              >
                <ReplayIcon
                  sx={{
                    transform: "scale(-1)",
                    margin: "0 10px",
                    fontSize: "20px",
                  }}
                />
                {t("Try Again")}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="image_gen_container">
            <div
              className="image_gen_header"
              style={{
                padding: width < 500 ? "10px" : "20px",
                alignSelf: "flex-start",
              }}
            >
              <h4 style={{ textAlign: lng === "arab" ? "right" : "left" }}>
                {t(
                  "Upscale your image up to 8x, get clear quality image without losing any detail"
                )}
              </h4>
              <p style={{ textAlign: lng === "arab" ? "right" : "left" }}>
                {t(
                  "Twwt.Ai's Image upscaler helps you increase the resolution and quality of your media files instantly."
                )}
              </p>

              {upscale.showRemover ? (
                <>
                  <div
                    style={{
                      margin: "auto",
                      width: "100%",
                      borderRadius: "20px",
                    }}
                  >
                    {/* {upscale?.upscale?.length === 0 && upscale?.loading === false && (
                      <div
                        style={{
                          maxWidth: "500px",
                          minHeight: "450px",
                          margin: "auto",
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span
                          onClick={() => dispatch({ type: "NEW_UPSCALE" })}
                          className="close_button"
                          style={{
                            left: lng === "arab" ? "30px" : null,
                            right: lng === "arab" ? null : "30px",
                            top: "15px",
                            background: "black",
                          }}
                        >
                          <CloseRoundedIcon sx={{ fontSize: "20px", color: "white" }} />
                        </span>
                        <img className="bg_remover_background" style={{ backgroundImage: "none" }} src={upscale?.uploaded} />
                      </div>
                    )} */}
                  </div>
                </>
              ) : (
                <FileContainer
                  handleDrop={handleDrop}
                  handleDragOver={handleDragOver}
                  handleDragLeave={handleDragLeave}
                  handleUpload={handleImageUpload}
                  dragging={dragging}
                  types=".jpg,.jpeg,.png"
                  support="JPG , PNG"
                />
              )}
              {user?.plan === "Trial" ? (
                <UpgradeModal
                  images={true}
                  setShowModal={setShowModal}
                  showModal={showModal}
                />
              ) : (
                <LimitPopUp setShowModal={setShowModal} showModal={showModal} />
              )}

              <UpgradePlanModal show={show} setShow={setShow} width={width} />
            </div>
            <div className="right_image_media">
              <img
                src={upscaleImage}
                alt="upscale-example"
                width={"100%"}
                height={"100%"}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Upscale;
