import { FETCH_OVERLAP_ANIMATION, LOAD_OVERLAP_ANIMATION, LOADED_OVERLAP_ANIMATION, NEW_OVERLAP_ANIMATION } from "redux/contstants";

export default (overlap_animation = { overlap_animation: [], loading: false, showRemover: false }, action) => {
  switch (action.type) {
    case LOAD_OVERLAP_ANIMATION:
      return { ...overlap_animation, loading: true, showRemover: true };
    case FETCH_OVERLAP_ANIMATION:
      return { ...overlap_animation, overlap_animation: action.data };
    case LOADED_OVERLAP_ANIMATION:
      return { ...overlap_animation, loading: false };
    case NEW_OVERLAP_ANIMATION:
      return { ...overlap_animation, overlap_animation: [], showRemover: false };
    default:
      return overlap_animation;
  }
};
