import { LOAD_REMOVER, FETCH_REMOVER, REMOVER_LOADED, NEW_BACKGROUND_REMOVER, SHOW_BACKGROUND_REMOVER_FALSE, SHOW_BACKGROUND_REMOVER_TRUE, LOAD_PERCENTAGE_REMOVER } from "redux/contstants";

export default (backgroundRemover = { backgroundRemover: [], loading: false, loadingNumber: 0, showRemover: false }, action) => {
  switch (action.type) {
    case LOAD_REMOVER:
      return { ...backgroundRemover, loading: true, loadingNumber: 0, showRemover: true };
    case FETCH_REMOVER:
      return { ...backgroundRemover, backgroundRemover: action.data, loadingNumber: 0 };
    case REMOVER_LOADED:
      return { ...backgroundRemover, loading: false };
    case NEW_BACKGROUND_REMOVER:
      return { ...backgroundRemover, backgroundRemover: [], loadingNumber: 0, showRemover: false };
    case SHOW_BACKGROUND_REMOVER_FALSE:
      return { ...backgroundRemover, showRemover: false };
    case SHOW_BACKGROUND_REMOVER_TRUE:
      return { ...backgroundRemover, showRemover: true };
    case LOAD_PERCENTAGE_REMOVER:
      return { ...backgroundRemover, loadingNumber: action.data };

    default:
      return backgroundRemover;
  }
};
