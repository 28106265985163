import React, { useState, useEffect } from "react";
import { baseUrl } from "api/baseUrl";
import axios from "axios";
import "views/MediaSection/ImageTools/Create/ImgGen.css";
import dragphoto from "assets/img/newDesign/cloud.svg";
import draghover from "assets/img/drag-img-hover.png";
import "font-awesome/css/font-awesome.min.css";
import { toastAlert } from "components/toastAlert/toastAlert";
import { useTranslation } from "react-i18next";
import UpgradeModal from "components/UpgradeModal/UpgradeModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllOldImages } from "redux/actions/history";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import UpgradePlanModal from "components/UpgradeModal/UpgradePlanModal";
import Resizer from "react-image-file-resizer";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import FileContainer from "components/MediaSection/FileContainer";

import LimitPopUp from "views/plansComponent/LimitPopUp";
const ResizeV2 = () => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showRemover, setShowRemover] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [uploaded, setUploaded] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [base64, setBase64] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [generated, setGenerated] = useState("");
  const [totalImages, setTotalImages] = useState(0);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [rotate, setRotate] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [loadingValue, setLoadingValue] = useState(100);
  const [loadingBegins, setloadingBegins] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (user?.plan === "Trial" || user?.plan === "Starter") {
      setShow(true);
    }
  }, [user]);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const [errors, setError] = useState("");
  let error_msg =
    lng === "arab"
      ? "حجم الصورة لا يجب أن يزيد عن 5 ميجابايت"
      : "Image size should be less than 5 MB";
  let error_valid =
    lng === "arab"
      ? "يُسمح فقط بملفات JPG و JPEG و PNG"
      : "Only JPG, JPEG, and PNG files are allowed.";

  useEffect(() => {
    setTotalImages(user?.image_count_left);
  }, [user]);

  const handleBackground = async () => {
    setloadingBegins(true);
    setGenerated(null);
    if (totalImages === 0) {
      setShowRemover(false);
      return setShowModal(true);
    } else {
      try {
        setIsLoading(true);
        const response = await axios.post(`${baseUrl}clip_interrogator`, {
          image_size: "1:1",
          style: "",
          num_images: 1,
          image: base64,
        });
        const res = await response?.data?.urls;
        setGenerated(res);
        dispatch({ type: "FETCH_USER" });
        dispatch(getAllOldImages());
        // setBase64("");
      } catch (error) {
        toastAlert(error);
        setIsLoading(false);
        setShowRemover(false);
      }
    }
  };

  useEffect(() => {
    if (base64) {
      handleBackground();
    }
  }, [base64]);

  const handleDrop = async (e) => {
    setGenerated(null);
    e.preventDefault();
    if (totalImages === 0) {
      setShowRemover(false);
      return setShowModal(true);
    } else {
      const file = await e.dataTransfer.files;
      if (file) {
        const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
        if (allowedTypes.includes(file[0].type)) {
          // if (file.size > 5242880) {
          //   setError(error_msg);
          //   return toastAlert(error_msg, undefined, "warning");
          // } else {
          // setSelectedFile(file[0]);
          const image = await resizeFile(file[0]);
          setBase64(image);
          // handleFile(file);
          setShowRemover(true);
          setDragging(false);
          // }
        } else {
          setError(error_valid);
          toastAlert(error_valid, undefined, "warning");
          setError("Only JPG, JPEG, and PNG files are allowed.");
        }
      } else setUploaded(true);
    }
  };

  const handleImageUpload = async (e) => {
    e.preventDefault();
    if (totalImages === 0) {
      setShowRemover(false);
      return setShowModal(true);
    } else {
      const file = await e.target.files;
      if (file) {
        const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
        if (allowedTypes.includes(file[0].type)) {
          // if (file[0].size > 5242880) {
          //   setError(error_msg);
          //   return toastAlert(error_msg, undefined, "warning");
          // } else {
          // setSelectedFile(file[0]);
          const image = await resizeFile(file[0]);
          setBase64(image);
          // handleFile(file[0]);
          setShowRemover(true);
          setDragging(false);
          setError();
          // }
        } else {
          toastAlert(
            "Only JPG, JPEG, and PNG files are allowed.",
            undefined,
            "warning"
          );
        }
      } else setUploaded(true);
    }
  };

  // const handleFile = (file) => {
  //   var reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = function () {
  //     setBase64(reader.result.toString());
  //   };
  //   if (file && /image/.test(file.type)) {
  //     setSelectedImage(URL.createObjectURL(file));
  //   }
  // };

  const handleDownload = async () => {
    const fileUrl = generated;

    //download image with api call and upscale

    // try {
    //   const response = await axios.post(`${baseUrl}upscale`, {
    //     scale: 4,
    //     image: fileUrl,
    //   });
    //   const res = await response?.data?.urls;
    // } catch (err) {
    //   if (err.response) {
    //     setShowRemover(false);
    //     const errorMessage = await err.response.data;
    //     toastAlert(errorMessage.message);
    //     setIsLoading(false);
    //   } else {
    //     setShowRemover(false);
    //     toastAlert("An error occurred.");
    //     setIsLoading(false);
    //   }
    // }
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = "hello"; // Specify the file name here
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1024,
        1024,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  // const handleChange = async (e) => {
  //   const file = e.target.files[0];
  //   const image = await resizeFile(file);
  //   // setBase64(image);
  //   console.log(image);
  // };
  return (
    <>
      <div
        style={{ padding: "40px 15px 0px 15px" }}
        className="image_gen_header"
      >
        <h4 style={{ marginBottom: "0" }}>
          {t("Reimagine from different perspective V2")}
        </h4>
        {showRemover ? (
          <>
            {isLoading && (
              <div
                style={{ margin: "auto", width: "100%", borderRadius: "20px" }}
              >
                <Skeleton
                  height={width <= 500 ? 350 : 400}
                  width={width <= 500 ? 350 : 450}
                  style={{ borderRadius: "15px" }}
                />
              </div>
            )}
            <div
              style={{
                position: "relative",
                display: isLoading ? "none" : "block",
              }}
            >
              <div
                className="redesign_image"
                style={{
                  maxWidth: "500px",
                  minHeight: "300px",
                  margin: "auto",
                  transform: `rotate(${rotate}deg)`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{ display: "flex", flexDirection: "column" }}
                  className="redesign_image_item"
                >
                  <h5>{t("Before")}</h5>
                  <img
                    src={base64}
                    // className="bg_remover_background"
                    style={{
                      width: width > 1200 ? "450px" : "300px",
                      borderRadius: "10px",
                      maxHeight: "500px",
                      objectFit: "cover",
                      marginBottom: "45px",
                    }}
                  />
                </div>
                {width > 670 && (
                  <ArrowForwardRoundedIcon
                    style={{
                      marginLeft: "1rem",
                      marginRight: "1rem",
                      transform: lng === "arab" ? "scale(-1)" : null,
                    }}
                  />
                )}

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{ display: "flex", flexDirection: "column" }}
                    className="redesign_image_item_item"
                  >
                    <h5>{t("After")}</h5>
                    <img
                      onLoad={() => setIsLoading(false)}
                      src={generated}
                      style={{
                        width: width > 1200 ? "450px" : "300px",
                        borderRadius: "10px",
                        maxHeight: "500px",
                        objectFit: "cover",
                      }}

                      // className="bg_remover_background"
                    />
                  </div>
                  {!isLoading && (
                    <div>
                      <div
                        className="beforeafter-gray"
                        style={{ padding: "0px" }}
                      >
                        <button
                          className="newupload"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "medium",
                          }}
                          onClick={() => {
                            setShowRemover(false);
                            setBase64("");
                            setGenerated("");
                          }}
                        >
                          {t("New")}
                          <i
                            className="fas fa-arrow-up"
                            style={{ fontSize: "medium" }}
                          ></i>
                        </button>
                        <button
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            fontSize: "medium",
                          }}
                          className="download-btn"
                          onClick={handleDownload}
                        >
                          {t("Download")}{" "}
                          <i
                            className="fas fa-arrow-down"
                            style={{ fontSize: "medium" }}
                          ></i>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* {!isLoading && (
                <div>
                  <div className="beforeafter-gray" style={{ padding: "0px" }}>
                    <button
                      className="newupload"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                        fontSize: "medium",
                      }}
                      onClick={() => setShowRemover(false)}
                    >
                      {t("New Upload")}
                      <i
                        className="fas fa-arrow-up"
                        style={{ fontSize: "medium" }}
                      ></i>
                    </button>
                    <button
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        fontSize: "medium",
                      }}
                      className="download-btn"
                      onClick={handleDownload}
                    >
                      {t("Download")}{" "}
                      <i
                        className="fas fa-arrow-down"
                        style={{ fontSize: "medium" }}
                      ></i>
                    </button>
                  </div>
                </div>
              )} */}
            </div>
          </>
        ) : (
          <>
            <FileContainer
              handleDrop={handleDrop}
              handleDragOver={handleDragOver}
              handleDragLeave={handleDragLeave}
              handleUpload={handleImageUpload}
              dragging={dragging}
              types=".jpg,.jpeg,.png"
              support="JPG , PNG"
            />
          </>
        )}
      </div>
      {user?.plan === "Trial" ? (
        <UpgradeModal
          images={true}
          setShowModal={setShowModal}
          showModal={showModal}
        />
      ) : (
        <LimitPopUp setShowModal={setShowModal} showModal={showModal} />
      )}
      <UpgradePlanModal show={show} setShow={setShow} width={width} />
      <label style={{ color: "red", paddingTop: "50px" }}>{errors}</label>
    </>
  );
};

export default ResizeV2;
