import { CASES_LOADED } from "redux/contstants";
import { LOAD_CASES } from "redux/contstants";
import { FETCH_CASES } from "redux/contstants";
import {MOST_USED_CASES} from "redux/contstants";

export default (cases = { cases: [], languages: [], tone: [], category: [], code: [], catInfo: [],most:[], loading: false }, action) => {
    switch (action.type) {
        case FETCH_CASES:
            let lng = JSON.parse(localStorage.getItem("language"))?.lang;
            let languages = action.data?.languages;
            let code = action.data?.code_languages;
            let catInfo = action.data?.categoryDesc;
            let tone = action.data?.tones;
            let casesInner = action.data?.use_cases?.filter(item => item?.category !== "landing_page").filter(item => item.arabic_name);
            let category = casesInner?.map((item, i) => { return { category: item?.category, category_icon: item?.category_icon, category_name: lng === 'arab' ? item?.arabic_category_name : item?.category_name, desc: lng === 'arab' ? catInfo?.filter(inner => inner?.cat_Key === item?.category)?.slice(0)?.shift()?.arab_desc : catInfo?.filter(inner => inner?.cat_Key === item?.category)?.slice(0)?.shift()?.eng_desc } });
            // let mostUsedData = action.data?.textUseCases;
            // let arr = []
            // let arr2 = []
            // for (let i in data) {
            //     arr.push(mostUsedData[i].useCase)
            // }

            // for (let i in casesInner) {

            //     if (arr.includes(casesInner[i].key)) {
            //         arr2.push(casesInner[i])
            //     }
            // }
            // let mostUsed = arr2
            let categoryUniqu = [...new Map(category?.map((item) => [item["category"], item]))?.values(),]?.filter(item => item?.category && item?.category_name)
            return { ...cases, catInfo: catInfo, cases: casesInner, tone: tone, languages: languages, category: categoryUniqu, code: code, loading: false };
        case MOST_USED_CASES:
           let most = action?.data?.data?.textUseCases
            return { ...cases, most: most };
        case LOAD_CASES:
            return { ...cases, loading: true };
        case CASES_LOADED:
            return { ...cases, loading: false };
        default:
            return cases;
    }
}