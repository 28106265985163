import React, { useState, useEffect } from "react";
import "./VideoBackgroundRemover.css";
import { toastAlert } from "components/toastAlert/toastAlert";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import UpgradeModal from "components/UpgradeModal/UpgradeModal";
import "views/MediaSection/ImageTools/Create/ImgGen.css";
import checkErr from "redux/actions/api_error_func";
import UpgradePlanModal from "components/UpgradeModal/UpgradePlanModal";
import { handleRemoveVideoBackground } from "redux/actions/media_section";
import FileContainer from "components/MediaSection/FileContainer";
import Loading_v2 from "components/Loading/Laoadingv2/Loadingv2";
import ReplayIcon from "@mui/icons-material/Replay";
import { handleRemoveVideoBackgroundLoading } from "redux/actions/media_section";
import remove_background from "assets/img/newDesign/imageSectionVideos/showreeledit.gif";

const Upscale = () => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const removeVideo = useSelector((state) => state.remove_video_background);
  const [showModal, setShowModal] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [totalImages, setTotalImages] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [outputType, setOutputType] = useState("green-screen");
  const [show, setShow] = useState(false);
  const [loadingText, setLoadingText] = useState("Uploading your Video");

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const [errors, setError] = useState();

  let error_valid = lng === "arab" ? "يُسمح فقط بملفات JPG و JPEG و PNG" : "Only MP4 and MOV files are allowed.";

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [width]);

  useEffect(() => {
    setTotalImages(user?.image_count_left);
   
  }, [user]);

  const handlePainting = async (video) => {
    if (totalImages === 0) {
      dispatch({ type: "SHOW_REMOVE_VIDEO_FALSE" });
      setShowModal(true);
      return;
    } else {
      if (!video) {
        return toastAlert("Please upload a video", undefined, "warning");
      }
    }
    try {
      const formData = new FormData();
      formData.append("file", video);
      formData.append("output_type", outputType);
      dispatch(handleRemoveVideoBackground(formData));
    } catch (error) {
      toastAlert(error);
      checkErr(error, toastAlert);
    }
  };

  const handleDrop = async (e) => {
    const file = await e.dataTransfer.files;
    if (file) {
      const allowedTypes = ["video/mp4", "video/mov"];
      if (allowedTypes.includes(file[0].type)) {
        let video = file[0];
        handlePainting(video);
        // setSelectedFile(file[0]);
        dispatch({ type: "SHOW_REMOVE_VIDEO_TRUE" });
        setDragging(false);
      } else {
        setError(error_valid);
        toastAlert(error_valid, undefined, "warning");
      }
    } else setUploaded(true);
  };

  const handleImageUpload = async (e) => {
    const file = await e.target.files;
    // console.log(file, "file");
    if (file) {
      const allowedTypes = ["video/mp4", "video/mov"];
      if (allowedTypes.includes(file[0].type)) {
        let video = file[0];
        handlePainting(video);
        // setSelectedFile(file[0]);
        dispatch({ type: "SHOW_REMOVE_VIDEO_TRUE" });
        setDragging(false);
      } else {
        setError(error_valid);
        toastAlert(error_valid, undefined, "warning");
      }
    } else setUploaded(true);
  };

  const handleDownload = () => {
    const fileUrl = removeVideo?.removeVideo?.data?.urls;
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = "hello"; // Specify the file name here
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  useEffect(() => {
    if (removeVideo?.loading) {
      let intervalTime;
      if (removeVideo?.loadingNumber >= 1 && removeVideo?.loadingNumber < 25) {
        intervalTime = 3000 / 25; // 5 seconds for 1-25, increment by 1 every 200ms
        setLoadingText("Uploading Your Video");
      } else if (removeVideo?.loadingNumber >= 25 && removeVideo?.loadingNumber < 50) {
        intervalTime = 5000 / 25; // 20 seconds for 25-50, increment by 1 every 800ms
        setLoadingText("Analyzing Your Video");
      } else if (removeVideo?.loadingNumber >= 50 && removeVideo?.loadingNumber < 75) {
        intervalTime = 100000 / 25; // 30 seconds for 50-75, increment by 1 every 1200ms
        setLoadingText("Removing background from your Video");
      } else if (removeVideo?.loadingNumber >= 75 && removeVideo?.loadingNumber < 100) {
        intervalTime = 10000 / 25; // 10 seconds for 50-75, increment by 1 every 1200ms
        setLoadingText("Downloading the Video");
      }
      const timer = setInterval(() => {
        dispatch(handleRemoveVideoBackgroundLoading(removeVideo?.loadingNumber, "removeVideo"));
      }, intervalTime);
      return () => {
        clearInterval(timer);
      };
    }
  }, [removeVideo?.loadingNumber, removeVideo?.loading]);

  return (
    <>
      {removeVideo?.loading ? (
        <div
          style={{
            height: "calc(100vh - 200px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading_v2 loadingText={loadingText} setLoadingText={setLoadingText} loadingNumber={removeVideo?.loadingNumber} />
        </div>
      ) : removeVideo?.removeVideo?.length != 0 ? (
        <div style={{ position: "relative" }}>
          {removeVideo?.removeVideo?.length != 0 && (
            <>
              <div
                style={{
                  maxWidth: "600px",
                  minHeight: "450px",
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <video src={removeVideo?.removeVideo?.data?.urls} controls className="bg_remover_background" />
              </div>
              <div className="beforeAfter-container">
                <div className="beforeafter-gray">
                  <button className="download-btn" onClick={handleDownload}>
                    <i
                      className="fas fa-arrow-down"
                      style={{
                        fontSize: "medium",
                        margin: "0 10px",
                      }}
                    ></i>
                    {t("Download")}
                  </button>
                  <button
                    className="newupload"
                    onClick={() => {
                      dispatch({ type: "NEW_REMOVE_VIDEO" });
                    }}
                  >
                    <ReplayIcon
                      sx={{
                        transform: "scale(-1)",
                        margin: "0 10px",
                        fontSize: "20px",
                      }}
                    />
                    {t("Try Again")}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          <div className="image_gen_container">
            <div
              className="image_gen_header"
              style={{
                padding: width < 500 ? "10px" : "20px",
                alignSelf: "flex-start",
              }}
            >
              <h4 style={{ textAlign: lng === "arab" ? "right" : "left" }}>{t("Say goodbye to cluttered backgrounds. with 1 click remove your background from a video")}</h4>
              <p style={{ textAlign: lng === "arab" ? "right" : "left" }}>
                {t("Watch as the distractions fade away, leaving behind a clean canvas that enhances your visuals and engages your viewers")}
              </p>
              {!removeVideo.showRemover && (
                <FileContainer
                  handleDrop={handleDrop}
                  handleDragOver={handleDragOver}
                  handleDragLeave={handleDragLeave}
                  handleUpload={handleImageUpload}
                  dragging={dragging}
                  types=".mp4,.wav,.mov"
                  support="mp4 and wav"
                />
              )}
              <UpgradeModal images={true} setShowModal={setShowModal} showModal={showModal} />
              <UpgradePlanModal show={show} setShow={setShow} width={width} />
            </div>
            <div className="right_image_media">
              <img src={remove_background} alt="upscale-example" width={"100%"} height={"90%"} style={{ borderRadius: "15px" }} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Upscale;
