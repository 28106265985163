import { Modal } from "react-bootstrap";
import React, { useState, useRef } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toastAlert } from "components/toastAlert/toastAlert";
import { changeStripeSubscription } from "../Functions/stripe";
import { HyperPayChangeSubscription } from "../Functions/hyperPay";
import { changeCryptoSubscription } from "../Functions/crypto";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import "./Downgrade.css";
import moment from "moment";
import Paypal from "../Paypal/Paypal";

const Downgrade = (props) => {
  const Freelancer = props?.planDetails?.name === "Freelancer";
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [checkedState, setCheckedState] = useState(new Array(props.userEnterpriseArray?.length).fill(false));
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const imageRef = useRef();

  const handleImgError = () => {
    if (imageRef.current) {
      imageRef.current.src = "https://riverlegacy.org/wp-content/uploads/2021/07/blank-profile-photo.jpeg";
    }
  };

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) => (index === position ? !item : item));
    setCheckedState(updatedCheckedState);
  };

  const onCancel = () => {
    setCheckedState(new Array(props.userEnterpriseArray?.length).fill(false));
    props.setDeletedUsers([]);
    props.setShowDownGrade(false);
  };

  const checkCanRemove = () => {
    if (props.deletedUsers?.length < props.removeUser) {
      props.setDeletedUsers([]);
      setCheckedState(new Array(props.userEnterpriseArray?.length).fill(false));
      toastAlert("Please select more users to continue");
    } else if (props.deletedUsers?.length == props.removeUser) {
      props.setEditType(props.editType);
      if (props.lastPayment?.last_transaction_id?.paymentGate === "hyperpay") {
        HyperPayChangeSubscription(props.planDetails, props.editType, props.selectedNumber, props.price, props.deletedUsers, props.isYearly);
      } else if (props.lastPayment?.last_transaction_id?.paymentGate === "coinbase") {
        changeCryptoSubscription(props.planDetails, props.editType, props.selectedNumber, props.price, props.deletedUsers);
      } else if (props.lastPayment?.last_transaction_id?.paymentGate === "stripe") {
        changeStripeSubscription(props.setShowDownGrade, props.subscribe_id, props.editType, props.selectedNumber, props.deletedUsers, props.setShowTyModal, dispatch, setLoaded, setIsLoading);
      } else toastAlert("You cant Upgrade / downgrade your plan");
    }
  };

  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered scrollable>
      <div className="header-container" style={{ fontFamily: lng === "arab" ? "Tajawal" : "poppins" }}>
        {Freelancer ? (
          <div className="header-text">
            <span style={{ fontWeight: 700 }}>
              {t("This is an individual plan select all users to Downgrade ")}
              {props.editType == "downgrade" && (
                <span>
                  {t("starting on ")}
                  {moment(props.lastPayment?.end_date)?.format("YYYY-MM-DD")}
                </span>
              )}
            </span>
          </div>
        ) : props.removeUser - props.deletedUsers?.length <= 0 ? (
          <div className="header-text">
            <span style={{ fontWeight: 700 }}>{t("Now you can change your plan.")} </span>
          </div>
        ) : (
          <div className="header-text">
            {t("Select up to ")}
            <span style={{ fontWeight: 900 }}>
              {props.removeUser - props.deletedUsers?.length} {t("users")}
            </span>
            {t(" to")}
            <span style={{ fontWeight: 900 }}> {t("remove")}</span>
            {t(" them from your new plan ")}
            {props.editType == "downgrade" && (
              <span>
                {t("starting on ")}
                {moment(props.lastPayment?.end_date)?.format("YYYY-MM-DD")}
              </span>
            )}
          </div>
        )}
        <div className="header-selected">
          {t(" Selected ")}({props.deletedUsers?.length}/{props.removeUser})
        </div>
      </div>
      <Modal.Body style={{ maxHeight: "390px", overflowY: "auto" }}>
        <Box>
          <div style={{ overflowX: "auto" }} className="tableScroll">
            <table
              style={{
                overflowX: "scroll",
                width: "100%",
                textAlign: lng === "arab" ? "right" : "left",
                overflow: "auto",
              }}
              className="table "
            >
              <thead>
                <tr style={{ textAlign: lng === "arab" ? "right" : "left" }}></tr>
              </thead>
              <tbody style={{ backgroundColor: "transparent" }}>
                {props.userEnterpriseArray?.map((item, i) => (
                  <tr key={i} style={{ backgroundColor: "transparent" }}>
                    <td
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      <img
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                        }}
                        ref={imageRef}
                        onError={handleImgError}
                        src={item?.user_info?.image != null ? item?.user_info?.image : "https://riverlegacy.org/wp-content/uploads/2021/07/blank-profile-photo.jpeg"}
                        alt="user_profile_image"
                        className="user_profile_image"
                      />
                      <div>
                        <label style={{ fontWeight: 600, marginBottom: "0px" }}>{item?.user_info?.name}</label>
                        <br />
                        <label style={{ fontSize: "12px", fontStyle: "italic" }}>{item?.user_info?.email}</label>
                      </div>
                      {
                        <div className="container_checkbox" style={{ marginLeft: lng === "arab" ? null : "auto", marginRight: lng === "arab" ? "auto" : null }}>
                          <div className="round">
                            <input
                              key={i}
                              name={item._id}
                              value={item._id}
                              id={`custom-checkbox-${i}`}
                              type="checkbox"
                              checked={checkedState[i]}
                              style={{
                                width: "30px",
                                height: "30px",
                              }}
                              onChange={() => {
                                handleOnChange(i);
                                // if (props.deletedUsers?.length) {
                                if (props.deletedUsers.includes(item._id)) {
                                  props.setDeletedUsers((prev) => props.deletedUsers.filter((item2) => item2 !== item._id));
                                } else {
                                  props.setDeletedUsers((prev) => [...prev, item._id]);
                                }
                                // } else if (props.deletedUsers?.length == props.removeUser) {
                                //   if (props.deletedUsers.includes(item._id)) {
                                //     props.setDeletedUsers((prev) => props.deletedUsers.filter((item2) => item2 !== item._id));
                                //   }
                                // }
                              }}
                            />
                            <label for={`custom-checkbox-${i}`}></label>
                          </div>
                        </div>
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Box>
      </Modal.Body>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          margin: "20px",
          fontFamily: lng === "arab" ? "Tajawal" : "poppins",
        }}
      >
        {props?.lastPayment?.last_transaction_id?.paymentGate === "paypal" ? (
          <>
            <button
              className=" button_wrapper cancel_button"
              onClick={() => {
                onCancel();
              }}
            >
              {t("Cancel")}
            </button>
            <Paypal
              selectedPlan={props.selectedPlanPayPal}
              selectedNumber={props.selectedNumber}
              isYearly={props.isYearly}
              user={user}
              setThankyou={props.setShowModalPayPal}
              setShowSubscribeModal={props.setShowDownGrade}
              setShowCheckoutModal={props.setIsOpen}
            />
          </>
        ) : (
          <>
            <button
              className=" button_wrapper cancel_button"
              onClick={() => {
                onCancel();
              }}
            >
              {t("Cancel")}
            </button>
            <button
              className=" button_wrapper proceed_button"
              onClick={() => {
                checkCanRemove();
              }}
            >
              {isLoading ? <CircularProgress size={"25px"} sx={{ color: "white" }} /> : t(props.editType)}
            </button>
          </>
        )}
      </div>
      {props.editType == "downgrade" && (
        <p style={{ fontSize: "12px", margin: "10px auto", textAlign: "center", fontFamily: lng === "arab" ? "Tajawal" : "poppins", fontWeight: "600" }}>
          {t("Downgrading plan will take effect on the next renewal")}
        </p>
      )}
    </Modal>
  );
};

export default Downgrade;
