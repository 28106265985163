import { toastAlert } from "components/toastAlert/toastAlert";
import { STOP_LOADING, LOADING } from "redux/contstants";
import { USER_ENTERPRISE } from "redux/contstants";
import { USER_SUBSCRIPTION } from "redux/contstants";
import * as api from "../../api";
import checkErr from "./api_error_func";

export const getUserSubscription = () => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
        var result = await api.userSubscription();
        dispatch({ type: USER_SUBSCRIPTION, subscription: result?.data?.subItem || [] });
        dispatch({ type: STOP_LOADING });
    } catch (error) {
        dispatch({ type: STOP_LOADING });
        checkErr(error, false, toastAlert, false, dispatch, false)
    }
};

export const getUserEnterprise = () => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
        var result = await api.userEnterprise();
        dispatch({ type: USER_ENTERPRISE, subscription: result?.data });
        dispatch({ type: STOP_LOADING });
    } catch (error) {
        dispatch({ type: STOP_LOADING });
        checkErr(error, false, toastAlert, false, dispatch, false)
    }
};

export const setUserSubscription = (userSubscription) => (dispatch) => {
    dispatch({ type: USER_SUBSCRIPTION, subscription: userSubscription });
};
