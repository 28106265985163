import React from 'react'
import CustomRadioBtn from './CustomRadioBtn'
import Remove from '../../assets/img/remove.svg';
import { useState } from 'react';
import { toastAlert } from 'components/toastAlert/toastAlert';
import checkErr from 'redux/actions/api_error_func';
import { baseUrl } from 'api/baseUrl';

const CustomRadioGroup = ({ showModal, setShowModal, options, onSelect, defaultSelected }) => {
    const [loading, setLoading] = useState(false);
    const deleteCard = async (id) => {
        setLoading(true);
        try {
            const response = await fetch(`${baseUrl}stripe/removePaymentMethod/${id}`, {
                method: "PUT",
                headers: {
                    Authorization: localStorage.getItem("sess"),
                    'Content-Type': 'application/json'

                },
            }).then((res) => res.json()).then(async (data) => {
                if (data.success) {
                    setLoading(false);
                    setShowModal(false);
                    window.location.reload();
                }
            })
        } catch (error) {
            checkErr(error, setLoading, toastAlert)
            setLoading(false);


        }
    }


    return (
        <div style={{ height: '300px', overflowY: 'auto' }}>{options.map((item, index) =>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <CustomRadioBtn
                    value={item.id}
                    // label={`•••• •••• •••• ${item.card.last4}`}
                    label={`Card ending in ${item.card.last4}`}
                    key={index}
                    id={item.id}
                    // disabled={disabled}
                    default={item.id === defaultSelected}
                    onChange={onSelect}
                />
                <button onClick={() => deleteCard(item.id)} style={{ border: 'none', background: 'none', padding: '0px 5px' }}>
                    <img src={Remove} alt="remove" height="20" width="20" />
                </button >
            </div>
        )}</div>
    )



}

export default CustomRadioGroup