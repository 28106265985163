import React, { useState, useEffect } from "react";
import { baseUrl } from "api/baseUrl";
import { useTranslation } from "react-i18next";
import dragHover from "assets/img/drag-img-hover.png";
import dragPhoto from "assets/img/newDesign/cloud.svg";
import { useSelector } from "react-redux";
import UpgradeModal from "components/UpgradeModal/UpgradeModal";
import UpgradePlanModal from "components/UpgradeModal/UpgradePlanModal";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import axios from "axios";
import "./chatBot.scss";
import Loading_v2 from "components/Loading/Laoadingv2/Loadingv2";

const ChatDoc = () => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);

  const [dragging, setDragging] = useState(false);
  const [totalImages, setTotalImages] = useState(0);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [ChatBot, setChatBot] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vectorId, setVectorId] = useState("");
  const [assistance, setAssistance] = useState([]);
  const [userInput, setUserInput] = useState([]);
  const [chatLoading, setChatLoading] = useState(false);
  const [pdf, setPdf] = useState(null);
  const [loadingText, setLoadingText] = useState("Uploading Your Pdf");
  const [loadingNumber, setLoadingNumber] = useState(0);

  const handleNewPdf = () => {
    setChatBot(false);
    setPdf(null);
    setVectorId("");
    setAssistance([]);
    setUserInput([]);
    setChatLoading(false);
    setLoadingNumber(0);
  };

  useEffect(() => {
    setTotalImages(user?.image_count_left);
    if (user?.plan === "Trial" || user?.plan === "Starter") {
      setShow(true);
    }
  }, [user]);

  const handleChat = async (e) => {
    setChatLoading(true);
    console.log(e.target[0].value);
    e.preventDefault();
    try {
      let response = await axios.post(`${baseUrl}pdf-chat/send/message`, {
        vector_store_id: vectorId,
        message: e.target[0].value,
      });
      setChatLoading(false);
      e.target[0].value = "";
      let temp = [...assistance];
      temp.push(response.data.data.assistant);
      setAssistance(temp);
      let tempU = [...userInput];
      tempU.push(response.data.data.user);
      setUserInput(tempU);
    } catch (error) {
      setChatLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const handlePdfUpload = async (e) => {
    e.preventDefault();
    setLoading(true);
    setWidth;
    const file = await e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    try {
      let response = await axios.post(`${baseUrl}pdf-chat/create`, formData);
      console.log(response.data.data);
      setVectorId(response?.data?.data?.vector_store_id);
      setPdf(response?.data?.data?.file_url);
      setChatBot(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setChatBot(true);
      setLoading(false);
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
  };

  const handleDragOver = (e) => {
    console.log(e);
  };

  const handleDragLeave = (e) => {
    console.log(e);
  };

  const combinedMessages = [];
  const maxMessages = Math.max(userInput.length, assistance.length);

  for (let i = 0; i < maxMessages; i++) {
    if (i < userInput.length) {
      combinedMessages.push({ text: userInput[i], type: "user" });
    }
    if (i < assistance.length) {
      combinedMessages.push({ text: assistance[i], type: "assistant" });
    }
  }

  useEffect(() => {
    if (loading) {
      let intervalTime;
      if (loadingNumber >= 1 && loadingNumber < 25) {
        intervalTime = 3000 / 25; // 3 seconds for 1-25, increment by 1 every 200ms
        setLoadingText("Uploading Your Pdf");
      } else if (loadingNumber >= 25 && loadingNumber < 50) {
        intervalTime = 5000 / 25; // 5 seconds for 25-50, increment by 1 every 800ms
        setLoadingText("Analyzing Your Pdf");
      } else if (loadingNumber >= 50 && loadingNumber < 75) {
        intervalTime = 10000 / 25; // 10 seconds for 50-75, increment by 1 every 1200ms
        setLoadingText("Getting content from your Pdf");
      } else if (loadingNumber >= 75 && loadingNumber < 100) {
        intervalTime = 50000 / 25; // 50 seconds for 50-75, increment by 1 every 1200ms
        setLoadingText("Almost Done");
      }
      const timer = setInterval(() => {
        if (loadingNumber < 100) {
          setLoadingNumber(loadingNumber + 1);
        } else {
          setLoadingNumber(0);
        }
      }, intervalTime);
      return () => {
        clearInterval(timer);
      };
    }
  }, [loadingNumber, loading]);

  return (
    <>
      {loading ? (
        <div
          className="page_wrapper border-radius-top"
          style={{
            height: "calc(100vh - 200px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading_v2 loadingText={loadingText} setLoadingText={setLoadingText} loadingNumber={loadingNumber} />
        </div>
      ) : (
        <div
          style={{
            gap: "20px",
            padding: "0px",
            height: "calc(100vh - 110px)",
            position: "relative",
          }}
          className="page_wrapper border-radius-top"
        >
          {ChatBot ? (
            <div className="chatBot_container">
              <div className="chatBot_left">
                <div className="chatBot_left_message_container">
                  <button onClick={handleNewPdf} style={{ float: "right", border: "none", borderRadius: "10px", padding: "5px 10px", background: "#1171d8", color: "white", fontWeight: "500" }}>
                    New chat
                  </button>
                  {combinedMessages.length === 0 && (
                    <div>
                      <h4 style={{ marginBottom: "0", textAlign: "center" }}>{t("Chat with documents, and get instant answers")}</h4>
                    </div>
                  )}
                  {combinedMessages.map((message, index) => (
                    <div key={index} className="chatBot_left_message">
                      {message.type === "user" ? <AccountCircleIcon /> : <SmartToyIcon />}
                      <p>{message.text}</p>
                    </div>
                  ))}
                </div>
                <form className="chatBot_form" onSubmit={handleChat}>
                  <input type="text" placeholder="Type your message here" />
                  <button style={{ background: chatLoading ? "grey" : null }} disabled={chatLoading} type="submit">
                    {chatLoading ? "..." : t("ask")}
                  </button>
                </form>
              </div>
              {width > 1000 && (
                <div className="chatbot_right">
                  <iframe src={pdf} width="100%" height="100%"></iframe>
                </div>
              )}
            </div>
          ) : (
            <>
              <div style={{ padding: "40px 15px 0px 15px",flex:"unset" }} className="image_gen_header">
                <h4 style={{ marginBottom: "0" }}>{t("Chat with documents, and get instant answers")}</h4>
              </div>
              <div className="image-drop-container" style={{ width: "70%" }}>
                <label className="file-input-label">
                  <input type="file" accept=".pdf" onChange={handlePdfUpload} />
                  <div className={`image-drop-zone ${dragging ? "dragging" : "undraging"}`} onDrop={handleDrop} onDragOver={handleDragOver} onDragLeave={handleDragLeave}>
                    <>
                      <div className="image-drop-text">
                        {dragging ? (
                          <img
                            src={dragHover}
                            alt="other-img"
                            style={{
                              height: "80px",
                              width: "122px",
                              objectFit: "cover",
                              borderRadius: "5px",
                            }}
                          />
                        ) : (
                          <img
                            src={dragPhoto}
                            alt="drop-img"
                            style={{
                              height: "80px",
                              width: "122px",
                              objectFit: "cover",
                              borderRadius: "5px",
                            }}
                          />
                        )}
                        <h5 style={{ marginBottom: "0px" }}>
                          {t("Drop your pdf here, or")} <span className="file-input-label">{t("browse")}</span>
                        </h5>

                        <div className="image-drop-support-text">{t("Supports pdf")}</div>
                        {/* <div className="image-drop-support-text">{t("Maximum Size 5MB")}</div> */}
                      </div>
                    </>
                  </div>
                </label>
              </div>
            </>
          )}
          <UpgradeModal images={true} setShowModal={setShowModal} showModal={showModal} />
          <UpgradePlanModal show={show} setShow={setShow} width={width} />
        </div>
      )}
    </>
  );
};

export default ChatDoc;
