import React, { useState } from "react";
import moment from "moment";
import "./history.scss";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { Spinner } from "react-bootstrap";
import { useTranslation } from 'react-i18next';


const TextHistory = ({ data, page, limit,setLimit, setPage, isLoading,width }) => {
  const navigate = useNavigate();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const {t} = useTranslation();
  const history = "TextHistory"
  const handlePageClick = (pageNo) => {
    setPage(pageNo);
  };

  const renderPaginationOptions = () => {
    const paginationOptions = [];
    let startPage = 1;
    let endPage = Math.min(data?.totalPages, 5);
  
    if (data?.totalPages > 5) {
      if (page <= 3) {
        endPage = 5;
      } else if (page >= data?.totalPages - 2) {
        startPage = data?.totalPages - 4;
        endPage = data?.totalPages;
      } else {
        startPage = page - 2;
        endPage = page + 2;
      }
    }
  
    if (startPage > 1) {
      paginationOptions.push(renderPaginationButton(1));
      paginationOptions.push(<span key="first-ellipsis">...</span>);
    }
  
    for (let i = startPage; i <= endPage; i++) {
      paginationOptions.push(renderPaginationButton(i));
    }
  
    if (endPage < data?.totalPages) {
      paginationOptions.push(<span key="last-ellipsis">...</span>);
      paginationOptions.push(renderPaginationButton(data?.totalPages));
    }
  
    return paginationOptions;
  };
  
  const renderPaginationButton = (i) => (
    <button
      key={i}
      onClick={() => handlePageClick(i)}
      style={{
        background: page === i ? "linear-gradient(270deg, #151723, #23273a)" : null,
        color: page === i ? "white" : null,
      }}
    >
      {i}
    </button>
  );

  return (
    <>
      {data?.docs?.length === 0 ? (
        <center style={{ margin: "40px 0" }}>{t("There is no history available for Text")}</center>
      ) : (
        <>
          {isLoading ? (
            <Spinner style={{ position: "absolute", top: "50%", left: "50%" }} animation="border" role="status" />
          ) : (
            <div className="table_container">
              <div className="table_header">
                {["Date", "Text", "Choices", "Word Count", "Use Case"]
                  .filter((_, index) => width > 600 || (index !== 2 && index !== 3))
                  .map((items, index) => (
                    <div
                      key={index}
                      className="table_header_item"
                      style={{
                        width: items === "Date" ? "15%" : items === "Text" ? "40%" : items === "Word Count" ? "15%" : items === "Choices" ? " 10%" : "20%",
                        textAlign: items === "Use Case" || items === "Word Count" || "Date" ? "center" : null,
                      }}
                    >
                      {t(items)}
                    </div>
                  ))}
              </div>
              <div className="table_body">
                {data?.docs?.map((item, index) => {
                  return (
                    <div key={index} className="table_body_item" onClick={() => navigate(item._id)} style={{ background: index % 2 === 0 ? "transparent" : null }}>
                      <div className="table_body_item_date" style={{ minWidth: "15%", textAlign: "center" }}>
                        {moment(item?.createdAt)?.format("MMMM Do YYYY")}
                      </div>
                      <div className="table_body_item_text" style={{ minWidth: "40%", padding: "0 10px" }}>
                        {item?.text?.choices?.map((items, index) => (
                          <h6 key={index} className="table_body_item_text_h6" style={{ direction: lng === "arab" ? "rtl" : "ltr", textAlign: lng === "arab" ? "right" : null, margin: "0" }}>
                            {index + 1} . {items?.text}
                          </h6>
                        )) || <h6 className="table_body_item_text_h6">{item?.text?.text}</h6> || <h6 style={{ whiteSpace: "break-spaces" }}>{item?.text}</h6>}
                      </div>
                      {width >= 600 && (
                        <>
                          <div className="table_body_item_choices" style={{ minWidth: "10%", textAlign: "center" }}>
                            {item?.text?.choices?.length || item?.text?.length}
                          </div>
                          <div className="table_body_item_word_count" style={{ minWidth: "15%", padding: "0 10px", textAlign: "center" }}>
                            {item?.word_count}
                          </div>
                        </>
                      )}
                      <div className="table_body_item_useCase" style={{ minWidth: "20%", textAlign: "center" }}>
                        {item?.useCase?.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="table_footer">
                <div className="table_footer_item">
                  <select onChange={(e) => setLimit(e.target.value)} value={limit}>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  <div className="table_footer_buttons">
                    <button style={{ background: "none" }} disabled={!data?.hasPrevPage} onClick={() => setPage((page) => (page -= 1))}>
                      <ArrowBackIosNewRoundedIcon fontSize="small" sx={{ color: !data?.hasPrevPage ? "grey" : null, transform: lng === "arab" ? "rotate(180deg)" : null }} />
                    </button>
                    {renderPaginationOptions()}
                    <button style={{ background: "none" }} disabled={!data?.hasNextPage} onClick={() => setPage((page) => (page += 1))}>
                      <ArrowForwardIosRoundedIcon fontSize="small" sx={{ color: !data?.hasNextPage ? "grey" : null, transform: lng === "arab" ? "rotate(180deg)" : null }} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TextHistory;
