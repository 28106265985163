import { useState, useEffect } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import { baseUrl } from "api/baseUrl";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { useTranslation } from "react-i18next";

const InvoiceHistory = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const handlePageClick = (pageNo) => {
    setPage(pageNo);
  };

  const getData = async () => {
    setIsLoading(true);
    let res = await axios.get(`${baseUrl}subscription/getAll/user?page=${page}&limit=${limit}`).then((res) => {
      setTableData(res?.data?.subscriptions);
      setIsLoading(false);
    });
  };

  const renderPaginationOptions = () => {
    const paginationOptions = [];
    let startPage = 1;
    let endPage = Math.min(tableData?.totalPages, 5);
    if (tableData?.totalPages > 5) {
      if (page <= 3) {
        endPage = 5;
      } else if (page >= tableData?.totalPages - 2) {
        startPage = tableData?.totalPages - 4;
        endPage = tableData?.totalPages;
      } else {
        startPage = page - 2;
        endPage = page + 2;
      }
    }
    if (startPage > 1) {
      paginationOptions.push(renderPaginationButton(1));
      paginationOptions.push(<span key="first-ellipsis">...</span>);
    }
    for (let i = startPage; i <= endPage; i++) {
      paginationOptions.push(renderPaginationButton(i));
    }
    if (endPage < tableData?.totalPages) {
      paginationOptions.push(<span key="last-ellipsis">...</span>);
      paginationOptions.push(renderPaginationButton(tableData?.totalPages));
    }
    return paginationOptions;
  };

  const renderPaginationButton = (i) => (
    <span
      key={i}
      onClick={() => handlePageClick(i)}
      style={{
        background: page === i ? "linear-gradient(270deg, #151723, #23273a)" : null,
        color: page === i ? "white" : null,
        cursor: "pointer",
        padding: "8px",
        borderRadius: "5px",
      }}
    >
      {i}
    </span>
  );

  useEffect(() => {
    getData();
  }, [page]);

  return (
    <>
      {tableData?.length === 0 ? (
        <center style={{ margin: "40px 0" }}>{t("No Transaction")}</center>
      ) : (
        <>
          {isLoading ? (
            <Spinner style={{ position: "absolute", top: "50%", left: "50%" }} animation="border" role="status" />
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  gap: "4rem",
                  backgroundColor: "#E8E8E8",
                  padding: "10px",
                  marginBottom: "10px",
                  borderRadius: "10px",
                  width: "100%",
                }}
              >
                <span style={{ minWidth: "20%", textAlign: "center" }}>{t("Date")}</span>
                <span style={{ minWidth: "15%", textAlign: "center" }}>{t("Amount")}</span>
                <span
                  style={{
                    minWidth: "15%",
                    textAlign: "center",
                  }}
                >
                  {t("Status")}
                </span>
                <span style={{ minWidth: "15%", textAlign: "center" }}>{t("Name")}</span>
              </div>
              <div
                style={{
                  display: "grid",
                  alignItems: "center",
                  width: "100%",
                  rowGap: "2rem",
                  columnGap: "2rem",
                }}
              >
                {tableData.docs?.map((item, index) => {
                  return (
                    <div key={index} style={{ display: "flex", gap: "4rem" }}>
                      <span style={{ minWidth: "20%", textAlign: "center" }}>{moment(item?.last_transaction_id?.paid_at)?.format("DD-MM-YYYY")}</span>
                      <span style={{ minWidth: "15%", textAlign: "center" }}>${item?.last_transaction_id?.total_amount}</span>
                      <span
                        style={{
                          minWidth: "15%",
                          fontSize: "10px",
                          color: item?.status == "active" ? "#006908" : "red",
                          fontWeight: "700",
                          backgroundColor: item?.status == "active" ? "#D7F7C2" : "#ffc0cb",
                          borderRadius: "10px",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {item?.status}
                      </span>
                      <span style={{ minWidth: "15%", textAlign: "center" }}>{item?.product_id?.name}</span>
                    </div>
                  );
                })}
              </div>
              <div className="table_footer" style={{ marginTop: "1rem" }}>
                <div className="table_footer_item">
                  <div className="table_footer_buttons">
                    <span style={{ background: "none", cursor: "pointer" }} disabled={!tableData?.hasPrevPage} onClick={() => setPage((page) => (page -= 1))}>
                      <ArrowBackIosNewRoundedIcon
                        fontSize="small"
                        sx={{
                          color: !tableData?.hasPrevPage ? "grey" : "black",
                          transform: lng === "arab" ? "rotate(180deg)" : null,
                        }}
                      />
                    </span>
                    {renderPaginationOptions()}
                    <span style={{ background: "none", cursor: "pointer" }} disabled={!tableData?.hasNextPage} onClick={() => setPage((page) => (page += 1))}>
                      <ArrowForwardIosRoundedIcon fontSize="small" sx={{ color: !tableData?.hasNextPage ? "grey" : null, transform: lng === "arab" ? "rotate(180deg)" : null }} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default InvoiceHistory;
