import React, { Component, Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Navbar, Container, Nav, Dropdown, Button } from "react-bootstrap";
import WordCount from "components/wordCont/WordCount";
import { dashboardRoutes } from "routes.js";
import menuBars from "../../assets/img/newDesign/menuBars.png";
import menuBar from "../../assets/img/burger-bar.png";
import logoutIcon from "../../assets/img/newDesign/logout.png";
import homeLogo from "../../assets/img/newDesign/home.png";
import styles from "./Header.module.scss";
import english from "../../assets/img/united-kingdom.png";
import arabic from "../../assets/img/saudi-arabia.png";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { clearData } from "redux/actions/user";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
function Header() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { name, id } = useParams();
  const { business_cases, loading, category } = useSelector((state) => state?.business_cases);
  const location = useLocation();
  const dispatch = useDispatch();
  const [pageTitle, setPageTitle] = useState("");
  const [isMenuOpen, handleMenu] = useState(false);
  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };
  function useOutsideAlerter() {
    useEffect(() => {
      document.removeEventListener("mousedown", mobileSidebarToggle);
      return () => {
        document.removeEventListener("mousedown", mobileSidebarToggle);
      };
    }, []);
  }
  useOutsideAlerter();

  useEffect(() => {
    setPageTitle(
      location.pathname.split("/").includes("photogen")
        ? "Media Section"
        : location.pathname.split("/").includes("writenow")
        ? "Writers Section"
        : location.pathname.split("/").includes("steps")
        ? "Steps"
        : location.pathname.split("/").includes("profile")
        ? "View Profile"
        : location.pathname.split("/").includes("plan")
        ? "Plans"
        : location.pathname.split("/").includes("templates")
        ? "Templates"
        : location.pathname.split("/").includes("documents")
        ? "Write Documents"
        : location.pathname.split("/").includes("contactus")
        ? "Contact Us"
        : location.pathname.split("/").includes("usage")
        ? "My Usage"
        : location.pathname.split("/").includes("writecode")
        ? "Developers Section"
        : location.pathname.split("/").includes("twwtgpt")
        ? "Twwt GPT"
        : // : location.pathname.split("/").includes("business")
        // ? "Business"
        location.pathname.split("/").includes("presentation")
        ? "Presentation"
        : location.pathname.split("/").includes("slides")
        ? "Slides"
        : location.pathname.split("/").includes("blog")
        ? "Blog"
        : location.pathname.split("/").includes("business-tools")
        ? location.pathname.split("/")?.length === 4
          ? business_cases?.filter((item) => item?.id == location.pathname.split("/").slice(0)?.reverse()?.shift())?.length
            ? lng === "arab"
              ? business_cases?.filter((item) => item?.id == location.pathname.split("/").slice(0)?.reverse()?.shift())?.shift()?.arabic_name
              : business_cases?.filter((item) => item?.id == location.pathname.split("/").slice(0)?.reverse()?.shift())?.shift()?.name
            : "Business Tools"
          : "Business Tools"
        : location.pathname.split("/").includes("history") && location.pathname.split("/").includes("text")
        ? "Text History"
        : location.pathname.split("/").includes("history") && location.pathname.split("/").includes("code")
        ? "Code History"
        : location.pathname.split("/").includes("history") && location.pathname.split("/").includes("photo")
        ? "Photo History"
        : location.pathname.split("/").includes("history") && location.pathname.split("/").includes("business")
        ? "Business History"
        : location.pathname.split("/").includes("history") && location.pathname.split("/").includes("presentation")
        ? "Presentation History"
        : location.pathname.split("/").includes("history")
        ? "History"
        : location.pathname.split("/").includes("paymentgateway")
        ? "Manage Plans"
        : location.pathname.split("/").includes("document")
        ? "Documents"
        : location.pathname.split("/").includes("pdf-chat")
        ? "Chat Document"
        : "Home"
    );
  }, [location]);
  const handleLanguage = (str) => {
    if (str === "eng") {
      localStorage.setItem("language", JSON.stringify({ lang: "en" }));
      window.location.reload();
    } else {
      localStorage.setItem("language", JSON.stringify({ lang: "arab" }));
      window.location.reload();
    }
  };

  const handleLogOut = () => {
    dispatch(clearData());
  };
  const [isMobile, setIsMobile] = useState(faBullseye);
  const [isMobileS, setIsMobileS] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const handleSizeMobile = () => {
    if (window.innerWidth < 550) {
      setIsMobileS(true);
    } else {
      setIsMobileS(false);
    }
  };

  const user = useSelector((state) => state.user);

  const userSubscription = useSelector((state) => state.userSubscription);
  //choose the screen size

  useEffect(() => {
    const handleWidth = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWidth);

    return () => {
      window.removeEventListener("resize", handleWidth);
    };
  }, []);
  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth <= 991) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    handleResize();
    handleSizeMobile();
    window.addEventListener("resize", handleResize);
  }, [width]);

  // create an event listener
  // useEffect(() => {
  //   handleSizeMobile();
  //   handleResize();
  //   window.addEventListener("resize", handleResize);
  // }, );

  // create an event listener
  // useEffect(() => {
  //   handleResize();
  //   handleSizeMobile();
  //   window.addEventListener("resize", handleResize);
  // }, []);

  return (
    <Fragment>
      <Navbar
        style={{
          background: (location?.pathname?.split("/") === 4 && !location.pathname.split("/").includes("business-tools")) || pageTitle === "Plans" || pageTitle === "Documents" ? "#FAFDFF" : null,
          padding: (location?.pathname?.split("/") === 4 && !location.pathname.split("/").includes("business-tools")) || pageTitle === "Plans" || pageTitle === "Documents" ? "0" : null,
          display:
            (location?.pathname?.split("/")?.length === 4 && location.pathname.split("/").includes("business-tools")) ||
            (location?.pathname?.split("/")?.length === 4 && location.pathname.split("/").includes("writenow")) ||
            (location?.pathname?.split("/")?.length === 3 && location.pathname.split("/").includes("slides")) ||
            pageTitle === "Steps" ||
            pageTitle === "Write Documents" ||
            (location?.pathname?.split("/")?.length === 3 && pageTitle === "Write Code") ||
            (location?.pathname?.split("/")?.length === 3 && pageTitle === "Blog") ||
            (location?.pathname?.split("/")?.length === 3 && pageTitle === "Developers Section") ||
            pageTitle === "Documents" ||
            (location?.pathname.split("/")?.length === 4 && pageTitle === "Media Section")
              ? "none"
              : null,
        }}
        expand="lg"
      >
        <Container fluid>
          <div
            style={{
              justifyContent: "space-between",
              width: "100%",
              flexWrap: "wrap",
            }}
            className="d-flex align-items-center ml-2 ml-lg-0"
          >
            {width <= 991 ? (
              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Button id="sideMenuMobileBtn" className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2" onClick={mobileSidebarToggle}>
                  <img style={{ height: "20px", width: "20px" }} alt="menu_bars" src={pageTitle === "Plans" ? menuBar : menuBars} />
                </Button>
                <h3 style={{ fontWeight: "700", fontSize: 20, margin: "0px", color: "#fff", marginBottom: "1px" }}>{t(pageTitle)}</h3>
              </div>
            ) : (
              <Button id="sideMenuMobileBtn" className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2" onClick={mobileSidebarToggle}>
                <img style={{ height: "25px", width: "25px" }} alt="menu_bars" src={pageTitle === "Plans" ? menuBar : menuBars} />
              </Button>
            )}
            <div
              style={{
                background: pageTitle !== "Steps" ? "transparent" : null,
                position: pageTitle !== "Steps" ? "absolute" : null,
                left: lng === "arab" ? "0px" : "auto",
                right: lng === "arab" ? "auto" : "0px",
                bottom: "10px",
                zIndex: "100",
              }}
              className="user_controls"
            >
              {pageTitle === "Plans" ? (
                ""
              ) : width <= 900 ? (
                <>
                  {pageTitle === "View Profile" ? (
                    <Link onClick={handleLogOut} to="#" style={{ flexDirection: "row" }}>
                      {isMobileS ? "" : <span style={{ textAlign: "center" }}>{t("Logout")}</span>}
                      <img src={logoutIcon} alt="logout" style={{ transform: lng === "arab" ? "rotate(180deg)" : "" }} />
                    </Link>
                  ) : (
                    <div style={{ marginTop: "0px", background: "white" }} className="upgrade_sideMenu">
                      <label
                        style={{ display: "flex", flexDirection: lng === "arab" ? "row-reverse" : "row", gap: "4px", marginBottom: user.plan !== "Pro" ? "0px" : "none" }}
                        onClick={() => navigate("/plan")}
                      >
                        <span style={{ color: "black", fontWeight: "600" }}>{userSubscription?.product?.name ? t(userSubscription?.product?.name) : user?.plan ? t(user?.plan) : t("Free Trial")}</span>
                        <span style={{ color: "black", fontWeight: "600" }}>{t("Plan")}</span>
                      </label>
                      {/* {(userSubscription?.product?.name !== "Pro" && !user?.enterprise) && <Link to='/plan' style={{ marginBottom: '4px' }} className='navbar_button2' tabIndex={-1}>{t("Upgrade Plan")}</Link>} */}
                    </div>
                  )}
                </>
              ) : (
                <Link onClick={handleLogOut} to="#" style={{ flexDirection: "row" }}>
                  {isMobileS ? "" : <span style={{ textAlign: "center" }}>{t("Logout")}</span>}
                  <img src={logoutIcon} alt="logout" style={{ transform: lng === "arab" ? "rotate(180deg)" : "" }} />
                </Link>
              )}
              <Link onClick={() => handleLanguage(lng === "arab" ? "eng" : "arab")} className={lng === "arab" ? "englishFont" : "arabicFont"} to="#">
                <div>
                  {lng === "arab" ? (
                    <div className={styles.dropdown}>
                      <img src={english} />
                      <h5 style={{ color: pageTitle === "Plans" ? "black" : null }} className={styles.araby}>
                        ENG
                      </h5>
                    </div>
                  ) : (
                    <div className={styles.dropdown}>
                      <img src={arabic} />
                      <h5 style={{ fontFamily: "Tajawal", color: pageTitle === "Plans" ? "black" : null }} className={`${styles.araby} arabicFont`}>
                        عربي
                      </h5>
                    </div>
                  )}
                </div>
              </Link>
              {/* <a
                style={{ border: "unset", minWidth: "auto", padding: "5px" }}
                href={process.env.REACT_APP_LANDING_URL}
              >
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={homeLogo}
                  alt="logout"
                />
              </a> */}
            </div>
          </div>
        </Container>
        {width <= 991 ? (
          <></>
        ) : (
          <>
            {location?.pathname?.split("/")[2] === "steps" ||
            location?.pathname?.split("/")[2] === "1" ||
            location?.pathname?.split("/")[2] === "2" ||
            location?.pathname?.split("/")[2] === "3" ? null : (
              <div
                style={{
                  padding: location?.pathname?.split("/") === 4 || pageTitle === "Plans" ? "0px 10px" : "10px",
                  justifyContent: !(
                    pageTitle === "Welcome to Twwt.Ai" ||
                    pageTitle === "Media Section" ||
                    pageTitle === "Writers Section" ||
                    pageTitle === "Home" ||
                    pageTitle === "Developers Section" ||
                    pageTitle === "Twwt GPT" ||
                    pageTitle === "Presentation" ||
                    pageTitle === "Business Tools" ||
                    pageTitle === "Slides" ||
                    pageTitle === "Marketing Plan" ||
                    pageTitle === "Text History" ||
                    pageTitle === "Code History" ||
                    pageTitle === "Manage Plans" ||
                    pageTitle === "Photo History" ||
                    pageTitle === "Business History" ||
                    pageTitle === "Presentation History" ||
                    pageTitle === "History" ||
                    pageTitle === "Blog" || 
                    pageTitle === "Chat Document" ||
                    (location.pathname.split("/").includes("business-tools") && location.pathname.split("/")?.length === 4)
                  )
                    ? "flex-start"
                    : null,
                }}
                className="wordCount_wrapper row"
              >
                <div
                  style={
                    lng === "arab"
                      ? {
                          left: "auto",
                          right: "10px",
                          position: !(
                            pageTitle === "Welcome to Twwt.Ai" ||
                            pageTitle === "Media Section" ||
                            pageTitle === "Writers Section" ||
                            pageTitle === "Home" ||
                            pageTitle === "Developers Section" ||
                            pageTitle === "Twwt GPT" ||
                            pageTitle === "Presentation" ||
                            pageTitle === "Business Tools" ||
                            pageTitle === "Slides" ||
                            pageTitle === "Marketing Plan" ||
                            pageTitle === "Text History" ||
                            pageTitle === "Code History" ||
                            pageTitle === "Photo History" ||
                            pageTitle === "Business History" ||
                            pageTitle === "Manage Plans" ||
                            pageTitle === "Presentation History" ||
                            pageTitle === "History" ||
                            pageTitle === "Blog" ||
                            pageTitle === "Chat Document"||
                            (location.pathname.split("/").includes("business-tools") && location.pathname.split("/")?.length === 4)
                          )
                            ? "static"
                            : null,
                        }
                      : {
                          position: !(
                            pageTitle === "Welcome to Twwt.Ai" ||
                            pageTitle === "Media Section" ||
                            pageTitle === "Writers Section" ||
                            pageTitle === "Home" ||
                            pageTitle === "Developers Section" ||
                            pageTitle === "Twwt GPT" ||
                            pageTitle === "Presentation" ||
                            pageTitle === "Business Tools" ||
                            pageTitle === "Slides" ||
                            pageTitle === "Marketing Plan" ||
                            pageTitle === "Text History" ||
                            pageTitle === "Code History" ||
                            pageTitle === "Photo History" ||
                            pageTitle === "Business History" ||
                            pageTitle === "Manage Plans" ||
                            pageTitle === "Presentation History" ||
                            pageTitle === "History" ||
                            pageTitle === "Blog" ||
                            pageTitle === "Chat Document" ||
                            (location.pathname.split("/").includes("business-tools") && location.pathname.split("/")?.length === 4)
                          )
                            ? "static"
                            : null,
                        }
                  }
                  className={
                    // location.pathname === "/"
                    //   ? "left_hand_side col-lg-6 col-md-6 col-sm-12" :
                    !(
                      pageTitle === "Welcome to Twwt.Ai" ||
                      pageTitle === "Media Section" ||
                      pageTitle === "Writers Section" ||
                      pageTitle === "Home" ||
                      pageTitle === "Developers Section" ||
                      pageTitle === "Twwt GPT" ||
                      pageTitle === "Presentation" ||
                      pageTitle === "Business Tools" ||
                      pageTitle === "Slides" ||
                      pageTitle === "Marketing Plan" ||
                      pageTitle === "Text History" ||
                      pageTitle === "Code History" ||
                      pageTitle === "Photo History" ||
                      pageTitle === "Business History" ||
                      pageTitle === "Presentation History" ||
                      pageTitle === "History" ||
                      pageTitle === "Blog" ||
                      pageTitle === "Chat Document" ||
                      (location.pathname.split("/").includes("business-tools") && location.pathname.split("/")?.length === 4)
                    )
                      ? "left_hand_side col-lg-12 col-md-12 col-sm-12"
                      : "left_hand_side col-lg-3  col-sm-12"
                  }
                >
                  <h3
                    style={{
                      fontWeight: "700",
                      fontSize: 21,
                    }}
                  >
                    {t(pageTitle)}
                  </h3>
                </div>
                {/* {isMobileS ? "" :  } */}

                {pageTitle === "View Profile" ||
                pageTitle === "Plans" ||
                pageTitle === "Contact Us" ||
                pageTitle === "My Usage" ||
                pageTitle === "Templates" ||
                pageTitle === "Business" ||
                pageTitle === "Plans" ||
                // pageTitle === "Blog" ||
                isMobile ? null : (
                  <WordCount imgGen={location.pathname.split("/").includes("photogen") ? true : false} />
                )}
              </div>
            )}
          </>
        )}
      </Navbar>
    </Fragment>
  );
}

export default Header;
