
export default (arabyGPT = { messages: [], loading: false, chatID: '', title: '', isStreaming: false, deleteOperation: {isDeleting: false, deletedItem: ''} }, action) => {
    switch (action.type) {
        case "ADD_MESSAGE":
            let messageHistory = arabyGPT?.messages?.filter(item => (item?.message_id === action.data?.message_id && item?.role !== 'user'));
                if (messageHistory?.length) {
                  let firstElement = messageHistory.slice(0)?.shift();
                  let index = arabyGPT?.messages?.indexOf(firstElement);
                  let newHistory = arabyGPT?.messages?.slice(0);
                  let newElement = {...firstElement, message: firstElement?.message + action.data?.message}
                  newHistory.splice(index, 1, newElement)
                  return { ...arabyGPT, messages: newHistory };
                } else {
                    return { ...arabyGPT, messages: [...arabyGPT.messages, action.data] };
                }     
        case "LOAD_MESSAGES":
            return { ...arabyGPT, loading: true };
        case "MESSAGES_LOADED":
            return { ...arabyGPT, loading: false };
        case "REMOVE_ALL":
            return { ...arabyGPT, messages: [], loading: false };
        case "STREAMING":
            return { ...arabyGPT, isStreaming: true };
        case "DONE_STREAMING":
            return { ...arabyGPT, isStreaming: false };
        case "CLEAR_WHOLE_CHAT":
            return { ...arabyGPT, messages: [], loading: false, chatID: '', title:'' };
        case "DELETED_ELEMENT":
            return { ...arabyGPT, deleteOperation: {isDeleting: false, deletedItem: action.data } };
        case "DELETING":
            return { ...arabyGPT, deleteOperation: {isDeleting: true, deletedItem: '' } };
        case "DELETED":
            return { ...arabyGPT, deleteOperation: {isDeleting: false, deletedItem: '' } };
        case "ADD_CHAT_ID":
            return { ...arabyGPT, chatID: action.data?.id, messages: action.data?.chat, loading: false, title: action.data?.title };
        default:
            return arabyGPT;
    }
}