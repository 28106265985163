import React, { useEffect, useState } from "react";
import { baseUrl } from "api/baseUrl";
import { Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserSubscription } from "redux/actions/user_subscription";
import { useTranslation } from "react-i18next";
import { getUser } from "redux/actions/user";
import { toastAlert } from "components/toastAlert/toastAlert";
import ThankYouModal from "components/ThankYouModal/ThankYouModal";
import { SkeletonTheme } from "react-loading-skeleton";
import checkErr from "redux/actions/api_error_func";
import ErrorModal from "components/ErrorModal/ErrorModal";
import Switch from "rc-switch";
import SubscribeModal from "../SubscribeModal/SubscribeModal.jsx";
import axios from "axios";
import close from "assets/img/close.png";
import PlansComponent from "./PlansComponent.jsx";
import SurePop from "../SurePop.jsx";
import TextButton from "components/textbutton/textbutton.tsx";
import CheckoutModal from "../Checkout/CheckoutModal.jsx";
import LoadingPopUp from "components/popUps/LoadingPopUp";
import Skeletons from "./Skeletons.jsx";
import Downgrade from "../Downgrade/Downgrade.jsx";
import DowngradePopUp from "../Downgrade/DowngradePopUp.jsx";
import "react-loading-skeleton/dist/skeleton.css";
import "rc-switch/assets/index.css";
import "./Plan.scss";
import moment from "moment";
import ThankYouModalPayPal from "components/ThankYouModal/ThankYouModalPayPal.jsx";

const Plan = ({ isaPOPUP, setShowPop }) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const userSubscription = useSelector((state) => state.userSubscription);
  const userEnterprise = useSelector((state) => state.enterprise);
  const [showModal, setShowModal] = useState(false);
  const [showDownGrade, setShowDownGrade] = useState(false);
  const [showTyModal, setShowTyModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [bigLoading, setBigLoading] = useState(false);
  const [showDown, setShowDown] = useState(false);
  const [planName, setPlanName] = useState("");
  const [plans, setPlans] = useState([]);
  const [showModalPayPal, setShowModalPayPal] = useState(false);
  const [message, setMessage] = useState("");
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [plansLoading, setPlansLoading] = useState(true);
  const [loading, setLoading] = useState(categoriesLoading || plansLoading);
  const [plansCategories, setPlansCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isYearly, setYearly] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState(1);
  const [deletedUsers, setDeletedUsers] = useState([]);
  const [editType, setEditType] = useState("");
  const [couponModel, setCouponModel] = useState(false);
  const [selectedCouponPlan, setSelectedCouponPlan] = useState({});
  const [width, setWidth] = useState(window.innerWidth);
  const [removeUserModal, setRemoveUserModal] = useState(false);
  const [userEnterpriseArray, setUserEnterpriseArray] = useState(userEnterprise?.users);
  const [removeUser, setRemoveUser] = useState(userEnterpriseArray?.length - selectedNumber);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [showSelectCountry, setShowSelectCountry] = useState(false);
  const [checkoutId, setCheckoutId] = useState(null);
  const [coupon, setCoupon] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [allCountries, setAllCountries] = useState([]);
  const [lastPayment, setLastPayment] = useState("");
  const [planDetails, setPlanDetails] = useState(null);
  const [selectedPlanPayPal, setSelectedPlanPayPal] = useState("");

  useEffect(() => {
    setUserEnterpriseArray(userEnterprise?.users);
    if (planDetails?.name === "Freelancer") {
      setRemoveUser(userEnterpriseArray?.length);
    } else {
      setRemoveUser(userEnterpriseArray?.length + 1 - selectedNumber);
    }
  }, [selectedNumber, userEnterpriseArray, planDetails]);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const getAllPlans = async (selectedCategory) => {
    setPlansLoading(true);
    let response = await fetch(`${baseUrl}plan/user/getAll?active=true&category_id=${selectedCategory}&is_mobile=false`, {
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("sess"),
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        if (data.success) {
          if (selectedCategory === "6481e68a6e1c694658cfbad6") {
            // development 645e432bfb1969cef8b92733
            // production 6481e68a6e1c694658cfbad6
            setPlans(data.products);
            setPlansLoading(false);
            return;
          } else {
            setPlans(data.products.sort((a, b) => a.monthly?.unit_amount - b.monthly?.unit_amount));
            setPlansLoading(false);
          }
        }
      })
      .catch((error) => {
        checkErr(error, setPlansLoading, toastAlert);
      });
  };

  useEffect(() => {
    setLoading(categoriesLoading || plansLoading);
  }, [categoriesLoading, plansLoading, selectedPlan]);

  useEffect(() => {
    if (selectedCategory) {
      getAllPlans(selectedCategory);
    }
  }, [selectedCategory]);

  const claimed = async () => {
    var config = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}auth/offer_claimed`,
      headers: { Authorization: localStorage.getItem("sess") },
    };
    await axios(config)
      .then(async (response) => {
        setMessage("Successfully Subscribed");
        await updatePlan();
      })
      .catch(function (error) {
        checkErr(error, setShowModal, toastAlert);
      });
  };
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Successfully Subscribed");
    }

    if (query.get("canceled")) {
      setMessage("Subscription was canceled due to payment error");
      if (localStorage.getItem("planId") !== null) {
        localStorage.removeItem("planId");
        setShowErrorModal(true);
        setLoading(false);
        toastAlert(message, undefined, "warning");
        // checkErr(message, setLoading, toastAlert);
      } else {
        setLoading(false);
        toastAlert("Subscription was canceled due to payment error", undefined, "warning");
        // checkErr(error, setLoading, toastAlert);
      }
      navigate("/plan");
    }
  }, []);

  useEffect(() => {
    getActivePlan();
    getUserCurrentSubscription();
  }, []);

  const getUserCurrentSubscription = async () => {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}subscription/get/user`,
    };
    axios(config)
      .then(function (response) {
        setLastPayment(response?.data?.subscription);
      })
      .catch(function (error) {
        checkErr(error, setLoading, toastAlert);
      });
  };

  const getActivePlan = async () => {
    try {
      var config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl}plans/category/get?is_active=true`,
      };
      const response = await axios(config);
      const responseData = response?.data?.categories;
      setPlansCategories(responseData?.reverse());
      if (user?.enterprise) {
        setSelectedCategory(responseData?.slice(0)?.reverse().shift()?._id);
        setCategoriesLoading(false);
      } else {
        setSelectedCategory(responseData?.slice(0)?.shift()?._id);
        setCategoriesLoading(false);
      }
    } catch (error) {
      setSelectedCategory("");
      setCategoriesLoading(false);
    }
  };

  const firstSubscription = (plan) => {
    setSelectedCouponPlan(plan);
    setSelectedPlan(plan);
    setCouponModel(true);
  };

  const leaveOrg = () => {
    setBigLoading(true);
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_ENTERPRISE_URL}enterprise/leave_org`,
      headers: {
        Authorization: localStorage.getItem("sess"),
      },
    };

    axios(config)
      .then(async function (response) {
        setBigLoading(false);
        await dispatch(getUser("enterprise"));
        await dispatch(getUserSubscription());
        toastAlert("Done!", undefined, "success");
      })
      .catch(function (error) {
        setBigLoading(false);
        checkErr(error, setBigLoading, toastAlert);
      });
  };
  const end_date = moment(lastPayment?.end_date)?.format("DD-MM-YYYY");
  return (
    <div
      style={{
        position: "relative",
        minHeight: isaPOPUP ? "unset" : null,
        fontFamily: lng === "arab" ? "Tajawal" : "Poppins",
      }}
      className="plan-type"
    >
      {bigLoading ? <LoadingPopUp gptLoading={true} /> : null}
      <div>
        <div className="Pricing_head">
          {width >= 500 && <h1>{t("Pricing")}</h1>}
          <h4>{t("tailored plans to fit businesses of all sizes")}</h4>
        </div>
        <div className="toggle_button_plans">
          {plansCategories?.map((item, i) => (
            <button
              key={i}
              onClick={() => {
                setSelectedCategory(item?._id);
                setYearly(false);
                if (item?.name_en === "Business") {
                  setSelectedNumber(5);
                } else {
                  setSelectedNumber(1);
                }
              }}
              disabled={categoriesLoading || loading}
              className={selectedCategory === item?._id ? "toggle_button_plans_1" : "toggle_button_plans_2"}
              style={{ margin: "0px" }}
            >
              {lng === "arab" ? item?.name_ar : item?.name_en}
            </button>
          ))}
        </div>
        {/* {selectedCategory === plansCategories?.slice(0)?.reverse().shift()?._id ? (
          <div style={{ minHeight: "90px" }} />
        ) : ( */}
        <div className="save-10-head">
          <h4 onClick={() => setYearly(false)} style={{ cursor: "pointer", margin: isaPOPUP ? "0" : null }}>
            {t("Monthly")}
          </h4>
          <Switch
            checked={!isYearly}
            onChange={(e) => setYearly(!isYearly)}
            style={{
              margin: "35px 10px 35px 10px",
              direction: "ltr",
              transform: "rotate(180deg)",
            }}
          />
          <h4 onClick={() => setYearly(true)} style={{ cursor: "pointer", margin: isaPOPUP ? "0" : null }}>
            {t("Annual")}
            <span className="save10">{t("Save 10%")} </span>
          </h4>
        </div>
        {/* )} */}
      </div>

      {isaPOPUP ? (
        <img
          src={close}
          width={20}
          onClick={() => setShowPop(false)}
          style={{
            position: "absolute",
            top: "20px",
            cursor: "pointer",
            right: lng === "arab" ? "" : "20px",
            left: lng === "arab" ? "20px" : "",
          }}
        />
      ) : null}

      <div className="skeleton_plans">
        {plansLoading ? (
          <SkeletonTheme baseColor="#d3d3d3" highlightColor="#f3f6f4">
            <Skeletons />
          </SkeletonTheme>
        ) : (
          <div className="plans_component">
            {plans.map((plan, i) => (
              <PlansComponent
                allPlans={plans}
                key={i}
                plan={plan}
                width={width}
                isYearly={isYearly}
                price={isYearly ? plan.yearly && plan.yearly.unit_amount / 100 : plan.monthly && plan.monthly.unit_amount / 100}
                tiers={isYearly ? plan?.yearly?.tiers : plan?.monthly?.tiers}
                isBusiness={selectedCategory === plansCategories?.slice(0)?.reverse().shift()?._id}
                setSelectedNumber={setSelectedNumber}
                isaPOPUP={isaPOPUP}
                features={lng === "arab" ? plan?.features?.map((item) => item) : plan?.features?.map((item) => item)}
                isPro={i === plans?.length - 1}
                btnText={userSubscription?.product?.name ?? user?.plan}
                // additionalFeatures={plan?.}
                myPlanId={isYearly ? plan.yearly && plan.yearly.id : plan.monthly && plan.monthly.id}
                unitAMount={isYearly ? plan?.yearly?.unit_amount : plan?.monthly?.unit_amount}
                thisPlanId={isYearly ? plan.yearly && plan.yearly.id : plan.monthly && plan.monthly.id}
                setTotalPrice={setTotalPrice}
                editType={editType}
                lastPayment={lastPayment}
                setEditType={setEditType}
                handleSubscription={() => {
                  setPlanDetails(plan);
                  if (user?.enterprise) {
                    if (userEnterprise?.enterprise?.owner !== user?._id) {
                      return setRemoveUserModal(true);
                    } else {
                      if (selectedNumber < userEnterprise?.users.length + 1) {
                        setPlanName(plan.name);
                        setSelectedPlanPayPal(isYearly ? plan.product : plan.product);
                        setSelectedPlan(isYearly ? plan.yearly && plan.yearly.id : plan.monthly && plan.monthly.id);
                        return setShowDown(true);
                      } else {
                        setPlanName(plan.name);
                        setSelectedPlanPayPal(isYearly ? plan.product : plan.product);
                        setSelectedPlan(isYearly ? plan.yearly && plan.yearly.id : plan.monthly && plan.monthly.id);
                        return setShowSubscribeModal(true);
                      }
                    }
                  } else {
                    if (user.plan !== "Trial") {
                      setPlanName(plan.name);
                      setSelectedPlanPayPal(isYearly ? plan.product : plan.product);
                      setSelectedPlan(isYearly ? plan.yearly && plan.yearly.id : plan.monthly && plan.monthly.id);
                      return setShowSubscribeModal(true);
                    } else {
                      setPlanName(plan.name);
                      setSelectedPlanPayPal(isYearly ? plan.product : plan.product);
                      setSelectedPlan(isYearly ? plan.yearly && plan.yearly.id : plan.monthly && plan.monthly.id);
                      setSelectedCouponPlan(plan);
                      user.plan !== selectedPlan && user.plan !== "Trial" && selectedCategory !== plansCategories?.slice(0)?.reverse().shift()?._id
                        ? setShowSubscribeModal(true)
                        : firstSubscription(plan);
                    }
                  }
                }}
              />
            ))}
          </div>
        )}

        <Col style={{ justifyContent: "center", padding: "10px" }}>
          <p>{t("Need More?")}</p>
          <div className="d-flex justify-content-center">
            <Link to="/contactus">
              <TextButton
                fontWeight={600}
                text={t("Contact Us")}
                backgroundColor={`transparent linear-gradient(to right,rgba(216, 81, 239, 1),rgba(146, 64, 202, 1),rgba(24, 119, 242, 1),rgba(24, 119, 242, 1),rgba(146, 64, 202, 1),rgba(216, 81, 239, 1)) no-repeat 0% 0% `}
                hoverBackgroundColor={`transparent linear-gradient(to right,rgba(216, 81, 239, 1),rgba(146, 64, 202, 1),rgba(24, 119, 242, 1),rgba(24, 119, 242, 1),rgba(146, 64, 202, 1),rgba(216, 81, 239, 1)) no-repeat  100% 0%`}
                textColor={"white"}
                padding={"0.5em 2em"}
                borderRadius={"8px"}
              />
            </Link>
          </div>
        </Col>
        <SubscribeModal
          setIsOpen={setCouponModel}
          selectedPlanPayPal={selectedPlanPayPal}
          setShowModalPayPal={setShowModalPayPal}
          setShowTyModal={setShowTyModal}
          setSelectedNumber={setSelectedNumber}
          isYearly={isYearly}
          lastPayment={lastPayment}
          editType={editType}
          isBusiness={selectedCategory === plansCategories?.slice(0)?.reverse().shift()?._id}
          setShowModal={setShowSubscribeModal}
          showModal={showSubscribeModal}
          subscribe_id={selectedPlan}
          planDetails={planDetails}
          selectedNumber={selectedNumber}
          price={
            totalPrice ? totalPrice : isYearly ? selectedCouponPlan.yearly && selectedCouponPlan.yearly.unit_amount / 100 : selectedCouponPlan.monthly && selectedCouponPlan.monthly.unit_amount / 100
          }
        />
        <DowngradePopUp showModal={showDown} setShowModal={setShowDown} showDown={setShowDownGrade} lastPayment={lastPayment} />

        <Downgrade
          setEditType={setEditType}
          setShowModalPayPal={setShowModalPayPal}
          setIsOpen={setCouponModel}
          selectedPlanPayPal={selectedPlanPayPal}
          setDeletedUsers={setDeletedUsers}
          deletedUsers={deletedUsers}
          setShowTyModal={setShowTyModal}
          userEnterpriseArray={userEnterpriseArray}
          setUserEnterpriseArray={setUserEnterpriseArray}
          removeUser={removeUser}
          setRemoveUser={setRemoveUser}
          show={showDownGrade}
          lastPayment={lastPayment}
          setShowDownGrade={setShowDownGrade}
          editType={editType}
          isYearly={isYearly}
          isBusiness={selectedCategory === plansCategories?.slice(0)?.reverse().shift()?._id}
          subscribe_id={selectedPlan}
          planDetails={planDetails}
          selectedNumber={selectedNumber}
          price={
            totalPrice ? totalPrice : isYearly ? selectedCouponPlan.yearly && selectedCouponPlan.yearly.unit_amount / 100 : selectedCouponPlan.monthly && selectedCouponPlan.monthly.unit_amount / 100
          }
        />
        <ThankYouModal setShowModal={setShowTyModal} showModal={showTyModal} />
        <ThankYouModalPayPal setShowModal={setShowModalPayPal} showModal={showModalPayPal} />
        <SurePop show={removeUserModal} onHide={() => setRemoveUserModal(false)} onsuccess={leaveOrg} />
        <ErrorModal setShowModal={setShowErrorModal} showModal={showErrorModal} />
        <CheckoutModal
          setShowModal={setShowSubscribeModal}
          setShowModalPayPal={setShowModalPayPal}
          setShowTyModal={setShowTyModal}
          selectedPlanPayPal={selectedPlanPayPal}
          setSelectedNumber={setSelectedNumber}
          selectedNumber={selectedNumber}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          setAllCountries={setAllCountries}
          allCountries={allCountries}
          setCheckoutId={setCheckoutId}
          checkoutId={checkoutId}
          centered
          show={couponModel}
          backdrop={false}
          keyboard={false}
          setTotalPrice={setTotalPrice}
          setIsOpen={setCouponModel}
          setShowSelectCountry={setShowSelectCountry}
          setCoupon={setCoupon}
          coupon={coupon}
          selectedPlan={selectedPlan}
          isBusiness={selectedCategory === plansCategories?.slice(0)?.reverse().shift()?._id}
          price={
            totalPrice ? totalPrice : isYearly ? selectedCouponPlan.yearly && selectedCouponPlan.yearly.unit_amount / 100 : selectedCouponPlan.monthly && selectedCouponPlan.monthly.unit_amount / 100
          }
          name={selectedCouponPlan?.name}
          isYearly={isYearly}
          id={isYearly ? selectedCouponPlan.yearly && selectedCouponPlan.yearly.id : selectedCouponPlan.monthly && selectedCouponPlan.monthly.id}
        />
      </div>
    </div>
  );
};
export default Plan;
