import React, { useState, useEffect } from "react"

const TextAnimation = ({content, style, className, onClick, speed, setScrollSimulation, scrollSimulation, setRunning}) => {
    const [text, setText] = useState("")
    const [fullText, setFullText] = useState(content)
    const [index, setIndex] = useState(0)
    useEffect(() => {
        if (index < fullText.length) {
          if (setRunning) {
            setRunning(true)
          }
          
          setTimeout(() => {
            
            setText(text + fullText[index])
            setIndex(index + 1)
            if ((scrollSimulation || scrollSimulation === 0) && setScrollSimulation) {
              setScrollSimulation(scrollSimulation + 1)
            }
            
          }, (speed || 40))
        } else {
          if (setRunning && content) {
            setRunning(false)
          }
        }
    }, [index])
  return (
    <label onClick={onClick} style={style || null} className={className || ''}>{text}</label>
  )
}

export default TextAnimation