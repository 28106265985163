import { baseUrl } from "api/baseUrl";
import checkErr from "redux/actions/api_error_func";
import { toastAlert } from "components/toastAlert/toastAlert";
import axios from "axios";

export const createCryptoCheckout = async (selectedPlan, price, selectedNumber) => {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}coin-base/charge/create`,
    headers: {
      Authorization: localStorage.getItem("sess"),
      "Content-Type": "application/json",
    },
    data: {
      planId: selectedPlan?.product?._id,
      amount: price,
      quantity: selectedNumber,
    },
  };
  axios(config)
    .then(function (response) {
      if (response.data.success) {
        window.open(response.data.data.hosted_url, "_self");
      }
    })
    .catch(function (error) {
      checkErr(error, undefined, toastAlert);
    });
};
export const changeCryptoSubscription = async (selectedPlan, editType, selectedNumber, price, deletedUser) => {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}coin-base/subscription/change`,
    headers: {
      Authorization: localStorage.getItem("sess"),
      "Content-Type": "application/json",
    },
    data: {
      planId: selectedPlan?.product?._id,
      amount: price,
      quantity: selectedNumber ? selectedNumber : 1,
      editType: editType,
      deletedUsers: deletedUser,
    },
  };
  axios(config)
    .then(function (response) {
      if (response.data.success) {
        window.open(response.data.data.hosted_url, "_self");
      }
      // window.location.reload();
    })
    .catch(function (error) {
      checkErr(error, undefined, toastAlert);
    });
};
