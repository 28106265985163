
import {
    FETCH_TEXT_SPEECH,
    LOAD_TEXT_SPEECH,
    LOADED_TEXT_SPEECH,
    NEW_TEXT_SPEECH,
    SHOW_TEXT_SPEECH_FALSE,
    SHOW_TEXT_SPEECH_TRUE
} from "redux/contstants";

export default (textToSpeech = { textToSpeech: [], uploaded: [], loading: false, showRemover: false }, action) => {
    switch (action.type) {
        case LOAD_TEXT_SPEECH:
            return { ...textToSpeech, loading: true, showRemover: true };
        case FETCH_TEXT_SPEECH:
            return { ...textToSpeech, textToSpeech: action.data };
        case LOADED_TEXT_SPEECH:
            return { ...textToSpeech, loading: false };
        case NEW_TEXT_SPEECH:
            return { ...textToSpeech, textToSpeech: [], uploaded: [], showRemover: false };
        case SHOW_TEXT_SPEECH_FALSE:
            return { ...textToSpeech, showRemover: false };
        case SHOW_TEXT_SPEECH_TRUE:
            return { ...textToSpeech, showRemover: true };
        default:
            return textToSpeech;
    }
};