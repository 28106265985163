import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import lightCheck from "assets/img/newDesign/verified.png";
import blackCheck from "assets/img/newDesign/badge-checkblack.png";
import { Slider } from "@mui/material";

const PlansComponent = ({
  keys,
  allPlans,
  plan,
  isYearly,
  tiers,
  price,
  features,
  isBusiness,
  btnText,
  myPlanId,
  thisPlanId,
  isPro,
  handleSubscription,
  setSelectedNumber,
  setTotalPrice,
  setEditType,
  lastPayment,
  editType,
  width,
}) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const user = useSelector((state) => state.user);
  const userEnterprise = useSelector((state) => state.enterprise);
  const userSubscription = useSelector((state) => state.userSubscription);
  const [customPrice, setCustomPrice] = useState(5);
  const [customMarks, setCustomMarks] = useState([]);
  const [dynamicButtonText, setDynamicButtonText] = useState("Current");
  const [dynamicSubsText, setDynamicSubsText] = useState("");
  const [isDisable, setIsDisable] = useState(true);

  useEffect(() => {
    const price = t(btnText) === t(plan.name) && myPlanId === thisPlanId ? userEnterprise?.enterprise?.max_users : null;
    if (price < customPrice) {
      setIsDisable(false);
      setDynamicButtonText("upgrade");
      setEditType("upgrade");
    } else if (customPrice === price) {
      setDynamicButtonText("Current");
      setIsDisable(true);
    } else {
      setIsDisable(false);
      setDynamicButtonText("downgrade");
      setEditType("downgrade");
    }
  }, [customPrice]);

  useEffect(() => {
    const users = t(btnText) === t(plan.name) && myPlanId === thisPlanId ? userEnterprise?.enterprise?.max_users : 5;
    setCustomPrice(users);

    if (isBusiness) {
      let tiersPrice = 0;
      if (plan.name === "Freelancer") {
        tiersPrice = plan?.monthly?.unit_amount / 10;
      } else {
        let firstTier = tiers?.map((item) => item?.unit_amount);
        tiersPrice = firstTier[0];
      }
      let myCurrentPlan = allPlans?.filter((item) => item?.product?._id === lastPayment?.product_id?._id)?.shift();
      let myPlan = myCurrentPlan?.name === "Freelancer" ? myCurrentPlan?.monthly?.unit_amount / 10 : myCurrentPlan?.monthly?.tiers[0]?.unit_amount;
      if (myPlan >= tiersPrice) {
        setDynamicSubsText("downgrade");
      } else if (myPlan <= tiersPrice) {
        setDynamicSubsText("upgrade");
      } else {
        setDynamicSubsText("upgrade");
      }
    } else {
      let planPrice = userSubscription.amount / 100;
      let currentPrice = lastPayment?.last_transaction_id?.total_amount;
      if (userSubscription.interval === "year") {
        planPrice = Math.round(planPrice / 12);
        currentPrice = Math.round(currentPrice / 12);
      }
      if (currentPrice > price || planPrice > price) {
        setDynamicSubsText("downgrade");
      } else if (currentPrice < price || planPrice / 100 < price) {
        setDynamicSubsText("upgrade");
      } else {
        setDynamicSubsText("upgrade");
      }
    }
  }, []);

  useEffect(() => {
    let arr = tiers
      ?.map((item) => {
        return { value: item?.up_to, label: item?.up_to };
      })
      ?.filter((item) => item?.value);
    arr?.unshift({ value: 1, label: 1 });
    setCustomMarks(arr);
  }, [tiers]);

  const detectPrice = (numberOfUSers) => {
    return (
      (tiers
        ?.filter((item) => numberOfUSers <= item?.up_to)
        ?.slice(0)
        ?.shift()?.unit_amount /
        100) *
      numberOfUSers
    ).toFixed(0);
  };

  return (
    <div className="col-xl-3 col-lg-6 col-sm-10" style={{ minWidth: "300px", maxWidth: "400px" }}>
      <div
        onClick={(t(btnText) === t(plan.name) && myPlanId === thisPlanId) || isBusiness || user?.enterprise ? null : handleSubscription}
        style={{ boxShadow: isPro && " 0px 0px 21px -3px rgba(114, 57, 235, 0.43)" }}
        className="newPlanCard plans_card "
      >
        <div className="upper_info">
          <div className="pop_wrapper">
            <div
              // style={{ background: isPro && "linear-gradient(90deg, #D852F0 0%, #1A77F3 100%)", color: isPro && "white" }}
              className="plan_name"
            >
              <h6>{t(plan.name ?? plan?.product?.name)}</h6>
            </div>
            {keys === 1 && (
              <div className="plan_name popular">
                <label>{t("Popular")}</label>
              </div>
            )}
          </div>
        </div>
        {/* <hr style={{ backgroundColor: "#fff", margin: isaPOPUP ? "5px 0" : null }} /> */}
        <div className="priceWrapper">
          {/* {!isBusiness && ( */}
          <p style={{ textAlign: lng === "arab" ? "right" : "left", fontSize: width < 991 ? "1.3rem" : "1rem", minHeight: "48.21px", marginBottom: "0" }}>
            {lng === "arab" ? plan?.product?.desc_ar : plan?.product?.desc_en}
          </p>
          {/* )} */}
          <h1 className="price">
            {plan?.product.name === "Trial" ? (
              <span>{t("FREE")}</span>
            ) : isBusiness && tiers?.length ? (
              "$" + detectPrice(customPrice)
            ) : (
              "$" + ((isYearly ? (Math.round(price * 100) / 100).toLocaleString() : price) || 0)
            )}
          </h1>
          {<label>{t(`${isYearly ? "Yearly" : "Monthly"}`)}</label>}
          {/* <hr style={{ margin: isaPOPUP ? "5px 0" : null }} /> */}
        </div>

        {/* <hr style={{ margin: isaPOPUP ? "5px 0" : null }} /> */}
        {plan?.product?.name === "Trial" ? (
          <button disabled className="subs_button" style={{ background: "linear-gradient(225deg, #C5C5C5 0%, #CCC 100%)", border: "1px solid #D7D7D7", color: "#fff" }}>
            {t("Free")}
          </button>
        ) : !isBusiness && userEnterprise?.enterprise?.owner === user?._id && user?.plan ? (
          <button disabled className="subs_button" style={{ background: "#ffffff", border: "1px solid #D7D7D7", color: "#ACACAC" }}>
            {t("Business Account")}
          </button>
        ) : user?.enterprise && userEnterprise?.enterprise?.owner !== user?._id ? (
          <button onClick={handleSubscription} className="subs_button">
            {t("Leave Organisation")}
          </button>
        ) : user.plan === "Trial" ? (
          <button
            onClick={() => {
              handleSubscription();
              if (isBusiness) {
                if (plan?.name === "Freelancer") {
                  setSelectedNumber(1);
                } else {
                  setSelectedNumber(customPrice);
                }
                if (plan?.name === "Freelancer") {
                  setTotalPrice(price);
                } else {
                  setTotalPrice(
                    (
                      (tiers
                        ?.filter((item) => customPrice <= item?.up_to)
                        ?.slice(0)
                        ?.shift()?.unit_amount /
                        100) *
                      customPrice
                    ).toFixed(0)
                  );
                }
              }
            }}
            className="subs_button"
          >
            {t("Subscribe Now")}
          </button>
        ) : t(btnText) === t(plan.name) && myPlanId === thisPlanId ? (
          <button
            disabled={isDisable}
            onClick={() => {
              setEditType(dynamicButtonText);
              if (isBusiness) {
                if (plan?.name === "Freelancer") {
                  setSelectedNumber(1);
                } else {
                  setSelectedNumber(customPrice);
                }
                if (plan?.name === "Freelancer") {
                  setTotalPrice(price);
                } else {
                  setTotalPrice(
                    (
                      (tiers
                        ?.filter((item) => customPrice <= item?.up_to)
                        ?.slice(0)
                        ?.shift()?.unit_amount /
                        100) *
                      customPrice
                    ).toFixed(0)
                  );
                }
              }
              handleSubscription();
            }}
            style={{ textTransform: "capitalize", background: isDisable ? "#ffffff" : null, border: isDisable ? "1px solid #D7D7D7" : null, color: isDisable ? "#ACACAC" : null }}
            className={isPro ? "sub_pro_button" : "subs_button"}
          >
            {t(dynamicButtonText) + " " + t("Plan")}
          </button>
        ) : (
          <button
            className={isPro ? "sub_pro_button" : "subs_button"}
            style={{ textTransform: "capitalize" }}
            onClick={() => {
              setEditType(dynamicSubsText);
              if (isBusiness) {
                if (plan?.name === "Freelancer") {
                  setSelectedNumber(1);
                } else {
                  setSelectedNumber(customPrice);
                }
                setTotalPrice(
                  (
                    (tiers
                      ?.filter((item) => customPrice <= item?.up_to)
                      ?.slice(0)
                      ?.shift()?.unit_amount /
                      100) *
                    customPrice
                  ).toFixed(0)
                );
              }
              handleSubscription();
            }}
          >
            {t(dynamicSubsText) + " " + t("Plan")}
          </button>
        )}
        {isBusiness && tiers?.length ? (
          <div style={{ width: "100%", padding: "10px" }}>
            <Slider
              value={customPrice}
              name="company_size"
              aria-label="company_size"
              step={1}
              marks={customMarks}
              min={5}
              // valueLabelDisplay={customPrice > 5 ? "on" : null}
              max={customMarks?.length ? Math.max(...customMarks?.map((item) => item?.value)) : 5}
              onChange={(e) => {
                setCustomPrice(e.target.value);
                setSelectedNumber(e.target.value);
              }}
            />
            {isBusiness && (
              <b style={{ textAlign: "center", display: "block" }}>
                {customPrice} <span style={{ color: "grey" }}>{t("Users")}</span>
              </b>
            )}
          </div>
        ) : null}
        <b style={{ fontSize: width < 991 ? "1.5rem" : "0.9rem", textAlign: lng === "arab" ? "right" : "left" }}>
          {lng === "arab" ? plan?.product?.features_desc_ar : plan?.product?.features_desc_en}
        </b>
        <ul className="plan_details">
          {plan?.product?.features.map((item, i) => (
            <li key={i}>
              <img src={item?.icon} style={{ height: "15px", width: "15px" }} alt="check_mark" />
              {item === "ArabyGPT" ? (
                <h6 className="araby-gpt-plan-text">Twwt GPT</h6>
              ) : item === "Unlock Business Tools" || item === "Unlock Business Tools per user" || item === "افتح أدوات العمل" || item === "افتح أدوات العمل لكل مستخدم" ? (
                <h6 className="business-plan-text" style={{ fontWeight: lng === "arab" ? 900 : null }}>
                  {item === "Unlock Business Tools" || item === "افتح أدوات العمل" ? t("Unlock Business Tools") : t("Unlock Business Tools per user")}
                </h6>
              ) : (
                <span
                  style={{
                    textAlign: lng === "arab" ? "right" : "left",
                    direction: lng === "arab" ? "rtl" : "ltr",
                  }}
                >
                  {lng === "arab" ? item?.feat_ar : item?.feat_en}
                </span>
              )}
            </li>
          )) ??
            features?.map((item, i) => (
              <li key={i}>
                <img src={item?.icon} style={{ height: "15px", width: "15px" }} alt="check_mark" />
                {item === "ArabyGPT" ? (
                  <h6 className="araby-gpt-plan-text">Twwt GPT</h6>
                ) : item === "Unlock Business Tools" || item === "Unlock Business Tools per user" || item === "افتح أدوات العمل" || item === "افتح أدوات العمل لكل مستخدم" ? (
                  <h6 className="business-plan-text" style={{ fontWeight: lng === "arab" ? 900 : null }}>
                    {item === "Unlock Business Tools" || item === "افتح أدوات العمل" ? t("Unlock Business Tools") : t("Unlock Business Tools per user")}
                  </h6>
                ) : (
                  <span
                    style={{
                      textAlign: lng === "arab" ? "right" : "left",
                      direction: lng === "arab" ? "rtl" : "ltr",
                    }}
                  >
                    {lng === "arab" ? item?.feat_ar : item?.feat_en}
                  </span>
                )}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default PlansComponent;
