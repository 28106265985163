import React, { useState } from "react";
import girl from "../assets/img/footer/backToScholl.png";
import logo from "../assets/img/footer/backTo.png";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import { useNavigate } from "react-router-dom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const BackToSchool = ({ width, t, lng }) => {
  const [close, setClose] = useState(true);
  const navigate = useNavigate();
  return (
    <>
      {close && (
        <div className="back_to_school">
          {width > 991 && (
            <span className="close_button" onClick={() => setClose(false)} style={{ left: lng === "arab" ? "15px" : null, right: lng === "arab" ? null : "15px" }}>
              <CloseRoundedIcon sx={{ fontSize: "20px" }} />
            </span>
          )}
          {width > 991 ? (
            <img
              className="image_back_to_school"
              style={{ right: lng === "arab" ? 0 : null, left: lng === "arab" ? null : 0, transform: lng === "arab" ? "scaleX(-1)" : null }}
              src={girl}
              alt="women reading book"
            />
          ) : null}
          <h1>
            {t("Back to ")}
            <span>{t("school")}</span>
          </h1>

          {width > 991 ? (
            <>
              <h4>{t("with twwt ai")}</h4> <img src={logo} width="120px" alt="logo" />
            </>
          ) : null}
          <h2>
            20%
            <span>{t("  off")}</span>
            <h5>{t("Use code ")}: School20</h5>
          </h2>
          <button style={{ margin: lng == "arab" ? "0 60px 0 0" : "0 0 0 60px" }} onClick={() => navigate("/plan")}>
            {width > 991 && <>{t("Go to")} </>}
            {t("Plans")} <EastRoundedIcon sx={{ fontSize: "20px", transform: lng === "arab" ? "scaleX(-1)" : null }} />
          </button>
          {width < 991 && <CloseRoundedIcon onClick={() => setClose(false)} sx={{ fontSize: "20px" }} />}
        </div>
      )}
    </>
  );
};

export default BackToSchool;
