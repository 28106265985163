import React, { useState, useEffect } from "react";
import "views/MediaSection/ImageTools/Create/ImgGen.css";
import dragPhoto from "assets/img/newDesign/cloud.svg";
import dragHover from "assets/img/drag-img-hover.png";
import "font-awesome/css/font-awesome.min.css";
import { toastAlert } from "components/toastAlert/toastAlert";
import { useTranslation } from "react-i18next";
import UpgradeModal from "components/UpgradeModal/UpgradeModal";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
// import "views/MediaSection/ImageTools/BackgroundRemover/animation.scss";
import UpgradePlanModal from "components/UpgradeModal/UpgradePlanModal";
import { handleEnhanceAudio } from "redux/actions/media_section";
import enhanceAudioImage from "assets/img/newDesign/play-music 1.png";
import Loading_v2 from "components/Loading/Laoadingv2/Loadingv2";
import ReplayIcon from "@mui/icons-material/Replay";
import { handleEnhanceAudioLoading } from "redux/actions/media_section";
import FileContainer from "components/MediaSection/FileContainer";

const EnhanceAudio = () => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [audioType, setAudioType] = useState("conference");
  const [totalImages, setTotalImages] = useState(0);
  const user = useSelector((state) => state.user);
  const enhanceAudio = useSelector((state) => state.enhance_audio);
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const [show, setShow] = useState(false);
  const [loadingText, setLoadingText] = useState("Uploading Your Audio");

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const [errors, setError] = useState("");
  let error_valid = lng === "arab" ? "" : "Only MP3, WAV, and MPEG audio files are allowed";

  useEffect(() => {
    setTotalImages(user?.image_count_left);
    // if (user?.plan === "Trial" || user?.plan === "Starter") {
    //   return setShow(true);
    // }
  }, [user]);
  useEffect(() => {
    if (enhanceAudio?.loading) {
      let intervalTime;
      if (enhanceAudio?.loadingNumber >= 1 && enhanceAudio?.loadingNumber < 25) {
        intervalTime = 3000 / 25; // 5 seconds for 1-25, increment by 1 every 200ms
        setLoadingText("Uploading Your Audio");
      } else if (enhanceAudio?.loadingNumber >= 25 && enhanceAudio?.loadingNumber < 50) {
        intervalTime = 5000 / 25; // 20 seconds for 25-50, increment by 1 every 800ms
        setLoadingText("Analyzing Your Audio");
      } else if (enhanceAudio?.loadingNumber >= 50 && enhanceAudio?.loadingNumber < 75) {
        intervalTime = 10000 / 25; // 30 seconds for 50-75, increment by 1 every 1200ms
        setLoadingText("Enhancing The Audio");
      } else if (enhanceAudio?.loadingNumber >= 75 && enhanceAudio?.loadingNumber < 100) {
        intervalTime = 100000 / 25; // 10 seconds for 50-75, increment by 1 every 1200ms
        setLoadingText("Loading The Audio");
      }
      const timer = setInterval(() => {
        dispatch(handleEnhanceAudioLoading(enhanceAudio?.loadingNumber, "enhance_audio"));
      }, intervalTime);
      return () => {
        clearInterval(timer);
      };
    }
  }, [enhanceAudio?.loadingNumber, enhanceAudio?.loading]);

  const handleAudioUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileType", "audio");
    formData.append("type", audioType);
    if (totalImages === 0) {
      dispatch({ type: "SHOW_ENHANCE_AUDIO_FALSE" });
      return setShowModal(true);
    } else {
      dispatch(handleEnhanceAudio(formData));
    }
  };
  const handleDownload = () => {
    const fileUrl = enhanceAudio?.enhanceAudio?.data?.generated?.urls;
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = "hello"; // Specify the file name here
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    if (totalImages === 0) {
      dispatch({ type: "SHOW_ENHANCE_AUDIO_FALSE" });
      return setShowModal(true);
    } else {
      const file = await e.dataTransfer.files;
      if (file) {
        const allowedTypes = ["audio/mpeg", "audio/wav", "audio/mp3"];
        if (!allowedTypes.includes(file[0]?.type)) {
          toastAlert("Only MP3, WAV, and MPEG audio files are allowed.", undefined, "warning");
        } else if (allowedTypes.includes(file[0].type)) {
          handleAudioUpload(file[0]);
          dispatch({ type: "SHOW_ENHANCE_AUDIO_TRUE" });
          setDragging(false);
          setError();
        } else {
          setError(error_valid);
          toastAlert(error_valid, undefined, "warning");
        }
      } else setUploaded(true);
    }
  };

  const handleImageUpload = async (e) => {
    e.preventDefault();
    if (totalImages === 0) {
      dispatch({ type: "SHOW_ENHANCE_AUDIO_FALSE" });
      return setShowModal(true);
    } else {
      const file = await e.target.files;
      if (file) {
        const allowedTypes = ["audio/mpeg", "audio/wav", "audio/mp3"];
        if (allowedTypes.includes(file[0]?.type)) {
          handleAudioUpload(file[0]);
          dispatch({ type: "SHOW_ENHANCE_AUDIO_TRUE" });
          setDragging(false);
          setError();
        } else {
          toastAlert("Only MP3, WAV, and MPEG audio files are allowed.", undefined, "warning");
        }
      } else setUploaded(true);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  return (
    <>
      {enhanceAudio?.loading ? (
        <div
          style={{
            height: "calc(100vh - 200px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading_v2 loadingText={loadingText} setLoadingText={setLoadingText} loadingNumber={enhanceAudio?.loadingNumber} />
        </div>
      ) : enhanceAudio?.enhanceAudio?.length != 0 ? (
        <div style={{ position: "relative" }}>
          {enhanceAudio?.enhanceAudio?.length != 0 && (
            <>
              <div
                style={{
                  maxWidth: "600px",
                  minHeight: "300px",
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <div className="audio_result">
                  <h4 style={{ margin: "0" }}>{t("Music :")} &nbsp;</h4> <audio src={enhanceAudio?.enhanceAudio?.data?.generated?.urls} controls controlsList="nodownload noplaybackrate" />
                </div>
              </div>
              <div className="beforeAfter-container">
                <div className="beforeafter-gray">
                  <button className="download-btn" onClick={handleDownload}>
                    <i
                      className="fas fa-arrow-down"
                      style={{
                        fontSize: "medium",
                        margin: "0 10px",
                      }}
                    ></i>
                    {t("Download")}
                  </button>

                  <button
                    className="newupload"
                    onClick={() => {
                      dispatch({ type: "NEW_ENHANCE_AUDIO" });
                    }}
                  >
                    <ReplayIcon sx={{ transform: "scale(-1)", margin: "0 10px" }} />
                    {t("Try Again")}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          <div className="image_gen_container">
            <div
              className="image_gen_header"
              style={{
                padding: width < 500 ? "10px" : "20px",
                alignSelf: "flex-start",
              }}
            >
              <h4 style={{ textAlign: lng === "arab" ? "right" : "left" }}>{t("Improving the sound quality of your audio has never been easier with the twwt.Ai Enhance")}</h4>
              <p style={{ textAlign: lng === "arab" ? "right" : "left" }}>{t("We automatically remove background noise and hums, ensure loudness levels are consistent, and reduce mouth clicks")}</p>
              {/* <select
            onChange={(e) => {
              setAudioType(e.target.value);
            }}
            style={{ borderRadius: "5px", padding: "5px" }}
          >
            <option value="conference">{t("Conference")}</option>
            <option value="interview">{t("Interview")}</option>
            <option value="lecture">{t("Lecture Room")}</option>
            <option value="meeting">{t("Meeting")}</option>
            <option value="mobile_phone">{t("Mobile phone")}</option>
            <option value="music">{t("Music")}</option>
            <option value="podcast">{t("Podcast")}</option>
            <option value="studio">{t("Studio")}</option>
            <option value="voice_recording">{t("Voice recording")}</option>
          </select> */}
              {!enhanceAudio.showRemover && (
                <FileContainer
                  handleDrop={handleDrop}
                  handleDragOver={handleDragOver}
                  handleDragLeave={handleDragLeave}
                  handleUpload={handleImageUpload}
                  dragging={dragging}
                  types=".mp3,.wav,.mp4,.mov,.mpeg"
                  support="MP3 and WAV"
                />
              )}

              <UpgradeModal images={true} setShowModal={setShowModal} showModal={showModal} />
              <UpgradePlanModal show={show} setShow={setShow} width={width} />
            </div>
            <div className="right_image_media">
              <img src={enhanceAudioImage} alt="enhanceAudio-example" width={"90%"} height={"90%"} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EnhanceAudio;
