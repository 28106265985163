import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import bottomArrow from "assets/img/left-right.svg";
import { createHyperPayCheckout } from "./Functions/hyperPay";

const CustomDropdown = ({ allCountries, setSelectedCountry, selectedCountry, isBusiness, plans, price, isYearly, setCheckoutId, selectedNumber }) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  const user = useSelector((state) => state.user);
  const [searchInput, setSearchInput] = useState("");
  const [allCountriesFinal, setAllCountriesFinal] = useState([]);
  useOutsideAlerter(wrapperRef);

  useEffect(() => {
    if (searchInput) {
      let arr = allCountries?.slice(0)?.filter((item) => item?.name?.ar.toUpperCase()?.includes(searchInput.toUpperCase()) || item?.name?.en.toUpperCase()?.includes(searchInput.toUpperCase()));
      setAllCountriesFinal(
        arr?.map((item) => {
          return {
            value: item?._id,
            label: lng === "arab" ? item?.name?.ar : item?.name?.en,
            icon: item?.icon?.url,
            PaymentGateWay: item?.paymentGate,
            allowCrypto: item?.allowCrypto,
          };
        })
      );
    } else {
      setAllCountriesFinal(
        allCountries?.map((item) => {
          return {
            value: item?._id,
            label: lng === "arab" ? item?.name?.ar : item?.name?.en,
            icon: item?.icon?.url,
            PaymentGateWay: item?.paymentGate,
            allowCrypto: item?.allowCrypto,
          };
        })
      );
    }
  }, [allCountries, searchInput]);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  return (
    <div className="custom_dropdown_wrapper">
      <div onClick={() => setIsOpen(!isOpen)} className="custom_dropdown_value">
        {selectedCountry?.value ? (
          <div style={{ display: "flex", alignItems: "center", gap: "10px", cursor: "pointer" }} className="selectOption">
            <img src={selectedCountry.icon} style={{ width: 36 }} alt={selectedCountry.label} />
            {selectedCountry.label}
          </div>
        ) : (
          <span>{t("Select Country")}</span>
        )}
        <img style={{ transform: "rotate(90deg)", height: "15px", width: "15px", opacity: isOpen ? "0.5" : null }} src={bottomArrow} alt="arrow" />
      </div>
      {isOpen ? (
        <div ref={wrapperRef} className="bigWrapper">
          <div className="custom_dropdown_list_wrapper">
            <input
              placeholder={t("Search...")}
              className="form-control"
              autoFocus
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
                setCheckoutId("");
              }}
            />
            <ul style={{ display: "flex", flexWrap: "wrap" }}>
              {allCountriesFinal?.length === 0 ? (
                <li>
                  <div style={{ display: "flex", alignItems: "center", gap: "10px", cursor: "pointer", justifyContent: "center", minHeight: "60px" }} className="selectOption">
                    <span style={{ opacity: "0.5" }}>{t("No Countries Available")}</span>
                  </div>
                </li>
              ) : (
                allCountriesFinal?.map((item, i) => (
                  <li key={i} style={{ flex: "50%" }}>
                    <div
                      onClick={() => {
                        if (item.paymentGate !== "hyperPay") {
                          setSelectedCountry(item);
                          setIsOpen(false);
                          return;
                        }
                        if (isBusiness) {
                          setSelectedCountry(item);
                          createHyperPayCheckout(plans, isYearly, price, setCheckoutId, selectedNumber, isBusiness);
                          setIsOpen(false);
                        } else {
                          setSelectedCountry(item);
                          createHyperPayCheckout(plans, isYearly, price, setCheckoutId, isBusiness);
                          setIsOpen(false);
                        }
                      }}
                      style={{ display: "flex", alignItems: "center", gap: "10px", cursor: "pointer" }}
                      className="selectOption"
                    >
                      <img src={item.icon} style={{ width: 36 }} alt={item.label} />
                      {item.label}
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CustomDropdown;
