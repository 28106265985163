import React, { useEffect, useState, useContext } from "react";
import logo from "assets/img/Aai-logo.png";
import close from "assets/img/close.png";
import styles from "./checkout.module.scss";
import Skeleton from "react-loading-skeleton";
import right from "assets/img/right-arrow.png";
import tag from "assets/img/tag.png";
import axios from "axios";
import { baseUrl } from "api/baseUrl";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { OfferTimerContext } from "providers/OfferTimeProvider";
import CustomDropdown from "../CustomDropdown";
import HyperPay from "../HyperPay/HyperPay";
import { Modal } from "react-bootstrap";
import { createStripeCheckout } from "../Functions/stripe";
import { createCryptoCheckout } from "../Functions/crypto";
import Paypal from "../Paypal/Paypal";

const CouponPopUp = ({
  selectedPlanPayPal,
  setIsOpen,
  price,
  coupon,
  setCoupon,
  isYearly,
  id,
  isBusiness,
  show,
  selectedPlan,
  allCountries,
  setAllCountries,
  setSelectedCountry,
  selectedCountry,
  selectedNumber,
  setTotalPrice,
  setShowTyModal,
  setShowModal,
  setShowModalPayPal,
}) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [data, setData] = useState("");
  const [error, setError] = useState(false);
  const [discount, setDiscount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const [checkoutId, setCheckoutId] = useState(null);

  const date = new Date(user?.user_prefernce?.Date);
  var now = new Date().getTime();
  const offerTimer = useContext(OfferTimerContext);
  const time = date - now;

  const checkValid = () => {
    setLoading(true);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}stripe/redeemCoupon`,
      headers: {
        Authorization: localStorage.getItem("sess"),
      },
      data: { code: data },
    };
    axios(config)
      .then(function (response) {
        let data = response.data;
        // Math.round((data.code.coupon.percent_off / 100 * price) * 100) / 100
        setDiscount(((data.code.coupon.percent_off / 100) * price).toFixed(2));
        setCoupon(data.code.id);
        setError(false);
        setLoading(false);
      })
      .catch(function (err) {
        setError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}country/get`,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        setAllCountries(response.data?.countries);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, []);

  const handleChange = (event) => {
    setData(event.target.value);
  };

  const removeCoupon = () => {
    setCoupon("");
    setData("");
    setError(false);
  };
  return (
    <Modal style={{ backgroundColor: "#000000a8" }} centered show={show} backdrop={false} keyboard={false}>
      <div style={{ fontFamily: lng === "arab" ? "Tajawal" : "Poppins" }}>
        <div className={styles.container}>
          <div className={styles.head}>
            <img src={logo} width={36} />
            <h2>{t("TWWT AI")}</h2>
            <img
              src={close}
              width={25}
              className={styles.close}
              onClick={() => {
                setIsOpen(false);
                setCoupon("");
                setDiscount("");
                setData("");
                setSelectedCountry("");
                setTotalPrice("");
                setCheckoutId("");
                setError(false);
              }}
            />
          </div>
        </div>
        <div className={styles.payment_head} style={{ textAlign: lng === "arab" ? "right" : "left" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <h5
                style={{
                  fontSize: "14px",
                  fontWeight: "100",
                  color: "#53626B",
                }}
              >
                {t("Order Details")}:
              </h5>
              <h4 style={{ fontSize: "37px", fontWeight: 500, color: "#1A82DC" }}>{t("Plan") + " " + t(selectedPlan?.name)}</h4>
            </div>
            <div>
              <h3 style={{ fontSize: "35px", fontWeight: "500" }}>{"$" + price}</h3>
              <h6
                style={{
                  fontSize: "12px",
                  color: "#53626B",
                  fontWeight: "400",
                  textTransform: "capitalize",
                  textAlign: lng === "arab" ? "left" : "right",
                }}
              >
                {isYearly ? t("Yearly") : t("Monthly")}
              </h6>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className="paymentQ">
            <label
              className={styles.required}
              style={{
                marginTop: "14px",
                textAlign: lng === "arab" ? "right" : "left",
                width: "100%",
              }}
            >
              {t("Please Select Your Country")}
            </label>

            <CustomDropdown
              isBusiness={isBusiness}
              setCheckoutId={setCheckoutId}
              allCountries={allCountries}
              setSelectedCountry={setSelectedCountry}
              selectedCountry={selectedCountry}
              selectedNumber={selectedNumber}
              plans={selectedPlan}
              isYearly={isYearly}
              price={price}
            />
          </div>
          <div style={{ marginTop: "20px", minHeight: "180px" }}>
            <label
              style={{
                marginTop: "14px",
                textAlign: lng === "arab" ? "right" : "left",
                width: "100%",
              }}
            >
              {t("Coupon")}
            </label>
            <div
              className={styles.input}
              style={{
                borderLeft: error ? "5px red solid" : "",
                display: coupon ? "none" : "flex",
              }}
            >
              <img src={tag} />
              <input placeholder={t("ENTER THE COUPON CODE")} value={data} onChange={handleChange} />
              {data && (
                <>
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <img
                      src={right}
                      width={56}
                      height={36}
                      style={{
                        transform: lng === "arab" ? " rotate(180deg)" : null,
                      }}
                      onClick={checkValid}
                    />
                  )}
                </>
              )}
            </div>
            {error ? (
              <h6
                style={{
                  color: "red",
                  textTransform: "capitalize",
                  fontSize: "0.8rem",
                  textAlign: lng === "arab" ? "right" : null,
                }}
              >
                {t("invalid coupon")}
              </h6>
            ) : null}
            {coupon ? (
              <div className={styles.valid}>
                <div
                  style={{
                    display: "flex",
                    gap: "50px",
                    float: "right",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h4>{coupon?.code}</h4>
                    <span
                      style={{
                        color: "#0E92FC",
                        textAlign: lng === "arab" ? "left" : null,
                        cursor: "pointer",
                      }}
                      onClick={removeCoupon}
                    >
                      {t("remove")}
                    </span>
                  </div>
                  <h5 style={{ fontSize: "1.25rem" }}>- ${discount}</h5>
                </div>
                <hr style={{ position: "relative", top: "40px" }}></hr>
                <h6
                  style={{
                    marginTop: "20px",
                    float: lng === "arab" ? "left" : "right",
                    padding: lng === "arab" ? "0 25px" : null,
                  }}
                >
                  <s
                    style={{
                      color: "#0E92FC",
                      fontSize: "0.9rem",
                      marginRight: lng === "arab" ? null : "10px",
                      marginLeft: lng === "arab" ? "10px" : null,
                      float: lng === "arab" ? "right" : "left",
                    }}
                  >
                    $ {price}
                  </s>
                  $ {(price - discount).toFixed(2)}
                </h6>
              </div>
            ) : null}
          </div>

          {selectedCountry.PaymentGateWay === "stripe" ? (
            <div style={{ paddingBottom: "10px" }}>
              <button
                disabled={isLoading || !selectedCountry}
                onClick={() => (isBusiness ? createStripeCheckout(setIsLoading, id, coupon, selectedNumber, selectedPlan) : createStripeCheckout(setIsLoading, id, coupon))}
              >
                {isLoading ? (
                  <CircularProgress size={25} />
                ) : (
                  <>
                    {t("Proceed To Checkout")}{" "}
                    <img
                      src={right}
                      width={36}
                      style={{
                        transform: lng === "arab" ? "rotate(180deg)" : null,
                      }}
                    />
                  </>
                )}
              </button>
              <div style={{ maxWidth: "250px", margin: "auto" }}>
                <Paypal
                  selectedPlan={selectedPlanPayPal}
                  selectedNumber={selectedNumber}
                  isYearly={isYearly}
                  user={user}
                  setThankyou={setShowModalPayPal}
                  setShowSubscribeModal={setShowModal}
                  setShowCheckoutModal={setIsOpen}
                />
              </div>

              {isYearly || isBusiness ? (
                <>
                  {selectedCountry?.allowCrypto === true ? (
                    <>
                      <button
                        style={{
                          background: "none",
                          border: "1px solid #D554F0",
                          color: "#D554F0",
                        }}
                        onClick={() => createCryptoCheckout(selectedPlan, price, selectedNumber)}
                      >
                        {t("Pay with Crypto")}
                      </button>
                    </>
                  ) : null}
                </>
              ) : null}
            </div>
          ) : selectedCountry.PaymentGateWay === "hyperpay" ? (
            <HyperPay id={checkoutId} />
          ) : null}
        </div>
      </div>
      <div className="row">
        <div className="col-12" style={{ marginBottom: "10px", marginTop: "10px" }}>
          <div className="row">
            <div className="col-12">
              <p style={{ fontSize: "10px", textAlign: "center" }}>
                {t(
                  "By confirming your subscription, you allow Twwt.Ai to charge your card for this payment and future payments in accordance with their team. You can always cancel your subscription."
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CouponPopUp;
