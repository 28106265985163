import React, { useEffect, useRef, useState } from "react";

import WaveSurfer from "wavesurfer.js";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";

const formWaveSurferOptions = (ref) => ({
  container: ref,
  waveColor: "#eee",
  progressColor: "rgba(60, 200, 235, 1)",
  cursorColor: "rgba(60, 200, 235, 1)",
  barWidth: 3,
  barRadius: 3,
  responsive: true,
  height: 130,
  normalize: true,
  partialRender: true,
});

export default function Waveform({ url }) {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [playing, setPlay] = useState(false);
  const [volume, setVolume] = useState(0.5);

  var timeCalculator = function (value) {
    let hour = "00";
    let minute = "00";
    let second = Math.floor(value % 60);

    if (second < 10) {
      second = "0" + second;
    }
    return hour + ":" + minute + ":" + second;
  };
  useEffect(() => {
    setPlay(false);
    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);
    wavesurfer.current.load(url);
    wavesurfer.current.on("audioprocess", function (e) {
      current.textContent = timeCalculator(wavesurfer.current.getCurrentTime());
    });
    
    wavesurfer.current.on("ready", function (e) {
      duration.textContent = timeCalculator(wavesurfer.current.getDuration());
      if (wavesurfer.current) {
        wavesurfer.current.setVolume(volume);
        setVolume(volume);
      }
    });
    return () => wavesurfer.current.destroy();
  }, [url]);

  const handlePlayPause = () => {
    setPlay(!playing);
    wavesurfer.current.playPause();
  };

  return (
    <div style={{ color: "blue", width: "50%" }}>
      <div id="waveform" ref={waveformRef} style={{ color: "blue" }} />
      <div className="controls">
        <div
          className="beforeafter-gray"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "baseline",
          }}
        >
          <div onClick={handlePlayPause} style={{ cursor: "pointer" }}>
            {!playing ? <PlayArrowRoundedIcon style={{ color: "rgba(60, 200, 235, 1)", fontSize: "40px" }} /> : <PauseRoundedIcon style={{ color: "rgba(60, 200, 235, 1)", fontSize: "40px" }} />}
          </div>
          <div className="time">
            <span id="current" style={{ color: "black" }}>
              0:00:00
            </span>{" "}
            /
            <span id="duration" style={{ color: "black" }}>
              0:00:00
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
